import { checkPermissionLevel, setPermissions } from './utils'

// this is handling google maps icon in SliderOptions & checkbox in OfferSettingsModal, rest is handled via allowedTabs
export const setGoogleMaps = ({ agentPermissions, selectedProperty, isAgentOwnership, isAgentOffice, currentAgentId, currentOffice, module }) => {
    const permission = module === 'properties' ? 'view.property.map' : 'view.project.map'

    // related to SliderOptions
    const hasSliderPermission = setPermissions(agentPermissions, permission, isAgentOwnership, isAgentOffice)

    // related to OfferSettingsModal
    const level = checkPermissionLevel(agentPermissions, permission)
    const permissions = {
        all: true,
        office: selectedProperty?.agent[0].agencyOffice.id === currentOffice.id,
        own: selectedProperty?.agent[0].id === currentAgentId,
    }
    const hasModalPermission = level in permissions ? permissions[level] : false

    return { showMaps: hasSliderPermission || hasModalPermission }
}
