import values from 'lodash/values';

import { stateKey } from './actions';

export const selectCityById = cityId => state => state[stateKey].city[cityId];

export const selectLocationsCountryFormOptions = state => {
    const data = values(state[stateKey].country);
    // console.log('data country', data)
    // console.log('state', state)
    return data.map((service) => (
        {
            value: service.id.toString(),
            label: service.name
        }
    ))
}

export const selectLocationsCountyFormOptions = state => {
    const data = values(state[stateKey].county);
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => (
        {
            value: service.id.toString(),
            label: service.name
        }
    ))
}
export const selectLocationsCityFormOptions = state => {
    const data = values(state[stateKey].city);
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => (
        {
            value: service.id.toString(),
            label: service.name
        }
    ))
}
export const selectLocationsWiderAreaFormOptions = state => {
    const data = values(state[stateKey].widerarea);
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => (
        {
            value: service.id.toString(),
            label: service.name
        }
    ))
}

export const selectLocationsQuarterFormOptions = state => {
    const data = values(state[stateKey].quarter);
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => (
        {
            value: service.id.toString(),
            label: service.name
        }
    ))
}

export const selectLocationsQIslandFormOptions = state => {
    const data = values(state[stateKey].island);
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => (
        {
            value: service.id.toString(),
            label: service.name
        }
    ))
}