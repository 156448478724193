import values from 'lodash/values';

import { stateKey } from './actions';

export const selectContacts = state => values(state[stateKey].items);
export const selectContactsAsyncFormOption =  state => {
    const data = values(state[stateKey].asyncItems);
    // console.log('data', data)
    // console.log('state', state)
    return data.map((item) => (
        {
            value: item.id.toString(),
            label: item.name
        }
    ))
};
export const selectContactsAsyncFetching = state => state[stateKey].asyncFetching;
export const selectContactsFetching = state => state[stateKey].fetching;
export const selectContactsCount = state => state[stateKey].count;
export const selectContactFetchingDetails = state => state[stateKey].fetchingDetails;
export const selectContact = state => state[stateKey].item;
export const selectContactsPagination = (state) => state[stateKey].pagination;
export const selectContactLinks = (state) => state[stateKey].links

export const selectContactFetchingDocuments = state => state[stateKey].fetchingDocuments;
export const selectContactsDocumentCount = state => state[stateKey].itemdocumentcount;

export const selectContactDocument = state => values(state[stateKey].itemdocument);
export const selectContactDocumentQue = state => values(state[stateKey].documentque);

export const selectContactAsycDocumentContactFormOptions = (state) => {
    const data = state[stateKey].item?.contactPerson;

    // console.log('data company', state[stateKey].item?.contactPersonCompany)
    if(data) {
        return [
            {
                value: state[stateKey].item?.id,
                label: data?.firstName + ' ' + data?.lastName
            }
        ]
    }

    return [];
}

export const selectContactCompanyAsycDocumentContactFormOptions = (state) => {
    const data = state[stateKey].item?.contactPersonCompany;
    // console.log('data company', state[stateKey].item?.contactPersonCompany)
    if(data?.length) {
        return data.map(item => ({
            label: item?.company?.contactCompany?.name,
            value: item?.company?.id ?? ""
        }))
    }

    return [];
}

export const selectContactAsyncSelectedFormOptions = (state) => {
    const data = state[stateKey].item?.contactPersonCompany;
    // console.log('data company', state[stateKey].item?.contactPersonCompany)
    if(data?.length) {
        return data?.map((item) => (
            {
                label: item?.company?.contactCompany?.name,
                value: item?.company?.id?.toString()
            }
        ))
    }

    return [];
}