import values from 'lodash/values'
import { priorityCompareFn } from '../../utils/general'

import { stateKey } from './actions'

export const selectGroundPlans = (state) => values(state[stateKey].items)
export const selectSortedGroundPlans = (state) => values(state[stateKey].items).sort(priorityCompareFn)
export const selectGroundPlansFetching = (state) => state[stateKey].fetching
export const selectGroundPlansCount = (state) => state[stateKey].count
export const selectGroundPlansQue = (state) => values(state[stateKey].que)
export const selectGroundPlansProxyPhoto = (state) => state[stateKey].proxyPhoto
export const selectGroundPlansProxyPhotoFetching = (state) => state[stateKey].proxyPhotoFetching
