export const companyGeneralFields = {
    ID: 'id',
    NAME: 'name',
    FULL_NAME: 'full_name',
    CONTACT_LEGAL_FORM_ID: 'contact_legal_form_id',
    TAX_NUMBER: 'tax_number',
    INITIAL_CONTACT_DATE: 'initial_contact_date',
    CONTACT_PRIORITY_ID: 'contact_priority_id',
    DATA_SOURCE_ID: 'data_source_id',
    PHONE_NUMBER: 'phone_number',
    CONTACT_PHONE_TYPE_ID: 'contact_phone_type_id',
    EMAIL: 'email',
    CONTACT_EMAIL_TYPE_ID: 'contact_email_type_id',
    NEWSLETTER_APPROVAL: 'newsletter_approval',
    WEBSITE: 'website',
    STREET: 'street',
    STREET_NUMBER: 'street_number',
    ZIP_CODE: 'zip_code',
    CITY: 'city',
    CONTACT_COUNTRY_ID: 'contact_country_id',
    CONTACT_ID: 'contact_id',
    CONTACT_STATUS_ID: 'contact_status_id',
    AGENT_ID: 'agent_id',
    CONTACT_QUERY_CATEGORY_ID: 'contact_query_category_id',
    CONTACT_SOURCE_ID: 'contact_source_id', // ERSTE ONLY FIELD
    CONTACT_ABOUT: 'contact_about' // ERSTE ONLY FIELD
}

export const companyOtherInfoFields = {
    CONTACT_DESCRIPTION_ID: 'contact_description_id',
    CONTACT_CORE_BUSINESS_ID: 'contact_core_business_id',
    CONTACT_COMPANY_SIZE_ID: 'contact_company_size_id',
    EMPLOYEES_NO: 'employees_no',
    BANK_ACCOUNT_NO: 'bank_account_no',
    REGISTRY_COURT: 'registry_court',
    SHARE_CAPITAL: 'share_capital',
    INITIAL_CALL_INFO: 'initial_call_info',
    NOTES: 'notes'
}

export const companyProfileFields = {
    PROPERTY_TYPE_ID: 'property_type_id',
    PROPERTY_SUB_TYPE_ID: 'property_sub_type_id',
    CONTACT_PROFILE_STATUS_ID: 'contact_profile_status_id',
    CONTACT_FINANCING_ID: 'contact_financing_id',
    CONTACT_PROFILE_DURATION_ID: 'contact_profile_duration_id',
    CONTACT_DEFINITION_ID: 'contact_definition_id',
    COMPANY_DEFINITION_ID: 'company_definition_id',
    COUNTRY_ID: 'country_id',
    COUNTY_ID: 'county_id',
    CITY_ID: 'city_id',
    WIDER_AREA_ID: 'wider_area_id',
    QUARTER_ID: 'quarter_id',
    ISLAND_ID: 'island_id',
    PRICE_FROM: 'price_from',
    PRICE_TO: 'price_to',
    PRICE_M2_FROM: 'price_m2_from',
    PRICE_M2_TO: 'price_m2_to',
    ROOMS_FROM: 'rooms_from',
    ROOMS_TO: 'rooms_to',
    BEDROOMS_FROM: 'bedrooms_from',
    BEDROOMS_TO: 'bedrooms_to',
    AREA_FROM: 'area_from',
    AREA_TO: 'area_to',
    PROPERTY_FLOOR_POSITION_ID: 'property_floor_position_id',
    FLOOR_NO_FROM: 'floor_from',
    FLOOR_NO_TO: 'floor_to',
    EXCLUDE_BASEMENT: 'exclude_basement',
    EXCLUDE_LAST_FLOOR: 'exclude_last_floor',
    PROPERTY_SPACE_ID: 'property_space_id',
    SPACE_COUNT: 'space_count',
    SPACE_AREA: 'space_area',
    PROPERTY_DESCRIPTIONS_ID: 'property_descriptions_id',
    PROPERTY_EQUIPMENT_ID: 'property_equipment_id',
    PROPERTY_UTILITY_ID: 'property_utility_id',
    PROPERTY_HEATING_ID: "property_heating_id",
    CONTACT_PROFILE_REMARK_ID: "contact_profile_remark_id",
    CONTACT_PROFILE_SERVICE_ID: 'contact_profile_service_id',
    SPECIAL_REQUEST: 'special_request',
    MAX_CONTSRUCTION_AREA: 'max_construction_area',
    PROPERTY_FLOOR_ID: 'property_floor_id',
}