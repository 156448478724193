import values from 'lodash/values';

import { stateKey } from './actions';

export const selectContactValuesAvailableEntitiesFormOptions = state => {
    const data = values(state[stateKey].availableentities);
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => (
        {
            value: service.id.toString(),
            label: service.name
        }
    ))
}

export const selectContactValuesCompanySizeFormOptions = state => {
    const data = values(state[stateKey].companysize);
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => (
        {
            value: service.id.toString(),
            label: service.name
        }
    ))
}

export const selectContactValuesCoreBusinessFormOptions = state => {
    const data = values(state[stateKey].corebusiness);
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => (
        {
            value: service.id.toString(),
            label: service.name
        }
    ))
}

export const selectContactValuesDefinitionFormOptions = state => {
    const data = values(state[stateKey].definition);
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => (
        {
            value: service.id.toString(),
            label: service.name
        }
    ))
}
export const selectContactValuesCompannyDefinitionFormOptions = state => {
    const data = values(state[stateKey].companydefinition);
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => (
        {
            value: service.id.toString(),
            label: service.name
        }
    ))
}

export const selectContactValuesDescriptionFormOptions = state => {
    const data = values(state[stateKey].description);
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => (
        {
            value: service.id.toString(),
            label: service.name
        }
    ))
}

export const selectContactValuesEmailTypeFormOptions = state => {
    const data = values(state[stateKey].emailtype);
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => (
        {
            value: service.id.toString(),
            label: service.name
        }
    ))
}

export const selectContactValuesGenderFormOptions = state => {
    const data = values(state[stateKey].gender);
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => (
        {
            value: service.id.toString(),
            label: service.name
        }
    ))
}

export const selectContactValuesJobTitleFormOptions = state => {
    const data = values(state[stateKey].jobtitle);
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => (
        {
            value: service.id.toString(),
            label: service.name
        }
    ))
}

export const selectContactSourceValues = state => values(state[stateKey].contactsource)

export const selectContactValuesSourceFormOptions = state => {
    const data = values(state[stateKey].contactsource);
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => (
        {
            value: service.id.toString(),
            label: service.name
        }
    ))
}

export const selectContactValuesLegalFormFormOptions = state => {
    const data = values(state[stateKey].legalform);
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => (
        {
            value: service.id.toString(),
            label: service.name
        }
    ))
}

export const selectContactValuesMartialStatusFormOptions = state => {
    const data = values(state[stateKey].martialstatus);
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => (
        {
            value: service.id.toString(),
            label: service.name
        }
    ))
}

export const selectContactValuesPhoneTypeFormOptions = state => {
    const data = values(state[stateKey].phonetype);
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => (
        {
            value: service.id.toString(),
            label: service.name
        }
    ))
}

export const selectContactValuesPriorityFormOptions = state => {
    const data = values(state[stateKey].priority);
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => (
        {
            value: service.id.toString(),
            label: service.name
        }
    ))
}

export const selectContactValuesQueryCategoryFormOptions = state => {
    const data = values(state[stateKey].querycategory);
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => (
        {
            value: service.id.toString(),
            label: service.name
        }
    ))
}

export const selectContactValuesStatusFormOptions = state => {
    const data = values(state[stateKey].status);
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => (
        {
            value: service.id.toString(),
            label: service.name
        }
    ))
}

export const selectContactValuesTitleFormOptions = state => {
    const data = values(state[stateKey].title);
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => (
        {
            value: service.id.toString(),
            label: service.name
        }
    ))
}

export const selectContactValuesFinancingFormOptions = state => {
    const data = values(state[stateKey].financing);
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => (
        {
            value: service.id.toString(),
            label: service.name
        }
    ))
}
export const selectContactValuesProfileFormOptions = state => {
    const data = values(state[stateKey].profile);
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => (
        {
            value: service.id.toString(),
            label: service.name
        }
    ))
}

export const selectContactValuesProfileDurationFormOptions = state => {
    const data = values(state[stateKey].profileduration);
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => (
        {
            value: service.id.toString(),
            label: service.name
        }
    ))
}

export const selectContactValuesProfileStatusFormOptions = state => {
    const data = values(state[stateKey].profilestatus);
    // console.log('data profile status', data)
    // console.log('state', state)
    return data.map((service) => (
        {
            value: service.id.toString(),
            label: service.name
        }
    ))
}

export const selectContactValuesProfileRemarkFormOptions = state => {
    const data = values(state[stateKey].profileremark);
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => (
        {
            value: service.id.toString(),
            label: service.name
        }
    ))
}

export const selectContactValuesProfileServiceFormOptions = state => {
    const data = values(state[stateKey].profileservice);
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => (
        {
            value: service.id.toString(),
            label: service.name
        }
    ))
}

export const selectContactValuesCountryFormOptions = state => {
    const data = values(state[stateKey].country);
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => (
        {
            value: service.id.toString(),
            label: service.name
        }
    ))
}