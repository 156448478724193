import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { Button, Card, CardBody, CardHeader, Col, Input, Row, UncontrolledTooltip } from 'reactstrap'
import { photosFields } from '../common/forms/photos/fields'
import { API_URL } from '../common/constants'

const PhotoCard = ({
    photo,
    isUploaded = true,
    handleFullSizeClick,
    className,
    handleCheckboxes,
    validation,
    handlePhotoSync,
    localeOptions,
    checkboxClassName = 'materialsPhotoCheckbox',
    subform = 'photos',
}) => {
    const { t } = useTranslation()

    const defaultOption = localeOptions[0]

    const [selectedLocale, setSelectedLocale] = useState(defaultOption)
    const [inputValue, setInputValue] = useState(photo?.translations?.[selectedLocale?.value]?.fileDescription)

    useEffect(() => {
        const validationField = validation.values?.[subform]?.[photo?.id]?.translations?.find(
            (x) => x.locale === selectedLocale.value
        )?.fileDescription

        const databaseField = photo?.translations?.[selectedLocale.value]?.fileDescription

        setInputValue(validationField || databaseField ? validationField ?? databaseField : '')
    }, [selectedLocale])

    if (!photo) return

    return (
        <Card className={classNames('dmn-photo-card', className)}>
            {isUploaded && (
                <CardHeader className="bg-dark border-0 px-2 py-1">
                    <div className="float-end d-flex align-items-center">
                        <Button onClick={() => handleFullSizeClick(photo)} color="dark" className="btn-icon">
                            <i className="mdi mdi-arrow-top-right-bottom-left fs-19" />{' '}
                        </Button>
                        <div className="form-check ms-2">
                            <Input
                                onChange={() => handleCheckboxes()}
                                type="checkbox"
                                className={`form-check-input ${checkboxClassName}`}
                                value={photo.id}
                            />
                        </div>
                    </div>
                    <Button color="dark" className="btn-icon" style={{ cursor: 'move' }} onClick={(e) => e.preventDefault()}>
                        {' '}
                        <i className="mdi mdi-menu fs-19" />{' '}
                    </Button>
                </CardHeader>
            )}
            <div className="card-img" style={{ position: 'relative', overflow: 'hidden' }}>
                <UncontrolledTooltip target={`fileSync_${photo.id}`}>
                    {photo.fileSync ? t('form.common.photo.synchronized') : t('form.common.photo.markForSync')}
                </UncontrolledTooltip>
                <span
                    id={`fileSync_${photo.id}`}
                    className={`text-success dmn-photo-sync-icon rounded-circle ${photo.fileSync ? 'dmn-photo-synced' : ''}`}
                    onClick={() => handlePhotoSync(photo.id)}
                >
                    <i className={`mdi mdi-check`}></i>
                </span>

                <img
                    className="img-fluid"
                    src={photo.base64 ? photo.base64 : `${API_URL}v1/file/show/${photo.id}/original`}
                    alt={photo.fileDescription}
                />
            </div>
            {isUploaded && (
                <CardBody>
                    <Row className="align-items-center">
                        <Col sm={7}>
                            <Input
                                type="text"
                                className="form-control"
                                onChange={(e) => {
                                    setInputValue(e.target.value)
                                }}
                                onBlur={() => {
                                    const oldValue = validation?.values?.[`${subform}`]?.[photo.id]?.translations ?? []

                                    oldValue?.some((e) => e.locale === selectedLocale.value)
                                        ? oldValue.map((item) =>
                                              item.locale === selectedLocale.value ? (item['fileDescription'] = inputValue) : null
                                          )
                                        : validation?.setFieldValue(`${subform}.${photo.id}.translations`, [
                                              ...oldValue,
                                              { locale: selectedLocale.value, fileDescription: inputValue },
                                          ])
                                }}
                                value={inputValue}
                            />
                        </Col>
                        <Col sm={5}>
                            <Select
                                onChange={(e) => {
                                    setSelectedLocale(e)
                                }}
                                options={localeOptions}
                                name={`${photosFields.PHOTO_LOCALE}${photo.id}`}
                                id={`${photosFields.PHOTO_LOCALE}${photo.id}`}
                                value={selectedLocale}
                                placeholder=""
                            />
                        </Col>
                    </Row>
                </CardBody>
            )}
        </Card>
    )
}

export default PhotoCard
