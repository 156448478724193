import { handleActions } from 'redux-actions'
import { fetchAgentsFailure, fetchAgentsRequest, fetchAgentsSuccess, fetchUserRequest, fetchUserSuccess, fetchUserFailure } from './actions'

export const initialState = {
    items: {},
    userArray: [],
    error: null,
    fetching: false,
    user: {},
    fetchingUser: false,
    errorUser: null,
}

export default handleActions(
    {
        [fetchAgentsRequest]: (state) => ({
            ...state,
            fetching: true,
            error: null,
        }),
        [fetchAgentsSuccess]: (state, { payload }) => ({
            ...state,
            count: payload?.count ?? 0,
            items: payload?.reduce((acc, item) => {
                // let parsedItem = JSON.parse(item);
                return { ...acc, [item.id]: item }
            }, {}),
            usersArray: payload,
            fetching: false,
        }),
        [fetchAgentsFailure]: (state, { payload }) => ({
            ...state,
            error: payload,
            fetching: false,
        }),
        [fetchUserRequest]: (state) => ({
            ...state,
            fetchingUser: true,
            errorUser: null,
        }),
        [fetchUserSuccess]: (state, { payload }) => ({
            ...state,
            user: payload,
            fetchingUser: false,
        }),
        [fetchUserFailure]: (state, { payload }) => ({
            ...state,
            errorUser: payload,
            fetchingUser: false,
        }),
    },
    initialState
)
