const requestFields = {
    PRICE_FROM: 'price_from',
    PRICE_TO: 'price_to',
    PRICE_M2_FROM: 'price_m2_from',
    PRICE_M2_TO: 'price_m2_to',
    ROOMS_FROM: 'rooms_from',
    ROOMS_TO: 'rooms_to',
    BEDROOMS_FROM: 'bedrooms_from',
    BEDROOMS_TO: 'bedrooms_to',
    AREA_FROM: 'area_from',
    AREA_TO: 'area_to',
    FLOOR_FROM: 'floor_from',
    FLOOR_TO: 'floor_to',
    CONTACT_PROFILE_DURATION_ID: 'contact_profile_duration_id',
    PROPERTY_SUPPLY_SOURCE_ID: 'property_supply_source_id',
}

export default requestFields;