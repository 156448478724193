import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { getPropertyById } from './functions'
import { AxiosError } from 'axios'
import { Property } from './types'
import { getSearchCriteriaFromStore } from '../helpers'

const properties = {
    GetProperty: {
        useQuery: (id: number, refreshToken: string, options?: UseQueryOptions<Property, AxiosError>) =>
            useQuery({
                queryKey: ['property', id, getSearchCriteriaFromStore('property'), refreshToken],
                queryFn: () => getPropertyById(id),
                staleTime: 5 * 60 * 1000,
                ...options,
            }),
    },
}

export default properties
