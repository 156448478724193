import { createAction } from 'redux-actions'

const ENTITY = 'COMPANIES'
export const stateKey = ENTITY.toLowerCase()

// export const fetchCompanies = createAction(`${ENTITY}/FETCH`);
export const fetchCompanies = createAction(
    `${ENTITY}/FETCH`,
    (payload) => payload,
    (_, callback) => callback
)
export const fetchCompaniesRequest = createAction(`${ENTITY}/FETCH_REQUEST`)
export const fetchCompaniesSuccess = createAction(`${ENTITY}/FETCH_SUCCESS`)
export const fetchCompaniesFailure = createAction(`${ENTITY}/FETCH_FAILURE`)

export const fetchCompany = createAction(`${ENTITY}/DETAILS/FETCH`)
export const fetchCompanyRequest = createAction(`${ENTITY}/DETAILS/FETCH_REQUEST`)
export const fetchCompanySuccess = createAction(`${ENTITY}/DETAILS/FETCH_SUCCESS`)
export const fetchCompanyFailure = createAction(`${ENTITY}/DETAILS/FETCH_FAILURE`)

export const createCompany = createAction(
    `${ENTITY}/CREATE`,
    (values) => values,
    (values, callback) => ({ callback })
)
// export const createCompany = createAction(`${ENTITY}/CREATE`);
export const createCompanyRequest = createAction(`${ENTITY}/CREATE_REQUEST`)
export const createCompanySuccess = createAction(`${ENTITY}/CREATE_SUCCESS`)
export const createCompanyFailure = createAction(`${ENTITY}/CREATE_FAILURE`)

export const updateCompany = createAction(
    `${ENTITY}/UPDATE`,
    (values) => values,
    (values, callback) => ({ callback })
)
// export const createCompany = createAction(`${ENTITY}/CREATE`);
export const updateCompanyRequest = createAction(`${ENTITY}/UPDATE_REQUEST`)
export const updateCompanySuccess = createAction(`${ENTITY}/UPDATE_SUCCESS`)
export const updateCompanyFailure = createAction(`${ENTITY}/UPDATE_FAILURE`)

export const deleteCompany = createAction(
    `${ENTITY}/DELETE`,
    (payload) => payload,
    (_, redirect) => ({ redirect })
)
export const deleteCompanyRequest = createAction(`${ENTITY}/DELTE_REQUEST`)
export const deleteCompanySuccess = createAction(`${ENTITY}/DELTE_SUCCESS`)
export const deleteCompanyFailure = createAction(`${ENTITY}/DELETE_FAILURE`)

export const deleteCompanyMultiple = createAction(
    `${ENTITY}/DELETE_MULTPLE`,
    (payload) => payload,
    (payload, callback) => callback
)
export const deleteCompanyMultipleRequest = createAction(`${ENTITY}/DELTE_MULTPLE_REQUEST`)
export const deleteCompanyMultipleSuccess = createAction(`${ENTITY}/DELTE_MULTPLE_SUCCESS`)
export const deleteCompanyMultipleFailure = createAction(`${ENTITY}/DELETE_MULTPLE_FAILURE`)

export const fetchCompaniesAsyncInput = createAction(
    `${ENTITY}/FETCH_ASYNC_INPUT`,
    (payload) => payload,
    (payload, callback) => callback
)
export const fetchCompaniesAsyncInputContactRequest = createAction(`${ENTITY}/FETCH_ASYNC_INPUT_REQUEST`)
export const fetchCompaniesAsyncInputContactSuccess = createAction(`${ENTITY}/FETCH_ASYNC_INPUT_SUCCESS`)
export const fetchCompaniesAsyncInputContactFailure = createAction(`${ENTITY}/FETCH_ASYNC_INPUT_FAILURE`)

export const fetchCompaniesNoStore = createAction(
    `${ENTITY}/NO_STORE/FETCH`,
    (payload) => payload,
    (payload, callback) => ({ callback })
)
export const fetchCompaniesNoStoreRequest = createAction(`${ENTITY}/NO_STORE/FETCH_REQUEST`)
export const fetchCompaniesNoStoreSuccess = createAction(`${ENTITY}/NO_STORE/FETCH_SUCCESS`)
export const fetchCompaniesNoStoreFailure = createAction(`${ENTITY}/NO_STORE/FETCH_FAILURE`)

export const resetPageCompanies = createAction(`${ENTITY}/RESET_PAGE_COMPANIES`)
