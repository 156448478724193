import { createAction } from 'redux-actions'

const ENTITY = 'PROPERTIES'
export const stateKey = ENTITY.toLowerCase()

export const fetchProperties = createAction(
    `${ENTITY}/FETCH`,
    (payload) => payload,
    (_, callback) => callback
)

// export const fetchProperties = createAction(`${ENTITY}/FETCH`);
export const fetchPropertiesRequest = createAction(`${ENTITY}/FETCH_REQUEST`)
export const fetchPropertiesSuccess = createAction(`${ENTITY}/FETCH_SUCCESS`)
export const fetchPropertiesFailure = createAction(`${ENTITY}/FETCH_FAILURE`)

export const fetchProperty = createAction(
    `${ENTITY}/DETAILS/FETCH`,
    (payload) => payload,
    (_, callback) => callback
)
export const fetchPropertyRequest = createAction(`${ENTITY}/DETAILS/FETCH_REQUEST`)
export const fetchPropertySuccess = createAction(`${ENTITY}/DETAILS/FETCH_SUCCESS`)
export const fetchPropertyFailure = createAction(`${ENTITY}/DETAILS/FETCH_FAILURE`)

export const opportunityShowActivity = createAction(`${ENTITY}/ACTIVITY/SHOW`)
export const opportunityShowContact = createAction(`${ENTITY}/CONTACT/SHOW`)

export const fetchOpportunityActivity = createAction(
    `${ENTITY}/ACTIVITY/FETCH`,
    (payload) => payload,
    (_, callback) => callback
)
export const fetchOpportunityActivityRequest = createAction(`${ENTITY}/ACTIVITY/FETCH_REQUEST`)
export const fetchOpportunityActivitySuccess = createAction(`${ENTITY}/ACTIVITY/FETCH_SUCCESS`)
export const fetchOpportunityActivityFailure = createAction(`${ENTITY}/ACTIVITY/FETCH_FAILURE`)

export const fetchOpportunityContact = createAction(
    `${ENTITY}/CONTACT/FETCH`,
    (payload) => payload,
    (_, callback) => callback
)
export const fetchOpportunityContactRequest = createAction(`${ENTITY}/CONTACT/FETCH_REQUEST`)
export const fetchOpportunityContactSuccess = createAction(`${ENTITY}/CONTACT/FETCH_SUCCESS`)
export const fetchOpportunityContactFailure = createAction(`${ENTITY}/CONTACT/FETCH_FAILURE`)

export const createProperty = createAction(
    `${ENTITY}/CREATE`,
    (values) => values,
    (values, callback) => ({ callback })
)
// export const createProperty = createAction(`${ENTITY}/CREATE`);
export const createPropertyRequest = createAction(`${ENTITY}/CREATE_REQUEST`)
export const createPropertySuccess = createAction(`${ENTITY}/CREATE_SUCCESS`)
export const createPropertyFailure = createAction(`${ENTITY}/CREATE_FAILURE`)

export const updateProperty = createAction(
    `${ENTITY}/UPDATE`,
    (values) => values,
    (values, callback) => ({ callback })
)
// export const createProperty = createAction(`${ENTITY}/CREATE`);
export const updatePropertyRequest = createAction(`${ENTITY}/UPDATE_REQUEST`)
export const updatePropertySuccess = createAction(`${ENTITY}/UPDATE_SUCCESS`)
export const updatePropertyFailure = createAction(`${ENTITY}/UPDATE_FAILURE`)

export const deleteProperty = createAction(
    `${ENTITY}/DELETE`,
    (payload) => payload,
    (_, redirect) => ({ redirect })
)
export const deletePropertyRequest = createAction(`${ENTITY}/DELTE_REQUEST`)
export const deletePropertySuccess = createAction(`${ENTITY}/DELTE_SUCCESS`)
export const deletePropertyFailure = createAction(`${ENTITY}/DELETE_FAILURE`)

export const deletePropertyMultiple = createAction(`${ENTITY}/DELETE_MULTPLE`, (payload) => payload)
export const deletePropertyMultipleRequest = createAction(`${ENTITY}/DELTE_MULTPLE_REQUEST`)
export const deletePropertyMultipleSuccess = createAction(`${ENTITY}/DELTE_MULTPLE_SUCCESS`)
export const deletePropertyMultipleFailure = createAction(`${ENTITY}/DELETE_MULTPLE_FAILURE`)

export const fetchPropertyPhoto = createAction(
    `${ENTITY}/PHOTO/FETCH`,
    (payload) => payload,
    (payload, callback) => ({ callback })
)
export const fetchPropertyPhotoRequest = createAction(`${ENTITY}/PHOTO/FETCH_REQUEST`)
export const fetchPropertyPhotoSuccess = createAction(`${ENTITY}/PHOTO/FETCH_SUCCESS`)
export const fetchPropertyPhotoFailure = createAction(`${ENTITY}/PHOTO/FETCH_FAILURE`)

export const uploadPropertyPhoto = createAction(`${ENTITY}/UPLOAD_PHOTO`)
export const uploadPropertyPhotoRequest = createAction(`${ENTITY}/UPLOAD_PHOTO_REQUEST`)
export const uploadPropertyPhotoSuccess = createAction(`${ENTITY}/UPLOAD_PHOTO_SUCCESS`)
export const uploadPropertyPhotoFailure = createAction(`${ENTITY}/UPLOAD_PHOTO_FAILURE`)

export const quePropertyPhoto = createAction(`${ENTITY}/QUE_PHOTO`)
export const quePropertyPhotoRequest = createAction(`${ENTITY}/QUE_PHOTO_REQUEST`)
export const quePropertyPhotoSuccess = createAction(`${ENTITY}/QUE_PHOTO_SUCCESS`)
export const quePropertyPhotoFailure = createAction(`${ENTITY}/QUE_PHOTO_FAILURE`)
export const clearQuePropertyPhoto = createAction(`${ENTITY}/CLEAR_QUE_PHOTO`)

// export const fetchPropertiesContactProfile = createAction(`${ENTITY}/CONTACT_PROFILE/FETCH`);
export const fetchPropertiesContactProfile = createAction(
    `${ENTITY}/CONTACT_PROFILE/FETCH`,
    (payload) => payload,
    (payload, callback) => callback
)
export const fetchPropertiesContactProfileRequest = createAction(`${ENTITY}/CONTACT_PROFILE/FETCH_REQUEST`)
export const fetchPropertiesContactProfileSuccess = createAction(`${ENTITY}/CONTACT_PROFILE/FETCH_SUCCESS`)
export const fetchPropertiesContactProfileFailure = createAction(`${ENTITY}/CONTACT_PROFILE/FETCH_FAILURE`)
export const removePropertiesContactProfile = createAction(`${ENTITY}/CONTACT_PROFILE/REMOVE`)

export const uploadPropertyGroundPlan = createAction(`${ENTITY}/UPLOAD_GROUND_PLAN`)
export const uploadPropertyGroundPlanRequest = createAction(`${ENTITY}/UPLOAD_GROUND_PLAN_REQUEST`)
export const uploadPropertyGroundPlanSuccess = createAction(`${ENTITY}/UPLOAD_GROUND_PLAN_SUCCESS`)
export const uploadPropertyGroundPlanFailure = createAction(`${ENTITY}/UPLOAD_GROUND_PLAN_FAILURE`)

export const deletePropertyGroundPlan = createAction(`${ENTITY}/DELETE_GROUND_PLAN`)
export const deletePropertyGroundPlanRequest = createAction(`${ENTITY}/DELETE_GROUND_PLAN_REQUEST`)
export const deletePropertyGroundPlanSuccess = createAction(`${ENTITY}/DELETE_GROUND_PLAN_SUCCESS`)
export const deletePropertyGroundPlanFailure = createAction(`${ENTITY}/DELETE_GROUND_PLAN_FAILURE`)

export const quePropertyGroundPlan = createAction(`${ENTITY}/QUE_GROUND_PLAN`)
export const quePropertyGroundPlanRequest = createAction(`${ENTITY}/QUE_GROUND_PLAN_REQUEST`)
export const quePropertyGroundPlanSuccess = createAction(`${ENTITY}/QUE_GROUND_PLAN_SUCCESS`)
export const quePropertyGroundPlanFailure = createAction(`${ENTITY}/QUE_GROUND_PLAN_FAILURE`)
export const clearQuePropertyGroundPlan = createAction(`${ENTITY}/CLEAR_QUE_GROUND_PLAN`)

export const fetchPropertyGroundPlans = createAction(
    `${ENTITY}/GROUND_PLANS/FETCH`,
    (payload) => payload,
    (_, callback) => callback
)
// export const fetchPropertyGroundPlans = createAction(`${ENTITY}/DOCUMENTS/FETCH`);
export const fetchPropertyGroundPlansRequest = createAction(`${ENTITY}/GROUND_PLAN/FETCH_REQUEST`)
export const fetchPropertyGroundPlansSuccess = createAction(`${ENTITY}/GROUND_PLAN/FETCH_SUCCESS`)
export const fetchPropertyGroundPlansFailure = createAction(`${ENTITY}/GROUND_PLAN/FETCH_FAILURE`)

export const fetchPropertiesAsyncInput = createAction(
    `${ENTITY}/FETCH_ASYNC_INPUT`,
    (payload) => payload,
    (payload, callback) => callback
)
export const fetchPropertiesAsyncInputContactRequest = createAction(`${ENTITY}/FETCH_ASYNC_INPUT_REQUEST`)
export const fetchPropertiesAsyncInputContactSuccess = createAction(`${ENTITY}/FETCH_ASYNC_INPUT_SUCCESS`)
export const fetchPropertiesAsyncInputContactFailure = createAction(`${ENTITY}/FETCH_ASYNC_INPUT_FAILURE`)

export const fetchPropertyNoStore = createAction(
    `${ENTITY}/FETCH_NO_STORE`,
    (payload) => payload,
    (payload, callback) => callback
)
export const fetchPropertyNoStoreRequest = createAction(`${ENTITY}/FETCH_NO_STORE_REQUEST`)
export const fetchPropertyNoStoreSuccess = createAction(`${ENTITY}/FETCH_NO_STORE_SUCCESS`)
export const fetchPropertyNoStoreFailure = createAction(`${ENTITY}/FETCH_NO_STORE_FAILURE`)

export const fetchPropertiesNoStore = createAction(
    `${ENTITY}/NO_STORE/FETCH`,
    (payload) => payload,
    (payload, callback) => ({ callback })
)
export const fetchPropertiesNoStoreRequest = createAction(`${ENTITY}/NO_STORE/FETCH_REQUEST`)
export const fetchPropertiesNoStoreSuccess = createAction(`${ENTITY}/NO_STORE/FETCH_SUCCESS`)
export const fetchPropertiesNoStoreFailure = createAction(`${ENTITY}/NO_STORE/FETCH_FAILURE`)
// export const fetchProjectsNoStoreClear = createAction(`${ENTITY}/NO_STORE/CLEAR`);

export const fetchTemplatesNoStore = createAction(
    `${ENTITY}/FETCH_TEMPLATES_NO_STORE`,
    (payload) => payload,
    (payload, callback) => callback
)
export const fetchTemplatesNoStoreRequest = createAction(`${ENTITY}/FETCH_TEMPLATES_NO_STORE_REQUEST`)
export const fetchTemplatesNoStoreSuccess = createAction(`${ENTITY}/FETCH_TEMPLATES_NO_STORE_SUCCESS`)
export const fetchTemplatesNoStoreFailure = createAction(`${ENTITY}/FETCH_TEMPLATES_NO_STORE_FAILURE`)

export const resetPageProperties = createAction(`${ENTITY}/RESET_PAGE_PROPERTIES`)

export const updatePropertiesAgent = createAction(`${ENTITY}/UPDATE_PROPERTIES_AGENT`)
