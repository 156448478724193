import { handleActions } from 'redux-actions'
import { setCheckedTableRows, resetTableCheckbox } from './actions'

export const initialState = {
    selectedTableRows: [],
    isChecked: false,
}

export default handleActions(
    {
        [setCheckedTableRows]: (state, { payload }) => ({
            ...state,
            selectedTableRows: [...payload],
        }),
        [resetTableCheckbox]: (state, { payload }) => ({
            ...state,
            isChecked: payload,
        }),
    },
    initialState
)