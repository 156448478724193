import i18next from 'i18next'
import { fetchContactsAsyncInput } from '../../store/contacts'

export const baseInput = {
    colProps: {
        sm: 12,
        xxl: 4,
    },
    inputProps: {},
    styles: {},
    required: false,
    disabled: false,
}

export const baseTextInput = {
    ...baseInput,
    type: 'text',
}

export const emptyLabel = {
    type: 'empty',
}

export const phoneNumber = {
    type: 'phone',
    colProps: {
        sm: 12,
        xxl: 4,
    },
}

export const baseTextAreaInput = {
    ...baseInput,
    type: 'textarea',
}

export const baseDateInput = {
    ...baseInput,
    type: 'date',
}
export const baseTimeInput = {
    ...baseInput,
    type: 'time',
}
export const baseNumberInput = {
    ...baseInput,
    type: 'number',
}
export const basePriceInput = {
    ...baseInput,
    type: 'price',
}

export const baseNumeralInput = {
    ...baseInput,
    type: 'numeral',
}

export const baseSelectInput = {
    ...baseInput,
    type: 'select',
    isClearable: true,
}

export const baseMultiSelectInput = {
    ...baseSelectInput,
    isMulti: true,
    isClearable: true,
}

export const baseAsyncSelectInput = {
    ...baseInput,
    type: 'async-select',
}

export const baseCheckboxInput = {
    ...baseInput,
    type: 'checkbox',
}

export const baseRadioInput = {
    ...baseInput,
    type: 'radio',
}

export const baseAutocompleteInput = {
    ...baseInput,
    type: 'autocomplete',
}

export const baseMultiLanguageTextInput = (languages) => ({
    ...baseInput,
    type: 'multilanguage',
    inputType: 'textarea',
    languages: languages,
})

export const baseMultiLanguageEditorInput = (languages) => ({
    ...baseInput,
    type: 'multilanguage',
    inputType: 'editor',
    languages: languages,
})

export const baseEmailInput = {
    ...baseInput,
    type: 'email',
}

export function getOwnerFieldType(name) {
    const { t } = i18next

    return {
        [name]: {
            ...baseAsyncSelectInput,
            action: fetchContactsAsyncInput,
            noLimit: true,
            criteria: ['name'],
            colProps: {
                sm: 12,
            },
            name,
            label: t('form.owner.firstNameLastName'),
        },
    }
}
