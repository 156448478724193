import { createAction } from 'redux-actions'

const ENTITY = 'DOCUMENTS'
export const stateKey = ENTITY.toLowerCase()

export const uploadDocument = createAction(
    `${ENTITY}/UPLOAD`,
    (payload) => payload,
    (payload, callback) => ({ callback })
)
export const uploadDocumentRequest = createAction(`${ENTITY}/UPLOAD_REQUEST`)
export const uploadDocumentSuccess = createAction(`${ENTITY}/UPLOAD_SUCCESS`)
export const uploadDocumentFailure = createAction(`${ENTITY}/UPLOAD_FAILURE`)

export const deleteDocument = createAction(`${ENTITY}/DELETE`)
export const deleteDocumentRequest = createAction(`${ENTITY}/DELETE_REQUEST`)
export const deleteDocumentSuccess = createAction(`${ENTITY}/DELETE_SUCCESS`)
export const deleteDocumentFailure = createAction(`${ENTITY}/DELETE_FAILURE`)

export const deleteDocumentMultiple = createAction(`${ENTITY}/DELETE_MULTIPLE`, (payload) => payload)
export const deleteDocumentMultipleRequest = createAction(`${ENTITY}/DELTE_MULTPLE_REQUEST`)
export const deleteDocumentMultipleSuccess = createAction(`${ENTITY}/DELTE_MULTPLE_SUCCESS`)
export const deleteDocumentMultipleFailure = createAction(`${ENTITY}/DELETE_MULTPLE_FAILURE`)

export const queDocument = createAction(`${ENTITY}/QUE`)
export const queDocumentRequest = createAction(`${ENTITY}/QUE_REQUEST`)
export const queDocumentSuccess = createAction(`${ENTITY}/QUE_SUCCESS`)
export const queDocumentFailure = createAction(`${ENTITY}/QUE_FAILURE`)
export const clearQueDocument = createAction(`${ENTITY}/CLEAR_QUE`)

export const fetchDocuments = createAction(
    `${ENTITY}/FETCH`,
    (payload) => payload,
    (_, callback) => callback
)

export const fetchDocumentsRequest = createAction(`${ENTITY}/FETCH_REQUEST`)
export const fetchDocumentsSuccess = createAction(`${ENTITY}/FETCH_SUCCESS`)
export const fetchDocumentsFailure = createAction(`${ENTITY}/FETCH_FAILURE`)
export const clearDocuments = createAction(`${ENTITY}/CLEAR_DOCUMENTS`)

export const downloadDocument = createAction(
    `${ENTITY}/DOWNLOAD`,
    (payload) => payload,
    (_, callback) => callback
)

export const downloadDocumentRequest = createAction(`${ENTITY}/DOWNLOAD_REQUEST`)
export const downloadDocumentSuccess = createAction(`${ENTITY}/DOWNLOAD_SUCCESS`)
export const downloadDocumentFailure = createAction(`${ENTITY}/DOWNLOAD_FAILURE`)

export const syncDocument = createAction(
    `${ENTITY}/SYNC`,
    (payload) => payload,
    (_, callback) => callback
)

export const syncDocumentRequest = createAction(`${ENTITY}/SYNC_REQUEST`)
export const syncDocumentSuccess = createAction(`${ENTITY}/SYNC_SUCCESS`)
export const syncDocumentFailure = createAction(`${ENTITY}/SYNC_FAILURE`)

export const updateDocument = createAction(
    `${ENTITY}/UPDATE`,
    (payload) => payload,
    (_, callback) => callback
)
// export const fetchPhotos = createAction(`${ENTITY}/DOCUMENTS/FETCH`);
export const updateDocumentRequest = createAction(`${ENTITY}/UPDATE_REQUEST`)
export const updateDocumentSuccess = createAction(`${ENTITY}/UPDATE_SUCCESS`)
export const updateDocumentFailure = createAction(`${ENTITY}/UPDATE_FAILURupdate`)
