import values from 'lodash/values'

import { stateKey } from './actions'

export const selectPropertySet = (state) => values(state[stateKey].items)

export const selectPropertySetFormOptions = (state) => {
    const data = selectPropertySet(state)

    return data.map((realEstateType) => ({
        value: realEstateType.id.toString(),
        label: realEstateType.name,
    }))
}
