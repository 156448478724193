import _ from 'lodash'
import * as Yup from 'yup'
import i18n from '../../../i18n'
import { resolveValueByType } from '../../../utils/general'
import { IS_ERSTE, PROJECT_STATUS } from '../../constants'
import { projectResponseFields } from '../../response/project'
import { projectDescriptionFields, projectDetailSearchFields, projectGeneralFields, projectNoteFields } from './fields'

export const projectGeneralValidaton = (appSettings, data) => {
    const initialValues = {
        [projectGeneralFields.ID]: data?.id ?? '',
        [projectGeneralFields.PROJECT_STATUS_ID]: data?.[projectResponseFields.PROJECT_STATUS]?.id ?? '',
        ...(IS_ERSTE && { [projectGeneralFields.PROJECT_SIZE_ID]: data?.[projectResponseFields.PROJECT_SIZE]?.id ?? '' }),
        [projectGeneralFields.PROJECT_TYPE_ID]: data?.[projectResponseFields.PROJECT_TYPE]?.id ?? '',
        ...(IS_ERSTE && { [projectGeneralFields.PROJECT_OBJECT_TYPE_ID]: data?.[projectResponseFields.PROJECT_OBJECT_TYPE]?.id ?? '' }),
        ...(IS_ERSTE && { [projectGeneralFields.PROJECT_DEVELOPMENT_STAGE_ID]: data?.[projectResponseFields.PROJECT_DEVELOPMENT_STAGE]?.id ?? '' }),
        [projectGeneralFields.PROJECT_END_DATE]: data?.[projectResponseFields.PROJECT_END_DATE] ?? '',
        [projectGeneralFields.PROJECT_RECEIPT_DATE]: data?.[projectResponseFields.PROJECT_RECEIPT_DATE] ?? '',
        [projectGeneralFields.CONTRACT_NO]: data?.[projectResponseFields.CONTRACT_NO] ?? '',
        [projectGeneralFields.CONTRACT_DATE]: data?.[projectResponseFields.CONTRACT_DATE] ?? '',
        [projectGeneralFields.OWNER_ID]: resolveValueByType(data?.[projectResponseFields.OWNER], projectGeneralFields.OWNER_ID),
        [projectGeneralFields.COUNTRY_ID]: data?.[projectResponseFields.COUNTRY]?.id?.toString() ?? '',
        [projectGeneralFields.COUNTY_ID]: data?.[projectResponseFields.COUNTY]?.id ?? '',
        [projectGeneralFields.CITY_ID]: data?.[projectResponseFields.CITY]?.id ?? '',
        [projectGeneralFields.WIDER_AREA_ID]: data?.[projectResponseFields.WIDER_AREA]?.id ?? '',
        [projectGeneralFields.QUARTER_ID]: data?.[projectResponseFields.QUARTER]?.id ?? '',
        [projectGeneralFields.ISLAND_ID]: data?.[projectResponseFields.ISLAND]?.id ?? '',
        [projectGeneralFields.STREET]: data?.[projectResponseFields.STREET] ?? '',
        [projectGeneralFields.STREET_NUMBER]: data?.[projectResponseFields.STREET_NUMBER] ?? '',
        [projectGeneralFields.STREET_SYNC]: data?.[projectResponseFields.STREET_SYNC] ?? false,
        [projectGeneralFields.PROJECT_ENERGY_EFFICIENCY_ID]: data?.[projectResponseFields.PROJECT_ENERGY_EFFICIENCY]?.id ?? '',
        [projectGeneralFields.PROJECT_WEB_SYNC]: data?.[projectResponseFields.PROJECT_WEB_SYNC] === 1 ? true : false ?? false,
        ...(IS_ERSTE && {
            [projectGeneralFields.PROJECT_WEB_NAVIGATION_SYNC]:
                data?.[projectResponseFields.PROJECT_WEB_NAVIGATION_SYNC] === 1 ? true : false ?? false,
        }),
        [projectGeneralFields.AGENCY_COMMISSION_ID]: data?.[projectResponseFields.AGENCY_COMMISSION]?.id?.toString() ?? '',
        [projectGeneralFields.AGENT_ID]: data?.[projectResponseFields.AGENT]?.[0]?.id || '',
        [`${projectGeneralFields.AGENT_ID}_secondary`]: data?.[projectResponseFields.AGENT]?.[1]?.id ?? '',
        ...(IS_ERSTE && { [projectGeneralFields.ERSTE_REPRESENTATIVE]: data?.[projectResponseFields.ERSTE_REPRESENTATIVE] ?? '' }),
    }

    const validationSchema = {
        [projectGeneralFields.PROJECT_STATUS_ID]: Yup.number(),
        [projectGeneralFields.PROJECT_SIZE_ID]: Yup.number(),
        [projectGeneralFields.PROJECT_TYPE_ID]: Yup.number(),
        [projectGeneralFields.PROJECT_OBJECT_TYPE_ID]: Yup.number(),
        [projectGeneralFields.PROJECT_DEVELOPMENT_STAGE_ID]: Yup.string(),
        [projectGeneralFields.PROJECT_END_DATE]: Yup.string(),
        [projectGeneralFields.PROJECT_RECEIPT_DATE]: Yup.string(),
        [projectGeneralFields.CONTRACT_NO]: Yup.string(),
        [projectGeneralFields.CONTRACT_DATE]: Yup.string(),
        [projectGeneralFields.OWNER_ID]: Yup.array().of(Yup.object({ [projectGeneralFields.OWNER_ID]: Yup.string() })),
        [projectGeneralFields.COUNTRY_ID]: Yup.string(),
        [projectGeneralFields.COUNTY_ID]: Yup.string(),
        [projectGeneralFields.CITY_ID]: Yup.string(),
        [projectGeneralFields.WIDER_AREA_ID]: Yup.string(),
        [projectGeneralFields.QUARTER_ID]: Yup.string(),
        [projectGeneralFields.ISLAND_ID]: Yup.string(),
        [projectGeneralFields.STREET]: Yup.string(),
        [projectGeneralFields.STREET_NUMBER]: Yup.string(),
        [projectGeneralFields.STREET_SYNC]: Yup.bool(),
        [projectGeneralFields.PROJECT_ENERGY_EFFICIENCY_ID]: Yup.number(),
        [projectGeneralFields.PROJECT_WEB_SYNC]: Yup.bool(),
        [projectGeneralFields.PROJECT_WEB_NAVIGATION_SYNC]: Yup.bool(),
        [projectGeneralFields.AGENCY_COMMISSION_ID]: Yup.string(),
        [projectGeneralFields.AGENT_ID]: Yup.string(),
        [`${projectGeneralFields.AGENT_ID}_secondary`]: Yup.string(),
        [projectGeneralFields.ERSTE_REPRESENTATIVE]: Yup.string(),
    }

    const validationObject = {
        initialValues: initialValues,
        validationSchema: Yup.object(validationSchema),
        prepareModelForSubmit: (values) => {
            const model = _.cloneDeep(values)

            delete model[`${projectGeneralFields.AGENT_ID}_secondary`]

            return {
                ...model,
                [projectGeneralFields.AGENT_ID]: [values[projectGeneralFields.AGENT_ID], values[`${projectGeneralFields.AGENT_ID}_secondary`]].filter(
                    Boolean
                ),
                [projectGeneralFields.PROJECT_WEB_SYNC]: values?.[projectGeneralFields.PROJECT_WEB_SYNC] ? 1 : 0,
                ...(IS_ERSTE && {
                    [projectGeneralFields.PROJECT_WEB_NAVIGATION_SYNC]: values?.[projectGeneralFields.PROJECT_WEB_NAVIGATION_SYNC] ? 1 : 0,
                }),
            }
        },
    }

    return validationObject
}

export const projectDescriptionValidation = (data, appSettings) => {
    const projectTitleInitialValues = {}
    const projectTitleValidationSchema = {}
    const projectDescriptionInitialValue = {}
    const projectDescriptionValidationSchema = {}
    const projectOfferDescriptionInitialValue = {}
    const projectOfferDescriptionValidationSchema = {}

    if (appSettings.languages) {
        for (let lang of appSettings.languages) {
            projectTitleInitialValues[lang] = data?.translations?.[lang]?.projectTitle ?? ''

            if (lang === appSettings.lang) {
                projectTitleValidationSchema[appSettings.lang] = Yup.string().required(i18n.t('validation.projectTitle'))
            } else {
                projectTitleValidationSchema[lang] = Yup.string()
            }

            projectDescriptionInitialValue[lang] = data?.translations?.[lang]?.projectDescription ?? ''
            projectDescriptionValidationSchema[lang] = Yup.string()
            projectOfferDescriptionInitialValue[lang] = data?.translations?.[lang]?.projectOfferDescription ?? ''
            projectOfferDescriptionValidationSchema[lang] = Yup.string()
        }
    }

    return {
        initialValues: {
            [projectDescriptionFields.PROJECT_TITLE]: projectTitleInitialValues,
            [projectDescriptionFields.PROJECT_DESCRIPTION]: projectDescriptionInitialValue,
            [projectDescriptionFields.PROJECT_OFFER_DESCRIPTION]: projectOfferDescriptionInitialValue,
        },
        validationSchema: Yup.object({
            [projectDescriptionFields.PROJECT_TITLE]: Yup.object().shape(projectTitleValidationSchema),
            [projectDescriptionFields.PROJECT_DESCRIPTION]: Yup.object().shape(projectDescriptionValidationSchema),
            [projectDescriptionFields.PROJECT_OFFER_DESCRIPTION]: Yup.object().shape(projectOfferDescriptionValidationSchema),
        }),
    }
}

export const projectNoteValidation = (data, t) => {
    const maxCharacters = 1000

    return {
        initialValues: {
            [projectNoteFields.NOTE_TITLE]: data?.noteTitle ?? '',
            [projectNoteFields.NOTE_DATE]: data?.noteDate ?? '',
            [projectNoteFields.NOTE]: data?.note ?? '',
            [projectNoteFields.PROJECT_NOTE_AGENT_ID]: data?.agent?.id ?? data?.currentAgentId,
        },
        validationSchema: Yup.object({
            [projectNoteFields.NOTE_TITLE]: Yup.string().required(i18n.t('modal.notes.error.title')),
            [projectNoteFields.NOTE_DATE]: Yup.string().required(i18n.t('modal.notes.error.date')),
            [projectNoteFields.NOTE]: Yup.string()
                .required(i18n.t('modal.notes.error.note'))
                .max(maxCharacters, i18n.t('modal.notes.error.limit', { maxCharacters })),
            [projectNoteFields.PROJECT_NOTE_AGENT_ID]: Yup.string().required(i18n.t('modal.notes.error.agent')),
        }),
    }
}

export const projectDetailSearchValidation = (data) => {
    return {
        initialValues: {
            [projectDetailSearchFields.ID]: data?.id ?? '',
            [projectDetailSearchFields.PROJECT_TITLE]: data?.[projectDetailSearchFields.PROJECT_TITLE] ?? '',
            [projectDetailSearchFields.CITY]: data?.[projectDetailSearchFields.CITY] ?? '',
            [projectDetailSearchFields.PROJECT_TYPE]: data?.[projectDetailSearchFields.PROJECT_TYPE] ?? '',
            [projectDetailSearchFields.PROJECT_STATUS]: data?.[projectDetailSearchFields.PROJECT_STATUS] ?? [
                PROJECT_STATUS.PREPARATION.toString(),
                PROJECT_STATUS.FOR_AUTHORIZATION.toString(),
                PROJECT_STATUS.ACTIVE.toString(),
            ],
            [projectDetailSearchFields.AGENT]: data?.[projectDetailSearchFields.AGENT] ?? '',
        },
        prepareModelForSubmit: (values) => {
            const filledValues = Object.fromEntries(Object.entries(values).filter(([key, value]) => value !== null && value !== ''))

            return {
                ...filledValues,
            }
        },
    }
}
