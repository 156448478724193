import { FormikProvider, getIn, useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import { Container, Form, TabContent, TabPane } from 'reactstrap'
import * as Yup from 'yup'
import { CONTACT_TYPE, SUBMIT_RESPONSE_STATUS } from '../../common/constants'
import { contactProfileFields } from '../../common/forms/contacts/fields'
import { contactResponseFields } from '../../common/response/contact'
import FormActionButtons from '../../Components/Common/FormActionButtons'
import Loader from '../../Components/Common/Loader'
import PageTitle from '../../Components/Common/PageTitle'
import ContactFormGeneralTab, {
    ContactFormGeneralTabMain,
    ContactFormGeneralTabSide,
} from '../../Components/ContactFormGeneralTab/ContactFormGeneralTab'
import ContactFormOtherInfoTab, {
    ContactFormOtherInfoTabMain,
    ContactFormOtherInfoTabSide,
} from '../../Components/ContactFormOtherInfoTab/ContactFormOtherInfoTab'
import ContactProfileFormTab from '../../Components/ContactProfileFormTab'
import DocumentFormTab from '../../Components/DocumentFormTab'
import {
    useContactGeneralFormConfig,
    useContactGeneralFormSelectOptions,
    useContactOtherInfoFormConfig,
    useContactOtherInfoFormSelectOptions,
    useContactProfileFormConfig,
    useContactProfileFormSelectOptions,
} from '../../Components/Hooks/FormHooks'
import useContactsFormTabs from '../../Components/Hooks/useContactsTabs'
import FormValidationErrorModal from '../../Components/Modals/FormValidationErrorModal'
import {
    clearContactProfile,
    createContactProfile,
    fetchContactProfile,
    selectContactProfileDetails,
    updateContactProfile,
} from '../../store/contactProfiles'
import { fetchContact, selectContact, selectContactFetchingDetails, updateContact } from '../../store/contacts'

const ContactEdit = () => {
    const { t } = useTranslation()
    const history = useHistory()
    const location = useLocation()
    const tabs = useContactsFormTabs(true)
    const dispatch = useDispatch()
    const [activeTab, setActiveTab] = useState(null)
    let { id } = useParams()
    const [contactProfileId, setContactProfileId] = useState(null)
    const [propertyTypeValue, setPropertyTypeValue] = useState('')
    const [propertyServiceValue, setPropertyServiceValue] = useState('')
    let { contact, fetchingDetails, contactProfile } = useSelector((state) => ({
        contact: selectContact(state),
        contactProfile: selectContactProfileDetails(state),
        fetchingDetails: selectContactFetchingDetails(state),
    }))
    const { generalInputs, generalValidationConfig } = useContactGeneralFormConfig(contact)
    const { otherInfoInputs, otherInfoValidationConfig } = useContactOtherInfoFormConfig(contact)
    const { profileInputs, profileValidationConfig } = useContactProfileFormConfig(contactProfile, propertyTypeValue, propertyServiceValue)
    const formSelectOptions = useContactGeneralFormSelectOptions()
    const contactOtherInfoSelectOptions = useContactOtherInfoFormSelectOptions()
    const contactProfileSelectOptions = useContactProfileFormSelectOptions()

    useEffect(() => {
        dispatch(fetchContact({ id }))

        return () => {
            // console.log('cleanup')
            dispatch(clearContactProfile())
        }
    }, [dispatch, id])

    // console.log('location', location)
    useEffect(() => {
        if (tabs.length > 0) {
            if (location?.state?.activeTab) {
                setActiveTab(location?.state?.activeTab)
            } else {
                setActiveTab(tabs[0].name)
            }
        }

        // console.log('location', location)
        if (location?.state && location?.state?.profileId) {
            dispatch(
                fetchContactProfile({
                    contactId: id,
                    id: location.state.profileId,
                })
            )
            setContactProfileId(location.state.profileId)
        }
    }, [tabs, location])
    const handleTabChange = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab)
        }
    }
    const { isValid, isSubmitting, isValidating, ...validation } = useFormik({
        enableReinitialize: true,
        initialValues: {
            general: generalValidationConfig?.initialValues ?? {},
            otherInfo: otherInfoValidationConfig?.initialValues ?? {},
        },
        validationSchema: Yup.object({
            general: generalValidationConfig?.validationSchema ?? Yup.object({}),
            otherInfo: otherInfoValidationConfig?.validationSchema ?? Yup.object({}),
        }),
        onSubmit: (values) => {
            // console.log('submiting')
            // console.log('values', values)
            const submitValues = {
                ...generalValidationConfig.prepareModelForSubmit(values.general),
                ...otherInfoValidationConfig.prepareModelForSubmit(values.otherInfo),
                id,
            }
            // console.log('submit values', submitValues)

            // console.log(,)
            // console.log(values?.price_from?.replace(/\./g,' '))

            dispatch(
                updateContact(submitValues, (response) => {
                    if (response?.submitStatus === SUBMIT_RESPONSE_STATUS.SUCCESS) {
                        // console.log('response', response)
                        // commented because of ticket [393kMYtG]
                        // history.goBack();
                    }
                })
            )
        },
    })

    const profileValidation = useFormik({
        enableReinitialize: true,
        initialValues: {
            profile: profileValidationConfig?.initialValues ?? {},
        },
        validationSchema: Yup.object({
            profile: profileValidationConfig?.validationSchema ?? Yup.object({}),
        }),
        onSubmit: (values) => {
            const profileValues = {
                ...profileValidationConfig.prepareModelForSubmit(values.profile),
                contactId: id,
                contact_id: id,
            }

            if (location?.state?.profileId || profileValues.id) {
                dispatch(updateContactProfile(profileValues))
            } else {
                if (profileValues?.property_type_id && profileValues.contact_profile_service_id) {
                    delete profileValues.id
                    dispatch(
                        createContactProfile(profileValues, (response) => {
                            if (response?.submitStatus === SUBMIT_RESPONSE_STATUS.SUCCESS) {
                                profileValidation.resetForm()
                            }
                        })
                    )
                }
            }
        },
    })

    const propertyTypeId = getIn(profileValidation?.values?.profile, contactProfileFields.PROPERTY_TYPE_ID)
    const propertyServiceId = getIn(profileValidation?.values?.profile, contactProfileFields.CONTACT_PROFILE_SERVICE_ID)

    useEffect(() => {
        if (propertyTypeId !== propertyTypeValue) {
            setPropertyTypeValue(propertyTypeId)
        }
    }, [propertyTypeId, propertyTypeValue])

    useEffect(() => {
        if (propertyServiceId !== propertyServiceValue) {
            setPropertyServiceValue(propertyServiceId)
        }
    }, [propertyServiceId, propertyServiceValue])

    useEffect(() => {
        if (!isValid && !isValidating && isSubmitting) {
            setIsErrorModalActive(true)
        }
    }, [isValid, isValidating, isSubmitting])

    const renderFormButtons = () => <FormActionButtons disabled={false} hasCancel={true} cancelAction={() => history.push(`/contacts/${id}`)} />
    document.title = `${t('edit')} ${contact?.contactPerson?.firstName ?? ''} ${contact?.contactPerson?.lastName ?? ''} | ${t('app.title')}`

    const [isErrorModalActive, setIsErrorModalActive] = useState(false)

    const toggleErrorModal = () => {
        setIsErrorModalActive(!isErrorModalActive)
    }

    if (fetchingDetails) {
        return (
            <div className="page-content">
                <Container fluid>
                    <Loader />
                </Container>
            </div>
        )
    }

    return (
        <div className="page-content">
            <Container fluid>
                <FormikProvider value={validation}>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault()
                            validation.handleSubmit()
                            profileValidation.handleSubmit()
                            return false
                        }}
                        className="needs-validation"
                        id="real_estate_form"
                    >
                        <PageTitle
                            title={`Uredi > ${contact?.[contactResponseFields.CONTACT_PERSON]?.[contactResponseFields.FIRST_NAME]} ${
                                contact?.[contactResponseFields.CONTACT_PERSON]?.[contactResponseFields.LAST_NAME]
                            }`}
                        >
                            {renderFormButtons()}
                        </PageTitle>

                        <TabContent activeTab={activeTab} className="text-muted">
                            <TabPane tabId="general">
                                <ContactFormGeneralTab
                                    tabs={tabs}
                                    activeTab={activeTab}
                                    handleTabChange={handleTabChange}
                                    inputs={generalInputs}
                                    subform="general"
                                    formik={validation}
                                    selectOptions={formSelectOptions}
                                >
                                    <ContactFormGeneralTabSide />
                                    <ContactFormGeneralTabMain />
                                </ContactFormGeneralTab>
                            </TabPane>
                            <TabPane tabId="otherInfo">
                                <ContactFormOtherInfoTab
                                    tabs={tabs}
                                    activeTab={activeTab}
                                    handleTabChange={handleTabChange}
                                    inputs={otherInfoInputs}
                                    subform="otherInfo"
                                    formik={validation}
                                    selectOptions={contactOtherInfoSelectOptions}
                                >
                                    <ContactFormOtherInfoTabSide />
                                    <ContactFormOtherInfoTabMain />
                                </ContactFormOtherInfoTab>
                            </TabPane>
                            <TabPane tabId="contactProfile">
                                <ContactProfileFormTab
                                    tabs={tabs}
                                    activeTab={activeTab}
                                    handleTabChange={handleTabChange}
                                    inputs={profileInputs}
                                    subform="profile"
                                    formik={profileValidation}
                                    selectOptions={contactProfileSelectOptions}
                                    contactType={CONTACT_TYPE.PERSON}
                                />
                            </TabPane>
                            {activeTab === 'documents' && (
                                <TabPane tabId="documents">
                                    <DocumentFormTab
                                        tabs={tabs}
                                        activeTab={activeTab}
                                        handleTabChange={handleTabChange}
                                        documentModule="contact"
                                        contactType={CONTACT_TYPE.PERSON}
                                        parentId={id}
                                    />
                                </TabPane>
                            )}
                        </TabContent>
                    </Form>
                </FormikProvider>
            </Container>
            {isErrorModalActive && (
                <FormValidationErrorModal
                    validation={validation}
                    inputs={[generalInputs, otherInfoInputs, profileInputs]}
                    onClose={() => toggleErrorModal(false)}
                />
            )}
        </div>
    )
}

export default ContactEdit
