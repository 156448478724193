import React, { useMemo } from 'react'
import TableContainer from '../../Components/Table/TableContainer'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAgentRevenue, selectReports, selectReportsFetching } from '../../store/reports'
import { findPropertySupplySourceByName, renderPropertySupplySource, totalPropertySupplySourceByName } from '../../utils/report'
import { dataFallback } from './constants'

const sourceTypes = ['ESB', 'WO', 'Tržište', 'Ostalo']

const agentRevenueSourceColumn = (name) => ({
    Header: name,
    id: name,
    accessor: ({ propertySupplySource }) => {
        const source = findPropertySupplySourceByName(propertySupplySource, name)

        if (!source) return dataFallback

        return renderPropertySupplySource(source)
    },
    filterable: false,
})

export const AgentRevenueBySourceReport = ({ searchCriteria }) => {
    const dispatch = useDispatch()

    const { reports, reportsFetching } = useSelector((state) => ({
        reports: selectReports(state),
        reportsFetching: selectReportsFetching(state),
    }))

    const columns = [
        {
            Header: 'Agent',
            id: 'agent',
            accessor: ({ agent }) => `${agent.firstName} ${agent.lastName}`,
            filterable: false,
        },
        ...sourceTypes.map(agentRevenueSourceColumn),
        {
            Header: 'Ukupno',
            id: 'total_by_source',
            accessor: ({ propertySupplySource }) => {
                const totalPropertySupplySource = propertySupplySource?.reduce((total, curr) => {
                    const { name, ...rest } = curr

                    const values = Object.values(rest)

                    if (!total) return values

                    return total.map((totalItem, i) => totalItem + values[i])
                }, null)

                if (!totalPropertySupplySource) return dataFallback

                const render = renderPropertySupplySource(totalPropertySupplySource)

                return <strong>{render}</strong>
            },
            filterable: false,
        },
    ]

    const handleDataFetch = (page, limit, order, criteria) => {
        dispatch(fetchAgentRevenue({ page, limit, order, criteria }))
    }

    const tableFooter = useMemo(() => {
        const sourceTypeTotals = sourceTypes.map((sourceType) => totalPropertySupplySourceByName(reports, sourceType))

        const total = sourceTypeTotals.reduce((acc, curr) => acc + curr)

        return (
            <tr>
                <td>Ukupno</td>
                {sourceTypeTotals.map((t, i) => (
                    <td key={i}>{renderPropertySupplySource(t)}</td>
                ))}
                <td>{renderPropertySupplySource(total)}</td>
            </tr>
        )
    }, [reports])

    return (
        <TableContainer
            columns={columns}
            data={reports || []}
            className="custom-header-css"
            theadClass={'dmn-table-header'}
            isLoading={reportsFetching}
            handleDataFetch={handleDataFetch}
            searchCriteria={searchCriteria}
            tableFooter={tableFooter}
            hidePagination
        />
    )
}
