import { setAllowedAgents } from './allowedAgents'
import { setAllowedPhotos } from './allowedPhotos'
import { setAllowedPermissionTabs } from './allowedTabs'
import { setChangeAgent } from './changeAgent'
import { setEditData } from './editData'
import { setGoogleMaps } from './googleMaps'
import { setModulePermissions } from './module'
import { setNavigation } from './navigation'
import { setPropertyStatus } from './propertyStatus'
import { setViewData } from './viewData'

const setEditPermissions = (isAgentOwnership, isAgentOffice, actionName, module, level, viewLevel) => {
    const isAffectedModule = ['contacts', 'companies'].includes(module)
    
    const permissions = {
        read_only: ['view'],
        read_write_delete: ['view', 'edit', 'delete'],
    }

    const allowedActions = permissions[level]

    if (isAgentOwnership) {
        return true
    }

    if (!allowedActions) {
        console.log(`Non existing edit level: ${level}`)
        return false
    }

    const hasEditPermissions = allowedActions.includes(actionName)

    // special case of edit permissions is applied for modules Contacts & Companies
    // since they are not filtered like Projects & Properties
    const contactsCompaniesEditPermissions = {
        all: hasEditPermissions,
        office: hasEditPermissions && isAgentOffice,
        own: false,
    }

    const hasContactsCompaniesEditPermissions = contactsCompaniesEditPermissions[viewLevel]

    return isAffectedModule ? hasContactsCompaniesEditPermissions : hasEditPermissions
}

// applied only for Contacts/Companies modules
const checkViewLevel = (permissionsArray, module) => {
    if (!module) return

    // module comes in plural
    const moduleMapping = {
        contacts: 'contact',
        companies: 'company'
    }

    const formattedModuleName = moduleMapping[module] 

    const viewPermissions = ['module.contact.view', 'module.company.view']
    const permission = viewPermissions.find((p) => p.includes(formattedModuleName))

    return checkPermissionLevel(permissionsArray, permission)
}

export const checkPermissionLevel = (permissionsArray, permissionName) => {
    const permission = permissionsArray?.find((obj) => obj.permission.token === permissionName)
    return permission ? permission.permissionLevel.token : null
}

export const isPermissionAll = (permissionsArray, permissionName) => {
    const level = checkPermissionLevel(permissionsArray, permissionName)
    return level === 'all'
}

export const generatePermissionsData = (permissions, agentPermissions, isAgentOwnership, isAgentOffice) => {
    return permissions.reduce((acc, permission) => {
        const { ruleName, dataName } = permission
        const result = setPermissions(agentPermissions, ruleName, isAgentOwnership, isAgentOffice)

        acc = { ...acc, [dataName]: result }
        return acc
    }, {})
}

export const setPermissions = (agentPermissions, permission, module, isAgentOwnership, isAgentOffice, actionName = '') => {
    if (!agentPermissions) return

    const level = checkPermissionLevel(agentPermissions, permission)
    const viewLevel = checkViewLevel(agentPermissions, module)

    switch (level) {
        case 'all':
        case 'yes':
            return true
        case 'office':
            return isAgentOffice
        case 'no':
            return false
        case 'own':
            return isAgentOwnership
        case 'read_only':
        case 'read_write_delete':
            return setEditPermissions(isAgentOwnership, isAgentOffice, actionName, module, level, viewLevel)
        default:
            // uncomment line below if something is breaking to get more info
            // console.error(`Non existing permission level: ${level}`)
            return null
    }
}

export {
    setAllowedAgents,
    setAllowedPermissionTabs,
    setAllowedPhotos,
    setChangeAgent,
    setEditData,
    setGoogleMaps,
    setModulePermissions,
    setNavigation,
    setPropertyStatus,
    setViewData,
}
