import { handleActions } from "redux-actions";
import {fetchDetailSearchLocationsCitySuccess, fetchDetailSearchLocationsCountrySuccess, fetchDetailSearchLocationsCountySuccess, fetchDetailSearchLocationsIslandSuccess, fetchDetailSearchLocationsQuarterSuccess, fetchDetailSearchLocationsWiderAreaSuccess, fetchDetailSearchPropertySubtypesSuccess, fetchDetailSearchPropertyTypesSuccess, fetchDetailSearchPropertyValuesDescriptionsSuccess, fetchDetailSearchPropertyValuesEquipmentSuccess, fetchDetailSearchPropertyValuesFloorSuccess, fetchDetailSearchPropertyValuesSpaceSuccess, fetchDetailSearchPropertyValuesUtilitySuccess } from "./actions";


export const initialState = { 
    country: {},
    county: {},
    additionalCounty: {},
    city: {},
    additionalCity: {},
    widerarea: {},
    additionalWiderarea: {},
    quarter: {},
    additionalQuarter: {},
    island: {},
    additionalIsland: {},

    propertyTypes: {},
    propertySubTypes: {},
    propertyDescriptions: {},
    propertyUtility: {},
    propertyEquipment: {},
    propertySpace: {},
    propertyFloor: {},
}

export default handleActions(
    {
        [fetchDetailSearchLocationsCountrySuccess]: (state, { payload }) => {
            return {
            ...state,
            country: payload.reduce(
              (dict, item) => ({
                ...dict,
                [item.id]: item
              }),
              {}
            )
          }},
          [fetchDetailSearchLocationsCountySuccess]: (state, { payload }) => {
            const { isAdditionalOptions, data } = payload;
            
            const countyData = data.reduce(
              (dict, item) => ({
                ...dict,
                [item.id]: item
              }),
              {}
            );

            if (isAdditionalOptions) {
              return {
                ...state,
                additionalCounty: countyData
              }
            }
            return {
              ...state,
              county: countyData    
            }
          },
          [fetchDetailSearchLocationsCitySuccess]: (state, { payload }) => {
            const { isAdditionalOptions, data } = payload;
            
            const mappedData = data.reduce(
              (dict, item) => ({
                ...dict,
                [item.id]: item
              }),
              {}
            );

            if (isAdditionalOptions) {
              return {
                ...state,
                additionalCity: mappedData
              }
            }

            return {
            ...state,
            city: mappedData
          }},
          [fetchDetailSearchLocationsWiderAreaSuccess]: (state, { payload }) => {
            const { isAdditionalOptions, data } = payload;
            
            const mappedData = data.reduce(
              (dict, item) => ({
                ...dict,
                [item.id]: item
              }),
              {}
            );

            if (isAdditionalOptions) {
              return {
                ...state,
                additionalWiderarea: mappedData
              }
            }
            return {
            ...state,
            widerarea: mappedData
          }},
          [fetchDetailSearchLocationsQuarterSuccess]: (state, { payload }) => {
            const { isAdditionalOptions, data } = payload;
            
            const mappedData = data.reduce(
              (dict, item) => ({
                ...dict,
                [item.id]: item
              }),
              {}
            );

            if (isAdditionalOptions) {
              return {
                ...state,
                additionalQuarter: mappedData
              }
            }
            return {
            ...state,
            quarter:  mappedData
          }},
          [fetchDetailSearchLocationsIslandSuccess]: (state, { payload }) => {
            const { isAdditionalOptions, data } = payload;
            
            const mappedData = data.reduce(
              (dict, item) => ({
                ...dict,
                [item.id]: item
              }),
              {}
            );

            if (isAdditionalOptions) {
              return {
                ...state,
                additionalIsland: mappedData
              }
            }
            return {
            ...state,
            island: mappedData
          }},
          [fetchDetailSearchPropertySubtypesSuccess]: (state, { payload }) => {
            return {
            ...state,
            propertySubTypes: payload.reduce(
              (dict, item) => ({
                ...dict,
                [item.id]: item
              }),
              {}
            )
          }},
          [fetchDetailSearchPropertyValuesDescriptionsSuccess]: (state, { payload }) => ({
            ...state,
            propertyDescriptions: payload.reduce(
              (dict, item) => ({
                ...dict,
                [item.id]: item
              }),
              {}
            )
          }),
          [fetchDetailSearchPropertyValuesUtilitySuccess]: (state, { payload }) => ({
            ...state,
            propertyUtility: payload.reduce(
              (dict, item) => ({
                ...dict,
                [item.id]: item
              }),
              {}
            )
          }),
          [fetchDetailSearchPropertyValuesEquipmentSuccess]: (state, { payload }) => ({
            ...state,
            propertyEquipment: payload.reduce(
              (dict, item) => ({
                ...dict,
                [item.id]: item
              }),
              {}
            )
          }),
          [fetchDetailSearchPropertyValuesSpaceSuccess]: (state, { payload }) => ({
            ...state,
            propertySpace: payload.reduce(
              (dict, item) => ({
                ...dict,
                [item.id]: item
              }),
              {}
            )
          }),
          [fetchDetailSearchPropertyTypesSuccess]: (state, { payload }) => {
            return {
            ...state,
            propertyTypes: payload.reduce(
              (dict, item) => ({
                ...dict,
                [item.id]: item
              }),
              {}
            )
          }},
          [fetchDetailSearchPropertyValuesFloorSuccess]: (state, { payload }) => ({
            ...state,
            propertyFloor: payload.reduce(
              (dict, item) => ({
                ...dict,
                [item.id]: item
              }),
              {}
            )
          }),
    },
    initialState);