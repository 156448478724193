import { useState, useEffect } from 'react'
import FormValidationErrorModal from '../../../../Components/Modals/FormValidationErrorModal'

const ErrorModal = ({ formik }) => {
    const [isErrorModalActive, setIsErrorModalActive] = useState(false)
    const [modalErrors, setModalErrors] = useState()

    useEffect(() => {
        if (!formik.isValid && !formik.isValidating && formik.isSubmitting) {
            setModalErrors(
                Object.keys(formik.errors).map((key) => {
                    return <li key={key}>{formik.errors[key]?.split(';')[1]}</li>
                })
            )
            setIsErrorModalActive(true)
        }
    }, [formik.isValidating, formik.isSubmitting])

    const toggleErrorModal = () => {
        setIsErrorModalActive(!isErrorModalActive)
    }

    return isErrorModalActive && <FormValidationErrorModal modalErrors={modalErrors} validation={formik} onClose={() => toggleErrorModal(false)} />
}

export default ErrorModal
