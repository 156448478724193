import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Loader from '../../Loader'
import SearchResultItems from './SearchResultItems'

const SearchResult = (props) => {
    const { searchResult, handleShowAll, linkName, currency, areaUnit } = props
    const { t } = useTranslation()

    return searchResult.isFetching ? (
        <div className="p-1">
            <Loader />
        </div>
    ) : searchResult?.data?.count > 0 ? (
        <>
            <div className="dropdown-header">
                <h6 className="text-overflow text-secondary fs-12 fw-medium mb-0 text-uppercase">{t(`app.common.${linkName}`)}</h6>
            </div>
            <div className="notification-list">
                {searchResult?.data?.items?.map((item) => (
                    <SearchResultItems
                        key={item.id}
                        id={item?.id}
                        updated={item?.updated}
                        contactCompany={item?.contactCompany}
                        contactPerson={item?.contactPerson}
                        projectTitle={item?.projectTitle}
                        price={item?.price}
                        area={item?.area}
                        propertyType={item?.propertyType}
                        linkName={linkName}
                        currency={currency}
                        areaUnit={areaUnit}
                        propertyStatus={item.propertyStatus}
                    />
                ))}

                <div className="text-end pt-1 pb-1 px-4">
                    <Link to={`/${linkName}`} onClick={handleShowAll} className="text-add">
                        <i className="mdi mdi-plus-circle fs-17"></i> {t('quickSearch.findAllButton')}
                    </Link>
                </div>
            </div>
        </>
    ) : null
}

export default SearchResult
