import classNames from 'classnames';
import React from 'react'

const SortingIcon = ({ sort }) => {
    const iconWrapperClassNames = classNames('dmn-sorting-icon', 'ms-2');

    const ascendingIconClassNames = classNames('bx', 'bxs-up-arrow', {
        active: sort === 'asc'
    });
    const descendingIconClassNames = classNames('bx', 'bxs-down-arrow', {
        active: sort === 'desc'
    });

    return (
        <span className={iconWrapperClassNames}>
            <i className={ascendingIconClassNames} />
            <i className={descendingIconClassNames} />
        </span>
    );
}

export default SortingIcon;
