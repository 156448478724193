import { Col } from "reactstrap";

const FormSideContainer = ({children, ...props}) => {
    return (
        <Col sm={12} xxl={3} {...props}>
            {children}
        </Col>
    )
}

export default FormSideContainer;