import { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { Card, CardBody, CardHeader, Col } from 'reactstrap'
import FormActionButtons from './FormActionButtons'
import FormTabNavigation from './FormTabNavigation'

const FormMainContainer = ({ children, tabs, activeTab, handleTabChange, formik, ...props }) => {
    const history = useHistory()
    const location = useLocation()
    const { id } = useParams()
    const [cancelRoute, setCancelRoute] = useState('projects')

    useEffect(() => {
        if (location.pathname.includes('contacts')) setCancelRoute(id ? `contacts/${id}` : 'contacts')
        if (location.pathname.includes('properties')) setCancelRoute(id ? `properties/${id}` : 'properties')
        if (location.pathname.includes('companies')) setCancelRoute(id ? `companies/${id}` : 'companies')
        if (location.pathname.includes('projects')) setCancelRoute(id ? `projects/${id}` : 'projects')
    }, [location, id])

    return (
        <Col sm={12} xxl={9}>
            <Card>
                {tabs && (
                    <CardHeader>
                        <FormTabNavigation tabs={tabs} activeTab={activeTab} handleTabChange={handleTabChange} formik={formik} />
                    </CardHeader>
                )}

                <CardBody>{children}</CardBody>
            </Card>
            <FormActionButtons disabled={false} hasCancel={true} cancelAction={() => history.push(`/${cancelRoute}`)} />
        </Col>
    )
}

export default FormMainContainer
