import { put, takeEvery, takeLatest } from 'redux-saga/effects';
import QueryString from "qs";
import { apiRequest } from '../api';
import { fetchLocationsAvailableEntities, fetchLocationsAvailableEntitiesFailure, fetchLocationsAvailableEntitiesRequest, fetchLocationsAvailableEntitiesSuccess, fetchLocationsCity, fetchLocationsWiderArea, fetchLocationsWiderAreaFailure, fetchLocationsWiderAreaRequest, fetchLocationsWiderAreaSuccess, fetchLocationsCityFailure, fetchLocationsCityRequest, fetchLocationsCitySuccess, fetchLocationsCountry, fetchLocationsCountryFailure, fetchLocationsCountryRequest, fetchLocationsCountrySuccess, fetchLocationsCounty, fetchLocationsCountyFailure, fetchLocationsCountyRequest, fetchLocationsCountySuccess, fetchLocationsQuarterRequest, fetchLocationsQuarterSuccess, fetchLocationsQuarterFailure, fetchLocationsQuarter, fetchLocationsIsland, fetchLocationsIslandRequest, fetchLocationsIslandSuccess, fetchLocationsIslandFailure } from './actions';


export function* fetchLocationsAvailableEntitiesSaga() {
  yield put(fetchLocationsAvailableEntitiesRequest());
  try {
    const response = yield apiRequest('v1/core/locations/availableEntities', {
      method: 'GET'
    });
    yield put(fetchLocationsAvailableEntitiesSuccess(response.data));
  } catch (error) {
    yield put(fetchLocationsAvailableEntitiesFailure(error));
  }
}

export function* fetchLocationsCountrySaga() {
  yield put(fetchLocationsCountryRequest());
  try {
    const response = yield apiRequest('v1/core/locations/values/country', {
      method: 'GET'
    });
    yield put(fetchLocationsCountrySuccess(response.data));
  } catch (error) {
    yield put(fetchLocationsCountryFailure(error));
  }
}

export function* fetchLocationsCountySaga({ payload }) {
  yield put(fetchLocationsCountyRequest());
  let params;
  if (payload) {
    params = {
      filter: {
        country: payload
      }
    }
  }
  try {
    const response = yield apiRequest(`v1/core/locations/values/county`, {
      method: 'GET',
      params: params,
      paramsSerializer: params => {
        return QueryString.stringify(params)
      }
    });
    yield put(fetchLocationsCountySuccess(response.data));
  } catch (error) {
    yield put(fetchLocationsCountyFailure(error));
  }
}

export function* fetchLocationsCitySaga({ payload }) {
  yield put(fetchLocationsCityRequest());
  let params;
  if (payload) {
    params = {
      filter: {
        county: payload
      }
    }
  }
  try {
    const response = yield apiRequest(`v1/core/locations/values/city`, {
      method: 'GET',
      params: params,
      paramsSerializer: params => {
        return QueryString.stringify(params)
      }
    });
    yield put(fetchLocationsCitySuccess(response.data));
  } catch (error) {
    yield put(fetchLocationsCityFailure(error));
  }
}

export function* fetchLocationsIslandSaga({ payload }) {
  yield put(fetchLocationsIslandRequest());
  let params;
  if (payload) {
    params = {
      filter: {
        country: payload
      }
    }
  }
  try {
    const response = yield apiRequest(`v1/core/locations/values/island`, {
      method: 'GET',
      params: params,
      paramsSerializer: params => {
        return QueryString.stringify(params)
      }
    });
    yield put(fetchLocationsIslandSuccess(response.data));
  } catch (error) {
    yield put(fetchLocationsIslandFailure(error));
  }
}

export function* fetchLocationsWiderAreaSaga({ payload }) {
  yield put(fetchLocationsWiderAreaRequest());
  let params;
  if (payload) {
    params = {
      filter: {
        city: payload
      }
    }
  }
  try {
    const response = yield apiRequest(`v1/core/locations/values/widerArea`, {
      method: 'GET',
      params: params,
      paramsSerializer: params => {
        return QueryString.stringify(params)
      }
    });
    yield put(fetchLocationsWiderAreaSuccess(response.data));
  } catch (error) {
    yield put(fetchLocationsWiderAreaFailure(error));
  }
}

export function* fetchLocationsQuarterSaga({ payload }) {
  yield put(fetchLocationsQuarterRequest());
  let params;
  if (payload) {
    params = {
      filter: {
        widerArea: payload
      }
    }
  }
  try {
    const response = yield apiRequest(`v1/core/locations/values/quarter`, {
      method: 'GET',
      params: params,
      paramsSerializer: params => {
        return QueryString.stringify(params)
      }
    });
    yield put(fetchLocationsQuarterSuccess(response.data));
  } catch (error) {
    yield put(fetchLocationsQuarterFailure(error));
  }
}

export default function* actionWatcher() {
  yield takeLatest(`${fetchLocationsAvailableEntities}`, fetchLocationsAvailableEntitiesSaga);
  yield takeLatest(`${fetchLocationsCountry}`, fetchLocationsCountrySaga);
  yield takeLatest(`${fetchLocationsCounty}`, fetchLocationsCountySaga);
  yield takeLatest(`${fetchLocationsCity}`, fetchLocationsCitySaga);
  yield takeLatest(`${fetchLocationsWiderArea}`, fetchLocationsWiderAreaSaga);
  yield takeLatest(`${fetchLocationsQuarter}`, fetchLocationsQuarterSaga);
  yield takeLatest(`${fetchLocationsIsland}`, fetchLocationsIslandSaga);
}
