import { FormikProvider, useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button, Container, Form, Modal, ModalBody, ModalHeader, TabContent, TabPane } from 'reactstrap'
import * as Yup from 'yup'
import { SUBMIT_RESPONSE_STATUS } from '../../common/constants'
import FormActionButtons from '../../Components/Common/FormActionButtons'
import PageTitle from '../../Components/Common/PageTitle'
import { useProjectDescriptionFormConfig, useProjectGeneralFormConfig, useProjectGeneralFormSelectOptions } from '../../Components/Hooks/FormHooks'
import { useProjectFormTabs } from '../../Components/Hooks/useProjectTabs'
import FormValidationErrorModal from '../../Components/Modals/FormValidationErrorModal'
import ProjectFormDescriptionTab, {
    ProjectFormDescriptionTabMain,
    ProjectFormDescriptionTabSide,
} from '../../Components/ProjectFormDescriptionTab/ProjectFormDescriptionTab'
import ProjectFormGeneralTab, {
    ProjectFormGeneralTabMain,
    ProjectFormGeneralTabSide,
} from '../../Components/ProjectFormGeneralTab/ProjectFormGeneralTab'
import { clearGroundPlans } from '../../store/groundplans'
import { clearPhotos } from '../../store/photos'
import { createProject } from '../../store/projects'
import { changeTranslationObject } from '../../utils/general'

const ProjectCreate = () => {
    let id = 0 // TODO: this id is needed for useProjectGeneralFormConfig ???
    const history = useHistory()
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const tabs = useProjectFormTabs(false)
    const { generalInputs, generalValidationConfig } = useProjectGeneralFormConfig(id)
    const formSelectOptions = useProjectGeneralFormSelectOptions()
    const { descriptionInputs, descriptionValidationConfig } = useProjectDescriptionFormConfig(null)

    const [activeTab, setActiveTab] = useState(null)

    useEffect(() => {
        if (tabs.length > 0) {
            setActiveTab(tabs[0].name)
        }
    }, [tabs])

    const handleTabChange = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab)
        }
    }

    const { isValid, isSubmitting, isValidating, ...validation } = useFormik({
        enableReinitialize: true,
        initialValues: {
            general: generalValidationConfig.initialValues ?? {},
            description: descriptionValidationConfig.initialValues ?? {},
            // description: descriptionInitialValues
        },
        validationSchema: Yup.object({
            general: generalValidationConfig.validationSchema ?? Yup.object({}),
            description: descriptionValidationConfig.validationSchema ?? Yup.object({}),
            // description: descriptionSchema
        }),
        onSubmit: (values) => {
            const generalValues = generalValidationConfig.prepareModelForSubmit(values.general)

            const translations = changeTranslationObject(values.description, 'project_title', 'project_description', 'project_offer_description')

            const submitValues = {
                ...generalValues,
                ...values.description,
                translations,
            }

            delete submitValues.id

            dispatch(
                createProject(submitValues, (response) => {
                    if (response?.submitStatus === SUBMIT_RESPONSE_STATUS.SUCCESS) {
                        history.push(`/projects/${response.data.id}/edit`)
                    }
                })
            )

            // clear previously fetched photos from the store
            dispatch(clearPhotos())
            dispatch(clearGroundPlans())
        },
    })

    useEffect(() => {
        if (!isValid && !isValidating && isSubmitting) {
            setIsErrorModalActive(true)
        }
    }, [isValid, isValidating, isSubmitting])

    const renderFormButtons = () => <FormActionButtons disabled={false} hasCancel={true} cancelAction={() => history.push('/projects')} />

    const [isErrorModalActive, setIsErrorModalActive] = useState(false)

    const toggleErrorModal = () => {
        setIsErrorModalActive(!isErrorModalActive)
    }

    return (
        <div className="page-content">
            <Container fluid>
                <FormikProvider value={validation}>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault()
                            validation.handleSubmit()
                            return false
                        }}
                        className="needs-validation"
                        id="real_estate_form"
                    >
                        <PageTitle title={t('page.newProject')}>{renderFormButtons()}</PageTitle>

                        <TabContent activeTab={activeTab} className="text-muted">
                            <TabPane tabId="general">
                                <ProjectFormGeneralTab
                                    tabs={tabs}
                                    activeTab={activeTab}
                                    handleTabChange={handleTabChange}
                                    inputs={generalInputs}
                                    subform="general"
                                    formik={validation}
                                    selectOptions={formSelectOptions}
                                >
                                    <ProjectFormGeneralTabSide />
                                    <ProjectFormGeneralTabMain />
                                </ProjectFormGeneralTab>
                            </TabPane>
                            <TabPane tabId="description">
                                <ProjectFormDescriptionTab
                                    tabs={tabs}
                                    activeTab={activeTab}
                                    handleTabChange={handleTabChange}
                                    inputs={descriptionInputs}
                                    subform="description"
                                    formik={validation}
                                >
                                    <ProjectFormDescriptionTabSide />
                                    <ProjectFormDescriptionTabMain />
                                </ProjectFormDescriptionTab>
                            </TabPane>
                        </TabContent>
                    </Form>
                </FormikProvider>
            </Container>
            {isErrorModalActive && (
                <FormValidationErrorModal
                    validation={validation}
                    inputs={[generalInputs, descriptionInputs]}
                    onClose={() => toggleErrorModal(false)}
                />
            )}
        </div>
    )
}

export default ProjectCreate
