import { createStore, applyMiddleware, compose } from "redux";
import api from './middleware/api';
import createSagaMiddleware from "redux-saga";
import rootReducer from "./reducers";
import rootSaga from "./sagas";

const sagaMiddleware = createSagaMiddleware();
const middlewares = [api, sagaMiddleware];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;


const appReducer = (state, action) => {
  
  if (action.type === 'AUTH/DESTROY') {
    state = undefined
  }
  
  return rootReducer(state, action)
}

export let store

export function configureStore(initialState) {

  store = createStore(
      appReducer,
      initialState,
      composeEnhancers(
          applyMiddleware(...middlewares)
      ),
  );
  sagaMiddleware.run(rootSaga);
  return store;
}