import React from 'react'
import { Card, CardBody } from 'reactstrap'
import ViewDataList from './ViewDataList'

const ViewDataCard = ({ dataList }) => {
    const viewDataList = <ViewDataList dataList={dataList}/>

    if (!viewDataList) return null

    return (
        <Card>
            <CardBody>{viewDataList}</CardBody>
        </Card>
    )
}

export default ViewDataCard
