import { put, takeEvery } from "redux-saga/effects";
import { apiRequest } from "../api";
import { fetchEntityValues } from "../entityValues";
import { fetchPropertyAvailableEntities, fetchPropertyAvailableEntitiesFailure, fetchPropertyAvailableEntitiesRequest, fetchPropertyAvailableEntitiesSuccess } from "./actions";

export function* fetchPropertyAvailableEntitiesSaga() {
    yield put(fetchPropertyAvailableEntitiesRequest());
    try {
        const response = yield apiRequest('v1/property/availableEntities', {
        method: 'GET'
        });
    //   console.log('AvailableEntities', response)
        yield put(fetchPropertyAvailableEntitiesSuccess(response));

        // yield put(fetchEntityValues());
    } catch (error) {
        yield put(fetchPropertyAvailableEntitiesFailure(error));
    }
}

export default function* actionWatcher() {
    yield takeEvery(`${fetchPropertyAvailableEntities}`, fetchPropertyAvailableEntitiesSaga);
}