const viewAction = {
    permission: 'view',
    name: 'view',
}

const editAction = {
    permission: 'edit',
    name: 'edit',
}

const deleteAction = {
    permission: 'edit',
    name: 'delete',
}

export const action = {
    view: viewAction,
    edit: editAction,
    delete: deleteAction
}