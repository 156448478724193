import { createAction } from 'redux-actions';

const ENTITY = 'LOCATIONS';
const AVAILABLEENTITIES = 'AVAILABLEENTITIES';
const COUNTRY = 'COUNTRY'; 
const COUNTY = 'COUNTY'; 
const CITY = 'CITY';
const WIDERAREA = 'WIDERAREA';
const QUARTER = 'QUARTER';
const ISLAND = 'ISLAND';
export const stateKey = ENTITY.toLowerCase();

export const fetchLocationsAvailableEntities = createAction(`${ENTITY}_${AVAILABLEENTITIES}/FETCH`);
export const fetchLocationsAvailableEntitiesRequest = createAction(`${ENTITY}_${AVAILABLEENTITIES}/FETCH_REQUEST`);
export const fetchLocationsAvailableEntitiesSuccess = createAction(`${ENTITY}_${AVAILABLEENTITIES}/FETCH_SUCCESS`);
export const fetchLocationsAvailableEntitiesFailure = createAction(`${ENTITY}_${AVAILABLEENTITIES}/FETCH_FAILURE`);

export const fetchLocationsCountry = createAction(`${ENTITY}_${COUNTRY}/FETCH`);
export const fetchLocationsCountryRequest = createAction(`${ENTITY}_${COUNTRY}/FETCH_REQUEST`);
export const fetchLocationsCountrySuccess = createAction(`${ENTITY}_${COUNTRY}/FETCH_SUCCESS`);
export const fetchLocationsCountryFailure = createAction(`${ENTITY}_${COUNTRY}/FETCH_FAILURE`);

export const fetchLocationsCounty = createAction(`${ENTITY}_${COUNTY}/FETCH`);
export const fetchLocationsCountyRequest = createAction(`${ENTITY}_${COUNTY}/FETCH_REQUEST`);
export const fetchLocationsCountySuccess = createAction(`${ENTITY}_${COUNTY}/FETCH_SUCCESS`);
export const fetchLocationsCountyFailure = createAction(`${ENTITY}_${COUNTY}/FETCH_FAILURE`);

export const fetchLocationsCity = createAction(`${ENTITY}_${CITY}/FETCH`);
export const fetchLocationsCityRequest = createAction(`${ENTITY}_${CITY}/FETCH_REQUEST`);
export const fetchLocationsCitySuccess = createAction(`${ENTITY}_${CITY}/FETCH_SUCCESS`);
export const fetchLocationsCityFailure = createAction(`${ENTITY}_${CITY}/FETCH_FAILURE`);

export const fetchLocationsWiderArea = createAction(`${ENTITY}_${WIDERAREA}/FETCH`);
export const fetchLocationsWiderAreaRequest = createAction(`${ENTITY}_${WIDERAREA}/FETCH_REQUEST`);
export const fetchLocationsWiderAreaSuccess = createAction(`${ENTITY}_${WIDERAREA}/FETCH_SUCCESS`);
export const fetchLocationsWiderAreaFailure = createAction(`${ENTITY}_${WIDERAREA}/FETCH_FAILURE`);

export const fetchLocationsQuarter = createAction(`${ENTITY}_${QUARTER}/FETCH`);
export const fetchLocationsQuarterRequest = createAction(`${ENTITY}_${QUARTER}/FETCH_REQUEST`);
export const fetchLocationsQuarterSuccess = createAction(`${ENTITY}_${QUARTER}/FETCH_SUCCESS`);
export const fetchLocationsQuarterFailure = createAction(`${ENTITY}_${QUARTER}/FETCH_FAILURE`);

export const fetchLocationsIsland = createAction(`${ENTITY}_${ISLAND}/FETCH`);
export const fetchLocationsIslandRequest = createAction(`${ENTITY}_${ISLAND}/FETCH_REQUEST`);
export const fetchLocationsIslandSuccess = createAction(`${ENTITY}_${ISLAND}/FETCH_SUCCESS`);
export const fetchLocationsIslandFailure = createAction(`${ENTITY}_${ISLAND}/FETCH_FAILURE`);