import { documentFields } from "./fields"
import * as Yup from "yup";
export const documentValidation = (data) => {
    const isEdit = data?.id ? true : false
    const currentLang = localStorage.getItem('i18nextLng')

    let contactIdValue = '';
    let propertyIdValue = '';
    let projectIdValue = '';
    if (data?.module === "contact") {
        contactIdValue = data?.parent ?? ''
    }

    if (data?.documentModule === "contact") {
        contactIdValue = data?.documentContactId ?? ''
    }

    if (data?.module === "property") {
        propertyIdValue = data?.parent ?? ''
    }
    if (data?.documentModule === "property") {
        propertyIdValue = data?.documentPropertyId ?? ''
    }

    if (data?.module === "project") {
        projectIdValue = data?.parent ?? ''
    }
    if (data?.documentModule === "project") {
        projectIdValue = data?.documentProjectId ?? ''
    }

    return {
        initialValues: {
            ...(isEdit && { id: data?.id ?? '' }),
            ...(isEdit && { file_description: data?.translations[currentLang]?.fileDescription ?? '' }),
            [documentFields.DOCUMENT_PROPRETY_ID]: propertyIdValue,
            [documentFields.DOCUMENT_PROJECT_ID]: projectIdValue,
            [documentFields.DOCUMENT_CONTACT_ID]: contactIdValue,
            [documentFields.DOCUMENT_TYPE]: data?.documentType?.id?.toString() ?? '',
            [documentFields.DOCUMENT_CREATION_DATE]: data?.documentCreationDate ?? '',
            [documentFields.DOCUMENT_VALIDITY_DATE]: data?.documentValidityDate ?? '',
            [documentFields.FILE_SYNC]: data?.fileSync ?? false,
        },
        validationSchema: Yup.object({
        })
    }
}