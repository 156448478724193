import { renderCurrency } from '../../../utils/property'
import { FORM_DIRECTION, REAL_ESTATE_TYPES } from '../../constants'
import responseFields from '../../constants/responseFields'
import i18n from '../../../i18n'
import { companyGeneralFields, companyOtherInfoFields, companyProfileFields } from './fields'
import {
    companyDetailSearchFieldTypes,
    companyGeneralFieldTypes,
    companyOtherInfoFieldTypes,
    companyProfileDetailSearchFieldTypes,
    companyProfileFieldTypes,
} from './fieldTypes'
import { IS_ERSTE } from '../../constants'

export const companyGeneralInputs = () => {
    const inputs = companyGeneralFieldTypes()
    return {
        sideForm: [
            inputs[companyGeneralFields.ID],
            inputs[companyGeneralFields.NAME],
            inputs[companyGeneralFields.FULL_NAME],
            inputs[companyGeneralFields.CONTACT_LEGAL_FORM_ID],
            inputs[companyGeneralFields.TAX_NUMBER],
            inputs[companyGeneralFields.INITIAL_CONTACT_DATE],
            inputs[companyGeneralFields.CONTACT_PRIORITY_ID],
            inputs[companyGeneralFields.DATA_SOURCE_ID],
            IS_ERSTE && inputs[companyGeneralFields.CONTACT_SOURCE_ID],
            IS_ERSTE && inputs[companyGeneralFields.CONTACT_ABOUT],
            inputs[companyGeneralFields.CONTACT_STATUS_ID],
        ],
        mainForm: [
            {
                type: 'fieldArray',
                name: 'contact_phone_group',
                children: [inputs[companyGeneralFields.PHONE_NUMBER], inputs[companyGeneralFields.CONTACT_PHONE_TYPE_ID]],
            },
            {
                type: 'fieldArray',
                name: 'contact_email_group',
                children: [
                    inputs[companyGeneralFields.EMAIL],
                    inputs[companyGeneralFields.CONTACT_EMAIL_TYPE_ID],
                    // inputs[companyGeneralFields.NEWSLETTER_APPROVAL]
                ],
            },
            {
                type: 'array',
                name: 'websiteGroup',
                children: [inputs[companyGeneralFields.WEBSITE]],
            },
            {
                type: 'array',
                name: 'addressGroup',
                children: [inputs[companyGeneralFields.STREET], inputs[companyGeneralFields.STREET_NUMBER]],
            },
            {
                type: 'array',
                name: 'postalCodeGroup',
                children: [inputs[companyGeneralFields.ZIP_CODE], inputs[companyGeneralFields.CITY], inputs[companyGeneralFields.CONTACT_COUNTRY_ID]],
            },
            // {
            //     type: "array",
            //     name: 'contactPersonGroup',
            //     children: [
            //         inputs[companyGeneralFields.CONTACT_ID],
            //     ]
            // },
            // {
            //     type: "array",
            //     name: 'contactStatusGroup',
            //     children: [
            //         inputs[companyGeneralFields.CONTACT_STATUS_ID],
            //     ]
            // },
            {
                type: 'array',
                name: 'queryCategoryroup',
                children: [inputs[companyGeneralFields.CONTACT_QUERY_CATEGORY_ID]],
            },
            {
                type: 'array',
                name: 'agentGroup',
                children: [inputs[companyGeneralFields.AGENT_ID]],
            },
        ],
    }
}

export const companyOtherInfoInputs = () => {
    const inputs = companyOtherInfoFieldTypes()
    return {
        sideForm: [
            // inputs[companyGeneralFields.CONTACT_QUERY_CATEGORY_ID],
            // inputs[companyGeneralFields.CONTACT_ABOUT],
            // inputs[companyOtherInfoFields.CONTACT_DESCRIPTION_ID],
        ],
        mainForm: [
            {
                type: 'array',
                name: 'companyGroup',
                children: [
                    inputs[companyOtherInfoFields.CONTACT_CORE_BUSINESS_ID],
                    inputs[companyOtherInfoFields.CONTACT_COMPANY_SIZE_ID],
                    inputs[companyOtherInfoFields.EMPLOYEES_NO],
                    inputs[companyOtherInfoFields.BANK_ACCOUNT_NO],
                    inputs[companyOtherInfoFields.REGISTRY_COURT],
                    inputs[companyOtherInfoFields.SHARE_CAPITAL],
                ],
            },
            // inputs[companyOtherInfoFields.INITIAL_CALL_INFO],
            inputs[companyOtherInfoFields.NOTES],
        ],
    }
}

export const companyProfileInputs = (appSettings, propertyTypeValue, propertyServiceValue, isEdit) => {
    const inputs = companyProfileFieldTypes(isEdit, appSettings)

    return {
        sideForm: [
            inputs[companyProfileFields.CONTACT_PROFILE_SERVICE_ID],
            inputs[companyProfileFields.PROPERTY_TYPE_ID],
            inputs[companyProfileFields.PROPERTY_SUB_TYPE_ID],
            inputs[companyProfileFields.CONTACT_PROFILE_STATUS_ID],
            inputs[companyProfileFields.CONTACT_FINANCING_ID],
            inputs[companyProfileFields.CONTACT_PROFILE_DURATION_ID],
            inputs[companyProfileFields.COMPANY_DEFINITION_ID],
            // inputs[companyProfileFields.CONTACT_PROFILE_REMARK_ID],
        ],
        mainForm: [
            {
                type: 'array',
                name: 'locationGroup',
                children: [
                    inputs[companyProfileFields.COUNTRY_ID],
                    inputs[companyProfileFields.COUNTY_ID],
                    inputs[companyProfileFields.CITY_ID],
                    inputs[companyProfileFields.WIDER_AREA_ID],
                    inputs[companyProfileFields.QUARTER_ID],
                    inputs[companyProfileFields.ISLAND_ID],
                ],
            },
            {
                type: 'array',
                name: 'priceGroup',
                children: [
                    {
                        type: 'grouped',
                        name: 'priceGrouped',
                        colProps: {
                            sm: 12,
                            xxl: 4,
                        },
                        items: [inputs[companyProfileFields.PRICE_FROM], inputs[companyProfileFields.PRICE_TO]],
                    },
                    // {
                    //     type: 'grouped',
                    //     name: 'pricem2Grouped',
                    //     colProps: {
                    //         sm: 12,
                    //         xxl: 4,
                    //     },
                    //     items: [
                    //         inputs[companyProfileFields.PRICE_M2_FROM],
                    //         inputs[companyProfileFields.PRICE_M2_TO],
                    //     ]
                    // }
                ],
            },
            {
                type: 'array',
                name: 'roomsGroup',
                children: [
                    ...(propertyTypeValue?.toString() === REAL_ESTATE_TYPES.house.toString() ||
                    propertyTypeValue?.toString() === REAL_ESTATE_TYPES.flat.toString() ||
                    propertyTypeValue?.toString() === REAL_ESTATE_TYPES.officeSpace.toString()
                        ? [
                              {
                                  type: 'grouped',
                                  name: 'roomsGrouped',
                                  colProps: {
                                      sm: 12,
                                      xxl: 4,
                                  },
                                  items: [inputs[companyProfileFields.ROOMS_FROM], inputs[companyProfileFields.ROOMS_TO]],
                              },
                          ]
                        : []),
                    ...(propertyTypeValue?.toString() === REAL_ESTATE_TYPES.house.toString() ||
                    propertyTypeValue?.toString() === REAL_ESTATE_TYPES.flat.toString()
                        ? [
                              {
                                  type: 'grouped',
                                  name: 'bedRoomsGrouped',
                                  colProps: {
                                      sm: 12,
                                      xxl: 4,
                                  },
                                  items: [inputs[companyProfileFields.BEDROOMS_FROM], inputs[companyProfileFields.BEDROOMS_TO]],
                              },
                          ]
                        : []),
                ],
            },
            {
                type: 'array',
                name: 'areaGroup',
                children: [
                    {
                        type: 'grouped',
                        name: 'areaGrouped',
                        items: [
                            inputs[companyProfileFields.AREA_FROM],
                            inputs[companyProfileFields.AREA_TO],
                            ...(propertyTypeValue?.toString() === REAL_ESTATE_TYPES.land.toString()
                                ? [inputs[companyProfileFields.MAX_CONTSRUCTION_AREA]]
                                : []),
                        ],
                    },
                ],
            },
            {
                type: 'array',
                name: 'floorGroup',
                children: [
                    ...(propertyTypeValue?.toString() === REAL_ESTATE_TYPES.house.toString() ? [inputs[companyProfileFields.PROPERTY_FLOOR_ID]] : []),
                    ...(propertyTypeValue?.toString() === REAL_ESTATE_TYPES.flat.toString() ||
                    propertyTypeValue?.toString() === REAL_ESTATE_TYPES.officeSpace.toString()
                        ? [
                              inputs[companyProfileFields.PROPERTY_FLOOR_POSITION_ID],
                              inputs[companyProfileFields.FLOOR_NO_FROM],
                              inputs[companyProfileFields.FLOOR_NO_TO],
                          ]
                        : []),
                    ...(propertyTypeValue?.toString() === REAL_ESTATE_TYPES.flat.toString()
                        ? [inputs[companyProfileFields.EXCLUDE_BASEMENT], inputs[companyProfileFields.EXCLUDE_LAST_FLOOR]]
                        : []),
                ],
            },
            {
                type: 'array',
                name: 'relatedStuffGroup',
                children: [
                    ...(propertyTypeValue?.toString() === REAL_ESTATE_TYPES.house.toString() ||
                    propertyTypeValue?.toString() === REAL_ESTATE_TYPES.flat.toString() ||
                    propertyTypeValue?.toString() === REAL_ESTATE_TYPES.officeSpace.toString()
                        ? [inputs[companyProfileFields.PROPERTY_SPACE_ID]]
                        : []),
                    // {
                    //     type: 'select-with-inputs',
                    //     name: 'propertyPropertySpace_group',
                    //     label: 'Prostori vezani uz nekretninu',
                    //     selectOptionsName: companyProfileFields.PROPERTY_SPACE_ID,
                    //     inputs: [
                    //         inputs[companyProfileFields.PROPERTY_SPACE_ID],
                    //         inputs[companyProfileFields.SPACE_COUNT],
                    //         inputs[companyProfileFields.SPACE_AREA],
                    //     ]
                    // },
                    ...(propertyTypeValue?.toString() !== REAL_ESTATE_TYPES.garage.toString()
                        ? [inputs[companyProfileFields.PROPERTY_DESCRIPTIONS_ID]]
                        : []),
                    ...(propertyTypeValue?.toString() !== REAL_ESTATE_TYPES.garage.toString() &&
                    propertyTypeValue?.toString() !== REAL_ESTATE_TYPES.land.toString()
                        ? [inputs[companyProfileFields.PROPERTY_EQUIPMENT_ID]]
                        : []),
                    ...(propertyTypeValue?.toString() !== REAL_ESTATE_TYPES.garage.toString()
                        ? [inputs[companyProfileFields.PROPERTY_UTILITY_ID]]
                        : []),
                    ...(propertyTypeValue?.toString() !== REAL_ESTATE_TYPES.garage.toString() &&
                    propertyTypeValue?.toString() !== REAL_ESTATE_TYPES.land.toString()
                        ? [inputs[companyProfileFields.PROPERTY_HEATING_ID]]
                        : []),
                ],
            },
            {
                type: 'array',
                name: 'specialRequest',
                children: [inputs[companyProfileFields.SPECIAL_REQUEST]],
            },
        ],
    }
}

export const companyDetailSearchInputs = () => {
    const inputs = companyDetailSearchFieldTypes()

    return {
        config: {
            direction: FORM_DIRECTION.COLUMN,
            colProps: {
                sm: 12,
                xl: 4,
            },
        },
        columns: [
            [
                inputs[responseFields.NAME],
                inputs[responseFields.CONTACT_PHONE],
                inputs[responseFields.CONTACT_EMAIL],
                inputs[responseFields.AGENT],
                {
                    type: 'grouped',
                    name: 'initialContactGrouped',
                    label: i18n.t('app.common.contact.establishmentDate'),
                    colProps: {
                        sm: 12,
                        // xl: 4
                    },
                    items: [inputs[responseFields.INITIAL_CONTACT_DATE_FROM], inputs[responseFields.INITIAL_CONTACT_DATE_TO]],
                },
                {
                    type: 'grouped',
                    name: 'previousActivityGrouped',
                    label: i18n.t('app.common.company.previousActivity'),
                    colProps: {
                        sm: 12,
                        // xl: 4
                    },
                    items: [inputs[responseFields.LAST_ACTIVITY_DATE_FROM], inputs[responseFields.LAST_ACTIVITY_DATE_TO]],
                },
            ],
            [inputs[responseFields.CONTACT_COUNTRY], inputs[responseFields.CITY]],
            [
                inputs[responseFields.CONTACT_STATUS],
                inputs[responseFields.CONTACT_PROFILE_SERVICE],
                inputs[responseFields.CONTACT_PROFILE_STATUS],
                inputs[responseFields.CONTACT_QUERY_CATEGORY],
                inputs[responseFields.CONTACT_PRIORITY],
                inputs[responseFields.DATA_SOURCE],
            ],
        ],
    }
}

export const companyProfileDetailSearchInputs = (appSettings) => {
    const inputs = companyProfileDetailSearchFieldTypes()
    return {
        config: {
            direction: FORM_DIRECTION.COLUMN,
            colProps: {
                sm: 12,
                xl: 4,
            },
        },
        columns: [
            [
                inputs[responseFields.PROPERTY_TYPE],
                inputs[responseFields.PROPERTY_SUB_TYPE],
                {
                    type: 'grouped',
                    name: 'priceGrouped',
                    label: `${i18n.t('table.header.profile.price')} ${renderCurrency(appSettings?.currency)}`,
                    colProps: {
                        sm: 12,
                        // xl: 4
                    },
                    items: [inputs[responseFields.PRICE_FROM], inputs[responseFields.PRICE_TO]],
                },
                {
                    type: 'grouped',
                    name: 'roomsGrouped',
                    label: i18n.t('app.common.property.numberOfRooms'),
                    colProps: {
                        sm: 12,
                        // xl: 4
                    },
                    items: [inputs[responseFields.ROOMS_FROM], inputs[responseFields.ROOMS_TO]],
                },
                {
                    type: 'grouped',
                    name: 'bedroomsGrouped',
                    label: i18n.t('app.common.bedrooms'),
                    colProps: {
                        sm: 12,
                        // xl: 4
                    },
                    items: [inputs[responseFields.BEDROOMS_FROM], inputs[responseFields.BEDROOMS_TO]],
                },
            ],
            [
                inputs[responseFields.CONTACT_PROFILE_DURATION],
                {
                    type: 'empty-column',
                    name: 'emptyColumn1',
                    colProps: {
                        sm: 0,
                        xl: 12,
                    },
                },
                {
                    type: 'grouped',
                    name: 'areaGrouped',
                    label: i18n.t('app.common.spaceArea'),
                    colProps: {
                        sm: 12,
                        // xl: 4
                    },
                    items: [inputs[responseFields.AREA_FROM], inputs[responseFields.AREA_TO]],
                },
                {
                    type: 'grouped',
                    name: 'floorGrouped',
                    label: i18n.t('app.common.floorNumber'),
                    colProps: {
                        sm: 12,
                        // xl: 4
                    },
                    items: [inputs[responseFields.FLOOR_FROM], inputs[responseFields.FLOOR_TO]],
                },
                inputs[responseFields.PROPERTY_FLOOR],
            ],
            [
                inputs[responseFields.COUNTRY],
                inputs[responseFields.COUNTY],
                inputs[responseFields.CITY],
                inputs[responseFields.WIDER_AREA],
                inputs[responseFields.QUARTER],
            ],
        ],
    }
}
