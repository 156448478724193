import { getIn, useFormik } from 'formik'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Card, CardBody } from 'reactstrap'
import * as Yup from 'yup'
import Dropzone from '../../Components/Dropzone/Dropzone'
import FormValidationErrorModal from '../../Components/Modals/FormValidationErrorModal'
import RecursiveForm from '../../Components/RecursiveForm'
import { documentFields } from '../../common/forms/documents/fields'
import { uploadDocument } from '../../store/documents'
import { useDocumentFormConfig, useDocumentFormSelectOptions } from '../Hooks/FormHooks'

const DocumentAdd = ({ setOpenDocumentFormAdd }) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const { documentInputs, documentValidationConfig } = useDocumentFormConfig()
    const selectOptions = useDocumentFormSelectOptions()

    const [isFileAdded, setIsFileAdded] = useState(false)
    const [isErrorModalActive, setIsErrorModalActive] = useState(false)

    const toggleErrorModal = () => {
        setIsErrorModalActive(!isErrorModalActive)
    }

    const setUploadErrorMessage = (fields) => {
        const errors = {}
        const { document_property_id, document_project_id, document_contact_id, document_type_id } = fields
        const requiredField = document_property_id || document_project_id || document_contact_id
        const allRequiredFields = requiredField && document_type_id
        const isTouched = Object.keys(formik.touched).length > 0

        if (!requiredField) {
            errors.general = t('form.errors.documents.general')
        }

        if (isFileAdded && isTouched && allRequiredFields) {
            errors.upload = t('form.errors.documents.upload')
        }

        return errors
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: documentValidationConfig.initialValues ?? {},
        validate: setUploadErrorMessage,
        validationSchema: Yup.object({
            document_type_id: Yup.string().required(t('form.errors.documents.document_type_id')),
        }),
        onSubmit: (values) => {},
    })

    const { values, errors, resetForm, handleSubmit } = formik
    const isError = Object.keys(errors).length > 0

    const contactInput = documentInputs?.sideForm?.findIndex((x) => x.name === documentFields.DOCUMENT_CONTACT_ID)
    const projectInput = documentInputs?.sideForm?.findIndex((x) => x.name === documentFields.DOCUMENT_PROJECT_ID)
    const propertyInput = documentInputs?.sideForm?.findIndex((x) => x.name === documentFields.DOCUMENT_PROPRETY_ID)

    const enableInputs = useCallback(() => {
        documentInputs.sideForm[propertyInput].disabled = false
        documentInputs.sideForm[projectInput].disabled = false
        documentInputs.sideForm[contactInput].disabled = false
    }, [documentInputs.sideForm, propertyInput, projectInput, contactInput])

    // TODO: this useEffect is refactored but is still ugly as hell (below is old commented out useEffect just for the reference)
    // we should think about better way of form management
    useEffect(() => {
        const { document_property_id, document_project_id, document_contact_id } = values
        const inputsRendered = projectInput !== undefined && propertyInput !== undefined && contactInput !== undefined
        const isValues = Object.keys(values).length > 0

        if (!isValues) return

        if (inputsRendered) {
            enableInputs()

            switch (true) {
                case document_project_id !== '':
                    documentInputs.sideForm[propertyInput].disabled = true
                    documentInputs.sideForm[contactInput].disabled = true
                    break
                case document_property_id !== '':
                    documentInputs.sideForm[projectInput].disabled = true
                    documentInputs.sideForm[contactInput].disabled = true
                    break
                case document_contact_id !== '':
                    documentInputs.sideForm[propertyInput].disabled = true
                    documentInputs.sideForm[projectInput].disabled = true
                    break
                default:
                    break
            }
        }
    }, [values, documentInputs.sideForm, projectInput, propertyInput, contactInput, enableInputs])

    // useEffect(() => {
    //     if (projectId) {
    //         // console.log('contactInput', projectId);
    //         if (propertyInput > -1) {
    //             console.log('projectid', projectId)
    //             documentInputs.sideForm[propertyInput].disabled = true
    //             console.log('propert', documentInputs.sideForm[propertyInput])
    //             validation.setFieldValue(documentFields.DOCUMENT_PROPRETY_ID, '')
    //         }
    //         if (contactInput > -1) {
    //             documentInputs.sideForm[contactInput].disabled = true
    //             validation.setFieldValue(documentFields.DOCUMENT_CONTACT_ID, '')
    //         }
    //     } else if (contactId) {
    //         if (projectInput > -1) {
    //             documentInputs.sideForm[projectInput].disabled = true
    //             validation.setFieldValue(documentFields.DOCUMENT_PROJECT_ID, '')
    //         }

    //         if (propertyInput > -1) {
    //             documentInputs.sideForm[propertyInput].disabled = true
    //             validation.setFieldValue(documentFields.DOCUMENT_PROPRETY_ID, '')
    //         }
    //     } else if (propertyId) {
    //         // console.log('contactInput', projectId);
    //         if (projectInput > -1) {
    //             documentInputs.sideForm[projectInput].disabled = true
    //             validation.setFieldValue(documentFields.DOCUMENT_PROJECT_ID, '')
    //         }
    //         if (contactInput > -1) {
    //             documentInputs.sideForm[contactInput].disabled = true
    //             validation.setFieldValue(documentFields.DOCUMENT_CONTACT_ID, '')
    //         }
    //     } else {
    //         if (propertyInput > -1) {
    //             documentInputs.sideForm[propertyInput].disabled = false
    //         }
    //         if (contactInput > -1) {
    //             documentInputs.sideForm[contactInput].disabled = false
    //         }
    //         if (projectInput > -1) {
    //             documentInputs.sideForm[projectInput].disabled = false
    //         }
    //     }
    // }, [contactId, propertyId, projectId])

    useEffect(() => {
        if (isError && isFileAdded) {
            setIsErrorModalActive(true)
        }
    }, [isError, isFileAdded])

    // TODO: this function is huge, needs to be refactored
    const handleDocumentUpload = (file) => {
        const { document_property_id, document_project_id, document_contact_id, document_type_id } = values
        const requiredId = [document_property_id, document_project_id, document_contact_id]
        const formError = requiredId.every((field) => field === '') || document_type_id === ''

        if (file) {
            setIsFileAdded(true)

            // form submit to trigger validation
            handleSubmit()

            const fileData = { ...file }[0]
            const [fileName, fileExtension] = file[0].name.split('.')

            // to actually see fromData content you need to convert it to js object
            let formData = new FormData()
            formData.append('file', fileData)

            const docTypeValue = getIn(values, documentFields.DOCUMENT_TYPE)
            const docTypeLabel = selectOptions?.[documentFields.DOCUMENT_TYPE]?.find((x) => x.value === docTypeValue?.toString())?.label

            const currentLang = localStorage.getItem('i18nextLng')

            Object.assign(fileData, {
                translations: {
                    [currentLang]: {
                        fileDescription: fileName,
                    },
                },
                order: 0,
                collection: 'document',
                documentCreationDate: getIn(values, documentFields.DOCUMENT_CREATION_DATE),
                documentValidityDate: getIn(values, documentFields.DOCUMENT_VALIDITY_DATE),
                documentType: {
                    id: docTypeValue,
                    name: docTypeLabel,
                },
                fileSync: getIn(values, documentFields.FILE_SYNC),
                fileExtension: fileExtension,
            })

            if (document_contact_id) {
                formData.append('module', 'contact')
                formData.append('parent_id', document_contact_id)
                Object.assign(fileData, {
                    module: 'contact',
                    parentId: document_contact_id,
                    parent: document_contact_id,
                })
            }

            if (document_property_id) {
                formData.append('module', 'property')
                formData.append('parent_id', document_property_id)
                Object.assign(fileData, {
                    module: 'property',
                    parentId: document_property_id,
                    parent: document_property_id,
                })
            }

            if (document_project_id) {
                formData.append('module', 'project')
                formData.append('parent_id', document_project_id)
                Object.assign(fileData, {
                    module: 'project',
                    parentId: document_project_id,
                    parent: document_project_id,
                })
            }

            formData.append('collection', 'document')
            formData.append(documentFields.DOCUMENT_CREATION_DATE, getIn(values, documentFields.DOCUMENT_CREATION_DATE))
            formData.append(documentFields.DOCUMENT_VALIDITY_DATE, getIn(values, documentFields.DOCUMENT_VALIDITY_DATE))
            formData.append(documentFields.DOCUMENT_PROJECT_ID, getIn(values, documentFields.DOCUMENT_PROJECT_ID))
            formData.append(documentFields.DOCUMENT_PROPRETY_ID, getIn(values, documentFields.DOCUMENT_PROPRETY_ID))
            formData.append(documentFields.DOCUMENT_CONTACT_ID, getIn(values, documentFields.DOCUMENT_CONTACT_ID))
            formData.append(documentFields.DOCUMENT_TYPE, getIn(values, documentFields.DOCUMENT_TYPE))
            const fileSyncValue = getIn(values, documentFields.FILE_SYNC)
            formData.append(documentFields.FILE_SYNC, fileSyncValue ? 1 : 0)

            let uplUrl = ''

            if (document_contact_id) {
                uplUrl = 'contact/document'
            }

            if (document_property_id) {
                uplUrl = 'property/document'
            }

            if (document_project_id) {
                uplUrl = 'project/document'
            }

            if (!formError) {
                dispatch(
                    uploadDocument(
                        {
                            fileData,
                            formData,
                            uploadUrl: uplUrl,
                        },
                        () => {
                            resetForm()
                            enableInputs()
                            setIsFileAdded(false)
                        }
                    )
                )
            }
        }
    }

    return (
        <>
            <Card>
                <div className="d-flex justify-content-between align-items-center w-100 p-3">
                    <h5 className="fw-light m-0">{t('form.documentFormAdd.title.add')}</h5>
                    <button
                        type="button"
                        className="btn btn-dark btn-icon btn-sm fs-16 close-btn-overview"
                        onClick={() => setOpenDocumentFormAdd(false)}
                    >
                        <i className="mdi mdi-close align-bottom"></i>
                    </button>
                </div>
                <hr className="border-dashed mx-3 my-0" />
                <CardBody>
                    {isError && <p className="mb-4 text-danger text-center">{errors.general ? errors.general : errors.upload}</p>}

                    <RecursiveForm inputs={documentInputs?.sideForm} formik={formik} selectOptions={selectOptions} />

                    <Dropzone callback={handleDocumentUpload} tab={'documents'} />
                </CardBody>
            </Card>
            {isErrorModalActive && <FormValidationErrorModal validation={formik} inputs={[documentInputs]} onClose={() => toggleErrorModal(false)} />}
        </>
    )
}

export default DocumentAdd
