import { combineActions, handleActions } from 'redux-actions'
import { activityResponseFields } from '../../common/response/activity'
import { hexToRGB } from '../../utils/general'
import {
    clearActivities,
    createActivityFailure,
    createActivityRequest,
    createActivitySuccess,
    deleteActivitySuccess,
    fetchActivitiesFailure,
    fetchActivitiesRequest,
    fetchActivitiesSuccess,
    fetchActivityFailure,
    fetchActivityRequest,
    fetchActivitySuccess,
    updateActivityFailure,
    updateActivityRequest,
    updateActivitySuccess,
} from './actions'

export const initialState = {
    items: {},
    item: {},
    count: 0,
    fetching: true,
    fetchingDetails: true,
    submitting: false,
    error: null,
    errorDetails: null,
    deleting: false,
}

export default handleActions(
    {
        [fetchActivitiesRequest]: (state) => ({
            ...state,
            fetching: true,
            error: null,
        }),
        [fetchActivitiesSuccess]: (state, { payload }) => {
            const formattedActivities = payload?.items?.reduce((acc, item) => {
                const formatted = {
                    ...item,
                    project: item.project,
                    property: item.property,
                    start: new Date(item.dateFrom + ' ' + item.timeFrom),
                    end: new Date(item.dateTo + ' ' + item.timeTo),
                    display: 'block',
                    allDay: item.durationDay ? true : false,
                    description: item.notes,
                    textColor: '#273B4A',
                    backgroundColor: hexToRGB(item?.[activityResponseFields.ACTIVITY_TYPE]?.color, 0.18),
                }

                return { ...acc, [item.id]: formatted }
            }, {})

            return {
                ...state,
                count: payload?.count ?? 0,
                items: formattedActivities,
                fetching: false,
            }
        },
        [fetchActivitiesFailure]: (state, { payload }) => ({
            ...state,
            error: payload,
            fetching: false,
        }),
        [fetchActivityRequest]: (state) => ({
            ...state,
            fetchingDetails: true,
            errorDetails: null,
        }),
        [fetchActivitySuccess]: (state, { payload }) => ({
            ...state,
            item: payload,
            fetchingDetails: false,
        }),
        [fetchActivityFailure]: (state, { payload }) => ({
            ...state,
            errorDetails: payload,
            fetchingDetails: false,
        }),
        [createActivitySuccess]: (state, { payload }) => {
            return {
                ...state,
                items: {
                    ...state.items,
                    [payload.id]: {
                        ...payload,
                        start: new Date(payload.dateFrom + ' ' + payload.timeFrom),
                        end: new Date(payload.dateTo + ' ' + payload.timeTo),
                        // className: 'bg-soft-add',
                        display: 'block',
                        allDay: payload.durationDay ? true : false,
                        description: payload.notes,
                        textColor: '#273B4A',
                        backgroundColor: hexToRGB(payload?.[activityResponseFields.ACTIVITY_TYPE]?.color, 0.18),
                    },
                },
            }
        },
        [updateActivitySuccess]: (state, { payload }) => {
            return {
                ...state,
                items: {
                    ...state.items,
                    [payload.id]: {
                        ...state.items[payload.id],
                        ...payload,
                        start: new Date(payload.dateFrom + ' ' + payload.timeFrom),
                        end: new Date(payload.dateTo + ' ' + payload.timeTo),
                    },
                },
            }
        },
        [combineActions(updateActivityRequest, createActivityRequest)]: (state, { payload }) => ({
            ...state,
            submitting: true,
        }),
        [combineActions(updateActivitySuccess, createActivitySuccess)]: (state, { payload }) => ({
            ...state,
            submitting: false,
        }),
        [combineActions(updateActivityFailure, createActivityFailure)]: (state, { payload }) => ({
            ...state,
            submitting: false,
            error: payload,
        }),
        [deleteActivitySuccess]: (state, { payload }) => {
            const items = { ...state.items }
            // console.log('items', items)
            delete items[payload]
            return {
                ...state,
                deleting: false,
                items,
            }
        },
        [clearActivities]: (state, { payload }) => {
            return {
                ...initialState,
            }
        },
    },
    initialState
)
