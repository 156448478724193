import i18n from 'i18next'
import * as Yup from 'yup'

export const officeValidation = () => {
    const errorMsg = i18n.t('validation.message')

    return {
        city: Yup.string().nullable(),
        country: Yup.string().nullable(),
        description: Yup.string().nullable(),
        email: Yup.string().nullable(),
        logo: Yup.string().nullable(),
        mobileNumber: Yup.string().nullable(),
        name: Yup.string()
            .nullable()
            .required(`${errorMsg}; ${i18n.t('app.common.firstName')}`),
        phoneNumber: Yup.string().nullable(),
        priority: Yup.number().nullable(),
        street: Yup.string().nullable(),
        streetNumber: Yup.string().nullable(),
        watermark: Yup.string().nullable(),
        watermarkPosition: Yup.string().nullable(),
        watermarkTransparency: Yup.string().nullable(),
        websiteUrl: Yup.string().nullable(),
        zipCode: Yup.string().nullable(),
    }
}
