export const activityFields = {
    ID: 'id',
    ACTIVITY_TEMPLATE: 'activity_template',
    TITLE: 'title',
    ACTIVITY_TYPE_ID: 'activity_type_id',
    DATE_FROM: 'date_from',
    TIME_FROM : 'time_from',
    DATE_TO: 'date_to',
    TIME_TO: 'time_to',
    DURATION_DAY: 'duration_day',
    CONTACT_ID: 'contact_id',
    PROPERTY_ID: 'property_id',
    PROJECT_ID: 'project_id',
    AGENT_ID: 'agent_id',
    NOTIFICATION_SEND: 'notification_send',
    REMINDER_TYPE: 'reminder_type',
    REMINDER_SEND: 'reminder_send',
    NOTES: 'notes',
    ACTIVITY_AGENT_GROUP: 'activity_agent_group',
    ACTIVITY_REMINDER_GROUP: 'activity_reminder_group',
    ACTIVITY_LOCATION: 'location',
    COMPLETED: 'completed',
    CONTACT_COMPANY: 'contact_company',
    COMPANY_ID: 'company_id',
    CONTACT_COMPANY_COLLECTION: 'contact_company_collection',
}