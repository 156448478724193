import { handleActions } from 'redux-actions'
import { fetchApplicationSettingsSuccess } from './actions'

export const initialState = {
    agency: {},
    applicationMarket: {},
    applicationPackage: {},
    availableLanguages: [],
    availableTranslationLanguages: [],
    availablePortals: [],
    locales: [],
}

export default handleActions(
    {
        [fetchApplicationSettingsSuccess]: (state, { payload }) => {
            const {
                availableLanguages = [],
                availableTranslationLanguages = [],
                applicationPackage = {},
                applicationMarket = {},
            } = payload ?? {}

            const locales = availableTranslationLanguages.map((lang) => lang.abbreviation)

            return {
                ...state,
                availableLanguages,
                availableTranslationLanguages,
                applicationPackage,
                applicationMarket,
                locales,
            }
        },
    },
    initialState
)
