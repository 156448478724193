import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { GoogleMap, useJsApiLoader, Marker, InfoWindow, MarkerClusterer } from '@react-google-maps/api'
import { fetchProperties } from '../../../../store/properties'
import { iconsSrc } from './iconsSrc'
import InfoWindowContent from './InfoWindowContent/InfoWindowContent'
import Loader from '../../../../Components/Common/Loader'
import style from './MapProperty.module.css'

const mapPosition = {
    lat: 44,
    lng: 16,
}

const MapProperty = (props) => {
    const { tableActions, properties, propertySearchCriteria, isDataLoading } = props

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchProperties({ noLimit: 1, criteria: propertySearchCriteria }))
    }, [dispatch, propertySearchCriteria])

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-properties',
        googleMapsApiKey: process.env.REACT_APP_GOOGLEMAPID,
    })

    const [showInfoWindow, setShowInfoWindow] = useState()
    const [map, setMap] = useState()

    const handleInfoWindowShow = (propertyId) => {
        const propertyById = properties.find((property) => property?.id === propertyId)
        setShowInfoWindow(propertyById)
        map.panTo({
            lat: parseFloat(propertyById?.mapLatitude),
            lng: parseFloat(propertyById?.mapLongitude),
        })
    }

    const handleInfoWindowHide = () => {
        setShowInfoWindow(null)
    }

    return (
        <>
            {tableActions()}
            {isLoaded && !isDataLoading ? (
                <GoogleMap
                    onLoad={(map) => setMap(map)}
                    options={{ gestureHandling: 'cooperative' }}
                    mapContainerClassName={style.mapContainer}
                    panto
                    center={mapPosition}
                    zoom={7}
                    onZoomChanged={handleInfoWindowHide}
                >
                    <MarkerClusterer
                    /* styles={[
                            { textColor: 'red', height: 53, url: 'images/m1.png', width: 53 },
                            { textColor: 'red', height: 56, url: 'images/m2.png', width: 56 },
                            { textColor: 'red', height: 66, url: 'images/m3.png', width: 66 },
                            { textColor: 'red', height: 78, url: 'images/m4.png', width: 78 },
                            { textColor: 'red', height: 90, url: 'images/m5.png', width: 90 },
                        ]} */
                    >
                        {(clusterer) =>
                            properties.map((item) => {
                                const icon = iconsSrc.find((icon) => icon?.id === item.propertyType?.id)
                                return (
                                    <Marker
                                        onClick={() => handleInfoWindowShow(item?.id)}
                                        key={item.id}
                                        position={{
                                            lat: parseFloat(item?.mapLatitude),
                                            lng: parseFloat(item?.mapLongitude),
                                        }}
                                        icon={{
                                            url: icon.src,
                                        }}
                                        clusterer={clusterer}
                                    >
                                        {showInfoWindow?.id === item?.id ? (
                                            <InfoWindow
                                                position={{
                                                    lat: parseFloat(showInfoWindow?.mapLatitude),
                                                    lng: parseFloat(showInfoWindow?.mapLongitude),
                                                }}
                                                onCloseClick={handleInfoWindowHide}
                                            >
                                                <InfoWindowContent {...showInfoWindow} />
                                            </InfoWindow>
                                        ) : (
                                            <></>
                                        )}
                                    </Marker>
                                )
                            })
                        }
                    </MarkerClusterer>
                </GoogleMap>
            ) : (
                <div className="text-center d-flex justify-content-center" style={{ position: 'absolute', inset: '100px 0' }}>
                    <Loader />
                </div>
            )}
        </>
    )
}

export default MapProperty
