import { useEffect, useState } from "react";
import { propertyPrintInputs } from "../common/forms/generalRealEstate/inputs";
import { propertyPrintValidation } from "../common/forms/generalRealEstate/validations";


export const usePropertyPrintFormConfig = (data) => {
    const [inputs, setInputs] = useState({});
    const [validationConfig, setValidationConfig] = useState({});
    // console.log('hm')
    useEffect(() => {
        setInputs(propertyPrintInputs())
        setValidationConfig(propertyPrintValidation(data))
    }, [data])

    return {
        inputs,
        validationConfig
    }
}