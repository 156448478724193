import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Card, CardBody, Container } from 'reactstrap'
import DeleteModal from '../../../Components/Common/DeleteModal'
import PageTitle from '../../../Components/Common/PageTitle'
import TableContainer from '../../../Components/Table/TableContainer'
import { deleteDiary, fetchDiaries } from '../../../store/diaries'
import { selectDiaries, selectDiariesCount, selectDiariesFetching, selectDiariesPagination } from '../../../store/diaries/selectors'
import { selectSearchCriteriaDiaries } from '../../../store/search'
import styles from './DiaryList.module.css'
import DiarySideBar from './DiarySideBar/DiarySideBar'
import TableColumns from './TableColumns/TableColumns'

const DiaryList = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const appModule = 'diary'

    const [pageCount, setPageCount] = useState(0)
    const [deleteModal, setDeleteModal] = useState(false)
    const [rowId, setRowId] = useState(0)
    const [sideBarStyles, setSideBarStyles] = useState({ gridTemplateColumns: `auto 0px`, overflow: 'hidden' })
    const [sideBarHeight, setSideBarHeight] = useState('h-100')
    const [tableSlideModal, setTableSlideModal] = useState({ overflow: 'hidden' })

    const { diaries, diariesFetching, diarySearchCriteria, diariesCount, pagination } = useSelector((state) => ({
        diaries: selectDiaries(state),
        diariesFetching: selectDiariesFetching(state),
        diarySearchCriteria: selectSearchCriteriaDiaries(state),
        diariesCount: selectDiariesCount(state),
        pagination: selectDiariesPagination(state),
    }))

    const handleDataFetch = (page, limit, order, criteria) => {
        dispatch(fetchDiaries({ page, limit, order, criteria }, (count) => setPageCount(Math.ceil(count / limit))))
    }

    useEffect(() => {})

    const handleDeleteDiaryModal = () => {
        dispatch(deleteDiary(rowId))
        setDeleteModal(false)
        handleSideBarClose()
    }

    const handleGetIdDiaryTable = (id, isDelete = false) => {
        setRowId(id)
        if (isDelete) {
            setDeleteModal(true)
        } else {
            setSideBarStyles({ gridTemplateColumns: `auto 500px` })
            setTimeout(function () {
                setSideBarHeight('h-0')
                setTableSlideModal({})
            }, 300)
        }
    }

    const handleSideBarClose = () => {
        setSideBarStyles({ gridTemplateColumns: `auto 0px`, overflow: 'hidden' })
        setSideBarHeight('h-100')
        setTableSlideModal({ overflow: 'hidden' })
    }

    const handleRowClick = (id) => {
        history.push(`/diary/${id}/edit`)
    }

    const columns = TableColumns(handleGetIdDiaryTable)

    return (
        <div className="page-content">
            <Container fluid>
                <PageTitle title={t('app.common.intermediaryDiary')} />
                <DeleteModal show={deleteModal} onDeleteClick={handleDeleteDiaryModal} onCloseClick={() => setDeleteModal(false)} />
                <div className={styles.tableRow} style={sideBarStyles}>
                    <div className={styles.tableCol}>
                        <Card>
                            <CardBody>
                                <TableContainer
                                    hasPageCount={true}
                                    columns={columns}
                                    data={diaries || []}
                                    isGlobalFilter={false}
                                    isAddUserList={false}
                                    customPageSize={20}
                                    className="custom-header-css"
                                    theadClass={'dmn-table-header'}
                                    isRealEstatesListFilter={true}
                                    hasPageSizeSelect={true}
                                    isLoading={diariesFetching}
                                    handleDataFetch={handleDataFetch}
                                    handleRowClick={handleRowClick}
                                    totalCount={diariesCount}
                                    pageCount={pageCount}
                                    searchCriteria={diarySearchCriteria}
                                    tableHash={appModule}
                                    currentPage={pagination.currentPage}
                                    totalPages={pagination.totalPages}
                                />
                            </CardBody>
                        </Card>
                    </div>
                    <div className={styles.tableSideModal} style={tableSlideModal}>
                        <DiarySideBar
                            id={rowId}
                            diaries={diaries}
                            className={`${styles.sideModalShow} ${sideBarHeight}`}
                            sidebarClose={handleSideBarClose}
                            handleGetIdDiaryTable={handleGetIdDiaryTable}
                        />
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default DiaryList
