import _ from 'lodash'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ACTIVITY_CATEGORY, CONTACT_TYPE, DETAIL_VIEW, PROPERTY_STATUS, REAL_ESTATE_SERVICE, REAL_ESTATE_TYPES } from '../../common/constants'
import requestFields from '../../common/constants/requestFields'
import { activityFields } from '../../common/forms/activities/fields'
import { activityEventInputs, activityReminderInputs, activityTaskInputs } from '../../common/forms/activities/inputs'
import { activityEventValidaton, activityReminderValidaton, activityTaskValidaton } from '../../common/forms/activities/validation'
import { companyGeneralFields, companyOtherInfoFields, companyProfileFields } from '../../common/forms/company/fields'
import { companyGeneralInputs, companyOtherInfoInputs, companyProfileInputs } from '../../common/forms/company/inputs'
import { companyGeneralValidation, companyOtherInfoValidation, companyProfileValidation } from '../../common/forms/company/validations'
import {
    contactDocumentFields,
    contactGeneralFields,
    contactNoteFields,
    contactOtherInfoFields,
    contactProfileFields,
} from '../../common/forms/contacts/fields'
import {
    contactDocumentInputs,
    contactGeneralInputs,
    contactNoteInputs,
    contactOtherInfoInputs,
    contactProfileInputs,
} from '../../common/forms/contacts/inputs'
import {
    contactDocumentValidation,
    contactGeneralValidation,
    contactNoteValidation,
    contactOtherInfoValidation,
    contactProfileValidation,
} from '../../common/forms/contacts/validations'
import { documentFields } from '../../common/forms/documents/fields'
import { documentsInputs } from '../../common/forms/documents/inputs'
import { documentValidation } from '../../common/forms/documents/validations'
import { mapInputs } from '../../common/forms/general/inputs'
import { mapValidation } from '../../common/forms/general/validations'
import { propertyTransactionsFields, realEstateGeneralFields } from '../../common/forms/generalRealEstate/fields'
import {
    propertyTransactionsInputs,
    realEstateDescriptionInputs,
    realEstateGeneralFlatLeaseInputs,
    realEstateGeneralFlatSaleInputs,
    realEstateGeneralGarageLeaseInputs,
    realEstateGeneralGarageSaleInputs,
    realEstateGeneralHouseLeaseInputs,
    realEstateGeneralHouseSaleInputs,
    realEstateGeneralInputs,
    realEstateGeneralLandLeaseInputs,
    realEstateGeneralLandSaleInputs,
    realEstateGeneralOfficeSpaceLeaseInputs,
    realEstateGeneralOfficeSpaceSaleInputs,
    realEstateMaterials3DVideoInputs,
    realEstateTransactionInputs,
} from '../../common/forms/generalRealEstate/inputs'
import {
    generalRealEstateFlatLeaseValidaton,
    generalRealEstateFlatSaleValidaton,
    generalRealEstateValidaton,
    propertMapValidation,
    propertyExportValidation,
    propertyGeneralGarageLeaseValidaton,
    propertyGeneralGarageSaleValidaton,
    propertyGeneralHouseLeaseValidaton,
    propertyGeneralHouseSaleValidaton,
    propertyGeneralLandLeaseValidaton,
    propertyGeneralLandSaleValidaton,
    propertyGeneralOfficeSpaceLeaseValidaton,
    propertyGeneralOfficeSpaceSaleValidaton,
    propertyTransactionsValidation,
    realEstateDescriptionValidation,
    realEstateMaterials3DVideoValidation,
    transactionRealEstateValidation,
} from '../../common/forms/generalRealEstate/validations'
import { projectGeneralFields, projectNoteFields } from '../../common/forms/projects/fields'
import { projectDescriptionInputs, projectGeneralInputs, projectNoteInputs } from '../../common/forms/projects/inputs'
import { projectDescriptionValidation, projectGeneralValidaton, projectNoteValidation } from '../../common/forms/projects/validation'
import { addRequiredValidation } from '../../helpers/validation_helper'
import {
    selectActivityContactAsyncSelectedFormOptions,
    selectActivityContactCompanyCollectionAsyncSelectedFormOptions,
    selectActivityProjectAsyncSelectedFormOptions,
    selectActivityPropertyAsyncSelectedFormOptions,
    selectActivityReminderTypeSelectedFormOptions,
} from '../../store/activities'
import { selectActivityValuesTypeFormOptions } from '../../store/activityValues/selectors'
import { selectAgentsFormOption } from '../../store/agents/selectors'
import { selectApplicationMarket, selectLocales } from '../../store/applicationSettings'
import { selectCurrentUser, selectCurrentUserId } from '../../store/auth/selectors'
import { selectCompanyAsycDocumentCompanyFormOptions } from '../../store/companies'
import {
    fetchContactValuesProfileService,
    selectContactValuesCompannyDefinitionFormOptions,
    selectContactValuesCompanySizeFormOptions,
    selectContactValuesCoreBusinessFormOptions,
    selectContactValuesCountryFormOptions,
    selectContactValuesDefinitionFormOptions,
    selectContactValuesDescriptionFormOptions,
    selectContactValuesEmailTypeFormOptions,
    selectContactValuesFinancingFormOptions,
    selectContactValuesGenderFormOptions,
    selectContactValuesJobTitleFormOptions,
    selectContactValuesLegalFormFormOptions,
    selectContactValuesMartialStatusFormOptions,
    selectContactValuesPhoneTypeFormOptions,
    selectContactValuesPriorityFormOptions,
    selectContactValuesProfileDurationFormOptions,
    selectContactValuesProfileRemarkFormOptions,
    selectContactValuesProfileServiceFormOptions,
    selectContactValuesProfileStatusFormOptions,
    selectContactValuesQueryCategoryFormOptions,
    selectContactValuesSourceFormOptions,
    selectContactValuesStatusFormOptions,
    selectContactValuesTitleFormOptions,
} from '../../store/contactValues'
import {
    selectContactAsycDocumentContactFormOptions,
    selectContactAsyncSelectedFormOptions,
    selectContactCompanyAsycDocumentContactFormOptions,
} from '../../store/contacts'
import { selectDocumentValuesTypeFormOptions } from '../../store/documentValues/selectors'
import {
    selectLocationsCityFormOptions,
    selectLocationsCountryFormOptions,
    selectLocationsCountyFormOptions,
    selectLocationsQIslandFormOptions,
    selectLocationsQuarterFormOptions,
    selectLocationsWiderAreaFormOptions,
} from '../../store/locations'
import {
    selectProjectValuesDevelopmentStageFormOptions,
    selectProjectValuesObjectTypeFormOptions,
    selectProjectValuesSizeFormOptions,
    selectProjectValuesStatusFormOptions,
    selectProjectValuesTypeFormOptions,
} from '../../store/projectValues/selectors'
import { selectProjectAsyncOwnerFormOptions } from '../../store/projects'
import {
    selectPropertyAsyncOwnerFormOptions,
    selectPropertyAsyncProjectFormOptions,
    selectPropertyAsyncSelectedFormOptions,
    selectPropertyContactAsyncSelectedFormOptions,
} from '../../store/properties'
import { selectPropertyServices, selectPropertyServicesFormOptions } from '../../store/propertyServices'
import { selectPropertySetFormOptions } from '../../store/propertySet'
import { selectPropertySubtypesFormOptions } from '../../store/propertySubtypes'
import { selectPropertyTypesFormOptions, selectRealEstateTypeFormOptions } from '../../store/propertyTypes'
import {
    selectPropertyValuesAccessFormOptions,
    selectPropertyValuesAgencyCommissionFormOptions,
    selectPropertyValuesCadastralMunicipalityFormOptions,
    selectPropertyValuesConditionFormOptions,
    selectPropertyValuesDataSourceFormOptions,
    selectPropertyValuesDescriptionsrFormOptions,
    selectPropertyValuesEnergyEfficiencyFormOptions,
    selectPropertyValuesEquipmentFormOptions,
    selectPropertyValuesFloorFormOptions,
    selectPropertyValuesFloorPositionFormOptions,
    selectPropertyValuesHeatingFormOptions,
    selectPropertyValuesHouseTypeFormOptions,
    selectPropertyValuesLandRegistryStatusFormOptions,
    selectPropertyValuesLandUsageFormOptions,
    selectPropertyValuesMainRegisterDepartmentFormOptions,
    selectPropertyValuesMainRegisterFormOptions,
    selectPropertyValuesMapLocationSyncTypeFormOptions,
    selectPropertyValuesOrientationFormOptions,
    selectPropertyValuesOwnershipAcquisitionFormOptions,
    selectPropertyValuesOwnershipAcquisitionProofFormOptions,
    selectPropertyValuesPermitFormOptions,
    selectPropertyValuesPositionFormOptions,
    selectPropertyValuesPrimaryUsageFormOptions,
    selectPropertyValuesPropertyTransactionRiskDegreeFormOptions,
    selectPropertyValuesPublicTransportationFormOptions,
    selectPropertyValuesPukDepartmentFormOptions,
    selectPropertyValuesPukFormOptions,
    selectPropertyValuesRegistryDepartmentKpuFormOptions,
    selectPropertyValuesRegistryDepartmentKpuSubinsertFormOptions,
    selectPropertyValuesRoomsNoFormOptions,
    selectPropertyValuesRoomsTypeFormOptions,
    selectPropertyValuesSpaceFormOptions,
    selectPropertyValuesSpecialFeatureFormOptions,
    selectPropertyValuesStatusFormOptions,
    selectPropertyValuesSupplySourceFormOptions,
    selectPropertyValuesTransactionCommissionTypeFormOptions,
    selectPropertyValuesUtilityFormOptions,
    selectPropertyValuesWindowsDoorsFormOptions,
} from '../../store/propertyValues/selectors'
import { selectApplicationAreaUnit, selectApplicationCurrency, selectCostTypes, selectSettingsDefaultLanguage } from '../../store/settings/selectors'
import useApplicationSettings from './useApplicationSettings'

const useRealEstateGeneralFormSelectOptions = () => {
    const selectOptions = useSelector((state) => ({
        [realEstateGeneralFields.PROPERTY_TYPE_ID]: selectPropertyTypesFormOptions(state),
        [realEstateGeneralFields.PROPERTY_SERVICE_ID]: selectPropertyServicesFormOptions(state),
        [realEstateGeneralFields.PROPERTY_SUB_TYPE_ID]: selectPropertySubtypesFormOptions(state),
        [realEstateGeneralFields.PROPERTY_SET_ID]: selectPropertySetFormOptions(state),
        [realEstateGeneralFields.OWNER_ID]: selectPropertyAsyncOwnerFormOptions(state),
        [realEstateGeneralFields.PROJECT_ID]: selectPropertyAsyncProjectFormOptions(state),
        [realEstateGeneralFields.COUNTRY_ID]: selectLocationsCountryFormOptions(state),
        [realEstateGeneralFields.COUNTY_ID]: selectLocationsCountyFormOptions(state),
        [realEstateGeneralFields.CITY_ID]: selectLocationsCityFormOptions(state),
        [realEstateGeneralFields.WIDER_AREA_ID]: selectLocationsWiderAreaFormOptions(state),
        [realEstateGeneralFields.QUARTER_ID]: selectLocationsQuarterFormOptions(state),
        [realEstateGeneralFields.ISLAND_ID]: selectLocationsQIslandFormOptions(state),
        [realEstateGeneralFields.PROPERTY_POSITION_ID]: selectPropertyValuesPositionFormOptions(state),
        [realEstateGeneralFields.PROPERTY_ROOMS_TYPE_ID]: selectPropertyValuesRoomsTypeFormOptions(state),
        [realEstateGeneralFields.PROPERTY_HOUSE_TYPE_ID]: selectPropertyValuesHouseTypeFormOptions(state),
        [realEstateGeneralFields.PROPERTY_ROOMS_NO_ID]: selectPropertyValuesRoomsNoFormOptions(state),
        [realEstateGeneralFields.PROPERTY_FLOOR_ID]: selectPropertyValuesFloorFormOptions(state),
        [realEstateGeneralFields.PROPERTY_FLOOR_POSITION_ID]: selectPropertyValuesFloorPositionFormOptions(state),
        [realEstateGeneralFields.PROPERTY_ORIENTATION_ID]: selectPropertyValuesOrientationFormOptions(state),
        [realEstateGeneralFields.PROPERTY_HEATING_ID]: selectPropertyValuesHeatingFormOptions(state),
        [realEstateGeneralFields.PROPERTY_CONDITION_ID]: selectPropertyValuesConditionFormOptions(state),
        [realEstateGeneralFields.PROPERTY_WINDOWS_DOORS_ID]: selectPropertyValuesWindowsDoorsFormOptions(state),
        [realEstateGeneralFields.PROPERTY_PUBLIC_TRANSPORTATION_ID]: selectPropertyValuesPublicTransportationFormOptions(state),
        [realEstateGeneralFields.PROPERTY_SPECIAL_FEATURE_ID]: selectPropertyValuesSpecialFeatureFormOptions(state),
        [realEstateGeneralFields.PROPERTY_UTILITY_ID]: selectPropertyValuesUtilityFormOptions(state),
        [realEstateGeneralFields.PROPERTY_EQUIPMENT_ID]: selectPropertyValuesEquipmentFormOptions(state),
        [realEstateGeneralFields.PROPERTY_SPACE_ID]: selectPropertyValuesSpaceFormOptions(state),
        [realEstateGeneralFields.PROPERTY_ENERGY_EFFICIENCY_ID]: selectPropertyValuesEnergyEfficiencyFormOptions(state),
        [realEstateGeneralFields.PROPERTY_ACCESS_ID]: selectPropertyValuesAccessFormOptions(state),
        [realEstateGeneralFields.PROPERTY_PERMIT_ID]: selectPropertyValuesPermitFormOptions(state),
        [realEstateGeneralFields.AGENCY_COMMISSION_ID]: selectPropertyValuesAgencyCommissionFormOptions(state),
        [realEstateGeneralFields.PROPERTY_DESCRIPTION]: selectPropertyValuesDescriptionsrFormOptions(state),
        [requestFields.PROPERTY_SUPPLY_SOURCE_ID]: selectPropertyValuesSupplySourceFormOptions(state),
        [realEstateGeneralFields.AGENT_ID]: selectAgentsFormOption(state),
        [`${realEstateGeneralFields.AGENT_ID}_secondary`]: selectAgentsFormOption(state),
        [realEstateGeneralFields.MAINTENCE_COSTS_TYPE]: selectCostTypes(),
        [realEstateGeneralFields.COMMON_MAINTENCE_COSTS_TYPE]: selectCostTypes(),
        [realEstateGeneralFields.COMMON_UTILITY_COSTS_TYPE]: selectCostTypes(),
        [realEstateGeneralFields.PROPERTY_PRIMARY_USAGE_ID]: selectPropertyValuesPrimaryUsageFormOptions(state),
        [realEstateGeneralFields.PROPERTY_LAND_USAGE_ID]: selectPropertyValuesLandUsageFormOptions(state),
    }))

    return selectOptions
}

const usePropertyTransactionFormSelectOptions = () => {
    const selectOptions = useSelector((state) => ({
        [propertyTransactionsFields.PROPERTY_STATUS_ID]: selectPropertyValuesStatusFormOptions(state),
        [propertyTransactionsFields.CONTACT_ID]: selectPropertyContactAsyncSelectedFormOptions(state),
        [propertyTransactionsFields.PROPERTY_TRANSACTION_RISK_DEGREE_ID]: selectPropertyValuesPropertyTransactionRiskDegreeFormOptions(state),
        [propertyTransactionsFields.PROPERTY_MAIN_REGISTER_ID]: selectPropertyValuesMainRegisterFormOptions(state),
        [propertyTransactionsFields.PROPERTY_MAIN_REGISTER_DEPARTMENT_ID]: selectPropertyValuesMainRegisterDepartmentFormOptions(state),
        [propertyTransactionsFields.PROPERTY_PUK_ID]: selectPropertyValuesPukFormOptions(state),
        [propertyTransactionsFields.PROPERTY_PUK_DEPARTMENT_ID]: selectPropertyValuesPukDepartmentFormOptions(state),
        [propertyTransactionsFields.PROPERTY_REGISTRY_DEPARTMENT_KPU_ID]: selectPropertyValuesRegistryDepartmentKpuFormOptions(state),
        [propertyTransactionsFields.PROPERTY_REGISTRY_DEPARTMENT_KPU_SUBINSERT_ID]:
            selectPropertyValuesRegistryDepartmentKpuSubinsertFormOptions(state),
        [propertyTransactionsFields.PROPERTY_TRANSACTION_AGENT_ID]: selectAgentsFormOption(state),
        [propertyTransactionsFields.PROPERTY_LAND_REGISTRY_STATUS_ID]: selectPropertyValuesLandRegistryStatusFormOptions(state),
        [propertyTransactionsFields.PROPERTY_OWNERSHIP_ACQUISITION_ID]: selectPropertyValuesOwnershipAcquisitionFormOptions(state),
        [propertyTransactionsFields.PROPERTY_OWNERSHIP_ACQUISITION_PROOF_ID]: selectPropertyValuesOwnershipAcquisitionProofFormOptions(state),
        [propertyTransactionsFields.COMMISION_BUYER_TYPE_ID]: selectPropertyValuesTransactionCommissionTypeFormOptions(state),
        [propertyTransactionsFields.COMMISION_SELLER_TYPE_ID]: selectPropertyValuesTransactionCommissionTypeFormOptions(state),
        [propertyTransactionsFields.PROPERTY_CADASTRAL_MUNCIPALITY]: selectPropertyValuesCadastralMunicipalityFormOptions(state),
    }))

    return selectOptions
}

const useContactGeneralFormSelectOptions = () => {
    const selectOptions = useSelector((state) => ({
        [contactGeneralFields.CONTACT_TITLE_ID]: selectContactValuesTitleFormOptions(state),
        [contactGeneralFields.CONTACT_PRIORITY_ID]: selectContactValuesPriorityFormOptions(state),
        [contactGeneralFields.CONTACT_PHONE_TYPE_ID]: selectContactValuesPhoneTypeFormOptions(state),
        [contactGeneralFields.CONTACT_EMAIL_TYPE_ID]: selectContactValuesEmailTypeFormOptions(state),
        [contactGeneralFields.CONTACT_QUERY_CATEGORY_ID]: selectContactValuesQueryCategoryFormOptions(state),
        [contactGeneralFields.CONTACT_STATUS_ID]: selectContactValuesStatusFormOptions(state),
        [contactGeneralFields.CONTACT_COUNTRY_ID]: selectContactValuesCountryFormOptions(state),
        [contactGeneralFields.DATA_SOURCE_ID]: selectPropertyValuesDataSourceFormOptions(state),
        [contactGeneralFields.CONTACT_SOURCE_ID]: selectContactValuesSourceFormOptions(state),
        [contactGeneralFields.AGENT_ID]: selectAgentsFormOption(state),
    }))

    return selectOptions
}

const useNoteFormSelectOptions = () => {
    const selectOptions = useSelector((state) => ({
        [projectNoteFields.PROJECT_NOTE_AGENT_ID]: selectAgentsFormOption(state),
    }))

    return selectOptions
}

const useContactDocumentFormSelectOptions = (contactType) => {
    const selectOptions = useSelector((state) => ({
        [contactDocumentFields.DOCUMENT_CONTACT_ID]:
            contactType === CONTACT_TYPE.COMPANY
                ? selectCompanyAsycDocumentCompanyFormOptions(state)
                : selectContactAsycDocumentContactFormOptions(state),
        [contactDocumentFields.DOCUMENT_TYPE]: selectDocumentValuesTypeFormOptions(state),
    }))

    return selectOptions
}

const useContactOtherInfoFormSelectOptions = () => {
    const selectOptions = useSelector((state) => ({
        [contactOtherInfoFields.CONTACT_GENDER_ID]: selectContactValuesGenderFormOptions(state),
        // [contactOtherInfoFields.CONTACT_DEFINITION_ID]: selectContactValuesDefinitionFormOptions(state),
        [contactOtherInfoFields.CONTACT_DESCRIPTION_ID]: selectContactValuesDescriptionFormOptions(state),
        [contactOtherInfoFields.CONTACT_MARTIAL_STATUS_ID]: selectContactValuesMartialStatusFormOptions(state),
        [contactOtherInfoFields.CONTACT_JOB_TITLE_ID]: selectContactValuesJobTitleFormOptions(state),
        [contactOtherInfoFields.COMPANY_ID]: selectContactAsyncSelectedFormOptions(state),
    }))

    return selectOptions
}

const useDocumentFormSelectOptions = (contactType, parentEl) => {
    let docuumentContactSelectOptions = []
    if (parentEl) {
        docuumentContactSelectOptions.push({
            label: parentEl?.title,
            value: parentEl?.id?.toString(),
        })
    }

    const selectOptions = useSelector((state) => ({
        [documentFields.DOCUMENT_TYPE]: selectDocumentValuesTypeFormOptions(state),
        [documentFields.DOCUMENT_CONTACT_ID]: docuumentContactSelectOptions.length
            ? docuumentContactSelectOptions
            : contactType === CONTACT_TYPE.COMPANY
            ? selectCompanyAsycDocumentCompanyFormOptions(state)
            : contactType === CONTACT_TYPE.PERSON
            ? selectContactAsycDocumentContactFormOptions(state)
            : [],
        // [contactOtherInfoFields.CONTACT_DEFINITION_ID]: selectContactValuesDefinitionFormOptions(state),
    }))

    return selectOptions
}

const useContactProfileFormSelectOptions = () => {
    const selectOptions = useSelector((state) => ({
        [contactProfileFields.CONTACT_PROFILE_SERVICE_ID]: selectContactValuesProfileServiceFormOptions(state),
        [contactProfileFields.PROPERTY_TYPE_ID]: selectPropertyTypesFormOptions(state),
        [contactProfileFields.PROPERTY_SUB_TYPE_ID]: selectPropertySubtypesFormOptions(state),
        [contactProfileFields.CONTACT_PROFILE_STATUS_ID]: selectContactValuesProfileStatusFormOptions(state),
        [contactProfileFields.CONTACT_PROFILE_REMARK_ID]: selectContactValuesProfileRemarkFormOptions(state),
        [contactProfileFields.CONTACT_FINANCING_ID]: selectContactValuesFinancingFormOptions(state),
        [contactProfileFields.CONTACT_PROFILE_DURATION_ID]: selectContactValuesProfileDurationFormOptions(state),
        [contactProfileFields.CONTACT_DEFINITION_ID]: selectContactValuesDefinitionFormOptions(state),
        [contactProfileFields.COUNTRY_ID]: selectLocationsCountryFormOptions(state),
        [contactProfileFields.COUNTY_ID]: selectLocationsCountyFormOptions(state),
        [contactProfileFields.CITY_ID]: selectLocationsCityFormOptions(state),
        [contactProfileFields.WIDER_AREA_ID]: selectLocationsWiderAreaFormOptions(state),
        [contactProfileFields.QUARTER_ID]: selectLocationsQuarterFormOptions(state),
        [contactProfileFields.ISLAND_ID]: selectLocationsQIslandFormOptions(state),
        [contactProfileFields.PROPERTY_FLOOR_POSITION_ID]: selectPropertyValuesFloorPositionFormOptions(state),
        [contactProfileFields.PROPERTY_SPACE_ID]: selectPropertyValuesSpaceFormOptions(state),
        [contactProfileFields.PROPERTY_EQUIPMENT_ID]: selectPropertyValuesEquipmentFormOptions(state),
        [contactProfileFields.PROPERTY_UTILITY_ID]: selectPropertyValuesUtilityFormOptions(state),
        [contactProfileFields.PROPERTY_HEATING_ID]: selectPropertyValuesHeatingFormOptions(state),
        [contactProfileFields.PROPERTY_DESCRIPTIONS_ID]: selectPropertyValuesDescriptionsrFormOptions(state),
        [contactProfileFields.PROPERTY_FLOOR_ID]: selectPropertyValuesFloorFormOptions(state),
    }))

    return selectOptions
}

const useCompanyGeneralFormSelectOptions = () => {
    const selectOptions = useSelector((state) => ({
        [companyGeneralFields.CONTACT_PRIORITY_ID]: selectContactValuesPriorityFormOptions(state),
        [companyGeneralFields.CONTACT_LEGAL_FORM_ID]: selectContactValuesLegalFormFormOptions(state),
        [companyGeneralFields.CONTACT_PHONE_TYPE_ID]: selectContactValuesPhoneTypeFormOptions(state),
        [companyGeneralFields.CONTACT_EMAIL_TYPE_ID]: selectContactValuesEmailTypeFormOptions(state),
        [companyGeneralFields.CONTACT_STATUS_ID]: selectContactValuesStatusFormOptions(state),
        [companyGeneralFields.CONTACT_COUNTRY_ID]: selectContactValuesCountryFormOptions(state),
        [companyGeneralFields.AGENT_ID]: selectAgentsFormOption(state),
        [companyGeneralFields.DATA_SOURCE_ID]: selectPropertyValuesDataSourceFormOptions(state),
        [companyGeneralFields.CONTACT_SOURCE_ID]: selectContactValuesSourceFormOptions(state),
        [companyGeneralFields.CONTACT_QUERY_CATEGORY_ID]: selectContactValuesQueryCategoryFormOptions(state),
    }))

    return selectOptions
}

const useCompanyOtherInfoFormSelectOptions = () => {
    const selectOptions = useSelector((state) => ({
        [companyOtherInfoFields.CONTACT_CORE_BUSINESS_ID]: selectContactValuesCoreBusinessFormOptions(state),

        [companyOtherInfoFields.CONTACT_COMPANY_SIZE_ID]: selectContactValuesCompanySizeFormOptions(state),
        [companyOtherInfoFields.CONTACT_DESCRIPTION_ID]: selectContactValuesDescriptionFormOptions(state),
    }))

    return selectOptions
}

const useCompanyProfileFormSelectOptions = () => {
    const selectOptions = useSelector((state) => ({
        [contactProfileFields.CONTACT_PROFILE_SERVICE_ID]: selectContactValuesProfileServiceFormOptions(state),
        [contactProfileFields.PROPERTY_TYPE_ID]: selectPropertyTypesFormOptions(state),
        [contactProfileFields.PROPERTY_SUB_TYPE_ID]: selectPropertySubtypesFormOptions(state),
        [contactProfileFields.CONTACT_PROFILE_STATUS_ID]: selectContactValuesProfileStatusFormOptions(state),
        [contactProfileFields.CONTACT_PROFILE_REMARK_ID]: selectContactValuesProfileRemarkFormOptions(state),
        [contactProfileFields.CONTACT_FINANCING_ID]: selectContactValuesFinancingFormOptions(state),
        [contactProfileFields.CONTACT_PROFILE_DURATION_ID]: selectContactValuesProfileDurationFormOptions(state),
        [companyProfileFields.COMPANY_DEFINITION_ID]: selectContactValuesCompannyDefinitionFormOptions(state),
        [contactProfileFields.COUNTRY_ID]: selectLocationsCountryFormOptions(state),
        [contactProfileFields.COUNTY_ID]: selectLocationsCountyFormOptions(state),
        [contactProfileFields.CITY_ID]: selectLocationsCityFormOptions(state),
        [contactProfileFields.WIDER_AREA_ID]: selectLocationsWiderAreaFormOptions(state),
        [contactProfileFields.QUARTER_ID]: selectLocationsQuarterFormOptions(state),
        [contactProfileFields.ISLAND_ID]: selectLocationsQIslandFormOptions(state),
        [contactProfileFields.PROPERTY_FLOOR_POSITION_ID]: selectPropertyValuesFloorPositionFormOptions(state),
        [contactProfileFields.PROPERTY_SPACE_ID]: selectPropertyValuesSpaceFormOptions(state),
        [contactProfileFields.PROPERTY_EQUIPMENT_ID]: selectPropertyValuesEquipmentFormOptions(state),
        [contactProfileFields.PROPERTY_UTILITY_ID]: selectPropertyValuesUtilityFormOptions(state),
        [contactProfileFields.PROPERTY_HEATING_ID]: selectPropertyValuesHeatingFormOptions(state),
        [contactProfileFields.PROPERTY_DESCRIPTIONS_ID]: selectPropertyValuesDescriptionsrFormOptions(state),
        [contactProfileFields.PROPERTY_FLOOR_ID]: selectPropertyValuesFloorFormOptions(state),
    }))

    return selectOptions
}

const useProjectGeneralFormSelectOptions = () => {
    const selectOptions = useSelector((state) => ({
        [projectGeneralFields.PROJECT_DEVELOPMENT_STAGE_ID]: selectProjectValuesDevelopmentStageFormOptions(state),
        [projectGeneralFields.PROJECT_OBJECT_TYPE_ID]: selectProjectValuesObjectTypeFormOptions(state),
        [projectGeneralFields.PROJECT_SIZE_ID]: selectProjectValuesSizeFormOptions(state),
        [projectGeneralFields.PROJECT_STATUS_ID]: selectProjectValuesStatusFormOptions(state),
        [projectGeneralFields.PROJECT_TYPE_ID]: selectProjectValuesTypeFormOptions(state),
        [projectGeneralFields.COUNTRY_ID]: selectLocationsCountryFormOptions(state),
        [projectGeneralFields.COUNTY_ID]: selectLocationsCountyFormOptions(state),
        [projectGeneralFields.CITY_ID]: selectLocationsCityFormOptions(state),
        [projectGeneralFields.WIDER_AREA_ID]: selectLocationsWiderAreaFormOptions(state),
        [projectGeneralFields.QUARTER_ID]: selectLocationsQuarterFormOptions(state),
        [projectGeneralFields.ISLAND_ID]: selectLocationsQIslandFormOptions(state),
        [projectGeneralFields.PROJECT_ENERGY_EFFICIENCY_ID]: selectPropertyValuesEnergyEfficiencyFormOptions(state),
        [projectGeneralFields.AGENT_ID]: selectAgentsFormOption(state),
        [`${projectGeneralFields.AGENT_ID}_secondary`]: selectAgentsFormOption(state),
        [projectGeneralFields.OWNER_ID]: selectProjectAsyncOwnerFormOptions(state),
    }))

    return selectOptions
}

const useActivityFormSelectOptions = (id, detailView = '') => {
    const selectOptions = useSelector((state) => {
        let selectContactIdOptionsResults = []

        if (id) {
            selectContactIdOptionsResults = selectActivityContactAsyncSelectedFormOptions(state, id)
        } else {
            if (detailView === DETAIL_VIEW.CONTACT) {
                selectContactIdOptionsResults = selectContactAsycDocumentContactFormOptions(state)
            }
        }

        let selectContactCompanyOptionsResult = []

        if (id) {
            selectContactCompanyOptionsResult = selectActivityContactCompanyCollectionAsyncSelectedFormOptions(state, id)
        } else {
            if (detailView === DETAIL_VIEW.COMPANY) {
                selectContactCompanyOptionsResult = selectCompanyAsycDocumentCompanyFormOptions(state)
            }
            if (detailView === DETAIL_VIEW.CONTACT) {
                selectContactCompanyOptionsResult = selectContactCompanyAsycDocumentContactFormOptions(state)
            }
        }

        return {
            [activityFields.ACTIVITY_TYPE_ID]: selectActivityValuesTypeFormOptions(state),
            [activityFields.AGENT_ID]: selectAgentsFormOption(state),
            [activityFields.REMINDER_TYPE]: selectActivityReminderTypeSelectedFormOptions(state),
            [activityFields.CONTACT_ID]: selectContactIdOptionsResults,
            [activityFields.CONTACT_COMPANY_COLLECTION]: selectContactCompanyOptionsResult,
            [activityFields.PROJECT_ID]: selectActivityProjectAsyncSelectedFormOptions(state, id),
            [activityFields.PROPERTY_ID]:
                detailView !== DETAIL_VIEW.PROPERTY
                    ? selectActivityPropertyAsyncSelectedFormOptions(state, id)
                    : selectPropertyAsyncSelectedFormOptions(state),
        }
    })

    return selectOptions
}

const useRealEstateGeneralFormConfig = (id, realEstateType, realEstateService, data) => {
    const [formInputs, setFormInputs] = useState({})
    const [formikConfig, setFormikConfig] = useState({})
    const { currentUserId, ...appSettings } = useSelector((state) => ({
        currency: selectApplicationCurrency(state),
        lang: selectSettingsDefaultLanguage(state),
        areaUnit: selectApplicationAreaUnit(state),
        currentUserId: selectCurrentUserId(state),
    }))

    const isEdit = id > 0

    useEffect(() => {
        let _formikInputs = null
        let _formikConfig = null

        const _realEstateType = realEstateService && realEstateType ? parseInt(realEstateType) : null

        switch (_realEstateType) {
            case REAL_ESTATE_TYPES.flat:
                if (realEstateService?.toString() === REAL_ESTATE_SERVICE.sale?.toString()) {
                    _formikInputs = realEstateGeneralFlatSaleInputs(isEdit, appSettings)
                    _formikConfig = generalRealEstateFlatSaleValidaton(
                        appSettings,
                        data ?? {
                            propertyService: { id: REAL_ESTATE_SERVICE.sale },
                            propertyType: { id: REAL_ESTATE_TYPES.flat },
                        }
                    )
                } else if (realEstateService?.toString() === REAL_ESTATE_SERVICE.lease?.toString()) {
                    _formikInputs = realEstateGeneralFlatLeaseInputs(isEdit, appSettings)
                    _formikConfig = generalRealEstateFlatLeaseValidaton(
                        appSettings,
                        data ?? {
                            propertyService: { id: REAL_ESTATE_SERVICE.lease },
                            propertyType: { id: REAL_ESTATE_TYPES.flat },
                        }
                    )
                }
                break
            case REAL_ESTATE_TYPES.house:
                if (realEstateService?.toString() === REAL_ESTATE_SERVICE.sale?.toString()) {
                    _formikInputs = realEstateGeneralHouseSaleInputs(isEdit, appSettings)
                    _formikConfig = propertyGeneralHouseSaleValidaton(
                        appSettings,
                        data ?? {
                            propertyService: { id: REAL_ESTATE_SERVICE.sale },
                            propertyType: { id: REAL_ESTATE_TYPES.house },
                        }
                    )
                } else if (realEstateService?.toString() === REAL_ESTATE_SERVICE.lease?.toString()) {
                    _formikInputs = realEstateGeneralHouseLeaseInputs(isEdit, appSettings)
                    _formikConfig = propertyGeneralHouseLeaseValidaton(
                        appSettings,
                        data ?? {
                            propertyService: { id: REAL_ESTATE_SERVICE.lease },
                            propertyType: { id: REAL_ESTATE_TYPES.house },
                        }
                    )
                }
                break
            case REAL_ESTATE_TYPES.officeSpace:
                if (realEstateService?.toString() === REAL_ESTATE_SERVICE.sale?.toString()) {
                    _formikInputs = realEstateGeneralOfficeSpaceSaleInputs(isEdit, appSettings)
                    _formikConfig = propertyGeneralOfficeSpaceSaleValidaton(
                        appSettings,
                        data ?? {
                            propertyService: { id: REAL_ESTATE_SERVICE.sale },
                            propertyType: { id: REAL_ESTATE_TYPES.officeSpace },
                        }
                    )
                } else if (realEstateService?.toString() === REAL_ESTATE_SERVICE.lease?.toString()) {
                    _formikInputs = realEstateGeneralOfficeSpaceLeaseInputs(isEdit, appSettings)
                    _formikConfig = propertyGeneralOfficeSpaceLeaseValidaton(
                        appSettings,
                        data ?? {
                            propertyService: { id: REAL_ESTATE_SERVICE.lease },
                            propertyType: { id: REAL_ESTATE_TYPES.officeSpace },
                        }
                    )
                }
                break
            case REAL_ESTATE_TYPES.land:
                if (realEstateService?.toString() === REAL_ESTATE_SERVICE.sale?.toString()) {
                    _formikInputs = realEstateGeneralLandSaleInputs(isEdit, appSettings)
                    _formikConfig = propertyGeneralLandSaleValidaton(
                        appSettings,
                        data ?? {
                            propertyService: { id: REAL_ESTATE_SERVICE.sale },
                            propertyType: { id: REAL_ESTATE_TYPES.land },
                        }
                    )
                } else if (realEstateService?.toString() === REAL_ESTATE_SERVICE.lease?.toString()) {
                    _formikInputs = realEstateGeneralLandLeaseInputs(isEdit, appSettings)
                    _formikConfig = propertyGeneralLandLeaseValidaton(
                        appSettings,
                        data ?? {
                            propertyService: { id: REAL_ESTATE_SERVICE.lease },
                            propertyType: { id: REAL_ESTATE_TYPES.land },
                        }
                    )
                }
                break
            case REAL_ESTATE_TYPES.garage:
                if (realEstateService?.toString() === REAL_ESTATE_SERVICE.sale?.toString()) {
                    _formikInputs = realEstateGeneralGarageSaleInputs(isEdit, appSettings)
                    _formikConfig = propertyGeneralGarageSaleValidaton(
                        appSettings,
                        data ?? {
                            propertyService: { id: REAL_ESTATE_SERVICE.sale },
                            propertyType: { id: REAL_ESTATE_TYPES.garage },
                        }
                    )
                } else if (realEstateService?.toString() === REAL_ESTATE_SERVICE.lease?.toString()) {
                    _formikInputs = realEstateGeneralGarageLeaseInputs(isEdit, appSettings)
                    _formikConfig = propertyGeneralGarageLeaseValidaton(
                        appSettings,
                        data ?? {
                            propertyService: { id: REAL_ESTATE_SERVICE.lease },
                            propertyType: { id: REAL_ESTATE_TYPES.garage },
                        }
                    )
                }
                break
            default:
                _formikInputs = realEstateGeneralInputs(isEdit, appSettings)
                _formikConfig = generalRealEstateValidaton(appSettings, data)
                break
        }

        if (!_formikConfig.initialValues[realEstateGeneralFields.AGENT_ID] && currentUserId) {
            _formikConfig.initialValues[realEstateGeneralFields.AGENT_ID] = currentUserId
        }

        addRequiredValidation(_formikConfig.validationSchema.fields, _formikInputs)
        setFormInputs(_formikInputs)
        setFormikConfig(_formikConfig)
    }, [isEdit, realEstateType, realEstateService, data])

    return {
        formInputs,
        formikConfig,
    }
}

const useRealEstateDescriptionFormConfig = (data) => {
    const [descriptionInputs, setDescriptionInputs] = useState({})
    const [descriptionValidationConfig, setDescriptionValidationConfig] = useState({})

    // TODO: uncomment after sagamiddleware refactor
    // const { locales } = useApplicationSettings()
    const currentLang = localStorage.getItem('i18nextLng')

    const appSettings = useSelector((state) => ({
        currency: selectApplicationCurrency(state),
        lang: currentLang,
        languages: selectLocales(state),
    }))

    useEffect(() => {
        setDescriptionInputs(realEstateDescriptionInputs(appSettings))
        setDescriptionValidationConfig(realEstateDescriptionValidation(data, appSettings.languages))
    }, [data])

    return {
        descriptionInputs,
        descriptionValidationConfig,
    }
}

const useMapFormConfig = (data = {}) => {
    const inputs = mapInputs()
    const [mapValidationConfig, setMapValidationConfig] = useState({})

    useEffect(() => {
        setMapValidationConfig(mapValidation(data))
    }, [data])

    return {
        mapInputs: inputs,
        mapValidationConfig,
    }
}

const usePropertyExportFormConfig = (data = {}) => {
    const [exportInputs, setExportInputs] = useState({})
    const [exportValidationConfig, setExportValidationConfig] = useState({})

    useEffect(() => {
        setExportValidationConfig(propertyExportValidation(data))
    }, [data])

    return {
        exportInputs,
        exportValidationConfig,
    }
}

const usePropertyTransactionsFormConfig = (data, propertyStatusValue) => {
    const [transactionsInputs, setTransactionsInputs] = useState({})
    const [transactionsValidationConfig, setTransactionsValidationConfig] = useState({})
    const appSettings = useSelector((state) => ({
        currency: selectApplicationCurrency(state),
        lang: selectSettingsDefaultLanguage(state),
        areaUnit: selectApplicationAreaUnit(state),
        applicationMarket: selectApplicationMarket(state),
    }))

    useEffect(() => {
        let propertyService = data?.propertyService?.id
        const propertyStatusesForRequiredFields = [
            PROPERTY_STATUS.SOLD,
            PROPERTY_STATUS.SOLD_ARCHIVED,
            PROPERTY_STATUS.RENTED,
            PROPERTY_STATUS.RENTED_ARCHIVED,
        ]
        let isRequiredAccordingToStatus = propertyStatusesForRequiredFields.includes(propertyStatusValue ? parseInt(propertyStatusValue) : null)

        setTransactionsInputs(propertyTransactionsInputs(appSettings, propertyService, propertyStatusValue, isRequiredAccordingToStatus))
        setTransactionsValidationConfig(propertyTransactionsValidation(appSettings, data, propertyService, isRequiredAccordingToStatus))
    }, [data, propertyStatusValue])

    return {
        transactionsInputs,
        transactionsValidationConfig,
    }
}

const useRealEstateMaterials3DVideoFormConfig = (data) => {
    const [materials3DVideoInputs, setMaterials3DVideoInputs] = useState({})
    const [materials3DVideoValidationConfig, setMaterials3DVideoValidationConfig] = useState({})

    const appSettings = useSelector((state) => ({
        currency: selectApplicationCurrency(state),
        lang: selectSettingsDefaultLanguage(state),
        areaUnit: selectApplicationAreaUnit(state),
    }))

    useEffect(() => {
        setMaterials3DVideoInputs(realEstateMaterials3DVideoInputs(appSettings))
        setMaterials3DVideoValidationConfig(realEstateMaterials3DVideoValidation(data))
    }, [data])

    return {
        materials3DVideoInputs,
        materials3DVideoValidationConfig,
    }
}

const useContactGeneralFormConfig = (data) => {
    const [generalInputs, setGeneralInputs] = useState({})
    const [generalValidationConfig, setGeneralValidationConfig] = useState({})
    const { currentUserId } = useSelector((state) => ({
        currentUserId: selectCurrentUserId(state),
    }))

    useEffect(() => {
        setGeneralInputs(contactGeneralInputs())
        setGeneralValidationConfig(
            contactGeneralValidation(
                data
                    ? data
                    : {
                          agent: {
                              id: currentUserId,
                          },
                          contactStatus: {
                              id: '1',
                          },
                      }
            )
        )
    }, [data])

    return {
        generalInputs,
        generalValidationConfig,
    }
}

const useContactOtherInfoFormConfig = (data) => {
    const [otherInfoInputs, setOtherInfoInputs] = useState({})
    const [otherInfoValidationConfig, setOtherInfoValidationConfig] = useState({})

    useEffect(() => {
        setOtherInfoInputs(contactOtherInfoInputs())
        setOtherInfoValidationConfig(contactOtherInfoValidation(data))
    }, [data])

    return {
        otherInfoInputs,
        otherInfoValidationConfig,
    }
}

const useCompanyGeneralFormConfig = (data) => {
    const [generalInputs, setGeneralInputs] = useState({})
    const [generalValidationConfig, setGeneralValidationConfig] = useState({})
    const { currentUserId } = useSelector((state) => ({
        currentUserId: selectCurrentUserId(state),
    }))

    useEffect(() => {
        setGeneralInputs(companyGeneralInputs())
        setGeneralValidationConfig(
            companyGeneralValidation(
                data
                    ? data
                    : {
                          agent: {
                              id: currentUserId,
                          },
                          contactStatus: {
                              id: '1',
                          },
                      }
            )
        )
    }, [data])

    return {
        generalInputs,
        generalValidationConfig,
    }
}

const useCompanyOtherInfoFormConfig = (data) => {
    const [otherInfoInputs, setOtherInfoInputs] = useState({})
    const [otherInfoValidationConfig, setOtherInfoValidationConfig] = useState({})

    useEffect(() => {
        setOtherInfoInputs(companyOtherInfoInputs())
        setOtherInfoValidationConfig(companyOtherInfoValidation(data))
    }, [data])

    return {
        otherInfoInputs,
        otherInfoValidationConfig,
    }
}

const useContactProfileFormConfig = (data, propertyTypeValue, propertyServiceValue) => {
    const [profileInputs, setProfileInputs] = useState({})
    const [profileValidationConfig, setProfileValidationConfig] = useState({})

    const appSettings = useSelector((state) => ({
        currency: selectApplicationCurrency(state),
        lang: selectSettingsDefaultLanguage(state),
        areaUnit: selectApplicationAreaUnit(state),
    }))
    useEffect(() => {
        let isEdit = data ? (data.id ? true : false) : false
        setProfileInputs(contactProfileInputs(appSettings, propertyTypeValue, propertyServiceValue, isEdit))
        setProfileValidationConfig(
            contactProfileValidation(
                data && !_.isEmpty(data)
                    ? data
                    : {
                          contactProfileService: { id: propertyServiceValue },
                          propertyType: { id: propertyTypeValue },
                      },
                propertyTypeValue,
                propertyServiceValue
            )
        )
    }, [data, propertyTypeValue, propertyServiceValue])

    return {
        profileInputs,
        profileValidationConfig,
    }
}

const useCompanyProfileFormConfig = (data, propertyTypeValue, propertyServiceValue) => {
    const [profileInputs, setProfileInputs] = useState({})
    const [profileValidationConfig, setProfileValidationConfig] = useState({})

    const appSettings = useSelector((state) => ({
        currency: selectApplicationCurrency(state),
        lang: selectSettingsDefaultLanguage(state),
        areaUnit: selectApplicationAreaUnit(state),
    }))

    useEffect(() => {
        let isEdit = data ? (data.id ? true : false) : false
        setProfileInputs(companyProfileInputs(appSettings, propertyTypeValue, propertyServiceValue, isEdit))
        setProfileValidationConfig(
            companyProfileValidation(
                data && !_.isEmpty(data)
                    ? data
                    : {
                          contactProfileService: { id: propertyServiceValue },
                          propertyType: { id: propertyTypeValue },
                      },
                propertyTypeValue,
                propertyServiceValue
            )
        )
    }, [data, propertyTypeValue, propertyServiceValue])

    return {
        profileInputs,
        profileValidationConfig,
    }
}

const useNoteFormConfig = (data) => {
    const [noteInputs, setNoteInputs] = useState({})
    const [noteValidationConfig, setNoteValidationConfig] = useState({})

    useEffect(() => {
        setNoteInputs(projectNoteInputs())
        setNoteValidationConfig(projectNoteValidation(data))
    }, [data])

    return {
        noteInputs,
        noteValidationConfig,
    }
}

const useContactDocumentFormConfig = (data) => {
    const [documentInputs, setDocumentInputs] = useState({})
    const [documentValidationConfig, setDocumentValidationConfig] = useState({})

    useEffect(() => {
        setDocumentInputs(contactDocumentInputs())
        setDocumentValidationConfig(contactDocumentValidation(data))
    }, [data])

    return {
        documentInputs,
        documentValidationConfig,
    }
}

const useProjectGeneralFormConfig = (data) => {
    const [generalInputs, setGeneralInputs] = useState({})
    const [generalValidationConfig, setGeneralValidationConfig] = useState({})

    const { currentUserId } = useSelector((state) => ({
        currentUserId: selectCurrentUserId(state),
    }))

    useEffect(() => {
        const _formInputs = projectGeneralInputs()
        const _formConfig = projectGeneralValidaton({}, data)

        if (!_formConfig.initialValues[projectGeneralFields.AGENT_ID] && currentUserId) {
            _formConfig.initialValues[projectGeneralFields.AGENT_ID] = currentUserId
        }

        addRequiredValidation(_formConfig.validationSchema.fields, _formInputs)

        setGeneralInputs(_formInputs)
        setGeneralValidationConfig(_formConfig)
    }, [data])

    return {
        generalInputs,
        generalValidationConfig,
    }
}

const useProjectDescriptionFormConfig = (data) => {
    const [descriptionInputs, setDescriptionInputs] = useState({})
    const [descriptionValidationConfig, setDescriptionValidationConfig] = useState({})

    // TODO: uncomment after sagamiddleware refactor
    // const { locales } = useApplicationSettings()
    const currentLang = localStorage.getItem('i18nextLng')

    const appSettings = useSelector((state) => ({
        currency: selectApplicationCurrency(state),
        lang: currentLang,
        languages: selectLocales(state),
    }))

    useEffect(() => {
        setDescriptionInputs(projectDescriptionInputs(appSettings))
        setDescriptionValidationConfig(projectDescriptionValidation(data, appSettings))
    }, [data])

    return {
        descriptionInputs,
        descriptionValidationConfig,
    }
}

const useActivityFormConfig = (data, activityCategory) => {
    const [inputs, setInputs] = useState({})
    const [validationConfig, setValidationConfig] = useState({})
    const config = useSelector((state) => {
        return {
            user: selectCurrentUser(state),
        }
    })

    useEffect(() => {
        switch (parseInt(activityCategory)) {
            case ACTIVITY_CATEGORY.EVENT:
                setInputs(activityEventInputs())
                setValidationConfig(activityEventValidaton(data, config))
                break
            case ACTIVITY_CATEGORY.TASK:
                setInputs(activityTaskInputs())
                setValidationConfig(activityTaskValidaton(data, config))
                break
            case ACTIVITY_CATEGORY.REMINDER:
                setInputs(activityReminderInputs())
                setValidationConfig(activityReminderValidaton(data, config))
                break
            default:
                setInputs(activityEventInputs())
                setValidationConfig(activityEventValidaton(data, config))
                break
        }
    }, [data, activityCategory])

    return {
        inputs,
        validationConfig,
    }
}

const useDocumentFormConfig = (data) => {
    const [documentInputs, setDocumentInputs] = useState({})
    const [documentValidationConfig, setDocumentValidationConfig] = useState({})

    useEffect(() => {
        let isEdit = data?.id > 0 ? true : false

        setDocumentInputs(documentsInputs(isEdit))
        setDocumentValidationConfig(documentValidation(data))
    }, [data])

    return {
        documentInputs,
        documentValidationConfig,
    }
}

export {
    useActivityFormConfig,
    useActivityFormSelectOptions,
    useCompanyGeneralFormConfig,
    useCompanyGeneralFormSelectOptions,
    useCompanyOtherInfoFormConfig,
    useCompanyOtherInfoFormSelectOptions,
    useCompanyProfileFormConfig,
    useCompanyProfileFormSelectOptions,
    useContactDocumentFormConfig,
    useContactDocumentFormSelectOptions,
    useContactGeneralFormConfig,
    useContactGeneralFormSelectOptions,
    useContactOtherInfoFormConfig,
    useContactOtherInfoFormSelectOptions,
    useContactProfileFormConfig,
    useContactProfileFormSelectOptions,
    useDocumentFormConfig,
    useDocumentFormSelectOptions,
    useMapFormConfig,
    useNoteFormConfig,
    useNoteFormSelectOptions,
    useProjectDescriptionFormConfig,
    useProjectGeneralFormConfig,
    useProjectGeneralFormSelectOptions,
    usePropertyExportFormConfig,
    usePropertyTransactionFormSelectOptions,
    usePropertyTransactionsFormConfig,
    useRealEstateDescriptionFormConfig,
    useRealEstateGeneralFormConfig,
    useRealEstateGeneralFormSelectOptions,
    useRealEstateMaterials3DVideoFormConfig,
}
