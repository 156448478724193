export const iconsSrc = [
    {
        id: 5,
        src: 'images/google-maps-icons/garage.png',
    },
    {
        id: 1,
        src: 'images/google-maps-icons/kuca.png',
    },
    {
        id: 4,
        src: 'images/google-maps-icons/land.png',
    },
    {
        id: 3,
        src: 'images/google-maps-icons/poslovni.png',
    },
    {
        id: 2,
        src: 'images/google-maps-icons/stan.png',
    },
]
