import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import { Button, Card, CardBody, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from 'reactstrap'
import AppPermissionsSwitcher from '../../../Components/AppPermissionsSwitcher/AppPermissionsSwitcher'
import DeleteModal from '../../../Components/Common/DeleteModal'
import PageTitle from '../../../Components/Common/PageTitle'
import TableContainer from '../../../Components/Table/TableContainer'
import { useFormatTableData } from '../../../Components/Table/utils'
import { MODAL_TYPES } from '../../../common/constants'
import { useLocationChange } from '../../../hooks'
import { fetchAgents } from '../../../store/agents/actions'
import { deleteContact, deleteContactMultiple, fetchContacts } from '../../../store/contacts'
import { selectContacts, selectContactsCount, selectContactsFetching, selectContactsPagination } from '../../../store/contacts/selectors'
import { showModal } from '../../../store/modal'
import { clearDetailSearchContactsCriteria, selectSearchCriteriaContacts } from '../../../store/search'
import { resetTableCheckbox, selectCheckedTableRows } from '../../../store/table'
import TableColumns from './TableColumns/TableColumns'

const ContactList = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const { CHANGE_AGENT_MODAL } = MODAL_TYPES

    const [rowId, setRowId] = useState(null)
    const [deleteModal, setDeleteModal] = useState(false)
    const [deleteModalMulti, setDeleteModalMulti] = useState(false)
    const [pageCount, setPageCount] = useState(0)

    const { pagination, contacts, contactsFetching, contactsCount, contactSearchCriteria, selectedTableRows } = useSelector((state) => ({
        contacts: selectContacts(state),
        contactsFetching: selectContactsFetching(state),
        contactsCount: selectContactsCount(state),
        contactSearchCriteria: selectSearchCriteriaContacts(state),
        selectedTableRows: selectCheckedTableRows(state),
        pagination: selectContactsPagination(state),
    }))

    /* useLocationChange((location, prevLocation) => {
        // console.log('Location changed', location)
        // console.log('location prev', prevLocation)
        // console.log({propertySearchCriteria})
        if (location.pathname === '/contacts' && prevLocation && prevLocation.pathname === '/contacts' && location.state && location.state.clearDetailSearch) {
            if (contactSearchCriteria) {
                dispatch(clearDetailSearchContactsCriteria())
            }
        }
    })

    useEffect(() => {
        return () => {
            dispatch(clearDetailSearchContactsCriteria())
        }
    }, []) */

    const appModule = 'contact'

    const onClickDelete = useCallback(
        (id) => {
            setRowId(id)
            setDeleteModal(true)
        },
        [setDeleteModal]
    )

    const handleDeleteContact = () => {
        dispatch(deleteContact(rowId))
        dispatch(resetTableCheckbox(true))
        setDeleteModal(false)
    }

    const deleteMultipleContacts = () => {
        const selectedRowsIds = selectedTableRows.map((row) => row.id)

        dispatch(deleteContactMultiple(selectedRowsIds))
        dispatch(resetTableCheckbox(true))
        setDeleteModalMulti(false)
    }

    const handleChangeAgentModal = () => {
        const payload = {
            modalType: CHANGE_AGENT_MODAL,
        }

        dispatch(showModal(payload))
        dispatch(fetchAgents())
    }

    const handleDataFetch = (page, limit, order, criteria) => {
        dispatch(
            fetchContacts(
                {
                    page,
                    limit,
                    order,
                    criteria: {
                        ...criteria,
                        contactType: 'person',
                    },
                },
                (count) => setPageCount(Math.ceil(count / limit))
            )
        )
    }

    const handleRowClick = (id) => {
        history.push(`/contacts/${id}`)
    }

    const tableData = useFormatTableData(contacts, 'contacts')
    const columns = TableColumns(onClickDelete, 'contacts')

    const tableActions = () => (
        <div className="d-flex flex-grow-1">
            <Button tag={Link} to="/contacts/new" color="add" className="btn-label">
                <i className="mdi mdi-account-outline align-bottom label-icon align-middle fs-16 me-2"></i>
                {t('button.add')}
            </Button>
            <UncontrolledDropdown className="ms-2">
                <DropdownToggle title={t('table.showMore')} href="#" className="btn btn-primary dropdown" tag="button">
                    <i className="mdi mdi-dots-horizontal align-middle"></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem href="#" onClick={() => setDeleteModalMulti(true)}>
                        <i className="mdi mdi-trash-can-outline align-bottom me-2 text-muted"></i> {t('table.action.deleteSelected')}
                    </DropdownItem>
                    <AppPermissionsSwitcher permission="ChangeAgent">
                        {({ hasPermission }) =>
                            hasPermission && (
                                <DropdownItem onClick={handleChangeAgentModal} disabled={!selectedTableRows.length}>
                                    <i className="mdi mdi-account-switch-outline align-bottom me-2 text-muted"></i> {t('app.common.switchAgent')}
                                </DropdownItem>
                            )
                        }
                    </AppPermissionsSwitcher>
                </DropdownMenu>
            </UncontrolledDropdown>
        </div>
    )

    return (
        <React.Fragment>
            <div className="page-content">
                <DeleteModal show={deleteModal} onDeleteClick={() => handleDeleteContact()} onCloseClick={() => setDeleteModal(false)} />
                <DeleteModal show={deleteModalMulti} onDeleteClick={() => deleteMultipleContacts()} onCloseClick={() => setDeleteModalMulti(false)} />

                <Container fluid>
                    <PageTitle title={t('app.common.contacts')} />
                    <Row>
                        <Col lg={12}>
                            <Card id="RealEstatesList">
                                <CardBody>
                                    <TableContainer
                                        tableActions={tableActions}
                                        hasColumnSelection={true}
                                        columns={columns}
                                        data={tableData || []}
                                        isGlobalFilter={false}
                                        isAddUserList={false}
                                        customPageSize={20}
                                        className="custom-header-css"
                                        theadClass={'dmn-table-header'}
                                        isRealEstatesListFilter={true}
                                        hasPageSizeSelect={true}
                                        isLoading={contactsFetching}
                                        handleDataFetch={handleDataFetch}
                                        handleRowClick={handleRowClick}
                                        totalCount={contactsCount}
                                        pageCount={pageCount}
                                        searchCriteria={contactSearchCriteria}
                                        tableHash={appModule}
                                        currentPage={pagination.currentPage}
                                        totalPages={pagination.totalPages}
                                        permissionName={'module.contact.view'}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default ContactList
