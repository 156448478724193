import { all, call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { apiRequest } from "../api";
import { selectPropertyAvailableEntities } from "../propertyAvailableEntities";
import { fetchEntityValues, fetchEntityValuesFailure, fetchEntityValuesRequest, fetchEntityValuesSuccess } from "./actions";

export function* fetchEntityValuesSaga() {
    yield put(fetchEntityValuesRequest());

    const availableEntities = yield select(selectPropertyAvailableEntities);

    try {
        // RETHINK THIS
        // console.log('fetching values', availableEntities)
        // const promises =  yield availableEntities.map(file => apiRequest(`v1/values/${file}`, {
        //     method: 'GET'
        //     }));
        // const result = yield all(promises);
        // yield put(fetchEntityValuesSuccess({result, availableEntities}));
    } catch (error) {
        yield put(fetchEntityValuesFailure(error));
    }
}

export default function* actionWatcher() {
    yield takeLatest(`${fetchEntityValues}`, fetchEntityValuesSaga);
}