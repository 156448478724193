import { useEffect } from "react";
import { DETAIL_SEARCH_TYPE, REAL_ESTATE_TYPES } from "../common/constants";
import { getIn } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { fetchDetailSearchLocationsCity, fetchDetailSearchLocationsCounty, fetchDetailSearchLocationsQuarter, fetchDetailSearchLocationsWiderArea, fetchDetailSearchPropertySubtypes, fetchDetailSearchPropertyValuesFloor } from "../store/searchValues";
import { selectSettingsPropertyFlatSubTypes, selectSettingsPropertyGarageSubTypes, selectSettingsPropertyHouseSubTypes, selectSettingsPropertyLandSubTypes, selectSettingsPropertyOfficeSpaceSubTypes } from "../store/settings";

export const useDetailSearchContactEffect = (type, validation, inputs, additionalInputs, validationConfig, additionalValidationConfig) => {
    const dispatch = useDispatch();
    const { propertyFlatSubTypes, propertyGarageSubTypes, propertyHouseSubTypes, propertyOfficeSpaceSubTypes, propertyLandSubTypes } = useSelector(state => {
        return {
            propertyFlatSubTypes: selectSettingsPropertyFlatSubTypes(state),
            propertyGarageSubTypes: selectSettingsPropertyGarageSubTypes(state),
            propertyHouseSubTypes: selectSettingsPropertyHouseSubTypes(state),
            propertyLandSubTypes: selectSettingsPropertyLandSubTypes(state),
            propertyOfficeSpaceSubTypes: selectSettingsPropertyOfficeSpaceSubTypes(state),
        }
    });

    // Contact && company specific
    const countryMainValue = getIn(validation?.values?.[`${validationConfig.formGroup}`], 'country');
    const countryAdditionalValue = getIn(validation?.values?.[`${additionalValidationConfig?.formGroup}`], 'country');
    const cityAdditionalValue = getIn(validation?.values?.[`${additionalValidationConfig?.formGroup}`], 'city');
    const countyAdditionalValue = getIn(validation?.values?.[`${additionalValidationConfig?.formGroup}`], 'county');
    const widerAreaAdditionalValue = getIn(validation?.values?.[`${additionalValidationConfig?.formGroup}`], 'widerArea');
    const quarterAdditionalValue = getIn(validation?.values?.[`${additionalValidationConfig?.formGroup}`], 'quarter');
    const propertyFloorValue = getIn(validation?.values?.[`${additionalValidationConfig?.formGroup}`], 'propertyFloor');
    const floorFromValue = getIn(validation?.values?.[`${additionalValidationConfig?.formGroup}`], 'floorFrom');
    const floorToValue = getIn(validation?.values?.[`${additionalValidationConfig?.formGroup}`], 'floorTo');
    const propertyTypeValue = getIn(validation?.values?.[`${additionalValidationConfig?.formGroup}`], 'propertyType');
    const propertySubTypeValue = getIn(validation?.values?.[`${additionalValidationConfig?.formGroup}`], 'propertySubType');

    const cityMainIndex = inputs.columns?.[1]?.findIndex(obj => obj.name === 'city');
    const countyAdditionalIndex = additionalInputs?.columns?.[2]?.findIndex(obj => obj.name === 'county');
    const cityAdditionalIndex = additionalInputs?.columns?.[2]?.findIndex(obj => obj.name === 'city');
    const widerAreaAdditionalIndex = additionalInputs?.columns?.[2]?.findIndex(obj => obj.name === 'widerArea');
    const quarterAdditionalIndex = additionalInputs?.columns?.[2]?.findIndex(obj => obj.name === 'quarter');
    const propertyFloorIndex = additionalInputs?.columns?.[1]?.findIndex(obj => obj.name === 'propertyFloor');
    const floorGroupIndex = additionalInputs?.columns?.[1]?.findIndex(obj => obj.name === 'floorGrouped');

    useEffect(() => {
        if(type === DETAIL_SEARCH_TYPE.CONTACT || type === DETAIL_SEARCH_TYPE.COMPANY) {
            
            if(countryAdditionalValue){
                dispatch(fetchDetailSearchLocationsCounty({ additionalOptions: true, country: countryAdditionalValue }))
                if(additionalInputs?.columns){
                    if(countyAdditionalIndex > -1) {
                        additionalInputs.columns[2][countyAdditionalIndex].disabled = false;
                    }
                }
            } else {
                if(additionalValidationConfig) {
                    validation.setFieldValue(`${additionalValidationConfig.formGroup}.county`, []);
                }
                if(additionalInputs?.columns){
                    if(countyAdditionalIndex > -1) {
                        additionalInputs.columns[2][countyAdditionalIndex].disabled = true;
                    }
                }
            }
        }
    }, [type, countryAdditionalValue])

    useEffect(() => {
        if(type === DETAIL_SEARCH_TYPE.CONTACT || type === DETAIL_SEARCH_TYPE.COMPANY) {
            
            if(countyAdditionalValue && countyAdditionalValue.length){
                dispatch(fetchDetailSearchLocationsCity({ additionalOptions: true, county: countyAdditionalValue }))
                if(additionalInputs?.columns){
                    if(cityAdditionalIndex > -1) {
                        additionalInputs.columns[2][cityAdditionalIndex].disabled = false;
                    }
                }
            } else {
                if(additionalValidationConfig) {
                    validation.setFieldValue(`${additionalValidationConfig.formGroup}.city`, []);
                }
                if(additionalInputs?.columns){
                    if(cityAdditionalIndex > -1) {
                        additionalInputs.columns[2][cityAdditionalIndex].disabled = true;
                    }
                }
            }
        }
    }, [type, countyAdditionalValue])

    useEffect(() => {
        if(type === DETAIL_SEARCH_TYPE.CONTACT || type === DETAIL_SEARCH_TYPE.COMPANY) {
            
            if(cityAdditionalValue && cityAdditionalValue.length){
                dispatch(fetchDetailSearchLocationsWiderArea({ additionalOptions: true, city: cityAdditionalValue }))
                if(additionalInputs?.columns){
                    if(widerAreaAdditionalIndex > -1) {
                        additionalInputs.columns[2][widerAreaAdditionalIndex].disabled = false;
                    }
                }
            } else {
                if(additionalValidationConfig) {
                    validation.setFieldValue(`${additionalValidationConfig.formGroup}.widerArea`, []);
                }
                if(additionalInputs?.columns){
                    if(widerAreaAdditionalIndex > -1) {
                        additionalInputs.columns[2][widerAreaAdditionalIndex].disabled = true;
                    }
                }
            }
        }
    }, [type, cityAdditionalValue])

    useEffect(() => {
        if(type === DETAIL_SEARCH_TYPE.CONTACT || type === DETAIL_SEARCH_TYPE.COMPANY) {
            
            if(widerAreaAdditionalValue && widerAreaAdditionalValue.length){
                dispatch(fetchDetailSearchLocationsQuarter({ additionalOptions: true, widerArea: widerAreaAdditionalValue }))
                if(additionalInputs?.columns){
                    if(quarterAdditionalIndex > -1) {
                        additionalInputs.columns[2][quarterAdditionalIndex].disabled = false;
                    }
                }
            } else {
                if(additionalValidationConfig) {
                    validation.setFieldValue(`${additionalValidationConfig.formGroup}.quarter`, []);
                }
                if(additionalInputs?.columns){
                    if(quarterAdditionalIndex > -1) {
                        additionalInputs.columns[2][quarterAdditionalIndex].disabled = true;
                    }
                }
            }
        }
    }, [type, widerAreaAdditionalValue])

    useEffect(() => {
        if (type === DETAIL_SEARCH_TYPE.CONTACT || type === DETAIL_SEARCH_TYPE.COMPANY) {
            if (floorToValue || floorFromValue) {
                if(additionalInputs?.columns){
                    if (floorGroupIndex > -1) {
                        if (propertyTypeValue.some(x => [REAL_ESTATE_TYPES.house.toString(), REAL_ESTATE_TYPES.land.toString()].includes(x))) {
                            additionalInputs.columns[1][floorGroupIndex].warning = "Odabrani tip nekretnine nije moguće pretražiti po ovom podatku."
                        } else {
                            if (propertySubTypeValue && propertySubTypeValue.length) {

                                let hasHouseSubType = propertyHouseSubTypes?.some((item) => {
                                    return propertySubTypeValue.includes(item.id.toString())
                                })

                                let hasLandSubType = propertyLandSubTypes?.some((item) => {
                                    return propertySubTypeValue.includes(item.id.toString())
                                })

                                if (hasHouseSubType || hasLandSubType) {
                                    additionalInputs.columns[1][floorGroupIndex].warning = "Odabrani tip nekretnine nije moguće pretražiti po ovom podatku.";
                                } else {
                                    additionalInputs.columns[1][floorGroupIndex].warning = null;
                                }
                            } else {
                                additionalInputs.columns[1][floorGroupIndex].warning = null;
                            }
                        }
                    }
                }
            } else {
                if (additionalInputs?.columns){
                    if (floorGroupIndex > -1) {
                        additionalInputs.columns[1][floorGroupIndex].warning = null;
                    }
                }
            }
        }
    }, [type, floorFromValue, floorToValue])
        
    useEffect(() => {
        if (type === DETAIL_SEARCH_TYPE.CONTACT || type === DETAIL_SEARCH_TYPE.COMPANY) {
            if (propertyFloorValue && propertyFloorValue.length) {
                if(additionalInputs?.columns){
                    if (propertyFloorIndex > -1) {
                        if (propertyTypeValue.some(x => [REAL_ESTATE_TYPES.garage.toString(), REAL_ESTATE_TYPES.land.toString(), REAL_ESTATE_TYPES.officeSpace.toString()].includes(x))) {
                            additionalInputs.columns[1][propertyFloorIndex].warning = "Odabrani tip nekretnine nije moguće pretražiti po ovom podatku."
                        } else {

                            if (propertySubTypeValue && propertySubTypeValue.length) {

                                let hasOfficeSpaceSubType = propertyOfficeSpaceSubTypes?.some((item) => {
                                    return propertySubTypeValue.includes(item.id.toString())
                                })
    
                                let hasLandSubType = propertyLandSubTypes?.some((item) => {
                                    return propertySubTypeValue.includes(item.id.toString())
                                })
    
                                let hasGarageSubType = propertyGarageSubTypes?.some((item) => {
                                    return propertySubTypeValue.includes(item.id.toString())
                                })
    
                                if (hasOfficeSpaceSubType || hasLandSubType || hasGarageSubType) {
                                    additionalInputs.columns[1][propertyFloorIndex].warning = "Odabrani tip nekretnine nije moguće pretražiti po ovom podatku."
                                } else {
                                    additionalInputs.columns[1][propertyFloorIndex].warning = null;
                                }
                            } else {
                                additionalInputs.columns[1][propertyFloorIndex].warning = null;
                            }
                        }
                    }
                }
            } else {
                if (additionalInputs?.columns){
                    if (propertyFloorIndex > -1) {
                        additionalInputs.columns[1][propertyFloorIndex].warning = null;
                    }
                }
            }
        }
    }, [type, propertyFloorValue])

    useEffect(() => {
        if (type === DETAIL_SEARCH_TYPE.CONTACT || type === DETAIL_SEARCH_TYPE.COMPANY) {
            if (propertyTypeValue && propertyTypeValue.length) {
                const filter = {
                    propertyType: propertyTypeValue,
                }
                dispatch(fetchDetailSearchPropertySubtypes({
                    filter
                }))
                dispatch(fetchDetailSearchPropertyValuesFloor({
                    filter
                }))
                validation.setFieldValue(`contactProfile.propertySubType`, []);
                validation.setFieldValue(`contactProfile.propertyFloor`, []);
                if (additionalInputs?.columns){
                    if (floorGroupIndex > -1) {
                        if (!propertyTypeValue.some(x => [REAL_ESTATE_TYPES.house.toString(), REAL_ESTATE_TYPES.land.toString()].includes(x)) && additionalInputs.columns[1][floorGroupIndex].warning) {
                            additionalInputs.columns[1][floorGroupIndex].warning = null;
                        } else {
                            if(floorToValue ||  floorFromValue) {
                                additionalInputs.columns[1][floorGroupIndex].warning = "Odabrani tip nekretnine nije moguće pretražiti po ovom podatku."
                            }
                        }
                    } 

                    if (propertyFloorIndex > -1) {
                        if (propertyTypeValue.some(x => [REAL_ESTATE_TYPES.garage.toString(), REAL_ESTATE_TYPES.land.toString(), REAL_ESTATE_TYPES.officeSpace.toString()].includes(x)) && additionalInputs.columns[1][floorGroupIndex].warning) {
                            additionalInputs.columns[1][propertyFloorIndex].warning = null;
                        } else {
                            if(propertyFloorValue && propertyFloorValue.length) {
                                additionalInputs.columns[1][propertyFloorIndex].warning = "Odabrani tip nekretnine nije moguće pretražiti po ovom podatku."
                            }
                        }
                    } 
                }
            } else {
                dispatch(fetchDetailSearchPropertySubtypes());
                dispatch(fetchDetailSearchPropertyValuesFloor())
                validation.setFieldValue(`contactProfile.propertySubType`, []);
                validation.setFieldValue(`contactProfilepropertyFloor`, []);
                if (additionalInputs?.columns){
                    if (floorGroupIndex > -1) {
                        additionalInputs.columns[1][floorGroupIndex].warning = null;
                    }

                    if (propertyFloorIndex > -1) {
                        additionalInputs.columns[1][propertyFloorIndex].warning = null;
                    }
                }
            }
        }
    }, [type, propertyTypeValue])

    useEffect(() => {
        if (type === DETAIL_SEARCH_TYPE.CONTACT || type === DETAIL_SEARCH_TYPE.COMPANY) {
            if (propertySubTypeValue && propertySubTypeValue.length) {

                if (additionalInputs?.columns){
                    if (floorGroupIndex > -1) {
                        if (propertyTypeValue && !propertyTypeValue.length) {
                            if (floorFromValue || floorToValue) {
                                let hasHouseSubType = propertyHouseSubTypes?.some((item) => {
                                    return propertySubTypeValue.includes(item.id.toString())
                                })

                                let hasLandSubType = propertyLandSubTypes?.some((item) => {
                                    return propertySubTypeValue.includes(item.id.toString())
                                })

                                if (hasHouseSubType || hasLandSubType) {
                                    additionalInputs.columns[1][floorGroupIndex].warning = "Odabrani tip nekretnine nije moguće pretražiti po ovom podatku."
                                } else {
                                    additionalInputs.columns[1][floorGroupIndex].warning = null;
                                }
                            } else {
                                additionalInputs.columns[1][floorGroupIndex].warning = null;
                            }
                        }
                    }

                    if (propertyFloorIndex > -1) {
                        if (propertyTypeValue && !propertyTypeValue.length) {
                            if (propertyFloorValue && propertyFloorValue.length) {
                                let hasOfficeSpaceSubType = propertyOfficeSpaceSubTypes?.some((item) => {
                                    return propertySubTypeValue.includes(item.id.toString())
                                })

                                let hasLandSubType = propertyLandSubTypes?.some((item) => {
                                    return propertySubTypeValue.includes(item.id.toString())
                                })

                                let hasGarageSubType = propertyGarageSubTypes?.some((item) => {
                                    return propertySubTypeValue.includes(item.id.toString())
                                })

                                if (hasOfficeSpaceSubType || hasLandSubType || hasGarageSubType) {
                                    additionalInputs.columns[1][propertyFloorIndex].warning = "Odabrani tip nekretnine nije moguće pretražiti po ovom podatku."
                                } else {
                                    additionalInputs.columns[1][propertyFloorIndex].warning = null;
                                }
                            } else {
                                additionalInputs.columns[1][propertyFloorIndex].warning = null;
                            }
                        }
                    }
                }
            } else {
                if (additionalInputs?.columns){
                    if (floorGroupIndex > -1) {
                        if (propertyTypeValue && !propertyTypeValue.length) {
                            additionalInputs.columns[1][floorGroupIndex].warning = null;
                        }
                        
                        if (propertyFloorIndex > -1) {
                            additionalInputs.columns[1][propertyFloorIndex].warning = null;
                        }                        
                    }
                }
            }
        }
    }, [type, propertySubTypeValue])
}