export const activityResponseFields = {
    ACTIVITY_TYPE: 'activityType',
    ID: 'id',
    ACTIVITY_TEMPLATE: 'activityTemplate',
    TITLE: 'title',
    DATE_FROM: 'dateFrom',
    TIME_FROM : 'timeFrom',
    DATE_TO: 'dateTo',
    TIME_TO: 'timeTo',
    DURATION_DAY: 'durationDay',
    CONTACT: 'contact',
    PROPERTY: 'property',
    PROJECT: 'project',
    AGENT: 'agent',
    NOTIFICATION_SEND: 'notificationSend',
    REMINDER_TYPE: 'reminderType',
    REMINDER_SEND: 'reminderSend',
    NOTES: 'notes',
    ACTIVITY_AGENT: 'activityAgent',
    ACTIVITY_REMINDER: 'activityReminder',
    ACTIVITY_CATEGORY: 'activityCategory',
    COMPLETED: 'completed',
    CONTACT_COMPANY_COLLECTION: 'contactCompanyCollection',
    CONTACT_COMPANY: 'contactCompany',
}