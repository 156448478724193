import values from 'lodash/values';

import {stateKey} from './actions';

export const selectPropertyServices = state => values(state[stateKey].results);
export const selectPropertyServicesFormOptions = state => {
    const data = values(state[stateKey].items);
    return data.map((service) => (
        {
            value: service.id.toString(),
            label: service.name
        }
    ))
}