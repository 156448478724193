import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { Button } from 'reactstrap'

const NavigationActions = (props) => {
    const { links } = props
    const history = useHistory()
    const { t } = useTranslation()
    const { pathname } = useLocation()
    const urlBase = pathname.split('/')[1]

    const previousItem = () => history.push(`/${urlBase}/${links?.previous}`)
    const nextItem = () => history.push(`/${urlBase}/${links?.next}`)

    return (
        <div className="d-flex justify-content-end mt-3 mt-md-0">
            <Button color="primary" className="btn btn-label me-3" onClick={previousItem} disabled={links?.previous === null}>
                <i className="mdi mdi-chevron-left align-bottom label-icon align-middle fs-16 me-2" />
                <span>{t('button.navigationAction.previous')}</span>
            </Button>
            <Button color="primary" className="btn-label right" onClick={nextItem} disabled={links?.next === null}>
                <i className="mdi mdi-chevron-right align-bottom label-icon align-middle fs-16" />
                <span>{t('button.navigationAction.next')}</span>
            </Button>
        </div>
    )
}

export default NavigationActions
