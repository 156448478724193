import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import {
    Card,
    CardBody,
    Col,
    Container,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    UncontrolledDropdown,
} from 'reactstrap'
import AppPermissionsSwitcher from '../../../Components/AppPermissionsSwitcher/AppPermissionsSwitcher'
import { action } from '../../../Components/AppPermissionsSwitcher/constants/actions'
import BackButton from '../../../Components/BackButton/BackButton'
import CollapseCard from '../../../Components/Common/CollapseCard'
import DeleteModal from '../../../Components/Common/DeleteModal'
import FormTabNavigation from '../../../Components/Common/FormTabNavigation'
import Loader from '../../../Components/Common/Loader'
import NavigationActions from '../../../Components/Common/NavigationActions/NavigationActions'
import ViewDataCard from '../../../Components/Common/ViewDataCard'
import ViewDataList from '../../../Components/Common/ViewDataList'
import DocumentViewTab from '../../../Components/DocumentViewTab/Index'
import MapContainer from '../../../Components/MapContainer'
import NotesViewAccordion from '../../../Components/NotesViewAccordion'
import RelatedPropertiesViewTab from '../../../Components/RelatedPropertiesViewTab'
import Slider from '../../../Components/Slider/Slider'
import SliderOptions from '../../../Components/Slider/SliderOptions'
import images from '../../../api/images/hooks'
import projects from '../../../api/projects/hooks'
import { API_URL } from '../../../common/constants'
import { projectResponseFields } from '../../../common/response/project'
import { propertyResponseFields } from '../../../common/response/property'
import { useProjectGeneralViewData, useProjectViewTabs } from '../../../hooks'
import { useColumnOrder } from '../../../hooks/useColumnOrder'
import { selectCurrentUser } from '../../../store/auth/selectors'
import { deleteDocument, fetchDocuments, selectDocuments, selectDocumentsFetching, syncDocument } from '../../../store/documents'
import { fetchGroundPlans, selectGroundPlans, selectGroundPlansFetching, selectSortedGroundPlans } from '../../../store/groundplans'
import { fetchPhotos, selectPhotosFetching, selectSortedPhotos } from '../../../store/photos'
import { deleteProject, fetchProject, selectProject, selectProjectFetchingDetails, selectProjectLinks } from '../../../store/projects'
import { deleteProperty, fetchProperties, selectProperties, selectPropertiesFetching } from '../../../store/properties'
import { selectSearchCriteriaProjects } from '../../../store/search'
import { selectApplicationAreaUnit, selectApplicationCurrency } from '../../../store/settings/selectors'
import { coerceLatLng, embedYotubeVideo, isApproxLocationSyncType } from '../../../utils/general'
import OfferPrint from './OfferPrint/OfferPrint'

const ProjectView = () => {
    const { t } = useTranslation()
    const history = useHistory()
    const location = useLocation()
    const { id } = useParams()
    const dispatch = useDispatch()

    const currentLang = localStorage.getItem('i18nextLng')
    const refreshToken = localStorage.getItem('refresh_token')

    const { isLoading: fetchingProject, data: projectData } = projects.GetProject.useQuery(id, refreshToken)
    const { isLoading: fetchingPhotos, data: photosData } = images.GetImages.useQuery(id, 'project', 'photo', refreshToken)
    const { isLoading: fetchingGroundPlans, data: groundPlansData } = images.GetImages.useQuery(id, 'project', 'ground_plan', refreshToken)

    const project = !fetchingProject ? projectData?.item : null
    const photos = !fetchingPhotos ? photosData : []
    const groundPlans = !fetchingGroundPlans ? groundPlansData : []

    const { documents, properties, fetchingProperties, fetchingDocuments, currentAgentId, links } = useSelector((state) => ({
        // project: selectProject(state),
        // groundPlans: selectGroundPlans(state),
        // fetching: selectProjectFetchingDetails(state),
        currency: selectApplicationCurrency(state),
        areaUnit: selectApplicationAreaUnit(state),
        fetchingDocuments: selectDocumentsFetching(state),
        documents: selectDocuments(state),
        properties: selectProperties(state),
        fetchingProperties: selectPropertiesFetching(state),
        // photos: selectSortedPhotos(state),
        // fetchingPhotos: selectPhotosFetching(state),
        // fetchingGroundPlans: selectGroundPlansFetching(state),
        // projectSearchCriteria: selectSearchCriteriaProjects(state),
        currentAgentId: selectCurrentUser(state).id,
        links: selectProjectLinks(state),
    }))

    const viewData = useProjectGeneralViewData(project)

    const tabs = useProjectViewTabs()
    const [activeTab, setActiveTab] = useState(null)
    const [activeMaterialsTab, setActiveMaterialsTab] = useState('photos')
    const [modalBaseOpen, setModalBaseOpen] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)

    useEffect(() => {
        if (tabs.length > 0) {
            setActiveTab(tabs[0].name)
        }
    }, [tabs])

    useEffect(() => {
        if (fetchingPhotos || fetchingGroundPlans) return

        switch (true) {
            case Boolean(!photos.length && !groundPlans.length):
                if (project?.[projectResponseFields.MAP_LATITUDE] && project?.[projectResponseFields.MAP_LONGITUDE]) {
                    setActiveMaterialsTab('map')
                    return
                }

                if (project?.[projectResponseFields.VIRTUAL_WALK_URL]) {
                    setActiveMaterialsTab('3d')
                    return
                }

                if (project?.[projectResponseFields.PROPERTY_VIDEO_URL]) {
                    setActiveMaterialsTab('video')
                    return
                }
                break
            case Boolean(!photos.length && groundPlans.length):
                setActiveMaterialsTab('groundPlans')
                break
            default:
                setActiveMaterialsTab('photos')
                break
        }
    }, [fetchingPhotos, fetchingGroundPlans, project, photos.length, groundPlans.length])

    const handleTabChange = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab)
        }
    }

    const handleMaterialsTabChange = (tab) => {
        if (activeMaterialsTab !== tab) {
            setActiveMaterialsTab(tab)
        }
    }

    const handleDeleteModal = () => {
        setDeleteModal(true)
    }

    const handleDeleteProject = () => {
        dispatch(deleteProject(id, () => history.push('/projects')))
        setDeleteModal(false)
    }

    if (fetchingProject) {
        return (
            <div className="page-content">
                <Container fluid>
                    <Loader />
                </Container>
            </div>
        )
    }

    document.title = `${project?.translations[currentLang]?.projectTitle ?? t('app.common.projects')} | ${t('app.title')}`

    return (
        <div className="page-content">
            <Container fluid>
                <Row>
                    <Col xs={12}>
                        <div className={`page-title-box d-sm-flex align-items-center mt-3 ps-0`}>
                            <BackButton className="me-3" />
                            <div>
                                <h4 className="mb-sm-0">{project?.translations[currentLang]?.projectTitle}</h4>
                                {project?.projectService?.name && <p>{project?.projectService?.name}</p>}
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="d-flex justify-content-between align-items-center mb-3">
                    <Col sm={12} md={8}>
                        <FormTabNavigation tabs={tabs} activeTab={activeTab} handleTabChange={handleTabChange} customClass="detail-view-tabs">
                            <NavItem>
                                <UncontrolledDropdown>
                                    <DropdownToggle
                                        title={t('table.showMore')}
                                        href="#"
                                        className="real-estate-form-nav-link nav-link dropdown"
                                        tag="a"
                                    >
                                        <i className="ri-more-fill align-middle"></i>
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-end">
                                        <AppPermissionsSwitcher permission="ModulePermissions" module={location} action={action.edit}>
                                            {({ hasModulePermission }) =>
                                                hasModulePermission && (
                                                    <>
                                                        <DropdownItem onClick={() => history.push(`/projects/${id}/edit`)}>
                                                            <i className="mdi mdi-pencil-outline align-bottom me-2 text-muted"></i>{' '}
                                                            {t('view.project.action.edit')}
                                                        </DropdownItem>
                                                        <DropdownItem href="#" onClick={() => handleDeleteModal()}>
                                                            <i className="mdi mdi-trash-can-outline align-bottom me-2 text-muted"></i>{' '}
                                                            {t('view.project.action.delete')}
                                                        </DropdownItem>
                                                    </>
                                                )
                                            }
                                        </AppPermissionsSwitcher>
                                        <DropdownItem onClick={() => history.push('/projects/new')}>
                                            <i className="mdi mdi-plus align-bottom me-2 text-muted"></i> {t('view.project.action.add')}
                                        </DropdownItem>
                                        <DropdownItem
                                            onClick={(e) => {
                                                e.preventDefault()
                                                setModalBaseOpen(true)
                                            }}
                                        >
                                            <i className="mdi mdi-file-document-multiple-outline align-bottom me-2 text-muted"></i>{' '}
                                            {t('app.common.offerOrPrint')}
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </NavItem>
                        </FormTabNavigation>
                    </Col>
                    <Col sm={12} md={4}>
                        <NavigationActions links={links} />
                    </Col>
                </Row>

                <TabContent activeTab={activeTab} className="text-muted">
                    <TabPane tabId="about">
                        <Row>
                            <Col sm={12} xxl={3}>
                                {viewData?.general && viewData.general.some((x) => x.value) && <ViewDataCard dataList={viewData.general} />}
                                {viewData?.location && viewData.location.some((x) => x.value) && <ViewDataCard dataList={viewData.location} />}
                                {viewData?.owners && viewData.owners.some((x) => x.value) && (
                                    <CollapseCard header={t('app.common.owner')} data={viewData.owners}>
                                        <ViewDataList dataList={viewData.owners} />
                                    </CollapseCard>
                                )}
                            </Col>

                            <Col sm={12} xxl={9}>
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col sm={12} xl={6}>
                                                {viewData?.price?.map((viewItem, index) => {
                                                    if (viewItem.value) {
                                                        return (
                                                            <Row
                                                                className={`align-items-start ${viewData.price.length - 1 !== index ? 'mb-4' : ''}`}
                                                                key={viewItem?.id}
                                                            >
                                                                <Col sm={6}>
                                                                    <span className="flex-grow-1 fw-medium">{viewItem?.label}:</span>
                                                                </Col>
                                                                <Col sm={6}>
                                                                    <span className="flex-grow-1 fw-light">
                                                                        {!viewItem?.value?.length ? viewItem?.value : [...viewItem.value]}
                                                                    </span>
                                                                </Col>
                                                            </Row>
                                                        )
                                                    }
                                                    return null
                                                })}
                                                {viewData?.price?.some((x) => x.value) && <div className="my-4"></div>}
                                                {viewData?.details?.map((viewItem, index) => {
                                                    if (viewItem.value) {
                                                        return (
                                                            <Row
                                                                className={`align-items-start ${viewData.details.length - 1 !== index ? 'mb-4' : ''}`}
                                                                key={viewItem?.id}
                                                            >
                                                                <Col sm={6}>
                                                                    <span className="flex-grow-1 fw-medium">{viewItem?.label}:</span>
                                                                </Col>
                                                                <Col sm={6}>
                                                                    <span className="flex-grow-1 fw-light">
                                                                        {!viewItem?.value?.length ? viewItem?.value : [...viewItem.value]}
                                                                    </span>
                                                                </Col>
                                                            </Row>
                                                        )
                                                    }
                                                    return null
                                                })}
                                            </Col>
                                            <Col sm={12} xl={6}>
                                                <Row className="mb-4">
                                                    <Col>
                                                        {fetchingPhotos || fetchingGroundPlans ? (
                                                            <div
                                                                className="d-flex align-items-center justify-content-center"
                                                                style={{ height: '370px' }}
                                                            >
                                                                <Loader />
                                                            </div>
                                                        ) : (
                                                            <>
                                                                <TabContent activeTab={activeMaterialsTab}>
                                                                    {photos.length ? (
                                                                        <TabPane tabId="photos">
                                                                            <Slider
                                                                                module={location}
                                                                                images={photos.map((item) => ({
                                                                                    id: item.id,
                                                                                    size: '1400-933',
                                                                                    src: `${API_URL}v1/file/show/${item.id}/original?${Date.now()}`,
                                                                                    srcSlider: `${API_URL}v1/file/show/${item.id}?${Date.now()}`,
                                                                                    thumb: `${API_URL}v1/file/show/${item.id}`,
                                                                                    fileSync: item.fileSync,
                                                                                }))}
                                                                            />
                                                                        </TabPane>
                                                                    ) : null}
                                                                    {groundPlans.length ? (
                                                                        <TabPane tabId="groundPlans">
                                                                            <Slider
                                                                                module={location}
                                                                                images={groundPlans.map((item) => ({
                                                                                    id: item.id,
                                                                                    size: '1400-933',
                                                                                    src: `${API_URL}v1/file/show/${item.id}?${Date.now()}`,
                                                                                    srcSlider: `${API_URL}v1/file/show/${item.id}?${Date.now()}`,
                                                                                    thumb: `${API_URL}v1/file/show/${item.id}`,
                                                                                    fileSync: item.fileSync,
                                                                                }))}
                                                                            />
                                                                        </TabPane>
                                                                    ) : null}
                                                                    {project?.[propertyResponseFields.VIRTUAL_WALK_URL] ? (
                                                                        <TabPane tabId="3d">
                                                                            <div className="ratio ratio-4x3">
                                                                                <iframe
                                                                                    title="3D virtualna šetnja"
                                                                                    src={project[propertyResponseFields.VIRTUAL_WALK_URL]}
                                                                                />
                                                                            </div>
                                                                        </TabPane>
                                                                    ) : null}
                                                                    {project?.[propertyResponseFields.PROPERTY_VIDEO_URL] ? (
                                                                        <TabPane tabId="video">
                                                                            <div className="ratio ratio-4x3">
                                                                                <iframe
                                                                                    title="Youtube video"
                                                                                    src={embedYotubeVideo(
                                                                                        project[propertyResponseFields.PROPERTY_VIDEO_URL]
                                                                                    )}
                                                                                />
                                                                            </div>
                                                                        </TabPane>
                                                                    ) : null}
                                                                    {project?.[projectResponseFields.MAP_LATITUDE] &&
                                                                    project?.[projectResponseFields.MAP_LONGITUDE] ? (
                                                                        <TabPane tabId="map">
                                                                            <MapContainer
                                                                                marker={coerceLatLng(project)}
                                                                                showRadius={isApproxLocationSyncType(project)}
                                                                                height="399px"
                                                                            />
                                                                        </TabPane>
                                                                    ) : null}
                                                                </TabContent>

                                                                <AppPermissionsSwitcher
                                                                    permission="AllowedPhotos"
                                                                    module={location}
                                                                    photos={photos}
                                                                    groundPlans={groundPlans}
                                                                >
                                                                    {({ photos, groundPlans }) =>
                                                                        photos.length || groundPlans.length ? (
                                                                            <SliderOptions
                                                                                photos={photos}
                                                                                groundPlans={groundPlans}
                                                                                activeMaterialsTab={activeMaterialsTab}
                                                                                handleMaterialsTabChange={handleMaterialsTabChange}
                                                                                viewData={project}
                                                                            />
                                                                        ) : null
                                                                    }
                                                                </AppPermissionsSwitcher>
                                                            </>
                                                        )}
                                                    </Col>
                                                </Row>
                                                {viewData?.relatedData?.map((viewItem, index) => {
                                                    if (viewItem.value) {
                                                        return (
                                                            <Row
                                                                className={`align-items-start ${viewData.details.length - 1 !== index ? 'mb-4' : ''}`}
                                                                key={viewItem?.id}
                                                            >
                                                                <Col sm={6}>
                                                                    <span className="flex-grow-1 fw-medium">{viewItem?.label}:</span>
                                                                </Col>
                                                                <Col sm={6}>
                                                                    <span className="flex-grow-1 fw-light">
                                                                        {!viewItem?.value?.length ? viewItem?.value : [...viewItem.value]}
                                                                    </span>
                                                                </Col>
                                                            </Row>
                                                        )
                                                    }
                                                    return null
                                                })}
                                            </Col>
                                        </Row>
                                        {project?.translations[currentLang]?.projectTitle && (
                                            <Row className="align-items-center mt-5">
                                                <Col sm={3}>
                                                    <span className="flex-grow-1 fw-medium">{t('app.common.title')}:</span>
                                                </Col>
                                                <Col sm={9}>
                                                    <span className="flex-grow-1 fw-light">{project?.translations[currentLang]?.projectTitle}</span>
                                                </Col>
                                            </Row>
                                        )}
                                        {project?.translations[currentLang]?.projectDescription && (
                                            <Row className="mt-5">
                                                <Col sm={12} className="mb-3">
                                                    <span className="flex-grow-1 fw-medium">{t('form.project.description.webField.label')}:</span>
                                                </Col>
                                                <Col sm={12} className="fw-light text-secondary">
                                                    {
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html: project?.translations[currentLang]?.projectDescription,
                                                            }}
                                                        />
                                                    }
                                                </Col>
                                            </Row>
                                        )}
                                        {project?.translations[currentLang]?.projectOfferDescription && (
                                            <Row className="mt-5">
                                                <Col sm={12} className="mb-3">
                                                    <span className="flex-grow-1 fw-medium">{t('form.project.description.offerField.label')}:</span>
                                                </Col>
                                                <Col sm={12} className="fw-light text-secondary">
                                                    {
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html: project?.translations[currentLang]?.projectOfferDescription,
                                                            }}
                                                        />
                                                    }
                                                </Col>
                                            </Row>
                                        )}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </TabPane>
                    {activeTab === 'documents' && (
                        <TabPane tabId="documents">
                            <Card>
                                <CardBody>
                                    <DocumentViewTab
                                        fetching={fetchingDocuments}
                                        fetchAction={fetchDocuments}
                                        deleteAction={deleteDocument}
                                        syncAction={syncDocument}
                                        activeTab={activeTab}
                                        files={documents}
                                        documentModule="project"
                                        addButtonAction={() => {
                                            history.push({
                                                pathname: `/projects/${id}/edit`,
                                                state: {
                                                    activeTab: 'documents',
                                                },
                                            })
                                        }}
                                    />
                                </CardBody>
                            </Card>
                        </TabPane>
                    )}
                    {activeTab === 'relatedProperties' && (
                        <TabPane tabId="relatedProperties">
                            <Card>
                                <CardBody>
                                    <RelatedPropertiesViewTab
                                        fetching={fetchingProperties}
                                        fetchAction={fetchProperties}
                                        deleteAction={deleteProperty}
                                        properties={properties}
                                        extraCriteria={{
                                            project: id,
                                        }}
                                        addButtonAction={() => {
                                            history.push({
                                                pathname: `/projects/${id}/edit`,
                                                state: {
                                                    activeTab: 'documents',
                                                },
                                            })
                                        }}
                                    />
                                </CardBody>
                            </Card>
                        </TabPane>
                    )}
                    {activeTab === 'notes' && (
                        <TabPane tabId="notes">
                            <NotesViewAccordion id={id} noteEntity={'project'} currentAgentId={currentAgentId} />
                        </TabPane>
                    )}
                </TabContent>
            </Container>
            <OfferPrint setModalBaseOpen={setModalBaseOpen} modalBaseOpen={modalBaseOpen} data={[project]} />
            <DeleteModal
                show={deleteModal}
                module={'project'}
                onDeleteClick={() => handleDeleteProject()}
                onCloseClick={() => setDeleteModal(false)}
            />
        </div>
    )
}

export default ProjectView
