import { apiReq } from '..'
import { getSearchCriteriaFromStore } from '../helpers'
import QueryString from 'qs'

export const getProjectById = async (id: number) => {
    const response = await apiReq(`v1/project/${id}`, {
        params: getSearchCriteriaFromStore('project'),
        paramsSerializer: (params: any) => {
            return QueryString.stringify(params)
        },
    })
    return response.data
}