import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import {
    AccordionBody,
    AccordionHeader,
    AccordionItem,
    Badge,
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Collapse,
    Container,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    NavItem,
    Row,
    TabContent,
    TabPane,
    UncontrolledAccordion,
    UncontrolledDropdown,
} from 'reactstrap'
import ActivitiesViewAccordion from '../../Components/ActivitiesViewAccordion'
import AppPermissionsSwitcher from '../../Components/AppPermissionsSwitcher/AppPermissionsSwitcher'
import { action } from '../../Components/AppPermissionsSwitcher/constants/actions'
import AppTopSwitcher from '../../Components/AppTopSwitcher/AppTopSwitcher'
import BackButton from '../../Components/BackButton/BackButton'
import DeleteModal from '../../Components/Common/DeleteModal'
import ErrorModal from '../../Components/Common/ErrorModal'
import FormTabNavigation from '../../Components/Common/FormTabNavigation'
import Loader from '../../Components/Common/Loader'
import NavigationActions from '../../Components/Common/NavigationActions/NavigationActions'
import PageTitle from '../../Components/Common/PageTitle'
import ConnectedPropertiesAccordion from '../../Components/ConnectedPropertiesAccordion/ConnectedPropertiesAccordion'
import ContactProfileViewTab from '../../Components/ContactProfileViewTab'
import DocumentViewTab from '../../Components/DocumentViewTab/Index'
import { useContactGeneralViewData } from '../../Components/Hooks/useContactViewHook'
import { useContactViewTabs } from '../../Components/Hooks/useContactsTabs'
import NotesViewAccordion from '../../Components/NotesViewAccordion'
import { CONTACT_TYPE, SUBMIT_RESPONSE_STATUS } from '../../common/constants'
import { contactResponseFields } from '../../common/response/contact'
import { useColumnOrder } from '../../hooks/useColumnOrder'
import { clearActivities } from '../../store/activities'
import { selectCurrentUser } from '../../store/auth/selectors'
import { fetchContactProfiles, selectContactProfileDetails, selectContactProfiles, selectContactProfilesFetching } from '../../store/contactProfiles'
import {
    deleteContact,
    downloadContactDocument,
    fetchContact,
    fetchContacts,
    selectContact,
    selectContactFetchingDetails,
    selectContacts,
    selectContactsFetching,
    selectContactsPagination,
    selectContactLinks
} from '../../store/contacts'
import { deleteDocument, fetchDocuments, selectDocuments, selectDocumentsFetching, syncDocument } from '../../store/documents'
import { selectSearchCriteriaContacts } from '../../store/search'
import { formatDateTime } from '../../utils/general'

const ContactView = () => {
    const history = useHistory()
    const location = useLocation()
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const { id } = useParams()

    const {
        contact,
        contactProfiles,
        fetching,
        documents,
        fetchingDocuments,
        contactSearchCriteria,
        currentAgentId,
        links
    } = useSelector((state) => ({
        contact: selectContact(state),
        contacts: selectContacts(state),
        contactsFetching: selectContactsFetching(state),
        fetching: selectContactFetchingDetails(state),
        pagination: selectContactsPagination(state),
        contactProfiles: selectContactProfiles(state),
        fetchingDocuments: selectDocumentsFetching(state),
        documents: selectDocuments(state),
        contactSearchCriteria: selectSearchCriteriaContacts(state),
        currentAgentId: selectCurrentUser(state).id,
        links: selectContactLinks(state)
    }))

    const tabs = useContactViewTabs()
    const viewData = useContactGeneralViewData(contact)
    const contactOrder = useColumnOrder('contact')

    const [activeTab, setActiveTab] = useState(null)
    const [noteErrorModal, setNoteErrorModal] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)

    useEffect(() => {
        return () => {
            dispatch(clearActivities())
        }
    }, [])

    useEffect(() => {
        const payload = contactSearchCriteria
            ? { id, order: contactOrder, criteria: { ...contactSearchCriteria, contactType: 'person' } }
            : { id, order: contactOrder }

        dispatch(fetchContact(payload))
        dispatch(fetchContactProfiles(id))
    }, [dispatch, contactSearchCriteria, contactOrder, id])

    useEffect(() => {
        if (tabs.length > 0) {
            setActiveTab(tabs[0].name)
        }
    }, [tabs])

    const handleTabChange = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab)
        }
    }

    const handleDeleteModal = () => {
        setDeleteModal(true)
    }

    const handleDeleteContact = () => {
        dispatch(deleteContact(id, () => history.push('/contacts')))
        setDeleteModal(false)
    }

    if (fetching) {
        return (
            <div className="page-content">
                <Container fluid>
                    <Loader />
                </Container>
            </div>
        )
    }

    document.title = `${contact?.[contactResponseFields.CONTACT_PERSON]?.[contactResponseFields.FIRST_NAME]} ${
        contact?.[contactResponseFields.CONTACT_PERSON]?.[contactResponseFields.LAST_NAME]
    } | ${t('app.title')}`

    return (
        <div className="page-content">
            <Container fluid>
                <div className="page-title-box ps-0 d-sm-flex align-items-start mt-3">
                    <BackButton className="me-3" />
                    <div>
                        <PageTitle
                            className="px-2"
                            title={`${
                                contact?.[contactResponseFields.CONTACT_PERSON]?.[contactResponseFields.FIRST_NAME]
                                    ? contact?.[contactResponseFields.CONTACT_PERSON]?.[contactResponseFields.FIRST_NAME]
                                    : ''
                            } ${
                                contact?.[contactResponseFields.CONTACT_PERSON]?.[contactResponseFields.LAST_NAME]
                                    ? contact?.[contactResponseFields.CONTACT_PERSON]?.[contactResponseFields.LAST_NAME]
                                    : ''
                            }`}
                            titleBadgeComponent={<Badge color="favorite">{contact?.[contactResponseFields.CONTACT_PRIORITY]?.name}</Badge>}
                        />

                        {contact?.[contactResponseFields.CONTACT_STATUS] && (
                            <Row>
                                <Col sm="12">
                                    <div className="px-2">
                                        <span className="fw-medium">{t('app.common.contact.profileStatus')} </span>
                                        {contact?.[contactResponseFields.CONTACT_STATUS]?.name}
                                    </div>
                                </Col>
                            </Row>
                        )}
                        {contact?.[contactResponseFields.INITIAL_CONTACT_DATE] && (
                            <Row>
                                <Col sm="12">
                                    <div className="px-2">
                                        <span className="fw-medium">{t('app.common.contact.establishmentDate')}</span>{' '}
                                        {formatDateTime(contact?.[contactResponseFields.INITIAL_CONTACT_DATE])}
                                    </div>
                                </Col>
                            </Row>
                        )}
                    </div>
                </div>

                <Row className="d-flex justify-content-between align-items-center mt-5 mb-3">
                    <Col sm={12} md={8}>
                        <FormTabNavigation tabs={tabs} activeTab={activeTab} handleTabChange={handleTabChange} customClass="detail-view-tabs">
                            <NavItem>
                                <UncontrolledDropdown>
                                    <DropdownToggle href="#" className="real-estate-form-nav-link nav-link dropdown" tag="a">
                                        <i className="ri-more-fill align-middle"></i>
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-end">
                                        <AppPermissionsSwitcher permission="ModulePermissions" module={location} action={action.edit}>
                                            {({ hasModulePermission }) =>
                                                hasModulePermission && (
                                                    <>
                                                        <DropdownItem onClick={() => history.push(`/contacts/${id}/edit`)}>
                                                            <i className="mdi mdi-pencil-outline align-bottom me-2 text-muted"></i>{' '}
                                                            {t('view.contact.action.edit')}
                                                        </DropdownItem>
                                                        <DropdownItem href="#" onClick={() => handleDeleteModal()}>
                                                            <i className="mdi mdi-trash-can-outline align-bottom me-2 text-muted"></i>{' '}
                                                            {t('view.contact.action.delete')}
                                                        </DropdownItem>
                                                    </>
                                                )
                                            }
                                        </AppPermissionsSwitcher>
                                        <DropdownItem
                                            onClick={() =>
                                                history.push({
                                                    pathname: `/contacts/${id}/edit`,
                                                    state: {
                                                        activeTab: 'contactProfile',
                                                        profileId: '',
                                                    },
                                                })
                                            }
                                        >
                                            <i className="mdi mdi-plus align-bottom me-2 text-muted"></i> {t('view.contact.action.add')}
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </NavItem>
                        </FormTabNavigation>
                    </Col>
                    <Col sm={12} md={4}>
                        <NavigationActions links={links} />
                    </Col>
                </Row>

                <TabContent activeTab={activeTab} className="text-muted">
                    {activeTab === 'general' && (
                        <TabPane tabId="general">
                            <Row>
                                <Col sm={12} xxl={3}>
                                    <Card>
                                        <CardBody>
                                            {viewData?.general &&
                                                viewData.general
                                                    .filter((viewItem) => viewItem.value)
                                                    .map((viewItem, index) => {
                                                        if (viewItem.value) {
                                                            return (
                                                                <Row
                                                                    className={`align-items-start ${
                                                                        viewData.general.filter((x) => x.value).length - 1 !== index ? 'mb-4' : ''
                                                                    }`}
                                                                    key={viewItem?.id}
                                                                >
                                                                    <Col sm={6}>
                                                                        <span className="flex-grow-1 fw-medium">{viewItem?.label}:</span>
                                                                    </Col>
                                                                    <Col sm={6}>
                                                                        <span className="flex-grow-1 fw-light">{viewItem?.value}</span>
                                                                    </Col>
                                                                </Row>
                                                            )
                                                        }
                                                        return null
                                                    })}
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col sm={12} xxl={9}>
                                    <UncontrolledAccordion defaultOpen={['1']} stayOpen>
                                        <AccordionItem className="mb-4">
                                            <AccordionHeader targetId="1">
                                                <h5>{t('app.common.remark')}</h5>
                                            </AccordionHeader>
                                            <AccordionBody accordionId="1">
                                                <Row className="mt-4">
                                                    <Col sm={12} className="fw-light text-secondary">
                                                        {contact?.[contactResponseFields.NOTES]}
                                                    </Col>
                                                </Row>
                                            </AccordionBody>
                                        </AccordionItem>
                                    </UncontrolledAccordion>

                                    <NotesViewAccordion id={id} noteEntity={'contact'} currentAgentId={currentAgentId} />

                                    <AppTopSwitcher
                                        feature="ActivitiesAccordion"
                                        permission="ModulePermissions"
                                        permissionName={'module.activity.view'}
                                        module={location}
                                        action={action.view}
                                    >
                                        {(props) => {
                                            const { featureActivitiesAccordion, hasModulePermission: permissionActivitiesAccordion } = props
                                            const allowAccordion = featureActivitiesAccordion && permissionActivitiesAccordion

                                            return (
                                                allowAccordion && (
                                                    <ActivitiesViewAccordion
                                                        contactType={CONTACT_TYPE.PERSON}
                                                        parentId={id}
                                                        contact={contact}
                                                        module={location}
                                                    />
                                                )
                                            )
                                        }}
                                    </AppTopSwitcher>

                                    <ConnectedPropertiesAccordion contact={contact} />
                                </Col>
                            </Row>
                        </TabPane>
                    )}
                    {activeTab === 'contactProfile' && (
                        <TabPane tabId="contactProfile">
                            <ContactProfileViewTab id={id} profiles={contactProfiles} contactType={CONTACT_TYPE.PERSON} />
                        </TabPane>
                    )}
                    {activeTab === 'documents' && (
                        <TabPane tabId="documents">
                            <Card>
                                <CardBody>
                                    <DocumentViewTab
                                        fetching={fetchingDocuments}
                                        fetchAction={fetchDocuments}
                                        deleteAction={deleteDocument}
                                        downloadAction={downloadContactDocument}
                                        syncAction={syncDocument}
                                        activeTab={activeTab}
                                        files={documents}
                                        documentModule="contact"
                                        contactType={CONTACT_TYPE.PERSON}
                                        addButtonAction={() => {
                                            history.push({
                                                pathname: `/contacts/${id}/edit`,
                                                state: { activeTab: 'documents' },
                                            })
                                        }}
                                    />
                                </CardBody>
                            </Card>
                        </TabPane>
                    )}
                </TabContent>
                <div style={{ clear: 'both' }}></div>
            </Container>

            <ErrorModal show={noteErrorModal} onCloseClick={() => setNoteErrorModal(false)} title="Uups!">
                <p>{t('modal.error.message')}</p>
            </ErrorModal>

            <DeleteModal
                show={deleteModal}
                module={'contact'}
                onDeleteClick={() => handleDeleteContact()}
                onCloseClick={() => setDeleteModal(false)}
            />
        </div>
    )
}

export default ContactView
