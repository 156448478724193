import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import i18n from '../../i18n'
import { getFieldTypes, transformValidationErrors } from '../../utils/general'

const FormValidationErrorModal = ({ validation, inputs, onClose, modalErrors }) => {
    const errors = transformValidationErrors(validation?.errors)
    const fieldTypes = getFieldTypes(inputs)

    // Adds label to validation error
    errors.forEach((err) => {
        const input = fieldTypes?.find((type) => type?.name === err.key)

        if (input) {
            err.label = input.label
        }

        // case for documents edit form which has global error
        if (err.key === 'general') {
            err.label = i18n.t('form.errors.documents.general')
        }
    })

    const errorList = errors.map(({ key, label }) => {
        return <li key={key}>{label}</li>
    })

    return (
        <Modal isOpen centered toggle={onClose}>
            <ModalHeader className="flex-column align-items-stretch p-3 bg-soft-danger">
                <div className="d-flex align-items-center w-100 text-danger">
                    <i className="mdi mdi-alert-circle-outline me-2 fs-4" />
                    {i18n.t('form.modal.error.title')}
                    {/*eslint-disable-next-line react/no-unknown-property*/}
                    <span onClick={onClose} className="ms-auto px-2 cursor-pointer fs-5">
                        <i className="mdi mdi-close" />
                    </span>
                </div>
            </ModalHeader>
            <ModalBody>
                <ul className="list-unstyled lh-lg ms-4 mb-1">{modalErrors ?? errorList}</ul>
            </ModalBody>
        </Modal>
    )
}

export default FormValidationErrorModal
