import classnames from 'classnames'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Nav, NavItem, NavLink } from 'reactstrap'
import { projectResponseFields } from '../../common/response/project'
import { propertyResponseFields } from '../../common/response/property'
import AppPermissionsSwitcher from '../AppPermissionsSwitcher/AppPermissionsSwitcher'

const SliderOptions = ({ photos, groundPlans, activeMaterialsTab, handleMaterialsTabChange, viewData }) => {
    const location = useLocation()

    useEffect(() => {
        if (!photos.length) {
            handleMaterialsTabChange('groundPlans')
        }
    }, [photos, handleMaterialsTabChange])

    return (
        <Nav tabs className="nav-tabs bg-primary text-white justify-content-center mb-4 property-view-materils-tabs">
            {photos?.length ? (
                <NavItem className={classnames({ active: activeMaterialsTab === 'photos' })}>
                    <NavLink
                        style={{ cursor: 'pointer' }}
                        className={classnames({ active: activeMaterialsTab === 'photos' }, 'real-estate-form-nav-link')}
                        onClick={() => handleMaterialsTabChange('photos')}
                    >
                        <i className="mdi mdi-image-multiple fs-16"></i>
                    </NavLink>
                </NavItem>
            ) : null}
            {groundPlans?.length ? (
                <NavItem className={classnames({ active: activeMaterialsTab === 'groundPlans' })}>
                    <NavLink
                        style={{ cursor: 'pointer' }}
                        className={classnames({ active: activeMaterialsTab === 'groundPlans' }, 'real-estate-form-nav-link')}
                        onClick={() => handleMaterialsTabChange('groundPlans')}
                    >
                        <i className="mdi mdi-floor-plan fs-16"></i>
                    </NavLink>
                </NavItem>
            ) : null}
            {viewData?.[propertyResponseFields.VIRTUAL_WALK_URL] ? (
                <NavItem className={classnames({ active: activeMaterialsTab === '3d' })}>
                    <NavLink
                        style={{ cursor: 'pointer' }}
                        className={classnames({ active: activeMaterialsTab === '3d' }, 'real-estate-form-nav-link')}
                        onClick={() => handleMaterialsTabChange('3d')}
                    >
                        <i className="mdi mdi-rotate-3d fs-16"></i>
                    </NavLink>
                </NavItem>
            ) : null}
            {viewData?.[propertyResponseFields.PROPERTY_VIDEO_URL] ? (
                <NavItem className={classnames({ active: activeMaterialsTab === 'video' })}>
                    <NavLink
                        style={{ cursor: 'pointer' }}
                        className={classnames({ active: activeMaterialsTab === 'video' }, 'real-estate-form-nav-link')}
                        onClick={() => handleMaterialsTabChange('video')}
                    >
                        <i className="mdi mdi-play-circle fs-16"></i>
                    </NavLink>
                </NavItem>
            ) : (
                ''
            )}
            {viewData?.[projectResponseFields.MAP_LATITUDE] && viewData?.[projectResponseFields.MAP_LONGITUDE] ? (
                <AppPermissionsSwitcher permission="GoogleMaps" module={location}>
                    {({ showMaps }) =>
                        showMaps && (
                            <NavItem className={classnames({ active: activeMaterialsTab === 'map' })}>
                                <NavLink
                                    style={{ cursor: 'pointer' }}
                                    className={classnames({ active: activeMaterialsTab === 'map' }, 'real-estate-form-nav-link')}
                                    onClick={() => handleMaterialsTabChange('map')}
                                >
                                    <i className="mdi mdi-map-marker fs-16"></i>
                                </NavLink>
                            </NavItem>
                        )
                    }
                </AppPermissionsSwitcher>
            ) : null}
        </Nav>
    )
}

export default SliderOptions
