import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'

const Paginator = ({ currentPage, totalPages, onPageChange }) => {
    const { t } = useTranslation()
    const isFirstPage = currentPage === 1
    const isLastPage = currentPage === totalPages
    // The maximum number of pages to display
    const maxPageDisplay = 5

    // The range of pages to display
    let startPage = 1
    let endPage = totalPages
    if (totalPages > maxPageDisplay) {
        const middlePage = Math.ceil(maxPageDisplay / 2)
        if (currentPage >= middlePage && currentPage <= totalPages - middlePage) {
            startPage = currentPage - middlePage + 1
            endPage = currentPage + middlePage - 1
        } else if (currentPage < middlePage) {
            startPage = 1
            endPage = maxPageDisplay
        } else {
            startPage = totalPages - maxPageDisplay + 1
            endPage = totalPages
        }
    }

    // The pages to display
    const pages = []
    for (let i = startPage; i <= endPage; i++) {
        pages.push(i)
    }

    return (
        <Pagination>
            <PaginationItem disabled={isFirstPage}>
                <PaginationLink previous onClick={() => onPageChange(currentPage - 1)}>
                {t('button.pagination.previous')}
                </PaginationLink>
            </PaginationItem>

            {startPage > 1 && (
                <PaginationItem>
                    <PaginationLink onClick={() => onPageChange(1)}>1</PaginationLink>
                </PaginationItem>
            )}

            {startPage > 2 && (
                <PaginationItem disabled>
                    <PaginationLink>...</PaginationLink>
                </PaginationItem>
            )}

            {pages.map((page) => (
                <PaginationItem key={page} active={page === currentPage}>
                    <PaginationLink onClick={() => onPageChange(page)}>{page}</PaginationLink>
                </PaginationItem>
            ))}

            {endPage < totalPages - 1 && (
                <PaginationItem disabled>
                    <PaginationLink>...</PaginationLink>
                </PaginationItem>
            )}

            {endPage < totalPages && (
                <PaginationItem>
                    <PaginationLink onClick={() => onPageChange(totalPages)}>{totalPages}</PaginationLink>
                </PaginationItem>
            )}

            <PaginationItem disabled={isLastPage}>
                <PaginationLink next onClick={() => onPageChange(currentPage + 1)}>
                {t('button.pagination.next')}
                </PaginationLink>
            </PaginationItem>
        </Pagination>
    )
}

export default memo(Paginator)
