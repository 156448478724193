import QueryString from 'qs'
import { toast } from 'react-toastify'
import { all, call, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects'
import { updateFile } from '../../api'
import { invalidateQuery } from '../../api/helpers'
import { SUBMIT_RESPONSE_STATUS } from '../../common/constants'
import i18n from '../../i18n'
import { getUploadPhotoFormData } from '../../utils/general'
import { apiRequest } from '../api'
import {
    deletePhoto,
    deletePhotoFailure,
    deletePhotoRequest,
    deletePhotoSuccess,
    fetchPhotos,
    fetchPhotosFailure,
    fetchPhotosRequest,
    fetchPhotosSuccess,
    proxyPhotoEdit,
    proxyPhotoEditFailure,
    proxyPhotoEditRequest,
    proxyPhotoEditSuccess,
    quePhoto,
    quePhotoFailure,
    quePhotoRequest,
    quePhotoSuccess,
    syncPhoto,
    syncPhotoFailure,
    syncPhotoRequest,
    syncPhotoSuccess,
    updatePhoto,
    updatePhotoFailure,
    updatePhotoPriority,
    updatePhotoRequest,
    updatePhotoSuccess,
    updatePhotosCount,
    uploadPhoto,
    uploadPhotoFailure,
    uploadPhotoRequest,
    uploadPhotoSuccess,
    uploadPhotos,
} from './actions'
import { selectPhotos, selectPhotosCount } from './selectors'

export function* uploadPhotoSaga({ payload }) {
    const { formData, uploadUrl } = payload
    const formDataObj = Object.fromEntries(formData.entries())
    delete formDataObj.file // not needed anymore

    yield put(uploadPhotoRequest())

    try {
        const response = yield apiRequest(`v1/file/upload/${uploadUrl}`, {
            method: 'POST',
            data: formData,
        })

        yield put(
            uploadPhotoSuccess({
                ...formDataObj,
                id: response.data?.[0].id,
                translations: response.data?.[0].translations,
            })
        )
 
        const item = response.data?.[0]
        invalidateQuery([item.parent.toString(), item.module, 'photo'])

        return { status: 'success' }
    } catch (error) {
        yield put(uploadPhotoFailure())

        return { status: 'error' }
    }
}

export function* uploadPhotosSaga({ payload }) {
    const { files, entity, moduleName } = payload

    const uploadPromises = []
    const uploadUrl = `${moduleName}/photo`
    const collection = 'photo'
    const filesCount = files.length

    const progressToast = toast.loading(i18n.t('toast.loading.images', { filesCount }))

    try {
        for (const [i, file] of files.entries()) {
            let existingCount = yield select(selectPhotosCount)

            yield put(updatePhotosCount(existingCount + 1))

            const priority = existingCount + 1

            // File object can only be send via api inside new FormData object
            const formData = getUploadPhotoFormData(file, entity, moduleName, collection, priority)

            const { status } = yield call(uploadPhotoSaga, {
                payload: { formData, uploadUrl },
            })

            uploadPromises.push({ status })
        }

        const successUploads = uploadPromises.filter((promise) => promise.status === 'success')
        const errorUploads = uploadPromises.filter((promise) => promise.status === 'error')
        const errorCount = errorUploads.length

        if (successUploads.length > 0) {
            toast.update(progressToast, {
                render: i18n.t('toast.loading.images.success', { filesCount }),
                type: 'success',
                isLoading: false,
                autoClose: 3000,
            })
        }

        if (errorUploads.length > 0) {
            toast.error(i18n.t('toast.loading.images.error', { errorCount }), {
                autoClose: 3000,
                isLoading: false,
            })
        }
    } catch (error) {
        console.error('Error during photos upload:', error)
    }
}

export function* quePhotoSaga({ payload, meta }) {
    yield put(quePhotoRequest())

    try {
        yield put(quePhotoSuccess(payload))
    } catch (error) {
        yield put(quePhotoFailure(error))
    }
}

export function* deletePhotoSaga({ payload, meta }) {
    const { id, entityId, moduleName } = payload
    yield put(deletePhotoRequest())

    try {
        const response = yield apiRequest(`v1/file/${id}`, {
            method: 'DELETE',
        })

        yield put(deletePhotoSuccess(id))

        invalidateQuery([entityId.toString(), `${moduleName}`, 'photo'])

        if (meta && meta.callback) {
            meta.callback()
        }
    } catch (error) {
        yield put(deletePhotoFailure(error))
    }
}

export function* syncPhotoSaga({ payload, meta }) {
    yield put(syncPhotoRequest())

    try {
        const response = yield apiRequest(`v1/file/sync/${payload}`, {
            method: 'PUT',
        })

        yield put(syncPhotoSuccess(payload))

        if (meta && meta.callback) {
            meta.callback()
        }
    } catch (error) {
        yield put(syncPhotoFailure(error))
    }
}

export function* fetchPhotosSaga({ payload, meta }) {
    yield put(fetchPhotosRequest())

    const { parentId, ...params } = payload
    const fileUrl = parentId ? `v1/file/parent/${parentId}` : 'v1/file'

    try {
        const response = yield apiRequest(fileUrl, {
            method: 'GET',
            params: params,
            paramsSerializer: (params) => {
                return QueryString.stringify(params)
            },
        })

        yield put(fetchPhotosSuccess(response.data))

        if (meta) {
            meta(response.data.count)
        }
    } catch (error) {
        yield put(fetchPhotosFailure(error))
    }
}

export function* updatePhotoSaga({ payload, meta }) {
    const { entityId, moduleName, id, ...data } = payload

    yield put(updatePhotoRequest())

    try {
        yield updateFile(id, data)
        yield put(updatePhotoSuccess(payload))

        invalidateQuery([entityId.toString(), `${moduleName}`, 'photo'])

        if (meta && meta.callback) {
            meta.callback(SUBMIT_RESPONSE_STATUS.SUCCESS)
        }
    } catch (error) {
        yield put(updatePhotoFailure(error))
    }
}

export function* proxyPhotoEditSaga({ payload, meta }) {
    yield put(proxyPhotoEditRequest())

    const photos = yield select((state) => selectPhotos(state))
    const photo = photos.find((photo) => photo.id === payload)

    try {
        const response = yield apiRequest(`v1/file/show/${payload}/original`, {
            method: 'GET',
            responseType: 'blob',
            headers: {
                'Cache-Control': 'no-cache',
            },
            // headers: {
            //     'Content-Type': 'image/jpeg',
            // }
        })

        const proxyPhoto = {
            ...photo,
            id: payload,
            url: URL.createObjectURL(response.data),
        }

        yield put(proxyPhotoEditSuccess(proxyPhoto))

        if (meta && meta.callback) {
            meta.callback(proxyPhoto)
        }
    } catch (error) {
        yield put(proxyPhotoEditFailure(error))
    }
}

export function* updatePhotoPrioritySaga({ payload }) {
    const photosToDelete = payload.map((str) => Number(str))

    const photos = yield select(selectPhotos)

    const updatedData = photos.filter((photo) => !photosToDelete.includes(photo.id)).map((photo, index) => ({ id: photo.id, priority: index + 1 }))

    for (const photo of updatedData) {
        yield put(updatePhoto(photo))
    }
}

export default function* actionWatcher() {
    yield takeLatest(`${fetchPhotos}`, fetchPhotosSaga)
    yield takeEvery(`${uploadPhoto}`, uploadPhotoSaga)
    yield takeEvery(`${uploadPhotos}`, uploadPhotosSaga)
    yield takeEvery(`${quePhoto}`, quePhotoSaga)
    yield takeEvery(`${deletePhoto}`, deletePhotoSaga)
    yield takeEvery(`${syncPhoto}`, syncPhotoSaga)
    yield takeEvery(`${updatePhoto}`, updatePhotoSaga)
    yield takeEvery(`${proxyPhotoEdit}`, proxyPhotoEditSaga)
    yield takeEvery(`${updatePhotoPriority}`, updatePhotoPrioritySaga)
}
