import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { getNotesById, createUpdateNote, deleteNoteById } from './functions'
import { Note } from './types'
import { queryClient } from '../..'

const notes = {
    GetNotes: {
        useQuery: (id: number, noteEntity: string, refreshToken: string, options?: UseQueryOptions<Note, AxiosError>) =>
            useQuery({
                queryKey: [id, 'notes', noteEntity, refreshToken],
                queryFn: () => getNotesById(id, noteEntity),
                staleTime: 5 * 60 * 1000,
                ...options,
            }),
    },
    CreateUpdateNote: {
        useMutation: (id: number, noteEntity: string, isEdit?: boolean, options?: UseMutationOptions<AxiosError>) =>
            useMutation({
                ...options,
                mutationFn: (data) => createUpdateNote(data, isEdit),
                onSuccess: () => {
                    queryClient.invalidateQueries({ queryKey: [id, 'notes', noteEntity] })
                },
            }),
    },
    DeleteNote: {
        useMutation: (id: number, noteEntity: string, options?: UseMutationOptions<AxiosError>) =>
            useMutation({
                ...options,
                mutationFn: (noteId) => deleteNoteById(noteId, noteEntity),
                onSuccess: () => {
                    queryClient.invalidateQueries({ queryKey: [id, 'notes', noteEntity] })
                },
            }),
    },
}

export default notes
