import { createAction } from 'redux-actions'

const ENTITY = 'ADMINISTRATION'
export const stateKey = ENTITY.toLowerCase()

export const fetchUsers = createAction(
    `${ENTITY}/FETCH_USERS`,
    (payload) => payload,
    (_, callback) => callback
)
export const fetchUsersRequest = createAction(`${ENTITY}/FETCH_USERS_REQUEST`)
export const fetchUsersSuccess = createAction(`${ENTITY}/FETCH_USERS_SUCCESS`)
export const fetchUsersFailure = createAction(`${ENTITY}/FETCH_USERS_FAILURE`)

export const fetchUser = createAction(
    `${ENTITY}/FETCH_USER`,
    (payload) => payload,
    (_, callback) => callback
)
export const fetchUserRequest = createAction(`${ENTITY}/FETCH_USER_REQUEST`)
export const fetchUserSuccess = createAction(`${ENTITY}/FETCH_USER_SUCCESS`)
export const fetchUserFailure = createAction(`${ENTITY}/FETCH_USER_FAILURE`)

export const updateUser = createAction(
    `${ENTITY}/UPDATE_USER`,
    (values) => values,
    (_, callback) => ({ callback })
)
export const updateUserRequest = createAction(`${ENTITY}/UPDATE_USER_REQUEST`)
export const updateUserSuccess = createAction(`${ENTITY}/UPDATE_USER_SUCCESS`)
export const updateUserFailure = createAction(`${ENTITY}/UPDATE_USER_FAILURE`)

export const fetchAgencyOffices = createAction(
    `${ENTITY}/FETCH_AGENCY_OFFICES`,
    (payload) => payload,
    (_, callback) => callback
)
export const fetchAgencyOfficesRequest = createAction(`${ENTITY}/FETCH_AGENCY_OFFICES_REQUEST`)
export const fetchAgencyOfficesSuccess = createAction(`${ENTITY}/FETCH_AGENCY_OFFICES_SUCCESS`)
export const fetchAgencyOfficesFailure = createAction(`${ENTITY}/FETCH_AGENCY_OFFICES_FAILURE`)

export const fetchAgencyOffice = createAction(
    `${ENTITY}/FETCH_AGENCY_OFFICE`,
    (payload) => payload,
    (_, callback) => callback
)
export const fetchAgencyOfficeRequest = createAction(`${ENTITY}/FETCH_AGENCY_OFFICE_REQUEST`)
export const fetchAgencyOfficeSuccess = createAction(`${ENTITY}/FETCH_AGENCY_OFFICE_SUCCESS`)
export const fetchAgencyOfficeFailure = createAction(`${ENTITY}/FETCH_AGENCY_OFFICE_FAILURE`)

export const createAgencyOffice = createAction(
    `${ENTITY}/CREATE_AGENCY_OFFICE`,
    (values) => values,
    (_, callback) => ({ callback })
)
export const createAgencyOfficeRequest = createAction(`${ENTITY}/CREAT_AGENCY_OFFICEE_REQUEST`)
export const createAgencyOfficeSuccess = createAction(`${ENTITY}/CREATE_AGENCY_OFFICE_SUCCESS`)
export const createAgencyOfficeFailure = createAction(`${ENTITY}/CREATE_AGENCY_OFFICE_FAILURE`)

export const updateAgencyOffice = createAction(
    `${ENTITY}/UPDATE_AGENCY_OFFICE`,
    (values) => values,
    (_, callback) => callback
)
export const updateAgencyOfficeRequest = createAction(`${ENTITY}/UPDATE_AGENCY_OFFICE_REQUEST`)
export const updateAgencyOfficeSuccess = createAction(`${ENTITY}/UPDATE_AGENCY_OFFICE_SUCCESS`)
export const updateAgencyOfficeFailure = createAction(`${ENTITY}/UPDATE_AGENCY_OFFICE_FAILURE`)

export const fetchAgencyPermissions = createAction(
    `${ENTITY}/FETCH_AGENCY_PERMISSIONS`,
    (payload) => payload,
    (_, callback) => callback
)
export const fetchAgencyPermissionsRequest = createAction(`${ENTITY}/FETCH_AGENCY_PERMISSIONS_REQUEST`)
export const fetchAgencyPermissionsSuccess = createAction(`${ENTITY}/FETCH_AGENCY_PERMISSIONS_SUCCESS`)
export const fetchAgencyPermissionsFailure = createAction(`${ENTITY}/FETCH_AGENCY_PERMISSIONS_FAILURE`)

export const fetchAgency = createAction(
    `${ENTITY}/FETCH_AGENCY`,
    (payload) => payload,
    (_, callback) => callback
)
export const fetchAgencyRequest = createAction(`${ENTITY}/FETCH_AGENCY_REQUEST`)
export const fetchAgencySuccess = createAction(`${ENTITY}/FETCH_AGENCY_SUCCESS`)
export const fetchAgencyFailure = createAction(`${ENTITY}/FETCH_AGENCY_FAILURE`)

export const updateAgency = createAction(
    `${ENTITY}/UPDATE_AGENCY`,
    (values) => values,
    (_, callback) => callback
)
export const updateAgencyRequest = createAction(`${ENTITY}/UPDATE_AGENCY_REQUEST`)
export const updateAgencySuccess = createAction(`${ENTITY}/UPDATE_AGENCY_SUCCESS`)
export const updateAgencyFailure = createAction(`${ENTITY}/UPDATE_AGENCY_FAILURE`)
