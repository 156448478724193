import { put, takeLatest } from 'redux-saga/effects';
import { apiRequest } from '../api';
import { fetchExportPortals, fetchExportPortalsFailure, fetchExportPortalsRequest, fetchExportPortalsSuccess, fetchExportSyncFailure, fetchExportSyncRequest, fetchExportSyncSuccess, triggerExportSync, fetchExportWebsites, fetchExportWebsitesFailure, fetchExportWebsitesRequest, fetchExportWebsitesSuccess, fetchExportSync } from './actions';

export function* fetchExportPortalsSaga() {
  yield put(fetchExportPortalsRequest());
  try {
    const response = yield apiRequest('v1/export/portals', {
      method: 'GET'
    });
    yield put(fetchExportPortalsSuccess(response.data));
  } catch (error) {
    yield put(fetchExportPortalsFailure(error));
  }
}

export function* fetchExportWebsitesSaga() {
  yield put(fetchExportWebsitesRequest());
  try {
    const response = yield apiRequest('v1/export/websites', {
      method: 'GET'
    });
    yield put(fetchExportWebsitesSuccess(response.data));
  } catch (error) {
    yield put(fetchExportWebsitesFailure(error));
  }
}

export function* syncExportSaga() {
  yield put(fetchExportSyncRequest());
  try {
    const response = yield apiRequest('v1/export/synchronize', {
      method: 'GET'
    });
    yield put(fetchExportSyncSuccess(response.data));
  } catch (error) {
    yield put(fetchExportSyncFailure(error));
  }
}

export function* fetchExportSyncSaga() {
  yield put(fetchExportSyncRequest());
  try {
    const response = yield apiRequest('v1/export/synchronization', {
      method: 'GET'
    });
    yield put(fetchExportSyncSuccess(response.data));
  } catch (error) {
    yield put(fetchExportSyncFailure(error));
  }
}


export default function* actionWatcher() {
  yield takeLatest(`${fetchExportPortals}`, fetchExportPortalsSaga);
  yield takeLatest(`${fetchExportWebsites}`, fetchExportWebsitesSaga);
  yield takeLatest(`${fetchExportSync}`, fetchExportSyncSaga);
  yield takeLatest(`${triggerExportSync}`, syncExportSaga);
}
