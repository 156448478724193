import { createAction } from 'redux-actions'

const ENTITY = 'CONTACTS'
export const stateKey = ENTITY.toLowerCase()

// export const fetchContacts = createAction(`${ENTITY}/FETCH`);
export const fetchContacts = createAction(
    `${ENTITY}/FETCH`,
    (payload) => payload,
    (_, callback) => callback
)
export const fetchContactsRequest = createAction(`${ENTITY}/FETCH_REQUEST`)
export const fetchContactsSuccess = createAction(`${ENTITY}/FETCH_SUCCESS`)
export const fetchContactsFailure = createAction(`${ENTITY}/FETCH_FAILURE`)

export const fetchContact = createAction(`${ENTITY}/DETAILS/FETCH`)
export const fetchContactRequest = createAction(`${ENTITY}/DETAILS/FETCH_REQUEST`)
export const fetchContactSuccess = createAction(`${ENTITY}/DETAILS/FETCH_SUCCESS`)
export const fetchContactFailure = createAction(`${ENTITY}/DETAILS/FETCH_FAILURE`)

export const createContact = createAction(
    `${ENTITY}/CREATE`,
    (values) => values,
    (values, callback) => ({ callback })
)
// export const createContact = createAction(`${ENTITY}/CREATE`);
export const createContactRequest = createAction(`${ENTITY}/CREATE_REQUEST`)
export const createContactSuccess = createAction(`${ENTITY}/CREATE_SUCCESS`)
export const createContactFailure = createAction(`${ENTITY}/CREATE_FAILURE`)

export const updateContact = createAction(
    `${ENTITY}/UPDATE`,
    (values) => values,
    (values, callback) => ({ callback })
)
// export const createContact = createAction(`${ENTITY}/CREATE`);
export const updateContactRequest = createAction(`${ENTITY}/UPDATE_REQUEST`)
export const updateContactSuccess = createAction(`${ENTITY}/UPDATE_SUCCESS`)
export const updateContactFailure = createAction(`${ENTITY}/UPDATE_FAILURE`)

export const deleteContact = createAction(
    `${ENTITY}/DELETE`,
    (payload) => payload,
    (_, redirect) => ({ redirect })
)
export const deleteContactRequest = createAction(`${ENTITY}/DELTE_REQUEST`)
export const deleteContactSuccess = createAction(`${ENTITY}/DELTE_SUCCESS`)
export const deleteContactFailure = createAction(`${ENTITY}/DELETE_FAILURE`)

export const deleteContactMultiple = createAction(
    `${ENTITY}/DELETE_MULTPLE`,
    (payload) => payload,
    (payload, callback) => callback
)
export const deleteContactMultipleRequest = createAction(`${ENTITY}/DELTE_MULTPLE_REQUEST`)
export const deleteContactMultipleSuccess = createAction(`${ENTITY}/DELTE_MULTPLE_SUCCESS`)
export const deleteContactMultipleFailure = createAction(`${ENTITY}/DELETE_MULTPLE_FAILURE`)

export const fetchContactsAsyncInput = createAction(
    `${ENTITY}/FETCH_ASYNC_INPUT`,
    (payload) => payload,
    (payload, callback) => callback
)
export const fetchContactsAsyncInputContactRequest = createAction(`${ENTITY}/FETCH_ASYNC_INPUT_REQUEST`)
export const fetchContactsAsyncInputContactSuccess = createAction(`${ENTITY}/FETCH_ASYNC_INPUT_SUCCESS`)
export const fetchContactsAsyncInputContactFailure = createAction(`${ENTITY}/FETCH_ASYNC_INPUT_FAILURE`)

export const uploadContactDocument = createAction(`${ENTITY}/UPLOAD_DOCUMENT`)
export const uploadContactDocumentRequest = createAction(`${ENTITY}/UPLOAD_DOCUMENT_REQUEST`)
export const uploadContactDocumentSuccess = createAction(`${ENTITY}/UPLOAD_DOCUMENT_SUCCESS`)
export const uploadContactDocumentFailure = createAction(`${ENTITY}/UPLOAD_DOCUMENT_FAILURE`)

export const deleteContactDocument = createAction(`${ENTITY}/DELETE_DOCUMENT`)
export const deleteContactDocumentRequest = createAction(`${ENTITY}/DELETE_DOCUMENT_REQUEST`)
export const deleteContactDocumentSuccess = createAction(`${ENTITY}/DELETE_DOCUMENT_SUCCESS`)
export const deleteContactDocumentFailure = createAction(`${ENTITY}/DELETE_DOCUMENT_FAILURE`)

export const queContactDocument = createAction(`${ENTITY}/QUE_DOCUMENT`)
export const queContactDocumentRequest = createAction(`${ENTITY}/QUE_DOCUMENT_REQUEST`)
export const queContactDocumentSuccess = createAction(`${ENTITY}/QUE_DOCUMENT_SUCCESS`)
export const queContactDocumentFailure = createAction(`${ENTITY}/QUE_DOCUMENT_FAILURE`)
export const clearQueContactDocument = createAction(`${ENTITY}/CLEAR_QUE_DOCUMENT`)

export const fetchContactDocuments = createAction(
    `${ENTITY}/DOCUMENTS/FETCH`,
    (payload) => payload,
    (_, callback) => callback
)
// export const fetchContactDocuments = createAction(`${ENTITY}/DOCUMENTS/FETCH`);
export const fetchContactDocumentsRequest = createAction(`${ENTITY}/DOCUMENTS/FETCH_REQUEST`)
export const fetchContactDocumentsSuccess = createAction(`${ENTITY}/DOCUMENTS/FETCH_SUCCESS`)
export const fetchContactDocumentsFailure = createAction(`${ENTITY}/DOCUMENTS/FETCH_FAILURE`)

export const downloadContactDocument = createAction(
    `${ENTITY}/DOCUMENTS/DOWNLOAD`,
    (payload) => payload,
    (_, callback) => callback
)
// export const fetchContactDocuments = createAction(`${ENTITY}/DOCUMENTS/FETCH`);
export const downloadContactDocumentRequest = createAction(`${ENTITY}/DOCUMENTS/DOWNLOAD_REQUEST`)
export const downloadContactDocumentSuccess = createAction(`${ENTITY}/DOCUMENTS/DOWNLOAD_SUCCESS`)
export const downloadContactDocumentFailure = createAction(`${ENTITY}/DOCUMENTS/DOWNLOAD_FAILURE`)

export const syncContactDocument = createAction(
    `${ENTITY}/DOCUMENTS/SYNC`,
    (payload) => payload,
    (_, callback) => callback
)

export const syncContactDocumentRequest = createAction(`${ENTITY}/DOCUMENTS/SYNC_REQUEST`)
export const syncContactDocumentSuccess = createAction(`${ENTITY}/DOCUMENTS/SYNC_SUCCESS`)
export const syncContactDocumentFailure = createAction(`${ENTITY}/DOCUMENTS/SYNC_FAILURE`)

export const fetchContactsNoStore = createAction(
    `${ENTITY}/NO_STORE/FETCH`,
    (payload) => payload,
    (payload, callback) => ({ callback })
)
export const fetchContactsNoStoreRequest = createAction(`${ENTITY}/NO_STORE/FETCH_REQUEST`)
export const fetchContactsNoStoreSuccess = createAction(`${ENTITY}/NO_STORE/FETCH_SUCCESS`)
export const fetchContactsNoStoreFailure = createAction(`${ENTITY}/NO_STORE/FETCH_FAILURE`)

export const resetPageContact = createAction(`${ENTITY}/RESET_PAGE_CONTACT`)

export const updateContactsAgent = createAction(`${ENTITY}/UPDATE_CONTACTS_AGENT`)
