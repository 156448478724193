import React, { useMemo, useState } from 'react'
import { Card, CardBody, Col, Container, Dropdown, DropdownMenu, DropdownToggle, Row } from 'reactstrap'
import SelectReport from './SelectReport'
import PageTitle from '../../Components/Common/PageTitle'
import { defaultEndDate, defaultStartDate } from './constants'
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom'
import { ReportType } from './types'
import { AgentActivitiesReport } from './AgentActivitiesReport'
import ReportListFilter from './ReportListFilter'
import { AgentRevenueReport } from './AgentRevenueReport'
import { AgentRevenueBySourceReport } from './AgentRevenueBySourceReport'
import { AgentInquiriesReport } from './AgentInquiriesReport'
import { contactResponseFields } from '../../common/response/contact'
import { selectContactSourceValues } from '../../store/contactValues'
import { selectPropertyValuesDataSource } from '../../store/propertyValues/selectors'
import { formatDateTime } from '../../utils/general'

const ReportRoute = {
    [ReportType.agentActivities]: AgentActivitiesReport,
    [ReportType.agentRevenue]: AgentRevenueReport,
    [ReportType.agentRevenueBySource]: AgentRevenueBySourceReport,
    [ReportType.agentInquiryByContactSource]: AgentInquiriesReport,
    [ReportType.agentInquiryByDataSource]: AgentInquiriesReport,
}

const ReportList = () => {
    const [searchCriteria, setSearchCriteria] = useState({
        dateFrom: defaultStartDate,
        dateTo: defaultEndDate,
    })

    const { path } = useRouteMatch()

    const [isDropdownOpen, setIsDropdownOpen] = useState(false)

    document.title = 'Izvještaji | Dimedia nekretnine CRM'

    const handleFilterSubmit = (formValues) => {
        const { dateFrom, dateTo } = formValues

        setSearchCriteria({
            dateFrom,
            dateTo,
        })

        setIsDropdownOpen(false)
    }

    const handleFilterReset = () => {
        setIsDropdownOpen(false)
    }

    const currentDateRange = useMemo(() => {
        if (searchCriteria) {
            const { dateFrom, dateTo } = searchCriteria

            const formattedDateFrom = formatDateTime(dateFrom)
            const formattedDateTo = formatDateTime(dateTo)

            return `[${formattedDateFrom} - ${formattedDateTo}]`
        }

        return null
    }, [searchCriteria])

    const tableActions = (
        <Row>
            <Col>
                <SelectReport />
            </Col>
            <Col xs="auto" className="ms-auto align-self-center">
                {currentDateRange}
            </Col>
            <Col xs="auto">
                <Dropdown isOpen={isDropdownOpen} toggle={() => ({})}>
                    <DropdownToggle className="btn btn-primary" tag="button" onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                        <i className="mdi mdi-filter-variant align-middle pe-none"></i>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-form">
                        <ReportListFilter value={searchCriteria} onFilterSubmit={handleFilterSubmit} onFilterReset={handleFilterReset} />
                    </DropdownMenu>
                </Dropdown>
            </Col>
        </Row>
    )

    return (
        <div className="page-content">
            <Container fluid>
                <PageTitle title="Izvještaji" />
                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardBody>
                                {tableActions}
                                <Switch>
                                    <Route
                                        path={path}
                                        render={({ match }) => {
                                            const { reportType } = match.params

                                            if (!reportType) {
                                                return <Redirect to={`/reports/${ReportType.agentActivities}`} />
                                            }

                                            const Report = ReportRoute[reportType]

                                            if (reportType === ReportType.agentInquiryByContactSource) {
                                                return <Report searchCriteria={searchCriteria} sourceKey={contactResponseFields.CONTACT_SOURCE} selectSources={selectContactSourceValues} />
                                            } else if (reportType === ReportType.agentInquiryByDataSource) {
                                                return <Report searchCriteria={searchCriteria} sourceKey="contactDataSource" selectSources={selectPropertyValuesDataSource} />
                                            } else {
                                                return <Report searchCriteria={searchCriteria} />
                                            }
                                        }}
                                    ></Route>
                                </Switch>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ReportList
