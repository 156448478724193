import { useDispatch, useSelector } from "react-redux";
import { USER_SETTINGS } from "../common/constants";
import { selectCurrentUserId, selectCurrentUserSettings } from "../store/auth/selectors";
import { isEqual } from "lodash";
import { updateCurrentSettings } from "../store/auth/actions";

export const usePageSizeSettings = (appModule) => {
    const dispatch = useDispatch();

    const { userSettings, userId } = useSelector(state => ({
        userSettings: selectCurrentUserSettings(state),
        userId: selectCurrentUserId(state),
    }));

    const pageSizeKey = `${appModule}_${USER_SETTINGS.PAGE_SIZE}`;

    const userSettingsLoaded = Boolean(userSettings?.[pageSizeKey]);

    const pageSizeDirtyCheck = (pageSize) => {
        // Return as dirty to trigger update
        if (!userSettingsLoaded) {
            return true;
        }

        return !isEqual(pageSize, userSettings[pageSizeKey]);
    }

    const updatePageSize = (pageSize) => {
        if (pageSizeDirtyCheck(pageSize)) {
            dispatch(updateCurrentSettings({
                userId,
                userSettings: {
                    ...userSettings,
                    [pageSizeKey]: pageSize
                }
            }));
        }
    }

    return {
        pageSize: userSettings?.[pageSizeKey] || 20,
        updatePageSize,
    }
}