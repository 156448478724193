import { handleActions } from 'redux-actions'
import { fetchActivityValuesAvailableEntitiesSuccess, fetchActivityValuesTypeRequest, fetchActivityValuesTypeSuccess } from './actions'

export const initialState = {
    availableentities: {},
    type: {},
    typefetching: false,
}

export default handleActions(
    {
        [fetchActivityValuesAvailableEntitiesSuccess]: (state, { payload }) => ({
            ...state,
            availableentities: payload.reduce(
                (dict, item) => ({
                    ...dict,
                    [item]: item,
                }),
                {}
            ),
        }),
        [fetchActivityValuesTypeRequest]: (state) => ({
            ...state,
            typefetch: true,
        }),
        [fetchActivityValuesTypeSuccess]: (state, { payload }) => ({
            ...state,
            typefetch: false,
            type: payload.reduce(
                (dict, item) => ({
                    ...dict,
                    [item.id]: { ...item },
                }),
                {}
            ),
        }),
    },
    initialState
)
