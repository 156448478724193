import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import SimpleBar from 'simplebar-react'
import { clearDocuments, deleteDocumentMultiple } from '../../store/documents'
import { resetTableCheckbox } from '../../store/table'
import { selectCheckedTableRows } from '../../store/table/selectors'
import DeleteModal from '../Common/DeleteModal'
import DocumentFormSidebar from '../DocumentFormTab/DocumentFormSidebar'
import TableContainer from '../Table/TableContainer'
import { useFormatTableData } from '../Table/utils'
import useDocumentsHeaders from './hooks/useDocumentsHeaders'

const DocumentViewTab = ({
    files,
    contactType,
    deleteAction,
    addButtonAction,
    fetchAction,
    fetching,
    syncAction,
    documentModule,
    openDocumentFormAdd
}) => {
    const dispatch = useDispatch()
    const { id } = useParams()
    const { t } = useTranslation()

    const { selectedTableRows } = useSelector((state) => ({
        selectedTableRows: selectCheckedTableRows(state),
    }))

    const [sidebarEditMode, setSidebarEditMode] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [deleteModalMulti, setDeleteModalMulti] = useState(false)
    const [rowId, setRowId] = useState(null)
    const [sidebarData, setSidebarData] = useState('')
    const [pageCount, setPageCount] = useState(0)
    const [documentCount, setDocumentCount] = useState(0)

    // SideBar Close
    function sidebarClose(value) {
        const element = document.getElementsByTagName('body')[0]
        element.classList.remove(value)
        setSidebarEditMode(false)
        setSidebarData('')
    }

    useEffect(() => {
        return () => {
            sidebarClose('file-detail-show')
        }
    }, [])

    useEffect(() => {
        dispatch(clearDocuments())
    }, [dispatch, id])

    const handleDataFetch = (page, limit) => {
        dispatch(
            fetchAction(
                {
                    ...(id ? { parentId: id } : {}),
                    page,
                    limit,
                    order: {},
                    criteria: {
                        module: documentModule,
                        collection: 'document',
                    },
                },
                (count) => {
                    setPageCount(Math.ceil(count / limit))
                    setDocumentCount(count)
                }
            )
        )
    }

    const onClickDelete = (id) => {
        setRowId(id)
        setDeleteModal(true)
    }

    const handleDeleteDocument = () => {
        dispatch(deleteAction(rowId))
        dispatch(resetTableCheckbox(true))
        setDeleteModal(false)
    }

    const deleteMultipleDocuments = () => {
        const selectedRowsIds = selectedTableRows.map((row) => row.id)

        dispatch(deleteDocumentMultiple(selectedRowsIds))
        dispatch(resetTableCheckbox(true))
        setDeleteModalMulti(false)
    }

    const tableData = useFormatTableData(files, 'documents')
    const columns = useDocumentsHeaders(documentModule, dispatch, syncAction, setSidebarData, setSidebarEditMode, onClickDelete, 'documents')

    const tableActions = () => (
        <div className="d-flex flex-grow-1">
            {!openDocumentFormAdd && (
                <Button
                    onClick={(e) => {
                        e.preventDefault()
                        if (addButtonAction) {
                            addButtonAction()
                        }
                    }}
                    color="add"
                    className="btn-label"
                >
                    <i className="mdi mdi-plus align-bottom label-icon align-middle fs-16 me-2"></i>
                    {t('button.add')}
                </Button>
            )}

            <UncontrolledDropdown className="ms-2">
                <DropdownToggle title={t('table.showMore')} href="#" className="btn btn-primary dropdown" tag="button">
                    <i className="mdi mdi-dots-horizontal align-middle"></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem href="#" onClick={() => setDeleteModalMulti(true)}>
                        <i className="mdi mdi-trash-can-outline align-bottom me-2 text-muted"></i> {t('table.action.deleteSelected')}
                    </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
        </div>
    )

    return (
        <>
            <DeleteModal show={deleteModal} onDeleteClick={() => handleDeleteDocument()} onCloseClick={() => setDeleteModal(false)} />
            <DeleteModal
                show={deleteModalMulti}
                onDeleteClick={() => {
                    deleteMultipleDocuments()
                }}
                onCloseClick={() => setDeleteModalMulti(false)}
            />
            <div className="chat-wrapper d-lg-flex gap-1">
                <div className={classNames('file-manager-content w-100 px-0 py-0', { 'pe-2': sidebarData })}>
                    <SimpleBar className="overflow-hidden">
                        <TableContainer
                            columns={columns}
                            tableActions={tableActions}
                            data={tableData || []}
                            isGlobalFilter={false}
                            isAddUserList={false}
                            isLoading={fetching}
                            customPageSize={20}
                            tableClass="dmn-table--property-documents"
                            theadClass={'dmn-table-header'}
                            totalCount={documentCount}
                            pageCount={pageCount}
                            responsive={false}
                            handleDataFetch={handleDataFetch}
                        />
                    </SimpleBar>
                </div>
                <DocumentFormSidebar
                    sidebarData={sidebarData}
                    sidebarClose={sidebarClose}
                    onClickDelete={onClickDelete}
                    isEdit={sidebarEditMode}
                    contactType={contactType}
                    documentModule={documentModule}
                    parentId={id}
                />
            </div>
        </>
    )
}

export default DocumentViewTab
