import React, { useMemo } from 'react'
import TableContainer from '../../Components/Table/TableContainer'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAgentActivities, selectOmittedActivitiesReports, selectReportsFetching } from '../../store/reports'
import { selectOmittedActivityValuesType } from '../../store/activityValues'
import { agentActivitiesReportColumnSortFn, getAgentActivitiesReportSum } from '../../utils/report'
import { totalReducer } from '../../utils/general'
import { initialActivitiesIds, initialActivitiesSum, targetedActivitiesIds, targetedActivitiesSum } from '../../utils/activity'
import { INITIAL_ACTIVITIES_ID, TARGETED_ACTIVITIES_ID } from './constants'

export const AgentActivitiesReport = ({ searchCriteria }) => {
    const dispatch = useDispatch()

    const { reports, reportsFetching, activityTypes } = useSelector((state) => ({
        reports: selectOmittedActivitiesReports(state),
        reportsFetching: selectReportsFetching(state),
        activityTypes: selectOmittedActivityValuesType(state),
    }))

    const columns = useMemo(() => {
        if (activityTypes?.length > 0) {
            const activityColumns = activityTypes.map(({ id, name }) => ({
                Header: name,
                id: `${id}`,
                accessor: ({ activities }) => activities[id],
                filterable: false,
            }))

            // Add targeted activities sum
            activityColumns.push({
                Header: 'Ciljane aktivnosti',
                id: TARGETED_ACTIVITIES_ID,
                accessor: ({ activities }) => targetedActivitiesSum(activities),
                filterable: false,
                tdClassName: 'bg-light',
            })

            // Add initial activities sum
            activityColumns.push({
                Header: 'Inicijalne aktivnosti',
                id: INITIAL_ACTIVITIES_ID,
                accessor: ({ activities }) => initialActivitiesSum(activities),
                filterable: false,
                tdClassName: 'bg-light',
            })

            // Sort columns in desired order
            activityColumns.sort(agentActivitiesReportColumnSortFn('id'))

            return [
                {
                    Header: 'Agent',
                    id: 'agent',
                    accessor: ({ agent }) => `${agent.firstName} ${agent.lastName}`,
                    filterable: false,
                },
                ...activityColumns,
                {
                    Header: 'Ukupno',
                    id: 'total_by_agent',
                    accessor: ({ activities }) => {
                        const activityData = Object.values(activities)
                        const total = activityData.reduce(totalReducer, 0)

                        return <strong>{total}</strong>
                    },
                    filterable: false,
                },
            ]
        }

        return []
    }, [activityTypes])

    const handleDataFetch = (page, limit, order, criteria) => {
        dispatch(fetchAgentActivities({ page, limit, order, criteria }))
    }

    const agentActivitiesReportSum = useMemo(() => getAgentActivitiesReportSum(reports), [reports])

    const tableFooter = useMemo(() => {
        if (!agentActivitiesReportSum) return null

        const agentActivitiesReportSumEntries = Object.entries(agentActivitiesReportSum)

        const agentActivitiesReportSums = Object.values(agentActivitiesReportSum)

        const agentActivitiesReportSumTotal = agentActivitiesReportSums.reduce((total, curr) => total + curr, 0)

        // Add targeted activities sum
        const targetedActivitiesSum = targetedActivitiesIds.reduce((total, id) => total + agentActivitiesReportSum[id], 0)
        agentActivitiesReportSumEntries.push([TARGETED_ACTIVITIES_ID, targetedActivitiesSum])

        // Add initial activities sum
        const initialActivitiesSum = initialActivitiesIds.reduce((total, id) => total + agentActivitiesReportSum[id], 0)
        agentActivitiesReportSumEntries.push([INITIAL_ACTIVITIES_ID, initialActivitiesSum])

        // Sort totals in desired order
        agentActivitiesReportSumEntries.sort(agentActivitiesReportColumnSortFn())

        return (
            <tr>
                <td>Ukupno</td>
                {agentActivitiesReportSumEntries.map(([k, value]) => (
                    <td key={k}>{value}</td>
                ))}
                <td>{agentActivitiesReportSumTotal}</td>
            </tr>
        )
    }, [agentActivitiesReportSum])

    return (
        <TableContainer
            columns={columns}
            data={reports || []}
            className="custom-header-css"
            theadClass={'dmn-table-header'}
            isLoading={reportsFetching}
            handleDataFetch={handleDataFetch}
            searchCriteria={searchCriteria}
            tableFooter={tableFooter}
            hidePagination
        />
    )
}
