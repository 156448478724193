import { getIn } from "formik";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Card, CardBody, Col, Input, Label, Row } from "reactstrap";
import { selectCityById } from "../../store/locations";
import FormMainContainer from "../Common/FormMainContainer";
import FormSideContainer from "../Common/FormSideContainer";
import MapContainer from "../MapContainer";
import RecursiveForm from "../RecursiveForm";
import MapFormContext from "./MapFormContext";
import { mapFields } from "../../common/forms/general/fields";
import { isApproxLocationSyncType } from "../../utils/general";
import { useTranslation } from 'react-i18next'

const MapFormTab = ({ children, subform = 'map', ...props }) => {
    const [addressSearch, setAddressSearch] = useState(null);
    const [marker, setMarker] = useState(null);
    
    useEffect(() => {
        const { map_latitude, map_longitude } = getIn(props.formik.values, subform)

        if (!marker && map_latitude && map_longitude) {
            setMarker({ lat: +map_latitude, lng: +map_longitude });
        }
    }, [props.formik.values]);

    return (
        <MapFormContext.Provider
            value={{
                formik: props.formik,
                inputs: props.inputs,
                subform: subform,
                tabs: props.tabs,
                activeTab: props.activeTab,
                handleTabChange: props.handleTabChange,
                addressSearch,
                setAddressSearch,
                marker,
                setMarker
            }}
        >
            <Row>
                {children}
            </Row>
        </MapFormContext.Provider>
    )
}

export const MapFormTabSide = (props) => {
    const { inputs, subform, formik, setAddressSearch, marker, setMarker } = useContext(MapFormContext);
    const { t } = useTranslation()

    const { city } = useSelector((state) => ({
        city: selectCityById(formik.values.general.city_id)(state),
    }));

    const [address, setAddress] = useState('');

    useEffect(() => {
        const { street, street_number } = formik.values.general;

        if (street && street_number && city) {
            const _address = `${street} ${street_number}, ${city.name}`;
            setAddress(_address);
        } else {
            setAddress('');
        }

    }, [formik.values.general, city]);

    const handleCoordinatesPaste = (e) => {
        const paste = (e.clipboardData || window.clipboardData).getData('text');
        const cords = paste.split(',');

        if (cords.length === 2) {
            e.preventDefault();
            formik.setFieldValue(`${subform}.${[mapFields.MAP_LATITUDE]}`, cords[0].replace(/\s/g, ''));
            formik.setFieldValue(`${subform}.${[mapFields.MAP_LONGITUDE]}`, cords[1].replace(/\s/g, ''));
        }
    }

    const handleDeleteMarker = () => {
        setMarker(null);
        formik.setFieldValue(`${subform}.${[mapFields.MAP_LATITUDE]}`, '');
        formik.setFieldValue(`${subform}.${[mapFields.MAP_LONGITUDE]}`, '');
    }

    const handleAddMarker = () => {
        const { map_latitude, map_longitude } = getIn(formik.values, subform);

        const lat = +map_latitude || null;
        const lng = +map_longitude || null;

        if (lat && lng) {
            setMarker({
                lat,
                lng
            });
        }
    }

    if (inputs.sideForm) {
        const latitudeIndex = inputs.sideForm?.findIndex(obj => (obj.name === mapFields.MAP_LATITUDE));
        const longitudeIndex = inputs.sideForm?.findIndex(obj => (obj.name === mapFields.MAP_LONGITUDE));
        if (latitudeIndex !== -1) {
            inputs.sideForm[latitudeIndex].inputProps.onPaste = handleCoordinatesPaste;
        }
        if (longitudeIndex) {
            inputs.sideForm[longitudeIndex].inputProps.onPaste = handleCoordinatesPaste;
        }
    }

    return (
        <FormSideContainer>
            <Card>
                <CardBody>
                    <Col sm={12} className="mb-4">
                        <Label for="address">{t('view.contact.general.address')}</Label>
                        <Input
                            type="text"
                            className="form-control"
                            id="address"
                            name="address"
                            onChange={(e) => setAddress(e.target.value)}
                            value={address}
                        />
                        <div className="mt-3 pb-3" style={{ borderBottom: '1px solid #E7E9EB' }}>
                            <Button color="primary" className="btn-label" onClick={() => setAddressSearch(address)}> <i className="mdi mdi-map-marker-radius-outline label-icon align-middle fs-16 me-2"></i> {t('view.project.map.showOnMap')}</Button>
                        </div>
                    </Col>
                    <RecursiveForm inputs={inputs?.sideForm} subform={subform} formik={formik} />
                    <Col sm={12} >
                        {marker ? (
                            <Button color="danger" className="btn-label" onClick={handleDeleteMarker}> <i className="mdi mdi-trash-can-outline label-icon align-middle fs-16 me-2"></i> {t('view.project.map.deleteMarker')}</Button>
                        ) : (
                            <Button color="add" className="btn-label" onClick={handleAddMarker}> <i className="mdi mdi-plus label-icon align-middle fs-16 me-2"></i> {t('view.project.map.addMarker')}</Button>
                        )}
                    </Col>
                </CardBody>
            </Card>
        </FormSideContainer>
    )
}

export const MapFormTabMain = (props) => {
    const { tabs, activeTab, formik, subform, handleTabChange, addressSearch, setAddressSearch, marker, setMarker } = useContext(MapFormContext);
    const isApproxLocation = isApproxLocationSyncType(formik.values?.[subform]);

    const handleAddressSearchDone = (location) => {
        setAddressSearch(null);
        handleLocationChange(location.lat, location.lng)
    }

    const handleLocationChange = (lat, lng) => {
        formik.setFieldValue(`${subform}.${[mapFields.MAP_LATITUDE]}`, lat);
        formik.setFieldValue(`${subform}.${[mapFields.MAP_LONGITUDE]}`, lng);
        setMarker({ lat, lng });
    }

    return (
        <FormMainContainer tabs={tabs} activeTab={activeTab} handleTabChange={handleTabChange}>
            <MapContainer
                handleAddressSearchDone={handleAddressSearchDone}
                address={addressSearch}
                marker={marker}
                showRadius={isApproxLocation}
                handleAddMarkerOnClick={handleLocationChange}
                handleMapMarkerOnMove={handleLocationChange}
            />
        </FormMainContainer>
    )
}

export default MapFormTab;