import { useTranslation } from 'react-i18next'
import { Col } from 'reactstrap'

const TablePageSize = ({ className, pageLength, totalCount, pageOptionsLength }) => {
    const { t } = useTranslation()

    return (
        <Col className={className}>
            <span style={{ color: '#8F99A3' }}>{t('table.resultsDisplayed', { displayedCount: pageLength, totalCount: totalCount ? totalCount : pageLength * pageOptionsLength })}</span>
        </Col>
    )
}

export default TablePageSize
