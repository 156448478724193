import { debounce } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import Select from 'react-select'
import { Col, Label, Row, UncontrolledTooltip } from 'reactstrap'
import { CONTACT_TYPE } from '../../common/constants'
import { activityFields } from '../../common/forms/activities/fields'
import { fetchContactsAsyncInput } from '../../store/contacts'

const ContactCompanyCascadeInput = ({ inputField, fieldNames, inputValues, formik, selectOptions }) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const [isContactsFetching, setIsContactsFetching] = useState(false)
    const [isCompaniesFetching, setIsCompaniesFetching] = useState(false)
    const [contactOptions, setContactOptions] = useState([])
    const [companyOptions, setCompanyOptions] = useState([])
    const [selectedCompany, setSelectedCompany] = useState(null)
    const [selectedContact, setSelectedContact] = useState(null)
    const [isFirstRun, setIsFirstRun] = useState(true)
    const [mode, setMode] = useState(null)

    useEffect(() => {
        if (
            (inputValues?.[fieldNames[0]] || inputValues?.[fieldNames[1]].length) &&
            (selectOptions?.[fieldNames[0]]?.length || selectOptions?.[fieldNames[1]]?.length) &&
            isFirstRun
        ) {
            setContactOptions(selectOptions[fieldNames[0]])

            setSelectedContact(selectOptions[fieldNames[0]].find((item) => item.value.toString() === inputValues[fieldNames[0]]?.toString()))

            setCompanyOptions(selectOptions[fieldNames[1]])

            const selectedCompanyOptions = inputValues[fieldNames[1]].map((item) =>
                selectOptions[fieldNames[1]].find((option) => option.value.toString() === item[activityFields.CONTACT_ID].toString())
            )

            setSelectedCompany(selectedCompanyOptions)

            if (selectedCompanyOptions?.length) {
                let params = {
                    noLimit: 1,
                    responseType: 'basic',
                    criteria: {
                        contactType: 'person',
                        contactCompany: selectedCompanyOptions.map((x) => x.value),
                    },
                }
                setIsContactsFetching(true)
                dispatch(
                    fetchContactsAsyncInput(
                        {
                            params,
                        },
                        (asyncValues) => {
                            setIsContactsFetching(false)

                            if (asyncValues?.length) {
                                let asyncSelectOptions = asyncValues.map((item) => {
                                    return {
                                        value: item.value.toString(),
                                        id: item.value,
                                        label: item.label,
                                        name: item.label,
                                        // contactPersonCompany: item.data.contactPersonCompany
                                    }
                                })
                                const combinedArray = [...selectOptions[fieldNames[0]], ...asyncSelectOptions]
                                const uniqueObjectsMap = new Map()

                                for (const obj of combinedArray) {
                                    uniqueObjectsMap.set(obj.value, obj)
                                }

                                const resultArray = Array.from(uniqueObjectsMap.values())

                                setContactOptions(resultArray)
                            }
                        }
                    )
                )
            }
            setIsFirstRun(false)
        }
    }, [inputValues, selectOptions, fieldNames, isFirstRun, dispatch])

    useEffect(() => {
        if (!selectedContact && !selectedCompany) {
            setMode(null)
        }
    }, [selectedContact, selectedCompany])

    const getContactOptions = (inputValue) => {
        let params = {
            noLimit: 1,
            criteria: {
                contactType: 'person',
                name: inputValue,
            },
        }

        dispatch(
            fetchContactsAsyncInput(
                {
                    params,
                },
                (asyncValues) => {
                    setIsContactsFetching(false)
                    if (asyncValues?.length) {
                        let selectOptions = asyncValues.map((item) => {
                            return {
                                value: item.value,
                                id: item.value,
                                label: item.label,
                                name: item.label,
                                contactPersonCompany: item.data.contactPersonCompany,
                            }
                        })

                        setContactOptions(selectOptions)
                    }
                }
            )
        )
    }

    const getCompanyOptions = (inputValue) => {
        let params = {
            noLimit: 1,
            criteria: {
                contactType: 'company',
                name: inputValue,
            },
        }

        dispatch(
            fetchContactsAsyncInput(
                {
                    params,
                },
                (asyncValues) => {
                    setIsCompaniesFetching(false)

                    if (asyncValues?.length) {
                        let selectOptions = asyncValues.map((item) => {
                            return {
                                value: item.value,
                                id: item.value,
                                label: item.label,
                                name: item.label,
                                // contactPersonCompany: item.data.contactPersonCompany
                            }
                        })

                        setCompanyOptions(selectOptions)
                    }
                }
            )
        )
    }

    const debounceHandleChange = useCallback(
        debounce((val, type) => {
            if (type === CONTACT_TYPE.PERSON) {
                setIsContactsFetching(true)
                getContactOptions(val)
            }
            if (type === CONTACT_TYPE.COMPANY) {
                setIsCompaniesFetching(true)
                getCompanyOptions(val)
            }
        }, 500),
        [fetchContactsAsyncInput]
    )

    const onContactInputChange = (value) => {
        if (value.length) {
            setMode(CONTACT_TYPE.PERSON)
            debounceHandleChange(value, CONTACT_TYPE.PERSON)
        }
    }

    const onCompanyInputChange = (value) => {
        if (value.length) {
            setMode(CONTACT_TYPE.COMPANY)
            debounceHandleChange(value, CONTACT_TYPE.COMPANY)
        }
    }

    const handleContactSelectChange = (contact) => {
        if (!contact && mode === CONTACT_TYPE.PERSON) {
            setCompanyOptions([])
            setSelectedCompany(null)
        }
        if (contact && mode === CONTACT_TYPE.PERSON) {
            setSelectedContact(contact)
            setSelectedCompany([])
            setCompanyOptions([])

            var contactCompanies = contact.contactPersonCompany

            if (contactCompanies?.length) {
                let selectOptions = contactCompanies.map((item) => {
                    return {
                        value: item.company.id,
                        id: item.company.id,
                        label: item.company.contactCompany.name,
                        name: item.company.contactCompany.name,
                    }
                })

                formik?.setFieldValue(
                    `${activityFields.CONTACT_COMPANY_COLLECTION}`,
                    selectOptions.map((item) => ({
                        [activityFields.CONTACT_ID]: item.value,
                    }))
                )
                setCompanyOptions(selectOptions)
                setSelectedCompany(selectOptions)
            }
        } else {
            setSelectedContact(null)
            setSelectedContact(contact)
        }
    }

    const handleCompanySelectChange = (e) => {
        setSelectedCompany(e)

        if (!e && mode === CONTACT_TYPE.COMPANY) {
            setSelectedCompany(null)
            setContactOptions([])
            setSelectedContact(null)
        }

        if (mode === CONTACT_TYPE.COMPANY) {
            setSelectedContact(null)
            setContactOptions([])
        }

        if (e.length) {
            formik?.setFieldValue(
                `${activityFields.CONTACT_COMPANY_COLLECTION}`,
                e
                    ? e.map((item) => ({
                          [activityFields.CONTACT_ID]: item.value,
                      }))
                    : []
            )
        } else {
            formik?.setFieldValue(
                `${activityFields.CONTACT_COMPANY_COLLECTION}`,
                e
                    ? [
                          {
                              [activityFields.CONTACT_ID]: e.value,
                          },
                      ]
                    : []
            )
        }
        if (mode === CONTACT_TYPE.COMPANY && (e?.value || e?.length)) {
            let params = {
                noLimit: 1,
                responseType: 'basic',
                criteria: {
                    contactType: 'person',
                    contactCompany: e?.length ? e.map((item) => item.value) : [e.value],
                },
            }
            setIsContactsFetching(true)
            dispatch(
                fetchContactsAsyncInput(
                    {
                        params,
                    },
                    (asyncValues) => {
                        setIsContactsFetching(false)

                        if (asyncValues?.length) {
                            let selectOptions = asyncValues.map((item) => {
                                return {
                                    value: item.value,
                                    id: item.value,
                                    label: item.label,
                                    name: item.label,
                                    // contactPersonCompany: item.data.contactPersonCompany
                                }
                            })

                            setContactOptions(selectOptions)
                        }
                    }
                )
            )
        }
    }
    return (
        <>
            <Col sm={12} className="mb-4">
                <div className="d-flex justify-content-between">
                    <Label for={activityFields.CONTACT_ID} id={`${activityFields.CONTACT_ID}Lbl`}>
                        {t('app.common.contactNameSurname')}
                    </Label>
                    <span id={`${activityFields.CONTACT_ID}Tooltip`}>
                        <i className="mdi mdi-help-circle-outline"></i>{' '}
                    </span>
                </div>
                <Select
                    onInputChange={(value) => {
                        onContactInputChange(value)
                    }}
                    onBlur={() => {
                        // formik?.setFieldTouched(fieldName)
                    }}
                    onChange={(e) => {
                        formik?.setFieldValue(`${activityFields.CONTACT_ID}`, e ? e.value : '')
                        handleContactSelectChange(e)
                    }}
                    options={contactOptions}
                    isClearable={true}
                    name={activityFields.CONTACT_ID}
                    id={activityFields.CONTACT_ID}
                    value={selectedContact}
                    noOptionsMessage={() => t('contactCompanyCascadeInput.message.noOptions')}
                    isLoading={isContactsFetching}
                    loadingMessage={() => t('app.common.loading')}
                    placeholder=""
                />
                <UncontrolledTooltip pement="right" target={`${activityFields.CONTACT_ID}Tooltip`}>
                    {t('app.common.contactNameSurname.tooltip')}
                </UncontrolledTooltip>
            </Col>
            <Col sm={12} className="mb-4">
                <div className="d-flex justify-content-between">
                    <Label for={activityFields.CONTACT_COMPANY_COLLECTION} id={`${activityFields.CONTACT_COMPANY_COLLECTION}Lbl`}>
                        {t('app.common.company.name')}
                    </Label>
                    <span id={`${activityFields.CONTACT_COMPANY_COLLECTION}Tooltip`}>
                        <i className="mdi mdi-help-circle-outline"></i>{' '}
                    </span>
                </div>
                <Select
                    onInputChange={(value) => {
                        onCompanyInputChange(value)
                    }}
                    onChange={(e) => {
                        handleCompanySelectChange(e)
                        setSelectedCompany(e)
                    }}
                    options={companyOptions}
                    isMulti={true}
                    isClearable={true}
                    name={activityFields.CONTACT_COMPANY_COLLECTION}
                    id={activityFields.CONTACT_COMPANY_COLLECTION}
                    value={selectedCompany}
                    noOptionsMessage={() => t('contactCompanyCascadeInput.message.noOptions')}
                    isLoading={isCompaniesFetching}
                    loadingMessage={() => t('app.common.loading')}
                    placeholder=""
                />
                <UncontrolledTooltip pement="right" target={`${activityFields.CONTACT_COMPANY_COLLECTION}Tooltip`}>
                    {t('app.common.company.name.tooltip')}
                </UncontrolledTooltip>
            </Col>
        </>
    )
}

export default ContactCompanyCascadeInput
