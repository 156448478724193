import i18n from 'i18next'
import _ from 'lodash'
import * as Yup from 'yup'
import { IS_ERSTE } from '../../constants'
import { removeEmpyObjectValues } from '../../../utils/array'
import { CONTACT_STATUS, REAL_ESTATE_TYPES } from '../../constants'
import responseFields from '../../constants/responseFields'
import { contactResponseFields } from '../../response/contact'
import {
    contactDetailSearchFields,
    contactDocumentFields,
    contactGeneralFields,
    contactNoteFields,
    contactOtherInfoFields,
    contactProfileFields,
} from './fields'

export const contactGeneralValidation = (data) => {
    return {
        initialValues: {
            [contactGeneralFields.ID]: data?.id ?? '',
            [contactGeneralFields.CONTACT_TITLE_ID]: data?.[contactResponseFields.CONTACT_PERSON]?.[contactResponseFields.CONTACT_TITLE]?.id ?? '',
            [contactGeneralFields.FIRST_NAME]: data?.[contactResponseFields.CONTACT_PERSON]?.firstName ?? '',
            [contactGeneralFields.LAST_NAME]: data?.[contactResponseFields.CONTACT_PERSON]?.lastName ?? '',
            [contactGeneralFields.INITIAL_CONTACT_DATE]: data?.initialContactDate ?? '',
            [contactGeneralFields.CONTACT_PRIORITY_ID]: data?.contactPriority?.id ?? '',
            [contactGeneralFields.DATA_SOURCE_ID]: data?.dataSource?.id ?? '',
            ...(IS_ERSTE && { [contactGeneralFields.CONTACT_SOURCE_ID]: data?.contactSource?.id?.toString() ?? '' }),
            contact_phone_group: data?.contactPhone?.length
                ? data?.contactPhone?.map((item) => ({
                      [contactGeneralFields.PHONE_NUMBER]: item?.phoneNumber ?? '',
                      [contactGeneralFields.CONTACT_PHONE_TYPE_ID]: item?.contactPhoneType?.id ?? '',
                  }))
                : [
                      {
                          [contactGeneralFields.PHONE_NUMBER]: '',
                          [contactGeneralFields.CONTACT_PHONE_TYPE_ID]: '',
                      },
                  ],
            contact_email_group: data?.contactEmail?.length
                ? data?.contactEmail?.map((item) => ({
                      [contactGeneralFields.EMAIL]: item?.email ?? '',
                      [contactGeneralFields.CONTACT_EMAIL_TYPE_ID]: item?.contactEmailType?.id ?? '',
                  }))
                : [
                      {
                          [contactGeneralFields.EMAIL]: '',
                          [contactGeneralFields.CONTACT_EMAIL_TYPE_ID]: '',
                      },
                  ],
            [contactGeneralFields.STREET]: data?.[contactResponseFields.STREET] ?? '',
            [contactGeneralFields.STREET_NUMBER]: data?.[contactResponseFields.STREET_NUMBER] ?? '',
            [contactGeneralFields.CONTACT_COUNTRY_ID]: data?.contactCountry?.id?.toString() ?? '',
            [contactGeneralFields.CITY]: data?.city ?? '',
            [contactGeneralFields.ZIP_CODE]: data?.zipCode ?? '',
            [contactGeneralFields.CONTACT_STATUS_ID]: data?.[contactResponseFields.CONTACT_STATUS]?.id?.toString() ?? '',
            [contactGeneralFields.AGENT_ID]: data?.agent?.id ?? '',
            [contactGeneralFields.CONTACT_QUERY_CATEGORY_ID]: data?.contactQueryCategory?.id ?? '',
            ...(IS_ERSTE && { [contactGeneralFields.CONTACT_ABOUT]: data?.contactAbout ?? '' }),
        },
        validationSchema: Yup.object({
            [contactGeneralFields.CONTACT_TITLE_ID]: Yup.number(),
            [contactGeneralFields.FIRST_NAME]: Yup.string().required().required(i18n.t('validation.message')),
            [contactGeneralFields.LAST_NAME]: Yup.string().required().required(i18n.t('validation.message')),
            [contactGeneralFields.INITIAL_CONTACT_DATE]: Yup.string(),
            [contactGeneralFields.CONTACT_ABOUT]: Yup.string(),
            [contactGeneralFields.CONTACT_SOURCE_ID]: Yup.string(),
            [contactGeneralFields.CONTACT_PRIORITY_ID]: Yup.number(),
            [contactGeneralFields.DATA_SOURCE_ID]: Yup.number(),
            contact_phone_group: Yup.array().of(
                Yup.object().shape({
                    [contactGeneralFields.PHONE_NUMBER]: Yup.string().matches('^[0-9\\+\\ ]*$', i18n.t('validation.phone')),
                    [contactGeneralFields.CONTACT_PHONE_TYPE_ID]: Yup.number(),
                })
            ),
            contact_email_group: Yup.array().of(
                Yup.object().shape({
                    [contactGeneralFields.EMAIL]: Yup.string(),
                    [contactGeneralFields.CONTACT_EMAIL_TYPE_ID]: Yup.number(),
                })
            ),
            [contactGeneralFields.STREET]: Yup.string(),
            [contactGeneralFields.STREET_NUMBER]: Yup.string(),
            [contactGeneralFields.CITY]: Yup.string(),
            [contactGeneralFields.ZIP_CODE]: Yup.string(),
            [contactGeneralFields.AGENT_ID]: Yup.string().required(i18n.t('validation.message')),
        }),
        prepareModelForSubmit: (values) => {
            return {
                ...values,
                contact_email_group: removeEmpyObjectValues(values.contact_email_group),
                contact_phone_group: removeEmpyObjectValues(values.contact_phone_group),
            }
        },
    }
}

export const contactOtherInfoValidation = (data) => {
    return {
        initialValues: {
            [contactOtherInfoFields.CONTACT_DESCRIPTION_ID]: data?.contactDescription?.id ?? '',
            [contactOtherInfoFields.CONTACT_GENDER_ID]: data?.[contactResponseFields.CONTACT_PERSON]?.contactGender?.id ?? '',
            [contactOtherInfoFields.OIB]: data?.taxNumber ?? '',
            contact_person_company_group: data?.contactPersonCompany?.length
                ? data?.contactPersonCompany?.map((item) => ({
                      [contactOtherInfoFields.COMPANY_ID]: item.company.id.toString() ?? '',
                      [contactOtherInfoFields.CONTACT_JOB_TITLE_ID]: item.contactJobTitle?.id.toString() ?? '',
                      [contactOtherInfoFields.EMPLOYMENT_TYPE]: item.employmentType ?? '',
                  }))
                : [
                      {
                          [contactOtherInfoFields.COMPANY_ID]: '',
                          [contactOtherInfoFields.CONTACT_JOB_TITLE_ID]: '',
                          [contactOtherInfoFields.EMPLOYMENT_TYPE]: '',
                      },
                  ],
            [contactOtherInfoFields.BIRTH_DATE]: data?.contactPerson?.birthDate ?? '',
            [contactOtherInfoFields.CONTACT_GENDER_ID]:
                data?.[contactResponseFields.CONTACT_PERSON]?.[contactResponseFields.CONTACT_GENDER]?.id ?? '',
            [contactOtherInfoFields.CONTACT_MARTIAL_STATUS_ID]: data?.contactPerson?.contactMaritalStatus?.id ?? '',
            [contactOtherInfoFields.NOTES]: data?.notes ?? '',
        },
        validationSchema: Yup.object({
            [contactOtherInfoFields.CONTACT_DESCRIPTION_ID]: Yup.number(),
            [contactOtherInfoFields.CONTACT_GENDER_ID]: Yup.number(),
            [contactOtherInfoFields.BIRTH_DATE]: Yup.string(),
            [contactOtherInfoFields.CONTACT_MARTIAL_STATUS_ID]: Yup.number(),
            [contactOtherInfoFields.NOTES]: Yup.string(),
        }),
        prepareModelForSubmit: (values) => {
            return {
                ...values,
                contact_person_company_group: removeEmpyObjectValues(values.contact_person_company_group),
            }
        },
    }
}

export const contactProfileValidation = (data, propertyTypeValue, propertyServiceValue) => {
    return {
        initialValues: {
            id: data?.id ?? '',
            [contactProfileFields.CONTACT_PROFILE_SERVICE_ID]: data?.[contactResponseFields.CONTACT_PROFILE_SERVICE]?.id ?? '',
            [contactProfileFields.PROPERTY_TYPE_ID]: data?.propertyType?.id ?? '',
            [contactProfileFields.PROPERTY_SUB_TYPE_ID]: data?.propertySubType?.map((item) => item.id?.toString()) ?? [],
            [contactProfileFields.CONTACT_PROFILE_STATUS_ID]: data?.[contactResponseFields.CONTACT_PROFILE_STATUS]?.id?.toString() ?? '',
            [contactProfileFields.CONTACT_FINANCING_ID]: data?.[contactResponseFields.CONTACT_FINANCING]?.map((item) => item.id?.toString()) ?? [],
            [contactProfileFields.CONTACT_PROFILE_DURATION_ID]: data?.[contactResponseFields.CONTACT_PROFILE_DURATION]?.id?.toString() ?? '',
            [contactProfileFields.CONTACT_DEFINITION_ID]: data?.[contactResponseFields.CONTACT_DEFINITION]?.id?.toString() ?? '',
            [contactProfileFields.COUNTRY_ID]: data?.[contactResponseFields.COUNTRY]?.id?.toString() ?? '',
            [contactProfileFields.COUNTY_ID]: data?.[contactResponseFields.COUNTY]?.id?.toString() ?? '',
            [contactProfileFields.WIDER_AREA_ID]: data?.[contactResponseFields.WIDER_AREA]?.map((item) => item.id?.toString()) ?? [],
            [contactProfileFields.CITY_ID]: data?.[contactResponseFields.CITY]?.map((item) => item.id?.toString()) ?? [],
            [contactProfileFields.QUARTER_ID]: data?.[contactResponseFields.QUARTER]?.map((item) => item.id?.toString()) ?? [],
            [contactProfileFields.ISLAND_ID]: data?.[contactResponseFields.ISLAND]?.map((item) => item.id?.toString()) ?? [],
            [contactProfileFields.PRICE_FROM]: data?.[contactResponseFields.PRICE_FROM] ?? '',
            [contactProfileFields.PRICE_TO]: data?.[contactResponseFields.PRICE_TO] ?? '',
            ...(propertyTypeValue?.toString() === REAL_ESTATE_TYPES.house.toString() ||
            propertyTypeValue?.toString() === REAL_ESTATE_TYPES.flat.toString() ||
            propertyTypeValue?.toString() === REAL_ESTATE_TYPES.officeSpace.toString()
                ? {
                      [contactProfileFields.ROOMS_FROM]: data?.[contactResponseFields.ROOMS_FROM] ?? '',
                      [contactProfileFields.ROOMS_TO]: data?.[contactResponseFields.ROOMS_TO] ?? '',
                  }
                : {}),
            ...(propertyTypeValue?.toString() === REAL_ESTATE_TYPES.house.toString() ||
            propertyTypeValue?.toString() === REAL_ESTATE_TYPES.flat.toString()
                ? {
                      [contactProfileFields.BEDROOMS_FROM]: data?.[contactResponseFields.BEDROOMS_FROM] ?? '',
                      [contactProfileFields.BEDROOMS_TO]: data?.[contactResponseFields.BEDROOMS_TO] ?? '',
                  }
                : {}),
            [contactProfileFields.SPECIAL_REQUEST]: data?.[contactResponseFields.SPECIAL_REQUEST] ?? '',
            [contactProfileFields.AREA_FROM]: data?.[contactResponseFields.AREA_FROM] ?? '',
            [contactProfileFields.AREA_TO]: data?.[contactResponseFields.AREA_TO] ?? '',
            ...(propertyTypeValue?.toString() === REAL_ESTATE_TYPES.flat.toString() ||
            propertyTypeValue?.toString() === REAL_ESTATE_TYPES.officeSpace.toString()
                ? {
                      [contactProfileFields.PROPERTY_FLOOR_POSITION_ID]:
                          data?.[contactResponseFields.PROPERTY_FLOOR_POSITION]?.map((item) => item.id?.toString()) ?? [],
                      [contactProfileFields.FLOOR_NO_FROM]: data?.[contactResponseFields.FLOOR_FROM] ?? '',
                      [contactProfileFields.FLOOR_NO_TO]: data?.[contactResponseFields.FLOOR_TO] ?? '',
                  }
                : {}),
            ...(propertyTypeValue?.toString() === REAL_ESTATE_TYPES.flat.toString()
                ? {
                      [contactProfileFields.EXCLUDE_BASEMENT]: data?.[contactResponseFields.EXCLUDE_BASEMENT] ?? false,
                      [contactProfileFields.EXCLUDE_LAST_FLOOR]: data?.[contactResponseFields.EXCLUDE_LAST_FLOOR] ?? false,
                  }
                : {}),
            ...(propertyTypeValue?.toString() === REAL_ESTATE_TYPES.house.toString() ||
            propertyTypeValue?.toString() === REAL_ESTATE_TYPES.flat.toString() ||
            propertyTypeValue?.toString() === REAL_ESTATE_TYPES.officeSpace.toString()
                ? {
                      [contactProfileFields.PROPERTY_SPACE_ID]: data?.[contactResponseFields.PROPERTY_SPACE]?.map((x) => x.id?.toString()) ?? [],
                  }
                : {}),
            ...(propertyTypeValue?.toString() !== REAL_ESTATE_TYPES.garage.toString()
                ? {
                      [contactProfileFields.PROPERTY_DESCRIPTIONS_ID]:
                          data?.[contactResponseFields.PROPERTY_DESCRIPTIONS]?.map((item) => item.id?.toString()) ?? [],
                      [contactProfileFields.PROPERTY_UTILITY_ID]:
                          data?.[contactResponseFields.PROPERTY_UTILITY]?.map((item) => item.id?.toString()) ?? [],
                  }
                : {}),
            [contactProfileFields.PROPERTY_EQUIPMENT_ID]: data?.[contactResponseFields.PROPERTY_EQUIPMENT]?.map((item) => item.id?.toString()) ?? [],
            ...(propertyTypeValue?.toString() !== REAL_ESTATE_TYPES.garage.toString() &&
            propertyTypeValue?.toString() !== REAL_ESTATE_TYPES.land.toString()
                ? {
                      [contactProfileFields.PROPERTY_HEATING_ID]:
                          data?.[contactResponseFields.PROPERTY_HEATING]?.map((item) => item.id?.toString()) ?? [],
                  }
                : {}),
            ...(propertyTypeValue?.toString() === REAL_ESTATE_TYPES.land.toString()
                ? {
                      [contactProfileFields.MAX_CONTSRUCTION_AREA]: data?.maxConstructionArea ?? '',
                  }
                : {}),
            ...(propertyTypeValue?.toString() === REAL_ESTATE_TYPES.house.toString()
                ? {
                      [contactProfileFields.PROPERTY_FLOOR_ID]: data?.propertyFloor?.id ?? '',
                  }
                : {}),
        },
        validationSchema: Yup.object({
            [contactProfileFields.PROPERTY_TYPE_ID]: Yup.string().required(i18n.t('validation.message')),
            [contactProfileFields.CONTACT_PROFILE_SERVICE_ID]: Yup.string().required(i18n.t('validation.message')),
            [contactProfileFields.CONTACT_PROFILE_STATUS_ID]: Yup.number(),
            [contactProfileFields.CONTACT_FINANCING_ID]: Yup.array().of(Yup.string()),
            [contactProfileFields.CONTACT_PROFILE_DURATION_ID]: Yup.number(),
            [contactProfileFields.CONTACT_DEFINITION_ID]: Yup.number(),
            [contactProfileFields.COUNTRY_ID]: Yup.number(),
        }),
        prepareModelForSubmit: (values) => {
            return {
                ...values,
                [contactProfileFields.PRICE_FROM]: values?.[contactProfileFields.PRICE_FROM]?.toString().replace(/\./g, '').replace(/,/g, '.'),
                [contactProfileFields.PRICE_TO]: values?.[contactProfileFields.PRICE_TO]?.toString().replace(/\./g, '').replace(/,/g, '.'),
                [contactProfileFields.PRICE_M2_FROM]: values?.[contactProfileFields.PRICE_M2_FROM]?.toString().replace(/\./g, '').replace(/,/g, '.'),
                [contactProfileFields.PRICE_M2_TO]: values?.[contactProfileFields.PRICE_M2_TO]?.toString().replace(/\./g, '').replace(/,/g, '.'),
            }
        },
    }
}

export const contactNoteValidation = (data) => {
    return {
        initialValues: {
            [contactNoteFields.NOTE_TITLE]: data?.noteTitle ?? '',
            [contactNoteFields.NOTE_DATE]: data?.noteDate ?? '',
            [contactNoteFields.NOTE]: data?.note ?? '',
            [contactNoteFields.CONTACT_NOTE_AGENT_ID]: data?.agent?.id ?? '',
        },
        validationSchema: Yup.object({
            [contactNoteFields.NOTE_TITLE]: Yup.string(),
            [contactNoteFields.NOTE_DATE]: Yup.string(),
            [contactNoteFields.NOTE]: Yup.string(),
            [contactNoteFields.CONTACT_NOTE_AGENT_ID]: Yup.string(),
        }),
    }
}

export const contactDetailSearchValidation = (data) => {
    return {
        initialValues: {
            [contactDetailSearchFields.NAME]: data?.[contactDetailSearchFields.NAME] ?? '',
            [contactDetailSearchFields.CONTACT_PHONE]: data?.[contactDetailSearchFields.CONTACT_PHONE] ?? '',
            [contactDetailSearchFields.CONTACT_EMAIL]: data?.[contactDetailSearchFields.CONTACT_EMAIL] ?? '',
            [contactDetailSearchFields.AGENT]: data?.[contactDetailSearchFields.AGENT] ?? '',
            [contactDetailSearchFields.INITIAL_CONTACT_DATE_FROM]: data?.[contactDetailSearchFields.INITIAL_CONTACT_DATE_FROM] ?? '',
            [contactDetailSearchFields.INITIAL_CONTACT_DATE_TO]: data?.[contactDetailSearchFields.INITIAL_CONTACT_DATE_TO] ?? '',
            [contactDetailSearchFields.LAST_ACTIVITY_DATE_FROM]: data?.[contactDetailSearchFields.LAST_ACTIVITY_DATE_FROM] ?? '',
            [contactDetailSearchFields.LAST_ACTIVITY_DATE_TO]: data?.[contactDetailSearchFields.LAST_ACTIVITY_DATE_TO] ?? '',
            [contactDetailSearchFields.CONTACT_COUNTRY]: data?.[contactDetailSearchFields.CONTACT_COUNTRY] ?? '',
            [contactDetailSearchFields.CITY]: data?.[contactDetailSearchFields.CITY] ?? [],
            [contactDetailSearchFields.CONTACT_PROFILE_SERVICE]: data?.[contactDetailSearchFields.CONTACT_PROFILE_SERVICE] ?? '',
            [contactDetailSearchFields.CONTACT_PROFILE_STATUS]: data?.[contactDetailSearchFields.CONTACT_PROFILE_STATUS] ?? '',
            [contactDetailSearchFields.CONTACT_QUERY_CATEGORY]: data?.[contactDetailSearchFields.CONTACT_QUERY_CATEGORY] ?? '',
            [contactDetailSearchFields.DATA_SOURCE]: data?.[contactDetailSearchFields.DATA_SOURCE] ?? '',
            [contactDetailSearchFields.CONTACT_PRIORITY]: data?.[contactDetailSearchFields.CONTACT_PRIORITY] ?? '',
            [contactDetailSearchFields.CONTACT_STATUS]: IS_ERSTE ? [
                CONTACT_STATUS.IN_ENTRY.toString(),
                CONTACT_STATUS.HOT_LEAD.toString(),
                CONTACT_STATUS.ON_HOLD.toString(),
                CONTACT_STATUS.NO_OFFER.toString(),
                CONTACT_STATUS.NO_OFFER_OUT_OF_ZG.toString(),
                CONTACT_STATUS.ACCEPTANCE_IN_OFFER.toString(),
            ] : data?.[contactDetailSearchFields.CONTACT_STATUS]?.map((item) => item.id?.toString()),
        },
        prepareModelForSubmit: (values) => {
            const filledValues = Object.fromEntries(Object.entries(values).filter(([key, value]) => value !== null && value !== ''))
            return {
                ...filledValues,
            }
        },
        formGroup: 'contact',
    }
}

export const contactProfileDetailSearchValidation = (data) => {
    return {
        initialValues: {
            [responseFields.PROPERTY_TYPE]: data?.[responseFields.PROPERTY_TYPE] ?? [],
            [responseFields.PROPERTY_SUB_TYPE]: data?.[responseFields.PROPERTY_SUB_TYPE] ?? [],
            [responseFields.PRICE_FROM]: data?.[responseFields.PRICE_FROM] ?? '',
            [responseFields.PRICE_TO]: data?.[responseFields.PRICE_TO] ?? '',
            [responseFields.ROOMS_FROM]: data?.[responseFields.ROOMS_FROM] ?? '',
            [responseFields.ROOMS_TO]: data?.[responseFields.ROOMS_TO] ?? '',
            [responseFields.BEDROOMS_FROM]: data?.[responseFields.BEDROOMS_FROM] ?? '',
            [responseFields.BEDROOMS_TO]: data?.[responseFields.BEDROOMS_TO] ?? '',
            [responseFields.AREA_FROM]: data?.[responseFields.AREA_FROM] ?? '',
            [responseFields.AREA_TO]: data?.[responseFields.AREA_TO] ?? '',
            [responseFields.FLOOR_FROM]: data?.[responseFields.FLOOR_FROM] ?? '',
            [responseFields.FLOOR_TO]: data?.[responseFields.FLOOR_TO] ?? '',
            [responseFields.CONTACT_PROFILE_DURATION]: data?.[responseFields.CONTACT_PROFILE_DURATION] ?? '',
            [responseFields.COUNTRY]: data?.[responseFields.COUNTRY] ?? '',
            [responseFields.COUNTY]: data?.[responseFields.COUNTY] ?? [],
            [responseFields.CITY]: data?.[responseFields.CITY] ?? [],
            [responseFields.WIDER_AREA]: data?.[responseFields.WIDER_AREA] ?? [],
            [responseFields.QUARTER]: data?.[responseFields.QUARTER] ?? [],
            [responseFields.PROPERTY_FLOOR]: data?.[responseFields.PROPERTY_FLOOR] ?? [],
        },
        prepareModelForSubmit: (values) => {
            const filledValues = Object.fromEntries(Object.entries(values).filter(([key, value]) => value !== null && value !== ''))
            return {
                ...filledValues,
                ...(filledValues[responseFields.PRICE_FROM]
                    ? {
                          [responseFields.PRICE_FROM]: filledValues[responseFields.PRICE_FROM]?.toString().replace(/\./g, '').replace(/,/g, '.'),
                      }
                    : {}),
                ...(filledValues[responseFields.PRICE_TO]
                    ? {
                          [responseFields.PRICE_TO]: filledValues[responseFields.PRICE_TO]?.toString().replace(/\./g, '').replace(/,/g, '.'),
                      }
                    : {}),
            }
        },
        formGroup: 'contactProfile',
    }
}

export const contactDocumentValidation = (data) => {
    return {
        initialValues: {
            [contactDocumentFields.DOCUMENT_PROPRETY_ID]: '',
            [contactDocumentFields.DOCUMENT_PROJECT_ID]: '',
            [contactDocumentFields.DOCUMENT_CONTACT_ID]: data?.documentContactId ?? '',
            [contactDocumentFields.DOCUMENT_CREATION_DATE]: '',
            [contactDocumentFields.DOCUMENT_VALIDITY_DATE]: '',
        },
        validationSchema: Yup.object({}),
    }
}
