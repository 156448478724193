import { checkPermissionLevel } from './utils'

// handles agents select options in Activities
export const setAllowedAgents = ({ agentPermissions, agentOptions, currentAgentId, currentOffice }) => {
    const permission = 'module.activity.view'
    const level = checkPermissionLevel(agentPermissions, permission)
    const permissions = {
        all: agentOptions,
        office: agentOptions.filter((option) => option.agencyOfficeId === currentOffice.id),
        own: agentOptions.filter((option) => option.agentId === currentAgentId),
    }
    const allowedAgentOptions = level in permissions ? permissions[level] : false

    return { allowedAgentOptions }
}
