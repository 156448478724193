export const REAL_ESTATE_TYPES = {
    house: 1,
    flat: 2,
    officeSpace: 3,
    land: 4,
    garage: 5,
}

export const REAL_ESTATE_SERVICE = {
    sale: 1,
    lease: 2,
}

export const ACTIVITY_CATEGORY = {
    EVENT: 1,
    TASK: 2,
    REMINDER: 3,
}

export const SUBMIT_RESPONSE_STATUS = {
    SUCCESS: 'success',
    FAILURE: 'failure',
}

export const DETAIL_SEARCH_TYPE = {
    PROPERTY: 'property',
    CONTACT: 'contact',
    COMPANY: 'company',
    PROJECT: 'project',
    DIARY: 'diary',
}

export const FORM_DIRECTION = {
    ROW: 'row',
    COLUMN: 'column',
}

export const CONTACT_TYPE = {
    PERSON: 'person',
    COMPANY: 'company',
}

export const USER_SETTINGS = {
    HIDDEN_COLUMNS: 'hiddenColumns',
    COLUMN_ORDER: 'columnOrder',
    COLUMN_SORT: 'columnSort',
    PAGE_SIZE: 'pageSize',
}

export const MODAL_TYPES = {
    OFFER_PRINT_MODAL: 'offerPrintModal',
    OFFER_SEND_MODAL: 'offerSendModal',
    TEMPLATES_MODAL: 'templatesModal',
    SHOPWINDOW_PRINT_MODAL: 'shopWindowPrintModal',
    CHANGE_AGENT_MODAL: 'changeAgentModal',
    RETURN_TO_OFFER_MODAL: 'returnToOffer',
    EXPORT_SYNC_MODAL: 'exportSyncModal',
}

export const USER_ROLES = {
    ROLE_ADMIN: 'ROLE_ADMIN',
    ROLE_USER: 'ROLE_USER',
}

export const PROPERTY_STATUS = {
    ACTIVE_OFFER: 1,
    RESERVED: 2,
    SOLD: 3,
    RENTED: 4,
    SOLD_ARCHIVED: 8,
    RENTED_ARCHIVED: 9,
    FOR_AUTHORIZATION: 10,
}

export const PROJECT_STATUS = {
    PREPARATION: 1,
    FOR_AUTHORIZATION: 2,
    ACTIVE: 3,
    ARCHIVED: 4,
}

export const WEBSITE_ATTRIBUTES = [
    {
        attribute: 'visibility',
        name: 'app.common.project.webSync',
    },
    {
        attribute: 'incognito',
        name: 'form.property.export.incognito',
    },
    {
        attribute: 'attribute1',
        name: 'form.property.export.separatedRealEstate',
    },
    {
        attribute: 'attribute2',
        name: 'form.property.export.specialOffer',
    },
    {
        attribute: 'attribute3',
        name: 'form.property.export.bestBuy',
    },
    {
        attribute: 'attribute4',
        name: 'form.property.export.seaProperties',
    },
]

export const CONTACT_STATUS = {
    IN_ENTRY: 1,
    HOT_LEAD: 3,
    ON_HOLD: 7,
    NO_OFFER: 13,
    NO_OFFER_OUT_OF_ZG: 14,
    ACCEPTANCE_IN_OFFER: 12,
}
export const INPUT_TPYES = {
    TEXT: 'text',
    NUMBER: 'number',
    DATE: 'date',
    DATETIME: 'datetime',
    TIME: 'time',
    EMAIL: 'email',
    PASSWORD: 'password',
    URL: 'url',
    CROP_DIRECTION: 'crop-direction',
    CHECKBOX: 'checkbox',
    RADIO: 'radio',
    SELECT: 'select',
    TEXTAREA: 'textarea',
}

export const IMAGE_EDITING_TOOLS = {
    CROP: 'crop',
    PATH: 'path',
    RESIZE: 'resize',
    ROTATE: 'rotate',
    MARKER: 'marker',
}

export const CROP_DIRECTION = {
    TOP_LEFT: 'top-left',
    TOP_CENTER: 'top-center',
    TOP_RIGHT: 'top-right',
    CENTER_LEFT: 'center-left',
    CENTER: 'center',
    CENTER_RIGHT: 'center-right',
    BOTTOM_LEFT: 'bottom-left',
    BOTTOM_CENTER: 'bottom-center',
    BOTTOM_RIGHT: 'bottom-right',
}

export const BRUSH_SIZE = {
    SMALL: 2,
    MEDIUM: 5,
    LARGE: 10,
}

export const DETAIL_VIEW = {
    PROPERTY: 'property',
    CONTACT: 'contact',
    COMPANY: 'company',
    PROJECT: 'project',
}

export const PASSWORD_REGEX =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,\-./:;<=>?@[\]^_`{|}~])[A-Za-z\d!"#$%&'()*+,\-./:;<=>?@[\]^_`{|}~]{8,}$/

    
export const API_URL = process.env.REACT_APP_API_URL

export const IS_ERSTE = process.env.REACT_APP_ERSTE_FEATURE === 'true'
