import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const useCompanyFormTabs = (isEdit) => {
    const [tabs, setTabs] = useState([])
    const { t } = useTranslation()

    useEffect(() => {
        setTabs([
            { id: 1, name: 'general', label: t('app.common.generalInfo') },
            { id: 2, name: 'otherInfo', label: t('form.contact.tab.otherInfo'), disabled: !isEdit },
            { id: 3, name: 'contactProfile', label: t('app.common.contact.demandProfile'), disabled: !isEdit },
            { id: 6, name: 'documents', label: t('app.common.documents'), disabled: !isEdit },
        ])
    }, [isEdit, t])
    return tabs
}

export default useCompanyFormTabs
