import i18n from "../i18n";
import { flattenDeep } from "lodash"

export function addRequiredValidation(validationSchema, fieldTypes, message = i18n.t("validation.message")) {
    if (fieldTypes.sideForm || fieldTypes.mainForm) {
        const { sideForm, mainForm } = fieldTypes;
        fieldTypes = flattenDeep([sideForm, mainForm]);
    }

    for (const field of fieldTypes) {
        if (field.required && validationSchema[field.name]) {
            validationSchema[field.name] = validationSchema[field.name].required(message);
        }

        if (field.children) {
            addRequiredValidation(validationSchema, field.children, message);
        }
    }
}