import { FormikProvider, getIn, useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { Container, Form, TabContent, TabPane } from 'reactstrap'
import * as Yup from 'yup'
import { propertyTransactionsFields, realEstateGeneralFields } from '../../common/forms/generalRealEstate/fields'
import { propertyResponseFields } from '../../common/response/property'
import FormActionButtons from '../../Components/Common/FormActionButtons'
import Loader from '../../Components/Common/Loader'
import PageTitle from '../../Components/Common/PageTitle'
import DocumentFormTab from '../../Components/DocumentFormTab'
import GroundPlansFormTab from '../../Components/GroundPlanFormTab'
import {
    useMapFormConfig,
    usePropertyExportFormConfig,
    usePropertyTransactionFormSelectOptions,
    usePropertyTransactionsFormConfig,
    useRealEstateDescriptionFormConfig,
    useRealEstateGeneralFormConfig,
    useRealEstateGeneralFormSelectOptions,
    useRealEstateMaterials3DVideoFormConfig,
} from '../../Components/Hooks/FormHooks'
import usePropertyGeneralFormTabs from '../../Components/Hooks/useRealEstateTabs'
import MapFormTab, { MapFormTabMain, MapFormTabSide } from '../../Components/MapFormTab/MapFormTab'
import FormValidationErrorModal from '../../Components/Modals/FormValidationErrorModal'
import PhotosFormTab from '../../Components/PhotosFormTab'
import PropertyFormDescriptionTab, {
    PropertyFormDescriptionTabMain,
    PropertyFormDescriptionTabSide,
} from '../../Components/PropertyFormDescriptionTab/PropertyFormDescriptionTab'
import PropertyFormExportTab, {
    PropertyFormExportTabMain,
    PropertyFormExportTabSide,
} from '../../Components/PropertyFormExportTab/PropertyFormExportTab'
import PropertyFormGeneralTab, {
    PropertyFormGeneralTabMain,
    PropertyFormGeneralTabSide,
} from '../../Components/PropertyFormGeneralTab/PropertyFormGeneralTab'
import PropertyFormMaterialsTab from '../../Components/PropertyFormMaterialsTab/PropertyFormMaterialsTab'
import PropertyFormMaterialsVirtualWalkVideoTab, {
    PropertyFormMaterialsVirtualWalkVideoTabMain,
    PropertyFormMaterialsVirtualWalkVideoTabSide,
} from '../../Components/PropertyFormMaterialsTab/Tabs/PropertyFormMaterialsVirtualWalkVideoTab/PropertyFormMaterialsVirtualWalkVideoTab'
import PropertyFormTransactionsTab, {
    PropertyFormTransactionsTabMain,
    PropertyFormTransactionsTabSide,
} from '../../Components/PropertyFormTransactionsTab/PropertyFormTransactionsTab'
import { selectGroundPlans, updateGroundPlan } from '../../store/groundplans'
import { selectPhotos, updatePhoto } from '../../store/photos'
import { fetchProperty, selectProperty, selectPropertyFetchingDetails, updateProperty } from '../../store/properties'
import { changeTranslationObject, findEntityTitle } from '../../utils/general'

const PropertyEdit = () => {
    const history = useHistory()
    const location = useLocation()
    const tabs = usePropertyGeneralFormTabs(true)
    const dispatch = useDispatch()

    const [activeTab, setActiveTab] = useState(null)
    const [activeMaterialsTab, setActiveMaterialsTab] = useState(null)
    const [realEstateType, setRealEstateType] = useState(null)
    const [realEstateService, setRealEstateService] = useState(null)
    const [propertyStatus, setPropertyStatus] = useState(null)
    const [newProperty, setNewPoperty] = useState(null)

    let { id } = useParams()

    let { property, fetchingDetails, photos, groundPlans } = useSelector((state) => ({
        property: selectProperty(state),
        fetchingDetails: selectPropertyFetchingDetails(state),
        photos: selectPhotos(state),
        groundPlans: selectGroundPlans(state),
    }))

    const { formInputs, formikConfig } = useRealEstateGeneralFormConfig(
        id,
        newProperty?.propertyType?.id,
        newProperty?.propertyService?.id,
        newProperty
    )
    const { transactionsInputs, transactionsValidationConfig } = usePropertyTransactionsFormConfig(property, propertyStatus)
    const { descriptionInputs, descriptionValidationConfig } = useRealEstateDescriptionFormConfig(property)
    const { mapInputs, mapValidationConfig } = useMapFormConfig(property)
    const { exportInputs, exportValidationConfig } = usePropertyExportFormConfig(property)
    const { materials3DVideoInputs, materials3DVideoValidationConfig } = useRealEstateMaterials3DVideoFormConfig(property)
    const formSelectOptions = useRealEstateGeneralFormSelectOptions()
    const transactionsSelectOptions = usePropertyTransactionFormSelectOptions()

    const moduleName = 'property'
    const transactionsTabSideData = { propertyStatus: property?.propertyStatus, propertyService: property?.propertyService }

    useEffect(() => {
        dispatch(fetchProperty({ id }))
    }, [dispatch, id])

    useEffect(() => {
        if (property?.id === +id) {
            setNewPoperty(property)
        }
    }, [id, property])

    useEffect(() => {
        if (tabs.length > 0) {
            if (location?.state?.activeTab) {
                setActiveTab(location?.state?.activeTab)
            } else {
                setActiveTab(tabs[0].name)
            }
        }
    }, [tabs, location])

    useEffect(() => {
        if (activeTab === 'materials') {
            setActiveMaterialsTab(tabs.find((x) => x.name === 'materials')?.children?.[0].name)
        }
    }, [activeTab, tabs])

    const handleTabChange = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab)
        }
    }

    const handleMaterialsTabChange = (tab) => {
        if (activeMaterialsTab !== tab) {
            setActiveMaterialsTab(tab)
        }
    }

    const updateMaterials = (materials, updateAction, name) => {
        for (const item of materials) {
            const payload = {
                id: item.id,
                priority: item.priority,
                translations: validation.values?.[name]?.[item.id].translations,
                entityId: property.id,
                moduleName
            }
            dispatch(updateAction(payload))
        }
    }

    const { isValid, isSubmitting, isValidating, ...validation } = useFormik({
        enableReinitialize: true,
        initialValues: {
            general: formikConfig.initialValues ?? {},
            description: descriptionValidationConfig.initialValues ?? {},
            map: mapValidationConfig.initialValues ?? {},
            transactions: transactionsValidationConfig.initialValues ?? {},
            virtualWalkVideo: materials3DVideoValidationConfig.initialValues ?? {},
            export: exportValidationConfig.initialValues ?? {},
            photos: {},
            groundplans: {},
        },
        validationSchema: Yup.object({
            general: formikConfig.validationSchema ?? Yup.object({}),
            description: descriptionValidationConfig.validationSchema ?? Yup.object({}),
            map: mapValidationConfig.validationSchema ?? Yup.object({}),
            transactions: transactionsValidationConfig.validationSchema ?? Yup.object({}),
            virtualWalkVideo: materials3DVideoValidationConfig.validationSchema ?? Yup.object({}),
            export: exportValidationConfig.validationSchema ?? Yup.object({}),
        }),
        onSubmit: (values) => {
            const generalValues = formikConfig.prepareModelForSubmit(values.general)
            const transactionValues = transactionsValidationConfig.prepareModelForSubmit(values.transactions)

            const translations = changeTranslationObject(values.description, 'property_title', 'property_description')

            delete transactionValues[propertyTransactionsFields.PROPERTY_STATUS_ID]

            const submitValues = {
                ...generalValues,
                ...values.map,
                ...values.virtualWalkVideo,
                ...values.export,
                propertyPortalCustomValue: JSON.stringify(values.export.propertyPortalCustomValue),
                translations,
                property_status_id: values.transactions[propertyTransactionsFields.PROPERTY_STATUS_ID],
                [realEstateGeneralFields.AGENT_ID]: [values.general.agent_id, values.general.agent_id_secondary],
                [realEstateGeneralFields.PRICE]: values?.general[realEstateGeneralFields.PRICE]?.toString().replace(/\./g, '').replace(/,/g, '.'),
                [realEstateGeneralFields.PRICE_INITIAL]: values?.general[realEstateGeneralFields.PRICE_INITIAL]
                    ?.toString()
                    .replace(/\./g, '')
                    .replace(/,/g, '.'),
                [realEstateGeneralFields.PRICE_CLIENT]: values?.general[realEstateGeneralFields.PRICE_CLIENT]
                    ?.toString()
                    .replace(/\./g, '')
                    .replace(/,/g, '.'),
                [realEstateGeneralFields.PRICE_M2]: values?.general[realEstateGeneralFields.PRICE_M2]
                    ?.toString()
                    .replace(/\./g, '')
                    .replace(/,/g, '.'),
                [realEstateGeneralFields.PRICE_OLD]: values?.general[realEstateGeneralFields.PRICE_OLD]
                    ?.toString()
                    .replace(/\./g, '')
                    .replace(/,/g, '.'),
                property_transaction_entity: transactionValues,
            }
            delete submitValues.agent_id_secondary
            submitValues.agent_id = submitValues.agent_id.filter(Boolean)

            dispatch(updateProperty(submitValues))

            updateMaterials(photos, updatePhoto, 'photos')
            updateMaterials(groundPlans, updateGroundPlan, 'groundplans')
        },
    })

    const realEstateTypeValue = getIn(validation.values?.general, realEstateGeneralFields.PROPERTY_TYPE_ID)
    const realEstateServiceValue = getIn(validation.values?.general, realEstateGeneralFields.PROPERTY_SERVICE_ID)
    const propertyStatusValue = getIn(validation.values?.transactions, propertyTransactionsFields.PROPERTY_STATUS_ID)

    useEffect(() => {
        if (realEstateTypeValue !== realEstateType) {
            setRealEstateType(realEstateTypeValue)
        }
    }, [realEstateTypeValue, realEstateType])

    useEffect(() => {
        if (realEstateServiceValue !== realEstateService) {
            setRealEstateService(realEstateServiceValue)
        }
    }, [realEstateServiceValue, realEstateService])

    useEffect(() => {
        if (propertyStatusValue !== propertyStatus) {
            setPropertyStatus(propertyStatusValue)
        }
    }, [propertyStatusValue, propertyStatus])

    useEffect(() => {
        if (!isValid && !isValidating && isSubmitting) {
            setIsErrorModalActive(true)
        }
    }, [isValid, isValidating, isSubmitting])

    const renderFormButtons = () => <FormActionButtons disabled={false} hasCancel={true} cancelAction={() => history.push(`/properties/${id}`)} />

    const [isErrorModalActive, setIsErrorModalActive] = useState(false)

    const toggleErrorModal = () => {
        setIsErrorModalActive(!isErrorModalActive)
    }

    if (fetchingDetails) {
        return (
            <div className="page-content">
                <Container fluid>
                    <Loader />
                </Container>
            </div>
        )
    }

    return (
        <div className="page-content">
            <Container fluid>
                <FormikProvider value={validation}>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault()
                            if (validation.values.transactions[propertyTransactionsFields.PROPERTY_STATUS_ID].toString() !== '2') {
                                validation.setFieldValue(`transactions.${[propertyTransactionsFields.RESERVATION_DATE]}`, '')
                            }
                            validation.handleSubmit()
                            return false
                        }}
                        className="needs-validation"
                        id="real_estate_form"
                    >
                        <PageTitle title={`Uredi > ${findEntityTitle(property, propertyResponseFields.PROPERTY_TITLE)}`}>
                            {renderFormButtons()}
                        </PageTitle>

                        <TabContent activeTab={activeTab} className="text-muted">
                            <TabPane tabId="general">
                                <PropertyFormGeneralTab
                                    tabs={tabs}
                                    activeTab={activeTab}
                                    handleTabChange={handleTabChange}
                                    inputs={formInputs}
                                    subform="general"
                                    formik={validation}
                                    selectOptions={formSelectOptions}
                                    realEstateType={realEstateType}
                                    realEstateService={realEstateService}
                                    module={location}
                                >
                                    <PropertyFormGeneralTabSide />
                                    <PropertyFormGeneralTabMain />
                                </PropertyFormGeneralTab>
                            </TabPane>
                            <TabPane tabId="description">
                                <PropertyFormDescriptionTab
                                    tabs={tabs}
                                    activeTab={activeTab}
                                    handleTabChange={handleTabChange}
                                    inputs={descriptionInputs}
                                    subform="description"
                                    formik={validation}
                                    realEstateType={realEstateType}
                                    realEstateService={realEstateService}
                                >
                                    <PropertyFormDescriptionTabSide />
                                    <PropertyFormDescriptionTabMain />
                                </PropertyFormDescriptionTab>
                            </TabPane>
                            <TabPane tabId="map">
                                <MapFormTab
                                    tabs={tabs}
                                    activeTab={activeTab}
                                    handleTabChange={handleTabChange}
                                    inputs={mapInputs}
                                    formik={validation}
                                >
                                    <MapFormTabSide />
                                    <MapFormTabMain />
                                </MapFormTab>
                            </TabPane>
                            <TabPane tabId="transactions">
                                <PropertyFormTransactionsTab
                                    tabs={tabs}
                                    activeTab={activeTab}
                                    handleTabChange={handleTabChange}
                                    inputs={transactionsInputs}
                                    subform="transactions"
                                    formik={validation}
                                    selectOptions={transactionsSelectOptions}
                                    module={location}
                                    tabSideData={transactionsTabSideData}
                                >
                                    <PropertyFormTransactionsTabSide />
                                    <PropertyFormTransactionsTabMain />
                                </PropertyFormTransactionsTab>
                            </TabPane>
                            <TabPane tabId="export">
                                <PropertyFormExportTab
                                    tabs={tabs}
                                    activeTab={activeTab}
                                    handleTabChange={handleTabChange}
                                    subform="export"
                                    formik={validation}
                                >
                                    <PropertyFormExportTabSide />
                                    <PropertyFormExportTabMain />
                                </PropertyFormExportTab>
                            </TabPane>
                            <TabPane tabId="materials">
                                <PropertyFormMaterialsTab
                                    tabs={tabs}
                                    activeTab={activeTab}
                                    handleTabChange={handleTabChange}
                                    activeMaterialsTab={activeMaterialsTab}
                                    materialsTabs={tabs.length > 0 ? tabs.find((x) => x.name === 'materials')?.children : []}
                                    handleMaterialsTabChange={handleMaterialsTabChange}
                                    propertyId={id}
                                >
                                    <TabContent activeTab={activeMaterialsTab} className="text-muted">
                                        <TabPane tabId="photos">
                                            <PhotosFormTab
                                                tabs={tabs}
                                                activeTab={activeTab}
                                                handleTabChange={handleTabChange}
                                                activeChildTab={activeMaterialsTab}
                                                childTabs={tabs.length > 0 ? tabs.find((x) => x.name === 'materials')?.children : []}
                                                handleChildTabChange={handleMaterialsTabChange}
                                                moduleName={moduleName}
                                                parentId={id}
                                                validation={validation}
                                            />
                                        </TabPane>
                                        <TabPane tabId="groundPlans">
                                            <GroundPlansFormTab
                                                tabs={tabs}
                                                activeTab={activeTab}
                                                handleTabChange={handleTabChange}
                                                activeChildTab={activeMaterialsTab}
                                                childTabs={tabs.length > 0 ? tabs.find((x) => x.name === 'materials')?.children : []}
                                                handleChildTabChange={handleMaterialsTabChange}
                                                moduleName={moduleName}
                                                parentId={id}
                                                validation={validation}
                                            />
                                        </TabPane>
                                        <TabPane tabId="3dVideo">
                                            <PropertyFormMaterialsVirtualWalkVideoTab
                                                inputs={materials3DVideoInputs}
                                                subform="virtualWalkVideo"
                                                formik={validation}
                                            >
                                                <PropertyFormMaterialsVirtualWalkVideoTabSide />
                                                <PropertyFormMaterialsVirtualWalkVideoTabMain />
                                            </PropertyFormMaterialsVirtualWalkVideoTab>
                                        </TabPane>
                                    </TabContent>
                                </PropertyFormMaterialsTab>
                            </TabPane>
                        </TabContent>
                    </Form>

                    {/* documents are saved via dropzone */}
                    {activeTab === 'documents' && (
                        <TabPane tabId="documents">
                            <DocumentFormTab
                                tabs={tabs}
                                activeTab={activeTab}
                                handleTabChange={handleTabChange}
                                documentModule="property"
                                parentId={id}
                            />
                        </TabPane>
                    )}
                </FormikProvider>
            </Container>
            {isErrorModalActive && (
                <FormValidationErrorModal
                    validation={validation}
                    inputs={[formInputs, transactionsInputs, descriptionInputs, mapInputs, exportInputs, materials3DVideoInputs]}
                    onClose={() => toggleErrorModal(false)}
                />
            )}
        </div>
    )
}

export default PropertyEdit
