const responseFields = {
    PROPERTY_TYPE: 'propertyType',
    PROPERTY_SUB_TYPE: 'propertySubType',
    PRICE_FROM: 'priceFrom',
    PRICE_TO: 'priceTo',
    PRICE_M2_FROM: 'priceM2From',
    PRICE_M2_TO: 'priceM2To',
    ROOMS_FROM: 'roomsFrom',
    ROOMS_TO: 'roomsTo',
    BEDROOMS_FROM: 'bedroomsFrom',
    BEDROOMS_TO: 'bedroomsTo',
    AREA_FROM: 'areaFrom',
    AREA_TO: 'areaTo',
    FLOOR_FROM: 'floorFrom',
    FLOOR_TO: 'floorTo',
    CONTACT_PROFILE_DURATION: 'contactProfileDuration',
    CONTACT_PHONE: 'contactPhone',
    CONTACT_EMAIL: 'contactEmail',
    AGENT: 'agent',
    INITIAL_CONTACT_DATE_FROM: 'initialContactDateFrom',
    INITIAL_CONTACT_DATE_TO: 'initialContactDateTo',
    LAST_ACTIVITY_DATE_FROM: 'lastActivityDateFrom',
    LAST_ACTIVITY_DATE_TO: 'lastActivityDateTo',
    CONTACT_COUNTRY: 'contactCountry',
    CONTACT_PROFILE_SERVICE: 'contactProfileService',
    CONTACT_PROFILE_STATUS: 'contactProfileStatus',
    CONTACT_QUERY_CATEGORY: 'contactQueryCategory',
    CONTACT_PRIORITY: 'contactPriority',
    DATA_SOURCE: 'dataSource',
    CITY: 'city',
    COUNTY: 'county',
    COUNTRY: 'country',
    CONTACT_STATUS: 'contactStatus',
    WIDER_AREA: 'widerArea',
    QUARTER: 'quarter',
    ISLAND: 'island',
    NAME: 'name',
    PROPERTY_FLOOR: 'propertyFloor',
    PROPERTY_SUPPLY_SOURCE: 'propertySupplySource',
}

export default responseFields; 