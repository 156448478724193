import { createAction } from 'redux-actions'

const ENTITY = 'DIARIES'
export const stateKey = ENTITY.toLowerCase()

export const fetchDiaries = createAction(
    `${ENTITY}/FETCH`,
    (payload) => payload,
    (_, callback) => callback
)

export const fetchDiariesRequest = createAction(`${ENTITY}/FETCH_REQUEST`)
export const fetchDiariesSuccess = createAction(`${ENTITY}/FETCH_SUCCESS`)
export const fetchDiariesFailure = createAction(`${ENTITY}/FETCH_FAILURE`)

export const fetchDiary = createAction(
    `${ENTITY}/DETAILS/FETCH`,
    (payload) => payload,
    (_, callback) => callback
)
export const fetchDiaryRequest = createAction(`${ENTITY}/DETAILS/FETCH_REQUEST`)
export const fetchDiarySuccess = createAction(`${ENTITY}/DETAILS/FETCH_SUCCESS`)
export const fetchDiaryFailure = createAction(`${ENTITY}/DETAILS/FETCH_FAILURE`)

export const updateDiary = createAction(
    `${ENTITY}/UPDATE`,
    (values) => values,
    (_, callback) => ({ callback })
)
export const updateDiaryRequest = createAction(`${ENTITY}/UPDATE_REQUEST`)
export const updateDiarySuccess = createAction(`${ENTITY}/UPDATE_SUCCESS`)
export const updateDiaryFailure = createAction(`${ENTITY}/UPDATE_FAILURE`)

export const deleteDiary = createAction(`${ENTITY}/DELETE`)
export const deleteDiaryRequest = createAction(`${ENTITY}/DELTE_REQUEST`)
export const deleteDiarySuccess = createAction(`${ENTITY}/DELTE_SUCCESS`)
export const deleteDiaryFailure = createAction(`${ENTITY}/DELETE_FAILURE`)

export const fetchDiariesNoStore = createAction(
    `${ENTITY}/NO_STORE/FETCH`,
    (payload) => payload,
    (payload, callback) => ({ callback })
)
export const fetchDiariesNoStoreRequest = createAction(`${ENTITY}/NO_STORE/FETCH_REQUEST`)
export const fetchDiariesNoStoreSuccess = createAction(`${ENTITY}/NO_STORE/FETCH_SUCCESS`)
export const fetchDiariesNoStoreFailure = createAction(`${ENTITY}/NO_STORE/FETCH_FAILURE`)
export const fetchDiariesNoStoreClear = createAction(`${ENTITY}/NO_STORE/CLEAR`)
