import { FormikProvider, getIn } from 'formik'
import { debounce, slice } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import Select from 'react-select'
import { Alert, Button, Col, Form, FormFeedback, Label, Row } from 'reactstrap'
import { SUBMIT_RESPONSE_STATUS } from '../../../common/constants'
import { contactGeneralFields } from '../../../common/forms/contacts/fields'
import { propertyOfferPrintFields } from '../../../common/forms/generalRealEstate/fields'
import { projectResponseFields } from '../../../common/response/project'
import { propertyResponseFields } from '../../../common/response/property'
import { selectAgentsFormOption } from '../../../store/agents/selectors'
import { createContact, fetchContactsAsyncInput, selectContact, updateContact } from '../../../store/contacts'
import { selectApplicationAreaUnit, selectApplicationCurrency } from '../../../store/settings'
import { selectAvailableLanguages } from '../../../store/applicationSettings'
import { uniqueObjectsInArray } from '../../../utils/general'
import { renderAreaUnit, renderCurrency } from '../../../utils/property'

const OfferTab = ({
    isProject = false,
    isOfferView,
    validation,
    offerTemplate,
    selectedTemplate,
    setSelectedTemplate,
    properties,
    toggleSettingsModal,
    selectedContactsForOffer,
    setSelectedContactsForOffer,
    togglePreviewModal,
    toggleSendModal,
    selectedTableRows = [],
    addSelectedRows,
    setAddSelectedRows,
}) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const { pathname } = useLocation()

    const currentLang = localStorage.getItem('i18nextLng')

    const { agents, areaUnit, currency, contact, availableLanguages } = useSelector((state) => {
        return {
            agents: selectAgentsFormOption(state),
            currency: selectApplicationCurrency(state),
            areaUnit: selectApplicationAreaUnit(state),
            contact: selectContact(state),
            availableLanguages: selectAvailableLanguages(state),
        }
    })

    const localeOptions = availableLanguages?.map((lang) => {
        return { value: lang.abbreviation, label: lang.abbreviation.toUpperCase() }
    })

    const [additionalOptions, setAdditionalOptions] = useState(offerTemplate?.items[0]?.additionalOptions)
    const [isContactsFetching, setIsContactsFetching] = useState(false)
    const [contacts, setContacts] = useState([])
    const [contactInputValue, setContactInputValue] = useState(null)
    const [contactEmailInputValue, setContactEmailInputValue] = useState(null)
    const [contactError, setContactError] = useState(null)
    const [selectedContact, setSelectedContact] = useState(null)
    const [selectedContactEmail, setSelectedContactEmail] = useState(null)
    const [contactAdded, setContactAdded] = useState(false)
    const [companies, setComapnies] = useState([])
    const [selectedCompany, setSelectedCompany] = useState(null)

    const offerEmailSettingsGroup = getIn(validation.values.offer, 'offer_email_settings_group')
    const localeValue = getIn(validation?.values.offer, propertyOfferPrintFields.OFFER_LOCALE)
    const agentValue = getIn(validation?.values.offer, propertyOfferPrintFields.AGENT_ID)

    const getContactOptions = (inputValue) => {
        // console.log('input value', inputValue)

        let params = {
            noLimit: 1,
            criteria: {
                contactType: 'person',
                name: inputValue,
            },
        }

        dispatch(
            fetchContactsAsyncInput(
                {
                    params,
                },
                (asyncValues) => {
                    setIsContactsFetching(false)
                    if (asyncValues?.length) {
                        let selectOptions = []
                        asyncValues.forEach((item) => {
                            if (item.data.contactEmail?.length > 1) {
                                item.data.contactEmail.forEach((emailItem) => {
                                    selectOptions.push({
                                        value: item.value + '|' + emailItem.id,
                                        id: item.value,
                                        label: `${item.label}, ${emailItem.email}`,
                                        name: item.label,
                                        email: emailItem.email,
                                        contactPersonCompany: item.data.contactPersonCompany,
                                    })
                                })
                            } else {
                                selectOptions.push({
                                    value: item.value,
                                    id: item.value,
                                    label: `${item.label} ${item.data.contactEmail[0]?.email ? `, ${item.data.contactEmail[0]?.email}` : ''}`,
                                    name: item.label,
                                    email: item.data.contactEmail[0]?.email,
                                    contactPersonCompany: item.data.contactPersonCompany,
                                })
                            }
                        })

                        // console.log(selectOptions);
                        setContacts(selectOptions)
                    }
                }
            )
        )
    }

    const debounceHandleChange = useCallback(
        debounce((val) => {
            setIsContactsFetching(true)
            getContactOptions(val)
        }, 500),
        [fetchContactsAsyncInput]
    )

    const onContactInputChange = (value) => {
        setContactInputValue(value)
        if (value.length) {
            debounceHandleChange(value)
        }
    }

    const handleContactSelectChange = (contact) => {
        setSelectedContact(contact)
        setSelectedCompany([])
        setComapnies([])

        var contactCompanies = contact.contactPersonCompany

        if (contactCompanies?.length) {
            let selectOptions = contactCompanies.map((item) => {
                return {
                    value: item.company.id,
                    id: item.company.id,
                    label: item.company.contactCompany.name,
                    name: item.company.contactCompany.name,
                }
            })
            setComapnies(selectOptions)
            setSelectedCompany(selectOptions)
        }
    }

    // const handleContactMenuClose = () => {
    //     if(contactError) setContactError(null);
    //     if(!selectedContact && contactInputValue) {
    //         setSelectedContact({
    //             label: contactInputValue,
    //             value: 0
    //         })
    //     }
    // }

    useEffect(() => {
        // add contact automatically on contactProfile tab in contacts
        const contactPage = pathname.split('/')
        if (contactPage.includes('contacts') && contactPage.includes(contact.id.toString())) {
            selectedTableRows && selectedTableRows.push({ contact: contact })
        }
        // add contacts automatically on opportunuty tab in properties
        const contactsArray = selectedContactsForOffer ? [...selectedContactsForOffer] : []
        const contactsForValidation = [...offerEmailSettingsGroup]
        selectedTableRows &&
            selectedTableRows?.forEach((contact) => {
                contactsArray.push({
                    id: contact.contact.id,
                    name:
                        contact.contact.contactType === 'person'
                            ? `${contact.contact.contactPerson?.firstName} ${contact.contact.contactPerson?.lastName}`
                            : `${contact.contact?.contactCompany?.name}`,
                    email: contact.contact.contactEmail[0]?.email,
                })
            })

        contactsArray.forEach((contact) => {
            contactsForValidation.push({
                [propertyOfferPrintFields.CONTACT_ID]: contact.id,
                [propertyOfferPrintFields.CONTACT_EMAIL]: contact.email,
            })
        })

        const uniqueContacts = uniqueObjectsInArray(contactsArray, 'id')
        const uniqueValidationContacts = uniqueObjectsInArray(contactsForValidation, 'contact_id')

        if (contactsArray.length > 0 && addSelectedRows) {
            setSelectedContactsForOffer([...uniqueContacts])
            validation?.setFieldValue(`offer.offer_email_settings_group`, [...uniqueValidationContacts])
            setAddSelectedRows(false)
        }
    }, [])

    const handleContactAdd = () => {
        if (selectedContact && selectedContact.email) {
            setContactError(null)
            const contactsArray = selectedContactsForOffer ? [...selectedContactsForOffer] : []
            const contactsForValidation = [...offerEmailSettingsGroup]

            contactsArray.push({
                id: selectedContact.id,
                name: selectedContact.name,
                email: selectedContact.email,
            })

            contactsForValidation.push({
                [propertyOfferPrintFields.CONTACT_ID]: selectedContact?.id,
                [propertyOfferPrintFields.CONTACT_EMAIL]: selectedContact.email,
                contact_company_collection: selectedCompany.map((company) => ({
                    [propertyOfferPrintFields.CONTACT_ID]: company.id,
                })),
            })

            const uniqueContacts = uniqueObjectsInArray(contactsArray, 'email')
            const uniqueValidationContacts = uniqueObjectsInArray(contactsForValidation, 'contact_email')

            setSelectedContactsForOffer([...uniqueContacts])
            validation?.setFieldValue(`offer.offer_email_settings_group`, [...uniqueValidationContacts])

            setContacts([])
            setSelectedContact(null)
            setSelectedCompany([])
            setComapnies([])
            return
        }

        setContactError(t('modal.offerPrintMain.message.error'))
    }

    const handleContactForOfferRemove = (itemId, itemEmail) => {
        setSelectedContactsForOffer((prevState) => prevState.filter((x) => !(x.id === itemId && x.email === itemEmail)))
        validation?.setFieldValue(`offer.offer_email_settings_group`, [
            ...offerEmailSettingsGroup.filter(
                (x) => !(x[propertyOfferPrintFields.CONTACT_ID] === itemId && x[propertyOfferPrintFields.CONTACT_EMAIL] === itemEmail)
            ),
        ])
    }

    return (
        <>
            <FormikProvider value={validation}>
                <Form
                    className="needs-validation"
                    name="event-form"
                    id="form-event"
                    onSubmit={(e) => {
                        e.preventDefault()
                        // formik.handleSubmit();
                        return false
                    }}
                >
                    <Row>
                        <Col sm={3} className="mb-4">
                            <div className="d-flex justify-content-between">
                                <Label for={propertyOfferPrintFields.OFFER_LOCALE} id={`${propertyOfferPrintFields.OFFER_LOCALE}Lbl`}>
                                    {t('modal.offerPrintMain.language')}
                                </Label>
                            </div>
                            <Select
                                onChange={(e) => {
                                    validation?.setFieldValue(`offer.${propertyOfferPrintFields.OFFER_LOCALE}`, e.value)
                                }}
                                onBlur={() => {
                                    validation?.setFieldTouched(`offer.${propertyOfferPrintFields.OFFER_LOCALE}`)
                                }}
                                options={localeOptions}
                                name={propertyOfferPrintFields.OFFER_LOCALE}
                                id={propertyOfferPrintFields.OFFER_LOCALE}
                                value={localeOptions?.find((x) => x.value === localeValue)}
                                placeholder=""
                            />
                        </Col>
                        <Col sm={9}>
                            <div className="d-flex justify-content-between">
                                <Label for={propertyOfferPrintFields.AGENT_ID} id={`${propertyOfferPrintFields.AGENT_ID}Lbl`}>
                                    {t('app.common.agent')}
                                </Label>
                            </div>
                            <Select
                                onChange={(e) => {
                                    validation?.setFieldValue(`offer.${propertyOfferPrintFields.AGENT_ID}`, e.value)
                                }}
                                onBlur={() => {
                                    validation?.setFieldTouched(`offer.${propertyOfferPrintFields.AGENT_ID}`)
                                }}
                                options={agents}
                                name={propertyOfferPrintFields.AGENT_ID}
                                id={propertyOfferPrintFields.AGENT_ID}
                                value={agents.length ? agents.find((x) => x.value?.toString() === agentValue) : ''}
                                noOptionsMessage={() => t('app.common.noOptions')}
                                placeholder=""
                            />
                        </Col>
                    </Row>
                    {isOfferView && (
                        <Row>
                            <Col sm={12} className="mb-4">
                                <div className="d-flex justify-content-between">
                                    <Label for={propertyOfferPrintFields.CONTACT_ID} id={`${propertyOfferPrintFields.CONTACT_ID}Lbl`}>
                                        {t('app.common.contactNameSurname')}
                                    </Label>
                                </div>
                                <Select
                                    onInputChange={(value) => {
                                        onContactInputChange(value)
                                    }}
                                    onBlur={(e) => {
                                        // console.log('blur', e)
                                    }}
                                    onChange={(e) => {
                                        handleContactSelectChange(e)
                                    }}
                                    options={contacts}
                                    name={propertyOfferPrintFields.CONTACT_ID}
                                    id={propertyOfferPrintFields.CONTACT_ID}
                                    value={selectedContact}
                                    noOptionsMessage={() => t('app.common.noOptions')}
                                    isLoading={isContactsFetching}
                                    loadingMessage={() => t('app.common.loading')}
                                    placeholder=""
                                />
                            </Col>
                            <Col sm={12} className="mb-2">
                                <div className="d-flex justify-content-between">
                                    <Label for={propertyOfferPrintFields.CONTACT_ID} id={`${propertyOfferPrintFields.CONTACT_ID}Lbl`}>
                                        {t('app.common.company.name')}
                                    </Label>
                                </div>
                                <Select
                                    onChange={(e) => {
                                        setSelectedCompany(e)
                                    }}
                                    options={companies}
                                    isMulti={true}
                                    name={propertyOfferPrintFields.COMPANY_ID}
                                    id={propertyOfferPrintFields.COMPANY_ID}
                                    value={selectedCompany}
                                    noOptionsMessage={() => t('app.common.noOptions')}
                                    // isLoading={isCompaniesFetching}
                                    loadingMessage={() => t('app.common.loading')}
                                    placeholder=""
                                />
                            </Col>

                            {contactError ? (
                                <Col sm={12} className="mb-2">
                                    <FormFeedback type="invalid" className={`${contactError ? 'd-block' : ''} mb-2`}>
                                        {contactError}
                                    </FormFeedback>
                                </Col>
                            ) : null}

                            <div
                                style={{ cursor: 'pointer' }}
                                className="d-inline-block text-edit align-items-center mb-4"
                                onClick={() => {
                                    handleContactAdd()
                                }}
                            >
                                <i className="mdi mdi-plus-circle fs-17"></i> {t('button.add')}
                            </div>
                            <Col sm={12}>
                                {contactAdded && (
                                    <Alert color="success">
                                        <span
                                            style={{
                                                color: '#B98A38',
                                            }}
                                        >
                                            {t('modal.offerPrintMain.message.success')}
                                        </span>
                                    </Alert>
                                )}
                            </Col>
                        </Row>
                    )}

                    <Row className="mb-4">
                        <Col sm={12}>
                            <div style={{ height: '34.5px' }} className="d-flex justify-content-between align-items-center">
                                <Label className="fw-medium">{t('modal.offerPrintMain.template')}</Label>
                                {additionalOptions && (
                                    <button
                                        className="btn btn-light"
                                        onClick={() => {
                                            toggleSettingsModal()
                                        }}
                                        type="button"
                                    >
                                        <i className="mdi mdi-cog"></i>{' '}
                                    </button>
                                )}
                            </div>
                            <Row>
                                {offerTemplate &&
                                    offerTemplate.items.map((tmpl) => {
                                        return (
                                            <Col sm={4} key={tmpl.id}>
                                                <img
                                                    onClick={() => {
                                                        setSelectedTemplate(tmpl.id)
                                                        setAdditionalOptions(tmpl.additionalOptions)
                                                    }}
                                                    className={`image-template ${tmpl.id === selectedTemplate ? 'image-selected' : ''}`}
                                                    alt=""
                                                    src={tmpl.cover}
                                                />
                                            </Col>
                                        )
                                    })}
                            </Row>
                        </Col>
                    </Row>
                    {isOfferView && (
                        <Row className={`${selectedContactsForOffer?.length ? 'offer-properties-list pt-4' : ''}`}>
                            {selectedContactsForOffer?.length
                                ? selectedContactsForOffer.map((item) => (
                                      <Col sm={12} key={item.id} className="offer-property-item pb-4 mb-4 d-flex align-items-center">
                                          <div className="flex-grow-1">
                                              <span className="d-block fw-medium fs-13 mb-2">
                                                  {item.name}, {item.email}
                                              </span>
                                              {properties?.length
                                                  ? properties.map((item) => {
                                                        return isProject ? (
                                                            <div className="text-secondary fs-11 fw-light mb-1" key={item.id}>
                                                                {item.id ? `${item.id}, ` : ''}
                                                                {item.translations?.[currentLang][projectResponseFields.PROJECT_TITLE]
                                                                    ? `${item.translations[currentLang][projectResponseFields.PROJECT_TITLE]}`
                                                                    : ''}
                                                            </div>
                                                        ) : (
                                                            <div className="text-secondary fs-11 fw-light mb-1" key={item.id}>
                                                                {item.id ? `${item.id}, ` : ''}
                                                                {item[propertyResponseFields.PROPERTY_SERVICE]
                                                                    ? `${item[propertyResponseFields.PROPERTY_SERVICE].name}, `
                                                                    : ''}
                                                                {item[propertyResponseFields.PROPERTY_TYPE]
                                                                    ? `${item[propertyResponseFields.PROPERTY_TYPE].name}`
                                                                    : ''}{' '}
                                                                |{' '}
                                                                {[
                                                                    item[propertyResponseFields.CITY]
                                                                        ? `${item[propertyResponseFields.CITY].name}, `
                                                                        : '',
                                                                ]}
                                                                {[
                                                                    item[propertyResponseFields.WIDER_AREA]
                                                                        ? `${item[propertyResponseFields.WIDER_AREA].name}`
                                                                        : '',
                                                                ]}{' '}
                                                                |{' '}
                                                                {item[propertyResponseFields.AREA] ? (
                                                                    <>
                                                                        {item[propertyResponseFields.AREA]} {renderAreaUnit(areaUnit)},{' '}
                                                                    </>
                                                                ) : (
                                                                    ''
                                                                )}
                                                                {item[propertyResponseFields.PRICE]
                                                                    ? `${item[propertyResponseFields.PRICE]?.toLocaleString(
                                                                          'hr-HR'
                                                                      )} ${renderCurrency(currency, false)}`
                                                                    : ''}
                                                            </div>
                                                        )
                                                    })
                                                  : null}
                                          </div>
                                          <div>
                                              <span
                                                  className="text-danger"
                                                  onClick={() => handleContactForOfferRemove(item.id, item.email)}
                                                  style={{ cursor: 'pointer' }}
                                              >
                                                  <i className="mdi mdi-trash-can-outline fs-17"></i>
                                              </span>
                                          </div>
                                      </Col>
                                  ))
                                : null}
                        </Row>
                    )}

                    <div className="hstack gap-2 justify-content-end">
                        <Button
                            color="warning"
                            className="btn-label"
                            onClick={() => {
                                togglePreviewModal()
                            }}
                        >
                            <i className="mdi mdi-eye align-bottom label-icon align-middle fs-16 me-2"></i>
                            {t('button.review')}
                        </Button>
                        {isOfferView ? (
                            <Button
                                color="send"
                                className="btn-label"
                                onClick={() => {
                                    toggleSendModal()
                                }}
                                disabled={!selectedContactsForOffer.length}
                            >
                                <i className="mdi mdi-arrow-right align-bottom label-icon align-middle fs-16 me-2"></i>
                                {t('button.send')}
                            </Button>
                        ) : (
                            <Button type="submit" color="send" className="btn-label" onClick={togglePreviewModal}>
                                <i className="mdi mdi-printer-outline align-bottom label-icon align-middle fs-16 me-2"></i>
                                {t('app.common.print')}
                            </Button>
                        )}
                    </div>
                </Form>
            </FormikProvider>
        </>
    )
}

export default OfferTab
