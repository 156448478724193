import React from 'react'
import FormActionButtons from '../../Components/Common/FormActionButtons'
import { Col, Label, Row } from 'reactstrap'
import Flatpickr from 'react-flatpickr'
import classNames from 'classnames'
import dayjs from 'dayjs'

const ReportFilterForm = ({ values, setFieldValue, errors, touched, handleSubmit, handleReset }) => {
    const datePickerClassNames = (fieldName) =>
        classNames('form-control', {
            'is-invalid': errors[fieldName] && touched[fieldName],
        })

    return (
        <form onSubmit={handleSubmit}>
            <Row className="mb-4">
                <Col>
                    <Label for="dateFrom">Datum od</Label>
                    <div className="flatpickr-container">
                        <Flatpickr
                            id="dateFrom"
                            className={datePickerClassNames('dateFrom')}
                            name="dateFrom"
                            options={{
                                dateFormat: 'd-m-Y',
                            }}
                            onChange={([date]) => {
                                setFieldValue('dateFrom', dayjs(date).format('YYYY-MM-DD'))
                            }}
                            value={values.dateFrom ? new Date(values.dateFrom) : ''}
                        />
                    </div>
                </Col>
                <Col>
                    <Label for="dateTo">Datum od</Label>
                    <div className="flatpickr-container">
                        <Flatpickr
                            id="dateTo"
                            className={datePickerClassNames('dateTo')}
                            name="dateTo"
                            options={{
                                dateFormat: 'd-m-Y',
                            }}
                            onChange={([date]) => {
                                setFieldValue('dateTo', dayjs(date).format('YYYY-MM-DD'))
                            }}
                            value={values.dateTo ? new Date(values.dateTo) : ''}
                        />
                    </div>
                </Col>
            </Row>

            <FormActionButtons hasCancel cancelAction={handleReset} />
        </form>
    )
}

export default ReportFilterForm
