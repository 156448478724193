import values from 'lodash/values';
import { stateKey } from './actions';
import { selectStateOwnerFormOptions } from '../../utils/general';

export const selectProjects = state => values(state[stateKey].items);
export const selectProjectsFetching = state => state[stateKey].fetching;
export const selectProjectFetchingDetails = state => state[stateKey].fetchingDetails;
export const selectProjectCount = state => state[stateKey].count;
export const selectProject = (state) => state[stateKey].item;
export const selectProjectsPagination = (state) => state[stateKey].pagination;
export const selectProjectLinks = (state) => state[stateKey].links

export const selectProjectAsyncOwnerFormOptions = (state) => {
    const stateSlice = state[stateKey].item?.owner
    return selectStateOwnerFormOptions(stateSlice)
}