import { diaryDetailSearchFields } from './fields'

export const diaryDetailSearchValidation = (data) => {
    return {
        initialValues: {
            [diaryDetailSearchFields.ID]: data?.id ?? '',
            [diaryDetailSearchFields.FULL_NAME_SELLER]: data?.[diaryDetailSearchFields.FULL_NAME_SELLER] ?? '',
            [diaryDetailSearchFields.FULL_NAME_BUYER]: data?.[diaryDetailSearchFields.FULL_NAME_BUYER] ?? '',
        },
        prepareModelForSubmit: (values) => {
            const filledValues = Object.fromEntries(Object.entries(values).filter(([key, value]) => value !== null && value !== ''))
            return {
                ...filledValues,
            }
        },
    }
}
