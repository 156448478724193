import QueryString from 'qs'
import { toast } from 'react-toastify'
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { updateFile } from '../../api'
import { apiRequest } from '../api'
import i18n from '../../i18n'
import {
    deleteDocument,
    deleteDocumentFailure,
    deleteDocumentMultiple,
    deleteDocumentMultipleFailure,
    deleteDocumentMultipleRequest,
    deleteDocumentMultipleSuccess,
    deleteDocumentRequest,
    deleteDocumentSuccess,
    fetchDocuments,
    fetchDocumentsFailure,
    fetchDocumentsRequest,
    fetchDocumentsSuccess,
    queDocument,
    queDocumentFailure,
    queDocumentRequest,
    queDocumentSuccess,
    syncDocument,
    syncDocumentFailure,
    syncDocumentRequest,
    syncDocumentSuccess,
    updateDocument,
    updateDocumentFailure,
    updateDocumentRequest,
    updateDocumentSuccess,
    uploadDocument,
    uploadDocumentFailure,
    uploadDocumentRequest,
    uploadDocumentSuccess,
} from './actions'

export function* uploadDocumentSaga({ payload, meta }) {
    const { formData, fileData, uploadUrl } = payload

    yield put(uploadDocumentRequest(fileData))

    const progressToast = toast.loading(i18n.t('toast.loading.documents'))

    try {
        const response = yield apiRequest(`v1/file/upload/${uploadUrl}`, {
            method: 'POST',
            data: formData,
        })

        if (fileData.module === 'contact') {
            const contactRespone = yield apiRequest(`v1/contact/${fileData.parentId}`, {
                method: 'GET',
            })

            if (contactRespone.data) {
                const { contactType, contactPerson, contactCompany } = contactRespone.data.item ?? {}
                const title = contactType === 'person' ? `${contactPerson?.firstName} ${contactPerson?.lastName}` : contactCompany?.name

                Object.assign(fileData, {
                    parentElement: {
                        id: fileData.parentId,
                        title,
                    },
                })
            }
        }

        yield put(
            uploadDocumentSuccess({
                ...fileData,
                id: response.data?.[0]?.id,
            })
        )

        toast.update(progressToast, { render: i18n.t('toast.update.documents.success'), type: 'success', isLoading: false, autoClose: 3000 })
        if (meta && meta.callback) {
            meta.callback()
        }
    } catch (error) {
        yield put(
            uploadDocumentFailure({
                error,
                fileData,
            })
        )
        toast.update(progressToast, { render: i18n.t('toast.update.documents.error'), type: 'error', isLoading: false, autoClose: 3000 })
    }
}

export function* queDocumentSaga({ payload, meta }) {
    yield put(queDocumentRequest())
    console.log('meta', meta)
    console.log('starting que', payload)
    try {
        yield put(queDocumentSuccess(payload))
        // if (meta && meta.callback) {
        //     meta.callback();
        //   }
    } catch (error) {
        yield put(queDocumentFailure(error))
    }
}

export function* deleteDocumentSaga({ payload, meta }) {
    yield put(deleteDocumentRequest())
    const progressToast = toast.loading(i18n.t('toast.loading.delete'))
    try {
        const response = yield apiRequest(`v1/file/${payload}`, {
            method: 'DELETE',
        })
        yield put(deleteDocumentSuccess(payload))
        toast.update(progressToast, { render: i18n.t('toast.update.delete.success'), type: 'success', isLoading: false, autoClose: 3000 })
        if (meta && meta.callback) {
            meta.callback()
        }
    } catch (error) {
        yield put(deleteDocumentFailure(error))
        toast.update(progressToast, { render: i18n.t('toast.update.delete.error'), type: 'error', isLoading: false, autoClose: 3000 })
    }
}

function* deleteDocumentById(id) {
    try {
        const response = yield apiRequest(`v1/file/${id}`, {
            method: 'DELETE',
        })
        return response
    } catch (error) {
        return error
    }
}

export function* deleteDocumentMultipleSaga({ payload }) {
    const requestsToBeDeletedCount = payload.length
    yield put(deleteDocumentMultipleRequest())

    if (requestsToBeDeletedCount === 0) {
        yield put(deleteDocumentMultipleFailure('Nema dokumenata za brisanje'))
        return
    }

    const progressToast = toast.loading(i18n.t('toast.loading.delete.number', {requestsToBeDeletedCount}))

    const responses = yield all(payload.map((id) => call(deleteDocumentById, id)))

    const failedResponses = responses.filter((response) => response instanceof Error)
    const deletedDocuments = responses.filter((response) => response.status === 200).map((response) => parseInt(response.data.id))
    const successCount = deletedDocuments.length
    const failedCount = failedResponses.length

    if (successCount === 0 && failedCount > 0) {
        yield put(deleteDocumentMultipleFailure('Brisanje nije uspjelo'))
        toast.update(progressToast, {
            render: i18n.t('toast.update.delete.error'),
            type: 'error',
            isLoading: false,
            autoClose: 3000,
        })
        return
    }

    if (successCount > 0) {
        toast.update(progressToast, {
            render: i18n.t('toast.loading.delete.number.success', {successCount, requestsToBeDeletedCount}),
            type: 'success',
            isLoading: false,
            autoClose: 3000,
        })
    }

    if (failedCount > 0) {
        toast.error(i18n.t('toast.loading.delete.number.error', {failedCount, requestsToBeDeletedCount}), {
            autoClose: 3000,
        })
    }

    yield put(deleteDocumentMultipleSuccess(deletedDocuments))
}

export function* syncDocumentSaga({ payload, meta }) {
    yield put(syncDocumentRequest())
    const progressToast = toast.loading(i18n.t('toast.loading'))
    try {
        const response = yield apiRequest(`v1/file/sync/${payload}`, {
            method: 'PUT',
        })

        //   console.log('sync response', response.data)
        yield put(syncDocumentSuccess(payload))

        if (meta && meta.callback) {
            meta.callback()
        }
        toast.update(progressToast, { render: i18n.t('toast.update.success'), type: 'success', isLoading: false, autoClose: 3000 })
    } catch (error) {
        yield put(syncDocumentFailure(error))
        toast.update(progressToast, { render: i18n.t('toast.update.error'), type: 'error', isLoading: false, autoClose: 3000 })
    }
}

export function* fetchDocumentsSaga({ payload, meta }) {
    yield put(fetchDocumentsRequest())
    const { parentId, ...params } = payload

    const fileUrl = parentId ? `v1/file/parent/${parentId}` : 'v1/file'

    try {
        const response = yield apiRequest(fileUrl, {
            method: 'GET',
            params: params,
            paramsSerializer: (params) => {
                return QueryString.stringify(params)
            },
        })

        yield put(fetchDocumentsSuccess(response.data))
        if (meta) {
            meta(response.data.count)
        }
    } catch (error) {
        yield put(fetchDocumentsFailure(error))
    }
}

export function* updateDocumentSaga({ payload, meta }) {
    const {
        document_project_id,
        document_property_id,
        document_contact_id,
        document_type_id,
        document_creation_date,
        document_validity_date,
        file_description,
        file_sync,
        id,
    } = payload

    const parentId = document_project_id || document_property_id || document_contact_id

    const currentLang = localStorage.getItem('i18nextLng')

    const translationObj = { 
        locale: currentLang,
        fileDescription: file_description
    }
 
    const updateData = {
        document_type_id,
        document_creation_date,
        document_validity_date,
        file_sync,
        id,
        parent_id: parentId,
        translations: [translationObj],
    }

    yield put(updateDocumentRequest())

    const progressToast = toast.loading(i18n.t('toast.loading'))
    try {
        yield updateFile(id, updateData)

        const response = yield apiRequest(`v1/file/${id}`, {
            method: 'GET',
        })

        yield put(updateDocumentSuccess(response.data))
        toast.update(progressToast, { render: i18n.t('toast.update.success'), type: 'success', isLoading: false, autoClose: 3000 })
        if (meta && meta.callback) {
            meta.callback()
        }
    } catch (error) {
        yield put(updateDocumentFailure(error))
        toast.update(progressToast, { render: i18n.t('toast.update.error'), type: 'error', isLoading: false, autoClose: 3000 })
    }
}

export default function* actionWatcher() {
    yield takeLatest(`${fetchDocuments}`, fetchDocumentsSaga)
    yield takeEvery(`${uploadDocument}`, uploadDocumentSaga)
    yield takeEvery(`${queDocument}`, queDocumentSaga)
    yield takeEvery(`${deleteDocument}`, deleteDocumentSaga)
    yield takeEvery(`${deleteDocumentMultiple}`, deleteDocumentMultipleSaga)
    yield takeEvery(`${syncDocument}`, syncDocumentSaga)
    yield takeEvery(`${updateDocument}`, updateDocumentSaga)
}
