import i18next from 'i18next'
import React from 'react'
import { formatDateTime, formatNumberWithTwoDecimals } from '../../utils/general'
import { renderAreaUnit, renderCurrency, renderCurrencyWithUnit } from '../../utils/property'
import { IS_ERSTE } from '../constants'
import { companyResponseFields } from '../response/company'

export const companyGeneralViewData = (data, config) => {
    const { t } = i18next

    const companyPhone = data?.[companyResponseFields.CONTACT_PHONE]?.map((item, i) => ({
        id: `${companyResponseFields.CONTACT_PHONE}-${item.id}`,
        label: `${t('view.contact.general.phone')} ${i + 1}`,
        value: `${item.phoneNumber}${item.contactPhoneType ? ' - ' + item.contactPhoneType?.name : ''}`,
    }))

    const companyEmail = data?.[companyResponseFields.CONTACT_EMAIL]?.map((item, i) => ({
        id: `${companyResponseFields.CONTACT_EMAIL}-${item.id}`,
        label: `${t('app.common.email.label')}  ${i + 1}`,
        value: (
            <>
                <a className="dmn-link" href={`mailto:${item.email}`}>
                    {item.email}
                </a>
                {item.contactEmailType ? ' - ' + item.contactEmailType?.name : ''}
            </>
        ),
    }))

    return {
        general: [
            {
                id: companyResponseFields.ID,
                label: t('app.common.id'),
                value: data?.[companyResponseFields.ID],
            },
            {
                id: `${companyResponseFields.NAME}`,
                label: t('app.common.name'),
                value: `${
                    data?.[companyResponseFields.CONTACT_COMPANY]?.[companyResponseFields.NAME]
                        ? data?.[companyResponseFields.CONTACT_COMPANY]?.[companyResponseFields.NAME]
                        : ''
                }`,
            },
            {
                id: `${companyResponseFields.FULL_NAME}`,
                label: t('app.common.company.fullName'),
                value: `${
                    data?.[companyResponseFields.CONTACT_COMPANY]?.[companyResponseFields.FULL_NAME]
                        ? data?.[companyResponseFields.CONTACT_COMPANY]?.[companyResponseFields.FULL_NAME]
                        : ''
                }`,
            },
            {
                id: `${companyResponseFields.TAX_NUMBER}`,
                label: t('app.common.pin'),
                value: `${data?.[companyResponseFields.TAX_NUMBER] ?? ''}`,
            },

            {
                id: `${companyResponseFields.STREET}-${companyResponseFields.STREET_NUMBER}`,
                label: t('view.contact.general.address'),
                value:
                    (data?.[companyResponseFields.STREET] ||
                        data?.[companyResponseFields.STREET_NUMBER] ||
                        data?.[companyResponseFields.ZIP_CODE] ||
                        data?.[companyResponseFields.CITY] ||
                        data?.[companyResponseFields.CONTACT_COUNTRY]) &&
                    `${data?.[companyResponseFields.STREET] ?? ''} ${data?.[companyResponseFields.STREET_NUMBER] ?? ''} ${
                        data?.[companyResponseFields.ZIP_CODE] ?? ''
                    } ${data?.[companyResponseFields.CITY] ?? ''} ${
                        data?.[companyResponseFields.CONTACT_COUNTRY] ? ', ' + data?.[companyResponseFields.CONTACT_COUNTRY]?.name : ''
                    }`,
            },
            ...(companyPhone ?? []),
            ...(companyEmail ?? []),
            {
                id: companyResponseFields.WEBSITE,
                label: t('app.common.web'),
                value: data?.[companyResponseFields.CONTACT_COMPANY]?.[companyResponseFields.WEBSITE],
            },
            {
                id: companyResponseFields.CONTACT_LEGAL_FORM,
                label: t('app.common.company.legalForm'),
                value: data?.[companyResponseFields.CONTACT_COMPANY]?.[companyResponseFields.CONTACT_LEGAL_FORM]?.name,
            },
            {
                id: companyResponseFields.DATA_SOURCE,
                label: t('app.common.contact.dataSource'),
                value: data?.[companyResponseFields.DATA_SOURCE]?.name,
            },
            IS_ERSTE && {
                id: companyResponseFields.CONTACT_SOURCE,
                label: t('view.contact.general.contactSource'),
                value: data?.[companyResponseFields.CONTACT_SOURCE]?.name,
            },
            IS_ERSTE && {
                id: `${companyResponseFields.CONTACT_ABOUT}`,
                label: t('app.common.contact.about'),
                value: data?.[companyResponseFields.CONTACT_ABOUT],
            },
            {
                id: companyResponseFields.CONTACT_STATUS,
                label: t('app.common.status'),
                value: data?.[companyResponseFields.CONTACT_STATUS] ? data?.[companyResponseFields.CONTACT_STATUS]?.name : '',
            },
            {
                id: `${companyResponseFields.CONTACT_QUERY_CATEGORY}_name`,
                label: t('app.common.contact.queryCategory'),
                value: data?.[companyResponseFields.CONTACT_QUERY_CATEGORY]?.name,
            },
            {
                id: `${companyResponseFields.CONTACT_PRIORITY}_name`,
                label: t('app.common.contact.priority'),
                value: data?.[companyResponseFields.CONTACT_PRIORITY]?.name,
            },
            {
                id: `${companyResponseFields.CONTACT_CORE_BUSINESS}_name`,
                label: t('app.common.company.coreBusiness'),
                value: data?.[companyResponseFields.CONTACT_COMPANY]?.[companyResponseFields.CONTACT_CORE_BUSINESS]?.name,
            },
            {
                id: `${companyResponseFields.CONTACT_COMPANY_SIZE}_name`,
                label: t('app.common.company.size'),
                value: data?.[companyResponseFields.CONTACT_COMPANY]?.[companyResponseFields.CONTACT_COMPANY_SIZE]?.name,
            },
            {
                id: `${companyResponseFields.EMPLOYEES_NO}_name`,
                label: t('app.common.company.employeesNumber'),
                value: data?.[companyResponseFields.CONTACT_COMPANY]?.[companyResponseFields.EMPLOYEES_NO],
            },
            {
                id: `${companyResponseFields.BANK_ACCOUNT_NO}_name`,
                label: t('app.common.company.bankAccount'),
                value: data?.[companyResponseFields.CONTACT_COMPANY]?.[companyResponseFields.BANK_ACCOUNT_NO],
            },
            {
                id: `${companyResponseFields.REGISTRY_COURT}_name`,
                label: t('app.common.company.registryCourt'),
                value: data?.[companyResponseFields.CONTACT_COMPANY]?.[companyResponseFields.REGISTRY_COURT],
            },
            {
                id: `${companyResponseFields.SHARE_CAPITAL}_name`,
                label: t('app.common.company.shareCapital'),
                value: data?.[companyResponseFields.CONTACT_COMPANY]?.[companyResponseFields.SHARE_CAPITAL],
            },
            {
                id: `${companyResponseFields.AGENT}_full_name`,
                label: t('app.common.agent'),
                value: data?.agent
                    ? data?.[companyResponseFields.AGENT] && (
                          <>
                              {data?.[companyResponseFields.AGENT]?.firstName} {data?.[companyResponseFields.AGENT]?.lastName}
                          </>
                      )
                    : '',
            },
            {
                id: companyResponseFields.CREATED,
                label: t('view.common.other.created'),
                value: formatDateTime(data?.[companyResponseFields.CREATED]),
            },
            {
                id: companyResponseFields.UPDATED,
                label: t('app.common.updated'),
                value: formatDateTime(data?.[companyResponseFields.UPDATED]),
            },
        ],
    }
}

export const companyProfileViewData = (data, config) => {
    const currency = renderCurrency(config.currency)
    const currencyWithUnit = renderCurrencyWithUnit(config?.currency, config?.areaUnit)
    const unit = renderAreaUnit(config?.areaUnit)
    const { t } = i18next

    return [
        {
            id: `${companyResponseFields.PROPERTY_SUB_TYPE}`,
            label: t('app.common.property.subtype'),
            value: data?.[companyResponseFields.PROPERTY_SUB_TYPE]?.length
                ? data?.[companyResponseFields.PROPERTY_SUB_TYPE]?.map((item, i) => {
                      return <React.Fragment key={item.id}>{i === 0 ? item.name : `, ${item.name}`}</React.Fragment>
                  })
                : null,
        },
        {
            id: `${companyResponseFields.CONTACT_PROFILE_STATUS}`,
            label: t('app.common.contact.profileStatus'),
            value: data?.[companyResponseFields.CONTACT_PROFILE_STATUS]?.name ?? null,
        },
        {
            id: `${companyResponseFields.CONTACT_FINANCING}`,
            label: t('app.common.contact.financing'),
            value: data?.[companyResponseFields.CONTACT_FINANCING]?.length
                ? data?.[companyResponseFields.CONTACT_FINANCING]?.map((item, i) => {
                      return <React.Fragment key={item.id}>{i === 0 ? item.name : `, ${item.name}`}</React.Fragment>
                  })
                : null,
        },
        {
            id: `${companyResponseFields.CONTACT_PROFILE_DURATION}`,
            label: t('app.common.contact.realizationTime'),
            value: data?.[companyResponseFields.CONTACT_PROFILE_DURATION]?.name ?? null,
        },
        {
            id: `${companyResponseFields.COMPANY_DEFINITION}`,
            label: t('app.common.contact.definition'),
            value: data?.[companyResponseFields.COMPANY_DEFINITION]?.name ?? null,
        },
        {
            id: `${companyResponseFields.COUNTRY}`,
            label: t('app.common.country'),
            value: data?.[companyResponseFields.COUNTRY]?.name,
        },
        {
            id: `${companyResponseFields.COUNTY}`,
            label: t('app.common.county'),
            value: data?.[companyResponseFields.COUNTY]?.name,
        },
        {
            id: `${companyResponseFields.CITY}`,
            label: t('app.common.cityMunicipality'),
            value: data?.[companyResponseFields.CITY]?.length
                ? data?.[companyResponseFields.CITY]?.map((item, i) => {
                      return <>{i === 0 ? item.name : `, ${item.name}`}</>
                  })
                : '',
        },
        {
            id: `${companyResponseFields.WIDER_AREA}`,
            label: t('app.common.widerArea'),
            value: data?.[companyResponseFields.WIDER_AREA]?.length
                ? data?.[companyResponseFields.WIDER_AREA]?.map((item, i) => {
                      return <>{i === 0 ? item.name : `, ${item.name}`}</>
                  })
                : '',
        },
        {
            id: `${companyResponseFields.QUARTER}`,
            label: t('app.common.quarter'),
            value: data?.[companyResponseFields.QUARTER]?.length
                ? data?.[companyResponseFields.QUARTER]?.map((item, i) => {
                      return <>{i === 0 ? item.name : `, ${item.name}`}</>
                  })
                : '',
        },
        {
            id: `${companyResponseFields.ISLAND}`,
            label: t('app.common.island'),
            value: data?.[companyResponseFields.ISLAND]?.length
                ? data?.[companyResponseFields.ISLAND]?.map((item, i) => {
                      return <>{i === 0 ? item.name : `, ${item.name}`}</>
                  })
                : '',
        },
        {
            id: `${companyResponseFields.PRICE_FROM}_${companyResponseFields.PRICE_TO}`,
            label: t('app.common.price', { currency }),
            value:
                data?.[companyResponseFields.PRICE_FROM] || data?.[companyResponseFields.PRICE_TO] ? (
                    <>
                        {data?.[companyResponseFields.PRICE_FROM] && (
                            <>
                                {t('app.common.from')} {formatNumberWithTwoDecimals(data?.[companyResponseFields.PRICE_FROM])}{' '}
                            </>
                        )}
                        {data?.[companyResponseFields.PRICE_TO] && (
                            <>
                                {t('app.common.to')} {formatNumberWithTwoDecimals(data?.[companyResponseFields.PRICE_TO])}
                            </>
                        )}
                    </>
                ) : (
                    ''
                ),
        },
        {
            id: `${companyResponseFields.PRICE_M2_FROM}_${companyResponseFields.PRICE_M2_TO}`,
            label: t('app.common.pricePerUnit', { currencyWithUnit }),
            value:
                data?.[companyResponseFields.PRICE_M2_FROM] || data?.[companyResponseFields.PRICE_M2_TO] ? (
                    <>
                        {data?.[companyResponseFields.PRICE_M2_FROM] && (
                            <>
                                {t('app.common.from')} {formatNumberWithTwoDecimals(data?.[companyResponseFields.PRICE_M2_FROM])}{' '}
                            </>
                        )}
                        {data?.[companyResponseFields.PRICE_M2_TO] && (
                            <>
                                {t('app.common.to')} {formatNumberWithTwoDecimals(data?.[companyResponseFields.PRICE_M2_TO])}
                            </>
                        )}
                    </>
                ) : (
                    ''
                ),
        },
        {
            id: `${companyResponseFields.ROOMS_FROM}_${companyResponseFields.ROOMS_TO}`,
            label: t('view.contact.profile.rooms'),
            value:
                data?.[companyResponseFields.ROOMS_FROM] || data?.[companyResponseFields.ROOMS_TO] ? (
                    <>
                        {data?.[companyResponseFields.ROOMS_FROM] && (
                            <>
                                {t('app.common.from')} {data?.[companyResponseFields.ROOMS_FROM]}{' '}
                            </>
                        )}
                        {data?.[companyResponseFields.ROOMS_TO] && (
                            <>
                                {t('app.common.to')} {data?.[companyResponseFields.ROOMS_TO]}
                            </>
                        )}
                    </>
                ) : (
                    ''
                ),
        },
        {
            id: `${companyResponseFields.BEDROOMS_FROM}_${companyResponseFields.BEDROOMS_TO}`,
            label: t('app.common.bedrooms'),
            value:
                data?.[companyResponseFields.BEDROOMS_FROM] || data?.[companyResponseFields.BEDROOMS_TO] ? (
                    <>
                        {data?.[companyResponseFields.BEDROOMS_FROM] && (
                            <>
                                {t('app.common.from')} {data?.[companyResponseFields.BEDROOMS_FROM]}{' '}
                            </>
                        )}
                        {data?.[companyResponseFields.BEDROOMS_TO] && (
                            <>
                                {t('app.common.to')} {data?.[companyResponseFields.BEDROOMS_TO]}
                            </>
                        )}
                    </>
                ) : (
                    ''
                ),
        },
        {
            id: `${companyResponseFields.AREA_FROM}_${companyResponseFields.AREA_TO}`,
            label: t('view.contact.profile.area', { unit }),
            value:
                data?.[companyResponseFields.AREA_FROM] || data?.[companyResponseFields.AREA_TO] ? (
                    <>
                        {data?.[companyResponseFields.AREA_FROM] && (
                            <>
                                {t('app.common.from')} {data?.[companyResponseFields.AREA_FROM]}{' '}
                            </>
                        )}
                        {data?.[companyResponseFields.AREA_TO] && (
                            <>
                                {t('app.common.to')} {data?.[companyResponseFields.AREA_TO]}
                            </>
                        )}
                    </>
                ) : (
                    ''
                ),
        },
        {
            id: `${companyResponseFields.FLOOR_FROM}_${companyResponseFields.FLOOR_TO}`,
            label: t('app.common.floor'),
            value:
                data?.[companyResponseFields.FLOOR_FROM] || data?.[companyResponseFields.FLOOR_TO] ? (
                    <>
                        {data?.[companyResponseFields.FLOOR_FROM] && (
                            <>
                                {t('app.common.from')} {data?.[companyResponseFields.FLOOR_FROM]}{' '}
                            </>
                        )}
                        {data?.[companyResponseFields.FLOOR_TO] && (
                            <>
                                {t('app.common.to')} {data?.[companyResponseFields.FLOOR_TO]}
                            </>
                        )}
                    </>
                ) : (
                    ''
                ),
        },
        {
            id: `${companyResponseFields.PROPERTY_FLOOR_POSITION}`,
            label: t('app.common.floorNumber'),
            value: data?.[companyResponseFields.PROPERTY_FLOOR_POSITION]?.length
                ? data?.[companyResponseFields.PROPERTY_FLOOR_POSITION]?.map((item, i) => {
                      return <>{i === 0 ? item.name : `, ${item.name}`}</>
                  })
                : '',
        },
        {
            id: `${companyResponseFields.PROPERTY_FLOOR}`,
            label: t('app.common.numberOfFloors'),
            value: data?.[companyResponseFields.PROPERTY_FLOOR] ? data?.[companyResponseFields.PROPERTY_FLOOR]?.name : '',
        },
        {
            id: `${companyResponseFields.EXCLUDE_BASEMENT}`,
            label: t('app.common.contact.excludeGroundFloor'),
            value:
                data?.[companyResponseFields.EXCLUDE_BASEMENT] &&
                (data?.[companyResponseFields.EXCLUDE_BASEMENT] === 1 || data?.[companyResponseFields.EXCLUDE_BASEMENT] === 0)
                    ? data?.[companyResponseFields.EXCLUDE_BASEMENT]
                        ? t('view.common.conclusion.yes')
                        : t('view.common.conclusion.no')
                    : '',
        },
        {
            id: `${companyResponseFields.EXCLUDE_LAST_FLOOR}`,
            label: t('app.common.contact.excludeLastFloor'),
            value:
                data?.[companyResponseFields.EXCLUDE_LAST_FLOOR] &&
                (data?.[companyResponseFields.EXCLUDE_LAST_FLOOR] === 1 || data?.[companyResponseFields.EXCLUDE_LAST_FLOOR] === 0)
                    ? data?.[companyResponseFields.EXCLUDE_LAST_FLOOR]
                        ? t('view.common.conclusion.yes')
                        : t('view.common.conclusion.no')
                    : '',
        },
        {
            id: `${companyResponseFields.PROPERTY_SPACE}`,
            label: t('app.common.property.space'),
            value: data?.[companyResponseFields.PROPERTY_SPACE].length
                ? data?.[companyResponseFields.PROPERTY_SPACE]?.map((item, i) => {
                      return <>{i === 0 ? item.name : `, ${item.name}`}</>
                  })
                : '',
        },
        {
            id: `${companyResponseFields.PROPERTY_DESCRIPTIONS}`,
            label: t('app.common.property.description'),
            value: data?.[companyResponseFields.PROPERTY_DESCRIPTIONS]?.length
                ? data?.[companyResponseFields.PROPERTY_DESCRIPTIONS]?.map((item, i) => {
                      return <>{i === 0 ? item.name : `, ${item.name}`}</>
                  })
                : '',
        },
        {
            id: `${companyResponseFields.PROPERTY_EQUIPMENT}`,
            label: t('app.common.equipment'),
            value: data?.[companyResponseFields.PROPERTY_EQUIPMENT]?.length
                ? data?.[companyResponseFields.PROPERTY_EQUIPMENT]?.map((item, i) => {
                      return <>{i === 0 ? item.name : `, ${item.name}`}</>
                  })
                : '',
        },
        {
            id: `${companyResponseFields.PROPERTY_UTILITY}`,
            label: t('app.common.utility'),
            value: data?.[companyResponseFields.PROPERTY_UTILITY]?.length
                ? data?.[companyResponseFields.PROPERTY_UTILITY]?.map((item, i) => {
                      return <>{i === 0 ? item.name : `, ${item.name}`}</>
                  })
                : '',
        },
        {
            id: `${companyResponseFields.PROPERTY_HEATING}`,
            label: t('app.common.heating'),
            value: data?.[companyResponseFields.PROPERTY_HEATING]?.length
                ? data?.[companyResponseFields.PROPERTY_HEATING]?.map((item, i) => {
                      return <>{i === 0 ? item.name : `, ${item.name}`}</>
                  })
                : '',
        },
        {
            id: `${companyResponseFields.PROFILE_REMARK}`,
            label: t('app.common.contact.comment'),
            value: data?.[companyResponseFields.PROFILE_REMARK]?.length
                ? data?.[companyResponseFields.PROFILE_REMARK]?.map((item, i) => {
                      return <>{i === 0 ? item.name : `, ${item.name}`}</>
                  })
                : '',
        },
        {
            id: `${companyResponseFields.SPECIAL_REQUEST}`,
            label: t('app.common.contact.specialRequests'),
            value: data?.[companyResponseFields.SPECIAL_REQUEST] ?? '',
        },
    ]
}
