import { Circle, GoogleApiWrapper, Map, Marker } from "google-maps-react";
import { useEffect, useState } from "react";
import MarkerIcon from '../assets/images/marker.svg';

const mapStyles = {
    width: '100%',
    height: '100%',
};

// Default center coordinates
const mapCenter = {
    lat: 45.8130174,
    lng: 15.9773833
};

const LoadingContainer = () => <div>Loading...</div>

const MapContainer = ({ marker, address, showRadius = false, handleAddressSearchDone, handleAddMarkerOnClick, handleMapMarkerOnMove, height, ...props }) => {
    const [center, setCenter] = useState(mapCenter);

    useEffect(() => {
        if (address) {
            const geocoder = new props.google.maps.Geocoder();

            geocoder.geocode({ address }, (results, status) => {
                if (status === props.google.maps.GeocoderStatus.OK) {
                    const location = results[0].geometry.location;
                    setCenter(location);
                    handleAddressSearchDone({ lat: location.lat(), lng: location.lng() });
                }
            });
        }
    }, [address]);

    useEffect(() => {
        if (marker) {
            setCenter(marker);
        }
    }, [marker]);

    const handleLocationChange = (mapProps, map, ev) => {
        handleMapMarkerOnMove(ev.latLng.lat(), ev.latLng.lng())
    }

    return (
        <div id="gmaps-markers" className="gmaps" style={{ position: "relative", height: height ? height : '671px' }}>
            <Map
                google={props.google}
                zoom={17}
                style={mapStyles}
                initialCenter={marker ?? center}
                center={center}
                onClick={handleLocationChange}
            >
                {!showRadius && marker && (
                    <Marker
                        draggable
                        position={marker}
                        onDragend={handleLocationChange}
                        icon={{
                            url: MarkerIcon,
                            scaledSize: new props.google.maps.Size(54, 54),
                            anchor: new props.google.maps.Point(22, 54),
                        }}
                    />
                )}
                {showRadius && marker && (
                    <Circle
                        radius={100}
                        center={marker}
                        strokeColor="#FF0000"
                        strokeOpacity={0.2}
                        fillColor="#FF0000"
                        fillOpacity={0.2}
                    />
                )}
            </Map>
        </div>
    )
}

export default (
    GoogleApiWrapper({
        apiKey: "AIzaSyDlIGnyEOVPJ82B5Zqj2EJyxyvv6cp9JH4",
        LoadingContainer: LoadingContainer,
        v: "3",
    })(MapContainer)
)