import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { REAL_ESTATE_SERVICE, REAL_ESTATE_TYPES } from '../../common/constants'
import {
    propertyFlatLeaseViewData,
    propertyFlatSaleViewData,
    propertyGarageLeaseViewData,
    propertyGarageSaleViewData,
    propertyHouseLeaseViewData,
    propertyHouseSaleViewData,
    propertyLandLeaseViewData,
    propertyLandSaleViewData,
    propertyOfficeSpaceLeaseViewData,
    propertyOfficeSpaceSaleViewData,
} from '../../common/views/propertyViewData'
import { selectApplicationAreaUnit, selectApplicationCurrency, selectSettingsDefaultLanguage } from '../../store/settings/selectors'

export const usePropertyGeneralViewData = (propertyType, propertyService, property) => {
    const [viewData, setViewData] = useState(null)

    const appSettings = useSelector((state) => ({
        currency: selectApplicationCurrency(state),
        lang: selectSettingsDefaultLanguage(state),
        areaUnit: selectApplicationAreaUnit(state),
    }))

    useEffect(() => {
        if (propertyService && propertyType) {
            switch (parseInt(propertyType)) {
                case REAL_ESTATE_TYPES.flat:
                    if (propertyService === REAL_ESTATE_SERVICE.sale) {
                        setViewData(propertyFlatSaleViewData(property, appSettings))
                    } else if (REAL_ESTATE_SERVICE.lease) {
                        setViewData(propertyFlatLeaseViewData(property, appSettings))
                    }
                    break
                case REAL_ESTATE_TYPES.house:
                    if (propertyService === REAL_ESTATE_SERVICE.sale) {
                        setViewData(propertyHouseSaleViewData(property, appSettings))
                    } else if (REAL_ESTATE_SERVICE.lease) {
                        setViewData(propertyHouseLeaseViewData(property, appSettings))
                    }
                    break
                case REAL_ESTATE_TYPES.officeSpace:
                    if (propertyService === REAL_ESTATE_SERVICE.sale) {
                        setViewData(propertyOfficeSpaceSaleViewData(property, appSettings))
                    } else if (REAL_ESTATE_SERVICE.lease) {
                        setViewData(propertyOfficeSpaceLeaseViewData(property, appSettings))
                    }
                    break
                case REAL_ESTATE_TYPES.land:
                    if (propertyService === REAL_ESTATE_SERVICE.sale) {
                        setViewData(propertyLandSaleViewData(property, appSettings))
                    } else if (REAL_ESTATE_SERVICE.lease) {
                        setViewData(propertyLandLeaseViewData(property, appSettings))
                    }
                    break
                case REAL_ESTATE_TYPES.garage:
                    if (propertyService === REAL_ESTATE_SERVICE.sale) {
                        setViewData(propertyGarageSaleViewData(property, appSettings))
                    } else if (REAL_ESTATE_SERVICE.lease) {
                        setViewData(propertyGarageLeaseViewData(property, appSettings))
                    }
                    break
                default:
                    break
            }
        }
    }, [propertyType, propertyService, property])

    return viewData
}
