import i18next from 'i18next'
import { useEffect, useState } from 'react'

export const useProjectViewTabs = () => {
    const [tabs, setTabs] = useState([])
    const { t } = i18next

    useEffect(() => {
        setTabs([
            { id: 1, name: 'about', label: t('view.project.tab.about') },
            { id: 8, name: 'notes', label: t('app.common.notes') },
            { id: 6, name: 'documents', label: t('app.common.documents') },
            { id: 7, name: 'relatedProperties', label: t('app.common.relatedProperties') },
        ])
    }, [t])
    return tabs
}
