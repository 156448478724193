import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import { fetchLocationsAvailableEntitiesSuccess, fetchLocationsWiderAreaSuccess, fetchLocationsCitySuccess, fetchLocationsCountrySuccess, fetchLocationsCountySuccess, fetchLocationsQuarterSuccess, fetchLocationsIslandSuccess } from './actions';


export const initialState = {
  availableEntities: {},
  country: {},
  county: {},
  city: {},
  widerarea: {},
  quarter: {},
  island: {}
};

export default handleActions(
  {
    [fetchLocationsAvailableEntitiesSuccess]: (state, { payload }) => ({
      ...state,
      availableEntities: payload.reduce(
        (dict, item) => ({
          ...dict,
          [item]: item
        }),
        {}
      )
    }),
    [fetchLocationsCountrySuccess]: (state, { payload }) => {
      return {
      ...state,
      country: payload.reduce(
        (dict, item) => ({
          ...dict,
          [item.id]: item
        }),
        {}
      )
    }},
    [fetchLocationsCountySuccess]: (state, { payload }) => {
      return {
      ...state,
      county: payload.reduce(
        (dict, item) => ({
          ...dict,
          [item.id]: item
        }),
        {}
      )
    }},
    [fetchLocationsCitySuccess]: (state, { payload }) => {
      return {
      ...state,
      city: payload.reduce(
        (dict, item) => ({
          ...dict,
          [item.id]: item
        }),
        {}
      )
    }},
    [fetchLocationsWiderAreaSuccess]: (state, { payload }) => {
      return {
      ...state,
      widerarea: payload.reduce(
        (dict, item) => ({
          ...dict,
          [item.id]: item
        }),
        {}
      )
    }},
    [fetchLocationsQuarterSuccess]: (state, { payload }) => {
      return {
      ...state,
      quarter: payload.reduce(
        (dict, item) => ({
          ...dict,
          [item.id]: item
        }),
        {}
      )
    }},
    [fetchLocationsIslandSuccess]: (state, { payload }) => {
      return {
      ...state,
      island: payload.reduce(
        (dict, item) => ({
          ...dict,
          [item.id]: item
        }),
        {}
      )
    }}
  },
  initialState
);
