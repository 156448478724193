import { createAction } from 'redux-actions';

const ENTITY = 'NOTES';
export const stateKey = ENTITY.toLowerCase();

export const fetchNotes = createAction(`${ENTITY}/FETCH`);
export const fetchNotesRequest = createAction(`${ENTITY}/FETCH_REQUEST`);
export const fetchNotesSuccess = createAction(`${ENTITY}/FETCH_SUCCESS`);
export const fetchNotesFailure = createAction(`${ENTITY}/FETCH_FAILURE`);

export const fetchContact = createAction(`${ENTITY}/DETAILS/FETCH`);
export const fetchNoteRequest = createAction(`${ENTITY}/DETAILS/FETCH_REQUEST`);
export const fetchNoteSuccess = createAction(`${ENTITY}/DETAILS/FETCH_SUCCESS`);
export const fetchNoteFailure = createAction(`${ENTITY}/DETAILS/FETCH_FAILURE`);

export const createNote = createAction(
    `${ENTITY}/CREATE`,
    values => values,
    (values, callback) => ({ callback })
);
// export const createContact = createAction(`${ENTITY}/CREATE`);
export const createNoteRequest = createAction(`${ENTITY}/CREATE_REQUEST`);
export const createNoteSuccess = createAction(`${ENTITY}/CREATE_SUCCESS`);
export const createNoteFailure = createAction(`${ENTITY}/CREATE_FAILURE`);

export const updateNote = createAction(
    `${ENTITY}/UPDATE`,
    values => values,
    (values, callback) => ({ callback })
);
// export const createContact = createAction(`${ENTITY}/CREATE`);
export const updateNoteRequest = createAction(`${ENTITY}/UPDATE_REQUEST`);
export const updateNoteSuccess = createAction(`${ENTITY}/UPDATE_SUCCESS`);
export const updateNoteFailure = createAction(`${ENTITY}/UPDATE_FAILURE`);

export const deleteNote = createAction(
    `${ENTITY}/DELETE`,
    values => values,
    (values, callback) => ({ callback })
);
// export const deleteNote = createAction(`${ENTITY}/DELETE`);
  export const deleteNoteRequest = createAction(`${ENTITY}/DELTE_REQUEST`);
  export const deleteNoteSuccess = createAction(`${ENTITY}/DELTE_SUCCESS`);
  export const deleteNoteFailure = createAction(`${ENTITY}/DELETE_FAILURE`);

  export const clearNotes = createAction(`${ENTITY}/CLEAR`);