export const removeSpecialChar = (string) => {
    return string?.toLowerCase()?.replace('č', 'c')?.replace('ć', 'c')?.replace('ž', 'z')?.replace('š', 's')?.replace('đ', 'd')
}

export const cleanObject = (obj) => {
    for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined) {
            delete obj[propName]
        }
    }
    return obj
}
