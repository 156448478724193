import styles from './TableImage.module.css'

const TableImage = (props) => {
    const { src, alt } = props

    return (
        <div className={styles.cellWrapper}>
            <div className={styles.photoCell}>
                <img src={src} alt={alt} />
            </div>
        </div>
    )
}

export default TableImage
