import values from 'lodash/values';

import { stateKey } from './actions';

export const selectActivityValuesType = state => values(state[stateKey].type);

export const selectOmittedActivityValuesType = state => {
    const activityTypes = selectActivityValuesType(state);
    return activityTypes.filter(({ id }) => id !== 10);
};

export const selectActivityValuesTypeFormOptions = state => {
    const data = values(state[stateKey].type);
    return data.map((service) => (
        {
            value: service.id.toString(),
            label: service.name
        }
    ))
}

export const selectActivityValuesTypeFetching = state => state[stateKey].typefetching