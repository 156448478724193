import classnames from 'classnames'
import { useLocation } from 'react-router-dom'
import { Nav, NavItem, NavLink } from 'reactstrap'
import AppTopSwitcher from '../AppTopSwitcher/AppTopSwitcher'

const FormTabNavigation = ({ tabs, activeTab, handleTabChange, formik, customClass = 'real-estate-form-tabs', children }) => {
    const location = useLocation()

    return (
        <Nav tabs className={`nav-tabs ${customClass}`}>
            {tabs?.map((tab) => (
                <AppTopSwitcher key={tab.id} feature="AllowedTabs" permission="AllowedTabs" module={location}>
                    {(props) => {
                        const { featureTabs, permissionTabs } = props
                        const { permissions, documents: featureDocuments } = featureTabs
                        const { map, relatedProperties, export: exportTab, documents: permissionDocuments } = permissionTabs

                        const tabsToHide = [featureDocuments, permissionDocuments, map, relatedProperties, exportTab, permissions]

                        const tabDescriptionError = handleProjectDescriptionError(formik, tab)

                        return (
                            <NavItem className={classnames({ active: tab.name === activeTab, 'd-none': tabsToHide.includes(tab.name) })}>
                                <NavLink
                                    style={{ cursor: 'pointer' }}
                                    className={classnames(
                                        { active: tab.name === activeTab, 'text-danger': tabDescriptionError },
                                        'real-estate-form-nav-link'
                                    )}
                                    onClick={() => handleTabChange(tab.name)}
                                    disabled={tab.disabled}
                                >
                                    {tab.label}
                                </NavLink>
                            </NavItem>
                        )
                    }}
                </AppTopSwitcher>
            ))}
            {children && children}
        </Nav>
    )
}

export default FormTabNavigation

const handleProjectDescriptionError = (formik, tab) => {
    const isDescriptionError = formik?.errors && Object.keys(formik.errors).every((error) => error === 'description')
    const projectTitleObj = formik?.values?.description?.project_title
    const isProjectTitle = projectTitleObj && Object.keys(projectTitleObj).some((key) => projectTitleObj[key] !== '')
    const error = tab.name === 'description' && formik?.submitCount > 0 && isDescriptionError && !isProjectTitle

    return error
}
