import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { getImagesById } from './functions'
import { Image } from './types'

const images = {
    GetImages: {
        useQuery: (id: number, module: string, collection: string, refreshToken: string, options?: UseQueryOptions<Image, AxiosError>) =>
            useQuery({
                queryKey: [id, module, collection, refreshToken],
                queryFn: () => getImagesById(id, module, collection),
                staleTime: 5 * 60 * 1000,
                ...options,
            }),
    },
}

export default images
