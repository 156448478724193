import { mapFieldTypes } from "./fieldTypes";
import { mapFields } from "./fields";

export const mapInputs = () => {
    const inputs = mapFieldTypes();
    return {
        sideForm: [
            inputs[mapFields.MAP_LATITUDE],
            inputs[mapFields.MAP_LONGITUDE],
            {
                type: 'divider'
            },
            inputs[mapFields.MAP_LOCATION_SYNC_TYPE],
            {
                type: 'divider'
            },
            inputs[mapFields.MAP_LOCATION_SYNC]
        ],
        mainForm: []
    }
}