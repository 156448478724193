import { Fragment, useState } from 'react'
import { Col, Input, Label, UncontrolledTooltip } from 'reactstrap'

const CheckBox = (props) => {
    const { inputValue, inputField, fieldName, isFieldArray, inputMargin, index, inputsLength, fieldArrayDeleteComponent, formik, error, touched } =
        props
    const [checkBoxValue, setCheckBoxValue] = useState(inputValue)

    return (
        <Fragment>
            <Col
                key={inputField.name}
                {...inputField.colProps}
                className={`${inputField.colProps.className ?? ''} ${!isFieldArray ? inputMargin : 'mb-2'} d-flex align-items-center`}
            >
                <div className="form-check">
                    <Input
                        type="checkbox"
                        className="form-check-input"
                        id={inputField.name}
                        name={fieldName.name}
                        onChange={() => {
                            const newValue = inputValue ? 0 : 1
                            setCheckBoxValue(newValue)
                            formik?.setFieldValue(fieldName, newValue)
                        }}
                        onBlur={formik?.handleBlur}
                        value={checkBoxValue}
                        invalid={(error && touched) ?? false}
                        {...inputField?.inputProps}
                        checked={inputValue || 0}
                        {...inputField?.inputProps}
                    />
                    <Label className="form-check-label" for={inputField.name}>
                        {inputField.label}
                        {inputField.required && <span className="required-mark">*</span>}
                    </Label>
                    {inputField.tooltip && (
                        <span id={`${inputField.name}Tooltip`}>
                            {' '}
                            <i className="mdi mdi-help-circle-outline"></i>{' '}
                        </span>
                    )}
                </div>
                {inputField.tooltip && (
                    <UncontrolledTooltip pement="right" target={`${inputField.name}Tooltip`}>
                        {inputField.tooltip}
                    </UncontrolledTooltip>
                )}
            </Col>
            {isFieldArray && index === inputsLength && fieldArrayDeleteComponent}
        </Fragment>
    )
}

export default CheckBox
