import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, useLocation } from 'react-router-dom'
import { selectIsAuthenticated } from '../store/auth'

import AppFeaturesSwitcher from '../Components/AppFeaturesSwitcher/AppFeaturesSwitcher'

const AuthProtected = (props) => {
    const location = useLocation()
    const { isAuthenticated } = useSelector((state) => ({
        isAuthenticated: selectIsAuthenticated(state),
    }))

    if (!isAuthenticated) {
        return <Redirect to={`/login?continue=${encodeURIComponent([location?.pathname, location?.search].join(''))}`} />
    }

    return <React.Fragment>{props.children}</React.Fragment>
}

const AccessRoute = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) => {
                return (
                    <AppFeaturesSwitcher link={rest.path} feature="Navigation">
                        {({ isLinkAvailable }) => (isLinkAvailable ? <Component {...props} /> : <Redirect to="/projects" />)}
                    </AppFeaturesSwitcher>
                )
            }}
        />
    )
}

export { AccessRoute, AuthProtected }
