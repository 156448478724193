import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Card, CardBody, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from 'reactstrap'
import SimpleBar from 'simplebar-react'
import DeleteModal from '../../Components/Common/DeleteModal'
import TableContainer from '../../Components/Table/TableContainer'

import {
    deleteDocument,
    deleteDocumentMultiple,
    fetchDocuments,
    selectDocuments,
    selectDocumentsCount,
    selectDocumentsFetching,
    syncDocument,
} from '../../store/documents'
import { resetTableCheckbox } from '../../store/table'
import { selectCheckedTableRows } from '../../store/table/selectors'
import FormMainContainer from '../Common/FormMainContainer'
import FormSideContainer from '../Common/FormSideContainer'
import DocumentForm from '../DocumentForm'
import useDocumentsHeaders from '../DocumentViewTab/hooks/useDocumentsHeaders'
import { useFormatTableData } from '../Table/utils'
import DocumentFormSidebar from './DocumentFormSidebar'

const DocumentFormTab = ({ documentModule, parentId, tabs = [], activeTab, handleTabChange, contactType }) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const [documentData, setDocumentData] = useState({})
    const [pageCount, setPageCount] = useState(0)
    const [deleteModal, setDeleteModal] = useState(false)
    const [deleteModalMulti, setDeleteModalMulti] = useState(false)
    const [rowId, setRowId] = useState(null)
    const [sidebarData, setSidebarData] = useState('')
    const [sidebarEditMode, setSidebarEditMode] = useState(false)

    const { documents, fetching, documentCount, selectedTableRows } = useSelector((state) => ({
        documents: selectDocuments(state),
        fetching: selectDocumentsFetching(state),
        documentCount: selectDocumentsCount(state),
        selectedTableRows: selectCheckedTableRows(state),
    }))

    useEffect(() => {
        switch (documentModule) {
            case 'property':
                setDocumentData({ documentPropertyId: parentId, documentModule: documentModule })
                break
            case 'project':
                setDocumentData({ documentProjectId: parentId, documentModule: documentModule })
                break
            case 'contact':
                setDocumentData({ documentContactId: parentId, documentModule: documentModule })
                break
            default:
                break
        }
    }, [documentModule])

    function sidebarClose(value) {
        const element = document.getElementsByTagName('body')[0]
        element.classList.remove(value)
        setSidebarEditMode(false)
        setSidebarData('')
    }

    useEffect(() => {
        return () => {
            sidebarClose('file-detail-show')
        }
    }, [])

    const handleDataFetch = (page, limit) => {
        dispatch(
            fetchDocuments(
                {
                    ...(parentId ? { parentId: parentId } : {}),
                    page,
                    limit,
                    order: {},
                    criteria: {
                        module: documentModule,
                        collection: 'document',
                    },
                },
                (count) => {
                    setPageCount(Math.ceil(count / limit))
                }
            )
        )
    }

    const onClickDelete = (id) => {
        setRowId(id)
        setDeleteModal(true)
    }

    const handleDeleteDocument = () => {
        dispatch(deleteDocument(rowId))
        dispatch(resetTableCheckbox(true))
        setDeleteModal(false)
    }

    const deleteMultipleDocuments = () => {
        const selectedRowsIds = selectedTableRows.map((row) => row.id)

        dispatch(deleteDocumentMultiple(selectedRowsIds))
        setDeleteModalMulti(false)
        dispatch(resetTableCheckbox(true))
    }

    const tableData = useFormatTableData(documents, 'documents')
    const columns = useDocumentsHeaders(documentModule, dispatch, syncDocument, setSidebarData, setSidebarEditMode, onClickDelete, 'documents')

    const tableActions = () => (
        <div className="d-flex flex-grow-1">
            <UncontrolledDropdown className="ms-2">
                <DropdownToggle
                    title={t('table.showMore')}
                    href="#"
                    className="btn btn-primary dropdown"
                    tag="button"
                    onClick={(e) => e.preventDefault()}
                >
                    <i className="mdi mdi-dots-horizontal align-middle"></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem
                        onClick={() => {
                            setDeleteModalMulti(true)
                        }}
                    >
                        <i className="mdi mdi-trash-can-outline align-bottom me-2 text-muted"></i> {t('table.action.deleteSelected')}
                    </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
        </div>
    )

    return (
        <>
            <Row>
                <FormSideContainer>
                    <Card>
                        <CardBody>
                            <DocumentForm
                                hasUpload={true}
                                isEdit={false}
                                documentData={documentData}
                                contactType={contactType}
                                documentModule={documentModule}
                                parentId={parentId}
                                activeTab={activeTab}
                            />
                        </CardBody>
                    </Card>
                </FormSideContainer>

                <FormMainContainer tabs={tabs} activeTab={activeTab} handleTabChange={handleTabChange}>
                    <DeleteModal show={deleteModal} onDeleteClick={() => handleDeleteDocument()} onCloseClick={() => setDeleteModal(false)} />
                    <DeleteModal
                        show={deleteModalMulti}
                        onDeleteClick={() => {
                            deleteMultipleDocuments()
                        }}
                        onCloseClick={() => setDeleteModalMulti(false)}
                    />
                    <div className="chat-wrapper d-lg-flex gap-1 p-0">
                        <div className={classNames('file-manager-content w-100 px-0 py-0', { 'pe-2': sidebarData })}>
                            <SimpleBar className="overflow-hidden">
                                <TableContainer
                                    columns={columns}
                                    tableActions={tableActions}
                                    data={tableData || []}
                                    isGlobalFilter={false}
                                    isAddUserList={false}
                                    isLoading={fetching}
                                    customPageSize={20}
                                    tableClass="dmn-table--property-documents"
                                    theadClass={'dmn-table-header'}
                                    totalCount={documentCount}
                                    pageCount={pageCount}
                                    responsive={false}
                                    handleDataFetch={handleDataFetch}
                                />
                            </SimpleBar>
                        </div>
                        <DocumentFormSidebar
                            sidebarData={sidebarData}
                            sidebarClose={sidebarClose}
                            onClickDelete={onClickDelete}
                            isEdit={sidebarEditMode}
                            contactType={contactType}
                            documentModule={documentModule}
                            parentId={parentId}
                        />
                    </div>
                </FormMainContainer>
            </Row>
        </>
    )
}

export default DocumentFormTab
