import { getIn } from 'formik'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DETAIL_SEARCH_TYPE, REAL_ESTATE_TYPES } from '../common/constants'
import {
    fetchDetailSearchLocationsCity,
    fetchDetailSearchLocationsCounty,
    fetchDetailSearchLocationsIsland,
    fetchDetailSearchLocationsQuarter,
    fetchDetailSearchLocationsWiderArea,
    fetchDetailSearchPropertySubtypes,
    fetchDetailSearchPropertyValuesFloor,
} from '../store/searchValues'
import {
    selectSettingsPropertyFlatSubTypes,
    selectSettingsPropertyGarageSubTypes,
    selectSettingsPropertyHouseSubTypes,
    selectSettingsPropertyLandSubTypes,
    selectSettingsPropertyOfficeSpaceSubTypes,
} from '../store/settings'

export const useDetailSearchPropertyEffect = (type, validation, inputs) => {
    const dispatch = useDispatch()
    const { propertyFlatSubTypes, propertyGarageSubTypes, propertyHouseSubTypes, propertyOfficeSpaceSubTypes, propertyLandSubTypes } = useSelector(
        (state) => {
            return {
                propertyFlatSubTypes: selectSettingsPropertyFlatSubTypes(state),
                propertyGarageSubTypes: selectSettingsPropertyGarageSubTypes(state),
                propertyHouseSubTypes: selectSettingsPropertyHouseSubTypes(state),
                propertyLandSubTypes: selectSettingsPropertyLandSubTypes(state),
                propertyOfficeSpaceSubTypes: selectSettingsPropertyOfficeSpaceSubTypes(state),
            }
        }
    )

    const countryValue = getIn(validation?.values, 'country')
    const countyValue = getIn(validation?.values, 'county')
    const cityValue = getIn(validation?.values, 'city')
    const widerAreaValue = getIn(validation?.values, 'widerArea')
    const propertyTypeValue = getIn(validation?.values, 'propertyType')
    const propertySubTypeValue = getIn(validation?.values, 'propertySubType')
    const floorToValue = getIn(validation?.values, 'floorNoTo')
    const floorFromValue = getIn(validation?.values, 'floorNoFrom')
    const webValue = getIn(validation?.values, 'propertyWeb')
    const propertyFloorValue = getIn(validation?.values, 'propertyFloor')
    const propertyStatus = getIn(validation?.values, 'propertyStatus')

    const countyIndex = inputs.mainForm?.[1]?.children?.findIndex((obj) => obj.name === 'county')
    const islandIndex = inputs.mainForm?.[1]?.children?.findIndex((obj) => obj.name === 'island')
    const cityIndex = inputs.mainForm?.[1]?.children?.findIndex((obj) => obj.name === 'city')
    const widerAreaIndex = inputs.mainForm?.[1]?.children?.findIndex((obj) => obj.name === 'widerArea')
    const quarterIndex = inputs.mainForm?.[1]?.children?.findIndex((obj) => obj.name === 'quarter')
    const floorGroupIndex = inputs.mainForm?.[1]?.children?.findIndex((obj) => obj.name === 'floorNoGrouped')
    const attributeIndex = inputs.mainForm?.[1]?.children?.findIndex((obj) => obj.name === 'attribute')
    const propertyFloorIndex = inputs.mainForm?.[1]?.children?.findIndex((obj) => obj.name === 'propertyFloor')

    useEffect(() => {
        if (type === DETAIL_SEARCH_TYPE.PROPERTY) {
            validation.setValues({})
            validation.setFieldValue(`propertyStatus`, propertyStatus)
        }
    }, [type, propertyStatus])

    useEffect(() => {
        if (type === DETAIL_SEARCH_TYPE.PROPERTY) {
            if (countryValue) {
                dispatch(fetchDetailSearchLocationsCounty(countryValue))
                dispatch(fetchDetailSearchLocationsIsland(countryValue))
                if (inputs.mainForm) {
                    if (countyIndex > -1) {
                        inputs.mainForm[1].children[countyIndex].disabled = false
                    }
                    if (islandIndex > -1) {
                        inputs.mainForm[1].children[islandIndex].disabled = false
                    }
                }
            } else {
                validation.setFieldValue(`county`, [])
                validation.setFieldValue('island', [])
                if (inputs.mainForm) {
                    if (countyIndex > -1) {
                        inputs.mainForm[1].children[countyIndex].disabled = true
                    }
                    if (islandIndex > -1) {
                        inputs.mainForm[1].children[islandIndex].disabled = true
                    }
                }
            }
        }
    }, [type, countryValue])

    useEffect(() => {
        if (type === DETAIL_SEARCH_TYPE.PROPERTY) {
            if (countyValue && countyValue.length) {
                dispatch(fetchDetailSearchLocationsCity(countyValue))
                if (inputs.mainForm) {
                    if (cityIndex > -1) {
                        inputs.mainForm[1].children[cityIndex].disabled = false
                    }
                }
            } else {
                validation.setFieldValue(`city`, [])
                if (inputs.mainForm) {
                    if (cityIndex > -1) {
                        inputs.mainForm[1].children[cityIndex].disabled = true
                    }
                }
            }
        }
    }, [type, countyValue])

    useEffect(() => {
        if (type === DETAIL_SEARCH_TYPE.PROPERTY) {
            if (cityValue && cityValue.length) {
                dispatch(fetchDetailSearchLocationsWiderArea(cityValue))
                if (inputs.mainForm) {
                    if (widerAreaIndex > -1) {
                        inputs.mainForm[1].children[widerAreaIndex].disabled = false
                    }
                }
            } else {
                validation.setFieldValue(`widerArea`, [])
                if (inputs.mainForm) {
                    if (widerAreaIndex > -1) {
                        inputs.mainForm[1].children[widerAreaIndex].disabled = true
                    }
                }
            }
        }
    }, [type, cityValue])

    useEffect(() => {
        if (type === DETAIL_SEARCH_TYPE.PROPERTY) {
            if (widerAreaValue && widerAreaValue.length) {
                dispatch(fetchDetailSearchLocationsQuarter(widerAreaValue))
                if (inputs.mainForm) {
                    if (quarterIndex > -1) {
                        inputs.mainForm[1].children[quarterIndex].disabled = false
                    }
                }
            } else {
                validation.setFieldValue(`quarter`, [])
                if (inputs.mainForm) {
                    if (quarterIndex > -1) {
                        inputs.mainForm[1].children[quarterIndex].disabled = true
                    }
                }
            }
        }
    }, [type, widerAreaValue])

    useEffect(() => {
        if (type === DETAIL_SEARCH_TYPE.PROPERTY) {
            if (webValue) {
                if (inputs.mainForm) {
                    if (attributeIndex > -1) {
                        inputs.mainForm[1].children[attributeIndex].disabled = false
                    }
                }
            } else {
                validation.setFieldValue(`attribute`, [])
                if (inputs.mainForm) {
                    if (attributeIndex > -1) {
                        inputs.mainForm[1].children[attributeIndex].disabled = true
                    }
                }
            }
        }
    }, [type, webValue])

    useEffect(() => {
        if (type === DETAIL_SEARCH_TYPE.PROPERTY) {
            if (floorToValue || floorFromValue) {
                if (inputs.mainForm) {
                    if (floorGroupIndex > -1) {
                        if (propertyTypeValue?.some((x) => [REAL_ESTATE_TYPES.house.toString(), REAL_ESTATE_TYPES.land.toString()].includes(x))) {
                            inputs.mainForm[1].children[floorGroupIndex].warning = 'Odabrani tip nekretnine nije moguće pretražiti po ovom podatku.'
                        } else {
                            if (propertySubTypeValue && propertySubTypeValue.length) {
                                let hasHouseSubType = propertyHouseSubTypes?.some((item) => {
                                    return propertySubTypeValue.includes(item.id.toString())
                                })

                                let hasLandSubType = propertyLandSubTypes?.some((item) => {
                                    return propertySubTypeValue.includes(item.id.toString())
                                })

                                if (hasHouseSubType || hasLandSubType) {
                                    inputs.mainForm[1].children[floorGroupIndex].warning =
                                        'Odabrani tip nekretnine nije moguće pretražiti po ovom podatku.'
                                } else {
                                    inputs.mainForm[1].children[floorGroupIndex].warning = null
                                }
                            } else {
                                inputs.mainForm[1].children[floorGroupIndex].warning = null
                            }
                        }
                    }
                }
            } else {
                if (inputs.mainForm) {
                    if (floorGroupIndex > -1) {
                        inputs.mainForm[1].children[floorGroupIndex].warning = null
                    }
                }
            }
        }
    }, [type, floorFromValue, floorToValue])

    useEffect(() => {
        if (type === DETAIL_SEARCH_TYPE.PROPERTY) {
            if (propertyFloorValue && propertyFloorValue.length) {
                if (inputs.mainForm) {
                    if (propertyFloorIndex > -1) {
                        if (
                            propertyTypeValue?.some((x) =>
                                [
                                    REAL_ESTATE_TYPES.garage.toString(),
                                    REAL_ESTATE_TYPES.land.toString(),
                                    REAL_ESTATE_TYPES.officeSpace.toString(),
                                ].includes(x)
                            )
                        ) {
                            inputs.mainForm[1].children[propertyFloorIndex].warning =
                                'Odabrani tip nekretnine nije moguće pretražiti po ovom podatku.'
                        } else {
                            inputs.mainForm[1].children[propertyFloorIndex].warning = null
                        }
                    } else {
                        if (propertySubTypeValue && propertySubTypeValue.length) {
                            let hasOfficeSpaceSubType = propertyOfficeSpaceSubTypes?.some((item) => {
                                return propertySubTypeValue.includes(item.id.toString())
                            })

                            let hasLandSubType = propertyLandSubTypes?.some((item) => {
                                return propertySubTypeValue.includes(item.id.toString())
                            })

                            let hasGarageSubType = propertyGarageSubTypes?.some((item) => {
                                return propertySubTypeValue.includes(item.id.toString())
                            })

                            if (hasOfficeSpaceSubType || hasLandSubType || hasGarageSubType) {
                                inputs.mainForm[1].children[propertyFloorIndex].warning =
                                    'Odabrani tip nekretnine nije moguće pretražiti po ovom podatku.'
                            } else {
                                inputs.mainForm[1].children[propertyFloorIndex].warning = null
                            }
                        } else {
                            inputs.mainForm[1].children[propertyFloorIndex].warning = null
                        }
                    }
                }
            } else {
                if (inputs.mainForm) {
                    if (propertyFloorIndex > -1) {
                        inputs.mainForm[1].children[propertyFloorIndex].warning = null
                    }
                }
            }
        }
    }, [type, propertyFloorValue])

    useEffect(() => {
        if (type === DETAIL_SEARCH_TYPE.PROPERTY) {
            if (propertyTypeValue && propertyTypeValue.length) {
                const filter = {
                    propertyType: propertyTypeValue,
                }
                dispatch(
                    fetchDetailSearchPropertySubtypes({
                        filter,
                    })
                )
                dispatch(
                    fetchDetailSearchPropertyValuesFloor({
                        filter,
                    })
                )
                validation.setFieldValue(`propertySubType`, [])
                validation.setFieldValue(`propertyFloor`, [])

                if (inputs.mainForm) {
                    if (floorGroupIndex > -1) {
                        if (
                            !propertyTypeValue.some((x) => [REAL_ESTATE_TYPES.house.toString(), REAL_ESTATE_TYPES.land.toString()].includes(x)) &&
                            inputs.mainForm[1].children[floorGroupIndex].warning
                        ) {
                            inputs.mainForm[1].children[floorGroupIndex].warning = null
                        } else {
                            if (floorToValue || floorFromValue) {
                                inputs.mainForm[1].children[floorGroupIndex].warning =
                                    'Odabrani tip nekretnine nije moguće pretražiti po ovom podatku.'
                            }
                        }
                    }

                    if (propertyFloorIndex > -1) {
                        if (
                            propertyTypeValue.some((x) =>
                                [
                                    REAL_ESTATE_TYPES.garage.toString(),
                                    REAL_ESTATE_TYPES.land.toString(),
                                    REAL_ESTATE_TYPES.officeSpace.toString(),
                                ].includes(x)
                            ) &&
                            inputs.mainForm[1].children[floorGroupIndex].warning
                        ) {
                            inputs.mainForm[1].children[propertyFloorIndex].warning = null
                        } else {
                            if (propertyFloorValue && propertyFloorValue.length) {
                                inputs.mainForm[1].children[propertyFloorIndex].warning =
                                    'Odabrani tip nekretnine nije moguće pretražiti po ovom podatku.'
                            }
                        }
                    }
                }
            } else {
                validation.setFieldValue(`propertySubType`, [])
                validation.setFieldValue(`propertyFloor`, [])
                if (inputs.mainForm) {
                    if (floorGroupIndex > -1) {
                        inputs.mainForm[1].children[floorGroupIndex].warning = null
                    }
                    if (propertyFloorIndex > -1) {
                        inputs.mainForm[1].children[propertyFloorIndex].warning = null
                    }
                }
            }
        }
    }, [type, propertyTypeValue])

    useEffect(() => {
        if (type === DETAIL_SEARCH_TYPE.PROPERTY) {
            if (propertySubTypeValue && propertySubTypeValue.length) {
                if (inputs.mainForm) {
                    if (floorGroupIndex > -1) {
                        if (propertyTypeValue && !propertyTypeValue.length) {
                            if (floorFromValue || floorToValue) {
                                let hasHouseSubType = propertyHouseSubTypes?.some((item) => {
                                    return propertySubTypeValue.includes(item.id.toString())
                                })

                                let hasLandSubType = propertyLandSubTypes?.some((item) => {
                                    return propertySubTypeValue.includes(item.id.toString())
                                })

                                if (hasHouseSubType || hasLandSubType) {
                                    inputs.mainForm[1].children[floorGroupIndex].warning =
                                        'Odabrani tip nekretnine nije moguće pretražiti po ovom podatku.'
                                } else {
                                    inputs.mainForm[1].children[floorGroupIndex].warning = null
                                }
                            } else {
                                inputs.mainForm[1].children[floorGroupIndex].warning = null
                            }
                        }
                    }

                    if (propertyFloorIndex > -1) {
                        if (propertyTypeValue && !propertyTypeValue.length) {
                            if (propertyFloorValue && propertyFloorValue.length) {
                                let hasOfficeSpaceSubType = propertyOfficeSpaceSubTypes?.some((item) => {
                                    return propertySubTypeValue.includes(item.id.toString())
                                })

                                let hasLandSubType = propertyLandSubTypes?.some((item) => {
                                    return propertySubTypeValue.includes(item.id.toString())
                                })

                                let hasGarageSubType = propertyGarageSubTypes?.some((item) => {
                                    return propertySubTypeValue.includes(item.id.toString())
                                })

                                if (hasOfficeSpaceSubType || hasLandSubType || hasGarageSubType) {
                                    inputs.mainForm[1].children[propertyFloorIndex].warning =
                                        'Odabrani tip nekretnine nije moguće pretražiti po ovom podatku.'
                                } else {
                                    inputs.mainForm[1].children[propertyFloorIndex].warning = null
                                }
                            } else {
                                inputs.mainForm[1].children[propertyFloorIndex].warning = null
                            }
                        }
                    }
                }
            } else {
                if (inputs.mainForm) {
                    if (floorGroupIndex > -1) {
                        if (propertyTypeValue && !propertyTypeValue.length) {
                            inputs.mainForm[1].children[floorGroupIndex].warning = null
                        }

                        if (propertyFloorIndex > -1) {
                            inputs.mainForm[1].children[propertyFloorIndex].warning = null
                        }
                    }
                }
            }
        }
    }, [type, propertySubTypeValue])
}
