import { Link } from 'react-router-dom'
import { formatNumberWithTwoDecimals, formatDateTime } from '../../../../utils/general'
import { renderAreaUnit, renderCurrency } from '../../../../utils/property'
import { Badge } from 'reactstrap'

const SearchResultItems = (props) => {
    const { id, linkName, updated, contactCompany, contactPerson, projectTitle, price, currency, area, areaUnit, propertyType, propertyStatus } =
        props
    let name = ''
    const link = `/${linkName}/${id}`
    const updatedRender = updated

    switch (linkName) {
        case 'companies':
            name = contactCompany.name
            break
        case 'contacts':
            name = `${contactPerson?.firstName} ${contactPerson?.lastName}`
            break
        case 'projects': {
            name = projectTitle ? `ID${id}, ${projectTitle}` : `ID${id}`
            break
        }
        case 'properties': {
            const priceRender = price ? `${formatNumberWithTwoDecimals(price)}${renderCurrency(currency, false)},` : ''
            const areaRender = area && `${area} ${renderAreaUnit(areaUnit)}`
            name = `ID${id}, ${propertyType?.name}, ${priceRender} ${areaRender}`
            break
        }
        default:
            break
    }

    return (
        <Link to={link} className="dropdown-item notify-item py-2">
            <div className="d-flex flex-wrap">
                <h6 className="d-inline-block fs-13 fw-light m-0">{name} </h6>
                <span className="fs-11 mb-0 text-secondary fw-light"> , ({formatDateTime(updatedRender)})</span>
                <Badge color="favorite" className="fs-12 fw-medium ms-2">
                    {propertyStatus?.name}
                </Badge>
            </div>
        </Link>
    )
}

export default SearchResultItems
