import { Label, FormFeedback, UncontrolledTooltip } from 'reactstrap'
import _ from 'lodash'
import Cleave from 'cleave.js/react'
import classNames from 'classnames'

const InputNumber = (props) => {
    const {
        id,
        label,
        className,
        onChange,
        defaultValue,
        formikError,
        formikTouched,
        maxLength,
        isRequired = false,
        isFormated = false,
        permission,
    } = props

    let error = _.get(formikError, `${id}`)
    let touched = _.get(formikTouched, `${id}`)

    const options = {
        base: {
            numeral: true,
            numeralDecimalMark: '',
            delimiter: '',
            numeralPositiveOnly: true,
        },
        format: {
            numeral: true,
            numeralDecimalMark: ',',
            delimiter: '.',
            numeralPositiveOnly: true,
        },
    }

    return (
        <div className={className}>
            <Label>{label}</Label>
            {isRequired && <span className="required-mark">*</span>}
            {permission && (
                <>
                    {' '}
                    <span id={`agentPermissionsTooltip${permission.id}`}>
                        <i className="mdi mdi-help-circle-outline"></i>{' '}
                    </span>
                    <UncontrolledTooltip pement="right" target={`agentPermissionsTooltip${permission.id}`}>
                        {permission.description}
                    </UncontrolledTooltip>
                </>
            )}
            <Cleave
                id={id}
                onChange={onChange}
                value={defaultValue}
                maxLength={maxLength ?? ``}
                options={isFormated ? options.format : options.base}
                className={classNames('form-control', {
                    'is-invalid': error && touched,
                })}
            />
            {error && touched ? <FormFeedback type="invalid">{error?.split(';')[0]}</FormFeedback> : null}
        </div>
    )
}

export default InputNumber
