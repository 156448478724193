import { apiReq } from '..'
import { getFirstDigit } from '../../helpers/number_helper'

export const getNotesById = async (id: number, noteEntity: string) => {
    const response = await apiReq(`v1/${noteEntity}-note/parent/${id}?noLimit=1`)
    return response?.data.items
}

export const createUpdateNote = async (data: any, isEdit?: boolean) => {
    const url = isEdit ? `v1/${data.noteEntity}-note/${data.id}` : `v1/${data.noteEntity}-note`

    const response = await apiReq(url, {
        method: isEdit ? 'PUT' : 'POST',
        data: data,
    })

    const isError = getFirstDigit(response.data.status)
    if (isError === 4 || isError === 5) throw new Error(response.data.title)

    return response?.data
}

export const deleteNoteById = async (noteId: any, noteEntity: string) => {
    const response = await apiReq(`v1/${noteEntity}-note/${noteId}`, {
        method: 'DELETE',
    })

    const isError = getFirstDigit(response.data.status)
    if (isError === 4 || isError === 5) throw new Error(response.data.title)

    return response?.data
}
