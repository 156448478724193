import PropTypes from "prop-types";
import React from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import { useTranslation } from 'react-i18next'

const ErrorModal = ({ show, onDeleteClick, onCloseClick, title, text, ...props }) => {
    const { t } = useTranslation()
    return (
        <Modal isOpen={show} toggle={onCloseClick}>
            <ModalBody className="py-3 px-5">
                <div className="mt-2 text-center">
                    <lord-icon
                        src="https://cdn.lordicon.com/gsqxdxog.json"
                        trigger="loop"
                        colors="primary:#f7b84b,secondary:#f06548"
                        style={{ width: "100px", height: "100px" }}
                    ></lord-icon>
                    <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                        <h4>{title}</h4>
                        {props.children ? (
                            props.children
                        ) : (
                            <p className="text-muted mx-4 mb-0">
                               {text}
                            </p>
                        )}

                    </div>
                </div>
                <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                    <Button
                        type="button"
                        color="primary"
                        data-bs-dismiss="modal"
                        onClick={onCloseClick}
                    >
                        {t('form.modal.error.button.ok')}
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    );
};

ErrorModal.propTypes = {
    onCloseClick: PropTypes.func,
    onDeleteClick: PropTypes.func,
    show: PropTypes.bool,
    title: PropTypes.string
};

export default ErrorModal;