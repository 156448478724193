import * as Yup from 'yup'
import i18n from 'i18next'

export const userValidation = () => {
    const errorMsg = i18n.t('validation.message')

    return {
        active: Yup.boolean(),
        firstName: Yup.string().nullable().required(`${errorMsg}; Ime`),
        lastName: Yup.string().nullable().required(`${errorMsg}; Prezime`),
        companyRole: Yup.string().nullable(),
        phoneNumber: Yup.string().nullable(),
        mobileNumber: Yup.string().nullable(),
        primaryEmail: Yup.string().nullable(),
        ccEmail: Yup.boolean().nullable(),
        nameSync: Yup.boolean().nullable(),
        phoneNumberSync: Yup.boolean().nullable(),
        mobileNumberSync: Yup.boolean().nullable(),
        primaryEmailSync: Yup.boolean().nullable(),
        photoSync: Yup.boolean().nullable(),
        agencySync: Yup.boolean().nullable(),
        agencyOfficeSync: Yup.boolean().nullable(),
        displayEmail: Yup.string()
            .nullable()
            .email(`${i18n.t('form.register.error.invalidEmail')}; Email za prikaz`),
    }
}
