import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { fetchOpportunityContact } from '../../../store/properties'
import { selectOpportunityContact, selectOpportunityContactFetching, selectOpportunityContactCount } from '../../../store/properties/selectors'
import TableContainer from '../../Table/TableContainer'
import TableColumns from './TableColumns/TableColumns'
import OpportunityDropDown from '../OpportunityDropDown/OpportunityDropDown'

const ContactTable = () => {
    const dispatch = useDispatch()
    const { id } = useParams()

    const { opportunityContact, fetching, opportunityContactCount } = useSelector((state) => ({
        opportunityContact: selectOpportunityContact(state),
        fetching: selectOpportunityContactFetching(state),
        opportunityContactCount: selectOpportunityContactCount(state),
    }))

    const handleDataFetch = (page, limit, order, criteria) => {
        dispatch(fetchOpportunityContact({ page, limit, order, criteria: { ...criteria, property: [id] } }))
    }

    const columnsContact = TableColumns()

    const tableActions = () => <OpportunityDropDown />

    return (
        <TableContainer
            tableActions={tableActions}
            columns={columnsContact}
            data={opportunityContact || []}
            isGlobalFilter={false}
            isAddUserList={false}
            isLoading={fetching}
            customPageSize={20}
            tableClass="dmn-table--property-documents"
            theadClass={'dmn-table-header'}
            totalCount={opportunityContactCount}
            pageCount={Math.ceil(opportunityContactCount / 20)}
            handleDataFetch={handleDataFetch}
            tableHash={'opportunityContactTable'}
        />
    )
}

export default ContactTable
