import { NavLink } from 'react-router-dom'
import style from './SideMenu.module.css'
import i18n from 'i18next'

const SideMenu = (props) => {
    const { className, showMenu, setShowMenu } = props

    const closeMenuOnMobile = () => {
        if (window.innerWidth <= 1024) {
            setShowMenu(false)
            document.querySelector('.hamburger-icon').classList.remove('open')
        }
    }

    return (
        <nav className={`${style.nav} ${className}`}>
            <div className={`${style.navMenu} ${showMenu ? style.showMenu : ''}`} id="nav-menu">
                <ul className={style.navList}>
                    <li>
                        <NavLink activeClassName={style.active} to="/admin/agency" className={style.navLink} onClick={closeMenuOnMobile}>
                            {i18n.t('admin.sideMenu.agency')}
                        </NavLink>
                    </li>
                    <li>
                        <NavLink activeClassName={style.active} to="/admin/office" className={style.navLink} onClick={closeMenuOnMobile}>
                            {i18n.t('admin.sideMenu.office')}
                        </NavLink>
                    </li>
                    <li>
                        <NavLink activeClassName={style.active} to="/admin/user" className={style.navLink} onClick={closeMenuOnMobile}>
                            {i18n.t('admin.sideMenu.user')}
                        </NavLink>
                    </li>
                </ul>
            </div>
        </nav>
    )
}

export default SideMenu
