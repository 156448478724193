import values from 'lodash/values';

import { stateKey } from './actions';

export const selectDocumentValuesAvailableEntitiesFormOptions = state => {
    const data = values(state[stateKey].availableentities);
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => (
        {
            value: service.id.toString(),
            label: service.name
        }
    ))
}

export const selectDocumentValuesTypeFormOptions = state => {
    const data = values(state[stateKey].type);
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => (
        {
            value: service.id.toString(),
            label: service.name
        }
    ))
}