import { useTranslation } from 'react-i18next'
import { Button, Col, Row } from 'reactstrap'
import SimpleBar from 'simplebar-react'
import { formatDateTime } from '../../utils/general'
import AppPermissionsSwitcher from '../AppPermissionsSwitcher/AppPermissionsSwitcher'
import { action } from '../AppPermissionsSwitcher/constants/actions'
import DocumentForm from '../DocumentForm'
import { API_URL } from '../../common/constants'

const DocumentFormSidebar = ({ sidebarData, isEdit, sidebarClose, onClickDelete, contactType, documentModule, parentId }) => {
    const { t } = useTranslation()
    const currentLang = localStorage.getItem('i18nextLng')
    const agentId = sidebarData?.agent?.id

    return (
        <div className="file-manager-detail-content" style={{ width: '500px' }}>
            <SimpleBar className="mx-n3 px-3 file-detail-content-scroll">
                <div id="file-overview" className="h-100">
                    <div className="d-flex h-100 flex-column">
                        <div className="d-flex align-items-center pb-3 border-bottom border-bottom-dashed mb-3 gap-2">
                            <h5 className="flex-grow-1 fw-light mb-0">
                                {isEdit ? t('form.documentFormSidebar.title.edit') : t('form.documentFormSidebar.title.view')}
                            </h5>
                            <div>
                                <button
                                    type="button"
                                    className="btn btn-dark btn-icon btn-sm fs-16 close-btn-overview"
                                    onClick={() => sidebarClose('file-detail-show')}
                                >
                                    <i className="mdi mdi-close align-bottom"></i>
                                </button>
                            </div>
                        </div>

                        <div className="pb-3 border-bottom border-bottom-dashed mb-3">
                            <div className="file-details-box bg-light p-3 text-center rounded-3 border border-light mb-3">
                                <div className="display-4 file-icon">
                                    <i className="mdi mdi-file-document-outline" />
                                </div>
                            </div>
                            <h5 className="fs-15 fw-normal mb-1 file-name">
                                {sidebarData.translations?.[currentLang]?.fileDescription}.{sidebarData.fileExtension}
                            </h5>
                            <p className="text-secondary fs-12 mb-0 fs-12">
                                <span className="file-size">{sidebarData.documentType?.name}</span>
                            </p>
                        </div>
                        <div>
                            {!isEdit && sidebarData.translations && (
                                <h5 className="fs-12 fw-normal text-uppercase mb-2">{t('form.documentFormSidebar.title.description')}</h5>
                            )}
                            {!isEdit && (
                                <Row className={`align-items-start mb-2`}>
                                    <Col sm={6}>
                                        <span className="flex-grow-1 fw-normal fs-12">{t('app.common.name')}:</span>
                                    </Col>
                                    <Col sm={6}>
                                        <span className="flex-grow-1 fw-light fs-12">
                                            {sidebarData.translations?.[currentLang]?.fileDescription}.{sidebarData.fileExtension}
                                        </span>
                                    </Col>
                                </Row>
                            )}
                            {!isEdit && sidebarData?.documentPropertyId && (
                                <Row className={`align-items-start mb-2`}>
                                    <Col sm={6}>
                                        <span className="flex-grow-1 fw-normal fs-12">{t('app.common.propertyId')}:</span>
                                    </Col>
                                    <Col sm={6}>
                                        <span className="flex-grow-1 fw-light fs-12">{sidebarData.documentPropertyId}</span>
                                    </Col>
                                </Row>
                            )}
                            {!isEdit && sidebarData?.documentCreationDate && (
                                <Row className={`align-items-start mb-2`}>
                                    <Col sm={6}>
                                        <span className="flex-grow-1 fw-normal fs-12">{t('app.common.creationDate')}:</span>
                                    </Col>
                                    <Col sm={6}>
                                        <span className="flex-grow-1 fw-light fs-12">{formatDateTime(sidebarData.documentCreationDate)}</span>
                                    </Col>
                                </Row>
                            )}
                            {!isEdit && sidebarData?.documentValidityDate && (
                                <Row className={`align-items-start mb-2`}>
                                    <Col sm={6}>
                                        <span className="flex-grow-1 fw-normal fs-12">{t('app.common.expirationDate')}:</span>
                                    </Col>
                                    <Col sm={6}>
                                        <span className="flex-grow-1 fw-light fs-12">{formatDateTime(sidebarData.documentValidityDate)}</span>
                                    </Col>
                                </Row>
                            )}
                            {isEdit && (
                                <DocumentForm
                                    hasUpload={false}
                                    isEdit={true}
                                    documentData={sidebarData}
                                    contactType={contactType}
                                    documentModule={documentModule}
                                    parentId={parentId}
                                />
                            )}
                        </div>
                        {!isEdit && (
                            <div className="border-top border-top-dashed py-3">
                                <div className="hstack gap-2">
                                    <Button
                                        tag="a"
                                        href={`${API_URL}v1/file/download/${sidebarData.id}`}
                                        color="primary"
                                        className="btn-label"
                                        disabled={false}
                                        download={true}
                                    >
                                        <i className="mdi mdi-download align-bottom label-icon align-middle fs-16 me-2"></i>
                                        {t('button.download')}
                                    </Button>
                                    <AppPermissionsSwitcher
                                        permission="ModulePermissions"
                                        module={'documents'}
                                        documentAgentId={agentId}
                                        action={action.delete}
                                    >
                                        {({ hasModulePermission }) =>
                                            hasModulePermission && (
                                                <Button color="danger" className="btn-label" onClick={() => onClickDelete(sidebarData.id)}>
                                                    <i className="mdi mdi-trash-can-outline align-bottom label-icon align-middle fs-16 me-2"></i>
                                                    {t('button.delete')}
                                                </Button>
                                            )
                                        }
                                    </AppPermissionsSwitcher>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </SimpleBar>
        </div>
    )
}

export default DocumentFormSidebar
