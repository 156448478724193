import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAgency, selectAgency, selectAgencyFetching, updateAgency } from '../../../../store/administration'
import AdminLoader from '../../Components/AdminLoader/AdminLoader'
import { Row } from 'reactstrap'
import BreadCrumb from '../../../../Components/Common/BreadCrumb'
import AgencyOfficeForm from '../../Components/AgencyOfficeForm/AgencyOfficeForm'
import UploadPhoto from '../../Components/UploadPhoto/UploadPhoto'
import * as Yup from 'yup'
import { officeValidation } from '../../Office/validateRules'
import i18n from 'i18next'
import parsePhoneNumber from 'libphonenumber-js'
import { officeAgencyFieldDescription } from '../../Components/AgencyOfficeForm/officeAgencyFieldDescription'

const AgencyEdit = () => {
    const dispatch = useDispatch()
    const description = officeAgencyFieldDescription()

    const { agency, fetching } = useSelector((state) => ({
        agency: selectAgency(state),
        fetching: selectAgencyFetching(state),
    }))

    useEffect(() => {
        dispatch(fetchAgency())
    }, [dispatch])

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: !fetching && agency,
        validationSchema: Yup.object(officeValidation()),
        onSubmit: (values) => {
            delete values.qrCode

            const phoneNumber = parsePhoneNumber(`+${values?.phoneNumber}`)?.formatInternational()
            const mobileNumber = parsePhoneNumber(`+${values?.mobileNumber}`)?.formatInternational()

            const agency = {
                ...values,
                mobileNumber: mobileNumber ?? '',
                phoneNumber: phoneNumber ?? '',
                logo: values.logo ?? '',
            }

            dispatch(updateAgency(agency))
        },
    })

    return fetching ? (
        <AdminLoader />
    ) : (
        <Row>
            <BreadCrumb title={`${i18n.t('button.edit')} - ${formik?.values?.name}`} />
            <UploadPhoto
                formik={formik}
                photoField={'logo'}
                imageUrl={formik?.values?.logo}
                firstName={formik?.values?.name}
                headline={formik?.values?.name}
                permission={description.agencyLogo}
            />
            <AgencyOfficeForm formik={formik} cancelButtonUrl={'/admin/agency'} />
        </Row>
    )
}

export default AgencyEdit
