import { handleActions } from "redux-actions"
import { fetchDocumentValuesAvailableEntitiesSuccess, fetchDocumentValuesTypeSuccess } from "./actions"

export const initialState = {
    availableentities: {},
    type: {},
}

export default handleActions(
    {
        [fetchDocumentValuesAvailableEntitiesSuccess]: (state, { payload }) => ({
            ...state,
            availableentities: payload.reduce(
              (dict, item) => ({
                ...dict,
                [item]: item
              }),
              {}
            )
          }),
          [fetchDocumentValuesTypeSuccess]: (state, { payload }) => ({
            ...state,
            type: payload.reduce(
              (dict, item) => ({
                ...dict,
                [item.id]: item
              }),
              {}
            )
          }),
    },
    initialState
)