import { useDispatch } from 'react-redux'
import { updateUser } from '../../../../../store/administration'

const ActiveButton = ({ user, filteredUsers }) => {
    const dispatch = useDispatch()

    const handleActiveUser = (user) => {
        const activeUserLocal = JSON.parse(JSON.stringify(user))
        activeUserLocal.active = !activeUserLocal.active

        const userForDatabase = JSON.parse(JSON.stringify(activeUserLocal))

        delete userForDatabase.agency
        delete userForDatabase.agencyOffice
        delete userForDatabase.defaultCounty
        delete userForDatabase.defaultCountry
        delete userForDatabase.defaultCity
        delete userForDatabase.agentPermissions

        dispatch(
            updateUser(userForDatabase, () => {
                const index = filteredUsers.findIndex((item) => item.id === user?.id)
                filteredUsers[index] = activeUserLocal
            })
        )
    }
    return (
        <button type="button" className="btn btn-light btn-icon rounded-circle btn-md favourite-btn" onClick={() => handleActiveUser(user)}>
            <i className={`mdi ${user?.active ? 'mdi-account-check text-success' : 'mdi-account-cancel text-danger'} fs-20`}></i>
        </button>
    )
}

export default ActiveButton
