const HamburgerButton = ({ btnFunction = null }) => {
    const toogleMenuBtn = () => {
        var windowSize = document.documentElement.clientWidth

        if (windowSize > 767) document.querySelector('.hamburger-icon').classList.toggle('open')

        //For collapse horizontal menu
        if (document.documentElement.getAttribute('data-layout') === 'horizontal') {
            document.body.classList.contains('menu') ? document.body.classList.remove('menu') : document.body.classList.add('menu')
        }

        //For collapse vertical menu
        if (document.documentElement.getAttribute('data-layout') === 'vertical') {
            if (windowSize < 1025 && windowSize > 767) {
                document.body.classList.remove('vertical-sidebar-enable')
                document.documentElement.getAttribute('data-sidebar-size') === 'sm'
                    ? document.documentElement.setAttribute('data-sidebar-size', '')
                    : document.documentElement.setAttribute('data-sidebar-size', 'sm')
            } else if (windowSize > 1025) {
                document.body.classList.remove('vertical-sidebar-enable')
                document.documentElement.getAttribute('data-sidebar-size') === 'lg'
                    ? document.documentElement.setAttribute('data-sidebar-size', 'sm')
                    : document.documentElement.setAttribute('data-sidebar-size', 'lg')
            } else if (windowSize <= 767) {
                document.body.classList.add('vertical-sidebar-enable')
                document.documentElement.setAttribute('data-sidebar-size', 'lg')
            }
        }

        //Two column menu
        if (document.documentElement.getAttribute('data-layout') === 'twocolumn') {
            document.body.classList.contains('twocolumn-panel')
                ? document.body.classList.remove('twocolumn-panel')
                : document.body.classList.add('twocolumn-panel')
        }
        btnFunction && btnFunction()
    }

    return (
        <button
            onClick={toogleMenuBtn}
            type="button"
            className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger"
            id="topnav-hamburger-icon"
        >
            <span className="hamburger-icon">
                <span></span>
                <span></span>
                <span></span>
            </span>
        </button>
    )
}

export default HamburgerButton
