import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchAgencyOffices, selectAgencyOfficesFetching, selectAgencyOffices } from '../../../../store/administration'
import AdminLoader from '../../Components/AdminLoader/AdminLoader'
import CardTableView from '../../Components/CardTableView/CardTableView'

const OfficeList = () => {
    const dispatch = useDispatch()

    const { offices, fetching } = useSelector((state) => ({
        offices: selectAgencyOffices(state),
        fetching: selectAgencyOfficesFetching(state),
    }))

    const [filteredOffices, setFilteredOffices] = useState([])

    useEffect(() => {
        dispatch(fetchAgencyOffices())
    }, [dispatch])

    return fetching ? (
        <AdminLoader />
    ) : (
        <>
            <CardTableView
                data={offices?.items}
                fetching={fetching}
                filteredData={filteredOffices}
                setFilteredData={setFilteredOffices}
                searchFields={{ firstName: 'name' }}
                editButtonLink={'/admin/office'}
            />
        </>
    )
}

export default OfficeList
