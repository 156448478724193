export const formFields = [
    {
        name: 'photos',
        label: 'app.common.photos',
        checked: 1, // 1 stands for true
    },
    {
        name: 'groundPlans',
        label: 'app.common.groundPlans',
        checked: 1,
    },
    {
        name: 'video',
        label: 'app.common.video',
        checked: 1,
    },
    {
        name: '3D',
        label: 'app.common.property.3DVideo.virtualWalk',
        checked: 1,
    },
    {
        name: 'notes',
        label: 'app.common.notes',
        checked: 1,
    },
    {
        name: 'documents',
        label: 'app.common.documents',
        checked: 1,
    },
]
