import 'cleave.js/dist/addons/cleave-phone.in'
import { getIn } from 'formik'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import { Button, Col, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import { propertyOfferPrintFields } from '../../common/forms/generalRealEstate/fields'
import { projectResponseFields } from '../../common/response/project'
import { propertyResponseFields } from '../../common/response/property'
import { selectAgentsFormOption } from '../../store/agents/selectors'
import { useTranslation } from 'react-i18next'

const OfferSendModal = ({ isOpen, toggle, validation, subform, properties, selectedContacts, type }) => {
    const { t } = useTranslation()

    const [prevLocale, setPrevLocale] = useState(null)
    const [selectedContact, setSelectedContact] = useState(null)
    const [selectedSendItem, setSelectedSendItem] = useState(null)
    const [contactOptions, setContactOptions] = useState([])

    const { agents } = useSelector((state) => ({
        agents: selectAgentsFormOption(state),
    }))

    const offerLocale = getIn(validation.values[`${subform}`], propertyOfferPrintFields.OFFER_LOCALE)
    const offerEmailSettingsGroup = getIn(validation.values[`${subform}`], 'offer_email_settings_group')
    const agent = getIn(validation.values[`${subform}`], 'agent_id')
  
    const renderEmailTitle = () => {
        let offerEmailTitle
        const isProperty = type === 'property'
        const isNativeLang = offerLocale === 'hr'

        if (isNativeLang) {
            offerEmailTitle = isProperty ? 'Ponuda nekretnina' : 'Ponuda projekata'
        } else {
            offerEmailTitle = isProperty ? 'Properties offer' : 'Projects offer'
        }

        return offerEmailTitle
    }

    const renderEmailMessage = (item, propertyString, agentString) => {
        const emailContact = selectedContacts?.find((x) => x.id === item?.contact_id)
        const isProperty = type === 'property'
        const isNativeLang = offerLocale === 'hr'
        let placeholder

        if (isNativeLang) {
            placeholder = isProperty ? 'nekretninu(e)' : 'projekt(e)'
        } else {
            placeholder = isProperty ? 'property(s)' : 'project(s)'
        }

        if (!isNativeLang) {
            return `Dear ${emailContact?.name},

            we send you an offer for the ${placeholder}:
            ${propertyString.join('\n')}

            Kind regards,
            ${agentString?.label ?? 'Agent'}`
        }
        return `Poštovani ${emailContact?.name},

            šaljemo Vam ponudu za ${placeholder}:
            ${propertyString.join('\n')}

            Lp,
            ${agentString?.label ?? 'Agent'}`
    }

    useEffect(() => {
        if (selectedContacts) {
            let mappedContactOptions = selectedContacts.map((item) => ({
                label: `${item.name}, ${item.email}`,
                value: item.id.toString() + item.email,
                id: item.id.toString(),
                email: item.email,
            }))
            setContactOptions(mappedContactOptions)
            setSelectedContact({ ...mappedContactOptions[0] })

            const cityField = type === 'property' ? propertyResponseFields.CITY : projectResponseFields.CITY
            const subTypeField = type === 'property' ? propertyResponseFields.PROPERTY_SUB_TYPE : ''

            const propertyString = properties?.map(
                (item) =>
                    `${item.id ? `ID:${item.id}, ` : ''}${[item[cityField] ? `Grad: ${item[cityField].name}` : '']}${
                        item[subTypeField] ? `, ${item[subTypeField].name}` : ''
                    }`
            )

            const agentString = agents?.find((x) => x.value === agent)

            validation?.setFieldValue(
                `${subform}.offer_email_settings_group`,
                offerEmailSettingsGroup?.map((item) => {
                    return {
                        ...item,
                        [propertyOfferPrintFields.OFFER_EMAIL_TITLE]: renderEmailTitle(),
                        [propertyOfferPrintFields.OFFER_EMAIL_MESSAGE]: renderEmailMessage(item, propertyString, agentString),
                    }
                })
            )
        }
    }, [selectedContacts, offerLocale])

    useEffect(() => {
        if (selectedContact && !selectedSendItem) {
            setSelectedSendItem(
                offerEmailSettingsGroup?.find(
                    (x) =>
                        x[propertyOfferPrintFields.CONTACT_ID]?.toString() === selectedContact.id &&
                        x[propertyOfferPrintFields.CONTACT_EMAIL] === selectedContact.email
                )
            )
        } else {
            if (prevLocale !== offerLocale) {
                setSelectedSendItem(
                    offerEmailSettingsGroup?.find(
                        (x) =>
                            x[propertyOfferPrintFields.CONTACT_ID]?.toString() === selectedContact?.id &&
                            x[propertyOfferPrintFields.CONTACT_EMAIL] === selectedContact?.email
                    )
                )
            }
        }
        setPrevLocale(offerLocale)
    }, [offerEmailSettingsGroup, selectedContact, selectedSendItem, prevLocale, offerLocale])

    useEffect(() => {
        if (selectedSendItem) {
            validation?.setFieldValue(
                `${subform}.offer_email_settings_group`,
                offerEmailSettingsGroup.map((item) => {
                    const foundSettings = offerEmailSettingsGroup.find(
                        (x) =>
                            x[propertyOfferPrintFields.CONTACT_ID] === selectedSendItem[propertyOfferPrintFields.CONTACT_ID] &&
                            x[propertyOfferPrintFields.CONTACT_EMAIL] === selectedSendItem[propertyOfferPrintFields.CONTACT_EMAIL]
                    )

                    if (foundSettings.contact_id === item.contact_id && foundSettings.contact_email === item.contact_email) {
                        return {
                            ...item,
                            [propertyOfferPrintFields.OFFER_EMAIL_TITLE]: selectedSendItem[propertyOfferPrintFields.OFFER_EMAIL_TITLE],
                            [propertyOfferPrintFields.OFFER_EMAIL_MESSAGE]: selectedSendItem[propertyOfferPrintFields.OFFER_EMAIL_MESSAGE],
                        }
                    }
                    return item
                })
            )
        }
    }, [selectedSendItem])


    const handleSend = () => {
        validation.handleSubmit()
    }

    return (
        <Modal isOpen={isOpen} toggle={toggle} id="sendmodal">
            <ModalHeader toggle={toggle}>{t('modal.offerSend.title')}</ModalHeader>
            <ModalBody className="p-5">
                <Row>
                    <Col sm={12} className="mb-4">
                        <div className="d-flex justify-content-between">
                            <Label for={propertyOfferPrintFields.AGENT_ID} id={`${propertyOfferPrintFields.PROPERTY_ID}Lbl`}>
                            {t('modal.offerSend.contact')}
                            </Label>
                        </div>
                        <Select
                            onChange={(e) => {
                                setSelectedContact(e)
                                setSelectedSendItem(
                                    offerEmailSettingsGroup.find(
                                        (x) => x[propertyOfferPrintFields.CONTACT_ID]?.toString() === e.id.toString() && x.contact_email === e.email
                                    )
                                )
                            }}
                            options={contactOptions}
                            name={propertyOfferPrintFields.PROPERTY_ID}
                            id={propertyOfferPrintFields.PROPERTY_ID}
                            value={contactOptions.find((x) => x.value === selectedContact?.value?.toString()) ?? ''}
                            noOptionsMessage={() => t('form.common.select.noData')}
                            placeholder=""
                        />
                    </Col>
                </Row>
                <Row className="aling-items-center mb-4">
                    <Col sm={12} className="align-self-center">
                        <Label for={propertyOfferPrintFields.OFFER_PROPERTY_AREA}>{t('modal.offerSend.emailTitle')}</Label>
                    </Col>
                    <Col sm={12}>
                        <Input
                            type="text"
                            className="form-control"
                            id={propertyOfferPrintFields.OFFER_EMAIL_TITLE}
                            name={propertyOfferPrintFields.OFFER_EMAIL_TITLE}
                            onChange={(e) => {
                                setSelectedSendItem({
                                    ...selectedSendItem,
                                    [propertyOfferPrintFields.OFFER_EMAIL_TITLE]: e.target.value,
                                })
                            }}
                            // onBlur={formik?.handleBlur}
                            value={selectedSendItem?.[propertyOfferPrintFields.OFFER_EMAIL_TITLE] || ''}
                        />
                    </Col>
                </Row>

                <Row className="aling-items-center mb-4">
                    <Col sm={12} className="align-self-center">
                        <Label for={propertyOfferPrintFields.OFFER_PROPERTY_AREA}>{t('modal.offerSend.emailMessage')}</Label>
                    </Col>
                    <Col sm={12}>
                        <Input
                            type="textarea"
                            className="form-control"
                            id={propertyOfferPrintFields.OFFER_EMAIL_MESSAGE}
                            name={propertyOfferPrintFields.OFFER_EMAIL_MESSAGE}
                            onChange={(e) => {
                                setSelectedSendItem({
                                    ...selectedSendItem,
                                    [propertyOfferPrintFields.OFFER_EMAIL_MESSAGE]: e.target.value,
                                })
                            }}
                            // onBlur={formik?.handleBlur}
                            style={{ resize: 'none', height: '170px' }}
                            value={selectedSendItem?.[propertyOfferPrintFields.OFFER_EMAIL_MESSAGE] || ''}
                        />
                    </Col>
                </Row>
                <div className="hstack gap-2 justify-content-end">
                    <Button type="submit" color="send" className="btn-label" onClick={() => handleSend()}>
                        {validation.isSubmitting ? (
                            <i className="mdi mdi-spin mdi-loading label-icon align-middle fs-16 me-2"></i>
                        ) : (
                            <i className="mdi mdi-arrow-right align-bottom label-icon align-middle fs-16 me-2"></i>
                        )}
                        {t('button.send')}
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default OfferSendModal
