import { FormikProvider, useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Container, Form, TabContent, TabPane } from 'reactstrap'
import * as Yup from 'yup'
import { SUBMIT_RESPONSE_STATUS } from '../../common/constants'
import FormActionButtons from '../../Components/Common/FormActionButtons'
import PageTitle from '../../Components/Common/PageTitle'
import ContactFormGeneralTab, {
    ContactFormGeneralTabMain,
    ContactFormGeneralTabSide,
} from '../../Components/ContactFormGeneralTab/ContactFormGeneralTab'
import {
    useContactGeneralFormConfig,
    useContactGeneralFormSelectOptions,
    useRealEstateGeneralFormConfig,
    useRealEstateGeneralFormSelectOptions,
    useRealEstateTransactionFormConfig,
} from '../../Components/Hooks/FormHooks'
import useContactsFormTabs from '../../Components/Hooks/useContactsTabs'
import FormValidationErrorModal from '../../Components/Modals/FormValidationErrorModal'
import { createContact } from '../../store/contacts'

const ContactCreate = () => {
    const history = useHistory()
    const tabs = useContactsFormTabs(false)
    const dispatch = useDispatch()
    const [activeTab, setActiveTab] = useState(null)
    let id = 0
    const { generalInputs, generalValidationConfig } = useContactGeneralFormConfig(id)
    const formSelectOptions = useContactGeneralFormSelectOptions()
    useEffect(() => {
        if (tabs.length > 0) {
            setActiveTab(tabs[0].name)
        }
    }, [tabs])
    const handleTabChange = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab)
        }
    }

    const { isValid, isSubmitting, isValidating, ...validation } = useFormik({
        enableReinitialize: true,
        initialValues: {
            general: generalValidationConfig.initialValues ?? {},
        },
        validationSchema: Yup.object({
            general: generalValidationConfig.validationSchema ?? Yup.object({}),
        }),
        onSubmit: (values) => {
            const submitValues = {
                ...generalValidationConfig.prepareModelForSubmit(values.general),
                contact_type: 'person',
            }

            delete submitValues.id

            dispatch(
                createContact(submitValues, (response) => {
                    if (response.submitStatus === SUBMIT_RESPONSE_STATUS.SUCCESS) {
                        history.push(`/contacts/${response.data.id}/edit`)
                    }
                })
            )
        },
    })

    useEffect(() => {
        if (!isValid && !isValidating && isSubmitting) {
            setIsErrorModalActive(true)
        }
    }, [isValid, isValidating, isSubmitting])

    const renderFormButtons = () => <FormActionButtons disabled={false} hasCancel={true} cancelAction={() => history.push('/contacts')} />

    const [isErrorModalActive, setIsErrorModalActive] = useState(false)

    const toggleErrorModal = () => {
        setIsErrorModalActive(!isErrorModalActive)
    }

    return (
        <div className="page-content">
            <Container fluid>
                <FormikProvider value={validation}>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault()
                            validation.handleSubmit()
                            return false
                        }}
                        className="needs-validation"
                        id="real_estate_form"
                    >
                        <PageTitle title="Novi kontakt">{renderFormButtons()}</PageTitle>

                        <TabContent activeTab={activeTab} className="text-muted">
                            <TabPane tabId="general">
                                <ContactFormGeneralTab
                                    tabs={tabs}
                                    activeTab={activeTab}
                                    handleTabChange={handleTabChange}
                                    inputs={generalInputs}
                                    subform="general"
                                    formik={validation}
                                    selectOptions={formSelectOptions}
                                >
                                    <ContactFormGeneralTabSide />
                                    <ContactFormGeneralTabMain />
                                </ContactFormGeneralTab>
                            </TabPane>
                        </TabContent>
                    </Form>
                </FormikProvider>
            </Container>
            {isErrorModalActive && (
                <FormValidationErrorModal validation={validation} inputs={[generalInputs]} onClose={() => toggleErrorModal(false)} />
            )}
        </div>
    )
}

export default ContactCreate
