import { useEffect, useState } from 'react'
import { Carousel, CarouselCaption, CarouselControl, CarouselIndicators, CarouselItem } from 'reactstrap'
import styles from './ImageCarousel.module.css'
import { API_URL } from '../../../../common/constants'

const ImageCarousel = ({ photos, changePhoto }) => {
    const [activeIndex, setActiveIndex] = useState(0)
    const itemsPerSlide = photos.length <= 3 ? photos.length : 3
    const photoUrl = `${API_URL}v1/file/show/`

    const photosToDisplay = photos?.slice(activeIndex, activeIndex + itemsPerSlide)

    useEffect(() => {
        setActiveIndex(0)
    }, [photos])

    const next = () => {
        setActiveIndex((currentIndex) => {
            const nextIndex = currentIndex + 1

            if (currentIndex + itemsPerSlide === photos.length) return currentIndex
            return nextIndex
        })
    }

    const previous = () => {
        setActiveIndex((currentIndex) => {
            const prevIndex = currentIndex - 1

            if (currentIndex === 0) return currentIndex
            return prevIndex
        })
    }

    return (
        <Carousel
            activeIndex={activeIndex}
            next={next}
            previous={previous}
            ride="carousel"
            slide={null}
            interval={null}
            className={styles.imageCarousel}
        >
            {/* <CarouselIndicators items={photos} /> */}

            {photosToDisplay.map((photo, index) => (
                <CarouselItem key={index} className={styles.imageItem}>
                    <img onClick={changePhoto} src={`${photoUrl}${photo.id}`} id={photo.id} alt={photo.id} />
                </CarouselItem>
            ))}

            <CarouselControl direction="prev" onClickHandler={previous} />
            <CarouselControl direction="next" onClickHandler={next} />
        </Carousel>
    )
}

export default ImageCarousel
