import { Input, Label, FormFeedback, UncontrolledTooltip } from 'reactstrap'
import _ from 'lodash'

const InputText = (props) => {
    const {
        id,
        label,
        className = '',
        onChange,
        defaultValue,
        formikError,
        formikTouched,
        isRequired = false,
        isDisabled,
        type,
        rows,
        onBlur,
        permission,
    } = props

    let error = _.get(formikError, `${id}`)
    let touched = _.get(formikTouched, `${id}`)

    return (
        <div className={className}>
            <Label>{label}</Label>
            {isRequired && <span className="required-mark">*</span>}
            {permission && (
                <>
                    {' '}
                    <span id={`agentPermissionsTooltip${permission.id}`}>
                        <i className="mdi mdi-help-circle-outline"></i>{' '}
                    </span>
                    <UncontrolledTooltip pement="right" target={`agentPermissionsTooltip${permission.id}`}>
                        {permission.description}
                    </UncontrolledTooltip>
                </>
            )}

            <Input
                id={id}
                onChange={onChange}
                onBlur={onBlur}
                value={defaultValue}
                invalid={(error && touched) ?? false}
                disabled={isDisabled}
                type={type}
                rows={rows}
            />
            {error && touched ? <FormFeedback type="invalid">{error?.split(';')[0]}</FormFeedback> : null}
        </div>
    )
}

export default InputText
