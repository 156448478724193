import { ModalBody, ModalHeader, TabContent, TabPane } from 'reactstrap'
import FormTabNavigation from '../../../../Components/Common/FormTabNavigation'
import OfferPrintTab from '../../../../Components/Modals/OfferPrintModal/OfferPrintTab'

const OfferPrintMainModal = (props) => {
    const {
        offerTemplate,
        selectedTemplate,
        setSelectedTemplate,
        offerValidation,
        data,
        toggleOfferPrintSettingsModal,
        toggleOfferPreviewModal,
        toggleOfferSendModal,
        selectedContactsForOffer,
        setSelectedContactsForOffer,
        tabs,
        activeTab,
        handleTabChange,
    } = props

    return (
        <>
            <ModalHeader>
                <FormTabNavigation tabs={tabs} activeTab={activeTab} handleTabChange={handleTabChange} customClass="offer-print-modal-tabs" />
            </ModalHeader>
            <ModalBody style={{ paddingTop: '1.25rem' }}>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="offer">
                        <OfferPrintTab
                            isProject={true}
                            isOfferView={true}
                            properties={data}
                            offerTemplate={offerTemplate}
                            selectedTemplate={selectedTemplate}
                            setSelectedTemplate={setSelectedTemplate}
                            validation={offerValidation}
                            toggleSettingsModal={toggleOfferPrintSettingsModal}
                            togglePreviewModal={toggleOfferPreviewModal}
                            toggleSendModal={toggleOfferSendModal}
                            selectedContactsForOffer={selectedContactsForOffer}
                            setSelectedContactsForOffer={setSelectedContactsForOffer}
                        />
                    </TabPane>
                    <TabPane tabId="print">
                        <OfferPrintTab
                            isProject={true}
                            isOfferView={false}
                            properties={data}
                            offerTemplate={offerTemplate}
                            selectedTemplate={selectedTemplate}
                            setSelectedTemplate={setSelectedTemplate}
                            validation={offerValidation}
                            toggleSettingsModal={toggleOfferPrintSettingsModal}
                            togglePreviewModal={toggleOfferPreviewModal}
                        />
                    </TabPane>
                </TabContent>
            </ModalBody>
        </>
    )
}

export default OfferPrintMainModal
