import { Button } from 'reactstrap'
import AppPermissionsSwitcher from '../AppPermissionsSwitcher/AppPermissionsSwitcher'
import { useState } from 'react'
import Lightbox from 'yet-another-react-lightbox'
import Counter from 'yet-another-react-lightbox/plugins/counter'
import 'yet-another-react-lightbox/styles.css'
import 'yet-another-react-lightbox/plugins/counter.css'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import 'swiper/css/scrollbar'
import 'swiper/css/effect-fade'
import 'swiper/css/effect-flip'
import { Pagination, Navigation } from 'swiper'
import styles from './Slider.module.css'

const Slider = (props) => {
    const { images, module } = props

    const [fullScreen, setFullScreen] = useState(false)
    const [indexPhoto, setIndexPhoto] = useState(0)
    const [swiperIndex, setSwiperIndex] = useState(null)

    const handleLightBoxOpen = (index) => {
        setFullScreen(true)
        setIndexPhoto(index)
    }

    const handleLightBoxClose = () => {
        swiperIndex.slideTo(indexPhoto, 0)
        setFullScreen(false)
    }

    return (
        <AppPermissionsSwitcher permission="AllowedPhotos" photos={images} module={module}>
            {({ photos }) => (
                <>
                    <Swiper
                        pagination={{
                            type: 'fraction',
                        }}
                        modules={[Navigation, Pagination]}
                        navigation={true}
                        loop={false}
                        autoplay={false}
                        className="mySwiper swiper navigation-swiper"
                        onSwiper={setSwiperIndex}
                    >
                        <div className="swiper-wrapper">
                            {photos.map((image, index) => (
                                <SwiperSlide className={`${styles.slide}`} key={image.id + '-' + index}>
                                    <div className="position-absolute" style={{ top: '10px', right: '10px' }}>
                                        <Button onClick={() => handleLightBoxOpen(index)} color="primary" className="btn-icon">
                                            <i className="mdi mdi-fullscreen fs-17" />{' '}
                                        </Button>
                                    </div>
                                    <img className={`${styles.imgCover}`} src={image.thumb} alt="" />
                                </SwiperSlide>
                            ))}
                        </div>
                    </Swiper>
                    <Lightbox
                        index={indexPhoto}
                        on={{ view: ({ index: currentIndex }) => setIndexPhoto(currentIndex) }}
                        open={fullScreen}
                        close={handleLightBoxClose}
                        slides={photos}
                        plugins={[Counter]}
                        carousel={{ finite: true }}
                    />
                </>
            )}
        </AppPermissionsSwitcher>
    )
}

export default Slider
