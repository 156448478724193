export const diaryResponseFields = {
    PROPERTY_ID: 'propertyId',
    BUYER_SELLER: {
        ID: 'id',
        NAME: 'fullName',
        CITY: 'city',
        STREET_NAME: 'street',
        STREET_NUMBER: 'streetNumber',
        OIB: 'taxNumber',
    },
    PROPERTY_TYPE: 'propertyType',
    PROPERTY_LOCATION: 'propertyLocation',
    PROPERTY_STREET: 'propertyStreet',
    AREA: 'areaNett',
    PROPERTY_FLOOR_NO: 'propertyFloorNo',
    PROPERTY_ROOMS_NO: 'propertyRoomsNo',
    PROPERTY_REGISTRY_INSERT: 'propertyRegistryInsert',
    PROPERTY_REGISTRY_SUBINSERT: 'propertyRegistrySubinsert',
    PROPERTY_CADASTRAL_PARCEL_NO: 'propertyRegistryParcelNo',
    PROPERTY_CASASTRAL_MUNICIPALITY: 'propertyRegistryMunicipality',
    PRICE: 'finalPrice',
    PRICE_M2: 'finalPriceM2',
    CONTRACT_TYPE: 'contractType',
    CONCLUSION_PLACE: 'conclusionPlace',
    CONCLUSION_DATE: 'conclusionDate',
    BUSSNIES_STATUS: 'intermediaryDiaryStatus',
    FINAL_PAYMENT_DATE: 'finalPaymentDate',
    INTERMEDIARY_DIARY_DATE: 'intermediaryDiaryDate',
    PROPERTY_ADVANCE_AMOUNT: 'propertyAdvanceAmount',
}
