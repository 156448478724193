import { COLUMN_IDS_ORDER, dataFallback } from "../pages/Reports/constants"

export function getAgentActivitiesReportSum(reports) {
    return reports.reduce((total, { activities }) => {
        if (total) {
            const newTotal = {}

            for (const k in total) {
                newTotal[k] = total[k] + activities[k]
            }

            return newTotal
        }

        return activities
    }, null)
}

export function findPropertySupplySourceByName(sources, sourceName) {
    return sources?.find(source => source.name === sourceName);
}

export function renderPropertySupplySource(source) {
    if (!source) return dataFallback;

    const values = Object.values(source);
    return values.join(' / ')
}

export function totalPropertySupplySourceByName(reports, sourceName) {
    return reports.reduce((total, { propertySupplySource }) => {
        const source = findPropertySupplySourceByName(propertySupplySource, sourceName);

        if (!source) return total;

        // Remove name from object
        const { name, ...rest } = source;

        const values = Object.values(rest);

        if (!total) return values;

        return total.map((totalItem, i) => totalItem + values[i]);
    }, null)
}

export function agentActivitiesReportColumnSortFn(lookupKey = 0) {
    return (a, b) => {
        const colAIndex = COLUMN_IDS_ORDER.indexOf(a[lookupKey])
        const colBIndex = COLUMN_IDS_ORDER.indexOf(b[lookupKey])

        if (colAIndex < colBIndex) {
            return -1
        }

        if (colAIndex > colBIndex) {
            return 1
        }

        return 0
    }
}

export function getAgentInquirySourceTotals(sources, reports, key) {
    if (!sources || !reports) return null

    return sources.map(({ id }) => {
        const total = reports.reduce((acc, { [key]: source }) => {
            const sourceValue = source[id]

            if (!sourceValue) return acc

            return acc + sourceValue
        }, 0)
        return total
    })
}