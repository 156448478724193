import values from 'lodash/values';

import { stateKey } from './actions';

export const selectDetailSearchLocationsCountryFormOptions = state => {
    const data = values(state[stateKey].country);
    // console.log('data country', data)
    // console.log('state', state)
    return data.map((service) => (
        {
            value: service.id.toString(),
            label: service.name
        }
    ))
}

export const selectDetailSearchLocationsCountyFormOptions = state => {
    const data = values(state[stateKey].county);
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => (
        {
            value: service.id.toString(),
            label: service.name
        }
    ))
}

export const selectDetailSearchAdditionalLocationsCountyFormOptions = state => {
    const data = values(state[stateKey].additionalCounty);
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => (
        {
            value: service.id.toString(),
            label: service.name
        }
    ))
}

export const selectDetailSearchLocationsCityFormOptions = state => {
    const data = values(state[stateKey].city);
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => (
        {
            value: service.id.toString(),
            label: service.name
        }
    ))
}

export const selectDetailSearchAdditionalLocationsCityFormOptions = state => {
    const data = values(state[stateKey].additionalCity);
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => (
        {
            value: service.id.toString(),
            label: service.name
        }
    ))
}

export const selectDetailSearchLocationsWiderAreaFormOptions = state => {
    const data = values(state[stateKey].widerarea);
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => (
        {
            value: service.id.toString(),
            label: service.name
        }
    ))
}

export const selectDetailSearchAdditionalLocationsWiderAreaFormOptions = state => {
    const data = values(state[stateKey].additionalWiderarea);
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => (
        {
            value: service.id.toString(),
            label: service.name
        }
    ))
}

export const selectDetailSearchLocationsQuarterFormOptions = state => {
    const data = values(state[stateKey].quarter);
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => (
        {
            value: service.id.toString(),
            label: service.name
        }
    ))
}

export const selectDetailSearchAdditionalLocationsQuarterFormOptions = state => {
    const data = values(state[stateKey].additionalQuarter);
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => (
        {
            value: service.id.toString(),
            label: service.name
        }
    ))
}

export const selectDetailSearchLocationsQIslandFormOptions = state => {
    const data = values(state[stateKey].island);
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => (
        {
            value: service.id.toString(),
            label: service.name
        }
    ))
}
export const selectDetailSearchAdditionalLocationsQIslandFormOptions = state => {
    const data = values(state[stateKey].additionalIsland);
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => (
        {
            value: service.id.toString(),
            label: service.name
        }
    ))
}

export const selectDetailSearchPropertySubTypesFormOptions = state => {
    const data = values(state[stateKey].propertySubTypes);
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => (
        {
            value: service.id.toString(),
            label: service.name
        }
    ))
}

export const selectDetailSearchPropertyValuesDescriptionsrFormOptions = state => {
    const data = values(state[stateKey].propertyDescriptions);
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => (
        {
            value: service.id.toString(),
            label: service.name
        }
    ))
}

export const selectDetailSearchPropertyValuesUtilityFormOptions = state => {
    const data = values(state[stateKey].propertyUtility);
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => (
        {
            value: service.id.toString(),
            label: service.name
        }
    ))
}

export const selectDetailSearchPropertyValuesEquipmentFormOptions = state => {
    const data = values(state[stateKey].propertyEquipment);
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => (
        {
            value: service.id.toString(),
            label: service.name
        }
    ))
}

export const selectDetailSearchPropertyValuesSpaceFormOptions = state => {
    const data = values(state[stateKey].propertySpace);
    // console.log('data', data)
    // console.log('serach state state', state[stateKey])
    return data.map((service) => (
        {
            value: service.id.toString(),
            label: service.name
        }
    ))
}

export const selectDetailSearchPropertyTypesFormOptions = state => {
    const data = values(state[stateKey].propertyTypes);
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => (
        {
            value: service.id.toString(),
            label: service.name
        }
    ))
}

export const selectDetailSearchPropertyValuesFloorFormOptions = state => {
    const data = values(state[stateKey].propertyFloor);
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => (
        {
            value: service.id.toString(),
            label: service.name
        }
    ))
}