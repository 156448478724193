import { createAction } from 'redux-actions'

const ENTITY = 'AGENTS'
export const stateKey = ENTITY.toLowerCase()

export const fetchAgents = createAction(
    `${ENTITY}/FETCH`,
    (payload) => payload,
    (_, callback) => callback
)
export const fetchAgentsRequest = createAction(`${ENTITY}/FETCH_REQUEST`)
export const fetchAgentsSuccess = createAction(`${ENTITY}/FETCH_SUCCESS`)
export const fetchAgentsFailure = createAction(`${ENTITY}/FETCH_FAILURE`)

export const updateAgent = createAction(
    `${ENTITY}/UPDATE_AGENT`,
    (payload) => payload,
    (_, reset) => ({ reset })
)
export const updateAgentRequest = createAction(`${ENTITY}/UPDATE_AGENT_REQUEST`)
export const updateAgentSuccess = createAction(`${ENTITY}/UPDATE_AGENT_SUCCESS`)
export const updateAgentFailure = createAction(`${ENTITY}/UPDATE_AGENT_FAILURE`)

export const updatePageData = createAction(`${ENTITY}/UPDATE_PAGE_DATA`, (payload) => payload)

export const fetchUser = createAction(
    `${ENTITY}/FETCH_USER`,
    (payload) => payload,
    (_, callback) => callback
)
export const fetchUserRequest = createAction(`${ENTITY}/FETCH_USER_REQUEST`)
export const fetchUserSuccess = createAction(`${ENTITY}/FETCH_USER_SUCCESS`)
export const fetchUserFailure = createAction(`${ENTITY}/FETCH_USER_FAILURE`)
