import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { MODAL_TYPES } from '../../common/constants'
import { hideModal } from '../../store/modal'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Button } from 'reactstrap'
import { selectFetchingExportSync, triggerExportSync } from '../../store/export'
import { useState } from 'react'

const ExportSyncModal = () => {
    const { EXPORT_SYNC_MODAL } = MODAL_TYPES
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const [syncDone, setSyncDone] = useState(false)

    const { loading } = useSelector((state) => ({
        loading: selectFetchingExportSync(state),
    }))

    const handleClose = () => {
        const payload = {
            modalType: EXPORT_SYNC_MODAL,
        }

        dispatch(hideModal(payload))
    }

    const handleSync = () => {
        setSyncDone(true)
        dispatch(triggerExportSync())
    }

    return (
        <Modal isOpen={true}>
            <ModalHeader className="border-bottom ms-3 ps-2 pb-3 me-3 pe-2">{t('modal.sync.title')}</ModalHeader>
            <ModalBody>
                {(syncDone || loading) && (
                    <div className="mb-4 mt-1" style={{ backgroundColor: 'rgba(7, 176, 150, 0.1)' }}>
                        <p className="pt-3 ps-3">{loading ? t('modal.sync.messageProgress') : t('modal.sync.messageDone')}</p>
                        <div className="progress progress-lg rounded-0 rounded-bottom">
                            <div
                                className={`progress-bar progress-bar-striped bg-success w-100 ${loading && 'progress-bar-animated'}`}
                                role="progressbar"
                            ></div>
                        </div>
                    </div>
                )}
                <div className="d-flex justify-content-end">
                    <Button color="success" className="btn-label me-3" disabled={loading || syncDone} onClick={handleSync}>
                        <i className="mdi mdi-check label-icon align-middle fs-16 me-2"></i> {t('modal.sync.buttonStart')}
                    </Button>
                    <Button color="primary" className="btn-label" onClick={handleClose} disabled={loading}>
                        <i className="mdi mdi-close label-icon align-middle fs-16 me-2"></i>
                        {t('modal.sync.buttonClose')}
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default ExportSyncModal
