export const targetedActivitiesIds = [3, 4];

export const targetedActivitiesSum = (activities) => {
    return getActivitiesSumByTypes(activities, targetedActivitiesIds);
}

export const initialActivitiesIds = [6, 7, 11];

export const initialActivitiesSum = (activities) => {
    return getActivitiesSumByTypes(activities, initialActivitiesIds);
}

const getActivitiesSumByTypes = (activities, types) => {
    return types.reduce((total, typeId) => total + activities[typeId], 0);
}