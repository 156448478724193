import { getIn } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import { Button, Card, CardBody, Col, Input, Row, TabContent, TabPane } from 'reactstrap'
import { realEstateDescriptionFields } from '../../common/forms/generalRealEstate/fields'
import { projectDescriptionFields } from '../../common/forms/projects/fields'
import { selectAvailableTranslationLanguages } from '../../store/applicationSettings'
import FormMainContainer from '../Common/FormMainContainer'
import FormSideContainer from '../Common/FormSideContainer'
import FormTabNavigation from '../Common/FormTabNavigation'
import useApplicationSettings from '../Hooks/useApplicationSettings'
import RecursiveForm from '../RecursiveForm'
import RichTextEditor from '../RichTextEditor/RichTextEditor'
import ProjectFormDescriptionContext from './ProjectFormDescriptionContext'

// TODO: ProjectFormDescriptionTab & PropertyFormDescriptionTab could be one reusable component
const ProjectFormDescriptionTab = ({ children, ...props }) => {
    // TODO: uncomment after sagamiddleware refactor, remove useSelector
    // const { availableTranslationLanguages } = useApplicationSettings()

    const availableTranslationLanguages = useSelector((state) => selectAvailableTranslationLanguages(state))

    const [activeTitleLanguage, setActiveTitleLanguage] = useState('hr')
    const [activeDescriptionLanguage, setActiveDescriptionLanguage] = useState('hr')
    const [activeOfferDescriptionLanguage, setActiveOfferDescriptionLanguage] = useState('hr')

    const languageTabs = availableTranslationLanguages.map((lang) => ({
        id: lang.id,
        name: lang.abbreviation,
        label: lang.abbreviation,
        disabled: false,
    }))

    const languageSelectOptions = availableTranslationLanguages.map((lang) => ({
        value: lang.abbreviation,
        label: lang.abbreviation,
    }))

    const languageTranslateValues = availableTranslationLanguages.reduce((acc, curr) => {
        acc[curr.abbreviation] = ''
        return acc
    }, {})

    return (
        <ProjectFormDescriptionContext.Provider
            value={{
                tabs: props.tabs,
                activeTab: props.activeTab,
                handleTabChange: props.handleTabChange,
                formik: props.formik,
                inputs: props.inputs,
                subform: props.subform,
                activeTitleLanguage,
                setActiveTitleLanguage,
                activeDescriptionLanguage,
                activeOfferDescriptionLanguage,
                setActiveOfferDescriptionLanguage,
                setActiveDescriptionLanguage,
                languageTabs,
                languageSelectOptions,
                languageTranslateValues,
            }}
        >
            <Row>{children}</Row>
        </ProjectFormDescriptionContext.Provider>
    )
}

export const ProjectFormDescriptionTabSide = (props) => {
    const {
        inputs,
        formik,
        subform,
        selectOptions,
        inputComponents,
        activeTitleLanguage,
        setActiveTitleLanguage,
        languageTabs,
        languageTranslateValues,
        languageSelectOptions,
    } = useContext(ProjectFormDescriptionContext)
    const [useGoogleTranslate, setUseGoogleTranslate] = useState(false)
    const [activeGoogleTab, setActiveGoogleTab] = useState(activeTitleLanguage)
    const [googleTabs, setGoogleTabs] = useState(languageTabs)
    const [selectedGoogleLanguage, setSelectedGoogleLanguage] = useState()
    const [propertyTitleTranslateValues, setProjectTitleTranslateValues] = useState(languageTranslateValues)
    const [googleLanguages, setGoogleLanguages] = useState(languageSelectOptions)

    useEffect(() => {
        if (languageTabs.length) {
            setGoogleTabs(languageTabs)
        }
    }, [languageTabs])

    useEffect(() => {
        if (languageSelectOptions.length) {
            setGoogleLanguages(languageSelectOptions)
        }
    }, [languageSelectOptions])

    if (inputs.sideForm) {
        const projectTitleIndex = inputs.sideForm?.findIndex((obj) => obj?.name === projectDescriptionFields.PROJECT_TITLE)
        if (projectTitleIndex !== -1) {
            inputs.sideForm[projectTitleIndex].tabs = languageTabs
            inputs.sideForm[projectTitleIndex].activeTab = activeTitleLanguage
            inputs.sideForm[projectTitleIndex].handleTabChange = (tab) => setActiveTitleLanguage(tab)
        }
    }

    const handleInputChange = (value, language) => {
        setProjectTitleTranslateValues((prevState) => {
            return {
                ...prevState,
                [language]: value,
            }
        })
    }

    const handleAcceptTranslations = () => {
        const formvalues = getIn(formik.values?.[subform], realEstateDescriptionFields.PROPERTY_TITLE)
        const newValues = Object.assign({}, formvalues, propertyTitleTranslateValues)

        formik.setFieldValue(`${subform}.${[realEstateDescriptionFields.PROPERTY_TITLE]}`, newValues)
        setUseGoogleTranslate(false)
        setProjectTitleTranslateValues(languageTranslateValues)
    }

    return (
        <FormSideContainer>
            <Card>
                <CardBody>
                    <RecursiveForm
                        inputs={inputs?.sideForm}
                        subform={subform}
                        formik={formik}
                        selectOptions={selectOptions}
                        inputComponents={inputComponents}
                    />

                    {/* TODO: future functionality */}
                    {useGoogleTranslate && (
                        <React.Fragment>
                            <Row>
                                <Col sm="12">
                                    <Select
                                        onChange={(e) => {
                                            let filteredTabs = languageTabs.filter((x) => x.name !== e.value)
                                            let filteredValues = filteredTabs.reduce(
                                                (dict, item) => ({
                                                    ...dict,
                                                    [item.name]: '',
                                                }),
                                                {}
                                            )
                                            setSelectedGoogleLanguage(e)
                                            setGoogleTabs(filteredTabs)
                                            setActiveGoogleTab(filteredTabs[0].name)
                                            setProjectTitleTranslateValues(filteredValues)
                                        }}
                                        onBlur={() => {
                                            // formik.setFieldTouched(fieldName)
                                        }}
                                        options={googleLanguages ? googleLanguages : []}
                                        id="propertyTitleGoogleLanguage"
                                        name="propertyTitleGoogleLanguage"
                                        // defaultInputValue={formik.values[inputField.name] || ""}
                                        value={selectedGoogleLanguage ?? ''}
                                        placeholder="Odaberi jezik"
                                    />
                                </Col>
                                <Col sm="12">
                                    <Button color="edit" className="btn-label my-3" onClick={(e) => e.preventDefault}>
                                        {' '}
                                        <i className="mdi mdi-arrow-right label-icon align-middle fs-16 me-2"></i> Pošalji
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12">
                                    <FormTabNavigation
                                        customClass="language-input-tabs"
                                        tabs={googleTabs}
                                        activeTab={activeGoogleTab}
                                        handleTabChange={(tab) => setActiveGoogleTab(tab)}
                                    />
                                    <TabContent activeTab={activeGoogleTab}>
                                        {googleTabs?.map((tab) => (
                                            <TabPane key={`propertyTitleGoogleTranslate-${tab.name}`} tabId={tab.name}>
                                                <Input
                                                    className="tabbed-input"
                                                    type="textarea"
                                                    style={{ resize: 'none', height: '108px' }}
                                                    id={`propertyTitleTranslate.${tab.name}`}
                                                    disabled={!selectedGoogleLanguage}
                                                    name={`propertyTitleTranslate.${tab.name}`}
                                                    onChange={(e) => handleInputChange(e.target.value, tab.name)}
                                                    value={propertyTitleTranslateValues?.[tab.name] || ''}
                                                />
                                            </TabPane>
                                        ))}
                                    </TabContent>
                                </Col>
                                <Col sm="12">
                                    <Button color="success" className="btn-label mt-3" onClick={(e) => handleAcceptTranslations()}>
                                        {' '}
                                        <i className="mdi mdi-check label-icon align-middle fs-16 me-2"></i> Prihvati
                                    </Button>
                                </Col>
                            </Row>
                        </React.Fragment>
                    )}
                </CardBody>
            </Card>
        </FormSideContainer>
    )
}

export const ProjectFormDescriptionTabMain = (props) => {
    const {
        tabs,
        activeTab,
        handleTabChange,
        inputs,
        formik,
        subform,
        selectOptions,
        inputComponents,
        activeDescriptionLanguage,
        setActiveDescriptionLanguage,
        activeOfferDescriptionLanguage,
        setActiveOfferDescriptionLanguage,
        languageTabs,
        languageTranslateValues,
        languageSelectOptions,
    } = useContext(ProjectFormDescriptionContext)

    const [useGoogleTranslate, setUseGoogleTranslate] = useState(false)
    const [activeGoogleTab, setActiveGoogleTab] = useState(activeDescriptionLanguage)
    const [googleTabs, setGoogleTabs] = useState(languageTabs)
    const [selectedGoogleLanguage, setSelectedGoogleLanguage] = useState()
    const [propertyDescriptionTranslateValues, setProjectDescriptionTranslateValues] = useState(languageTranslateValues)
    const [googleLanguages, setGoogleLanguages] = useState(languageSelectOptions)

    useEffect(() => {
        if (languageTabs.length) {
            setGoogleTabs(languageTabs)
        }
    }, [languageTabs])

    useEffect(() => {
        if (languageSelectOptions.length) {
            setGoogleLanguages(languageSelectOptions)
        }
    }, [languageSelectOptions])

    if (inputs.mainForm) {
        const projectDescriptionIndex = inputs.mainForm?.findIndex((obj) => obj?.name === projectDescriptionFields.PROJECT_DESCRIPTION)
        const projectOfferDescriptionIndex = inputs.mainForm?.findIndex((obj) => obj?.name === projectDescriptionFields.PROJECT_OFFER_DESCRIPTION)
        if (projectDescriptionIndex !== -1) {
            inputs.mainForm[projectDescriptionIndex].tabs = languageTabs
            inputs.mainForm[projectDescriptionIndex].activeTab = activeDescriptionLanguage
            inputs.mainForm[projectDescriptionIndex].handleTabChange = (tab) => setActiveDescriptionLanguage(tab)
        }

        if (projectOfferDescriptionIndex !== -1) {
            inputs.mainForm[projectOfferDescriptionIndex].tabs = languageTabs
            inputs.mainForm[projectOfferDescriptionIndex].activeTab = activeOfferDescriptionLanguage
            inputs.mainForm[projectOfferDescriptionIndex].handleTabChange = (tab) => setActiveOfferDescriptionLanguage(tab)
        }
    }

    const handleInputChange = (value, language) => {
        setProjectDescriptionTranslateValues((prevState) => {
            return {
                ...prevState,
                [language]: value,
            }
        })
    }

    const handleAcceptTranslations = () => {
        const formvalues = getIn(formik.values?.[subform], realEstateDescriptionFields.PROPERTY_DESCRIPTION)
        const newValues = Object.assign({}, formvalues, propertyDescriptionTranslateValues)

        formik.setFieldValue(`${subform}.${[realEstateDescriptionFields.PROPERTY_DESCRIPTION]}`, newValues)
        setUseGoogleTranslate(false)
        setProjectDescriptionTranslateValues(languageTranslateValues)
    }

    return (
        <FormMainContainer ainContainer tabs={tabs} activeTab={activeTab} handleTabChange={handleTabChange}>
            <RecursiveForm
                inputs={inputs?.mainForm}
                subform={subform}
                formik={formik}
                selectOptions={selectOptions}
                inputComponents={inputComponents}
            />

            {/* TODO: future functionality */}
            {useGoogleTranslate && (
                <React.Fragment>
                    <Row>
                        <Col sm="12">
                            <Select
                                onChange={(e) => {
                                    // formik.setFieldValue(fieldName, e.value)
                                    let filteredTabs = languageTabs.filter((x) => x.name !== e.value)
                                    let filteredValues = filteredTabs.reduce(
                                        (dict, item) => ({
                                            ...dict,
                                            [item.name]: '',
                                        }),
                                        {}
                                    )
                                    setSelectedGoogleLanguage(e)
                                    setGoogleTabs(filteredTabs)
                                    setActiveGoogleTab(filteredTabs[0].name)
                                    setProjectDescriptionTranslateValues(filteredValues)
                                }}
                                onBlur={() => {
                                    // formik.setFieldTouched(fieldName)
                                }}
                                options={googleLanguages ? googleLanguages : []}
                                id="propertyTitleGoogleLanguage"
                                name="propertyTitleGoogleLanguage"
                                // defaultInputValue={formik.values[inputField.name] || ""}
                                value={selectedGoogleLanguage ?? ''}
                                placeholder="Odaberi jezik"
                            />
                        </Col>
                        <Col sm="12">
                            <Button color="edit" className="btn-label my-3" onClick={(e) => e.preventDefault}>
                                {' '}
                                <i className="mdi mdi-arrow-right label-icon align-middle fs-16 me-2"></i> Pošalji
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            <FormTabNavigation
                                customClass="language-input-tabs"
                                tabs={googleTabs}
                                activeTab={activeGoogleTab}
                                handleTabChange={(tab) => setActiveGoogleTab(tab)}
                            />
                            <TabContent activeTab={activeGoogleTab}>
                                {googleTabs?.map((tab) => (
                                    <TabPane key={`propertyTitleGoogleTranslate-${tab.name}`} tabId={tab.name}>
                                        <RichTextEditor
                                            content={propertyDescriptionTranslateValues?.[tab.name] || ''}
                                            disabled={!selectedGoogleLanguage}
                                        />
                                    </TabPane>
                                ))}
                            </TabContent>
                        </Col>
                        <Col sm="12">
                            <Button color="success" className="btn-label mt-3" onClick={(e) => handleAcceptTranslations()}>
                                {' '}
                                <i className="mdi mdi-check label-icon align-middle fs-16 me-2"></i> Prihvati
                            </Button>
                        </Col>
                    </Row>
                </React.Fragment>
            )}
        </FormMainContainer>
    )
}

export default ProjectFormDescriptionTab
