import values from 'lodash/values'
import { stateKey } from './actions'

export const selectUsers = (state) => values(state[stateKey].users)
export const selectUsersFetching = (state) => state[stateKey].fetchingUsers
export const selectUsersError = (state) => state[stateKey].error
export const selectUser = (state) => state[stateKey].user
export const selectUserFetching = (state) => state[stateKey].fetchingUser
export const selectAgencyOffices = (state) => state[stateKey].agencyOffices
export const selectAgencyOfficesFetching = (state) => state[stateKey].fetchingOffices
export const selectAgencyOffice = (state) => state[stateKey].agencyOffice
export const selectAgencyOfficeFetching = (state) => state[stateKey].fetchingOffice
export const selectAgencyPermissions = (state) => state[stateKey].agencyPermissions
export const selectAgencyPermissionsFetching = (state) => state[stateKey].fetchingAgencyPermissions
export const selectAgency = (state) => state[stateKey].agency
export const selectAgencyFetching = (state) => state[stateKey].fetchingAgency
