import { combineActions, handleActions } from "redux-actions";
import { createOfferFailure, createOfferRequest, createOfferSuccess, deleteOfferFailure, deleteOfferRequest, deleteOfferSuccess, fetchOffersFailure, fetchOffersRequest, fetchOffersSuccess, previoewOfferFailure, previoewOfferRequest, previoewOfferSuccess, updateOfferFailure, updateOfferRequest, updateOfferSuccess } from "./actions";

export const initialState = {
    items: {},
    item: {},
    count: 0,
    fetching: false,
    fetchingDetails: false,
    submitting: false,
    error: null,
    errorDetails: null,
}

export default handleActions(
    {
        // [fetchOffersRequest]: state => ({
        //     ...state,
        //     fetching: true,
        //     error: null,
        // }),
        // [fetchOffersSuccess]: (state, { payload }) => ({
        //     ...state,
        //     count: payload?.count ?? 0,
        //     items: payload?.items?.reduce((acc, item) => {
        //         // let parsedItem = JSON.parse(item);
        //         return { ...acc, [item.id]: item }}, {}),
        //     fetching: false
        // }),
        // [fetchOffersFailure]: (state, { payload }) => ({
        //     ...state,
        //     error: payload,
        //     fetching: false
        // }),
        [previoewOfferRequest]: state => ({
            ...state,
            fetchingDetails: true,
            errorDetails: null,
        }),
        [previoewOfferSuccess]: (state, { payload }) => ({ 
            ...state,
            item: payload,
            fetchingDetails: false
        }),
        [previoewOfferFailure]: (state, { payload }) => ({
            ...state,
            errorDetails: payload,
            fetchingDetails: false
        }),
        [combineActions(updateOfferRequest, createOfferRequest)]: (state, { payload }) => ({
            ...state,
            submitting: true,
        }),
        [combineActions(updateOfferSuccess, createOfferSuccess)]: (state, { payload }) => ({
            ...state,
            submitting: false,
        }),
        [combineActions(updateOfferFailure, createOfferFailure)]: (state, { payload }) => ({
            ...state,
            submitting: false,
            error: payload?.response
        }),
        [updateOfferSuccess]: (state, { payload }) => {
            const items = {...state.items};
            // console.log('items ', items)
            // console.log('paylaod', payload)
            items[payload.id] = {
                ...items[payload.id],
                noteTitle: payload.note_title,
                noteDate: payload.note_date,
                
            };
            return {
                ...state,
                items
            }
        },
        [deleteOfferRequest]: state => {
            return {
                ...state,
                error: null,
                deleting: true
            };
        },
        [deleteOfferSuccess]: (state, { payload }) => {
            const items = { ...state.items };
            // console.log('items', items)
            delete items[payload];
            return {
                ...state,
                deleting: false,
                items
            };
        },
        [deleteOfferFailure]: (state, { payload }) => {
            return {
                ...state,
                deleting: false,
                error: payload.response
            }
        }
    },
    initialState);