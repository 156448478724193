import { createAction } from "redux-actions";

const ENTITY = 'DOCUMENTVALUES';
export const stateKey = ENTITY.toLowerCase();

const AVAILABLEENTITIES = 'AVAILABLEENTITIES';
const TYPE = 'TYPE';

export const fetchDocumentValuesAvailableEntities = createAction(`${ENTITY}/${AVAILABLEENTITIES}/FETCH`);
export const fetchDocumentValuesAvailableEntitiesRequest = createAction(`${ENTITY}/${AVAILABLEENTITIES}/FETCH_REQUEST`);
export const fetchDocumentValuesAvailableEntitiesSuccess = createAction(`${ENTITY}/${AVAILABLEENTITIES}/FETCH_SUCCESS`);
export const fetchDocumentValuesAvailableEntitiesFailure = createAction(`${ENTITY}/${AVAILABLEENTITIES}/FETCH_FAILURE`);

export const fetchDocumentValuesType = createAction(`${ENTITY}/${TYPE}/FETCH`);
export const fetchDocumentValuesTypeRequest = createAction(`${ENTITY}/${TYPE}/FETCH_REQUEST`);
export const fetchDocumentValuesTypeSuccess = createAction(`${ENTITY}/${TYPE}/FETCH_SUCCESS`);
export const fetchDocumentValuesTypeFailure = createAction(`${ENTITY}/${TYPE}/FETCH_FAILURE`);