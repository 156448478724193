import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'

const DeleteModal = ({ show, module, onDeleteClick, onCloseClick }) => {
    const { t } = useTranslation()
    const moduleKey = module ? t(`modal.delete.moduleName.${module}`) : null

    return (
        <Modal isOpen={show} toggle={onCloseClick} centered={false}>
            <ModalHeader toggle={onCloseClick}>
                {module ? t('modal.delete.message.view', { moduleKey }) : t('modal.delete.message.list')}
            </ModalHeader>
            <ModalBody className="py-3 px-5">
                <div className="d-flex gap-2 justify-content-end mt-4 mb-2">
                    <Button type="button" color="primary" className="btn-label" data-bs-dismiss="modal" onClick={onCloseClick}>
                        <i className="mdi mdi-close label-icon align-middle fs-16"></i>
                        {t('button.cancel')}
                    </Button>
                    <Button type="button" color="danger" className="btn-label" id="delete-record" onClick={onDeleteClick}>
                        <i className="mdi mdi-trash-can-outline label-icon align-middle fs-16"></i>
                        {t('button.delete')}
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    )
}

DeleteModal.propTypes = {
    onCloseClick: PropTypes.func,
    onDeleteClick: PropTypes.func,
    show: PropTypes.any,
}

export default DeleteModal
