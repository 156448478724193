import { getIn } from 'formik'
import _ from 'lodash'
import { useState } from 'react'
import ReactDragListView from 'react-drag-listview/lib/index.js'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import {
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
    Button,
    Card,
    CardHeader,
    Col,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
} from 'reactstrap'
import { propertyOfferPrintFields } from '../../common/forms/generalRealEstate/fields'
import { selectApplicationAreaUnit, selectApplicationCurrency } from '../../store/settings'
import { renderAreaUnit, renderCurrency, renderCurrencyWithUnit } from '../../utils/property'
import AppPermissionsSwitcher from '../AppPermissionsSwitcher/AppPermissionsSwitcher'
import ModalInputWithCheckBox from './ModalInputWithCheckBox'
import { API_URL } from '../../common/constants'

const OfferPrintSettingsModal = ({ isOpen, toggle, validation, subform, propertySelectOptions }) => {
    const { t } = useTranslation()
    const offerSettingsGroup = getIn(validation.values[`${subform}`], 'offer_settings_group').sort((a, b) => {
        return -(a.property_id - b.property_id)
    })

    const [selectedProperty, setSelectedProperty] = useState(offerSettingsGroup[0])
    const [isAccordionOpen, setAccordionOpen] = useState('')
    const { areaUnit, currency } = useSelector((state) => {
        return {
            currency: selectApplicationCurrency(state),
            areaUnit: selectApplicationAreaUnit(state),
        }
    })

    const dragProps = {
        onDragEnd(fromIndex, toIndex) {
            const data = selectedProperty?.[propertyOfferPrintFields.OFFER_PHOTO_ORDER].split(',')

            const item = data.splice(fromIndex, 1)[0]
            data.splice(toIndex, 0, item)

            setSelectedProperty({
                ...selectedProperty,
                [propertyOfferPrintFields.OFFER_PHOTO_ORDER]: data.join(','),
            })
        },
        nodeSelector: '.drag-node',
        handleSelector: '.mdi-menu',
        lineClassName: 'dmn-photo-drag',
    }

    const handleSettingsSave = () => {
        const otherProperties = offerSettingsGroup.filter((x) => x.property_id !== selectedProperty.property_id)
        const selectedPropertyToSave = {
            ...selectedProperty,
            offer_property_price: selectedProperty.propertyPriceChecked ? selectedProperty.propertyPrice : null,
            offer_property_priceM2: selectedProperty.propertyPriceM2Checked ? selectedProperty.propertyPriceM2 : null,
            offer_property_area: selectedProperty.propertyAreaChecked ? selectedProperty.propertyArea : null,
        }

        validation?.setFieldValue(`${subform}.offer_settings_group`, [...otherProperties, { ...selectedPropertyToSave }])
        toggle()
    }

    const toggleAccordion = (id) => {
        if (isAccordionOpen === id) {
            setAccordionOpen('')
        } else {
            setAccordionOpen(id)
        }
    }

    return (
        <Modal isOpen={isOpen} toggle={toggle} id="secondmodal">
            <ModalHeader toggle={toggle}>{t('modal.offerPrintSettings.title')}</ModalHeader>
            <ModalBody className="">
                <Row>
                    <Col sm={12} className="mb-4">
                        <div className="d-flex justify-content-between">
                            <Label for={propertyOfferPrintFields.AGENT_ID} id={`${propertyOfferPrintFields.PROPERTY_ID}Lbl`}>
                                {t('modal.offerPrintSettings.select.property.title')}
                            </Label>
                        </div>
                        <Select
                            onChange={(e) => {
                                const startingProperty = offerSettingsGroup.find((x) => x.property_id.toString() === e.value)
                                if (startingProperty) {
                                    setSelectedProperty({
                                        ...startingProperty,
                                    })
                                }
                            }}
                            options={propertySelectOptions}
                            name={propertyOfferPrintFields.PROPERTY_ID}
                            id={propertyOfferPrintFields.PROPERTY_ID}
                            value={propertySelectOptions?.find((option) => option.value === selectedProperty?.property_id?.toString()) ?? ''}
                            noOptionsMessage={() => t('form.common.select.noData')}
                            placeholder=""
                        />
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <Accordion id="default-accordion-example" open={isAccordionOpen} toggle={toggleAccordion}>
                            <AccordionItem className="mb-4">
                                <AccordionHeader
                                    className="accordion-header d-flex justify-content-between align-items-center"
                                    id="headingOne"
                                    targetId="1"
                                    style={{ cursor: 'pointer' }}
                                >
                                    <Label className="m-0">{t('modal.offerPrintSettings.select.photos.title')}</Label>
                                </AccordionHeader>
                                <AccordionBody accordionId="1">
                                    <Row className="mt-4">
                                        <Col sm={12} className="fw-light text-secondary">
                                            {!selectedProperty ? 'Odaberite nekretninu' : ''}
                                            {selectedProperty && !selectedProperty[propertyOfferPrintFields.OFFER_PHOTO_ORDER]
                                                ? t('modal.offerPrintSettings.select.photos.message')
                                                : ''}
                                            {selectedProperty && selectedProperty[propertyOfferPrintFields.OFFER_PHOTO_ORDER] ? (
                                                <Row className="dmn-drag-row">
                                                    <ReactDragListView {...dragProps} className="row" tag={Row}>
                                                        {selectedProperty[propertyOfferPrintFields.OFFER_PHOTO_ORDER]?.split(',').map((photo) => {
                                                            const removePhoto = () => {
                                                                const newPhotos = selectedProperty[propertyOfferPrintFields.OFFER_PHOTO_ORDER]
                                                                const formatedPhotos = newPhotos
                                                                    .replace(`${photo}`, '')
                                                                    .replace(',,', ',')
                                                                    .replace(/,\s*$/, '')
                                                                formatedPhotos.startsWith(',')
                                                                    ? setSelectedProperty({
                                                                          ...selectedProperty,
                                                                          [propertyOfferPrintFields.OFFER_PHOTO_ORDER]: formatedPhotos.substring(1),
                                                                      })
                                                                    : setSelectedProperty({
                                                                          ...selectedProperty,
                                                                          [propertyOfferPrintFields.OFFER_PHOTO_ORDER]: formatedPhotos,
                                                                      })
                                                            }
                                                            return (
                                                                <Col sm={4} className="drag-node" key={photo}>
                                                                    <Card>
                                                                        <CardHeader className="bg-dark border-0 px-2 py-1 d-flex align-items-center justify-content-between">
                                                                            <span
                                                                                className="text-white"
                                                                                style={{ cursor: 'move' }}
                                                                                onClick={(e) => e.preventDefault()}
                                                                            >
                                                                                {' '}
                                                                                <i className="mdi mdi-menu fs-19" />{' '}
                                                                            </span>
                                                                            <button
                                                                                className="btn-close btn-close-white"
                                                                                onClick={removePhoto}
                                                                            ></button>
                                                                        </CardHeader>
                                                                        <div className="card-img" style={{ position: 'relative' }}>
                                                                            <img
                                                                                className="img-fluid"
                                                                                src={`${API_URL}v1/file/show/${photo}`}
                                                                                alt="Card cap"
                                                                            />
                                                                        </div>
                                                                    </Card>
                                                                </Col>
                                                            )
                                                        })}
                                                    </ReactDragListView>
                                                </Row>
                                            ) : (
                                                ''
                                            )}
                                        </Col>
                                    </Row>
                                </AccordionBody>
                            </AccordionItem>
                        </Accordion>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} className="mb-4 d-flex align-items-center">
                        <div className="form-check">
                            <Input
                                type="checkbox"
                                className="form-check-input"
                                id={propertyOfferPrintFields.OFFER_INCLUDE_GROUND_PLAN}
                                name={propertyOfferPrintFields.OFFER_INCLUDE_GROUND_PLAN}
                                value={selectedProperty?.[propertyOfferPrintFields.OFFER_INCLUDE_GROUND_PLAN] || ''}
                                onChange={(e) => {
                                    setSelectedProperty({
                                        ...selectedProperty,
                                        [propertyOfferPrintFields.OFFER_INCLUDE_GROUND_PLAN]: e.target.checked,
                                    })
                                }}
                                checked={selectedProperty?.[propertyOfferPrintFields.OFFER_INCLUDE_GROUND_PLAN]}
                            />
                            <Label className="form-check-label" for={propertyOfferPrintFields.OFFER_INCLUDE_GROUND_PLAN}>
                                {t('modal.offerPrintSettings.checkbox.groundPlan')}
                            </Label>
                        </div>
                    </Col>
                    <Col sm={12} className="mb-4 d-flex align-items-center">
                        <div className="form-check">
                            <Input
                                type="checkbox"
                                className="form-check-input"
                                id={propertyOfferPrintFields.OFFER_INCLUDE_WATERMARK}
                                name={propertyOfferPrintFields.OFFER_INCLUDE_WATERMARK}
                                value={selectedProperty?.[propertyOfferPrintFields.OFFER_INCLUDE_WATERMARK] || ''}
                                onChange={(e) => {
                                    setSelectedProperty({
                                        ...selectedProperty,
                                        [propertyOfferPrintFields.OFFER_INCLUDE_WATERMARK]: e.target.checked,
                                    })
                                }}
                                checked={selectedProperty?.[propertyOfferPrintFields.OFFER_INCLUDE_WATERMARK]}
                            />
                            <Label className="form-check-label" for={propertyOfferPrintFields.OFFER_INCLUDE_WATERMARK}>
                                {t('modal.offerPrintSettings.checkbox.watermark')}
                            </Label>
                        </div>
                    </Col>
                    <AppPermissionsSwitcher permission="GoogleMaps" selectedProperty={selectedProperty}>
                        {({ showMaps }) =>
                            showMaps && (
                                <Col sm={12} className="mb-4 d-flex align-items-center">
                                    <div className="form-check">
                                        <Input
                                            type="checkbox"
                                            className="form-check-input"
                                            id={propertyOfferPrintFields.OFFER_INCLUDE_MAP}
                                            name={propertyOfferPrintFields.OFFER_INCLUDE_MAP}
                                            value={selectedProperty?.[propertyOfferPrintFields.OFFER_INCLUDE_MAP] || ''}
                                            onChange={(e) => {
                                                setSelectedProperty({
                                                    ...selectedProperty,
                                                    [propertyOfferPrintFields.OFFER_INCLUDE_MAP]: e.target.checked,
                                                })
                                            }}
                                            checked={selectedProperty?.[propertyOfferPrintFields.OFFER_INCLUDE_MAP]}
                                        />
                                        <Label className="form-check-label" for={propertyOfferPrintFields.OFFER_INCLUDE_MAP}>
                                            {t('modal.offerPrintSettings.checkbox.googleMap')}
                                        </Label>
                                    </div>
                                </Col>
                            )
                        }
                    </AppPermissionsSwitcher>
                    <Col sm={12} className="mb-4 d-flex align-items-center">
                        <div className="form-check">
                            <Input
                                type="checkbox"
                                className="form-check-input"
                                id={propertyOfferPrintFields.OFFER_EXCLUDE_HEADER}
                                name={propertyOfferPrintFields.OFFER_EXCLUDE_HEADER}
                                value={selectedProperty?.[propertyOfferPrintFields.OFFER_EXCLUDE_HEADER] || ''}
                                onChange={(e) => {
                                    setSelectedProperty({
                                        ...selectedProperty,
                                        [propertyOfferPrintFields.OFFER_EXCLUDE_HEADER]: e.target.checked,
                                    })
                                }}
                                checked={selectedProperty?.[propertyOfferPrintFields.OFFER_EXCLUDE_HEADER]}
                            />
                            <Label className="form-check-label" for={propertyOfferPrintFields.OFFER_EXCLUDE_HEADER}>
                                {t('modal.offerPrintSettings.checkbox.header')}
                            </Label>
                        </div>
                    </Col>
                    <Col sm={12} className="mb-4 d-flex align-items-center">
                        <div className="form-check">
                            <Input
                                type="checkbox"
                                className="form-check-input"
                                id={propertyOfferPrintFields.OFFER_EXCLUDE_AGENT}
                                name={propertyOfferPrintFields.OFFER_EXCLUDE_AGENT}
                                value={selectedProperty?.[propertyOfferPrintFields.OFFER_EXCLUDE_AGENT] || ''}
                                onChange={(e) => {
                                    setSelectedProperty({
                                        ...selectedProperty,
                                        [propertyOfferPrintFields.OFFER_EXCLUDE_AGENT]: e.target.checked,
                                    })
                                }}
                                checked={selectedProperty?.[propertyOfferPrintFields.OFFER_EXCLUDE_AGENT]}
                            />
                            <Label className="form-check-label" for={propertyOfferPrintFields.OFFER_EXCLUDE_AGENT}>
                                {t('modal.offerPrintSettings.checkbox.agent')}
                            </Label>
                        </div>
                    </Col>
                </Row>
                <ModalInputWithCheckBox
                    key={propertyOfferPrintFields.OFFER_PROPERTY_PRICE + selectedProperty?.id}
                    selectedProperty={selectedProperty}
                    setSelectedProperty={setSelectedProperty}
                    name={propertyOfferPrintFields.OFFER_PROPERTY_PRICE}
                    checkBoxValue={'propertyPriceChecked'}
                    textBoxvalue={'propertyPrice'}
                >
                    {t('modal.offerPrintSettings.price', { currency: renderCurrency(currency) })}
                </ModalInputWithCheckBox>
                <ModalInputWithCheckBox
                    key={propertyOfferPrintFields.OFFER_PROPERTY_PRICEM2 + selectedProperty?.id}
                    selectedProperty={selectedProperty}
                    setSelectedProperty={setSelectedProperty}
                    name={propertyOfferPrintFields.OFFER_PROPERTY_PRICEM2}
                    checkBoxValue={'propertyPriceM2Checked'}
                    textBoxvalue={'propertyPriceM2'}
                >
                    {t('app.common.pricePerUnit', { currencyWithUnit: renderCurrencyWithUnit(currency, areaUnit) })}
                </ModalInputWithCheckBox>
                <ModalInputWithCheckBox
                    key={propertyOfferPrintFields.OFFER_PROPERTY_AREA + selectedProperty?.id}
                    selectedProperty={selectedProperty}
                    setSelectedProperty={setSelectedProperty}
                    name={propertyOfferPrintFields.OFFER_PROPERTY_AREA}
                    checkBoxValue={'propertyAreaChecked'}
                    textBoxvalue={'propertyArea'}
                >
                    {t('modal.offerPrintSettings.area', { areaUnit: renderAreaUnit(areaUnit) })}
                </ModalInputWithCheckBox>
                <div className="hstack gap-2 justify-content-end">
                    <Button type="submit" color="send" className="btn-label" onClick={() => handleSettingsSave()}>
                        <i className="mdi mdi-check align-bottom label-icon align-middle fs-16 me-2"></i>
                        {t('button.save')}
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default OfferPrintSettingsModal
