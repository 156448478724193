import i18n from 'i18next'
import * as Yup from 'yup'
import { convertNumeralToFloatString, resolveValueByType } from '../../../utils/general'
import { IS_ERSTE, PROPERTY_STATUS, REAL_ESTATE_SERVICE } from '../../constants'
import requestFields from '../../constants/requestFields'
import { propertyResponseFields } from '../../response/property'
import {
    propertyDetailSearchFields,
    propertyExportFields,
    propertyOfferPrintFields,
    propertyTransactionsFields,
    realEstateDescriptionFields,
    realEstateGeneralFields,
    realEstateMaterials3DVideoFields,
} from './fields'

const propertyGeneralValueDefinition = (data) => {
    return {
        [realEstateGeneralFields.PROPERTY_LAND_USAGE_ID]: {
            [realEstateGeneralFields.PROPERTY_LAND_USAGE_ID]: data?.propertyLandusage?.id ?? '',
        },
        [realEstateGeneralFields.PROPERTY_LABEL]: {
            [realEstateGeneralFields.PROPERTY_LABEL]: data?.propertyLabel ?? '',
        },
        [realEstateGeneralFields.PROPERTY_SPACE_ID]: {
            propertyPropertySpace_group:
                data?.propertyPropertySpace?.map((x) => ({
                    property_space_id: x.propertySpace?.id?.toString(),
                    space_count: x.spaceCount,
                    space_area: x.spaceArea,
                })) ?? [],
        },
        [realEstateGeneralFields.PROPERTY_UTILITY_ID]: {
            [realEstateGeneralFields.PROPERTY_UTILITY_ID]: data?.propertyUtility?.map((item) => item.id.toString()) ?? [],
        },
        [realEstateGeneralFields.PROPERTY_SPECIAL_FEATURE_ID]: {
            [realEstateGeneralFields.PROPERTY_SPECIAL_FEATURE_ID]: data?.propertySpecialFeature?.map((item) => item.id.toString()) ?? [],
        },
        [realEstateGeneralFields.PROPERTY_EQUIPMENT_ID]: {
            [realEstateGeneralFields.PROPERTY_EQUIPMENT_ID]: data?.propertyEquipment?.map((item) => item.id?.toString()) ?? [],
        },
        [realEstateGeneralFields.PROPERTY_PERMIT_ID]: {
            [realEstateGeneralFields.PROPERTY_PERMIT_ID]: data?.propertyPermit?.map((item) => item.id?.toString()) ?? [],
        },
        [realEstateGeneralFields.PROPERTY_PUBLIC_TRANSPORTATION_ID]: {
            [realEstateGeneralFields.PROPERTY_PUBLIC_TRANSPORTATION_ID]: data?.propertyPublicTransportation?.map((item) => item.id?.toString()) ?? [],
        },
        [realEstateGeneralFields.PROPERTY_WINDOWS_DOORS_ID]: {
            [realEstateGeneralFields.PROPERTY_WINDOWS_DOORS_ID]: data?.propertyWindowsDoors?.map((item) => item.id?.toString()) ?? [],
        },
        [realEstateGeneralFields.PROPERTY_CONDITION_ID]: {
            [realEstateGeneralFields.PROPERTY_CONDITION_ID]: data?.propertyCondition?.map((item) => item.id?.toString()) ?? [],
        },
        [realEstateGeneralFields.PROPERTY_HEATING_ID]: {
            [realEstateGeneralFields.PROPERTY_HEATING_ID]: data?.propertyHeating?.map((item) => item.id?.toString()) ?? [],
        },
        [realEstateGeneralFields.PROPERTY_ORIENTATION_ID]: {
            [realEstateGeneralFields.PROPERTY_ORIENTATION_ID]: data?.propertyOrientation?.map((item) => item.id?.toString()) ?? [],
        },
        [realEstateGeneralFields.PROPERTY_ACCESS_ID]: {
            [realEstateGeneralFields.PROPERTY_ACCESS_ID]: data?.propertyAccess?.map((item) => item.id?.toString()) ?? [],
        },
        [realEstateGeneralFields.PROPERTY_DESCRIPTION]: {
            [realEstateGeneralFields.PROPERTY_DESCRIPTION]: data?.propertyDescriptions?.map((item) => item.id?.toString()) ?? [],
        },
        [realEstateGeneralFields.PROPERTY_FLOOR_POSITION_ID]: {
            [realEstateGeneralFields.PROPERTY_FLOOR_POSITION_ID]: data?.propertyFloorPosition?.map((item) => item.id?.toString()) ?? [],
        },
    }
}

const propertySharedInitialValues = (data, isSale) => {
    const valueDefinition = propertyGeneralValueDefinition(data)

    const requestVatField = isSale ? 'VAT_INCLUDED' : 'VAT_NOT_INCLUDED'
    const responseVatField = isSale ? 'vatIncluded' : 'vatNotIncluded'

    return {
        [realEstateGeneralFields.ID]: data?.id ?? 0,
        [realEstateGeneralFields.PROPERTY_SERVICE_ID]: data?.propertyService?.id ?? '',
        [realEstateGeneralFields.PROPERTY_TYPE_ID]: data?.propertyType?.id ?? '',
        [realEstateGeneralFields.PROPERTY_SUB_TYPE_ID]: data?.propertySubType?.id ?? '',
        ...(IS_ERSTE && { [realEstateGeneralFields.PROPERTY_SET_ID]: data?.propertySet?.id ?? '' }),
        [realEstateGeneralFields.INTERNAL_ID]: data?.internalId ?? '',
        [realEstateGeneralFields.PROPERTY_RECEIPT_DATE]: data?.propertyReceiptDate ?? '',
        [realEstateGeneralFields.CONTRACT_NO]: data?.contractNo ?? '',
        [realEstateGeneralFields.CONTRACT_DATE]: data?.contractDate ?? '',
        [realEstateGeneralFields.OWNER_ID]: resolveValueByType(data?.owner, realEstateGeneralFields.OWNER_ID),
        [realEstateGeneralFields.PROJECT_ID]: data?.project?.id ?? '',
        [requestFields.PROPERTY_SUPPLY_SOURCE_ID]: data?.propertySupplySource?.id?.toString() ?? '',
        [realEstateGeneralFields.COUNTRY_ID]: data?.country?.id?.toString() ?? '',
        [realEstateGeneralFields.COUNTY_ID]: data?.county?.id ?? '',
        [realEstateGeneralFields.CITY_ID]: data?.city?.id ?? '',
        [realEstateGeneralFields.WIDER_AREA_ID]: data?.widerArea?.id ?? '',
        [realEstateGeneralFields.QUARTER_ID]: data?.quarter?.id ?? '',
        [realEstateGeneralFields.ISLAND_ID]: data?.island?.id ?? '',
        [realEstateGeneralFields.STREET]: data?.street ?? '',
        [realEstateGeneralFields.STREET_NUMBER]: data?.streetNumber ?? '',
        [realEstateGeneralFields.STREET_SYNC]: data?.streetSync ?? 0,
        [realEstateGeneralFields.PRICE]: data?.price?.toString().replace(/\./g, ',') ?? '',
        [realEstateGeneralFields.PRICE_CLIENT]: data?.priceClient?.toString().replace(/\./g, ',') ?? '',
        [realEstateGeneralFields.PRICE_M2]: data?.priceM2?.toString().replace(/\./g, ',') ?? '',
        [realEstateGeneralFields.PRICE_OLD]: data?.priceOld?.toString().replace(/\./g, ',') ?? '',
        [realEstateGeneralFields.PRICE_ON_REQUEST]: data?.priceOnRequest ?? false,
        [realEstateGeneralFields.DISTANCE_SEA]: data?.distanceSea ?? '',
        [realEstateGeneralFields.DISTANCE_RIVER]: data?.distanceRiver ?? '',
        [realEstateGeneralFields.DISTANCE_BEACH]: data?.distanceBeach ?? '',
        [realEstateGeneralFields.DISTANCE_CITY_CENTRE]: data?.distanceCityCentre ?? '',
        [realEstateGeneralFields.DISTANCE_SCHOOL]: data?.distanceSchool ?? '',
        [realEstateGeneralFields.DISTANCE_PUBLIC_TRANSPORTATION]: data?.distancePublicTransportation ?? '',
        [realEstateGeneralFields.AGENCY_COMMISSION_ID]: data?.agencyCommission?.id?.toString() ?? '',
        [realEstateGeneralFields.AGENT_ID]: data?.agent?.[0]?.id ?? '',
        [`${realEstateGeneralFields.AGENT_ID}_secondary`]: data?.agent?.[1]?.id ?? '',
        [realEstateGeneralFields[requestVatField]]: data?.[responseVatField] ?? false,
        ...valueDefinition[realEstateGeneralFields.PROPERTY_SPECIAL_FEATURE_ID],
        ...valueDefinition[realEstateGeneralFields.PROPERTY_UTILITY_ID],
        ...valueDefinition[realEstateGeneralFields.PROPERTY_EQUIPMENT_ID],
        ...valueDefinition[realEstateGeneralFields.PROPERTY_DESCRIPTION],
    }
}

const propertySharedInitialValidation = (isSale) => {
    const vatField = isSale ? 'VAT_INCLUDED' : 'VAT_NOT_INCLUDED'

    return {
        [realEstateGeneralFields.ID]: Yup.string(),
        [realEstateGeneralFields.PROPERTY_SERVICE_ID]: Yup.string(),
        [realEstateGeneralFields.PROPERTY_TYPE_ID]: Yup.string(),
        [realEstateGeneralFields.PROPERTY_SUB_TYPE_ID]: Yup.string(),
        [realEstateGeneralFields.PROPERTY_SET_ID]: Yup.string(),
        [realEstateGeneralFields.INTERNAL_ID]: Yup.string(),
        [realEstateGeneralFields.CONTRACT_NO]: Yup.string(),
        [realEstateGeneralFields.CONTRACT_DATE]: Yup.string(),
        [realEstateGeneralFields.OWNER_ID]: Yup.array().of(Yup.object({ [realEstateGeneralFields.OWNER_ID]: Yup.string() })),
        [realEstateGeneralFields.DATA_SOURCE_ID]: Yup.string(),
        [realEstateGeneralFields.COUNTRY_ID]: Yup.string().required(i18n.t('validation.message')),
        [realEstateGeneralFields.COUNTY_ID]: Yup.string().required(i18n.t('validation.message')),
        [realEstateGeneralFields.CITY_ID]: Yup.string().required(i18n.t('validation.message')),
        [realEstateGeneralFields.WIDER_AREA_ID]: Yup.string().required(i18n.t('validation.message')),
        [realEstateGeneralFields.STREET]: Yup.string(),
        [realEstateGeneralFields.STREET_NUMBER]: Yup.string(),
        [realEstateGeneralFields.STREET_SYNC]: Yup.number(),
        [realEstateGeneralFields.PRICE]: Yup.string(),
        [realEstateGeneralFields.PRICE_M2]: Yup.string(),
        [realEstateGeneralFields.PRICE_ON_REQUEST]: Yup.bool(),
        [realEstateGeneralFields.PROPERTY_SPECIAL_FEATURE_ID]: Yup.array().of(Yup.string()),
        [realEstateGeneralFields.PROPERTY_UTILITY_ID]: Yup.array().of(Yup.string()),
        [realEstateGeneralFields.PROPERTY_EQUIPMENT_ID]: Yup.array().of(Yup.number()),
        [realEstateGeneralFields.PROPERTY_DESCRIPTION]: Yup.array().of(Yup.number()),
        [realEstateGeneralFields.AGENCY_COMMISSION_ID]: Yup.string(),
        [realEstateGeneralFields.AGENT_ID]: Yup.string(),
        [realEstateGeneralFields[vatField]]: Yup.bool(),
    }
}

const generalRealEstateValidaton = (appSettings, data) => {
    const initialValues = {
        ...propertySharedInitialValues(data),
    }
    const validationSchema = {
        ...propertySharedInitialValidation(),
    }

    const validationObject = {
        initialValues: initialValues,
        validationSchema: Yup.object(validationSchema),
        prepareModelForSubmit: (values) => ({
            id: values[realEstateGeneralFields.ID],
            realEstateTypeId: values[realEstateGeneralFields.PROPERTY_TYPE_ID],
            agencyInternalId: values[realEstateGeneralFields.INTERNAL_ID],
        }),
    }

    return validationObject
}

const generalRealEstateFlatSaleValidaton = (appSettings, data) => {
    const valueDefinition = propertyGeneralValueDefinition(data)

    const validationSchema = {
        ...propertySharedInitialValidation(true),
        [realEstateGeneralFields.PROJECT_ID]: Yup.string(),
        [realEstateGeneralFields.PROPERTY_LABEL]: Yup.string(),
        [realEstateGeneralFields.MORTGAGE_INFO]: Yup.string(),
        [realEstateGeneralFields.EXCHANGE_POSSIBLE]: Yup.bool(),
        [realEstateGeneralFields.AREA]: Yup.string().required(i18n.t('validation.message')),
        [realEstateGeneralFields.PROPERTY_ROOMS_NO_ID]: Yup.string(),
        [realEstateGeneralFields.PROPERTY_ROOMS_TYPE_ID]: Yup.string(),
        [realEstateGeneralFields.BEDROOMS_NO]: Yup.number(),
        [realEstateGeneralFields.BATHROOM_TOILET_NO]: Yup.number(),
        [realEstateGeneralFields.BATHROOM_NO]: Yup.number(),
        [realEstateGeneralFields.TOILET_NO]: Yup.number(),
        [realEstateGeneralFields.CONSTRUCTION_YEAR]: Yup.number(),
        [realEstateGeneralFields.ADAPTATION_YEAR]: Yup.number(),
        [realEstateGeneralFields.PROPERTY_FLOOR_ID]: Yup.string().required(i18n.t('validation.message')),

        [realEstateGeneralFields.FLOOR_NO]: Yup.string().test('FLOOR_NO', i18n.t('validation.message'), function (value) {
            const { property_floor_position_id } = this.parent
            if (property_floor_position_id?.length === 0) return value != null
            return true
        }),
        [realEstateGeneralFields.FLOORS_TOTAL_NO]: Yup.string(),
        [realEstateGeneralFields.PROPERTY_ORIENTATION_ID]: Yup.array().of(Yup.number()),
        [realEstateGeneralFields.PROPERTY_HEATING_ID]: Yup.array().of(Yup.number()),
        [realEstateGeneralFields.PROPERTY_CONDITION_ID]: Yup.array().of(Yup.number()),
        [realEstateGeneralFields.PROPERTY_WINDOWS_DOORS_ID]: Yup.array().of(Yup.number()),
        [realEstateGeneralFields.PROPERTY_PUBLIC_TRANSPORTATION_ID]: Yup.array().of(Yup.number()),
        [realEstateGeneralFields.PROPERTY_PERMIT_ID]: Yup.array().of(Yup.number()),
        [realEstateGeneralFields.PROPERTY_ENERGY_EFFICIENCY_ID]: Yup.string(),
        [realEstateGeneralFields.PROPERTY_FLOOR_POSITION_ID]: Yup.array().when('floor_no', {
            is: (floor_no) => !floor_no,
            then: Yup.array().min(1, i18n.t('validation.message')),
        }),
    }
    const initialValues = {
        ...propertySharedInitialValues(data, true),
        ...valueDefinition[realEstateGeneralFields.PROPERTY_LABEL],
        [realEstateGeneralFields.MAINTENCE_COSTS]: data?.maintenanceCosts?.toString().replace(/\./g, ',') ?? '',
        [realEstateGeneralFields.MAINTENCE_COSTS_TYPE]: data?.maintenanceCostsType ?? '€/m2',
        [realEstateGeneralFields.MORTGAGE_INFO]: data?.mortgageInfo ?? '',
        [realEstateGeneralFields.EXCHANGE_POSSIBLE]: data?.exchangePossible ?? '',
        [realEstateGeneralFields.AREA]: data?.area?.toString().replace(/\./g, ',') ?? '',
        [realEstateGeneralFields.AREA_NETT]: data?.areaNett?.toString().replace(/\./g, ',') ?? '',
        [realEstateGeneralFields.AREA_GARDEN]: data?.areaGarden?.toString().replace(/\./g, ',') ?? '',
        [realEstateGeneralFields.PROPERTY_ROOMS_NO_ID]: data?.propertyRoomsNo?.id ?? '',
        ...(IS_ERSTE && { [realEstateGeneralFields.PROPERTY_ROOMS_TYPE_ID]: data?.propertyRoomsType?.id ?? '' }),
        [realEstateGeneralFields.BEDROOMS_NO]: data?.bedroomsNo ?? '',
        [realEstateGeneralFields.BATHROOM_TOILET_NO]: data?.bathroomToiletNo ?? '',
        [realEstateGeneralFields.BATHROOM_NO]: data?.bathroomNo ?? '',
        [realEstateGeneralFields.TOILET_NO]: data?.toiletNo ?? '',
        [realEstateGeneralFields.CEILING_HEIGHT]: data?.ceilingHeight ?? '',
        [realEstateGeneralFields.CONSTRUCTION_YEAR]: data?.constructionYear ?? '',
        [realEstateGeneralFields.ADAPTATION_YEAR]: data?.adaptationYear ?? '',
        ...valueDefinition[realEstateGeneralFields.PROPERTY_FLOOR_POSITION_ID],
        [realEstateGeneralFields.PROPERTY_FLOOR_ID]: data?.propertyFloor?.id ?? '',
        [realEstateGeneralFields.FLOOR_NO]: data?.floorNo ?? '',
        [realEstateGeneralFields.FLOORS_TOTAL_NO]: data?.floorsTotalNo ?? '',
        [realEstateGeneralFields.PRICE_INITIAL]: data?.priceInitial?.toString().replace(/\./g, ',') ?? '',
        ...valueDefinition[realEstateGeneralFields.PROPERTY_ORIENTATION_ID],
        ...valueDefinition[realEstateGeneralFields.PROPERTY_HEATING_ID],
        ...valueDefinition[realEstateGeneralFields.PROPERTY_CONDITION_ID],
        ...valueDefinition[realEstateGeneralFields.PROPERTY_WINDOWS_DOORS_ID],
        ...valueDefinition[realEstateGeneralFields.PROPERTY_PUBLIC_TRANSPORTATION_ID],
        ...valueDefinition[realEstateGeneralFields.PROPERTY_PERMIT_ID],
        ...valueDefinition[realEstateGeneralFields.PROPERTY_SPACE_ID],
        [realEstateGeneralFields.PROPERTY_ENERGY_EFFICIENCY_ID]: data?.propertyEnergyEfficiency?.id ?? '',
    }

    const validationObject = {
        initialValues: initialValues,
        validationSchema: Yup.object(validationSchema),
        prepareModelForSubmit: (values) => ({
            ...values,
            [realEstateGeneralFields.MORTGAGE_INFO]: convertNumeralToFloatString(values?.[realEstateGeneralFields.MORTGAGE_INFO]),
            [realEstateGeneralFields.MAINTENCE_COSTS]: convertNumeralToFloatString(values?.[realEstateGeneralFields.MAINTENCE_COSTS]),
            [realEstateGeneralFields.AREA]: convertNumeralToFloatString(values?.[realEstateGeneralFields.AREA]),
            [realEstateGeneralFields.AREA_NETT]: convertNumeralToFloatString(values?.[realEstateGeneralFields.AREA_NETT]),
            [realEstateGeneralFields.AREA_GARDEN]: convertNumeralToFloatString(values?.[realEstateGeneralFields.AREA_GARDEN]),
            [realEstateGeneralFields.CEILING_HEIGHT]: convertNumeralToFloatString(values?.[realEstateGeneralFields.CEILING_HEIGHT]),
            [realEstateGeneralFields.DISTANCE_SEA]: convertNumeralToFloatString(values?.[realEstateGeneralFields.DISTANCE_SEA]),
            [realEstateGeneralFields.DISTANCE_RIVER]: convertNumeralToFloatString(values?.[realEstateGeneralFields.DISTANCE_RIVER]),
            [realEstateGeneralFields.DISTANCE_BEACH]: convertNumeralToFloatString(values?.[realEstateGeneralFields.DISTANCE_BEACH]),
            [realEstateGeneralFields.DISTANCE_CITY_CENTRE]: convertNumeralToFloatString(values?.[realEstateGeneralFields.DISTANCE_CITY_CENTRE]),
            [realEstateGeneralFields.DISTANCE_SCHOOL]: convertNumeralToFloatString(values?.[realEstateGeneralFields.DISTANCE_SCHOOL]),
            [realEstateGeneralFields.DISTANCE_PUBLIC_TRANSPORTATION]: convertNumeralToFloatString(
                values?.[realEstateGeneralFields.DISTANCE_PUBLIC_TRANSPORTATION]
            ),
        }),
    }

    return validationObject
}

const generalRealEstateFlatLeaseValidaton = (appSettings, data) => {
    const valueDefinition = propertyGeneralValueDefinition(data)
    const validationSchema = {
        ...propertySharedInitialValidation(false),
        [realEstateGeneralFields.PROPERTY_LABEL]: Yup.string(),
        [realEstateGeneralFields.PARKING_SPACE_PRICE]: Yup.string(),
        [realEstateGeneralFields.UTILITIES_INCLUDED]: Yup.bool(),
        [realEstateGeneralFields.AREA]: Yup.string().required(i18n.t('validation.message')),
        [realEstateGeneralFields.PROPERTY_ROOMS_NO_ID]: Yup.string(),
        [realEstateGeneralFields.PROPERTY_ROOMS_TYPE_ID]: Yup.string(),
        [realEstateGeneralFields.BEDROOMS_NO]: Yup.number(),
        [realEstateGeneralFields.BATHROOM_TOILET_NO]: Yup.number(),
        [realEstateGeneralFields.BATHROOM_NO]: Yup.number(),
        [realEstateGeneralFields.TOILET_NO]: Yup.number(),
        [realEstateGeneralFields.CONSTRUCTION_YEAR]: Yup.number(),
        [realEstateGeneralFields.ADAPTATION_YEAR]: Yup.number(),
        [realEstateGeneralFields.PROPERTY_FLOOR_ID]: Yup.string(),
        [realEstateGeneralFields.FLOOR_NO]: Yup.string(),
        [realEstateGeneralFields.FLOORS_TOTAL_NO]: Yup.string(),
        [realEstateGeneralFields.PROPERTY_ORIENTATION_ID]: Yup.array().of(Yup.number()),
        [realEstateGeneralFields.PROPERTY_HEATING_ID]: Yup.array().of(Yup.number()),
        [realEstateGeneralFields.PROPERTY_CONDITION_ID]: Yup.array().of(Yup.number()),
        [realEstateGeneralFields.PROPERTY_WINDOWS_DOORS_ID]: Yup.array().of(Yup.number()),
        [realEstateGeneralFields.PROPERTY_PUBLIC_TRANSPORTATION_ID]: Yup.array().of(Yup.number()),
        [realEstateGeneralFields.PROPERTY_ENERGY_EFFICIENCY_ID]: Yup.string(),
    }

    const initialValues = {
        ...propertySharedInitialValues(data, false),
        [realEstateGeneralFields.PROPERTY_LABEL]: data?.propertyLabel ?? '',
        [realEstateGeneralFields.AVAILABLE_FROM_DATE]: data?.rentedToDate ?? '',
        [realEstateGeneralFields.MAINTENCE_COSTS]: data?.maintenanceCosts?.toString().replace(/\./g, ',') ?? '',
        [realEstateGeneralFields.MAINTENCE_COSTS_TYPE]: data?.maintenanceCostsType ?? '€/m2',
        [realEstateGeneralFields.COMMON_UTILITY_COSTS]: data?.commonUtilityCosts?.toString().replace(/\./g, ',') ?? '',
        [realEstateGeneralFields.COMMON_UTILITY_COSTS_TYPE]: data?.commonUtilityCostsType ?? '€/m2',
        [realEstateGeneralFields.PARKING_SPACE_PRICE]: data?.parkingSpacePrice?.toString().replace(/\./g, ',') ?? '',
        [realEstateGeneralFields.UTILITIES_INCLUDED]: data?.utilitiesIncluded ?? '',
        [realEstateGeneralFields.AREA]: data?.area?.toString().replace(/\./g, ',') ?? '',
        [realEstateGeneralFields.AREA_NETT]: data?.areaNett?.toString().replace(/\./g, ',') ?? '',
        [realEstateGeneralFields.AREA_GARDEN]: data?.areaGarden?.toString().replace(/\./g, ',') ?? '',
        [realEstateGeneralFields.PROPERTY_ROOMS_NO_ID]: data?.propertyRoomsNo?.id ?? '',
        ...(IS_ERSTE && { [realEstateGeneralFields.PROPERTY_ROOMS_TYPE_ID]: data?.propertyRoomsType?.id ?? '' }),
        [realEstateGeneralFields.BEDROOMS_NO]: data?.bedroomsNo ?? '',
        [realEstateGeneralFields.BATHROOM_TOILET_NO]: data?.bathroomToiletNo ?? '',
        [realEstateGeneralFields.BATHROOM_NO]: data?.bathroomNo ?? '',
        [realEstateGeneralFields.TOILET_NO]: data?.toiletNo ?? '',
        [realEstateGeneralFields.CEILING_HEIGHT]: data?.ceilingHeight ?? '',
        [realEstateGeneralFields.CONSTRUCTION_YEAR]: data?.constructionYear ?? '',
        [realEstateGeneralFields.ADAPTATION_YEAR]: data?.adaptationYear ?? '',
        ...valueDefinition[realEstateGeneralFields.PROPERTY_FLOOR_POSITION_ID],
        [realEstateGeneralFields.PROPERTY_FLOOR_ID]: data?.propertyFloor?.id ?? '',
        [realEstateGeneralFields.FLOOR_NO]: data?.floorNo ?? '',
        [realEstateGeneralFields.FLOORS_TOTAL_NO]: data?.floorsTotalNo ?? '',
        ...valueDefinition[realEstateGeneralFields.PROPERTY_ORIENTATION_ID],
        ...valueDefinition[realEstateGeneralFields.PROPERTY_HEATING_ID],
        ...valueDefinition[realEstateGeneralFields.PROPERTY_CONDITION_ID],
        ...valueDefinition[realEstateGeneralFields.PROPERTY_WINDOWS_DOORS_ID],
        ...valueDefinition[realEstateGeneralFields.PROPERTY_PUBLIC_TRANSPORTATION_ID],
        ...valueDefinition[realEstateGeneralFields.PROPERTY_SPACE_ID],
        [realEstateGeneralFields.PROPERTY_ENERGY_EFFICIENCY_ID]: data?.propertyEnergyEfficiency?.id ?? '',
        ...valueDefinition[realEstateGeneralFields.PROPERTY_PERMIT_ID],
    }

    const validationObject = {
        initialValues: initialValues,
        validationSchema: Yup.object(validationSchema),
        prepareModelForSubmit: (values) => ({
            ...values,
            [realEstateGeneralFields.MAINTENCE_COSTS]: convertNumeralToFloatString(values?.[realEstateGeneralFields.MAINTENCE_COSTS]),
            [realEstateGeneralFields.COMMON_UTILITY_COSTS]: convertNumeralToFloatString(values?.[realEstateGeneralFields.COMMON_UTILITY_COSTS]),
            [realEstateGeneralFields.DISTANCE_SEA]: convertNumeralToFloatString(values?.[realEstateGeneralFields.DISTANCE_SEA]),
            [realEstateGeneralFields.DISTANCE_RIVER]: convertNumeralToFloatString(values?.[realEstateGeneralFields.DISTANCE_RIVER]),
            [realEstateGeneralFields.DISTANCE_BEACH]: convertNumeralToFloatString(values?.[realEstateGeneralFields.DISTANCE_BEACH]),
            [realEstateGeneralFields.DISTANCE_CITY_CENTRE]: convertNumeralToFloatString(values?.[realEstateGeneralFields.DISTANCE_CITY_CENTRE]),
            [realEstateGeneralFields.DISTANCE_SCHOOL]: convertNumeralToFloatString(values?.[realEstateGeneralFields.DISTANCE_SCHOOL]),
            [realEstateGeneralFields.DISTANCE_PUBLIC_TRANSPORTATION]: convertNumeralToFloatString(
                values?.[realEstateGeneralFields.DISTANCE_PUBLIC_TRANSPORTATION]
            ),
            [realEstateGeneralFields.AREA]: convertNumeralToFloatString(values?.[realEstateGeneralFields.AREA]),
            [realEstateGeneralFields.AREA_NETT]: convertNumeralToFloatString(values?.[realEstateGeneralFields.AREA_NETT]),
            [realEstateGeneralFields.AREA_GARDEN]: convertNumeralToFloatString(values?.[realEstateGeneralFields.AREA_GARDEN]),
            [realEstateGeneralFields.CEILING_HEIGHT]: convertNumeralToFloatString(values?.[realEstateGeneralFields.CEILING_HEIGHT]),
            [realEstateGeneralFields.PARKING_SPACE_PRICE]: convertNumeralToFloatString(values?.[realEstateGeneralFields.PARKING_SPACE_PRICE]),
        }),
    }

    return validationObject
}

const propertyGeneralHouseSaleValidaton = (appSettings, data) => {
    const valueDefinition = propertyGeneralValueDefinition(data)
    const validationSchema = {
        ...propertySharedInitialValidation(true),
        [realEstateGeneralFields.PROJECT_ID]: Yup.string(),
        [realEstateGeneralFields.PROPERTY_HOUSE_TYPE_ID]: Yup.string(),
        [realEstateGeneralFields.MORTGAGE_INFO]: Yup.string(),
        [realEstateGeneralFields.EXCHANGE_POSSIBLE]: Yup.bool(),
        [realEstateGeneralFields.AREA]: Yup.string().required(i18n.t('validation.message')),
        [realEstateGeneralFields.ROOMS_NO]: Yup.string(),
        [realEstateGeneralFields.BEDROOMS_NO]: Yup.number(),
        [realEstateGeneralFields.BED_NO]: Yup.number(),
        [realEstateGeneralFields.BATHROOM_TOILET_NO]: Yup.number(),
        [realEstateGeneralFields.BATHROOM_NO]: Yup.number(),
        [realEstateGeneralFields.TOILET_NO]: Yup.number(),
        [realEstateGeneralFields.CONSTRUCTION_YEAR]: Yup.number(),
        [realEstateGeneralFields.ADAPTATION_YEAR]: Yup.number(),
        [realEstateGeneralFields.PROPERTY_FLOOR_ID]: Yup.string().required(i18n.t('validation.message')),
        [realEstateGeneralFields.PROPERTY_ORIENTATION_ID]: Yup.array().of(Yup.number()),
        [realEstateGeneralFields.PROPERTY_HEATING_ID]: Yup.array().of(Yup.number()),
        [realEstateGeneralFields.PROPERTY_CONDITION_ID]: Yup.array().of(Yup.number()),
        [realEstateGeneralFields.PROPERTY_WINDOWS_DOORS_ID]: Yup.array().of(Yup.number()),
        [realEstateGeneralFields.PROPERTY_PUBLIC_TRANSPORTATION_ID]: Yup.array().of(Yup.number()),
        [realEstateGeneralFields.PROPERTY_PERMIT_ID]: Yup.array().of(Yup.number()),
        [realEstateGeneralFields.PROPERTY_ENERGY_EFFICIENCY_ID]: Yup.string(),
    }

    const initialValues = {
        ...propertySharedInitialValues(data, true),
        [realEstateGeneralFields.PROPERTY_HOUSE_TYPE_ID]: data?.propertyHouseType?.id ?? '',
        [realEstateGeneralFields.MORTGAGE_INFO]: data?.mortgageInfo ?? '',
        [realEstateGeneralFields.EXCHANGE_POSSIBLE]: data?.exchangePossible ?? '',
        [realEstateGeneralFields.AREA]: data?.area?.toString().replace(/\./g, ',') ?? '',
        [realEstateGeneralFields.AREA_NETT]: data?.areaNett?.toString().replace(/\./g, ',') ?? '',
        [realEstateGeneralFields.AREA_GARDEN]: data?.areaGarden?.toString().replace(/\./g, ',') ?? '',
        [realEstateGeneralFields.PLOT_AREA]: data?.plotArea?.toString().replace(/\./g, ',') ?? '',
        [realEstateGeneralFields.ROOMS_NO]: data?.roomsNo ?? '',
        [realEstateGeneralFields.BEDROOMS_NO]: data?.bedroomsNo ?? '',
        [realEstateGeneralFields.HOUSING_UNITS_NUMBER]: data?.housingUnitsNumber ?? '',
        [realEstateGeneralFields.BED_NO]: data?.bedNo ?? '',
        [realEstateGeneralFields.BATHROOM_TOILET_NO]: data?.bathroomToiletNo ?? '',
        [realEstateGeneralFields.BATHROOM_NO]: data?.bathroomNo ?? '',
        [realEstateGeneralFields.TOILET_NO]: data?.toiletNo ?? '',
        [realEstateGeneralFields.CEILING_HEIGHT]: data?.ceilingHeight ?? '',
        [realEstateGeneralFields.CONSTRUCTION_YEAR]: data?.constructionYear ?? '',
        [realEstateGeneralFields.ADAPTATION_YEAR]: data?.adaptationYear ?? '',
        [realEstateGeneralFields.PROPERTY_FLOOR_ID]: data?.propertyFloor?.id ?? '',
        [realEstateGeneralFields.PRICE_INITIAL]: data?.priceInitial?.toString().replace(/\./g, ',') ?? '',
        ...valueDefinition[realEstateGeneralFields.PROPERTY_ORIENTATION_ID],
        ...valueDefinition[realEstateGeneralFields.PROPERTY_HEATING_ID],
        ...valueDefinition[realEstateGeneralFields.PROPERTY_CONDITION_ID],
        ...valueDefinition[realEstateGeneralFields.PROPERTY_WINDOWS_DOORS_ID],
        ...valueDefinition[realEstateGeneralFields.PROPERTY_PUBLIC_TRANSPORTATION_ID],
        ...valueDefinition[realEstateGeneralFields.PROPERTY_PERMIT_ID],
        ...valueDefinition[realEstateGeneralFields.PROPERTY_SPACE_ID],
        [realEstateGeneralFields.PROPERTY_ENERGY_EFFICIENCY_ID]: data?.propertyEnergyEfficiency?.id ?? '',
    }

    const validationObject = {
        initialValues: initialValues,
        validationSchema: Yup.object(validationSchema),
        prepareModelForSubmit: (values) => ({
            ...values,
            [realEstateGeneralFields.MORTGAGE_INFO]: convertNumeralToFloatString(values?.[realEstateGeneralFields.MORTGAGE_INFO]),
            [realEstateGeneralFields.CEILING_HEIGHT]: convertNumeralToFloatString(values?.[realEstateGeneralFields.CEILING_HEIGHT]),
            [realEstateGeneralFields.DISTANCE_SEA]: convertNumeralToFloatString(values?.[realEstateGeneralFields.DISTANCE_SEA]),
            [realEstateGeneralFields.DISTANCE_RIVER]: convertNumeralToFloatString(values?.[realEstateGeneralFields.DISTANCE_RIVER]),
            [realEstateGeneralFields.DISTANCE_BEACH]: convertNumeralToFloatString(values?.[realEstateGeneralFields.DISTANCE_BEACH]),
            [realEstateGeneralFields.DISTANCE_CITY_CENTRE]: convertNumeralToFloatString(values?.[realEstateGeneralFields.DISTANCE_CITY_CENTRE]),
            [realEstateGeneralFields.DISTANCE_SCHOOL]: convertNumeralToFloatString(values?.[realEstateGeneralFields.DISTANCE_SCHOOL]),
            [realEstateGeneralFields.DISTANCE_PUBLIC_TRANSPORTATION]: convertNumeralToFloatString(
                values?.[realEstateGeneralFields.DISTANCE_PUBLIC_TRANSPORTATION]
            ),
            [realEstateGeneralFields.AREA]: convertNumeralToFloatString(values?.[realEstateGeneralFields.AREA]),
            [realEstateGeneralFields.AREA_NETT]: convertNumeralToFloatString(values?.[realEstateGeneralFields.AREA_NETT]),
            [realEstateGeneralFields.AREA_GARDEN]: convertNumeralToFloatString(values?.[realEstateGeneralFields.AREA_GARDEN]),
            [realEstateGeneralFields.PLOT_AREA]: convertNumeralToFloatString(values?.[realEstateGeneralFields.PLOT_AREA]),
        }),
    }

    return validationObject
}

const propertyGeneralHouseLeaseValidaton = (appSettings, data) => {
    const valueDefinition = propertyGeneralValueDefinition(data)
    const validationSchema = {
        ...propertySharedInitialValidation(false),
        [realEstateGeneralFields.UTILITIES_INCLUDED]: Yup.bool(),
        [realEstateGeneralFields.AREA]: Yup.string().required(i18n.t('validation.message')),
        [realEstateGeneralFields.PROPERTY_HOUSE_TYPE_ID]: Yup.string(),
        [realEstateGeneralFields.ROOMS_NO]: Yup.string(),
        [realEstateGeneralFields.BEDROOMS_NO]: Yup.number(),
        [realEstateGeneralFields.BED_NO]: Yup.number(),
        [realEstateGeneralFields.BATHROOM_TOILET_NO]: Yup.number(),
        [realEstateGeneralFields.BATHROOM_NO]: Yup.number(),
        [realEstateGeneralFields.TOILET_NO]: Yup.number(),
        [realEstateGeneralFields.CONSTRUCTION_YEAR]: Yup.number(),
        [realEstateGeneralFields.ADAPTATION_YEAR]: Yup.number(),
        [realEstateGeneralFields.PROPERTY_FLOOR_ID]: Yup.string(),
        [realEstateGeneralFields.PROPERTY_ORIENTATION_ID]: Yup.array().of(Yup.number()),
        [realEstateGeneralFields.PROPERTY_HEATING_ID]: Yup.array().of(Yup.number()),
        [realEstateGeneralFields.PROPERTY_CONDITION_ID]: Yup.array().of(Yup.number()),
        [realEstateGeneralFields.PROPERTY_WINDOWS_DOORS_ID]: Yup.array().of(Yup.number()),
        [realEstateGeneralFields.PROPERTY_PUBLIC_TRANSPORTATION_ID]: Yup.array().of(Yup.number()),
        [realEstateGeneralFields.PROPERTY_ENERGY_EFFICIENCY_ID]: Yup.string(),
    }

    const initialValues = {
        ...propertySharedInitialValues(data, false),
        [realEstateGeneralFields.PROPERTY_HOUSE_TYPE_ID]: data?.propertyHouseType?.id ?? '',
        [realEstateGeneralFields.AVAILABLE_FROM_DATE]: data?.rentedToDate ?? '',
        [realEstateGeneralFields.UTILITIES_INCLUDED]: data?.utilitiesIncluded ?? false,
        [realEstateGeneralFields.AREA]: data?.area?.toString().replace(/\./g, ',') ?? '',
        [realEstateGeneralFields.AREA_NETT]: data?.areaNett?.toString().replace(/\./g, ',') ?? '',
        [realEstateGeneralFields.AREA_GARDEN]: data?.areaGarden?.toString().replace(/\./g, ',') ?? '',
        [realEstateGeneralFields.PLOT_AREA]: data?.plotArea?.toString().replace(/\./g, ',') ?? '',
        [realEstateGeneralFields.ROOMS_NO]: data?.roomsNo ?? '',
        [realEstateGeneralFields.BEDROOMS_NO]: data?.bedroomsNo ?? '',
        [realEstateGeneralFields.HOUSING_UNITS_NUMBER]: data?.housingUnitsNumber ?? '',
        [realEstateGeneralFields.BED_NO]: data?.bedNo ?? '',
        [realEstateGeneralFields.BATHROOM_TOILET_NO]: data?.bathroomToiletNo ?? '',
        [realEstateGeneralFields.BATHROOM_NO]: data?.bathroomNo ?? '',
        [realEstateGeneralFields.TOILET_NO]: data?.toiletNo ?? '',
        [realEstateGeneralFields.CEILING_HEIGHT]: data?.ceilingHeight ?? '',
        [realEstateGeneralFields.CONSTRUCTION_YEAR]: data?.constructionYear ?? '',
        [realEstateGeneralFields.ADAPTATION_YEAR]: data?.adaptationYear ?? '',
        [realEstateGeneralFields.PROPERTY_FLOOR_ID]: data?.propertyFloor?.id ?? '',
        ...valueDefinition[realEstateGeneralFields.PROPERTY_ORIENTATION_ID],
        ...valueDefinition[realEstateGeneralFields.PROPERTY_HEATING_ID],
        ...valueDefinition[realEstateGeneralFields.PROPERTY_CONDITION_ID],
        ...valueDefinition[realEstateGeneralFields.PROPERTY_WINDOWS_DOORS_ID],
        ...valueDefinition[realEstateGeneralFields.PROPERTY_PUBLIC_TRANSPORTATION_ID],
        ...valueDefinition[realEstateGeneralFields.PROPERTY_SPACE_ID],
        ...valueDefinition[realEstateGeneralFields.PROPERTY_PERMIT_ID],
        [realEstateGeneralFields.PROPERTY_ENERGY_EFFICIENCY_ID]: data?.propertyEnergyEfficiency?.id ?? '',
    }

    const validationObject = {
        initialValues: initialValues,
        validationSchema: Yup.object(validationSchema),
        prepareModelForSubmit: (values) => ({
            ...values,
            [realEstateGeneralFields.CEILING_HEIGHT]: convertNumeralToFloatString(values?.[realEstateGeneralFields.CEILING_HEIGHT]),
            [realEstateGeneralFields.DISTANCE_SEA]: convertNumeralToFloatString(values?.[realEstateGeneralFields.DISTANCE_SEA]),
            [realEstateGeneralFields.DISTANCE_RIVER]: convertNumeralToFloatString(values?.[realEstateGeneralFields.DISTANCE_RIVER]),
            [realEstateGeneralFields.DISTANCE_BEACH]: convertNumeralToFloatString(values?.[realEstateGeneralFields.DISTANCE_BEACH]),
            [realEstateGeneralFields.DISTANCE_CITY_CENTRE]: convertNumeralToFloatString(values?.[realEstateGeneralFields.DISTANCE_CITY_CENTRE]),
            [realEstateGeneralFields.DISTANCE_SCHOOL]: convertNumeralToFloatString(values?.[realEstateGeneralFields.DISTANCE_SCHOOL]),
            [realEstateGeneralFields.DISTANCE_PUBLIC_TRANSPORTATION]: convertNumeralToFloatString(
                values?.[realEstateGeneralFields.DISTANCE_PUBLIC_TRANSPORTATION]
            ),
            [realEstateGeneralFields.AREA]: convertNumeralToFloatString(values?.[realEstateGeneralFields.AREA]),
            [realEstateGeneralFields.AREA_NETT]: convertNumeralToFloatString(values?.[realEstateGeneralFields.AREA_NETT]),
            [realEstateGeneralFields.AREA_GARDEN]: convertNumeralToFloatString(values?.[realEstateGeneralFields.AREA_GARDEN]),
            [realEstateGeneralFields.PLOT_AREA]: convertNumeralToFloatString(values?.[realEstateGeneralFields.PLOT_AREA]),
        }),
    }

    return validationObject
}

const propertyGeneralOfficeSpaceSaleValidaton = (appSettings, data) => {
    const valueDefinition = propertyGeneralValueDefinition(data)

    const validationSchema = {
        ...propertySharedInitialValidation(true),
        ...propertyOfficeSpaceFloorGroupValidation(),
        [realEstateGeneralFields.PROJECT_ID]: Yup.string(),
        [realEstateGeneralFields.PROPERTY_LABEL]: Yup.string(),
        [realEstateGeneralFields.PROPERTY_POSITION_ID]: Yup.number(),
        [realEstateGeneralFields.MORTGAGE_INFO]: Yup.string(),
        [realEstateGeneralFields.EXCHANGE_POSSIBLE]: Yup.bool(),
        [realEstateGeneralFields.AREA]: Yup.string().required(i18n.t('validation.message')),
        [realEstateGeneralFields.ROOMS_NO]: Yup.string().required(i18n.t('validation.message')),
        [realEstateGeneralFields.COMMON_BATHROOM_NO]: Yup.number(),
        [realEstateGeneralFields.TOILET_NO]: Yup.number(),
        [realEstateGeneralFields.CONSTRUCTION_YEAR]: Yup.number(),
        [realEstateGeneralFields.ADAPTATION_YEAR]: Yup.number(),
        [realEstateGeneralFields.FLOORS_TOTAL_NO]: Yup.number(),
        [realEstateGeneralFields.PROPERTY_ORIENTATION_ID]: Yup.array().of(Yup.number()),
        [realEstateGeneralFields.PROPERTY_HEATING_ID]: Yup.array().of(Yup.number()),
        [realEstateGeneralFields.PROPERTY_CONDITION_ID]: Yup.array().of(Yup.number()),
        [realEstateGeneralFields.PROPERTY_WINDOWS_DOORS_ID]: Yup.array().of(Yup.number()),
        [realEstateGeneralFields.PROPERTY_PUBLIC_TRANSPORTATION_ID]: Yup.array().of(Yup.number()),
        [realEstateGeneralFields.PROPERTY_PERMIT_ID]: Yup.array().of(Yup.number()),
        [realEstateGeneralFields.PROPERTY_ENERGY_EFFICIENCY_ID]: Yup.string(),
        [realEstateGeneralFields.PROPERTY_ACCESS_ID]: Yup.array().of(Yup.number()),
    }

    const initialValues = {
        ...propertySharedInitialValues(data, true),
        [realEstateGeneralFields.PROPERTY_PRIMARY_USAGE_ID]: data?.propertyPrimaryUsage?.id ?? '',
        [realEstateGeneralFields.PROPERTY_LABEL]: data?.propertyLabel ?? '',
        [realEstateGeneralFields.PROPERTY_POSITION_ID]: data?.propertyPosition?.id ?? '',
        [realEstateGeneralFields.MORTGAGE_INFO]: data?.mortgageInfo ?? '',
        [realEstateGeneralFields.EXCHANGE_POSSIBLE]: data?.exchangePossible ?? '',
        [realEstateGeneralFields.AREA]: data?.area?.toString().replace(/\./g, ',') ?? '',
        [realEstateGeneralFields.PLOT_AREA]: data?.plotArea?.toString().replace(/\./g, ',') ?? '',
        [realEstateGeneralFields.AREA_GARDEN]: data?.areaGarden?.toString().replace(/\./g, ',') ?? '',
        [realEstateGeneralFields.AREA_STORAGE]: data?.areaStorage?.toString().replace(/\./g, ',') ?? '',
        [realEstateGeneralFields.PARKING_SPACE_NUMBER]: data?.parkingSpaceNumber ?? '',
        [realEstateGeneralFields.ROOMS_NO]: data?.roomsNo ?? '',
        [realEstateGeneralFields.COMMON_BATHROOM_NO]: data?.commBathroomNo ?? '',
        [realEstateGeneralFields.TOILET_NO]: data?.toiletNo ?? '',
        [realEstateGeneralFields.CEILING_HEIGHT]: data?.ceilingHeight ?? '',
        [realEstateGeneralFields.CONSTRUCTION_YEAR]: data?.constructionYear ?? '',
        [realEstateGeneralFields.ADAPTATION_YEAR]: data?.adaptationYear ?? '',
        [realEstateGeneralFields.FLOOR_NO]: data?.floorNo ?? '',
        [realEstateGeneralFields.FLOORS_TOTAL_NO]: data?.floorsTotalNo ?? '',
        [realEstateGeneralFields.PRICE_INITIAL]: data?.priceInitial?.toString().replace(/\./g, ',') ?? '',
        [realEstateGeneralFields.PROPERTY_ENERGY_EFFICIENCY_ID]: data?.propertyEnergyEfficiency?.id ?? '',
        [realEstateGeneralFields.MAINTENCE_COSTS]: data?.maintenanceCosts?.toString().replace(/\./g, ',') ?? '',
        [realEstateGeneralFields.MAINTENCE_COSTS_TYPE]: data?.maintenanceCostsType ?? '€/m2',
        ...valueDefinition[realEstateGeneralFields.PROPERTY_FLOOR_POSITION_ID],
        ...valueDefinition[realEstateGeneralFields.PROPERTY_ORIENTATION_ID],
        ...valueDefinition[realEstateGeneralFields.PROPERTY_HEATING_ID],
        ...valueDefinition[realEstateGeneralFields.PROPERTY_CONDITION_ID],
        ...valueDefinition[realEstateGeneralFields.PROPERTY_WINDOWS_DOORS_ID],
        ...valueDefinition[realEstateGeneralFields.PROPERTY_PUBLIC_TRANSPORTATION_ID],
        ...valueDefinition[realEstateGeneralFields.PROPERTY_PERMIT_ID],
        ...valueDefinition[realEstateGeneralFields.PROPERTY_SPACE_ID],
        ...valueDefinition[realEstateGeneralFields.PROPERTY_ACCESS_ID],
    }

    const validationObject = {
        initialValues: initialValues,
        validationSchema: Yup.object(validationSchema),
        prepareModelForSubmit: (values) => ({
            ...values,
            [realEstateGeneralFields.MORTGAGE_INFO]: convertNumeralToFloatString(values?.[realEstateGeneralFields.MORTGAGE_INFO]),
            [realEstateGeneralFields.CEILING_HEIGHT]: convertNumeralToFloatString(values?.[realEstateGeneralFields.CEILING_HEIGHT]),
            [realEstateGeneralFields.DISTANCE_SEA]: convertNumeralToFloatString(values?.[realEstateGeneralFields.DISTANCE_SEA]),
            [realEstateGeneralFields.DISTANCE_RIVER]: convertNumeralToFloatString(values?.[realEstateGeneralFields.DISTANCE_RIVER]),
            [realEstateGeneralFields.DISTANCE_BEACH]: convertNumeralToFloatString(values?.[realEstateGeneralFields.DISTANCE_BEACH]),
            [realEstateGeneralFields.DISTANCE_CITY_CENTRE]: convertNumeralToFloatString(values?.[realEstateGeneralFields.DISTANCE_CITY_CENTRE]),
            [realEstateGeneralFields.DISTANCE_SCHOOL]: convertNumeralToFloatString(values?.[realEstateGeneralFields.DISTANCE_SCHOOL]),
            [realEstateGeneralFields.DISTANCE_PUBLIC_TRANSPORTATION]: convertNumeralToFloatString(
                values?.[realEstateGeneralFields.DISTANCE_PUBLIC_TRANSPORTATION]
            ),
            [realEstateGeneralFields.AREA]: convertNumeralToFloatString(values?.[realEstateGeneralFields.AREA]),
            [realEstateGeneralFields.AREA_STORAGE]: convertNumeralToFloatString(values?.[realEstateGeneralFields.AREA_STORAGE]),
            [realEstateGeneralFields.AREA_GARDEN]: convertNumeralToFloatString(values?.[realEstateGeneralFields.AREA_GARDEN]),
            [realEstateGeneralFields.PLOT_AREA]: convertNumeralToFloatString(values?.[realEstateGeneralFields.PLOT_AREA]),
        }),
    }

    return validationObject
}

const propertyGeneralOfficeSpaceLeaseValidaton = (appSettings, data) => {
    const valueDefinition = propertyGeneralValueDefinition(data)

    const validationSchema = {
        ...propertySharedInitialValidation(false),
        ...propertyOfficeSpaceFloorGroupValidation(),
        [realEstateGeneralFields.PROPERTY_LABEL]: Yup.string(),
        [realEstateGeneralFields.PROPERTY_POSITION_ID]: Yup.number(),
        [realEstateGeneralFields.PARKING_SPACE_PRICE]: Yup.number(),
        [realEstateGeneralFields.UTILITIES_INCLUDED]: Yup.bool(),
        [realEstateGeneralFields.AREA]: Yup.string().required(i18n.t('validation.message')),
        [realEstateGeneralFields.ROOMS_NO]: Yup.string().required(i18n.t('validation.message')),
        [realEstateGeneralFields.COMMON_BATHROOM_NO]: Yup.number(),
        [realEstateGeneralFields.TOILET_NO]: Yup.number(),
        [realEstateGeneralFields.CONSTRUCTION_YEAR]: Yup.number(),
        [realEstateGeneralFields.ADAPTATION_YEAR]: Yup.number(),
        [realEstateGeneralFields.FLOORS_TOTAL_NO]: Yup.number(),
        [realEstateGeneralFields.PROPERTY_ORIENTATION_ID]: Yup.array().of(Yup.number()),
        [realEstateGeneralFields.PROPERTY_HEATING_ID]: Yup.array().of(Yup.number()),
        [realEstateGeneralFields.PROPERTY_CONDITION_ID]: Yup.array().of(Yup.number()),
        [realEstateGeneralFields.PROPERTY_WINDOWS_DOORS_ID]: Yup.array().of(Yup.number()),
        [realEstateGeneralFields.PROPERTY_PUBLIC_TRANSPORTATION_ID]: Yup.array().of(Yup.number()),
        [realEstateGeneralFields.PROPERTY_ENERGY_EFFICIENCY_ID]: Yup.string(),
        [realEstateGeneralFields.PROPERTY_ACCESS_ID]: Yup.array().of(Yup.number()),
    }

    const initialValues = {
        ...propertySharedInitialValues(data),
        [realEstateGeneralFields.AVAILABLE_FROM_DATE]: data?.rentedToDate ?? '',
        [realEstateGeneralFields.PROPERTY_PRIMARY_USAGE_ID]: data?.propertyPrimaryUsage?.id ?? '',
        [realEstateGeneralFields.PROPERTY_LABEL]: data?.propertyLabel ?? '',
        [realEstateGeneralFields.PROPERTY_POSITION_ID]: data?.propertyPosition?.id ?? '',
        [realEstateGeneralFields.MAINTENCE_COSTS]: data?.maintenanceCosts?.toString().replace(/\./g, ',') ?? '',
        [realEstateGeneralFields.MAINTENCE_COSTS_TYPE]: data?.maintenanceCostsType ?? '€/m2',
        [realEstateGeneralFields.COMMON_MAINTENCE_COSTS]: data?.commonMaintenanceCosts?.toString().replace(/\./g, ',') ?? '',
        [realEstateGeneralFields.COMMON_MAINTENCE_COSTS_TYPE]: data?.commonMaintenanceCostsType ?? '€/m2',
        [realEstateGeneralFields.COMMON_UTILITY_COSTS]: data?.commonUtilityCosts?.toString().replace(/\./g, ',') ?? '',
        [realEstateGeneralFields.COMMON_UTILITY_COSTS_TYPE]: data?.commonUtilityCostsType ?? '€/m2',
        [realEstateGeneralFields.PARKING_SPACE_PRICE]: data?.parkingSpacePrice?.toString().replace(/\./g, ',') ?? '',
        [realEstateGeneralFields.UTILITIES_INCLUDED]: data?.utilitiesIncluded ?? false,
        [realEstateGeneralFields.AREA]: data?.area?.toString().replace(/\./g, ',') ?? '',
        [realEstateGeneralFields.AREA_GARDEN]: data?.areaGarden?.toString().replace(/\./g, ',') ?? '',
        [realEstateGeneralFields.PLOT_AREA]: data?.plotArea?.toString().replace(/\./g, ',') ?? '',
        [realEstateGeneralFields.AREA_STORAGE]: data?.areaStorage?.toString().replace(/\./g, ',') ?? '',
        [realEstateGeneralFields.PARKING_SPACE_NUMBER]: data?.parkingSpaceNumber ?? '',
        [realEstateGeneralFields.ROOMS_NO]: data?.roomsNo ?? '',
        [realEstateGeneralFields.COMMON_BATHROOM_NO]: data?.commBathroomNo ?? '',
        [realEstateGeneralFields.TOILET_NO]: data?.toiletNo ?? '',
        [realEstateGeneralFields.CEILING_HEIGHT]: data?.ceilingHeight ?? '',
        [realEstateGeneralFields.CONSTRUCTION_YEAR]: data?.constructionYear ?? '',
        [realEstateGeneralFields.ADAPTATION_YEAR]: data?.adaptationYear ?? '',
        [realEstateGeneralFields.FLOOR_NO]: data?.floorNo ?? '',
        [realEstateGeneralFields.FLOORS_TOTAL_NO]: data?.floorsTotalNo ?? '',
        [realEstateGeneralFields.PROPERTY_ENERGY_EFFICIENCY_ID]: data?.propertyEnergyEfficiency?.id ?? '',
        ...valueDefinition[realEstateGeneralFields.PROPERTY_FLOOR_POSITION_ID],
        ...valueDefinition[realEstateGeneralFields.PROPERTY_ORIENTATION_ID],
        ...valueDefinition[realEstateGeneralFields.PROPERTY_HEATING_ID],
        ...valueDefinition[realEstateGeneralFields.PROPERTY_CONDITION_ID],
        ...valueDefinition[realEstateGeneralFields.PROPERTY_WINDOWS_DOORS_ID],
        ...valueDefinition[realEstateGeneralFields.PROPERTY_PUBLIC_TRANSPORTATION_ID],
        ...valueDefinition[realEstateGeneralFields.PROPERTY_PERMIT_ID],
        ...valueDefinition[realEstateGeneralFields.PROPERTY_SPACE_ID],
        ...valueDefinition[realEstateGeneralFields.PROPERTY_ACCESS_ID],
    }

    const validationObject = {
        initialValues: initialValues,
        validationSchema: Yup.object(validationSchema),
        prepareModelForSubmit: (values) => ({
            ...values,
            [realEstateGeneralFields.MAINTENCE_COSTS]: convertNumeralToFloatString(values?.[realEstateGeneralFields.MAINTENCE_COSTS], true),
            [realEstateGeneralFields.COMMON_MAINTENCE_COSTS]: convertNumeralToFloatString(
                values?.[realEstateGeneralFields.COMMON_MAINTENCE_COSTS],
                true
            ),
            [realEstateGeneralFields.COMMON_UTILITY_COSTS]: convertNumeralToFloatString(values?.[realEstateGeneralFields.COMMON_UTILITY_COSTS], true),
            [realEstateGeneralFields.CEILING_HEIGHT]: convertNumeralToFloatString(values?.[realEstateGeneralFields.CEILING_HEIGHT]),
            [realEstateGeneralFields.DISTANCE_SEA]: convertNumeralToFloatString(values?.[realEstateGeneralFields.DISTANCE_SEA]),
            [realEstateGeneralFields.DISTANCE_RIVER]: convertNumeralToFloatString(values?.[realEstateGeneralFields.DISTANCE_RIVER]),
            [realEstateGeneralFields.DISTANCE_BEACH]: convertNumeralToFloatString(values?.[realEstateGeneralFields.DISTANCE_BEACH]),
            [realEstateGeneralFields.DISTANCE_CITY_CENTRE]: convertNumeralToFloatString(values?.[realEstateGeneralFields.DISTANCE_CITY_CENTRE]),
            [realEstateGeneralFields.DISTANCE_SCHOOL]: convertNumeralToFloatString(values?.[realEstateGeneralFields.DISTANCE_SCHOOL]),
            [realEstateGeneralFields.DISTANCE_PUBLIC_TRANSPORTATION]: convertNumeralToFloatString(
                values?.[realEstateGeneralFields.DISTANCE_PUBLIC_TRANSPORTATION]
            ),
            [realEstateGeneralFields.AREA]: convertNumeralToFloatString(values?.[realEstateGeneralFields.AREA]),
            [realEstateGeneralFields.AREA_STORAGE]: convertNumeralToFloatString(values?.[realEstateGeneralFields.AREA_STORAGE]),
            [realEstateGeneralFields.AREA_GARDEN]: convertNumeralToFloatString(values?.[realEstateGeneralFields.AREA_GARDEN]),
            [realEstateGeneralFields.PLOT_AREA]: convertNumeralToFloatString(values?.[realEstateGeneralFields.PLOT_AREA]),
            [realEstateGeneralFields.PARKING_SPACE_PRICE]: convertNumeralToFloatString(values?.[realEstateGeneralFields.PARKING_SPACE_PRICE]),
        }),
    }

    return validationObject
}

// used in office sale & office lease validations
const propertyOfficeSpaceFloorGroupValidation = () => {
    return {
        [realEstateGeneralFields.FLOOR_NO]: Yup.number().test('floor-or-position', i18n.t('validation.message'), (value, context) => {
            const { property_floor_position_id } = context.parent
            return value || property_floor_position_id?.length > 0
        }),

        [realEstateGeneralFields.PROPERTY_FLOOR_POSITION_ID]: Yup.array().test(
            'position-or-floor',
            i18n.t('validation.message'),
            (value, context) => {
                const { floor_no } = context.parent
                return value?.length > 0 || floor_no
            }
        ),
    }
}

const propertyGeneralLandSaleValidaton = (appSettings, data) => {
    const valueDefinition = propertyGeneralValueDefinition(data)
    const validationSchema = {
        ...propertySharedInitialValidation(true),
        [realEstateGeneralFields.PROJECT_ID]: Yup.string(),
        [realEstateGeneralFields.PROPERTY_LAND_USAGE_ID]: Yup.number(),
        [realEstateGeneralFields.MORTGAGE_INFO]: Yup.string(),
        [realEstateGeneralFields.AREA]: Yup.string().required(i18n.t('validation.message')),
        [realEstateGeneralFields.CONSTRUCTION_FLOORS_NO]: Yup.number(),
        [realEstateGeneralFields.PROPERTY_ORIENTATION_ID]: Yup.array().of(Yup.number()),
        [realEstateGeneralFields.PROPERTY_PUBLIC_TRANSPORTATION_ID]: Yup.array().of(Yup.number()),
        [realEstateGeneralFields.PROPERTY_PERMIT_ID]: Yup.array().of(Yup.number()),
        [realEstateGeneralFields.PROPERTY_ACCESS_ID]: Yup.array().of(Yup.number()),
    }

    const initialValues = {
        ...propertySharedInitialValues(data, true),
        [realEstateGeneralFields.PROPERTY_LAND_USAGE_ID]: data?.propertyLandUsage?.id ?? '',
        [realEstateGeneralFields.MORTGAGE_INFO]: data?.mortgageInfo ?? '',
        [realEstateGeneralFields.EXCHANGE_POSSIBLE]: data?.exchangePossible ?? '',
        [realEstateGeneralFields.AREA]: data?.area?.toString().replace(/\./g, ',') ?? '',
        [realEstateGeneralFields.PLOT_WIDTH]: data?.plotWidth ?? '',
        [realEstateGeneralFields.PLOT_LENGTH]: data?.plotLength ?? '',
        [realEstateGeneralFields.CONSTRUCTION_COEFFICIENT]: data?.constructionCoefficient ?? '',
        [realEstateGeneralFields.CONSTRUCTION_FLOORS_NO]: data?.constructionFloorsNo ?? '',
        [realEstateGeneralFields.MAX_CONTRUCTION_AREA]: data?.maxConstructionArea ?? '',
        [realEstateGeneralFields.PRICE_INITIAL]: data?.priceInitial?.toString().replace(/\./g, ',') ?? '',
        ...valueDefinition[realEstateGeneralFields.PROPERTY_ORIENTATION_ID],
        ...valueDefinition[realEstateGeneralFields.PROPERTY_PUBLIC_TRANSPORTATION_ID],
        ...valueDefinition[realEstateGeneralFields.PROPERTY_PERMIT_ID],
        ...valueDefinition[realEstateGeneralFields.PROPERTY_ACCESS_ID],
        [realEstateGeneralFields.DISTANCE_PATHWAY]: data?.distancePathway ?? '',
    }

    const validationObject = {
        initialValues: initialValues,
        validationSchema: Yup.object(validationSchema),
        prepareModelForSubmit: (values) => ({
            ...values,
            [realEstateGeneralFields.MORTGAGE_INFO]: convertNumeralToFloatString(values?.[realEstateGeneralFields.MORTGAGE_INFO]),
            [realEstateGeneralFields.DISTANCE_SEA]: convertNumeralToFloatString(values?.[realEstateGeneralFields.DISTANCE_SEA]),
            [realEstateGeneralFields.DISTANCE_RIVER]: convertNumeralToFloatString(values?.[realEstateGeneralFields.DISTANCE_RIVER]),
            [realEstateGeneralFields.DISTANCE_BEACH]: convertNumeralToFloatString(values?.[realEstateGeneralFields.DISTANCE_BEACH]),
            [realEstateGeneralFields.DISTANCE_CITY_CENTRE]: convertNumeralToFloatString(values?.[realEstateGeneralFields.DISTANCE_CITY_CENTRE]),
            [realEstateGeneralFields.DISTANCE_SCHOOL]: convertNumeralToFloatString(values?.[realEstateGeneralFields.DISTANCE_SCHOOL]),
            [realEstateGeneralFields.DISTANCE_PUBLIC_TRANSPORTATION]: convertNumeralToFloatString(
                values?.[realEstateGeneralFields.DISTANCE_PUBLIC_TRANSPORTATION]
            ),
            [realEstateGeneralFields.DISTANCE_PATHWAY]: convertNumeralToFloatString(values?.[realEstateGeneralFields.DISTANCE_PATHWAY]),
            [realEstateGeneralFields.AREA]: convertNumeralToFloatString(values?.[realEstateGeneralFields.AREA]),
            [realEstateGeneralFields.PLOT_LENGTH]: convertNumeralToFloatString(values?.[realEstateGeneralFields.PLOT_LENGTH]),
            [realEstateGeneralFields.PLOT_WIDTH]: convertNumeralToFloatString(values?.[realEstateGeneralFields.PLOT_WIDTH]),
            [realEstateGeneralFields.MAX_CONTRUCTION_AREA]: convertNumeralToFloatString(values?.[realEstateGeneralFields.MAX_CONTRUCTION_AREA]),
        }),
    }

    return validationObject
}

const propertyGeneralLandLeaseValidaton = (appSettings, data) => {
    const valueDefinition = propertyGeneralValueDefinition(data)
    const validationSchema = {
        ...propertySharedInitialValidation(false),
        [realEstateGeneralFields.PROPERTY_LAND_USAGE_ID]: Yup.number(),
        [realEstateGeneralFields.MORTGAGE_INFO]: Yup.string(),
        [realEstateGeneralFields.AREA]: Yup.string().required(i18n.t('validation.message')),
        [realEstateGeneralFields.CONSTRUCTION_COEFFICIENT]: Yup.number(),
        [realEstateGeneralFields.CONSTRUCTION_FLOORS_NO]: Yup.number(),
        [realEstateGeneralFields.PROPERTY_ORIENTATION_ID]: Yup.array().of(Yup.number()),
        [realEstateGeneralFields.PROPERTY_PUBLIC_TRANSPORTATION_ID]: Yup.array().of(Yup.number()),
        [realEstateGeneralFields.PROPERTY_ACCESS_ID]: Yup.array().of(Yup.number()),
    }

    const initialValues = {
        ...propertySharedInitialValues(data),
        [realEstateGeneralFields.AVAILABLE_FROM_DATE]: data?.rentedToDate ?? '',
        [realEstateGeneralFields.PROPERTY_LAND_USAGE_ID]: data?.propertyLandUsage?.id ?? '',
        [realEstateGeneralFields.MORTGAGE_INFO]: data?.mortgageInfo ?? '',
        [realEstateGeneralFields.AREA]: data?.area?.toString().replace(/\./g, ',') ?? '',
        [realEstateGeneralFields.PLOT_WIDTH]: data?.plotWidth ?? '',
        [realEstateGeneralFields.PLOT_LENGTH]: data?.plotLength ?? '',
        [realEstateGeneralFields.CONSTRUCTION_COEFFICIENT]: data?.constructionCoefficient ?? '',
        [realEstateGeneralFields.CONSTRUCTION_FLOORS_NO]: data?.constructionFloorsNo ?? '',
        [realEstateGeneralFields.MAX_CONTRUCTION_AREA]: data?.maxConstructionArea ?? '',
        ...valueDefinition[realEstateGeneralFields.PROPERTY_ORIENTATION_ID],
        ...valueDefinition[realEstateGeneralFields.PROPERTY_PUBLIC_TRANSPORTATION_ID],
        ...valueDefinition[realEstateGeneralFields.PROPERTY_ACCESS_ID],
        ...valueDefinition[realEstateGeneralFields.PROPERTY_PERMIT_ID],
        [realEstateGeneralFields.DISTANCE_PATHWAY]: data?.distancePathway ?? '',
    }

    const validationObject = {
        initialValues: initialValues,
        validationSchema: Yup.object(validationSchema),
        prepareModelForSubmit: (values) => ({
            ...values,
            [realEstateGeneralFields.DISTANCE_SEA]: convertNumeralToFloatString(values?.[realEstateGeneralFields.DISTANCE_SEA]),
            [realEstateGeneralFields.DISTANCE_RIVER]: convertNumeralToFloatString(values?.[realEstateGeneralFields.DISTANCE_RIVER]),
            [realEstateGeneralFields.DISTANCE_BEACH]: convertNumeralToFloatString(values?.[realEstateGeneralFields.DISTANCE_BEACH]),
            [realEstateGeneralFields.DISTANCE_CITY_CENTRE]: convertNumeralToFloatString(values?.[realEstateGeneralFields.DISTANCE_CITY_CENTRE]),
            [realEstateGeneralFields.DISTANCE_SCHOOL]: convertNumeralToFloatString(values?.[realEstateGeneralFields.DISTANCE_SCHOOL]),
            [realEstateGeneralFields.DISTANCE_PUBLIC_TRANSPORTATION]: convertNumeralToFloatString(
                values?.[realEstateGeneralFields.DISTANCE_PUBLIC_TRANSPORTATION]
            ),
            [realEstateGeneralFields.DISTANCE_PATHWAY]: convertNumeralToFloatString(values?.[realEstateGeneralFields.DISTANCE_PATHWAY]),
            [realEstateGeneralFields.AREA]: convertNumeralToFloatString(values?.[realEstateGeneralFields.AREA]),
            [realEstateGeneralFields.PLOT_LENGTH]: convertNumeralToFloatString(values?.[realEstateGeneralFields.PLOT_LENGTH]),
            [realEstateGeneralFields.PLOT_WIDTH]: convertNumeralToFloatString(values?.[realEstateGeneralFields.PLOT_WIDTH]),
            [realEstateGeneralFields.MAX_CONTRUCTION_AREA]: convertNumeralToFloatString(values?.[realEstateGeneralFields.MAX_CONTRUCTION_AREA]),
        }),
    }

    return validationObject
}

const propertyGeneralGarageSaleValidaton = (appSettings, data) => {
    const valueDefinition = propertyGeneralValueDefinition(data)
    const validationSchema = {
        ...propertySharedInitialValidation(true),
        [realEstateGeneralFields.MORTGAGE_INFO]: Yup.string(),
        [realEstateGeneralFields.AREA]: Yup.string().required(i18n.t('validation.message')),
        [realEstateGeneralFields.FLOOR_NO]: Yup.number(),
        [realEstateGeneralFields.FLOORS_TOTAL_NO]: Yup.number(),
        [realEstateGeneralFields.PROPERTY_PERMIT_ID]: Yup.array().of(Yup.number()),
    }

    const initialValues = {
        ...propertySharedInitialValues(data, true),
        [realEstateGeneralFields.COMMON_MAINTENCE_COSTS]: data?.commonMaintenanceCosts?.toString().replace(/\./g, ',') ?? '',
        [realEstateGeneralFields.COMMON_MAINTENCE_COSTS_TYPE]: data?.commonMaintenanceCostsType ?? '€/m2',
        [realEstateGeneralFields.MORTGAGE_INFO]: data?.mortgageInfo ?? '',
        [realEstateGeneralFields.EXCHANGE_POSSIBLE]: data?.exchangePossible ?? '',
        [realEstateGeneralFields.AREA]: data?.area?.toString().replace(/\./g, ',') ?? '',
        [realEstateGeneralFields.CEILING_HEIGHT]: data?.ceilingHeight ?? '',
        [realEstateGeneralFields.CONSTRUCTION_YEAR]: data?.constructionYear ?? '',
        ...valueDefinition[realEstateGeneralFields.PROPERTY_FLOOR_POSITION_ID],
        ...valueDefinition[realEstateGeneralFields.PROPERTY_PERMIT_ID],
        [realEstateGeneralFields.FLOOR_NO]: data?.floorNo ?? '',
        [realEstateGeneralFields.FLOORS_TOTAL_NO]: data?.floorsTotalNo ?? '',
        [realEstateGeneralFields.PRICE_INITIAL]: data?.priceInitial?.toString().replace(/\./g, ',') ?? '',
    }

    const validationObject = {
        initialValues: initialValues,
        validationSchema: Yup.object(validationSchema),
        prepareModelForSubmit: (values) => ({
            ...values,
            [realEstateGeneralFields.MORTGAGE_INFO]: convertNumeralToFloatString(values?.[realEstateGeneralFields.MORTGAGE_INFO]),
            [realEstateGeneralFields.CEILING_HEIGHT]: convertNumeralToFloatString(values?.[realEstateGeneralFields.CEILING_HEIGHT]),
            [realEstateGeneralFields.DISTANCE_SEA]: convertNumeralToFloatString(values?.[realEstateGeneralFields.DISTANCE_SEA]),
            [realEstateGeneralFields.DISTANCE_RIVER]: convertNumeralToFloatString(values?.[realEstateGeneralFields.DISTANCE_RIVER]),
            [realEstateGeneralFields.DISTANCE_BEACH]: convertNumeralToFloatString(values?.[realEstateGeneralFields.DISTANCE_BEACH]),
            [realEstateGeneralFields.DISTANCE_CITY_CENTRE]: convertNumeralToFloatString(values?.[realEstateGeneralFields.DISTANCE_CITY_CENTRE]),
            [realEstateGeneralFields.DISTANCE_SCHOOL]: convertNumeralToFloatString(values?.[realEstateGeneralFields.DISTANCE_SCHOOL]),
            [realEstateGeneralFields.DISTANCE_PUBLIC_TRANSPORTATION]: convertNumeralToFloatString(
                values?.[realEstateGeneralFields.DISTANCE_PUBLIC_TRANSPORTATION]
            ),
            [realEstateGeneralFields.AREA]: convertNumeralToFloatString(values?.[realEstateGeneralFields.AREA]),
        }),
    }

    return validationObject
}

const propertyGeneralGarageLeaseValidaton = (appSettings, data) => {
    const valueDefinition = propertyGeneralValueDefinition(data)
    const validationSchema = {
        ...propertySharedInitialValidation(false),
        [realEstateGeneralFields.AREA]: Yup.string().required(i18n.t('validation.message')),
        [realEstateGeneralFields.FLOOR_NO]: Yup.number(),
        [realEstateGeneralFields.FLOORS_TOTAL_NO]: Yup.number(),
        [realEstateGeneralFields.PROPERTY_PERMIT_ID]: Yup.array().of(Yup.number()),
    }

    const initialValues = {
        ...propertySharedInitialValues(data, false),
        [realEstateGeneralFields.COMMON_MAINTENCE_COSTS]: data?.commonMaintenanceCosts?.toString().replace(/\./g, ',') ?? '',
        [realEstateGeneralFields.COMMON_MAINTENCE_COSTS_TYPE]: data?.commonMaintenanceCostsType ?? '€/m2',
        [realEstateGeneralFields.MAINTENCE_COSTS]: data?.maintenanceCosts ?? '',
        [realEstateGeneralFields.MAINTENCE_COSTS_TYPE]: data?.maintenanceCostsType ?? '€/m2',
        [realEstateGeneralFields.AVAILABLE_FROM_DATE]: data?.rentedToDate ?? '',
        [realEstateGeneralFields.MAINTENCE_COSTS]: data?.maintenceCosts?.toString().replace(/\./g, ',') ?? '',
        [realEstateGeneralFields.MAINTENCE_COSTS_TYPE]: data?.maintenanceCostsType ?? '€/m2',
        [realEstateGeneralFields.COMMON_UTILITY_COSTS]: data?.commonUtilityCosts?.toString().replace(/\./g, ',') ?? '',
        [realEstateGeneralFields.COMMON_UTILITY_COSTS_TYPE]: data?.commonUtilityCostsType ?? '€/m2',
        [realEstateGeneralFields.AREA]: data?.area?.toString().replace(/\./g, ',') ?? '',
        [realEstateGeneralFields.CEILING_HEIGHT]: data?.ceilingHeight ?? '',
        [realEstateGeneralFields.CONSTRUCTION_YEAR]: data?.constructionYear ?? '',
        ...valueDefinition[realEstateGeneralFields.PROPERTY_FLOOR_POSITION_ID],
        [realEstateGeneralFields.FLOOR_NO]: data?.floorNo ?? '',
        [realEstateGeneralFields.FLOORS_TOTAL_NO]: data?.floorsTotalNo ?? '',
        ...valueDefinition[realEstateGeneralFields.PROPERTY_PERMIT_ID],
    }

    const validationObject = {
        initialValues: initialValues,
        validationSchema: Yup.object(validationSchema),
        prepareModelForSubmit: (values) => ({
            ...values,
            [realEstateGeneralFields.MAINTENCE_COSTS]: convertNumeralToFloatString(values?.[realEstateGeneralFields.MAINTENCE_COSTS]),
            [realEstateGeneralFields.COMMON_UTILITY_COSTS]: convertNumeralToFloatString(values?.[realEstateGeneralFields.COMMON_UTILITY_COSTS]),
            [realEstateGeneralFields.CEILING_HEIGHT]: convertNumeralToFloatString(values?.[realEstateGeneralFields.CEILING_HEIGHT]),
            [realEstateGeneralFields.DISTANCE_SEA]: convertNumeralToFloatString(values?.[realEstateGeneralFields.DISTANCE_SEA]),
            [realEstateGeneralFields.DISTANCE_RIVER]: convertNumeralToFloatString(values?.[realEstateGeneralFields.DISTANCE_RIVER]),
            [realEstateGeneralFields.DISTANCE_BEACH]: convertNumeralToFloatString(values?.[realEstateGeneralFields.DISTANCE_BEACH]),
            [realEstateGeneralFields.DISTANCE_CITY_CENTRE]: convertNumeralToFloatString(values?.[realEstateGeneralFields.DISTANCE_CITY_CENTRE]),
            [realEstateGeneralFields.DISTANCE_SCHOOL]: convertNumeralToFloatString(values?.[realEstateGeneralFields.DISTANCE_SCHOOL]),
            [realEstateGeneralFields.DISTANCE_PUBLIC_TRANSPORTATION]: convertNumeralToFloatString(
                values?.[realEstateGeneralFields.DISTANCE_PUBLIC_TRANSPORTATION]
            ),
            [realEstateGeneralFields.AREA]: convertNumeralToFloatString(values?.[realEstateGeneralFields.AREA]),
            [realEstateGeneralFields.COMMON_MAINTENCE_COSTS]: convertNumeralToFloatString(values?.[realEstateGeneralFields.COMMON_MAINTENCE_COSTS]),
        }),
    }

    return validationObject
}

const realEstateDescriptionValidation = (data, languages = []) => {
    const propertyTitleInitialValues = {}
    const propertyTitleValidationSchema = {}
    const propertyDescriptionInitialValue = {}
    const propertyDescriptionValidationSchema = {}

    for (let lang of languages) {
        propertyTitleInitialValues[lang] = data?.translations?.[lang]?.propertyTitle ?? ''
        propertyTitleValidationSchema[lang] = Yup.string().max(70, i18n.t('validation.title'))
        propertyDescriptionInitialValue[lang] = data?.translations?.[lang]?.propertyDescription ?? ''
        propertyTitleValidationSchema[lang] = Yup.string().max(2000, i18n.t('validation.description'))
    }
    return {
        initialValues: {
            [realEstateDescriptionFields.PROPERTY_TITLE]: propertyTitleInitialValues,
            [realEstateDescriptionFields.PROPERTY_DESCRIPTION]: propertyDescriptionInitialValue,
        },
        validationSchema: Yup.object({
            [realEstateDescriptionFields.PROPERTY_TITLE]: Yup.object().shape(propertyTitleValidationSchema),
            [realEstateDescriptionFields.PROPERTY_DESCRIPTION]: Yup.object().shape(propertyDescriptionValidationSchema),
        }),
    }
}

const propertyExportValidation = (data) => {
    const propertyWebsiteInitValues = []
    let propertyPortalCustomValue = []

    if (data?.propertyWebsite && data?.propertyWebsite.length) {
        const propertyWebsite = data.propertyWebsite.map(
            // Only website id and attributes are mapped, the rest are removed through destructuring
            ({ id, title, websiteUrl, website: { id: website_id }, ...attributes }) => ({ website_id, ...attributes })
        )
        propertyWebsiteInitValues.push(...propertyWebsite)
    }

    if (data?.propertyPortalCustomValue) {
        const jsonParse = JSON.parse(data?.propertyPortalCustomValue)
        propertyPortalCustomValue = [...jsonParse]
    }

    return {
        initialValues: {
            [propertyExportFields.PROPERTY_PORTAL_ID]:
                data?.propertyPortal && data?.propertyPortal.length ? data?.propertyPortal.map((x) => x.id) : [],
            [propertyExportFields.PROPERTY_WEBSITE_ID]: propertyWebsiteInitValues,
            [propertyExportFields.PROPERTY_PORTAL_CUSTOM_VALUE]: propertyPortalCustomValue,
        },
        validationSchema: Yup.object({}),
    }
}

const propertyTransactionsValidation = (appSettings, data, propertyServiceValue, isRequiredAccordingToStatus) => {
    const dataEntity = data?.propertyTransaction
    const IS_SLO_MARKET = appSettings?.applicationMarket.name === 'Slovenija'

    return {
        initialValues: {
            [propertyTransactionsFields.PROPERTY_STATUS_ID]: data?.propertyStatus?.id ?? '',
            [propertyTransactionsFields.PROPERTY_TRANSACTION_AGENT_ID]: dataEntity?.propertyTransactionAgent?.id ?? '',
            [propertyTransactionsFields.RESERVATION_DATE]: dataEntity?.reservationDate ?? '',
            contact_collection:
                dataEntity?.contact && dataEntity.contact.length
                    ? dataEntity.contact.map((item) => ({
                          [propertyTransactionsFields.CONTACT_ID]: item.id,
                      }))
                    : [
                          {
                              [propertyTransactionsFields.CONTACT_ID]: '',
                          },
                      ],
            [propertyTransactionsFields.PRICE_FINAL]: dataEntity?.priceFinal?.toString().replace(/\./g, ',') ?? '',
            [propertyTransactionsFields.COMMISION_SELLER]: dataEntity?.commissionSeller?.toString().replace(/\./g, ',') ?? '',
            [propertyTransactionsFields.COMMISION_SELLER_TYPE_ID]: dataEntity?.commissionSellerType?.toString() ?? '',
            [propertyTransactionsFields.COMMISION_BUYER]: dataEntity?.commissionBuyer?.toString().replace(/\./g, ',') ?? '',
            [propertyTransactionsFields.COMMISION_BUYER_TYPE_ID]: dataEntity?.commissionBuyerType?.toString() ?? '',
            [propertyTransactionsFields.PROPERTY_MAIN_REGISTER_ID]: dataEntity?.propertyMainRegister?.id?.toString() ?? '',
            [propertyTransactionsFields.PROPERTY_MAIN_REGISTER_DEPARTMENT_ID]: dataEntity?.propertyMainRegisterDepartment?.id?.toString(),
            [propertyTransactionsFields.PROPERTY_REGISTRY_MUNICIPALITY]: dataEntity?.propertyRegistryMunicipality ?? '',
            [propertyTransactionsFields.PROPERTY_REGISTRY_DEPARTMENT_KPU_SUBINSERT_ID]:
                dataEntity?.propertyRegistryDepartmentKpuSubinsert?.id?.toString() ?? '',
            [propertyTransactionsFields.PROPERTY_PUK_ID]: dataEntity?.propertyPuk?.id ?? '',
            ...(propertyServiceValue === REAL_ESTATE_SERVICE.sale
                ? {
                      [propertyTransactionsFields.PROPERTY_LAND_REGISTRY_STATUS_ID]: dataEntity?.propertyLandRegistryStatus?.id?.toString() ?? '',
                      [propertyTransactionsFields.PROPERTY_OWNERSHIP_ACQUISITION_ID]: dataEntity?.propertyOwnershipAcquisition?.id?.toString() ?? '',
                      [propertyTransactionsFields.PROPERTY_OWNERSHIP_ACQUISITION_PROOF_ID]:
                          dataEntity?.propertyOwnershipAcquisitionProof?.id?.toString() ?? '',
                  }
                : {}),
            [propertyTransactionsFields.ADVANCE_AMOUNT]: dataEntity?.advanceAmount?.toString()?.replace(/\./g, ',') ?? '',
            [propertyTransactionsFields.ADVANCE_DATE]: data?.propertyTransaction?.advancePaymentDate ?? '',
            [propertyTransactionsFields.CONCLUSION_DATE]: dataEntity?.conclusionDate ?? '',
            [propertyTransactionsFields.CONCLUSION_PLACE]: dataEntity?.conclusionPlace ?? '',
            [propertyTransactionsFields.FINAL_PAYMENT_DATE]: dataEntity?.finalPaymentDate ?? '',
            [propertyTransactionsFields.PROPERTY_TRANSACTION_RISK_DEGREE_ID]: dataEntity?.propertyTransactionRiskDegree?.id?.toString() ?? '',
            [propertyTransactionsFields.PROPERTY_REGISTRY_INSERT]: dataEntity?.propertyRegistryInsert ?? '',
            [propertyTransactionsFields.PROPERTY_REGISTRY_PARCEL_NO]: dataEntity?.propertyRegistryParcelNo ?? '',
            [propertyTransactionsFields.PROPERTY_REGISTRY_SUBINSERT]: dataEntity?.propertyRegistrySubinsert ?? '',
            [propertyTransactionsFields.PROPERTY_REGISTRY_DEPARTMENT_KPU_ID]: dataEntity?.propertyRegistryDepartmentKpu?.id?.toString() ?? '',
            [propertyTransactionsFields.PROPERTY_SUBINSERT_KPU]: dataEntity?.propertySubinsertKpu ?? '',
            [propertyTransactionsFields.PROPERTY_PUK_DEPARTMENT_ID]: dataEntity?.propertyPukDepartment?.id?.toString() ?? '',
            [propertyTransactionsFields.PROPERTY_CADASTRAL_MUNCIPALITY]: dataEntity?.propertyCadastralMunicipality ?? '',
            [propertyTransactionsFields.PROPERTY_CADASTRAL_PARCEL_NO]: dataEntity?.propertyCadastralParcelNo ?? '',
            [propertyTransactionsFields.PROPERTY_POSSESSORY_SHEET_NO]: dataEntity?.propertyPossessorySheetNo ?? '',
            ...(IS_SLO_MARKET && { [propertyTransactionsFields.PROPERTY_BUILDING_NUMBER]: dataEntity?.propertyBuildingNumber ?? '' }),
            ...(IS_SLO_MARKET && { [propertyTransactionsFields.PROPERTY_BUILDING_PART_NUMBER]: dataEntity?.propertyBuildingPartNumber ?? '' }),
        },
        validationSchema: Yup.object({
            [propertyTransactionsFields.PROPERTY_STATUS_ID]: Yup.string().required(i18n.t('validation.message')),
            ...(isRequiredAccordingToStatus
                ? {
                      contact_collection: Yup.array().of(
                          Yup.object().shape({
                              [propertyTransactionsFields.CONTACT_ID]: Yup.string().nullable().required(i18n.t('validation.message')),
                          })
                      ),
                      [propertyTransactionsFields.PRICE_FINAL]: Yup.string().required(i18n.t('validation.message')),
                      [propertyTransactionsFields.CONCLUSION_PLACE]: Yup.string().required(i18n.t('validation.message')),
                      [propertyTransactionsFields.CONCLUSION_DATE]: Yup.string().required(i18n.t('validation.message')),
                      ...(!IS_SLO_MARKET && { [propertyTransactionsFields.FINAL_PAYMENT_DATE]: Yup.string().required(i18n.t('validation.message')) }),
                      [propertyTransactionsFields.PROPERTY_TRANSACTION_RISK_DEGREE_ID]: Yup.string().required(i18n.t('validation.message')),
                      [propertyTransactionsFields.PROPERTY_MAIN_REGISTER_ID]: Yup.string().required(i18n.t('validation.message')),
                      [propertyTransactionsFields.PROPERTY_MAIN_REGISTER_DEPARTMENT_ID]: Yup.string().required(i18n.t('validation.message')),
                      [propertyTransactionsFields.PROPERTY_REGISTRY_MUNICIPALITY]: Yup.string().required(i18n.t('validation.message')),
                      [propertyTransactionsFields.PROPERTY_REGISTRY_INSERT]: Yup.string().required(i18n.t('validation.message')),
                      ...(!IS_SLO_MARKET && {
                          [propertyTransactionsFields.PROPERTY_CADASTRAL_MUNCIPALITY]: Yup.string().required(i18n.t('validation.message')),
                      }),
                      [propertyTransactionsFields.PROPERTY_CADASTRAL_PARCEL_NO]: Yup.string().when(
                          [propertyTransactionsFields.PROPERTY_REGISTRY_PARCEL_NO],
                          {
                              is: undefined,
                              then: Yup.string().required(i18n.t('validation.message')),
                          }
                      ),
                  }
                : {}),
        }),
        prepareModelForSubmit: (values) => {
            const contact_collection =
                values.contact_collection?.map(({ [propertyTransactionsFields.CONTACT_ID]: contactId }) => contactId).filter(Boolean) || []

            return {
                ...values,
                contact_collection,
                [propertyTransactionsFields.PRICE_FINAL]: values?.[propertyTransactionsFields.PRICE_FINAL]
                    ?.toString()
                    .replace(/\./g, '')
                    .replace(/,/g, '.'),
                [propertyTransactionsFields.ADVANCE_AMOUNT]: values?.[propertyTransactionsFields.ADVANCE_AMOUNT]
                    ?.toString()
                    .replace(/\./g, '')
                    .replace(/,/g, '.'),
                [propertyTransactionsFields.COMMISION_SELLER]: values?.[propertyTransactionsFields.COMMISION_SELLER]
                    ?.toString()
                    .replace(/\./g, '')
                    .replace(/,/g, '.'),
                [propertyTransactionsFields.COMMISION_BUYER]: values?.[propertyTransactionsFields.COMMISION_BUYER]
                    ?.toString()
                    .replace(/\./g, '')
                    .replace(/,/g, '.'),
            }
        },
    }
}

const realEstateMaterials3DVideoValidation = (data) => {
    return {
        initialValues: {
            [realEstateMaterials3DVideoFields.VIRTUAL_WALK_URL]: data?.[propertyResponseFields.VIRTUAL_WALK_URL] ?? '',
            [realEstateMaterials3DVideoFields.PROPERTY_VIDEO_URL]: data?.[propertyResponseFields.PROPERTY_VIDEO_URL] ?? '',
        },
        validationSchema: Yup.object({
            [realEstateMaterials3DVideoFields.VIRTUAL_WALK_URL]: Yup.string(),
            [realEstateMaterials3DVideoFields.PROPERTY_VIDEO_URL]: Yup.string(),
        }),
    }
}

const propertyOfferValidation = (data) => {
    return {
        initialValues: {
            [propertyOfferPrintFields.OFFER_LOCALE]: data?.offerLocale ?? '',
            [propertyOfferPrintFields.AGENT_ID]: data?.agent?.id?.toString() ?? '',
            [propertyOfferPrintFields.OFFER_TEMPLATE]: data?.offerTemplate ?? '',
            offer_type: data?.offerType ?? '',
            offer_settings_group: data?.offerSettings ?? [],
            offer_email_settings_group: [],
            offer_contact_group: [],
            offer_company_group: [],
        },
        validationSchema: Yup.object({}),
    }
}

const propertyPrintValidation = (data) => {
    return {
        initialValues: {
            [propertyOfferPrintFields.LANGUAGE]: data?.language ?? '',
            [propertyOfferPrintFields.AGENT_ID]: data?.agent?.id ?? '',
        },
        validationSchema: Yup.object({
            [propertyOfferPrintFields.LANGUAGE]: Yup.string(),
            [propertyOfferPrintFields.AGENT_ID]: Yup.string(),
        }),
    }
}

const propertyDetailSearchValidation = (data) => {
    return {
        initialValues: {
            [propertyDetailSearchFields.ID]: data?.id ?? '',
            [propertyDetailSearchFields.PROPERTY_TYPE]: data?.[propertyDetailSearchFields.PROPERTY_TYPE] ?? [],
            [propertyDetailSearchFields.PROPERTY_STATUS]: data?.[propertyDetailSearchFields.PROPERTY_STATUS] ?? [
                PROPERTY_STATUS.ACTIVE_OFFER.toString(),
                PROPERTY_STATUS.FOR_AUTHORIZATION.toString(),
            ],
            [propertyDetailSearchFields.PROPERTY_SUB_TYPE]: data?.[propertyDetailSearchFields.PROPERTY_SUB_TYPE] ?? [],
            [propertyDetailSearchFields.PRICE_FROM]: data?.[propertyDetailSearchFields.PRICE_FROM] ?? '',
            [propertyDetailSearchFields.PRICE_TO]: data?.[propertyDetailSearchFields.PRICE_TO] ?? '',
            [propertyDetailSearchFields.AREA_FROM]: data?.[propertyDetailSearchFields.AREA_FROM] ?? '',
            [propertyDetailSearchFields.AREA_TO]: data?.[propertyDetailSearchFields.AREA_TO] ?? '',
            [propertyDetailSearchFields.ROOMS_NO_FROM]: data?.[propertyDetailSearchFields.ROOMS_NO_FROM] ?? '',
            [propertyDetailSearchFields.ROOMS_NO_TO]: data?.[propertyDetailSearchFields.ROOMS_NO_TO] ?? '',
            [propertyDetailSearchFields.BEDROOMS_NO_FROM]: data?.[propertyDetailSearchFields.BEDROOMS_NO_FROM] ?? '',
            [propertyDetailSearchFields.BEDROOMS_NO_TO]: data?.[propertyDetailSearchFields.BEDROOMS_NO_TO] ?? '',
            [propertyDetailSearchFields.FLOOR_NO_FROM]: data?.[propertyDetailSearchFields.FLOOR_NO_FROM] ?? '',
            [propertyDetailSearchFields.FLOOR_NO_TO]: data?.[propertyDetailSearchFields.FLOOR_NO_TO] ?? '',
            [propertyDetailSearchFields.PROPERTY_DESCRIPTIONS]: data?.[propertyDetailSearchFields.PROPERTY_DESCRIPTIONS] ?? [],
            [propertyDetailSearchFields.PROPERTY_SPACE]: data?.[propertyDetailSearchFields.PROPERTY_SPACE] ?? [],

            [propertyDetailSearchFields.COUNTRY]: data?.[propertyDetailSearchFields.COUNTRY] ?? [],
            [propertyDetailSearchFields.CITY]: data?.[propertyDetailSearchFields.CITY] ?? [],
            [propertyDetailSearchFields.COUNTY]: data?.[propertyDetailSearchFields.COUNTY] ?? [],
            [propertyDetailSearchFields.WIDER_AREA]: data?.[propertyDetailSearchFields.WIDER_AREA] ?? [],
            [propertyDetailSearchFields.QUARTER]: data?.[propertyDetailSearchFields.QUARTER] ?? [],
            [propertyDetailSearchFields.STREET]: data?.[propertyDetailSearchFields.STREET] ?? '',
            [propertyDetailSearchFields.ISLAND]: data?.[propertyDetailSearchFields.ISLAND] ?? [],
            [propertyDetailSearchFields.DISTANCE_SEA]: data?.[propertyDetailSearchFields.DISTANCE_SEA] ?? '',
            [propertyDetailSearchFields.PROPERTY_UTILITY]: data?.[propertyDetailSearchFields.PROPERTY_UTILITY] ?? [],
            [propertyDetailSearchFields.PROPERTY_EQUIPMENT]: data?.[propertyDetailSearchFields.PROPERTY_EQUIPMENT] ?? [],

            [propertyDetailSearchFields.OWNER]: data?.[propertyDetailSearchFields.OWNER] ?? '',
            [propertyDetailSearchFields.AGENT]: data?.[propertyDetailSearchFields.AGENT] ?? [],
            [propertyDetailSearchFields.PHONE_NUMBER]: data?.[propertyDetailSearchFields.PHONE_NUMBER] ?? '',
            [propertyDetailSearchFields.PROPERTY_RECEIPT_DATE_FROM]: data?.[propertyDetailSearchFields.PROPERTY_RECEIPT_DATE_FROM] ?? '',
            [propertyDetailSearchFields.PROPERTY_RECEIPT_DATE_TO]: data?.[propertyDetailSearchFields.PROPERTY_RECEIPT_DATE_TO] ?? '',
            [propertyDetailSearchFields.AVAILABLE_FROM_DATE_FROM]: data?.[propertyDetailSearchFields.AVAILABLE_FROM_DATE_FROM] ?? '',
            [propertyDetailSearchFields.AVAILABLE_FROM_DATE_TO]: data?.[propertyDetailSearchFields.AVAILABLE_FROM_DATE_TO] ?? '',
            [propertyDetailSearchFields.WEB]: data?.[propertyDetailSearchFields.WEB] ?? '',
            [propertyDetailSearchFields.ATTRIBUTE]: data?.[propertyDetailSearchFields.ATTRIBUTE] ?? [],
            [propertyDetailSearchFields.PORTAL]: data?.[propertyDetailSearchFields.PORTAL] ?? [],
            [propertyDetailSearchFields.PROPERTY_CONDITION]: data?.[propertyDetailSearchFields.PROPERTY_CONDITION] ?? [],
            [propertyDetailSearchFields.PROPERTY_ENERGY_EFFICIENCY]: data?.[propertyDetailSearchFields.PROPERTY_ENERGY_EFFICIENCY] ?? [],
            [propertyDetailSearchFields.PROPERTY_FLOOR]: data?.[propertyDetailSearchFields.PROPERTY_FLOOR] ?? [],
        },
        prepareModelForSubmit: (values) => {
            const filledValues = Object.fromEntries(Object.entries(values).filter(([key, value]) => value !== null && value !== ''))

            const filteredValues = {
                ...filledValues,
                ...(filledValues[propertyDetailSearchFields.WEB]
                    ? {
                          [propertyDetailSearchFields.WEB]: {
                              id: filledValues[propertyDetailSearchFields.WEB],
                              ...(filledValues[propertyDetailSearchFields.ATTRIBUTE]
                                  ? {
                                        [propertyDetailSearchFields.ATTRIBUTE]: filledValues[propertyDetailSearchFields.ATTRIBUTE],
                                    }
                                  : {}),
                          },
                      }
                    : {}),
                ...(filledValues[propertyDetailSearchFields.PRICE_FROM]
                    ? {
                          [propertyDetailSearchFields.PRICE_FROM]: filledValues[propertyDetailSearchFields.PRICE_FROM]
                              ?.toString()
                              .replace(/\./g, '')
                              .replace(/,/g, '.'),
                      }
                    : {}),
                ...(filledValues[propertyDetailSearchFields.PRICE_TO]
                    ? {
                          [propertyDetailSearchFields.PRICE_TO]: filledValues[propertyDetailSearchFields.PRICE_TO]
                              ?.toString()
                              .replace(/\./g, '')
                              .replace(/,/g, '.'),
                      }
                    : {}),
            }

            delete filteredValues[propertyDetailSearchFields.ATTRIBUTE]

            return filteredValues
        },
    }
}

export {
    generalRealEstateFlatLeaseValidaton,
    generalRealEstateFlatSaleValidaton,
    generalRealEstateValidaton,
    propertyDetailSearchValidation,
    propertyExportValidation,
    propertyGeneralGarageLeaseValidaton,
    propertyGeneralGarageSaleValidaton,
    propertyGeneralHouseLeaseValidaton,
    propertyGeneralHouseSaleValidaton,
    propertyGeneralLandLeaseValidaton,
    propertyGeneralLandSaleValidaton,
    propertyGeneralOfficeSpaceLeaseValidaton,
    propertyGeneralOfficeSpaceSaleValidaton,
    propertyOfferValidation,
    propertyPrintValidation,
    propertyTransactionsValidation,
    realEstateDescriptionValidation,
    realEstateMaterials3DVideoValidation,
}
