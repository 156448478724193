import { Row } from 'reactstrap'
import { useFormik } from 'formik'
import { useDispatch } from 'react-redux'
import { createAgencyOffice } from '../../../../store/administration'
import BreadCrumb from '../../../../Components/Common/BreadCrumb'
import AgencyOfficeForm from '../../Components/AgencyOfficeForm/AgencyOfficeForm'
import UploadPhoto from '../../Components/UploadPhoto/UploadPhoto'
import { removePrefix } from '../../helpers'
import * as Yup from 'yup'
import { officeValidation } from '../validateRules'
import i18n from 'i18next'

const OfficeNew = () => {
    const dispatch = useDispatch()

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {},
        validationSchema: Yup.object(officeValidation()),
        onSubmit: (values) => {
            values.phoneNumber = removePrefix(values.phoneNumber)
            values.mobileNumber = removePrefix(values.mobileNumber)
            dispatch(createAgencyOffice(values))
        },
    })

    return (
        <Row>
            <BreadCrumb title={i18n.t('admin.createNewOffice')} />
            <UploadPhoto formik={formik} imageUrl={formik?.values?.logo} firstName={formik?.values?.name} headline={formik?.values?.name} />
            <AgencyOfficeForm formik={formik} cancelButtonUrl={'/admin/office'} />
        </Row>
    )
}

export default OfficeNew
