import { documentFields } from "./fields";
import { documentFieldTypes } from "./fieldTypes";

export const documentsInputs = (isEdit) => {
    const inputs = documentFieldTypes();

    return {
        sideForm: [
            ...(isEdit ? [
                inputs[documentFields.FILE_DESCRIPTION],
            ] : []),
            inputs[documentFields.DOCUMENT_PROPRETY_ID],
            inputs[documentFields.DOCUMENT_PROJECT_ID],
            inputs[documentFields.DOCUMENT_CONTACT_ID],
            inputs[documentFields.DOCUMENT_TYPE],
            inputs[documentFields.DOCUMENT_CREATION_DATE],
            inputs[documentFields.DOCUMENT_VALIDITY_DATE],
            inputs[documentFields.FILE_SYNC],
        ]
    }
}

