import { Link } from 'react-router-dom'
import { AccordionBody, AccordionHeader, AccordionItem, Col, Row } from 'reactstrap'
import { CONTACT_TYPE } from '../common/constants'

const ContactProfileAccordionItem = ({ item, history, profileId, id, profileViewData, contactType = CONTACT_TYPE.PERSON, handleDelete }) => {
    return (
        <AccordionItem className="border-0">
            <AccordionHeader targetId={profileId} className="contact-profile-accordion-header">
                <div className="d-flex align-items-center ">
                    {item.propertyType ? `${item.propertyType?.name}` : ''}{' '}
                    {item.contactProfileService ? `, ${item.contactProfileService?.name}` : ''}
                    <ul className="list-inline mb-0 justify-content-start ms-2">
                        <li className="list-inline-item edit">
                            <Link
                                to={contactType === CONTACT_TYPE.COMPANY ? `/companies/${id}/edit` : `/contacts/${id}/edit`}
                                state={{ activeTab: 'profile' }}
                                onClick={(e) => {
                                    e.preventDefault()
                                    history.push({
                                        pathname: contactType === CONTACT_TYPE.COMPANY ? `/companies/${id}/edit` : `/contacts/${id}/edit`,
                                        state: {
                                            activeTab: 'contactProfile',
                                            profileId: item.id,
                                        },
                                    })
                                }}
                                className="text-edit d-inline-block edit-item-btn"
                            >
                                <i className="mdi mdi-pencil-outline fs-16"></i>
                            </Link>
                        </li>
                        <li className="list-inline-item">
                            <Link
                                to="#"
                                className="text-danger d-inline-block remove-item-btn"
                                onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    handleDelete(item)
                                }}
                            >
                                <i className="mdi mdi-trash-can-outline fs-16"></i>
                            </Link>
                        </li>
                    </ul>
                </div>
            </AccordionHeader>

            <AccordionBody accordionId={profileId}>
                <div className="accordion-body px-0">
                    {profileViewData &&
                        profileViewData
                            .filter((viewItem) => viewItem.value)
                            .map((viewItem, index) => {
                                if (viewItem.value) {
                                    return (
                                        <Row
                                            className={`align-items-start ${
                                                profileViewData.filter((x) => x.value).length - 1 !== index ? 'mb-4' : ''
                                            }`}
                                            key={viewItem?.id}
                                        >
                                            <Col sm={6}>
                                                <span className="flex-grow-1 text-primary fw-medium">{viewItem?.label}:</span>
                                            </Col>
                                            <Col sm={6}>
                                                <span className="flex-grow-1 text-primary fw-light">{viewItem?.value}</span>
                                            </Col>
                                        </Row>
                                    )
                                }
                                return null
                            })}
                </div>
            </AccordionBody>
        </AccordionItem>
    )
}

export default ContactProfileAccordionItem
