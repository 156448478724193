import i18n from 'i18next'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Card, CardBody, Col, Row } from 'reactstrap'
import {
    fetchAgency,
    fetchAgencyOffices,
    fetchUsers,
    selectAgency,
    selectAgencyFetching,
    selectAgencyOffices,
    selectUsers,
    selectUsersFetching,
} from '../../../../store/administration'
import AdminLoader from '../../Components/AdminLoader/AdminLoader'
import ImageContainer from '../../Components/ImageContainer/ImageContainer'

const AgencyView = () => {
    const dispatch = useDispatch()

    const { agency, fetching, users, fetchingUsers, offices } = useSelector((state) => ({
        agency: selectAgency(state),
        fetching: selectAgencyFetching(state),
        users: selectUsers(state),
        fetchingUsers: selectUsersFetching(state),
        offices: selectAgencyOffices(state),
    }))

    useEffect(() => {
        dispatch(fetchAgency())
        dispatch(fetchUsers())
        dispatch(fetchAgencyOffices())
    }, [dispatch])

    return fetching ? (
        <AdminLoader />
    ) : (
        <div className="">
            <div className="profile-foreground position-relative">
                <div className="profile-wid-bg" />
            </div>
            <div className="pt-4 mb-4 mb-lg-3 pb-lg-4">
                <Row className="g-4 px-3">
                    <div className="col-auto">
                        <ImageContainer imageUrl={agency.logo} firstName={agency.name} />
                    </div>

                    <Col className="d-flex align-items-center">
                        <div>
                            <h3 className="text-white mb-1">{agency.name}</h3>
                            <div className="hstack text-white-50 gap-1">
                                <div className="me-2">
                                    <i className="ri-map-pin-user-line me-1 text-white-75 fs-16 align-middle"></i>
                                    {agency.street} {agency.streetNumber}, {agency.zipCode} {agency.city}, {agency.country}
                                </div>
                            </div>
                        </div>
                    </Col>

                    <Col xs={12} className="col-lg-auto order-last order-lg-0">
                        <Row className="text text-white-50 text-center">
                            <Col lg={6} xs={4}>
                                <div className="p-2">
                                    <h4 className="text-white mb-1">{offices.count}</h4>
                                    <p className="fs-12 mb-0">{i18n.t('admin.numberOfOffices')}</p>
                                </div>
                            </Col>
                            {!fetchingUsers && (
                                <Col lg={6} xs={4}>
                                    <div className="p-2">
                                        <h4 className="text-white mb-1">{users.length}</h4>
                                        <p className="fs-12 mb-0">{i18n.t('admin.numberOfUsers')}</p>
                                    </div>
                                </Col>
                            )}
                        </Row>
                    </Col>
                </Row>
            </div>

            <Row>
                <Col lg={12}>
                    <div>
                        <div className="d-flex">
                            <div className="flex-shrink-0 w-25 px-3">
                                <Link to={`/admin/agency/${agency.id}/edit`} className="btn btn-success w-100">
                                    {i18n.t('button.edit')}
                                </Link>
                            </div>
                        </div>

                        <Row>
                            <Col className="mt-4" xxl={12}>
                                <Card style={{ minHeight: '200px' }}>
                                    <CardBody>
                                        <Row>
                                            {agency.phoneNumber && (
                                                <Col xs={6} md={4}>
                                                    <div className="d-flex mt-4">
                                                        <div className="flex-shrink-0 avatar-xs align-self-center me-3">
                                                            <div className="avatar-title bg-light rounded-circle fs-16 text-primary">
                                                                <i className="mdi mdi-phone"></i>
                                                            </div>
                                                        </div>

                                                        <div className="flex-grow-1 overflow-hidden">
                                                            <p className="mb-1">{i18n.t('admin.phone')}:</p>
                                                            <h6 className="text-truncate mb-0">{agency.phoneNumber}</h6>
                                                        </div>
                                                    </div>
                                                </Col>
                                            )}
                                            {agency.mobileNumber && (
                                                <Col xs={6} md={4}>
                                                    <div className="d-flex mt-4">
                                                        <div className="flex-shrink-0 avatar-xs align-self-center me-3">
                                                            <div className="avatar-title bg-light rounded-circle fs-16 text-primary">
                                                                <i className="mdi mdi-cellphone"></i>
                                                            </div>
                                                        </div>

                                                        <div className="flex-grow-1 overflow-hidden">
                                                            <p className="mb-1">{i18n.t('admin.mobilePhone')}:</p>
                                                            <h6 className="text-truncate mb-0">{agency.mobileNumber}</h6>
                                                        </div>
                                                    </div>
                                                </Col>
                                            )}
                                            {agency.email && (
                                                <Col xs={6} md={4}>
                                                    <div className="d-flex mt-4">
                                                        <div className="flex-shrink-0 avatar-xs align-self-center me-3">
                                                            <div className="avatar-title bg-light rounded-circle fs-16 text-primary">
                                                                <i className="mdi mdi-email"></i>
                                                            </div>
                                                        </div>

                                                        <div className="flex-grow-1 overflow-hidden">
                                                            <p className="mb-1">{i18n.t('app.common.email.label')}:</p>
                                                            <a href={`mailto:${agency.email}`} className="fw-semibold">
                                                                {agency.email}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </Col>
                                            )}
                                            {agency.websiteUrl && (
                                                <Col xs={6} md={4}>
                                                    <div className="d-flex mt-4">
                                                        <div className="flex-shrink-0 avatar-xs align-self-center me-3">
                                                            <div className="avatar-title bg-light rounded-circle fs-16 text-primary">
                                                                <i className="ri-global-line"></i>
                                                            </div>
                                                        </div>

                                                        <div className="flex-grow-1 overflow-hidden">
                                                            <p className="mb-1">{i18n.t('app.common.web')}:</p>
                                                            <a href={`https://${agency.websiteUrl}`} className="fw-semibold">
                                                                {agency.websiteUrl}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </Col>
                                            )}
                                            {agency.taxNumber && (
                                                <Col xs={6} md={4}>
                                                    <div className="d-flex mt-4">
                                                        <div className="flex-shrink-0 avatar-xs align-self-center me-3">
                                                            <div className="avatar-title bg-light rounded-circle fs-16 text-primary">
                                                                <i className="mdi mdi-numeric"></i>
                                                            </div>
                                                        </div>

                                                        <div className="flex-grow-1 overflow-hidden">
                                                            <p className="mb-1">{i18n.t('app.common.pin')}:</p>
                                                            <h6 className="text-truncate mb-0">{agency.taxNumber}</h6>
                                                        </div>
                                                    </div>
                                                </Col>
                                            )}
                                            {agency.agencyId && (
                                                <Col xs={6} md={4}>
                                                    <div className="d-flex mt-4">
                                                        <div className="flex-shrink-0 avatar-xs align-self-center me-3">
                                                            <div className="avatar-title bg-light rounded-circle fs-16 text-primary">
                                                                <i className="mdi mdi-file-key-outline"></i>
                                                            </div>
                                                        </div>

                                                        <div className="flex-grow-1 overflow-hidden">
                                                            <p className="mb-1">{i18n.t('app.common.agencyId')}:</p>
                                                            <h6 className="text-truncate mb-0">{agency.agencyId}</h6>
                                                        </div>
                                                    </div>
                                                </Col>
                                            )}
                                        </Row>
                                        {agency.description && (
                                            <>
                                                <h5 className="card-title mb-3 mt-5">{i18n.t('admin.officeInfo')}</h5>
                                                <p>{agency.description}</p>{' '}
                                            </>
                                        )}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default AgencyView
