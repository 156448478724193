import { setPermissions } from './utils'

export const setNavigation = ({ agentPermissions, subItems }) => {
    const updatedSubItems = []

    subItems.forEach((item) => {
        let moduleName = item.link.split('/')[1]

        // TODO: link is /diary, token is module.intermediaryDiary.access
        // link is /reports, token is module.report.access
        moduleName = moduleName === 'diary' ? 'intermediaryDiary' : 'report'

        const permission = `module.${moduleName}.access`
        const isLinkAvailable = setPermissions(agentPermissions, permission)
        const linkData = {
            ...item,
            isLinkAvailable,
            className: `nav-link ${!isLinkAvailable ? 'd-none' : ''}`
        }

        updatedSubItems.push(linkData)
    })

    return { updatedSubItems }
}
