import { Label, UncontrolledTooltip } from 'reactstrap'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import styles from './SelectCountry.module.css'
import { useEffect, useRef, useState } from 'react'

const PhoneWithDropDown = (props) => {
    const { id, label, formik, defaultValue, isRequired = false, permission } = props

    const [mask, setMask] = useState('')
    const [isFocus, setIsFocus] = useState(false)
    const phoneInputRef = useRef(null)

    useEffect(() => {
        defaultValue?.startsWith('+385 1') || defaultValue?.startsWith('3851') ? setMask('. ... ....') : setMask('.. ... ....')
    }, [defaultValue])

    useEffect(() => {
        isFocus && phoneInputRef?.current.numberInputRef.focus()
    }, [isFocus, mask])

    const handlePhoneChange = (value) => {
        formik.setFieldValue(`${id}`, value)
    }

    return (
        <>
            <Label>{label}</Label>
            {isRequired && <span className="required-mark">*</span>}
            {permission && (
                <>
                    {' '}
                    <span id={`agentPermissionsTooltip${permission.id}`}>
                        <i className="mdi mdi-help-circle-outline"></i>{' '}
                    </span>
                    <UncontrolledTooltip pement="right" target={`agentPermissionsTooltip${permission.id}`}>
                        {permission.description}
                    </UncontrolledTooltip>
                </>
            )}
            <PhoneInput
                ref={phoneInputRef}
                key={JSON.stringify(mask)}
                className={styles.phoneInput}
                onBlur={() => setIsFocus(false)}
                onFocus={() => setIsFocus(true)}
                country={'hr'}
                value={defaultValue}
                onChange={handlePhoneChange}
                masks={{ hr: mask }}
                placeholder="+385 00 000 0000"
            />
        </>
    )
}

export default PhoneWithDropDown
