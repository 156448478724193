import { Link, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const BackButton = ({ className }) => {
    const history = useHistory()
    const linkUrlArray = history.location.pathname.split('/')
    const { t } = useTranslation()

    return <Link title={t('button.back')} to={`/${linkUrlArray[1]}`} className={`btn btn-primary ${className} mdi mdi-undo-variant text-white`} />
}

export default BackButton
