const contactGeneralFields = {
    ID: 'id',
    CONTACT_TITLE_ID: 'contact_title_id',
    FIRST_NAME: 'first_name',
    MIDDLE_NAME: 'middle_name',
    LAST_NAME: 'last_name',
    INITIAL_CONTACT_DATE: 'initial_contact_date',
    CONTACT_PRIORITY_ID: 'contact_priority_id',
    DATA_SOURCE_ID: 'data_source_id',
    PHONE_NUMBER: 'phone_number',
    CONTACT_PHONE_TYPE_ID: 'contact_phone_type_id',
    EMAIL: 'email',
    CONTACT_EMAIL_TYPE_ID: 'contact_email_type_id',
    NEWSLETTER_APPROVAL: 'newsletter_approval',
    STREET: 'street',
    STREET_NUMBER: 'street_number',
    ZIP_CODE: 'zip_code',
    CITY: 'city',
    CONTACT_COUNTRY_ID: 'contact_country_id',
    // ADDITIONAL_CONTACT_ID: 'additional_contact_id',
    CONTACT_QUERY_CATEGORY_ID: 'contact_query_category_id',
    CONTACT_STATUS_ID: 'contact_status_id',
    AGENT_ID: 'agent_id',
    CONTACT_SOURCE_ID: 'contact_source_id', // ERSTE ONLY FIELD
    CONTACT_ABOUT: 'contact_about' // ERSTE ONLY FIELD
};

const contactOtherInfoFields = {
    CONTACT_GENDER_ID: 'contact_gender_id',
    COMPANY_ID: 'company_id',
    CONTACT_JOB_TITLE_ID: 'contact_job_title_id',
    EMPLOYMENT_TYPE: 'employment_type',
    // CONTACT_DEFINITION_ID: 'contact_definition_id',
    CONTACT_DESCRIPTION_ID: 'contact_description_id',
    BIRTH_DATE: 'birth_date',
    INITIAL_CALL_INFO: 'initial_call_info',
    CONTACT_MARTIAL_STATUS_ID: 'contact_marital_status_id',
    NOTES: 'notes',
    OIB: 'tax_number',
}

const contactProfileFields = {
    PROPERTY_TYPE_ID: 'property_type_id',
    PROPERTY_SUB_TYPE_ID: 'property_sub_type_id',
    CONTACT_PROFILE_STATUS_ID: 'contact_profile_status_id',
    CONTACT_FINANCING_ID: 'contact_financing_id',
    CONTACT_PROFILE_DURATION_ID: 'contact_profile_duration_id',
    CONTACT_DEFINITION_ID: 'contact_definition_id',
    COUNTRY_ID: 'country_id',
    COUNTY_ID: 'county_id',
    CITY_ID: 'city_id',
    WIDER_AREA_ID: 'wider_area_id',
    QUARTER_ID: 'quarter_id',
    ISLAND_ID: 'island_id',
    PRICE_FROM: 'price_from',
    PRICE_TO: 'price_to',
    PRICE_M2_FROM: 'price_m2_from',
    PRICE_M2_TO: 'price_m2_to',
    ROOMS_FROM: 'rooms_from',
    ROOMS_TO: 'rooms_to',
    BEDROOMS_FROM: 'bedrooms_from',
    BEDROOMS_TO: 'bedrooms_to',
    AREA_FROM: 'area_from',
    AREA_TO: 'area_to',
    PROPERTY_FLOOR_POSITION_ID: 'property_floor_position_id',
    FLOOR_NO_FROM: 'floor_from',
    FLOOR_NO_TO: 'floor_to',
    EXCLUDE_BASEMENT: 'exclude_basement',
    EXCLUDE_LAST_FLOOR: 'exclude_last_floor',
    PROPERTY_SPACE_ID: 'property_space_id',
    SPACE_COUNT: 'space_count',
    SPACE_AREA: 'space_area',
    PROPERTY_DESCRIPTIONS_ID: 'property_descriptions_id',
    PROPERTY_EQUIPMENT_ID: 'property_equipment_id',
    PROPERTY_UTILITY_ID: 'property_utility_id',
    PROPERTY_HEATING_ID: "property_heating_id",
    CONTACT_PROFILE_REMARK_ID: "contact_profile_remark_id",
    CONTACT_PROFILE_SERVICE_ID: 'contact_profile_service_id',
    SPECIAL_REQUEST: 'special_request',
    MAX_CONTSRUCTION_AREA: 'max_construction_area',
    PROPERTY_FLOOR_ID: 'property_floor_id',
}

const contactNoteFields = {
    ID: 'id',
    NOTE_TITLE: 'note_title',
    NOTE_DATE: 'note_date',
    NOTE: 'note',
    CONTACT_NOTE_AGENT_ID: 'contact_note_agent_id'
}

const contactDocumentFields = {
    DOCUMENT_CREATION_DATE: 'document_creation_date',
    DOCUMENT_VALIDITY_DATE: 'document_validity_date',
    DOCUMENT_TYPE: 'document_type',
    FILE_SYNC: 'file_sync',
    FILE_DESCRIPTION: 'file_description',
    DOCUMENT_PROPRETY_ID: 'document_property_id',
    DOCUMENT_PROJECT_ID: 'document_project_id',
    DOCUMENT_CONTACT_ID: 'document_contact_id',
}

const contactDetailSearchFields = {
    NAME: 'name',
    CONTACT_PHONE: 'contactPhone',
    CONTACT_EMAIL: 'contactEmail',
    AGENT: 'agent',
    INITIAL_CONTACT_DATE_FROM: 'initialContactDateFrom',
    INITIAL_CONTACT_DATE_TO: 'initialContactDateTo',
    LAST_ACTIVITY_DATE_FROM: 'lastActivityDateFrom',
    LAST_ACTIVITY_DATE_TO: 'lastActivityDateTo',
    CONTACT_COUNTRY: 'contactCountry',
    CITY: 'city',
    CONTACT_PROFILE_SERVICE: 'contactProfileService',
    CONTACT_PROFILE_STATUS: 'contactProfileStatus',
    CONTACT_QUERY_CATEGORY: 'contactQueryCategory',
    CONTACT_PRIORITY: 'contactPriority',
    DATA_SOURCE: 'dataSource',
    CONTACT_STATUS: 'contactStatus',
}


export {
    contactGeneralFields,
    contactOtherInfoFields,
    contactProfileFields,
    contactNoteFields,
    contactDocumentFields,
    contactDetailSearchFields
}
