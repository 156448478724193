import { handleActions } from 'redux-actions'
import { v4 as uuid } from 'uuid'
import {
    clearDocuments,
    clearQueDocument,
    deleteDocumentMultipleFailure,
    deleteDocumentMultipleRequest,
    deleteDocumentMultipleSuccess,
    deleteDocumentSuccess,
    fetchDocumentsFailure,
    fetchDocumentsRequest,
    fetchDocumentsSuccess,
    queDocumentSuccess,
    syncDocumentSuccess,
    updateDocumentSuccess,
    uploadDocumentFailure,
    uploadDocumentSuccess,
} from './actions'
export const initialState = {
    items: [],
    count: 0,
    fetching: true,
    error: null,
    que: {},
}

export default handleActions(
    {
        [fetchDocumentsRequest]: (state) => ({
            ...state,
            fetching: true,
            error: null,
        }),
        [fetchDocumentsSuccess]: (state, { payload }) => {
            return {
                ...state,
                count: payload?.count ?? 0,
                items: payload?.items ?? [],
                fetching: false,
            }
        },
        [fetchDocumentsFailure]: (state, { payload }) => ({
            ...state,
            error: payload,
            fetching: false,
        }),
        [queDocumentSuccess]: (state, { payload }) => {
            // console.log('que payload', payload)
            return {
                ...state,
                que: {
                    ...payload?.reduce((acc, item) => {
                        // let parsedItem = JSON.parse(item);
                        let id = uuid()
                        return {
                            ...acc,
                            [id]: Object.assign(item, {
                                id: '',
                                tmpId: id,
                                preview: URL.createObjectURL(item),
                                // formattedSize: formatBytes(file.size),
                                queue: true,
                                uploading: false,
                            }),
                        }
                    }, {}),
                },
            }
        },
        [uploadDocumentSuccess]: (state, { payload }) => {
            delete payload.tmpId

            const items = [payload, ...state.items]

            return {
                ...state,
                que: {},
                items: items,
                count: state.count + 1,
            }
        },
        [uploadDocumentFailure]: (state, { payload, file }) => {
            return {
                ...state,
                que: {
                    ...state.photoque,
                    [payload?.file?.id]: Object.assign(payload?.file, {
                        uploading: false,
                        error: true,
                    }),
                },
            }
        },
        [clearQueDocument]: (state, { payload, file }) => {
            return {
                ...state,
                que: {},
            }
        },
        [deleteDocumentSuccess]: (state, { payload }) => {
            let items = [...state.items]
            items = items.filter((x) => x.id !== parseInt(payload))

            return {
                ...state,
                deleting: false,
                items: items,
                count: state.count - 1,
            }
        },
        [deleteDocumentMultipleRequest]: (state) => {
            return {
                ...state,
                error: null,
                deleting: true,
            }
        },
        [deleteDocumentMultipleSuccess]: (state, { payload }) => {
            let items = [...state.items]
            items = items.filter((x) => !payload.includes(x.id))

            return {
                ...state,
                deleting: false,
                items,
                count: state.count - payload?.length,
            }
        },
        [deleteDocumentMultipleFailure]: (state, { payload }) => {
            return {
                ...state,
                deleting: false,
                error: payload.response,
            }
        },
        [syncDocumentSuccess]: (state, { payload }) => {
            const items = [...state.items]
            const index = items.findIndex((obj) => obj.id === payload)
            items[index].fileSync = !items[index].fileSync

            return {
                ...state,
                items: items,
            }
        },
        [updateDocumentSuccess]: (state, { payload }) => {
            const items = [...state.items]
            const index = items.findIndex((obj) => obj.id === payload.id)

            items[index] = payload

            return {
                ...state,
                items: items,
            }
        },
        [clearDocuments]: (state) => {
            return {
                ...state,
                items: [],
            }
        },
    },
    initialState
)
