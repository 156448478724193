import Loader from '../../../../Components/Common/Loader'
import styles from './AdminLoader.module.css'

const AdminLoader = () => {
    return (
        <div className={`text-center d-flex justify-content-center ${styles.loaderCenter}`}>
            <Loader />
        </div>
    )
}

export default AdminLoader
