import { ReactComponent as CalcIcon } from '../assets/images/fileTypes/calc.svg'
import { ReactComponent as GifIcon } from '../assets/images/fileTypes/gif.svg'
import { ReactComponent as JpgIcon } from '../assets/images/fileTypes/jpg.svg'
import { ReactComponent as PdfIcon } from '../assets/images/fileTypes/pdf.svg'
import { ReactComponent as PngIcon } from '../assets/images/fileTypes/png.svg'
import { ReactComponent as WordIcon } from '../assets/images/fileTypes/word.svg'
import { API_URL } from '../common/constants'

export function getMaterialIconFromMIME(mimeType) {
    // List of official MIME Types: http://www.iana.org/assignments/media-types/media-types.xhtml
    var icon_classes = {
        // Media
        image: 'fa-file-image-o',
        audio: 'fa-file-audio-o',
        video: 'fa-file-video-o',
        // Documents
        'application/pdf': 'fa-file-pdf-o',
        'application/msword': 'mdi-file-word',
        'application/vnd.ms-word': 'mdi-file-word',
        'application/vnd.oasis.opendocument.text': 'fa-file-word-o',
        'application/vnd.openxmlformats-officedocument.wordprocessingml': 'fa-file-word-o',
        'application/vnd.ms-excel': 'fa-file-excel-o',
        'application/vnd.openxmlformats-officedocument.spreadsheetml': 'fa-file-excel-o',
        'application/vnd.oasis.opendocument.spreadsheet': 'fa-file-excel-o',
        'application/vnd.ms-powerpoint': 'fa-file-powerpoint-o',
        'application/vnd.openxmlformats-officedocument.presentationml': 'fa-file-powerpoint-o',
        'application/vnd.oasis.opendocument.presentation': 'fa-file-powerpoint-o',
        'text/plain': 'fa-file-text-o',
        'text/html': 'fa-file-code-o',
        'application/json': 'fa-file-code-o',
        // Archives
        'application/gzip': 'fa-file-archive-o',
        'application/zip': 'fa-file-archive-o',
    }

    for (var key in icon_classes) {
        // eslint-disable-next-line no-prototype-builtins
        if (icon_classes.hasOwnProperty(key)) {
            if (mimeType.search(key) === 0) {
                // Found it
                return icon_classes[key]
            }
        } else {
            return 'fa-file-o'
        }
    }
}

export function getSVGIconFromExtension(extension) {
    // List of official MIME Types: http://www.iana.org/assignments/media-types/media-types.xhtml
    var icon_classes = {
        // Media
        pdf: <PdfIcon />,
        gif: <GifIcon />,
        jpg: <JpgIcon />,
        jpeg: <JpgIcon />,
        doc: <WordIcon />,
        docx: <WordIcon />,
    }

    for (var key in icon_classes) {
        // eslint-disable-next-line no-prototype-builtins
        if (icon_classes.hasOwnProperty(key)) {
            if (extension?.search(key) === 0) {
                // Found it
                return icon_classes[key]
            } else {
                return <i className="mdi mdi-file-document-outline align-middle fs-16"></i>
            }
        } else {
            return <i className="mdi mdi-file-document-outline align-middle fs-16"></i>
        }
    }
}

export function removeFileExtension(fileName) {
    const indexLastDot = fileName.lastIndexOf('.')
    return fileName.slice(0, indexLastDot)
}
export function getFileExtension(fileName) {
    const indexLastDot = fileName.lastIndexOf('.')
    return fileName.slice(indexLastDot + 1)
}

export function toFullURL(fileId) {
    return `${API_URL}v1/file/show/${fileId}`
}
