import { createAction } from 'redux-actions';

const ENTITY = 'COMPANYNOTES';
export const stateKey = ENTITY.toLowerCase();

export const fetchCompanyNotes = createAction(`${ENTITY}/FETCH`);
export const fetchCompanyNotesRequest = createAction(`${ENTITY}/FETCH_REQUEST`);
export const fetchCompanyNotesSuccess = createAction(`${ENTITY}/FETCH_SUCCESS`);
export const fetchCompanyNotesFailure = createAction(`${ENTITY}/FETCH_FAILURE`);

export const fetchCompany = createAction(`${ENTITY}/DETAILS/FETCH`);
export const fetchCompanyNoteRequest = createAction(`${ENTITY}/DETAILS/FETCH_REQUEST`);
export const fetchCompanyNoteSuccess = createAction(`${ENTITY}/DETAILS/FETCH_SUCCESS`);
export const fetchCompanyNoteFailure = createAction(`${ENTITY}/DETAILS/FETCH_FAILURE`);

export const createCompanyNote = createAction(
    `${ENTITY}/CREATE`,
    values => values,
    (values, callback) => ({ callback })
);
// export const createCompany = createAction(`${ENTITY}/CREATE`);
export const createCompanyNoteRequest = createAction(`${ENTITY}/CREATE_REQUEST`);
export const createCompanyNoteSuccess = createAction(`${ENTITY}/CREATE_SUCCESS`);
export const createCompanyNoteFailure = createAction(`${ENTITY}/CREATE_FAILURE`);

export const updateCompanyNote = createAction(
    `${ENTITY}/UPDATE`,
    values => values,
    (values, callback) => ({ callback })
);
// export const createCompany = createAction(`${ENTITY}/CREATE`);
export const updateCompanyNoteRequest = createAction(`${ENTITY}/UPDATE_REQUEST`);
export const updateCompanyNoteSuccess = createAction(`${ENTITY}/UPDATE_SUCCESS`);
export const updateCompanyNoteFailure = createAction(`${ENTITY}/UPDATE_FAILURE`);

export const deleteCompanyNote = createAction(`${ENTITY}/DELETE`);
  export const deleteCompanyNoteRequest = createAction(`${ENTITY}/DELTE_REQUEST`);
  export const deleteCompanyNoteSuccess = createAction(`${ENTITY}/DELTE_SUCCESS`);
  export const deleteCompanyNoteFailure = createAction(`${ENTITY}/DELETE_FAILURE`);