export function renderCurrencyWithUnit(currency, areaUnit) {
    const unit = areaUnit === 'm2' ? 'm²' : areaUnit
    const defaultCurrency = currency ?? '€'

    if (currency && areaUnit) {
        return `(${defaultCurrency}/${unit})`
    } else if (areaUnit) {
        return unit
    }
    return 'm²'
}

export function renderCurrency(currency, includeBraces = true) {
    if (!includeBraces) return `${currency ? currency : ''}`
    return `${currency ? '(' + currency + ')' : ''}`
}

export function renderAreaUnit(areaUnit) {
    return areaUnit === 'm2' ? 'm²' : areaUnit
    // return <>m<sup>2</sup></>
}

export function getOwnerFullName(owner) {
    if (!owner || !owner.contactPerson?.firstName || !owner.contactPerson?.lastName) {
        return ''
    }

    return `${owner.contactPerson.firstName} ${owner.contactPerson.lastName}`
}
