import QueryString from 'qs'
import { put, select, takeEvery } from 'redux-saga/effects'
import { MODAL_TYPES } from '../../common/constants'
import { propertyResponseFields } from '../../common/response/property'
import { formatNumberWithTwoDecimals } from '../../utils/general'
import { renderAreaUnit, renderCurrency } from '../../utils/property'
import { apiRequest } from '../api'
import { selectApplicationAreaUnit, selectApplicationCurrency } from '../settings'
import { showModal, showModalFailure, showModalRequest, showModalSuccess } from './actions'

const { OFFER_PRINT_MODAL, SHOPWINDOW_PRINT_MODAL, OFFER_SEND_MODAL } = MODAL_TYPES

function* offerPrintModalSaga(payload, meta) {
    const { modalType, modalProps } = payload
    const { properties } = modalProps[modalType]

    const areaUnit = yield select((state) => selectApplicationAreaUnit(state))
    const currency = yield select((state) => selectApplicationCurrency(state))

    if (properties) {
        const propertiesData = []
        const propertySelectOptions = []
        for (const [i, propertyItem] of properties.entries()) {
            const propertyResponseItem = propertyItem

            const photosRsponse = yield apiRequest(`v1/file/parent/${propertyItem.id}`, {
                method: 'GET',
                params: {
                    noLimit: 1,
                    order: { priority: 'asc' },
                    criteria: {
                        collection: 'photo',
                        module: 'property',
                    },
                },
                paramsSerializer: (params) => {
                    return QueryString.stringify(params)
                },
            })

            propertiesData.push({
                ...propertyResponseItem,
                photos: photosRsponse.data.items,
            })

            const propertySelectItem = {
                label: (
                    <>
                        {propertyResponseItem.id ? `${propertyResponseItem.id}, ` : ''}
                        {propertyResponseItem[propertyResponseFields.PROPERTY_SERVICE]
                            ? `${propertyResponseItem[propertyResponseFields.PROPERTY_SERVICE].name}, `
                            : ''}
                        {propertyResponseFields[propertyResponseFields.PROPERTY_TYPE]
                            ? `${propertyResponseFields[propertyResponseFields.PROPERTY_TYPE].name}`
                            : ''}{' '}
                        | {[propertyResponseFields[propertyResponseFields.CITY] ? `${propertyResponseItem[propertyResponseFields.CITY].name}, ` : '']}
                        {[
                            propertyResponseItem[propertyResponseFields.WIDER_AREA]
                                ? `${propertyResponseItem[propertyResponseFields.WIDER_AREA].name}`
                                : '',
                        ]}{' '}
                        |{' '}
                        {propertyResponseItem[propertyResponseFields.AREA] ? (
                            <>
                                {propertyResponseItem[propertyResponseFields.AREA]} {renderAreaUnit(areaUnit)},{' '}
                            </>
                        ) : (
                            ''
                        )}
                        {propertyResponseItem[propertyResponseFields.PRICE]
                            ? `${formatNumberWithTwoDecimals(propertyResponseItem[propertyResponseFields.PRICE])} ${renderCurrency(currency, false)}`
                            : ''}
                    </>
                ),
                value: propertyResponseItem.id.toString(),
            }

            propertySelectOptions.push(propertySelectItem)
            // console.log('propertyItem', propertyItem)
            // dispatch(fetchPropertyPhoto(propertyItem.id, (photos) => {
            //     offerValidation?.setFieldValue(`offer.offer_settings_group`, [
            //         ...(offerSettingsGroup ? offerSettingsGroup.filter(x => x[propertyOfferPrintFields.PROPERTY_ID] === propertyItem.id): []),
            //         {
            //             [propertyOfferPrintFields.PROPERTY_ID]: propertyItem.id,
            //             [propertyOfferPrintFields.OFFER_PHOTO_ORDER]: photos?.map(x => x.id).join(',') ?? '',
            //             [propertyOfferPrintFields.OFFER_PROPERTY_PRICE]: propertyItem[propertyResponseFields.PRICE],
            //             [propertyOfferPrintFields.OFFER_PROPERTY_PRICEM2]: propertyItem[propertyResponseFields.PRICE_M2],
            //             [propertyOfferPrintFields.OFFER_PROPERTY_AREA]: propertyItem[propertyResponseFields.AREA],
            //         }
            //     ])
            // }));
        }

        const getModalProps = () => {
            switch (modalType) {
                case OFFER_PRINT_MODAL:
                    return {
                        properties: propertiesData,
                        propertySelectOptions,
                        selectedTableRows: modalProps.selectedTableRows,
                    }

                case OFFER_SEND_MODAL:
                    return null

                case SHOPWINDOW_PRINT_MODAL: {
                    const { selectedTemplate } = modalProps[modalType]

                    return {
                        properties: propertiesData.map((prop) => {
                            const { photos, ...rest } = prop

                            const editedPhotos = photos
                                .sort((a, b) => a.priority - b.priority)
                                .map((photo) => ({ id: photo.id, propertyId: prop.id }))

                            return {
                                ...rest,
                                photos: editedPhotos,
                            }
                        }),
                        selectedTemplate,
                    }
                }

                default:
                    throw new Error('Unknown modal type')
            }
        }

        yield put(
            showModalSuccess({
                modalType,
                modalProps: {
                    [modalType]: {
                        ...getModalProps(),
                    },
                },
            })
        )
    }
}

export function* showModalSaga({ payload, meta }) {
    const { modalType, modalProps } = payload

    yield put(showModalRequest(payload))

    try {
        switch (modalType) {
            case OFFER_PRINT_MODAL:
                return yield offerPrintModalSaga(payload, meta)
            case SHOPWINDOW_PRINT_MODAL:
                return yield offerPrintModalSaga(payload, meta)
            default:
                yield put(
                    showModalSuccess({
                        modalType,
                        modalProps,
                    })
                )
                return
        }
    } catch (error) {
        yield put(showModalFailure(error))
    }
}

export default function* actionWatcher() {
    yield takeEvery(`${showModal}`, showModalSaga)
}
