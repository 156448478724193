import classNames from 'classnames'
import dayjs from 'dayjs'
import { FieldArray } from 'formik'
import _ from 'lodash'
import React from 'react'
import Flatpickr from 'react-flatpickr'
import { useTranslation } from 'react-i18next'
import { Card, CardBody, Col, FormFeedback, Input, Label, Row, TabContent, TabPane, UncontrolledTooltip } from 'reactstrap'
import { FORM_DIRECTION } from '../../common/constants'
import { CharCountFeedback } from '../Common/CharCountFeedback'
import FormTabNavigation from '../Common/FormTabNavigation'
import { AccordionInput, AsyncSelectInput, NumeralInput, PriceInput, SelectInput } from '../FormInputs'
import CheckBox from '../FormInputs/CheckBox'
import ContactCompanyCascadeInput from '../FormInputs/ContactCompanyCascadeInput'
import { PhoneNumberInput } from '../FormInputs/PhoneNumberInput'
import { SelectInputWithInputs } from '../FormInputs/SelectInputWithInputs'
import RichTextEditor from '../RichTextEditor/RichTextEditor'

const RecursiveForm = ({
    config,
    inputs,
    formik,
    selectOptions,
    subform,
    inputComponents,
    isFieldArray = false,
    fieldArrayDeleteComponent,
    inputMargin = 'mb-4',
}) => {
    const { t } = useTranslation()

    const renderLanguageInputField = (inputField, inputValue, fieldName, type, lang) => {
        let error = _.get(formik?.errors, `${fieldName}.${lang}`)
        let touched = _.get(formik?.touched, `${fieldName}.${lang}`)
        switch (type) {
            case 'text':
                return (
                    <React.Fragment>
                        <Input
                            className="tabbed-input"
                            type="text"
                            id={`${inputField.name}.${lang}`}
                            name={`${fieldName}.${lang}`}
                            disabled={inputField.disabled}
                            onChange={formik?.handleChange}
                            onBlur={formik?.handleBlur}
                            value={inputValue?.[lang] || ''}
                            placeholder={inputField.placeholder}
                            invalid={(error && touched) ?? false}
                            {...inputField?.inputProps}
                            SelectInputWithInput
                        />
                        {error && touched ? <FormFeedback type="invalid">{error}</FormFeedback> : null}
                    </React.Fragment>
                )
            case 'textarea':
                return (
                    <React.Fragment>
                        <Input
                            className="tabbed-input"
                            type="textarea"
                            id={`${inputField.name}.${lang}`}
                            name={`${fieldName}.${lang}`}
                            disabled={inputField.disabled}
                            onChange={formik?.handleChange}
                            onBlur={formik?.handleBlur}
                            value={inputValue?.[lang] || ''}
                            placeholder={inputField.placeholder}
                            invalid={(error && touched) ?? false}
                            {...inputField?.inputProps}
                        />

                        {error && touched ? <FormFeedback type="invalid">{error}</FormFeedback> : null}
                    </React.Fragment>
                )
            case 'editor':
                return (
                    <React.Fragment>
                        <RichTextEditor content={inputValue?.[lang] || ''} formik={formik} fieldName={fieldName} lang={lang} />

                        {error && touched ? <FormFeedback type="invalid">{error}</FormFeedback> : null}
                    </React.Fragment>
                )
            default:
                break
        }
    }

    const renderInputField = (inputField, index, inputsLength) => {
        let fieldName = subform ? `${subform}.${inputField?.name}` : `${inputField?.name}`
        let error = _.get(formik?.errors, fieldName)
        let touched = _.get(formik?.touched, fieldName)
        let inputValue = _.get(formik?.values, fieldName)

        if (isFieldArray && index === inputsLength) {
            const mappedColProps = {}
            if (inputField.colProps.sm) {
                mappedColProps.sm = inputField.colProps.sm - 1
            }
            if (inputField.colProps.xs) {
                mappedColProps.xs = inputField.colProps.xs - 1
            }
            if (inputField.colProps.md) {
                mappedColProps.md = inputField.colProps.md - 1
            }

            if (inputField.colProps.lg) {
                mappedColProps.lg = inputField.colProps.lg - 1
            }

            if (inputField.colProps.xl) {
                mappedColProps.xl = inputField.colProps.xl - 1
            }

            if (inputField.colProps.xxl) {
                mappedColProps.xxl = inputField.colProps.xxl - 1
            }

            inputField.colProps = {
                ...inputField.colProps,
                ...mappedColProps,
            }
        }

        switch (inputField?.type) {
            case 'text':
                return (
                    <React.Fragment key={inputField.name}>
                        <Col
                            {...inputField?.colProps}
                            className={`${inputField.colProps?.className ?? ''} ${
                                !isFieldArray ? (inputField.customMargin ? inputField.customMargin : inputMargin) : 'mb-2'
                            }`}
                        >
                            {inputField.label && (
                                <div className="d-flex justify-content-between">
                                    <Label for={fieldName} id={`${inputField.name}Lbl`}>
                                        {inputField.label}
                                        <span
                                            className={`required-mark ${
                                                inputField.required ? 'd-inline' : inputField.showRequired ? 'd-inline' : 'd-none'
                                            }`}
                                        >
                                            *
                                        </span>
                                    </Label>
                                    {inputField.tooltip && (
                                        <span id={`${inputField.name}Tooltip`}>
                                            <i className="mdi mdi-help-circle-outline"></i>{' '}
                                        </span>
                                    )}
                                </div>
                            )}
                            <Input
                                type="text"
                                className="form-control"
                                id={inputField.name}
                                name={fieldName}
                                disabled={inputField?.disabled}
                                onChange={formik?.handleChange}
                                onBlur={formik?.handleBlur}
                                value={inputValue || ''}
                                placeholder={inputField?.placeholder ?? ''}
                                invalid={(error && touched) ?? false}
                                {...inputField?.inputProps}
                            />
                            {error && touched ? <FormFeedback type="invalid">{error}</FormFeedback> : null}
                            {inputComponents && inputComponents[inputField.name] && inputComponents[inputField.name]()}
                            {inputField.tooltip && (
                                <UncontrolledTooltip pement="right" target={`${inputField.name}Tooltip`}>
                                    {inputField.tooltip}
                                </UncontrolledTooltip>
                            )}
                        </Col>
                        {isFieldArray && index === inputsLength && fieldArrayDeleteComponent}
                    </React.Fragment>
                )
            case 'textarea':
                return (
                    <React.Fragment key={inputField.name}>
                        <Col {...inputField?.colProps} className={`${inputField.colProps?.className ?? ''} ${!isFieldArray ? inputMargin : 'mb-2'}`}>
                            {inputField.label && (
                                <div className="d-flex justify-content-between">
                                    <Label for={fieldName} id={`${inputField.name}Lbl`}>
                                        {inputField.label}
                                        {inputField.required && <span className="required-mark">*</span>}
                                    </Label>
                                    {inputField.tooltip && (
                                        <span id={`${inputField.name}Tooltip`}>
                                            <i className="mdi mdi-help-circle-outline"></i>{' '}
                                        </span>
                                    )}
                                </div>
                            )}

                            <Input
                                type="textarea"
                                className="form-control"
                                id={inputField.name}
                                name={fieldName}
                                disabled={inputField?.disabled}
                                onChange={formik?.handleChange}
                                onBlur={formik?.handleBlur}
                                value={inputValue || ''}
                                placeholder={inputField?.placeholder ?? ''}
                                invalid={(error && touched) ?? false}
                                {...inputField?.inputProps}
                            />

                            {inputComponents && inputComponents[inputField.name] && inputComponents[inputField.name]()}
                            {inputField.tooltip && (
                                <UncontrolledTooltip pement="right" target={`${inputField.name}Tooltip`}>
                                    {inputField.tooltip}
                                </UncontrolledTooltip>
                            )}
                            {error && touched ? (
                                <FormFeedback type="invalid">{error}</FormFeedback>
                            ) : inputField.hint ? (
                                <CharCountFeedback label={inputField.hint} value={inputValue} max={inputField.maxLenFeedback} />
                            ) : null}
                        </Col>
                        {isFieldArray && index === inputsLength && fieldArrayDeleteComponent}
                    </React.Fragment>
                )
            case 'contact-company-cascade': {
                let fieldNames = []
                let errors = {}
                let touched = {}
                let inputValues = {}

                if (inputField?.inputs?.length) {
                    inputField.inputs.forEach((item) => {
                        let fieldName = subform ? `${subform}.${item?.name}` : `${item?.name}`
                        fieldNames.push(fieldName)
                        inputValues = {
                            ...inputValues,
                            [fieldName]: _.get(formik?.values, fieldName),
                        }
                        errors = {
                            ...errors,
                            [fieldName]: _.get(formik?.errors, fieldName),
                        }
                        touched = {
                            ...touched,
                            [fieldName]: _.get(formik?.touched, fieldName),
                        }
                    })
                }

                return (
                    <ContactCompanyCascadeInput
                        key={inputField.name}
                        inputField={inputField}
                        fieldNames={fieldNames}
                        inputValues={inputValues}
                        formik={formik}
                        selectOptions={selectOptions}
                    />
                )
            }
            case 'select-with-inputs':
                return (
                    <SelectInputWithInputs
                        key={inputField.name}
                        inputField={inputField}
                        fieldName={fieldName}
                        isFieldArray={isFieldArray}
                        formik={formik}
                        error={error}
                        touched={touched}
                        inputComponents={inputComponents}
                        inputValue={inputValue}
                        index={index}
                        fieldArrayDeleteComponent={fieldArrayDeleteComponent}
                        selectOptions={selectOptions}
                        inputsLength={inputsLength}
                        inputMargin={inputMargin}
                    />
                )
            case 'select':
                return (
                    <SelectInput
                        key={inputField.name}
                        inputField={inputField}
                        fieldName={fieldName}
                        isFieldArray={isFieldArray}
                        formik={formik}
                        error={error}
                        touched={touched}
                        inputComponents={inputComponents}
                        inputValue={inputValue}
                        index={index}
                        fieldArrayDeleteComponent={fieldArrayDeleteComponent}
                        selectOptions={selectOptions}
                        inputsLength={inputsLength}
                        inputMargin={inputMargin}
                    />
                )
            case 'checkbox':
                return (
                    <CheckBox
                        key={inputField.name}
                        inputValue={inputValue}
                        inputField={inputField}
                        fieldName={fieldName}
                        isFieldArray={isFieldArray}
                        inputMargin={inputMargin}
                        index={index}
                        inputsLength={inputsLength}
                        fieldArrayDeleteComponent={fieldArrayDeleteComponent}
                        formik={formik}
                        error={error}
                        touched={touched}
                    />
                )
            case 'radio':
                return (
                    <React.Fragment key={inputField.name}>
                        <Col
                            key={inputField.name}
                            {...inputField.colProps}
                            className={`${
                                !isFieldArray ? (inputField.customMargin ? inputField.customMargin : inputMargin) : 'mb-2'
                            } d-flex align-items-center ${inputField.colProps.className ?? ''} `}
                        >
                            <Row>
                                {inputField.options?.map((radioOpt, index) => (
                                    <Col
                                        key={radioOpt.value}
                                        {...inputField.optionColProps}
                                        className={`${index + 1 !== inputField.options.length ? 'mb-2' : ''}`}
                                    >
                                        <div className="form-check">
                                            <Input
                                                type="radio"
                                                className="form-check-input"
                                                id={inputField.name + radioOpt.value}
                                                name={fieldName}
                                                onChange={formik?.handleChange}
                                                onBlur={formik?.handleBlur}
                                                value={radioOpt.value || ''}
                                                invalid={(error && touched) ?? false}
                                                checked={inputValue === radioOpt.value}
                                                {...inputField?.inputProps}
                                            />
                                            <Label className="form-check-label" for={inputField.name + radioOpt.value}>
                                                {radioOpt.label}
                                                {inputField.required && <span className="required-mark">*</span>}
                                                {radioOpt.tooltip && (
                                                    <span id={`${inputField.name}${radioOpt.value}Tooltip`}>
                                                        {' '}
                                                        <i className="mdi mdi-help-circle-outline"></i>
                                                    </span>
                                                )}
                                            </Label>
                                        </div>
                                        {radioOpt.tooltip && (
                                            <UncontrolledTooltip placement="right" target={`${inputField.name}${radioOpt.value}Tooltip`}>
                                                {radioOpt.tooltip}
                                            </UncontrolledTooltip>
                                        )}
                                    </Col>
                                ))}
                            </Row>
                        </Col>
                        {isFieldArray && index === inputsLength && fieldArrayDeleteComponent}
                    </React.Fragment>
                )
            case 'number':
                return (
                    <React.Fragment key={inputField.name}>
                        <Col
                            key={inputField.name}
                            {...inputField.colProps}
                            className={`${inputField.colProps?.className ?? ''} ${!isFieldArray ? inputMargin : 'mb-2'}`}
                        >
                            {inputField && (
                                <div className="d-flex justify-content-between">
                                    <Label for={fieldName} id={`${inputField.name}Lbl`}>
                                        {inputField.label}
                                        {inputField.required && <span className="required-mark">*</span>}
                                    </Label>
                                    {inputField.tooltip && (
                                        <span id={`${inputField.name.replace(/\./g, '')}Tooltip`}>
                                            <i className="mdi mdi-help-circle-outline"></i>{' '}
                                        </span>
                                    )}
                                </div>
                            )}

                            <Input
                                type="number"
                                className="form-control"
                                id={inputField.name}
                                name={fieldName}
                                disabled={inputField.disabled}
                                onChange={formik?.handleChange}
                                onBlur={formik?.handleBlur}
                                value={inputValue || ''}
                                placeholder={inputField.placeholder}
                                invalid={(error && touched) ?? false}
                                {...inputField?.inputProps}
                            />
                            {error && touched ? <FormFeedback type="invalid">{error}</FormFeedback> : null}
                            {inputComponents && inputComponents[inputField.name] && inputComponents[inputField.name]()}
                            {inputField.tooltip && (
                                <UncontrolledTooltip pement="right" target={`${inputField.name.replace(/\./g, '')}Tooltip`}>
                                    {inputField.tooltip}
                                </UncontrolledTooltip>
                            )}
                        </Col>
                        {isFieldArray && index === inputsLength && fieldArrayDeleteComponent}
                    </React.Fragment>
                )
            case 'phone':
                return (
                    <PhoneNumberInput
                        key={inputField.name}
                        inputField={inputField}
                        isFieldArray={isFieldArray}
                        fieldName={fieldName}
                        inputValue={inputValue}
                        formik={formik}
                        error={error}
                        touched={touched}
                        inputComponents={inputComponents}
                        index={index}
                        fieldArrayDeleteComponent={fieldArrayDeleteComponent}
                        inputsLength={inputsLength}
                        inputMargin={inputMargin}
                    />
                )
            case 'price':
                return (
                    <PriceInput
                        key={inputField.name}
                        inputField={inputField}
                        isFieldArray={isFieldArray}
                        fieldName={fieldName}
                        inputValue={inputValue}
                        formik={formik}
                        error={error}
                        touched={touched}
                        inputComponents={inputComponents}
                        index={index}
                        fieldArrayDeleteComponent={fieldArrayDeleteComponent}
                        inputsLength={inputsLength}
                        inputMargin={inputMargin}
                    />
                )
            case 'numeral':
                return (
                    <NumeralInput
                        key={inputField.name}
                        inputField={inputField}
                        isFieldArray={isFieldArray}
                        fieldName={fieldName}
                        inputValue={inputValue}
                        formik={formik}
                        error={error}
                        touched={touched}
                        inputComponents={inputComponents}
                        index={index}
                        fieldArrayDeleteComponent={fieldArrayDeleteComponent}
                        inputsLength={inputsLength}
                        inputMargin={inputMargin}
                    />
                )
            case 'date':
                return (
                    <React.Fragment key={inputField.name}>
                        <Col {...inputField?.colProps} className={`${inputField.colProps?.className ?? ''} ${!isFieldArray ? inputMargin : 'mb-2'}`}>
                            {inputField.label && (
                                <div className="d-flex justify-content-between">
                                    <Label for={fieldName} id={`${inputField.name}Lbl`}>
                                        {inputField.label}
                                        {inputField.required && <span className="required-mark">*</span>}
                                    </Label>
                                    {inputField.tooltip && (
                                        <span id={`${inputField.name}Tooltip`}>
                                            <i className="mdi mdi-help-circle-outline"></i>{' '}
                                        </span>
                                    )}
                                </div>
                            )}
                            <div className="flatpicker-container">
                                <Flatpickr
                                    className={classNames('form-control', {
                                        'is-invalid': error && touched,
                                    })}
                                    name={fieldName}
                                    id={inputField.name}
                                    disabled={inputField?.disabled}
                                    options={{
                                        dateFormat: 'd-m-Y',
                                    }}
                                    onChange={([date]) => {
                                        formik?.setFieldValue(fieldName, dayjs(date).format('YYYY-MM-DD'))
                                    }}
                                    value={inputValue ? new Date(inputValue) : ''}
                                    placeholder={inputField.placeholder}
                                />
                                {inputValue && (
                                    <span className="flatpicker-clear">
                                        <i className="mdi mdi-close" onClick={() => formik?.setFieldValue(fieldName, '')}></i>
                                    </span>
                                )}
                                {error && touched ? <FormFeedback type="invalid">{error}</FormFeedback> : null}
                                {inputComponents && inputComponents[inputField.name] && inputComponents[inputField.name]()}
                                {inputField.tooltip && (
                                    <UncontrolledTooltip pement="right" target={`${inputField.name}Tooltip`}>
                                        {inputField.tooltip}
                                    </UncontrolledTooltip>
                                )}
                            </div>
                        </Col>
                        {isFieldArray && index === inputsLength && fieldArrayDeleteComponent}
                    </React.Fragment>
                )
            case 'time':
                return (
                    <React.Fragment key={inputField.name}>
                        <Col
                            key={inputField.name}
                            {...inputField?.colProps}
                            className={`${inputField.colProps?.className ?? ''} ${!isFieldArray ? inputMargin : 'mb-2'}`}
                        >
                            {inputField.label && (
                                <div className="d-flex justify-content-between">
                                    <Label for={fieldName} id={`${inputField.name}Lbl`}>
                                        {inputField.label}
                                        {inputField.required && <span className="required-mark">*</span>}
                                    </Label>
                                    {inputField.tooltip && (
                                        <span id={`${inputField.name}Tooltip`}>
                                            <i className="mdi mdi-help-outline"></i>{' '}
                                        </span>
                                    )}
                                </div>
                            )}

                            <Flatpickr
                                className={classNames('form-control', {
                                    'is-invalid': error && touched,
                                })}
                                name={fieldName}
                                id={inputField.name}
                                disabled={inputField?.disabled}
                                onChange={([date]) => {
                                    formik?.setFieldValue(fieldName, dayjs(date).format('HH:mm'))
                                }}
                                value={inputValue ? inputValue : ''}
                                options={{
                                    enableTime: true,
                                    noCalendar: true,
                                    dateFormat: 'H:i',
                                    time_24hr: true,
                                }}
                            />
                            {error && touched ? <FormFeedback type="invalid">{error}</FormFeedback> : null}
                            {inputComponents && inputComponents[inputField.name] && inputComponents[inputField.name]()}
                            {inputField.tooltip && (
                                <UncontrolledTooltip pement="right" target={`${inputField.name}Tooltip`}>
                                    {inputField.tooltip}
                                </UncontrolledTooltip>
                            )}
                        </Col>
                        {isFieldArray && index === inputsLength && fieldArrayDeleteComponent}
                    </React.Fragment>
                )
            case 'multilanguage':
                return (
                    <Col
                        key={inputField.name}
                        {...inputField?.colProps}
                        className={`${inputField.colProps?.className ?? ''} ${!isFieldArray ? inputMargin : 'mb-2'}`}
                    >
                        {inputField.label && (
                            <div className="d-flex justify-content-between">
                                <Label for={fieldName} id={`${inputField.name}Lbl`}>
                                    {inputField.label}
                                    {inputField.required && <span className="required-mark">*</span>}
                                </Label>
                                {inputField.tooltip && (
                                    <span id={`${inputField.name}Tooltip`}>
                                        <i className="mdi mdi-help-outline"></i>{' '}
                                    </span>
                                )}
                            </div>
                        )}
                        {inputField.description && (
                            <p className="fw-light text-secondary mb-3">
                                <small>{inputField.description}</small>
                            </p>
                        )}
                        {inputField.languageStyle === 'tabs' && (
                            <React.Fragment>
                                <FormTabNavigation
                                    customClass="language-input-tabs"
                                    tabs={inputField.tabs}
                                    activeTab={inputField.activeTab}
                                    handleTabChange={inputField.handleTabChange}
                                />
                                <TabContent activeTab={inputField.activeTab}>
                                    {inputField.tabs?.map((tab) => {
                                        return (
                                            <TabPane key={`${inputField.name}-${tab.name}`} tabId={tab.name}>
                                                {renderLanguageInputField(inputField, inputValue, fieldName, inputField.inputType, tab.name)}
                                                {inputField.hint && inputValue && (
                                                    <CharCountFeedback
                                                        label={inputField.hint}
                                                        value={inputValue[tab.name]}
                                                        max={inputField.maxLenFeedback}
                                                    />
                                                )}
                                            </TabPane>
                                        )
                                    })}
                                </TabContent>
                            </React.Fragment>
                        )}
                    </Col>
                )
            case 'fieldArray':
                return (
                    <FieldArray
                        key={inputField.name}
                        name={fieldName}
                        render={(arrayHelpers) => {
                            return (
                                <div className={`${inputMargin}`}>
                                    {inputField.label && (
                                        <div className="d-flex justify-content-between">
                                            <Label className={`${inputField.labelProps?.className}`} for={fieldName} id={`${inputField.name}Lbl`}>
                                                {inputField.label}
                                                {inputField.required && <span className="required-mark">*</span>}
                                            </Label>
                                            {inputField.tooltip && (
                                                <span id={`${inputField.name}Tooltip`}>
                                                    <i className="mdi mdi-help-circle-outline"></i>{' '}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                    {inputValue?.map((fieldItem, index) => {
                                        const fieldArrayInputs = inputField.children.map((inpt) => ({
                                            ...inpt,
                                            oldName: inpt.name,
                                            name: `${inputField.name}.${index}.${inpt.name}`,
                                        }))

                                        return (
                                            <div key={index}>
                                                <RecursiveForm
                                                    key={inputField.name}
                                                    subform={subform}
                                                    inputs={fieldArrayInputs}
                                                    formik={formik}
                                                    selectOptions={selectOptions}
                                                    isFieldArray={true}
                                                    fieldArrayDeleteComponent={
                                                        <Col sm={1} className="d-flex align-items-center mb-2">
                                                            {index > 0 && (
                                                                <span
                                                                    className="text-danger"
                                                                    onClick={() => arrayHelpers.remove(index)}
                                                                    style={{ cursor: 'pointer' }}
                                                                >
                                                                    <i className="mdi mdi-trash-can-outline fs-17"></i>
                                                                </span>
                                                            )}
                                                        </Col>
                                                    }
                                                />
                                            </div>
                                        )
                                    })}
                                    {inputField.tooltip && (
                                        <UncontrolledTooltip pement="right" target={`${inputField.name}Tooltip`}>
                                            {inputField.tooltip}
                                        </UncontrolledTooltip>
                                    )}
                                    <div
                                        style={{ cursor: 'pointer' }}
                                        className="d-inline-block text-edit align-items-center"
                                        onClick={() =>
                                            arrayHelpers.push(
                                                inputField.children.reduce(
                                                    (dict, item) => ({
                                                        ...dict,
                                                        [item.name]: '',
                                                    }),
                                                    {}
                                                )
                                            )
                                        }
                                    >
                                        <i className="mdi mdi-plus-circle fs-17"></i> {t('button.add')}
                                    </div>
                                </div>
                            )
                        }}
                    />
                )
            case 'grouped':
                return (
                    <Col key={inputField.name} {...inputField?.colProps} className={`${inputField.colProps?.className ?? ''} ${inputMargin}`}>
                        <Row>
                            <div className="d-flex justify-content-between">
                                <Label for={fieldName} id={`${inputField.name}Lbl`}>
                                    {inputField.label}
                                    {inputField.required && <span className="required-mark">*</span>}
                                </Label>
                                {inputField.tooltip && (
                                    <span id={`${inputField.name.replace(/\./g, '')}Tooltip`}>
                                        <i className="mdi mdi-help-circle-outline"></i>{' '}
                                    </span>
                                )}
                                {inputField.tooltip && (
                                    <UncontrolledTooltip pement="right" target={`${inputField.name.toString().replace(/\./g, '')}Tooltip`}>
                                        {inputField.tooltip}
                                    </UncontrolledTooltip>
                                )}
                            </div>
                            {inputField?.items?.map((item) => renderInputField(item))}
                            {inputField.warning && (
                                <FormFeedback type="invalid" className="d-block mb-2">
                                    {inputField.warning}
                                </FormFeedback>
                            )}
                        </Row>
                    </Col>
                )
            case 'async-select':
                return (
                    <AsyncSelectInput
                        key={inputField.name}
                        inputField={inputField}
                        subform={subform}
                        inputs={inputField}
                        formik={formik}
                        selectOptions={selectOptions}
                        isFieldArray={isFieldArray}
                        fieldName={fieldName}
                        error={error}
                        touched={touched}
                        inputComponents={inputComponents}
                        inputValue={inputValue}
                        index={index}
                        fieldArrayDeleteComponent={fieldArrayDeleteComponent}
                        inputsLength={inputsLength}
                        inputMargin={inputMargin}
                    />
                )
            case 'accordion':
                return <AccordionInput key={inputField.name} subform={subform} inputs={inputField} formik={formik} selectOptions={selectOptions} />
            case 'array':
                return (
                    <RecursiveForm
                        key={inputField.name}
                        subform={subform}
                        inputs={inputField.children}
                        formik={formik}
                        selectOptions={selectOptions}
                        inputMargin={inputMargin}
                    />
                )
            case 'divider':
                return <div className="mt-3 mb-3 dmn-divider" key={`divider-${index}`} />
            case 'empty':
                return <Col key={`${fieldName}-${index}`} {...inputField?.colProps} />
            case 'empty-row':
                return (
                    <Col key={`${fieldName}-${index}`} {...inputField?.colProps}>
                        <Input
                            type="text"
                            className="form-control"
                            style={{
                                height: '60px',
                                visibility: 'hidden',
                            }}
                        />
                    </Col>
                )
            case 'empty-column-noLabel':
                return (
                    <Col
                        key={fieldName}
                        {...inputField?.colProps}
                        className={`${inputField.colProps?.className ?? ''} ${!isFieldArray ? inputMargin : 'mb-2'}`}
                    >
                        <Input
                            type="text"
                            className="form-control"
                            style={{
                                visibility: 'hidden',
                            }}
                        />
                    </Col>
                )
            case 'empty-column':
                return (
                    <Col
                        key={fieldName}
                        {...inputField?.colProps}
                        className={`${inputField.colProps?.className ?? ''} ${!isFieldArray ? inputMargin : 'mb-2'}`}
                    >
                        <Label
                            for={fieldName}
                            style={{
                                height: '19.5px',
                                visibility: 'hidden',
                            }}
                        ></Label>

                        <Input
                            type="text"
                            className="form-control"
                            style={{
                                visibility: 'hidden',
                            }}
                        />
                    </Col>
                )
            default:
                return null
        }
    }

    if (config && config?.direction === FORM_DIRECTION.COLUMN) {
        return (
            <Row>
                {inputs?.map((item, indx) => (
                    <Col {...config.colProps} key={indx}>
                        {item?.map((inputField) => renderInputField(inputField))}
                    </Col>
                ))}
            </Row>
        )
    }

    return (
        <>
            {inputs && Array.isArray(inputs[0]) ? (
                inputs?.map((item, indx) => (
                    <Card key={indx}>
                        <CardBody>
                            <Row>{item?.map((inputField) => renderInputField(inputField))}</Row>
                        </CardBody>
                    </Card>
                ))
            ) : inputs?.length ? (
                <Row>{inputs.map((inputField, index) => renderInputField(inputField, index + 1, inputs.length))}</Row>
            ) : (
                <Row>{renderInputField(inputs)}</Row>
            )}
        </>
    )
}
export default RecursiveForm
