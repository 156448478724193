import React from 'react'
import { Switch, Route } from 'react-router-dom'

//Layouts
import NonAuthLayout from '../Layouts/NonAuthLayout'
import VerticalLayout from '../Layouts/index'
import AdminLayout from '../Layouts/AdminLayout/AdminLayout'
//routes
import { authProtectedRoutes, publicRoutes, adminProtectedRoutes } from './allRoutes'
import { AuthProtected, AccessRoute } from './AuthProtected'

const Index = () => {
    const availablePublicRoutesPaths = publicRoutes.map((r) => r.path)
    const availableAuthRoutesPath = authProtectedRoutes.map((r) => r.path)
    const adminProtectedRoutesPath = adminProtectedRoutes.map((r) => r.path)

    return (
        <React.Fragment>
            <Switch>
                <Route path={availablePublicRoutesPaths}>
                    <NonAuthLayout>
                        <Switch>
                            {publicRoutes.map((route, idx) => (
                                <Route path={route.path} component={route.component} key={idx} exact={true} />
                            ))}
                        </Switch>
                    </NonAuthLayout>
                </Route>

                <Route path={adminProtectedRoutesPath}>
                    <AuthProtected>
                        <AdminLayout>
                            <Switch>
                                {adminProtectedRoutes.map((route, idx) => (
                                    <AccessRoute path={route.path} component={route.component} key={idx} exact={true} />
                                ))}
                            </Switch>
                        </AdminLayout>
                    </AuthProtected>
                </Route>

                <Route path={availableAuthRoutesPath}>
                    <AuthProtected>
                        <VerticalLayout>
                            <Switch>
                                {authProtectedRoutes.map((route, idx) => (
                                    <AccessRoute path={route.path} component={route.component} key={idx} exact={true} />
                                ))}
                            </Switch>
                        </VerticalLayout>
                    </AuthProtected>
                </Route>
            </Switch>
        </React.Fragment>
    )
}

export default Index
