import { baseCheckboxInput, baseRadioInput, baseTextInput } from '../fieldTypes'
import { mapFields } from './fields'
import i18next from 'i18next'

export const mapFieldTypes = () => {
    const { t } = i18next
    return {
        [mapFields.MAP_LATITUDE]: {
            ...baseTextInput,
            name: mapFields.MAP_LATITUDE,
            label: t('view.project.map.coordinates.title'),
            placeholder: t('view.project.map.coordinates.latitude'),
            customMargin: 'mb-2',
            colProps: {
                sm: 12,
            },
        },
        [mapFields.MAP_LONGITUDE]: {
            ...baseTextInput,
            name: mapFields.MAP_LONGITUDE,
            placeholder: t('view.project.map.coordinates.longitude'),
            customMargin: 'mb-0',
            colProps: {
                sm: 12,
            },
        },
        [mapFields.MAP_LOCATION_SYNC_TYPE]: {
            ...baseRadioInput,
            name: mapFields.MAP_LOCATION_SYNC_TYPE,
            options: [
                {
                    value: '1',
                    label: t('view.project.map.location.exact'),
                    default: true,
                },
                {
                    value: '2',
                    label: t('view.project.map.location.approximate'),
                    tooltip: t('view.project.map.location.approximate.tooltip'),
                },
            ],
            optionColProps: {
                sm: 12,
            },
            customMargin: 'mb-0',
            colProps: {
                sm: 12,
            },
        },
        [mapFields.MAP_LOCATION_SYNC]: {
            ...baseCheckboxInput,
            name: mapFields.MAP_LOCATION_SYNC,
            label: t('form.property.map.sync'),
            colProps: {
                sm: 12,
            },
        },
    }
}
