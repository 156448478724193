import { useEffect, useState } from 'react'
import {
    actionTranslations,
    buttonTranslations,
    colorPickerLabel,
    customCropLabel,
    customIconLabel,
    drawTranslations,
    filterTranslations,
    filterTranslations2,
    iconTranslations,
    rangeLabelDistance,
    rangeLabelShape,
    rangeLabelText,
    rangeLabelTreshold,
    rangleLabelRD,
    ratioLabel,
    resizeLabelsTranslations,
    shapeTranslations,
    textAlignTranslations,
} from './translations'

const toolbarTranslations = () => {
    getEditModeTranslations(buttonTranslations, 'toolbar')
}

const actionButtonsTranslation = (crop) => {
    const cropActionsContainer = document.querySelector('.tie-crop-button')
    const resizeActionsContainer = document.querySelector('.tie-resize-button')
    const container = crop ? cropActionsContainer : resizeActionsContainer

    if (container) {
        for (const [buttonClass, translation] of Object.entries(actionTranslations)) {
            const button = container.querySelector(buttonClass)
            const svg = button.querySelector('svg')
            const useElement = svg.firstElementChild

            const label = button.querySelector('label')
            label.textContent = translation

            const isApplyBtn = button.classList.contains('apply')

            if (isApplyBtn) {
                useElement.style.fill = 'green'
                useElement.style.stroke = 'green'
                useElement.style.strokeWidth = '1.5px'
            } else {
                useElement.style.fill = 'red'
                useElement.style.stroke = 'red'
                useElement.style.strokeWidth = '1.5px'
            }
        }
    }
}

const cropTranslations = () => {
    const customCropBtn = document.querySelector('.preset-none')
    const label = customCropBtn.querySelector('label')
    label.textContent = customCropLabel
}

const resizeTranslations = () => {
    const resizeContainer = document.querySelector('.tui-image-editor-range-wrap')
    const ratioCheckbox = document.querySelector('.tie-lock-aspect-ratio')

    if (resizeContainer) {
        const labels = resizeContainer.querySelectorAll('label')
        labels.forEach((label, index) => (label.textContent = resizeLabelsTranslations[index]))
    }

    if (ratioCheckbox) {
        const span = ratioCheckbox.nextElementSibling
        span.textContent = ratioLabel
    }
}

const rotateDrawRangeTranslation = (rotate) => {
    const rotateRange = document.querySelector('.tie-rotate-range')
    const drawRange = document.querySelector('.tie-draw-range')

    const rangeElement = rotate ? rotateRange : drawRange

    if (rangeElement) {
        const label = rangeElement.previousElementSibling
        label.textContent = rangleLabelRD
    }
}

const drawTranslation = () => {
    getEditModeTranslations(drawTranslations, 'draw')
}

const shapeTranslation = () => {
    getEditModeTranslations(shapeTranslations, 'shape')
    rangeTranslation('.tie-stroke-range', rangeLabelShape)
}

const iconsTranslation = () => {
    const iconAddBtn = document.querySelector('.tie-icon-image-file')
    const customIconlabel = iconAddBtn.parentElement.nextElementSibling
    customIconlabel.textContent = customIconLabel

    getEditModeTranslations(iconTranslations, 'icon')
    colorPickerTranslation('.tie-icon-color')
}

const textTranslation = () => {
    const textAlign = document.querySelector('.tie-text-align-button')
    const labels = textAlign.querySelectorAll('label')

    labels.forEach((label, index) => (label.textContent = textAlignTranslations[index]))

    rangeTranslation('.tie-text-range', rangeLabelText)
    colorPickerTranslation('.tie-text-color')
}

const filterTranslation = () => {
    getEditModeTranslations(filterTranslations, 'filter')
    getEditModeTranslations(filterTranslations2, 'filter2')
    rangeTranslation('.tie-removewhite-distance-range', rangeLabelDistance)
    rangeTranslation('.tie-colorfilter-threshold-range', rangeLabelTreshold)
}

// helper functions

const useActiveButton = (buttonClass1, buttonClass2, callback) => {
    const [isActive, setIsActive] = useState(false)

    useEffect(() => {
        const btn1 = document.querySelector(buttonClass1)
        const btn2 = document.querySelector(buttonClass2)

        btn1.addEventListener('click', () => setIsActive(true))
        btn2.addEventListener('click', () => setIsActive(false))

        callback(isActive)
    }, [buttonClass1, buttonClass2, isActive, callback])
}

const getEditModeTranslations = (translations, mode) => {
    for (const [elementClass, translation] of Object.entries(translations)) {
        const element = document.querySelector(mode === 'icon' ? `[data-icontype="${elementClass}"]` : elementClass)

        switch (mode) {
            case 'draw':
            case 'shape':
            case 'icon': {
                const label = element.querySelector('label')
                label.textContent = translation
                break
            }
            case 'toolbar': {
                element.setAttribute('tooltip-content', translation)
                break
            }
            case 'filter': {
                const span = element.nextElementSibling
                span.textContent = translation
                break
            }
            case 'filter2': {
                const label = element.lastChild
                label.textContent = translation
                break
            }
            default:
                break
        }
    }
}

const colorPickerTranslation = (buttonClass) => {
    const colorPicker = document.querySelector(buttonClass)
    const colorLabel = colorPicker.querySelector('label')
    colorLabel.textContent = colorPickerLabel
}

const rangeTranslation = (buttonClass, translation) => {
    const range = document.querySelector(buttonClass)
    const label = range.previousElementSibling
    label.textContent = translation
}

export {
    actionButtonsTranslation,
    cropTranslations,
    drawTranslation,
    filterTranslation,
    iconsTranslation,
    resizeTranslations,
    rotateDrawRangeTranslation,
    shapeTranslation,
    textTranslation,
    toolbarTranslations,
    useActiveButton,
}
