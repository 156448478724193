import values from 'lodash/values';

import { stateKey } from './actions';

export const selectCompanies = state => values(state[stateKey].items);
export const selectCompany = state => state[stateKey].item;
export const selectCompaniesFetching = state => state[stateKey].fetching;
export const selectCompaniesCount = state => state[stateKey].count;
export const selectCompanyFetchingDetails = state => state[stateKey].fetchingDetails;
export const selectCompanyProfile = (state, id) => state[stateKey].item?.profiles?.[id];
export const selectCompaniesPagination = (state) => state[stateKey].pagination;
export const selectCompanyLinks = (state) => state[stateKey].links

export const selectCompanyAsycDocumentCompanyFormOptions = (state) => {
    const data = state[stateKey].item;
    // console.log('data company', data)
    if(data) {
        return [
            {
                label: data?.contactCompany?.name,
                value: data.id?.toString()
            }
        ]
    }

    return [];
}
