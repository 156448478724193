import values from 'lodash/values'
import { projectResponseFields } from '../../common/response/project'
import { contactOptions, findEntityTitle, generateLocationData, selectStateOwnerFormOptions } from '../../utils/general'
import { stateKey } from './actions'

export const selectProperties = (state) => values(state[stateKey].items)
export const selectPropertiesFetching = (state) => state[stateKey].fetching
export const selectPropertyFetchingDetails = (state) => state[stateKey].fetchingDetails
export const selectPropertyCount = (state) => state[stateKey].count
export const selectProperty = (state) => state[stateKey].item

export const selectOpportunityActivity = (state) => state[stateKey].itemsOpportunityActivity
export const selectOpportunityActivityCount = (state) => state[stateKey].countOpportunityActivity
export const selectOpportunityActivityFetching = (state) => state[stateKey].fetchingOpportunityActivity

export const selectOpportunityContact = (state) => state[stateKey].itemsOpportunityContact
export const selectOpportunityContactCount = (state) => state[stateKey].countOpportunityContact
export const selectOpportunityContactFetching = (state) => state[stateKey].fetchingOpportunityContact

export const selectOpportunityShowActivity = (state) => state[stateKey].opportunityShowActivity
export const selectOpportunityTitle = (state) => state[stateKey].opportunityTitle
export const selectOpportunityDropDownButtonText = (state) => state[stateKey].opportunityDropDownButtonText

export const selectPropertyPagination = (state) => state[stateKey].pagination
export const selectPropertyLinks = (state) => state[stateKey].links

export const selectPropertyPhoto = (state) => values(state[stateKey].itemphoto)
export const selectPropertyPhotoQue = (state) => values(state[stateKey].photoque)
export const selectPropertyFetchingPhotos = (state) => state[stateKey].fetchingPhotos

export const selectPropertiesContactProfile = (state) => state[stateKey].itemsPropertiesContactProfile
export const selectPropertiesContactProfileCount = (state) => state[stateKey].countPropertiesContactProfile
export const selectPropertiesContactProfileFetching = (state) => state[stateKey].fetchingPropertiesContactProfile

export const selectPropertyAsyncOwnerFormOptions = (state) => {
    const stateSlice = state[stateKey].item?.owner
    return selectStateOwnerFormOptions(stateSlice)
}

export const selectPropertyAsyncProjectFormOptions = (state) => {
    const data = state[stateKey].item?.project

    if (data?.id) {
        return [
            {
                label: `${findEntityTitle(data, projectResponseFields.PROJECT_TITLE)} - ID: ${data.id}`,
                value: data?.id?.toString(),
            },
        ]
    }

    if (data?.length) {
        return data?.map((item) => ({
            label: `${findEntityTitle(item, projectResponseFields.PROJECT_TITLE)} - ID: ${item.id}`,
            value: item?.id?.toString(),
        }))
    }

    return []
}

export const selectPropertyContactAsyncSelectedFormOptions = (state) => {
    const contacts = state[stateKey].item?.propertyTransaction?.contact

    if (contacts?.length > 0) {
        return contacts.map((contact) => contactOptions(contact)[0])
    }

    return []
}

export const selectPropertyAsyncSelectedFormOptions = (state) => {
    const data = state[stateKey].item
    const currentLang = localStorage.getItem('i18nextLng')

    const createOption = (item, currentLang) => {
        return {
            value: item?.id?.toString(),
            label: `ID: ${item.id} - ${item.translations[currentLang]?.propertyTitle ?? generateLocationData(item)}`,
            location: generateLocationData(item)
        }
    }

    if (!data) return []

    if (Array.isArray(data)) {
        return data.map((item) => createOption(item, currentLang))
    } else {
        const option = createOption(data, currentLang)
        return [option]
    }
}
