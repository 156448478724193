import { diaryDetailSearchFieldTypes } from './fieldTypes'
import { diaryDetailSearchFields } from './fields'
import i18n from '../../../i18n'

export const diaryDetailSearchInputs = () => {
    const inputs = diaryDetailSearchFieldTypes()
    return {
        mainForm: [
            {
                type: 'array',
                name: 'idGroup',
                children: inputs[diaryDetailSearchFields.ID],
            },
            {
                type: 'array',
                name: 'personGroup',
                children: [
                    {
                        type: 'grouped',
                        name: 'diaryPersonGrouped',
                        items: [inputs[diaryDetailSearchFields.FULL_NAME_SELLER], inputs[diaryDetailSearchFields.FULL_NAME_BUYER]],
                    },
                ],
            },
            {
                type: 'array',
                name: 'dateGroup',
                children: [
                    inputs[diaryDetailSearchFields.LOCATION],
                    {
                        type: 'grouped',
                        name: 'diaryDateGrouped',
                        label: i18n.t('app.common.conclusionDate'),
                        colProps: {
                            sm: 12,
                            xl: 6,
                        },
                        items: [inputs[diaryDetailSearchFields.DIARY_DATE_FROM], inputs[diaryDetailSearchFields.DIARY_DATE_TO]],
                    },
                ],
            },
        ],
    }
}
