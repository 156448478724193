import QueryString from 'qs'
import { apiReq } from '..'
import { priorityCompareFn } from '../../utils/general'

export const getImagesById = async (id: number, module: string, collection: string) => {
    const paramsLink = `noLimit=1&order[priority]=asc&criteria[module]=${module}&criteria[collection]=${collection}`
    const paramsEncoded = QueryString.stringify(QueryString.parse(paramsLink))

    const response = await apiReq(`v1/file/parent/${id}?${paramsEncoded}`)
    return response?.data.items.sort(priorityCompareFn)
}
