import { Col, Input, Label, Row } from 'reactstrap'
import Cleave from 'cleave.js/react'

const ModalInputWithCheckBox = (props) => {
    const { children, selectedProperty, setSelectedProperty, name, checkBoxValue, textBoxvalue } = props

    return (
        <Row className="aling-items-center mb-4">
            <Col sm={6} className="align-self-center">
                <div className="form-check">
                    <Input
                        key={name + selectedProperty?.property_id + '_Chck'}
                        type="checkbox"
                        className="form-check-input"
                        id={name + selectedProperty?.property_id + '_Chck'}
                        name={name + selectedProperty?.property_id + '_Chck'}
                        onChange={(e) => {
                            setSelectedProperty({
                                ...selectedProperty,
                                [checkBoxValue]: e.target.checked,
                            })
                        }}
                        checked={selectedProperty?.[checkBoxValue]}
                    />
                    <Label className="form-check-label" for={name + selectedProperty?.id + '_Chck'}>
                        {children}
                    </Label>
                </div>
            </Col>
            <Col sm={6}>
                <Cleave
                    options={{
                        numeral: true,
                        numeralDecimalMark: ',',
                        delimiter: '.',
                    }}
                    value={selectedProperty?.[checkBoxValue] && selectedProperty?.[textBoxvalue]}
                    onBlur={(e) => {
                        setSelectedProperty((prevState) => ({
                            ...prevState,
                            [textBoxvalue]: e.target.value,
                        }))
                    }}
                    disabled={!selectedProperty?.[checkBoxValue]}
                    id={name + selectedProperty?.property_id}
                    name={name + selectedProperty?.property_id}
                    className="form-control"
                />
            </Col>
        </Row>
    )
}

export default ModalInputWithCheckBox
