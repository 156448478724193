import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { Project } from './types'
import { getSearchCriteriaFromStore } from '../helpers'
import { getProjectById } from './functions'

const projects = {
    GetProject: {
        useQuery: (id: number, refreshToken: string, options?: UseQueryOptions<Project, AxiosError>) => useQuery({
            queryKey: ['project', id, getSearchCriteriaFromStore('project'), refreshToken],
            queryFn: () => getProjectById(id),
            staleTime: 60 * 1000,
            ...options,
        })
    }
}

export default projects