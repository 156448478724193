import classNames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'reactstrap'
import { selectExportSync, selectFetchingExportSync } from '../store/export'
import DateTime from './Common/DateTime'
import { MODAL_TYPES } from '../common/constants'
import { showModal } from '../store/modal'

const ExportSyncWidget = ({ className }) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const {
        sync: { lastSynchronization, synchronizationLeft },
        loading,
    } = useSelector((state) => ({
        sync: selectExportSync(state),
        loading: selectFetchingExportSync(state),
    }))

    const { EXPORT_SYNC_MODAL } = MODAL_TYPES

    const handleExportSyncModal = () => {
        const payload = {
            modalType: EXPORT_SYNC_MODAL,
        }

        dispatch(showModal(payload))
    }

    const iconClassNames = classNames('mdi', 'mdi-refresh', {
        'mdi-spin': loading,
    })

    return (
        <div className={classNames('export-sync-controls', 'hstack gap-3', className)}>
            <Button title={t('app.common.synchronize')} color="primary" outline className="btn btn-ghost-primary" onClick={handleExportSyncModal}>
                <i className={iconClassNames} />
                {synchronizationLeft}
            </Button>
            <div className="vr my-3" />
            <DateTime className="fs-13" value={lastSynchronization} />
            <div className="vr my-4" />
            <DateTime className="fs-13 fw-light" value={lastSynchronization} timeOnly />
        </div>
    )
}

export default ExportSyncWidget
