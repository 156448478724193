import { createAction } from 'redux-actions'

const ENTITY = 'PHOTOS'
export const stateKey = ENTITY.toLowerCase()

export const uploadPhoto = createAction(`${ENTITY}/UPLOAD`)
export const uploadPhotos = createAction(`${ENTITY}/UPLOAD/BATCH`, (payload) => payload)
export const uploadPhotoRequest = createAction(`${ENTITY}/UPLOAD_REQUEST`)
export const uploadPhotoSuccess = createAction(`${ENTITY}/UPLOAD_SUCCESS`)
export const uploadPhotoFailure = createAction(`${ENTITY}/UPLOAD_FAILURE`)

export const deletePhoto = createAction(`${ENTITY}/DELETE`)
export const deletePhotoRequest = createAction(`${ENTITY}/DELETE_REQUEST`)
export const deletePhotoSuccess = createAction(`${ENTITY}/DELETE_SUCCESS`)
export const deletePhotoFailure = createAction(`${ENTITY}/DELETE_FAILURE`)

export const quePhoto = createAction(`${ENTITY}/QUE`)
export const quePhotoRequest = createAction(`${ENTITY}/QUE_REQUEST`)
export const quePhotoSuccess = createAction(`${ENTITY}/QUE_SUCCESS`)
export const quePhotoFailure = createAction(`${ENTITY}/QUE_FAILURE`)

export const clearQuePhoto = createAction(`${ENTITY}/CLEAR_QUE`)
export const reorderPhotos = createAction(
    `${ENTITY}/REODER`,
    (payload) => payload,
    (_, callback) => callback
)

export const fetchPhotos = createAction(
    `${ENTITY}/FETCH`,
    (payload) => payload,
    (_, callback) => callback
)

export const fetchPhotosRequest = createAction(`${ENTITY}/FETCH_REQUEST`)
export const fetchPhotosSuccess = createAction(`${ENTITY}/FETCH_SUCCESS`)
export const fetchPhotosFailure = createAction(`${ENTITY}/FETCH_FAILURE`)

export const downloadPhoto = createAction(
    `${ENTITY}/DOWNLOAD`,
    (payload) => payload,
    (_, callback) => callback
)

export const downloadPhotoRequest = createAction(`${ENTITY}/DOWNLOAD_REQUEST`)
export const downloadPhotoSuccess = createAction(`${ENTITY}/DOWNLOAD_SUCCESS`)
export const downloadPhotoFailure = createAction(`${ENTITY}/DOWNLOAD_FAILURE`)

export const syncPhoto = createAction(
    `${ENTITY}/SYNC`,
    (payload) => payload,
    (_, callback) => callback
)

export const syncPhotoRequest = createAction(`${ENTITY}/SYNC_REQUEST`)
export const syncPhotoSuccess = createAction(`${ENTITY}/SYNC_SUCCESS`)
export const syncPhotoFailure = createAction(`${ENTITY}/SYNC_FAILURE`)

export const updatePhoto = createAction(
    `${ENTITY}/UPDATE_PHOTO`,
    (payload) => payload,
    (_, callback) => callback
)

export const updatePhotoRequest = createAction(`${ENTITY}/UPDATE_REQUEST`)
export const updatePhotoSuccess = createAction(`${ENTITY}/UPDATE_SUCCESS`)
export const updatePhotoFailure = createAction(`${ENTITY}/UPDATE_FAILURupdate`)

export const proxyPhotoEdit = createAction(
    `${ENTITY}/PROXY_PHOTO_EDIT`,
    (payload) => payload,
    (_, callback) => callback
)

export const proxyPhotoEditRequest = createAction(`${ENTITY}/PROXY_PHOTO_EDIT_REQUEST`)
export const proxyPhotoEditSuccess = createAction(`${ENTITY}/PROXY_PHOTO_EDIT_SUCCESS`)
export const proxyPhotoEditFailure = createAction(`${ENTITY}/PROXY_PHOTO_EDIT_FAILURE`)
export const proxyPhotoEditClear = createAction(`${ENTITY}/PROXY_PHOTO_EDIT_CLEAR`)
export const proxyPhotoUpdateList = createAction(`${ENTITY}/PROXY_PHOTO_UPDATE_LIST`)

export const updatePhotosCount = createAction(`${ENTITY}/UPDATE_PHOTOS_COUNT`)
export const updatePhotoPriority = createAction(`${ENTITY}/UPDATE_PRIORITY`)
export const clearPhotos = createAction(`${ENTITY}/CLEAR_PHOTOS`)

