import AuthReducers from './reducers'
import AuthSagas from './sagas'

export {
    authenticateDestroy,
    authenticateExpired,
    authenticateFailure,
    authenticateInitial,
    authenticateRefresh,
    authenticateRequest,
    authenticateSuccess,
    authenticateWithPassword,
    fetchUser,
    fetchUserFailure,
    fetchUserRequest,
    fetchUserSuccess,
    forgotPasswordSendEmail,
    forgotPasswordSendEmailRequest,
    forgotPasswordSendEmailSuccess,
    forgotPasswordSendEmailFailure,
    resetPassword,
    resetPasswordRequest,
    resetPasswordSuccess,
    resetPasswordFailure,
    resetPasswordClear,
} from './actions'

export const reducer = AuthReducers

export {
    selectAccessToken,
    selectErrorMessage,
    selectIsAuthenticated,
    selectIsFetching,
    selectIsRefreshing,
    selectIsRegistered,
    selectRefreshToken,
    selectResetPasswordSuccess,
} from './selectors'

export default AuthSagas
