import { useEffect, useRef } from 'react'
import styles from './TableShowHideText.module.css'

const TableShowHideText = (props) => {
    const { text, rowId, numberOfRows } = props
    const style = useRef()
    const tooltip = useRef()
    const tooltipParent = useRef()

    useEffect(() => {
        style.current = tooltip.current.offsetHeight > tooltipParent.current.offsetHeight ? `${styles.hideText} ${rowId > numberOfRows / 2 && styles.hideTextSecondHalf}` : ''
    }, [])

    return (
        <div ref={tooltipParent} className={styles.tableCellTooltip}>
            <div ref={tooltip} className={style.current}>
                {text}
            </div>
        </div>
    )
}

export default TableShowHideText
