import React, { useMemo } from 'react'
import TableContainer from '../../Components/Table/TableContainer'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAgentRevenue, selectReports, selectReportsFetching } from '../../store/reports'

export const AgentRevenueReport = ({ searchCriteria }) => {
    const dispatch = useDispatch()

    const { reports, reportsFetching } = useSelector((state) => ({
        reports: selectReports(state),
        reportsFetching: selectReportsFetching(state),
    }))

    const columns = [
        {
            Header: 'Agent',
            id: 'agent',
            accessor: ({ agent }) => `${agent.firstName} ${agent.lastName}`,
            filterable: false,
        },
        {
            Header: 'Zbroj realizacije',
            id: 'total_amount',
            accessor: ({ transaction }) => transaction?.totalAmount,
            filterable: false,
        },
        {
            Header: 'Iznos ostvarene provizije',
            id: 'count',
            accessor: ({ transaction }) => transaction?.count,
            filterable: false,
        },
    ]

    const handleDataFetch = (page, limit, order, criteria) => {
        dispatch(fetchAgentRevenue({ page, limit, order, criteria }))
    }

    const tableFooter = useMemo(() => {
        const data = reports.reduce((result, { transaction }) => {
            if (!result) return transaction

            let { totalAmount, count } = result

            totalAmount += transaction.totalAmount
            count += transaction.count

            return {
                totalAmount,
                count,
            }
        }, null)

        if (!data) return null

        const values = Object.values(data)

        return (
            <tr>
                <td>Ukupno</td>
                {values.map((value, i) => (
                    <td key={i}>{value}</td>
                ))}
            </tr>
        )
    }, [reports])

    return (
        <TableContainer
            columns={columns}
            data={reports || []}
            className="custom-header-css"
            theadClass={'dmn-table-header'}
            isLoading={reportsFetching}
            handleDataFetch={handleDataFetch}
            searchCriteria={searchCriteria}
            tableFooter={tableFooter}
            hidePagination
        />
    )
}
