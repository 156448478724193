import QueryString from 'qs'
import { toast } from 'react-toastify'
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { SUBMIT_RESPONSE_STATUS } from '../../common/constants'
import i18n from '../../i18n'
import { apiRequest } from '../api'
import {
    createProperty,
    createPropertyFailure,
    createPropertyRequest,
    createPropertySuccess,
    deleteProperty,
    deletePropertyGroundPlan,
    deletePropertyGroundPlanFailure,
    deletePropertyGroundPlanRequest,
    deletePropertyGroundPlanSuccess,
    deletePropertyMultiple,
    deletePropertyMultipleFailure,
    deletePropertyMultipleRequest,
    deletePropertyMultipleSuccess,
    deletePropertyRequest,
    deletePropertySuccess,
    fetchOpportunityActivity,
    fetchOpportunityActivityFailure,
    fetchOpportunityActivityRequest,
    fetchOpportunityActivitySuccess,
    fetchOpportunityContact,
    fetchOpportunityContactFailure,
    fetchOpportunityContactRequest,
    fetchOpportunityContactSuccess,
    fetchProperties,
    fetchPropertiesAsyncInput,
    fetchPropertiesAsyncInputContactFailure,
    fetchPropertiesAsyncInputContactRequest,
    fetchPropertiesAsyncInputContactSuccess,
    fetchPropertiesContactProfile,
    fetchPropertiesContactProfileFailure,
    fetchPropertiesContactProfileRequest,
    fetchPropertiesContactProfileSuccess,
    fetchPropertiesFailure,
    fetchPropertiesNoStore,
    fetchPropertiesNoStoreFailure,
    fetchPropertiesNoStoreRequest,
    fetchPropertiesNoStoreSuccess,
    fetchPropertiesRequest,
    fetchPropertiesSuccess,
    fetchProperty,
    fetchPropertyFailure,
    fetchPropertyGroundPlans,
    fetchPropertyGroundPlansFailure,
    fetchPropertyGroundPlansRequest,
    fetchPropertyGroundPlansSuccess,
    fetchPropertyNoStore,
    fetchPropertyNoStoreFailure,
    fetchPropertyNoStoreRequest,
    fetchPropertyNoStoreSuccess,
    fetchPropertyPhoto,
    fetchPropertyPhotoFailure,
    fetchPropertyPhotoRequest,
    fetchPropertyPhotoSuccess,
    fetchPropertyRequest,
    fetchPropertySuccess,
    fetchTemplatesNoStore,
    fetchTemplatesNoStoreFailure,
    fetchTemplatesNoStoreRequest,
    fetchTemplatesNoStoreSuccess,
    quePropertyGroundPlan,
    quePropertyGroundPlanFailure,
    quePropertyGroundPlanRequest,
    quePropertyGroundPlanSuccess,
    quePropertyPhoto,
    quePropertyPhotoFailure,
    quePropertyPhotoRequest,
    quePropertyPhotoSuccess,
    updateProperty,
    updatePropertyFailure,
    updatePropertyRequest,
    updatePropertySuccess,
    uploadPropertyGroundPlan,
    uploadPropertyGroundPlanFailure,
    uploadPropertyGroundPlanRequest,
    uploadPropertyGroundPlanSuccess,
    uploadPropertyPhoto,
    uploadPropertyPhotoFailure,
    uploadPropertyPhotoRequest,
    uploadPropertyPhotoSuccess,
} from './actions'
import { generateLocationData } from '../../utils/general'
import { invalidateQuery } from '../../api/helpers'
import { getSearchCriteriaFromStore } from '../../api/helpers'

export function* fetchPropertiesSaga({ payload, meta }) {
    const params = payload

    yield put(fetchPropertiesRequest())
    try {
        const response = yield apiRequest(`v1/property`, {
            method: 'GET',
            params,
            paramsSerializer: (params) => {
                return QueryString.stringify(params)
            },
        })
        yield put(fetchPropertiesSuccess(response.data))
        meta(response.data.count)
    } catch (error) {
        yield put(fetchPropertiesFailure(error))
    }
}

export function* fetchPropertySaga({ payload, meta }) {
    const { id, order, criteria } = payload

    yield put(fetchPropertyRequest())
    try {
        const response = yield apiRequest(`v1/property/${id}`, {
            method: 'GET',
            params: { order, criteria },
            paramsSerializer: (params) => {
                return QueryString.stringify(params)
            },
        })

        yield put(fetchPropertySuccess(response.data))

        if (meta) {
            meta({
                data: response.data,
                status: SUBMIT_RESPONSE_STATUS.SUCCESS,
            })
        }
    } catch (error) {
        yield put(fetchPropertyFailure(error))
    }
}

export function* fetchOpportunityActivitySaga({ payload, meta }) {
    yield put(fetchOpportunityActivityRequest())
    try {
        const response = yield apiRequest(`v1/activity`, {
            method: 'GET',
            params: payload,
            paramsSerializer: (params) => {
                return QueryString.stringify(params)
            },
        })

        yield put(fetchOpportunityActivitySuccess(response.data))

        if (meta) {
            meta({
                data: response.data,
                status: SUBMIT_RESPONSE_STATUS.SUCCESS,
            })
        }
    } catch (error) {
        yield put(fetchOpportunityActivityFailure(error))
    }
}

export function* fetchOpportunityContactSaga({ payload, meta }) {
    yield put(fetchOpportunityContactRequest())
    try {
        const response = yield apiRequest(`v1/contact/profile`, {
            method: 'GET',
            params: payload,
            paramsSerializer: (params) => {
                return QueryString.stringify(params)
            },
        })

        yield put(fetchOpportunityContactSuccess(response.data))

        if (meta) {
            meta({
                data: response.data,
                status: SUBMIT_RESPONSE_STATUS.SUCCESS,
            })
        }
    } catch (error) {
        yield put(fetchOpportunityContactFailure(error))
    }
}

export function* fetchPropertyPhotoSaga({ payload, meta }) {
    yield put(fetchPropertyPhotoRequest())
    try {
        const response = yield apiRequest(`v1/file/parent/${payload}`, {
            method: 'GET',
            params: {
                noLimit: 1,
                criteria: {
                    collection: 'photo',
                    module: 'property',
                },
            },
            paramsSerializer: (params) => {
                return QueryString.stringify(params)
            },
        })
        // console.log('response', response.data)
        yield put(fetchPropertyPhotoSuccess(response.data))
        // console.log('phtoos success')
        if (meta && meta.callback) {
            meta.callback(response.data.items)
        }
    } catch (error) {
        console.log('photo serror', error)
        yield put(fetchPropertyPhotoFailure(error))
    }
}

export function* createPropertySaga({ payload, meta }) {
    yield put(createPropertyRequest())
    const progressToast = toast.loading(i18n.t('toast.loading'))
    try {
        const response = yield apiRequest('v1/property', {
            method: 'POST',
            data: payload,
        })
        yield put(createPropertySuccess(response))
        if (meta && meta.callback) {
            meta.callback({
                submitStatus: SUBMIT_RESPONSE_STATUS.SUCCESS,
                data: response.data,
            })
        }
        toast.update(progressToast, { render: i18n.t('toast.update.success'), type: 'success', isLoading: false, autoClose: 3000 })
    } catch (error) {
        yield put(createPropertyFailure(error))
        toast.update(progressToast, { render: i18n.t('toast.update.error'), type: 'error', isLoading: false, autoClose: 3000 })
    }
}

export function* updatePropertySaga({ payload, meta }) {
    yield put(updatePropertyRequest())
    const { id, ...data } = payload
    const progressToast = toast.loading(i18n.t('toast.loading'))
    try {
        const response = yield apiRequest(`v1/property/${id}`, {
            method: 'PUT',
            data: data,
        })
        yield put(updatePropertySuccess(response))
        invalidateQuery(['property', id.toString(), getSearchCriteriaFromStore('property')])

        if (meta && meta.callback) {
            meta.callback({
                submitStatus: SUBMIT_RESPONSE_STATUS.SUCCESS,
            })
        }
        toast.update(progressToast, { render: i18n.t('toast.update.success'), type: 'success', isLoading: false, autoClose: 3000 })
    } catch (error) {
        yield put(updatePropertyFailure(error))
        toast.update(progressToast, { render: i18n.t('toast.update.error'), type: 'error', isLoading: false, autoClose: 3000 })
    }
}

export function* deletePropertySaga({ payload, meta }) {
    yield put(deletePropertyRequest())
    const progressToast = toast.loading(i18n.t('toast.loading.delete'))
    try {
        const response = yield apiRequest(`v1/property/${payload}`, {
            method: 'DELETE',
        })
        yield put(deletePropertySuccess(payload))
        toast.update(progressToast, { render: i18n.t('toast.update.delete.success'), type: 'success', isLoading: false, autoClose: 3000 })

        if (meta && meta.redirect) {
            meta.redirect()
        }
    } catch (error) {
        yield put(updatePropertyFailure(error))
        toast.update(progressToast, { render: i18n.t('toast.update.delete.error'), type: 'error', isLoading: false, autoClose: 3000 })
    }
}

function* deletePropertyById(id) {
    try {
        const response = yield apiRequest(`v1/property/${id}`, {
            method: 'DELETE',
        })
        return response
    } catch (error) {
        return error
    }
}

export function* deletePropertyMultipleSaga({ payload }) {
    const requestsToBeDeletedCount = payload.length
    yield put(deletePropertyMultipleRequest())

    if (requestsToBeDeletedCount === 0) {
        yield put(deletePropertyMultipleFailure('Nema nekretnina za brisanje'))
        return
    }

    const progressToast = toast.loading(i18n.t('toast.loading.delete.number', { requestsToBeDeletedCount }))

    const responses = yield all(payload.map((id) => call(deletePropertyById, id)))

    const failedResponses = responses.filter((response) => response instanceof Error)
    const deletedProperties = responses.filter((response) => response.status === 200).map((response) => parseInt(response.data.id))
    const successCount = deletedProperties.length
    const failedCount = failedResponses.length

    if (successCount === 0 && failedCount > 0) {
        yield put(deletePropertyMultipleFailure('Brisanje nije uspjelo'))
        toast.update(progressToast, {
            render: i18n.t('toast.loading.delete'),
            type: 'error',
            isLoading: false,
            autoClose: 3000,
        })
        return
    }

    if (successCount > 0) {
        toast.update(progressToast, {
            render: i18n.t('toast.loading.delete.number.success', { successCount, requestsToBeDeletedCount }),
            type: 'success',
            isLoading: false,
            autoClose: 3000,
        })
    }

    if (failedCount > 0) {
        toast.error(i18n.t('toast.loading.delete.number.error', { failedCount, requestsToBeDeletedCount }), {
            autoClose: 3000,
        })
    }

    yield put(deletePropertyMultipleSuccess(deletedProperties))
}

export function* uploadPropertyPhotoSaga({ payload, meta }) {
    // console.log('meta', meta);
    console.log('uploading', payload)
    const { formData, file } = payload
    yield put(uploadPropertyPhotoRequest(file))
    try {
        const response = yield apiRequest('v1/file/upload/property/photo', {
            method: 'POST',
            data: formData,
        })

        console.log('upload response', response)
        yield put(uploadPropertyPhotoSuccess(file))
        // if (meta && meta.callback) {
        //     meta.callback();
        //   }
        // toast.success("Todo Added Successfully", { autoClose: 3000 });
    } catch (error) {
        yield put(
            uploadPropertyPhotoFailure({
                error,
                file,
            })
        )
        // toast.error("Todo Added Failed", { autoClose: 3000 });
    }
}

export function* quePropertyPhotoSaga({ payload, meta }) {
    yield put(quePropertyPhotoRequest())
    console.log('meta', meta)
    console.log('starting que', payload)
    try {
        yield put(quePropertyPhotoSuccess(payload))
        // if (meta && meta.callback) {
        //     meta.callback();
        //   }
        // toast.success("Todo Added Successfully", { autoClose: 3000 });
    } catch (error) {
        yield put(quePropertyPhotoFailure(error))
        // toast.error("Todo Added Failed", { autoClose: 3000 });
    }
}

export function* fetchPropertiesContactProfileSaga({ payload, meta }) {
    yield put(fetchPropertiesContactProfileRequest())
    try {
        const response = yield apiRequest(`v1/property`, {
            method: 'GET',
            params: payload,
            paramsSerializer: (params) => {
                return QueryString.stringify(params)
            },
        })
        yield put(fetchPropertiesContactProfileSuccess(response.data))
        meta(response.data)
    } catch (error) {
        yield put(fetchPropertiesContactProfileFailure(error))
    }
}

export function* uploadPropertyGroundPlanSaga({ payload, meta }) {
    // console.log('meta', meta);
    console.log('uploading', payload)
    const { formData, file } = payload
    yield put(uploadPropertyGroundPlanRequest(file))
    try {
        const response = yield apiRequest('v1/file/upload/property/ground_plan', {
            method: 'POST',
            data: formData,
        })

        console.log('upload response', response)
        yield put(uploadPropertyGroundPlanSuccess(file))
        // if (meta && meta.callback) {
        //     meta.callback();
        //   }
        // toast.success("Todo Added Successfully", { autoClose: 3000 });
    } catch (error) {
        yield put(
            uploadPropertyGroundPlanFailure({
                error,
                file,
            })
        )
        // toast.error("Todo Added Failed", { autoClose: 3000 });
    }
}

export function* quePropertyGroundPlanSaga({ payload, meta }) {
    yield put(quePropertyGroundPlanRequest())
    console.log('meta', meta)
    console.log('starting que', payload)
    try {
        yield put(quePropertyGroundPlanSuccess(payload))
        // if (meta && meta.callback) {
        //     meta.callback();
        //   }
    } catch (error) {
        yield put(quePropertyGroundPlanFailure(error))
    }
}

export function* deletePropertyGroundPlanSaga({ payload, meta }) {
    yield put(deletePropertyGroundPlanRequest())
    // console.log('detele', payload)
    try {
        const response = yield apiRequest(`v1/file/${payload}`, {
            method: 'DELETE',
        })
        yield put(deletePropertyGroundPlanSuccess(payload))

        if (meta && meta.callback) {
            meta.callback()
        }
        // toast.success("Todo Added Successfully", { autoClose: 3000 });
    } catch (error) {
        yield put(deletePropertyGroundPlanFailure(error))
        // toast.error("Todo Added Failed", { autoClose: 3000 });
    }
}

export function* fetchPropertyGroundPlansSaga({ payload, meta }) {
    yield put(fetchPropertyGroundPlansRequest())
    const { parentId, ...params } = payload
    // console.log('fetch ground plan params', parentId, params)
    // console.log('meta', meta)
    try {
        const response = yield apiRequest(`v1/file/parent/${parentId}`, {
            method: 'GET',
            params: params,
            paramsSerializer: (params) => {
                return QueryString.stringify(params)
            },
        })

        yield put(fetchPropertyGroundPlansSuccess(response.data))
        if (meta) {
            meta(response.data.count)
        }
    } catch (error) {
        yield put(fetchPropertyGroundPlansFailure(error))
    }
}

export function* fetchPropertiesAsyncInputSaga({ payload, meta }) {
    const params = payload.params
    const currentLang = localStorage.getItem('i18nextLng')

    yield put(fetchPropertiesAsyncInputContactRequest())

    try {
        let response = yield apiRequest(`v1/property`, {
            method: 'GET',
            params: params,
            paramsSerializer: (params) => {
                return QueryString.stringify(params)
            },
        })

        yield put(fetchPropertiesAsyncInputContactSuccess())

        let formatedResults
        if (response.data.items) {
            formatedResults = response.data.items?.map((item) => {
                return {
                    value: item?.id,
                    label: `ID: ${item.id} - ${item.translations[currentLang]?.propertyTitle ?? generateLocationData(item)}`,
                    location: generateLocationData(item),
                }
            })
        } else {
            if (response.data.contactType === 'company') {
                formatedResults = [
                    {
                        value: response.data.id,
                        label: response.data.contactCompany?.name,
                        selected: payload?.initialValue ? true : false,
                    },
                ]
            } else {
                formatedResults = [
                    {
                        value: response.data.id,
                        label: response.data.name,
                    },
                ]
            }
        }

        meta(formatedResults)
    } catch (error) {
        console.log('error', error)
        yield put(fetchPropertiesAsyncInputContactFailure(error))
    }
}

export function* fetchPropertiesNoStoreSaga({ payload, meta }) {
    const params = payload

    yield put(fetchPropertiesNoStoreRequest())
    try {
        const response = yield apiRequest(`v1/property`, {
            method: 'GET',
            params,
            paramsSerializer: (params) => {
                return QueryString.stringify(params)
            },
        })

        yield put(fetchPropertiesNoStoreSuccess())
        if (meta && meta.callback) {
            meta.callback({
                submitStatus: SUBMIT_RESPONSE_STATUS.SUCCESS,
                data: response.data,
            })
        }
    } catch (error) {
        yield put(fetchPropertiesNoStoreFailure(error))
        meta.callback({
            submitStatus: SUBMIT_RESPONSE_STATUS.FAILURE,
        })
    }
}

export function* fetchTemplatesNoStoreSaga({ meta }) {
    yield put(fetchTemplatesNoStoreRequest())
    try {
        const response = yield apiRequest(`v1/offer/templates/property`, {
            method: 'GET',
        })
        yield put(fetchTemplatesNoStoreSuccess())
        meta(response.data)
    } catch (error) {
        yield put(fetchTemplatesNoStoreFailure(error))
    }
}

export default function* actionWatcher() {
    yield takeLatest(`${fetchProperties}`, fetchPropertiesSaga)
    yield takeLatest(`${fetchPropertiesContactProfile}`, fetchPropertiesContactProfileSaga)
    yield takeLatest(`${fetchProperty}`, fetchPropertySaga)
    yield takeLatest(`${fetchPropertyPhoto}`, fetchPropertyPhotoSaga)
    yield takeEvery(`${createProperty}`, createPropertySaga)
    yield takeEvery(`${updateProperty}`, updatePropertySaga)
    yield takeEvery(`${deleteProperty}`, deletePropertySaga)
    yield takeEvery(`${deletePropertyMultiple}`, deletePropertyMultipleSaga)
    yield takeEvery(`${uploadPropertyPhoto}`, uploadPropertyPhotoSaga)
    yield takeEvery(`${quePropertyPhoto}`, quePropertyPhotoSaga)
    yield takeEvery(`${uploadPropertyGroundPlan}`, uploadPropertyGroundPlanSaga)
    yield takeEvery(`${quePropertyGroundPlan}`, quePropertyGroundPlanSaga)
    yield takeEvery(`${deletePropertyGroundPlan}`, deletePropertyGroundPlanSaga)
    yield takeLatest(`${fetchPropertyGroundPlans}`, fetchPropertyGroundPlansSaga)
    yield takeLatest(`${fetchPropertiesAsyncInput}`, fetchPropertiesAsyncInputSaga)
    yield takeLatest(`${fetchPropertiesNoStore}`, fetchPropertiesNoStoreSaga)
    yield takeLatest(`${fetchTemplatesNoStore}`, fetchTemplatesNoStoreSaga)
    yield takeLatest(`${fetchOpportunityActivity}`, fetchOpportunityActivitySaga)
    yield takeLatest(`${fetchOpportunityContact}`, fetchOpportunityContactSaga)
}
