import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
import { apiReq } from './api'

const customBackend = {
    type: 'backend',
    read: async (language, namespace, callback) => {
        try {
            // false - no need for access token, public route
            const response = await apiReq(`v1/core/labels/${language}`, {}, false)

            callback(null, response.data)
        } catch (error) {
            callback(error, null)
        }
    },
}

i18n.use(initReactI18next)
    .use(LanguageDetector)
    .use(customBackend)
    .init({
        lng: localStorage.getItem('i18nextLng'),
        fallbackLng: 'hr',
        // TODO: add more supported languages
        supportedLngs: ['hr', 'sl', 'en'],
        detection: {
            order: ['localStorage', 'navigator'],
        },
        // TODO: uncomment debug to see missing translations
        // debug: true,
        interpolation: {
            escapeValue: false,
        },
    })

export default i18n
