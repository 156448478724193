import { createAction } from 'redux-actions';

const ENTITY = 'EXPORTS';
export const stateKey = ENTITY.toLowerCase();

const PORTALS = 'PORTALS';
const WEBSITES = 'WEBSITES';
const SYNC = 'SYNC';

export const fetchExportPortals = createAction(`${ENTITY}/${PORTALS}/FETCH`);
export const fetchExportPortalsRequest = createAction(`${ENTITY}/${PORTALS}/FETCH_REQUEST`);
export const fetchExportPortalsSuccess = createAction(`${ENTITY}/${PORTALS}/FETCH_SUCCESS`);
export const fetchExportPortalsFailure = createAction(`${ENTITY}/${PORTALS}/FETCH_FAILURE`);

export const fetchExportWebsites = createAction(`${ENTITY}/${WEBSITES}/FETCH`);
export const fetchExportWebsitesRequest = createAction(`${ENTITY}/${WEBSITES}/FETCH_REQUEST`);
export const fetchExportWebsitesSuccess = createAction(`${ENTITY}/${WEBSITES}/FETCH_SUCCESS`);
export const fetchExportWebsitesFailure = createAction(`${ENTITY}/${WEBSITES}/FETCH_FAILURE`);

export const fetchExportSync = createAction(`${ENTITY}/${SYNC}/FETCH`);
export const fetchExportSyncRequest = createAction(`${ENTITY}/${SYNC}/FETCH_REQUEST`);
export const fetchExportSyncSuccess = createAction(`${ENTITY}/${SYNC}/FETCH_SUCCESS`);
export const fetchExportSyncFailure = createAction(`${ENTITY}/${SYNC}/FETCH_FAILURE`);
export const triggerExportSync = createAction(`${ENTITY}/${SYNC}/TRIGGER`);