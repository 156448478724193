import { getIn } from 'formik'
import i18n from 'i18next'
import { Fragment, useContext, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import { Card, CardBody, Col, Input, Label, Row } from 'reactstrap'
import { WEBSITE_ATTRIBUTES } from '../../common/constants'
import { propertyExportFields } from '../../common/forms/generalRealEstate/fields'
import { selectExportPortals, selectExportWebsites } from '../../store/export'
import FormMainContainer from '../Common/FormMainContainer'
import FormSideContainer from '../Common/FormSideContainer'
import PropertyFormExportContext from './PropertyFormExportContext'

const PropertyFormExportTab = ({ children, ...props }) => {
    return (
        <PropertyFormExportContext.Provider
            value={{
                tabs: props.tabs,
                activeTab: props.activeTab,
                handleTabChange: props.handleTabChange,
                formik: props.formik,
                subform: props.subform,
            }}
        >
            <Row>{children}</Row>
        </PropertyFormExportContext.Provider>
    )
}

export const PropertyFormExportTabSide = (props) => {
    return <FormSideContainer></FormSideContainer>
}

export const PropertyFormExportTabMain = (props) => {
    const { tabs, activeTab, handleTabChange, formik } = useContext(PropertyFormExportContext)
    const [isCheckAllChecked, setIsCheckedAllChecked] = useState(false)
    const { portals, websites } = useSelector((state) => ({
        portals: selectExportPortals(state),
        websites: selectExportWebsites(state),
    }))
    const portalCustomField = getIn(formik?.values?.export, 'propertyPortalCustomValue')
    const [portalCustomFieldArray, setPortalCustomFieldArray] = useState(portalCustomField)

    const portalValues = getIn(formik?.values?.export, propertyExportFields.PROPERTY_PORTAL_ID)
    const websiteValues = getIn(formik?.values?.export, propertyExportFields.PROPERTY_WEBSITE_ID)

    const checkedAllPortals = () => {
        const checkall = document.getElementById('portalsCheckBoxAll')

        if (checkall.checked) {
            formik.setFieldValue(`export.${propertyExportFields.PROPERTY_PORTAL_ID}`, [...portals.map((x) => x.id)])
            setIsCheckedAllChecked(true)
        } else {
            formik.setFieldValue(`export.${propertyExportFields.PROPERTY_PORTAL_ID}`, [])
            setIsCheckedAllChecked(false)
        }
    }

    const checkIfAllPortalsAreChecked = () => {
        let isChecked = false
        if (portals && portals.length) {
            const portalIds = portals?.map((x) => x.id)
            isChecked = portalIds.every((x) => portalValues.includes(x))
        }
        if (isCheckAllChecked !== isChecked) {
            setIsCheckedAllChecked(isChecked)
        }
    }

    useEffect(() => {
        if (portalValues) {
            checkIfAllPortalsAreChecked()
        }
    }, [portalValues])

    useEffect(() => {
        const uniquePortalCustomField = [...new Map(portalCustomFieldArray?.map((item) => [item['portal'], item])).values()]
        formik.setFieldValue('export.propertyPortalCustomValue', uniquePortalCustomField)
    }, [portalCustomFieldArray])

    const addCustomField = (customField, portal) => {
        setPortalCustomFieldArray((prev) => (prev ? [...prev, { customField, portal }] : [{ customField, portal }]))
    }

    return (
        <FormMainContainer ainContainer tabs={tabs} activeTab={activeTab} handleTabChange={handleTabChange}>
            <Row>
                <Col sm={12} md={6}>
                    <p>{i18n.t('form.property.tab.export.portals')}</p>
                    <div className="form-check mb-3">
                        <Input
                            type="checkbox"
                            className="form-check-input"
                            name="portals-check-all"
                            id="portalsCheckBoxAll"
                            onChange={checkedAllPortals}
                            checked={isCheckAllChecked}
                        />
                        <Label className="form-check-label" for="portalsCheckBoxAll">
                            {i18n.t('form.property.tab.export.checkboxAll')}
                        </Label>
                    </div>
                    {portals && portals.length
                        ? portals.map((portal) => {
                              const optionsCustomField = JSON.parse(portal.portalCustomField)
                              const formikSelected = formik?.values?.export?.propertyPortalCustomValue?.filter((item) => item.portal === portal.id)[0]

                              return (
                                  <div key={portal.id} className="form-check mb-2 d-flex w-100">
                                      <div className="d-flex align-items-center my-2 w-25">
                                          <Input
                                              type="checkbox"
                                              className="form-check-input portalCheckBox"
                                              id={`portal-checkbox-${portal.id}`}
                                              name={`portal-checkbox-${portal.id}`}
                                              value={portal.id.toString()}
                                              checked={portalValues && portalValues.length ? portalValues?.includes(portal.id) : false}
                                              onChange={(e) => {
                                                  let newvalues
                                                  if (e.target.checked) {
                                                      newvalues = [...portalValues, portal.id]
                                                  } else {
                                                      newvalues = [...portalValues.filter((x) => x !== portal.id)]
                                                  }
                                                  formik.setFieldValue(`export.${propertyExportFields.PROPERTY_PORTAL_ID}`, [...new Set(newvalues)])
                                              }}
                                          />
                                          <Label className="form-check-label ms-2" for={`portal-checkbox-${portal.id}`}>
                                              {portal.title} {portal.count ? `(${portal.count})` : ''}
                                          </Label>
                                      </div>
                                      <div className="d-flex ms-3 w-75">
                                          {portal.portalCustomField && (
                                              <Select
                                                  key={portal.id}
                                                  onChange={(e) => {
                                                      addCustomField(e.value, portal.id)
                                                  }}
                                                  isDisabled={!portalValues?.includes(portal.id)}
                                                  options={optionsCustomField}
                                                  placeholder={i18n.t('form.property.tab.export.select.label')}
                                                  value={optionsCustomField.filter((item) => item.value === formikSelected?.customField)}
                                              />
                                          )}
                                      </div>
                                  </div>
                              )
                          })
                        : null}
                </Col>
                <Col sm={12} md={6}>
                    <p>{i18n.t('app.common.web')}</p>
                    <Row>
                        <Col>
                            {websites && websites.length
                                ? websites.map(({ id: website_id, title, supportsIncognito, ...attributes }, i) => {
                                      const CLONE_WEBSITE_ATTRIBUTES = [...WEBSITE_ATTRIBUTES]
                                      supportsIncognito === 0 && CLONE_WEBSITE_ATTRIBUTES.splice(1, 1)

                                      return (
                                          <Fragment key={website_id}>
                                              <p>{title}</p>
                                              {CLONE_WEBSITE_ATTRIBUTES.map(({ attribute, name }) => {
                                                  const elementId = `website-checkbox-${attribute}`

                                                  const websiteValue = websiteValues?.[i]

                                                  return (
                                                      <div key={attribute} className="form-check mb-2">
                                                          <Input
                                                              type="checkbox"
                                                              className="form-check-input"
                                                              id={elementId}
                                                              name={attribute}
                                                              value={attributes[attribute]}
                                                              checked={Boolean(websiteValue?.[attribute])}
                                                              onChange={(e) => {
                                                                  const newValue = {
                                                                      website_id,
                                                                      ...websiteValue,
                                                                      [attribute]: e.target.checked ? 1 : 0,
                                                                  }
                                                                  formik.setFieldValue(
                                                                      `export.${propertyExportFields.PROPERTY_WEBSITE_ID}[${i}]`,
                                                                      newValue
                                                                  )
                                                              }}
                                                          />
                                                          <Label className="form-check-label" for={elementId}>
                                                              {i18n.t(name)}
                                                          </Label>
                                                      </div>
                                                  )
                                              })}
                                          </Fragment>
                                      )
                                  })
                                : null}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </FormMainContainer>
    )
}

export default PropertyFormExportTab
