import { handleActions } from "redux-actions";
import { fetchReportsFailure, fetchReportsRequest, fetchReportsSuccess } from "./actions";


export const initialState = {
    items: {},
    fetching: false,
    error: null
}

export default handleActions(
    {
        [fetchReportsRequest]: state => ({
            ...state,
            fetching: true,
            error: null,
        }),
        [fetchReportsSuccess]: (state, { payload }) => ({
            ...state,
            fetching: false,
            items: payload
        }),
        [fetchReportsFailure]: (state, { payload }) => ({
            ...state,
            fetching: false,
            error: payload,
        }),
    },
    initialState,
);