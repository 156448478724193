import { createAction } from 'redux-actions';

const ENTITY = 'CONTACTNOTES';
export const stateKey = ENTITY.toLowerCase();

export const fetchContactNotes = createAction(`${ENTITY}/FETCH`);
export const fetchContactNotesRequest = createAction(`${ENTITY}/FETCH_REQUEST`);
export const fetchContactNotesSuccess = createAction(`${ENTITY}/FETCH_SUCCESS`);
export const fetchContactNotesFailure = createAction(`${ENTITY}/FETCH_FAILURE`);

export const fetchContact = createAction(`${ENTITY}/DETAILS/FETCH`);
export const fetchContactNoteRequest = createAction(`${ENTITY}/DETAILS/FETCH_REQUEST`);
export const fetchContactNoteSuccess = createAction(`${ENTITY}/DETAILS/FETCH_SUCCESS`);
export const fetchContactNoteFailure = createAction(`${ENTITY}/DETAILS/FETCH_FAILURE`);

export const createContactNote = createAction(
    `${ENTITY}/CREATE`,
    values => values,
    (values, callback) => ({ callback })
);
// export const createContact = createAction(`${ENTITY}/CREATE`);
export const createContactNoteRequest = createAction(`${ENTITY}/CREATE_REQUEST`);
export const createContactNoteSuccess = createAction(`${ENTITY}/CREATE_SUCCESS`);
export const createContactNoteFailure = createAction(`${ENTITY}/CREATE_FAILURE`);

export const updateContactNote = createAction(
    `${ENTITY}/UPDATE`,
    values => values,
    (values, callback) => ({ callback })
);
// export const createContact = createAction(`${ENTITY}/CREATE`);
export const updateContactNoteRequest = createAction(`${ENTITY}/UPDATE_REQUEST`);
export const updateContactNoteSuccess = createAction(`${ENTITY}/UPDATE_SUCCESS`);
export const updateContactNoteFailure = createAction(`${ENTITY}/UPDATE_FAILURE`);

export const deleteContactNote = createAction(`${ENTITY}/DELETE`);
  export const deleteContactNoteRequest = createAction(`${ENTITY}/DELTE_REQUEST`);
  export const deleteContactNoteSuccess = createAction(`${ENTITY}/DELTE_SUCCESS`);
  export const deleteContactNoteFailure = createAction(`${ENTITY}/DELETE_FAILURE`);