import i18n from 'i18next'
import { useEffect, useMemo, useState } from 'react'
import Select from 'react-select'
import { Col, Label, Row, UncontrolledTooltip } from 'reactstrap'
import {
    createSelectOptionData,
    handleFilterConfig,
    handleFilterValue,
    handleIsEqual,
    handleMultipleFiltersChange,
    handleMultipleFiltersDisable,
    handleSectionFilters,
    handleShouldDisable,
} from '../../helpers'
import { formTitles } from './constants'

const AgentPermissions = ({ formik }) => {
    const formikAgentPermissions = formik?.values.agentPermissions
    const [filterValue, setFilterValue] = useState(null)
    const [optionId, setOptionId] = useState(null)

    const sortedAgentPermissions = useMemo(() => {
        const formGroups = {}

        formTitles.forEach((obj) => {
            formGroups[obj.permissionGroup] = []
        })

        formikAgentPermissions?.forEach((item) => {
            formGroups[item.permission.permissionGroup].push(item)
        })

        return formTitles.flatMap((obj) => {
            const translatedObj = { ...obj, title: i18n.t(obj.title) }
            return [translatedObj, ...formGroups[obj.permissionGroup]]
        })
    }, [formikAgentPermissions])

    const handleInputChange = (event, permissionId) => {
        handleFilterValue(permissionId, setFilterValue)
        setOptionId(event.id)

        const updatedAgentPermissions = formikAgentPermissions.map((item) =>
            item.permission.id === permissionId ? { ...item, permissionLevel: event } : item
        )

        formik.setFieldValue('agentPermissions', updatedAgentPermissions)
    }

    const handleTooltip = (tooltipName, tooltipId, tooltipContent) => {
        return (
            <>
                <span id={`${tooltipName}-${tooltipId}`}>
                    <i className="mdi mdi-help-circle-outline"></i>{' '}
                </span>
                <UncontrolledTooltip placement="right" target={`${tooltipName}-${tooltipId}`}>
                    {tooltipContent}
                </UncontrolledTooltip>
            </>
        )
    }

    useEffect(() => {
        if (!filterValue) return

        const filters = handleSectionFilters(filterValue)
        const selectedConfig = handleFilterConfig(filterValue, optionId)

        if (!selectedConfig) return

        const updatedAgentPermissions = handleMultipleFiltersChange(
            formikAgentPermissions,
            filters,
            selectedConfig.defaultValue,
            selectedConfig.targetValue
        )

        handleIsEqual(formikAgentPermissions, updatedAgentPermissions, formik)
        setFilterValue(null)
    }, [filterValue, optionId, formikAgentPermissions, formik])

    useEffect(() => {
        const updatedAgentPermissions = handleShouldDisable(formikAgentPermissions)
        handleIsEqual(formikAgentPermissions, updatedAgentPermissions, formik)
    }, [formikAgentPermissions, formik])

    return (
        <Row>
            {sortedAgentPermissions?.length > 0 &&
                sortedAgentPermissions.map((item, index) => {
                    const { permission, permissionLevel } = item ?? {}

                    const selectOptions = createSelectOptionData(permission?.agentPermissionsLevels, 'name', 'id')
                    const defaultSelectOptions = selectOptions?.filter((option) => option.shouldFilter === undefined)

                    const savedPermission = { label: permissionLevel?.name, value: permissionLevel?.id }
                    const isSavedPermission = Object.values(savedPermission).every((permission) => permission !== undefined)
                    const defaultValue = isSavedPermission && savedPermission

                    return item.title ? (
                        <Col lg={12} key={index}>
                            <div className="d-flex h-100 align-items-center">
                                <h6 className={index === 0 ? 'py-2 text-uppercase' : 'py-2 mt-3 text-uppercase'}>{item.title}</h6>
                            </div>
                        </Col>
                    ) : (
                        <Col lg={4} key={index}>
                            <div className="mb-3">
                                <div className="d-flex">
                                    <Label htmlFor="agentPermissions" className="form-label me-2">
                                        {permission?.name}
                                    </Label>
                                    {permission.description && handleTooltip('label', permission.id, permission.description)}
                                </div>

                                <Select
                                    id={permission.name}
                                    onChange={(e) => handleInputChange(e, permission.id)}
                                    options={defaultSelectOptions}
                                    placeholder={i18n.t('admin.selectPermission')}
                                    isDisabled={handleMultipleFiltersDisable(permission)}
                                    value={defaultValue}
                                />
                            </div>
                        </Col>
                    )
                })}
        </Row>
    )
}

export default AgentPermissions
