import { setPermissions } from './utils'

export const setAllowedPhotos = ({ agentPermissions, photos, groundPlans, photo, isAgentOwnership, isAgentOffice, module }) => {
    const permission = module === 'properties' ? 'view.property.photos' : 'view.project.photos'
    const hasPermission = setPermissions(agentPermissions, permission, isAgentOwnership, isAgentOffice)

    const allowedPhotos = photos?.filter((photo) => photo.fileSync)
    const allowedGroundPlans = groundPlans?.filter((photo) => photo.fileSync)

    photos = photos && !hasPermission? allowedPhotos : photos // SliderOptions (ProjectView & PropertyView)
    groundPlans = groundPlans && !hasPermission? allowedGroundPlans : groundPlans // SliderOptions (ProjectView & PropertyView)
    photo = photo && !photo.fileSync && !hasPermission? null : photo // PhotoCard

    return { photos, groundPlans, photo }
}
