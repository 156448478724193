import { createAction } from 'redux-actions'

const ENTITY = 'SEARCH'
export const stateKey = ENTITY.toLowerCase()

export const fetchQuickSearch = createAction(
    `${ENTITY}/QUICK/FETCH`,
    (payload) => payload,
    (payload, callback) => callback
)
export const fetchQuickSearchRequest = createAction(`${ENTITY}/QUICK/FETCH_REQUEST`)
export const fetchQuickSearchSuccess = createAction(`${ENTITY}/QUICK/FETCH_SUCCESS`)
export const fetchQuickSearchFailure = createAction(`${ENTITY}/QUICK/FETCH_FAILURE`)
export const fetchQuickSearchClear = createAction(`${ENTITY}/QUICK/CLEAR`)

export const setDetailSearchProjectsCriteria = createAction(
    `${ENTITY}/DETAIL_PROJECTS/SET`,
    (payload) => payload,
    (payload, callback) => callback
)
export const clearDetailSearchProjectsCriteria = createAction(`${ENTITY}/DETAIL_PROJECTS/CLEAR`)

export const setDetailSearchPropertiesCriteria = createAction(
    `${ENTITY}/DETAIL_PROPERTIES/SET`,
    (payload) => payload,
    (payload, callback) => callback
)
export const clearDetailSearchPropertiesCriteria = createAction(`${ENTITY}/DETAIL_PROPERTIES/CLEAR`)

export const setDetailSearchConctactsCriteria = createAction(
    `${ENTITY}/DETAIL_CONTACTS/SET`,
    (payload) => payload,
    (payload, callback) => callback
)
export const clearDetailSearchContactsCriteria = createAction(`${ENTITY}/DETAIL_CONTACTS/CLEAR`)

export const setDetailSearchCompaniesCriteria = createAction(
    `${ENTITY}/DETAIL_COMPANIES/SET`,
    (payload) => payload,
    (payload, callback) => callback
)
export const clearDetailSearchCompaniesCriteria = createAction(`${ENTITY}/DETAIL_COMPANIES/CLEAR`)

export const setDetailSearchDiaryCriteria = createAction(
    `${ENTITY}/DETAIL_DIARY/SET`,
    (payload) => payload,
    (payload, callback) => callback
)
export const clearDetailSearchDiaryCriteria = createAction(`${ENTITY}/DETAIL_DIARY/CLEAR`)
