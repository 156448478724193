import values from 'lodash/values';

import { stateKey } from './actions';

export const selectCityById = cityId => state => state[stateKey].city[cityId];

export const selectExportPortals = state => {
    return state[stateKey].portals;
}

export const selectExportWebsites = state => {
    return state[stateKey].websites;
}

export const selectExportPortalsFormOptions = state => {
    return values(state[stateKey].portals)?.map((service) => (
        {
            value: service.id.toString(),
            label: service.title
        }
    ));
}

export const selectExportWebsitesFormOptions = state => {
    return values(state[stateKey].websites)?.map((service) => (
        {
            value: service.id.toString(),
            label: service.title
        }
    ));
}

export const selectExportSync = state => {
    return state[stateKey].sync;
}

export const selectFetchingExportSync = state => {
    return state[stateKey].fetchingSync;
}