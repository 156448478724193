import { FormikProvider, useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { Container, Form, TabContent, TabPane } from 'reactstrap'
import * as Yup from 'yup'
import FormActionButtons from '../../Components/Common/FormActionButtons'
import Loader from '../../Components/Common/Loader'
import PageTitle from '../../Components/Common/PageTitle'
import DocumentFormTab from '../../Components/DocumentFormTab'
import GroundPlansFormTab from '../../Components/GroundPlanFormTab'
import {
    useMapFormConfig,
    useProjectDescriptionFormConfig,
    useProjectGeneralFormConfig,
    useProjectGeneralFormSelectOptions,
    useRealEstateMaterials3DVideoFormConfig,
} from '../../Components/Hooks/FormHooks'
import { useProjectFormTabs } from '../../Components/Hooks/useProjectTabs'
import MapFormTab, { MapFormTabMain, MapFormTabSide } from '../../Components/MapFormTab/MapFormTab'
import FormValidationErrorModal from '../../Components/Modals/FormValidationErrorModal'
import PhotosFormTab from '../../Components/PhotosFormTab'
import ProjectFormDescriptionTab, {
    ProjectFormDescriptionTabMain,
    ProjectFormDescriptionTabSide,
} from '../../Components/ProjectFormDescriptionTab/ProjectFormDescriptionTab'
import ProjectFormGeneralTab, {
    ProjectFormGeneralTabMain,
    ProjectFormGeneralTabSide,
} from '../../Components/ProjectFormGeneralTab/ProjectFormGeneralTab'
import PropertyFormMaterialsTab from '../../Components/PropertyFormMaterialsTab/PropertyFormMaterialsTab'
import PropertyFormMaterialsVirtualWalkVideoTab, {
    PropertyFormMaterialsVirtualWalkVideoTabMain,
    PropertyFormMaterialsVirtualWalkVideoTabSide,
} from '../../Components/PropertyFormMaterialsTab/Tabs/PropertyFormMaterialsVirtualWalkVideoTab/PropertyFormMaterialsVirtualWalkVideoTab'
import { selectGroundPlans, updateGroundPlan } from '../../store/groundplans'
import { selectPhotos, updatePhoto } from '../../store/photos'
import { fetchProject, selectProject, selectProjectFetchingDetails, updateProject } from '../../store/projects'
import { changeTranslationObject } from '../../utils/general'

const ProjectEdit = () => {
    const history = useHistory()
    const location = useLocation()
    const dispatch = useDispatch()
    const { id } = useParams()
    const { t } = useTranslation()

    const [activeTab, setActiveTab] = useState(null)
    const [activeMaterialsTab, setActiveMaterialsTab] = useState(null)

    const { project, fetchingDetails, photos, groundPlans } = useSelector((state) => ({
        project: selectProject(state),
        fetchingDetails: selectProjectFetchingDetails(state),
        photos: selectPhotos(state),
        groundPlans: selectGroundPlans(state),
    }))

    const tabs = useProjectFormTabs(true)
    const { generalInputs, generalValidationConfig } = useProjectGeneralFormConfig(project)
    const { descriptionInputs, descriptionValidationConfig } = useProjectDescriptionFormConfig(project)
    const { mapInputs, mapValidationConfig } = useMapFormConfig(project)
    const { materials3DVideoInputs, materials3DVideoValidationConfig } = useRealEstateMaterials3DVideoFormConfig(project)
    const formSelectOptions = useProjectGeneralFormSelectOptions()

    const moduleName = 'project'

    useEffect(() => {
        if (tabs.length > 0) {
            if (location?.state?.activeTab) {
                setActiveTab(location?.state?.activeTab)
            } else {
                setActiveTab(tabs[0].name)
            }
        }
    }, [tabs, location])

    useEffect(() => {
        if (activeTab === 'materials') {
            setActiveMaterialsTab(tabs.find((x) => x.name === 'materials')?.children?.[0].name)
        }
    }, [activeTab, tabs])

    useEffect(() => {
        dispatch(fetchProject({ id }))
    }, [dispatch, id])

    const handleTabChange = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab)
        }
    }

    const updateMaterials = (materials, updateAction, name) => {
        for (const item of materials) {
            const payload = {
                id: item.id,
                priority: item.priority,
                translations: validation.values?.[name]?.[item.id].translations,
                entityId: project.id,
                moduleName,
            }
            dispatch(updateAction(payload))
        }
    }

    const { isValid, isSubmitting, isValidating, ...validation } = useFormik({
        enableReinitialize: true,
        initialValues: {
            general: generalValidationConfig.initialValues ?? {},
            description: descriptionValidationConfig.initialValues ?? {},
            map: mapValidationConfig.initialValues ?? {},
            virtualWalkVideo: materials3DVideoValidationConfig.initialValues ?? {},
            photos: {},
            groundplans: {},
        },
        validationSchema: Yup.object({
            general: generalValidationConfig.validationSchema ?? Yup.object({}),
            description: descriptionValidationConfig.validationSchema ?? Yup.object({}),
            map: mapValidationConfig.validationSchema ?? Yup.object({}),
            virtualWalkVideo: materials3DVideoValidationConfig.validationSchema ?? Yup.object({}),
        }),
        onSubmit: (values) => {
            const generalValues = generalValidationConfig.prepareModelForSubmit(values.general)

            const translations = changeTranslationObject(values.description, 'project_title', 'project_description', 'project_offer_description')

            const submitValues = {
                ...generalValues,
                ...values.map,
                ...values.virtualWalkVideo,
                translations,
            }

            dispatch(updateProject(submitValues))

            updateMaterials(photos, updatePhoto, 'photos')
            updateMaterials(groundPlans, updateGroundPlan, 'groundplans')
        },
    })

    useEffect(() => {
        if (!isValid && !isValidating && isSubmitting) {
            setIsErrorModalActive(true)
        }
    }, [isValid, isValidating, isSubmitting])

    const renderFormButtons = () => <FormActionButtons disabled={false} hasCancel={true} cancelAction={() => history.push(`/projects/${id}`)} />

    const [isErrorModalActive, setIsErrorModalActive] = useState(false)

    const toggleErrorModal = () => {
        setIsErrorModalActive(!isErrorModalActive)
    }

    const handleMaterialsTabChange = (tab) => {
        if (activeMaterialsTab !== tab) {
            setActiveMaterialsTab(tab)
        }
    }

    if (fetchingDetails) {
        return (
            <div className="page-content">
                <Container fluid>
                    <Loader />
                </Container>
            </div>
        )
    }

    return (
        <div className="page-content">
            <Container fluid>
                <FormikProvider value={validation}>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault()
                            validation.handleSubmit()
                            return false
                        }}
                        className="needs-validation"
                        id="real_estate_form"
                    >
                        <PageTitle
                            title={`${t('page.editProject')} ${
                                project?.projectTitle
                                    ? project.projectTitle
                                    : project.projectTitleTranslations?.hr
                                    ? project.projectTitleTranslations.hr
                                    : ''
                            }`}
                        >
                            {renderFormButtons()}
                        </PageTitle>

                        <TabContent activeTab={activeTab} className="text-muted">
                            <TabPane tabId="general">
                                <ProjectFormGeneralTab
                                    tabs={tabs}
                                    activeTab={activeTab}
                                    handleTabChange={handleTabChange}
                                    inputs={generalInputs}
                                    subform="general"
                                    formik={validation}
                                    selectOptions={formSelectOptions}
                                    module={location}
                                >
                                    <ProjectFormGeneralTabSide />
                                    <ProjectFormGeneralTabMain />
                                </ProjectFormGeneralTab>
                            </TabPane>
                            <TabPane tabId="materials">
                                <PropertyFormMaterialsTab
                                    tabs={tabs}
                                    activeTab={activeTab}
                                    handleTabChange={handleTabChange}
                                    activeMaterialsTab={activeMaterialsTab}
                                    materialsTabs={tabs.length > 0 ? tabs.find((x) => x.name === 'materials')?.children : []}
                                    handleMaterialsTabChange={handleMaterialsTabChange}
                                    propertyId={id}
                                >
                                    <TabContent activeTab={activeMaterialsTab} className="text-muted">
                                        <TabPane tabId="photos">
                                            <PhotosFormTab
                                                tabs={tabs}
                                                activeTab={activeTab}
                                                handleTabChange={handleTabChange}
                                                activeChildTab={activeMaterialsTab}
                                                childTabs={tabs.length > 0 ? tabs.find((x) => x.name === 'materials')?.children : []}
                                                handleChildTabChange={handleMaterialsTabChange}
                                                moduleName={moduleName}
                                                parentId={id}
                                                validation={validation}
                                            />
                                        </TabPane>
                                        <TabPane tabId="groundPlans">
                                            <GroundPlansFormTab
                                                tabs={tabs}
                                                activeTab={activeTab}
                                                handleTabChange={handleTabChange}
                                                activeChildTab={activeMaterialsTab}
                                                childTabs={tabs.length > 0 ? tabs.find((x) => x.name === 'materials')?.children : []}
                                                handleChildTabChange={handleMaterialsTabChange}
                                                moduleName={moduleName}
                                                parentId={id}
                                                validation={validation}
                                            />
                                        </TabPane>
                                        <TabPane tabId="3dVideo">
                                            <PropertyFormMaterialsVirtualWalkVideoTab
                                                inputs={materials3DVideoInputs}
                                                subform="virtualWalkVideo"
                                                formik={validation}
                                            >
                                                <PropertyFormMaterialsVirtualWalkVideoTabSide />
                                                <PropertyFormMaterialsVirtualWalkVideoTabMain />
                                            </PropertyFormMaterialsVirtualWalkVideoTab>
                                        </TabPane>
                                    </TabContent>
                                </PropertyFormMaterialsTab>
                            </TabPane>
                            <TabPane tabId="map">
                                <MapFormTab
                                    tabs={tabs}
                                    activeTab={activeTab}
                                    handleTabChange={handleTabChange}
                                    inputs={mapInputs}
                                    formik={validation}
                                >
                                    <MapFormTabSide />
                                    <MapFormTabMain />
                                </MapFormTab>
                            </TabPane>
                            <TabPane tabId="description">
                                <ProjectFormDescriptionTab
                                    tabs={tabs}
                                    activeTab={activeTab}
                                    handleTabChange={handleTabChange}
                                    inputs={descriptionInputs}
                                    subform="description"
                                    formik={validation}
                                >
                                    <ProjectFormDescriptionTabSide />
                                    <ProjectFormDescriptionTabMain />
                                </ProjectFormDescriptionTab>
                            </TabPane>
                        </TabContent>
                    </Form>

                    {/* documents are saved via dropzone */}
                    {activeTab === 'documents' && (
                        <TabPane tabId="documents">
                            <DocumentFormTab
                                tabs={tabs}
                                activeTab={activeTab}
                                handleTabChange={handleTabChange}
                                documentModule={moduleName}
                                parentId={id}
                            />
                        </TabPane>
                    )}
                </FormikProvider>
            </Container>
            {isErrorModalActive && (
                <FormValidationErrorModal
                    validation={validation}
                    inputs={[generalInputs, descriptionInputs, mapInputs, materials3DVideoInputs]}
                    onClose={() => toggleErrorModal(false)}
                />
            )}
        </div>
    )
}

export default ProjectEdit
