import { MODAL_TYPES } from '../../../../common/constants'
import { getFilteredProperties } from '../../utils'

export const getModalPayloads = (modalType, properties, selectedRowsIds) => {
    const { OFFER_PRINT_MODAL, TEMPLATES_MODAL, CHANGE_AGENT_MODAL } = MODAL_TYPES

    const getSelectedProperties = () => {
        return properties.filter((property) => selectedRowsIds.includes(property.id))
    }

    switch (modalType) {
        case OFFER_PRINT_MODAL:
            return {
                modalType: OFFER_PRINT_MODAL,
                modalProps: {
                    [OFFER_PRINT_MODAL]: {
                        properties: getSelectedProperties(),
                    },
                },
            }
        case TEMPLATES_MODAL: {
            const selectedProperties = getSelectedProperties()

            return {
                modalType: TEMPLATES_MODAL,
                modalProps: {
                    [TEMPLATES_MODAL]: {
                        selectedProperties: getFilteredProperties(selectedProperties),
                    },
                },
            }
        }
        case CHANGE_AGENT_MODAL:
            return {
                modalType: CHANGE_AGENT_MODAL,
            }
        default:
            throw new Error('Unknown modal type')
    }
}
