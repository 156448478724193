import axios from 'axios'
import { API_URL } from '../common/constants'
import { apiRequest } from '../store/api'
import { dispatchAuthenticateWithRefreshToken } from './helpers'

export function updateFile(id, data) {
    return apiRequest(`v1/file/${id}`, {
        method: 'PUT',
        data,
    })
}

// reusable function for requests
// add parameter: false to requests that do not need Authorization
// TODO: see how to handle tokens...
export const apiReq = async (url, options = {}, isAuthRequired = true) => {
    const accessToken = localStorage.getItem('access_token')
    const refreshToken = localStorage.getItem('refresh_token')

    const authorizationHeader = isAuthRequired && accessToken ? { Authorization: `Bearer ${accessToken}` } : {}

    const headers = {
        locale: localStorage.getItem('i18nextLng'),
        ...authorizationHeader,
    }

    if (isAuthRequired && !accessToken) {
        throw new Error('No access token available')
    }

    try {
        const result = await axios({
            url: `${API_URL}${url}`,
            headers,
            ...options,
        })
        return result
    } catch (error) {
        if (error.response.status === 401) {
            dispatchAuthenticateWithRefreshToken(refreshToken)
        }

        return error.response
    }
}

export const getData = async (url) => {
    const accessToken = localStorage.getItem('access_token')

    return await axios
        .get(`${API_URL}${url}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        })
        .then((res) => {
            return res.data
        })
}

export const fetchApplicationSettings = async () => {
    const response = await apiReq('v1/core/application-settings', {
        method: 'get',
    })
    return response.data
}

export const fetchAgencyData = async () => {
    const response = await apiReq('v1/agency', {
        method: 'get',
    })
    return response.data
}

export const fetchNewPropertyId = async (id, data) => {
    const response = await apiReq(`v1/property/duplicate/${id}`, {
        method: 'post',
        data: data,
    })
    return response.data
}
