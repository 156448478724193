import { createAction } from 'redux-actions';

const ENTITY = 'REPORTS';
export const stateKey = ENTITY.toLowerCase();

export const fetchAgentActivities = createAction(
    `${ENTITY}/ACTIVITY/FETCH`,
    payload => payload,
    (payload, callback) => callback
);

export const fetchAgentRevenue = createAction(
    `${ENTITY}/REVENUE/FETCH`,
    payload => payload,
    (payload, callback) => callback
);

export const fetchAgentInquiries = createAction(
    `${ENTITY}/INQUIRIES/FETCH`,
    payload => payload,
    (payload, callback) => callback
);

export const fetchReportsRequest = createAction(`${ENTITY}/FETCH_REQUEST`);
export const fetchReportsSuccess = createAction(`${ENTITY}/FETCH_SUCCESS`);
export const fetchReportsFailure = createAction(`${ENTITY}/FETCH_FAILURE`);