import styles from './ImageContainer.module.css'
import i18n from 'i18next'

const UserImage = ({ imageUrl, firstName = '', lastName = '', roundContainer = false }) => {
    return (
        <div className="avatar-lg rounded-circle flex-shrink-0">
            {imageUrl !== null && imageUrl !== '' ? (
                <img src={imageUrl} alt="" className={`${styles.imageFitCover} ${roundContainer && 'rounded-circle'}`} />
            ) : (
                <div
                    className={`avatar-title text-uppercase border ${roundContainer && 'rounded-circle'} bg-light text-primary ${styles.avatarTitle}`}
                >
                    {firstName ? firstName?.charAt(0).toUpperCase() + lastName?.charAt(0).toUpperCase() : `${i18n.t('button.edit')}`}
                </div>
            )}
        </div>
    )
}

export default UserImage
