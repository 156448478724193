import { createAction } from 'redux-actions';

const ENTITY = 'SETTINGS';
export const stateKey = ENTITY.toLowerCase();

export const fetchSettings = createAction(`${ENTITY}/FETCH`);
export const fetchSettingsRequest = createAction(`${ENTITY}/FETCH_REQUEST`);
export const fetchSettingsSuccess = createAction(`${ENTITY}/FETCH_SUCCESS`);
export const fetchSettingsFailure = createAction(`${ENTITY}/FETCH_FAILURE`);


export const fetchSettingsPropertyFlatSubtypes = createAction(`${ENTITY}/PROPERTY_FLAT_SUBTYPE/FETCH`);
export const fetchSettingsPropertyFlatSubtypesRequest = createAction(`${ENTITY}/PROPERTY_FLAT_SUBTYPE/FETCH_REQUEST`);
export const fetchSettingsPropertyFlatSubtypesSuccess = createAction(`${ENTITY}/PROPERTY_FLAT_SUBTYPE/FETCH_SUCCESS`);
export const fetchSettingsPropertyFlatSubtypesFailure = createAction(`${ENTITY}/PROPERTY_FLAT_SUBTYPE/FETCH_FAILURE`);

export const fetchSettingsPropertyHouseSubtypes = createAction(`${ENTITY}/PROPERTY_HOUSE_SUBTYPE/FETCH`);
export const fetchSettingsPropertyHouseSubtypesRequest = createAction(`${ENTITY}/PROPERTY_HOUSE_SUBTYPE/FETCH_REQUEST`);
export const fetchSettingsPropertyHouseSubtypesSuccess = createAction(`${ENTITY}/PROPERTY_HOUSE_SUBTYPE/FETCH_SUCCESS`);
export const fetchSettingsPropertyHouseSubtypesFailure = createAction(`${ENTITY}/PROPERTY_HOUSE_SUBTYPE/FETCH_FAILURE`);

export const fetchSettingsPropertyLandSubtypes = createAction(`${ENTITY}/PROPERTY_LAND_SUBTYPE/FETCH`);
export const fetchSettingsPropertyLandSubtypesRequest = createAction(`${ENTITY}/PROPERTY_LAND_SUBTYPE/FETCH_REQUEST`);
export const fetchSettingsPropertyLandSubtypesSuccess = createAction(`${ENTITY}/PROPERTY_LAND_SUBTYPE/FETCH_SUCCESS`);
export const fetchSettingsPropertyLandSubtypesFailure = createAction(`${ENTITY}/PROPERTY_LAND_SUBTYPE/FETCH_FAILURE`);

export const fetchSettingsPropertyOfficeSpaceSubtypes = createAction(`${ENTITY}/PROPERTY_OFFICE_SPACE_SUBTYPE/FETCH`);
export const fetchSettingsPropertyOfficeSpaceSubtypesRequest = createAction(`${ENTITY}/PROPERTY_OFFICE_SPACE_SUBTYPE/FETCH_REQUEST`);
export const fetchSettingsPropertyOfficeSpaceSubtypesSuccess = createAction(`${ENTITY}/PROPERTY_OFFICE_SPACE_SUBTYPE/FETCH_SUCCESS`);
export const fetchSettingsPropertyOfficeSpaceSubtypesFailure = createAction(`${ENTITY}/PROPERTY_OFFICE_SPACE_SUBTYPE/FETCH_FAILURE`);

export const fetchSettingsPropertyGarageSubtypes = createAction(`${ENTITY}/PROPERTY_GARAGE_SUBTYPE/FETCH`);
export const fetchSettingsPropertyGarageSubtypesRequest = createAction(`${ENTITY}/PROPERTY_GARAGEE_SUBTYPE/FETCH_REQUEST`);
export const fetchSettingsPropertyGarageSubtypesSuccess = createAction(`${ENTITY}/PROPERTY_GARAGE_SUBTYPE/FETCH_SUCCESS`);
export const fetchSettingsPropertyGarageSubtypesFailure = createAction(`${ENTITY}/PROPERTY_GARAGE_SUBTYPE/FETCH_FAILURE`);