import values from 'lodash/values';

import { stateKey } from './actions';

export const selectProjectValuesSizeFormOptions = state => {
    const data = values(state[stateKey].size);
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => (
        {
            value: service.id.toString(),
            label: service.name
        }
    ))
}

export const selectProjectValuesDevelopmentStageFormOptions = state => {
    const data = values(state[stateKey].developmentstage);
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => (
        {
            value: service.id.toString(),
            label: service.name
        }
    ))
}

export const selectProjectValuesObjectTypeFormOptions = state => {
    const data = values(state[stateKey].objecttype);
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => (
        {
            value: service.id.toString(),
            label: service.name
        }
    ))
}

export const selectProjectValuesStatusFormOptions = state => {
    const data = values(state[stateKey].status);
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => (
        {
            value: service.id.toString(),
            label: service.name
        }
    ))
}

export const selectProjectValuesTypeFormOptions = state => {
    const data = values(state[stateKey].type);
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => (
        {
            value: service.id.toString(),
            label: service.name
        }
    ))
}