import { createAction } from 'redux-actions';

const ENTITY = 'ACTIVITIES';
export const stateKey = ENTITY.toLowerCase();


export const fetchActivities = createAction(`${ENTITY}/FETCH`);
export const fetchActivitiesRequest = createAction(`${ENTITY}/FETCH_REQUEST`);
export const fetchActivitiesSuccess = createAction(`${ENTITY}/FETCH_SUCCESS`);
export const fetchActivitiesFailure = createAction(`${ENTITY}/FETCH_FAILURE`);

export const fetchActivity = createAction(`${ENTITY}/DETAILS/FETCH`);
export const fetchActivityRequest = createAction(`${ENTITY}/DETAILS/FETCH_REQUEST`);
export const fetchActivitySuccess = createAction(`${ENTITY}/DETAILS/FETCH_SUCCESS`);
export const fetchActivityFailure = createAction(`${ENTITY}/DETAILS/FETCH_FAILURE`);

export const createActivity = createAction(
    `${ENTITY}/CREATE`,
    values => values,
    (values, callback) => ({ callback })
);
// export const createActivity = createAction(`${ENTITY}/CREATE`);
export const createActivityRequest = createAction(`${ENTITY}/CREATE_REQUEST`);
export const createActivitySuccess = createAction(`${ENTITY}/CREATE_SUCCESS`);
export const createActivityFailure = createAction(`${ENTITY}/CREATE_FAILURE`);

export const updateActivity = createAction(
    `${ENTITY}/UPDATE`,
    values => values,
    (values, callback) => ({ callback })
);
// export const createActivity = createAction(`${ENTITY}/CREATE`);
export const updateActivityRequest = createAction(`${ENTITY}/UPDATE_REQUEST`);
export const updateActivitySuccess = createAction(`${ENTITY}/UPDATE_SUCCESS`);
export const updateActivityFailure = createAction(`${ENTITY}/UPDATE_FAILURE`);

export const deleteActivity = createAction(`${ENTITY}/DELETE`);
export const deleteActivityRequest = createAction(`${ENTITY}/DELETE_REQUEST`);
export const deleteActivitySuccess = createAction(`${ENTITY}/DELETE_SUCCESS`);
export const deleteActivityFailure = createAction(`${ENTITY}/DELETE_FAILURE`);

export const clearActivities = createAction(`${ENTITY}/CLEAR`);