import dayjs from 'dayjs'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import Select from 'react-select'
import { Card, CardBody, Col, Container, Form, Label } from 'reactstrap'
import * as Yup from 'yup'
import FormActionButtons from '../../../Components/Common/FormActionButtons'
import Loader from '../../../Components/Common/Loader'
import PageTitle from '../../../Components/Common/PageTitle'
import InputDate from '../../../Components/FormUI/InputDate'
import InputNumber from '../../../Components/FormUI/InputNumber'
import InputText from '../../../Components/FormUI/InputText'
import FormValidationErrorModal from '../../../Components/Modals/FormValidationErrorModal'
import { fetchDiary, selectDiary, selectDiaryFetching, updateDiary } from '../../../store/diaries'
import { selectPropertyServicesFormOptions } from '../../../store/propertyServices'
import { selectApplicationAreaUnit, selectApplicationCurrency } from '../../../store/settings/selectors'
import { renderAreaUnit, renderCurrency } from '../../../utils/property'
import style from './DiaryEdit.module.css'
import { getDiaryValidationSchema } from './formValidation'

const DiaryEdit = () => {
    const { id } = useParams()
    const dispatch = useDispatch()
    const history = useHistory()
    const { t } = useTranslation()
    const [modalErrors, setModalErrors] = useState()

    const [isErrorModalActive, setIsErrorModalActive] = useState(false)

    const { diary, fetchingDetails, propetyServiceOptions, areaUnit, currency } = useSelector((state) => ({
        diary: selectDiary(state),
        fetchingDetails: selectDiaryFetching(state),
        propetyServiceOptions: selectPropertyServicesFormOptions(state),
        areaUnit: selectApplicationAreaUnit(state),
        currency: selectApplicationCurrency(state),
    }))

    useEffect(() => {
        dispatch(fetchDiary({ id }))
    }, [dispatch, id])

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: diary,
        validationSchema: Yup.object(getDiaryValidationSchema()),
        onSubmit: (values) => {
            if (values.propertyRegistryParcelNo === '') {
                formik.setFieldValue('propertyRegistryParcelNo', null)
            } else {
                let data = {
                    ...values,
                    intermediaryDiaryBuyer_group: values.intermediaryDiaryBuyer,
                    intermediaryDiarySeller_group: values.intermediaryDiarySeller,
                }
                delete data.intermediaryDiaryBuyer
                delete data.intermediaryDiarySeller
                dispatch(updateDiary(data))
            }
        },
    })

    useEffect(() => {
        if (!formik.isValid && !formik.isValidating && formik.isSubmitting) {
            setModalErrors(Object.keys(formik.errors).map((key) => <li key={key}>{formik.errors[key]?.split(';')[1]}</li>))
            setIsErrorModalActive(true)
        }
    }, [formik.isValidating, formik.isSubmitting])

    const handleDateChange = (e, propertyName) => {
        formik?.setFieldValue(propertyName, dayjs(e).format('YYYY-MM-DD'))
    }

    const handleDropDownChange = (e, propertyName) => {
        formik?.setFieldValue(propertyName, e)
    }

    const toggleErrorModal = () => {
        setIsErrorModalActive(!isErrorModalActive)
    }

    return (
        <div className="page-content">
            <Container fluid>
                {fetchingDetails ? (
                    <div className="text-center d-flex justify-content-center" style={{ position: 'absolute', inset: 'auto 0' }}>
                        <Loader />
                    </div>
                ) : (
                    <Form onSubmit={(e) => formik.handleSubmit(e)}>
                        <div className="page-title-box d-flex align-items-center justify-content-between">
                            <PageTitle title={t('app.common.intermediaryDiary')} />
                            <FormActionButtons disabled={false} hasCancel={true} cancelAction={() => history.push('/diary')} />
                        </div>
                        <div className="d-flex justify-content-between gap-3">
                            <Col>
                                <Card>
                                    <CardBody className={style.cardBody}>
                                        <Label>{t('form.diary.propertyService')}</Label>
                                        <Select
                                            id={formik.values?.propertyService}
                                            onChange={(e) => handleDropDownChange(e.label, 'propertyService')}
                                            options={propetyServiceOptions}
                                            defaultValue={propetyServiceOptions.find(
                                                (x) => x.label.toLowerCase() === diary?.propertyService.toLowerCase()
                                            )}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg={9}>
                                <Card>
                                    <CardBody className={style.cardBody}>
                                        {formik.values?.intermediaryDiaryBuyer.length > 0 && (
                                            <Label className={style.formLabel}>{t('form.diary.buyerInfo')}</Label>
                                        )}
                                        {formik.values?.intermediaryDiaryBuyer.map((item, index) => (
                                            <div key={index} className={style.formContent}>
                                                <InputText
                                                    label={t('form.diary.fullName')}
                                                    className={style.formInput}
                                                    id={`intermediaryDiaryBuyer[${index}].fullName`}
                                                    onChange={formik.handleChange}
                                                    defaultValue={item.fullName}
                                                    formikError={formik.errors}
                                                    formikTouched={formik.touched}
                                                    isRequired={true}
                                                />
                                                <InputNumber
                                                    maxLength={14}
                                                    label={t('app.common.pin')}
                                                    className={style.formInput}
                                                    id={`intermediaryDiaryBuyer[${index}].taxNumber`}
                                                    onChange={formik.handleChange}
                                                    defaultValue={item.taxNumber}
                                                />
                                                <InputText
                                                    label={t('app.common.city')}
                                                    className={style.formInput}
                                                    id={`intermediaryDiaryBuyer[${index}].city`}
                                                    onChange={formik.handleChange}
                                                    defaultValue={item.city}
                                                />
                                                <InputText
                                                    label={t('app.common.street')}
                                                    className={style.formInput}
                                                    id={`intermediaryDiaryBuyer[${index}].street`}
                                                    onChange={formik.handleChange}
                                                    defaultValue={item.street}
                                                />
                                                <InputNumber
                                                    label={t('app.common.streetNumber')}
                                                    className={style.formInput}
                                                    id={`intermediaryDiaryBuyer[${index}].streetNumber`}
                                                    onChange={formik.handleChange}
                                                    defaultValue={item.streetNumber}
                                                />
                                            </div>
                                        ))}
                                        {formik.values?.intermediaryDiarySeller.length > 0 && (
                                            <Label className={style.formLabel}>{t('form.diary.sellerInfo')}</Label>
                                        )}
                                        {formik.values?.intermediaryDiarySeller.map((item, index) => (
                                            <div key={index} className={style.formContent}>
                                                <InputText
                                                    label={t('form.diary.fullName')}
                                                    className={style.formInput}
                                                    id={`intermediaryDiarySeller[${index}].fullName`}
                                                    onChange={formik.handleChange}
                                                    defaultValue={item.fullName}
                                                />
                                                <InputNumber
                                                    maxLength={14}
                                                    label={t('app.common.pin')}
                                                    className={style.formInput}
                                                    id={`intermediaryDiarySeller[${index}].taxNumber`}
                                                    onChange={formik.handleChange}
                                                    defaultValue={item.taxNumber}
                                                />
                                                <InputText
                                                    label={t('app.common.city')}
                                                    className={style.formInput}
                                                    id={`intermediaryDiarySeller[${index}].city`}
                                                    onChange={formik.handleChange}
                                                    defaultValue={item.city}
                                                />
                                                <InputText
                                                    label={t('app.common.street')}
                                                    className={style.formInput}
                                                    id={`intermediaryDiarySeller[${index}].street`}
                                                    onChange={formik.handleChange}
                                                    defaultValue={item.street}
                                                />
                                                <InputNumber
                                                    label={t('app.common.streetNumber')}
                                                    className={style.formInput}
                                                    id={`intermediaryDiarySeller[${index}].streetNumber`}
                                                    onChange={formik.handleChange}
                                                    defaultValue={item.streetNumber}
                                                />
                                            </div>
                                        ))}
                                        <Label className={style.formLabel}>{t('form.diary.propertyInfo')}</Label>
                                        <div className={style.formContent}>
                                            <InputText
                                                label={t('app.common.property.type')}
                                                className={style.formInput}
                                                id={'propertyType'}
                                                onChange={formik.handleChange}
                                                defaultValue={formik.values?.propertyType}
                                                formikError={formik.errors}
                                                formikTouched={formik.touched}
                                                isRequired={true}
                                            />
                                            <InputNumber
                                                label={`${t('app.common.spaceArea')} (${renderAreaUnit(areaUnit)})`}
                                                className={style.formInput}
                                                id={'areaNett'}
                                                onChange={formik.handleChange}
                                                defaultValue={formik.values?.areaNett}
                                                formikError={formik.errors}
                                                formikTouched={formik.touched}
                                                isRequired={true}
                                                isFormated={true}
                                            />
                                            <InputNumber
                                                label={t('app.common.floor')}
                                                className={style.formInput}
                                                id={'propertyFloorNo'}
                                                onChange={formik.handleChange}
                                                defaultValue={formik.values?.propertyFloorNo}
                                            />
                                            <InputNumber
                                                label={t('form.diary.roomNo')}
                                                className={style.formInput}
                                                id={'propertyRoomsNo'}
                                                onChange={formik.handleChange}
                                                defaultValue={formik.values?.propertyRoomsNo}
                                            />
                                            <InputText
                                                label={t('app.common.registry.parcel')}
                                                className={style.formInput}
                                                id={'propertyRegistryParcelNo'}
                                                onChange={formik.handleChange}
                                                defaultValue={formik.values?.propertyRegistryParcelNo}
                                                formikError={formik.errors}
                                                formikTouched={formik.touched}
                                            />
                                            <InputText
                                                label={t('app.common.registry.insert')}
                                                className={style.formInput}
                                                id={'propertyRegistryInsert'}
                                                onChange={formik.handleChange}
                                                defaultValue={formik.values?.propertyRegistryInsert}
                                                formikError={formik.errors}
                                                formikTouched={formik.touched}
                                                isRequired={true}
                                            />
                                            <InputText
                                                label={t('app.common.registry.subInsert')}
                                                className={style.formInput}
                                                id={'propertyRegistrySubinsert'}
                                                onBlur={() => console.log(formik.errors)}
                                                onChange={formik.handleChange}
                                                defaultValue={formik.values?.propertyRegistrySubinsert}
                                            />
                                            <InputText
                                                label={t('app.common.cadastralMunicipality')}
                                                className={style.formInput}
                                                id={'propertyRegistryMunicipality'}
                                                onChange={formik.handleChange}
                                                defaultValue={formik.values?.propertyRegistryMunicipality}
                                                formikError={formik.errors}
                                                formikTouched={formik.touched}
                                                isRequired={true}
                                            />
                                            <InputText
                                                label={t('app.common.property.cadastralParcel')}
                                                className={style.formInput}
                                                id={'propertyCadastralParcelNo'}
                                                onChange={formik.handleChange}
                                                defaultValue={formik.values?.propertyCadastralParcelNo}
                                                formikError={formik.errors}
                                                formikTouched={formik.touched}
                                            />
                                        </div>
                                        <Label className={style.formLabel}>{t('form.diary.transactionInfo')}</Label>
                                        <div className={style.formContent}>
                                            <InputNumber
                                                label={`${t('form.diary.finalPrice')} ${renderCurrency(currency)}`}
                                                className={style.formInput}
                                                id={'finalPrice'}
                                                onChange={formik.handleChange}
                                                defaultValue={formik.values?.finalPrice}
                                                formikError={formik.errors}
                                                formikTouched={formik.touched}
                                                isRequired={true}
                                                isFormated={true}
                                            />
                                            <InputNumber
                                                label={`${t('form.diary.propertyAdvanceAmount')} ${renderCurrency(currency)}`}
                                                className={style.formInput}
                                                id={'propertyAdvanceAmount'}
                                                onChange={formik.handleChange}
                                                defaultValue={formik.values?.propertyAdvanceAmount}
                                                isFormated={true}
                                            />
                                            <InputText
                                                label={t('app.common.conclusionPlace')}
                                                className={style.formInput}
                                                id={'conclusionPlace'}
                                                onChange={formik.handleChange}
                                                defaultValue={formik.values?.conclusionPlace}
                                                formikError={formik.errors}
                                                formikTouched={formik.touched}
                                                isRequired={true}
                                            />
                                            <InputDate
                                                label={t('app.common.conclusionDate')}
                                                className={style.formInput}
                                                id={'conclusionDate'}
                                                onChange={(e) => handleDateChange(e, 'conclusionDate')}
                                                defaultValue={formik.values?.conclusionDate}
                                                formikError={formik.errors}
                                                formikTouched={formik.touched}
                                                isRequired={true}
                                            />
                                            <InputDate
                                                label={t('form.diary.finalPaymentDate')}
                                                className={style.formInput}
                                                id={'finalPaymentDate'}
                                                onChange={(e) => handleDateChange(e, 'finalPaymentDate')}
                                                defaultValue={formik.values?.finalPaymentDate}
                                                formikError={formik.errors}
                                                formikTouched={formik.touched}
                                                isRequired={true}
                                            />
                                            <InputDate
                                                label={t('app.common.creationDate')}
                                                className={style.formInput}
                                                id={'intermediaryDiaryDate'}
                                                onChange={(e) => handleDateChange(e, 'intermediaryDiaryDate')}
                                                defaultValue={formik.values?.intermediaryDiaryDate}
                                                formikError={formik.errors}
                                                formikTouched={formik.touched}
                                                isRequired={true}
                                            />
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </div>
                        <div className="d-flex justify-content-end mb-4">
                            <FormActionButtons disabled={false} hasCancel={true} cancelAction={() => history.push('/diary')} />
                        </div>
                    </Form>
                )}
            </Container>
            {isErrorModalActive && <FormValidationErrorModal modalErrors={modalErrors} validation={formik} onClose={() => toggleErrorModal(false)} />}
        </div>
    )
}

export default DiaryEdit
