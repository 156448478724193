import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { saveAgentLanguage } from './functions'
import { LangErrorResponse, LangSuccessResponse } from './types'

// TODO: move to Agents later 
const langDropdown = {
    saveAgentLanguage: {
        useMutation: (options?: UseMutationOptions<LangSuccessResponse, LangErrorResponse, string>) =>
            useMutation({
                mutationFn: (locale: string) => saveAgentLanguage(locale),
                onSuccess: () => window.location.reload(), // reload app to fetch non i18next translations
                ...options,
            }),
    },
}

export default langDropdown
