import PropertyFormMaterialsContext from "./PropertyFormMaterialsContext";

const PropertyFormMaterialsTab = ({ children, ...props}) => {
    return (
        <PropertyFormMaterialsContext.Provider
            value={{
                tabs: props.tabs,
                activeTab: props.activeTab,
                handleTabChange: props.handleTabChange,
                activeMaterialsTab: props.activeMaterialsTab,
                materialsTabs: props.materialsTabs,
                handleMaterialsTabChange: props.handleMaterialsTabChange,
                propertyId: props.propertyId
            }}
        >
            {children}
        </PropertyFormMaterialsContext.Provider>
    )
}


export default PropertyFormMaterialsTab;