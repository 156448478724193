import QueryString from 'qs'
import { toast } from 'react-toastify'
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { SUBMIT_RESPONSE_STATUS } from '../../common/constants'
import { apiRequest } from '../api'
import i18n from '../../i18n'
import {
    createCompany,
    createCompanyFailure,
    createCompanyRequest,
    createCompanySuccess,
    deleteCompany,
    deleteCompanyFailure,
    deleteCompanyMultiple,
    deleteCompanyMultipleFailure,
    deleteCompanyMultipleRequest,
    deleteCompanyMultipleSuccess,
    deleteCompanyRequest,
    deleteCompanySuccess,
    fetchCompanies,
    fetchCompaniesAsyncInput,
    fetchCompaniesAsyncInputContactFailure,
    fetchCompaniesAsyncInputContactRequest,
    fetchCompaniesAsyncInputContactSuccess,
    fetchCompaniesFailure,
    fetchCompaniesNoStore,
    fetchCompaniesNoStoreFailure,
    fetchCompaniesNoStoreRequest,
    fetchCompaniesNoStoreSuccess,
    fetchCompaniesRequest,
    fetchCompaniesSuccess,
    fetchCompany,
    fetchCompanyFailure,
    fetchCompanyRequest,
    fetchCompanySuccess,
    updateCompany,
    updateCompanyFailure,
    updateCompanyRequest,
    updateCompanySuccess,
} from './actions'

export function* fetchCompaniesSaga({ payload, meta }) {
    yield put(fetchCompaniesRequest())
    try {
        const response = yield apiRequest(`v1/contact`, {
            method: 'GET',
            params: payload,
            paramsSerializer: (params) => {
                return QueryString.stringify(params)
            },
        })
        yield put(fetchCompaniesSuccess(response.data))
        meta(response.data.count)
    } catch (error) {
        yield put(fetchCompaniesFailure(error))
    }
}

export function* fetchCompanySaga({ payload }) {
    const { id, order, criteria } = payload

    yield put(fetchCompanyRequest())
    try {
        const response = yield apiRequest(`v1/contact/${id}`, {
            method: 'GET',
            params: { order, criteria },
            paramsSerializer: (params) => {
                return QueryString.stringify(params)
            },
        })
        yield put(fetchCompanySuccess(response.data))
    } catch (error) {
        yield put(fetchCompanyFailure(error))
    }
}

export function* createCompanySaga({ payload, meta }) {
    yield put(createCompanyRequest())
    const progressToast = toast.loading(i18n.t('toast.loading'))
    try {
        const response = yield apiRequest('v1/contact/company', {
            method: 'POST',
            data: payload,
        })
        yield put(createCompanySuccess(response))
        toast.update(progressToast, { render: i18n.t('toast.update.success'), type: 'success', isLoading: false, autoClose: 3000 })
        if (meta && meta.callback) {
            meta.callback({
                submitStatus: SUBMIT_RESPONSE_STATUS.SUCCESS,
                data: response.data,
            })
        }
    } catch (error) {
        yield put(createCompanyFailure(error))
        toast.update(progressToast, { render: i18n.t('toast.update.error'), type: 'error', isLoading: false, autoClose: 3000 })
    }

    // if(meta && meta.callback){
    //     meta.callback();
    // }
}

export function* updateCompanySaga({ payload, meta }) {
    yield put(updateCompanyRequest())
    const progressCToast = toast.loading(i18n.t('toast.loading'))
    console.log(progressCToast)
    const { id, ...data } = payload
    try {
        const response = yield apiRequest(`v1/contact/${id}`, {
            method: 'PUT',
            data: data,
        })
        yield put(updateCompanySuccess(response))
        toast.update(progressCToast, { render: i18n.t('toast.update.success'), type: 'success', isLoading: false, autoClose: 3000 })
        if (meta && meta.callback) {
            meta.callback({
                submitStatus: SUBMIT_RESPONSE_STATUS.SUCCESS,
                data: response.data,
            })
        }
    } catch (error) {
        yield put(updateCompanyFailure(error))
        toast.update(progressCToast, { render: i18n.t('toast.update.error'), type: 'error', isLoading: false, autoClose: 3000 })
    }

    // if(meta && meta.callback){
    //     meta.callback();
    // }
}

export function* deleteCompanySaga({ payload, meta }) {
    yield put(deleteCompanyRequest())
    const progressToast = toast.loading(i18n.t('toast.loading.delete'))
    try {
        const response = yield apiRequest(`v1/contact/${payload}`, {
            method: 'DELETE',
        })
        yield put(deleteCompanySuccess(payload))
        toast.update(progressToast, { render: i18n.t('toast.update.delete.success'), type: 'success', isLoading: false, autoClose: 3000 })

        if (meta && meta.redirect) {
            meta.redirect()
        }
    } catch (error) {
        yield put(deleteCompanyFailure(error))
        toast.update(progressToast, { render: i18n.t('toast.update.delete.error'), type: 'error', isLoading: false, autoClose: 3000 })
    }
}

function* deleteCompanyById(id) {
    try {
        const response = yield apiRequest(`v1/contact/${id}`, {
            method: 'DELETE',
        })
        return response
    } catch (error) {
        return error
    }
}

export function* deleteCompanyMultipleSaga({ payload }) {
    const requestsToBeDeletedCount = payload.length
    yield put(deleteCompanyMultipleRequest())

    if (requestsToBeDeletedCount === 0) {
        yield put(deleteCompanyMultipleFailure('Nema poduzeća za brisanje'))
        return
    }

    const progressToast = toast.loading(i18n.t('toast.loading.delete.number', {requestsToBeDeletedCount}))

    const responses = yield all(payload.map((id) => call(deleteCompanyById, id)))

    const failedResponses = responses.filter((response) => response instanceof Error)
    const deletedCompanies = responses.filter((response) => response.status === 200).map((response) => parseInt(response.data.id))
    const successCount = deletedCompanies.length
    const failedCount = failedResponses.length

    if (successCount === 0 && failedCount > 0) {
        yield put(deleteCompanyMultipleFailure('Brisanje nije uspjelo'))
        toast.update(progressToast, {
            render: i18n.t('toast.update.delete.error'),
            type: 'error',
            isLoading: false,
            autoClose: 3000,
        })
        return
    }

    if (successCount > 0) {
        toast.update(progressToast, {
            render: i18n.t('toast.loading.delete.number.success', {successCount, requestsToBeDeletedCount}),
            type: 'success',
            isLoading: false,
            autoClose: 3000,
        })
    }
    
    if (failedCount > 0) {
        toast.error(i18n.t('toast.loading.delete.number.error', {failedCount, requestsToBeDeletedCount}), {
            autoClose: 3000,
        })
    }

    yield put(deleteCompanyMultipleSuccess(deletedCompanies))
}

export function* fetchCompaniesAsyncInputSaga({ payload, meta }) {
    console.log('payload async', payload, meta)
    const params = payload.params
    yield put(fetchCompaniesAsyncInputContactRequest())
    try {
        let response = yield apiRequest(`v1/contact`, {
            method: 'GET',
            params: params,
            paramsSerializer: (params) => {
                return QueryString.stringify(params)
            },
        })
        // console.log('## respnse', response)
        yield put(fetchCompaniesAsyncInputContactSuccess())
        // console.log('response',response.data)
        let formatedResults = []
        if (response.data.items) {
            formatedResults = response.data.items?.map((item) => {
                if (item.contactType === 'company') {
                    // console.log('checking ids', item)
                    return {
                        value: item.id,
                        label: item.contactCompany?.name,
                        data: {
                            contactEmail: item.contactEmail,
                        },
                    }
                }
                return {
                    value: item.id,
                    label: `${item.contactPerson?.firstName} ${item.contactPerson?.lastName}`,
                    data: {
                        contactEmail: item.contactEmail,
                    },
                }
            })
        } else {
            if (response.data.contactType === 'company') {
                formatedResults = [
                    {
                        value: response.data.id,
                        label: response.data.contactCompany?.name,
                        selected: payload?.initialValue ? true : false,
                    },
                ]
            } else {
                if (response.data.id) {
                    formatedResults = [
                        {
                            value: response.data.id,
                            label: response.data.contactCompany?.name,
                            data: {
                                contactEmail: response.data.contactEmail,
                            },
                        },
                    ]
                }
            }
        }

        meta(formatedResults)

        // return response.data
    } catch (error) {
        yield put(fetchCompaniesAsyncInputContactFailure(error))
    }
}

export function* fetchCompaniesNoStoreSaga({ payload, meta }) {
    let params = {
        criteria: {
            ...payload?.criteria,
            contactType: 'company',
        },
    }

    if (payload.criteria?.contact && payload.criteria.contactProfile) {
        params = {
            criteria: {
                ...payload.criteria.contact,
                contactProfile: {
                    ...payload.criteria.contactProfile,
                    ...(payload.criteria.contact.contactProfileService
                        ? {
                              contactProfileService: payload.criteria.contact.contactProfileService,
                          }
                        : {}),
                    ...(payload.criteria.contact.contactProfileStatus
                        ? {
                              contactProfileStatus: payload.criteria.contact.contactProfileStatus,
                          }
                        : {}),
                },
                contactType: 'company',
            },
        }

        delete params.criteria.contactProfileStatus
        delete params.criteria.contactProfileService
    }

    yield put(fetchCompaniesNoStoreRequest())
    try {
        const response = yield apiRequest(`v1/contact`, {
            method: 'GET',
            params,
            paramsSerializer: (params) => {
                return QueryString.stringify(params)
            },
        })

        yield put(fetchCompaniesNoStoreSuccess())
        if (meta && meta.callback) {
            meta.callback({
                submitStatus: SUBMIT_RESPONSE_STATUS.SUCCESS,
                data: response.data,
            })
        }
    } catch (error) {
        yield put(fetchCompaniesNoStoreFailure(error))
        meta.callback({
            submitStatus: SUBMIT_RESPONSE_STATUS.FAILURE,
        })
    }
}

export default function* actionWatcher() {
    yield takeLatest(`${fetchCompanies}`, fetchCompaniesSaga)
    yield takeLatest(`${fetchCompany}`, fetchCompanySaga)
    yield takeEvery(`${createCompany}`, createCompanySaga)
    yield takeEvery(`${updateCompany}`, updateCompanySaga)
    yield takeEvery(`${deleteCompany}`, deleteCompanySaga)
    yield takeEvery(`${deleteCompanyMultiple}`, deleteCompanyMultipleSaga)
    yield takeLatest(`${fetchCompaniesAsyncInput}`, fetchCompaniesAsyncInputSaga)
    yield takeLatest(`${fetchCompaniesNoStore}`, fetchCompaniesNoStoreSaga)
}
