import { getUrlBase } from '../../../utils/general'
import { setPermissions } from './utils'

// module permissions controls actions (view, edit, delete) inside modules
export const setModulePermissions = ({ agentPermissions, isAgentOwnership, isAgentOffice, module, action, permissionName }) => {
    const { permission, name: actionName } = action ?? {}

    let hasModulePermission
    const moduleName = getUrlBase(module)

    permissionName = permissionName ?? `module.${moduleName}.${permission}`

    switch (permission) {
        case 'view':
        case 'edit':
            hasModulePermission = setPermissions(agentPermissions, permissionName, module, isAgentOwnership, isAgentOffice, actionName)
            break
        default:
            break
    }

    return { hasModulePermission }
}
