import { getIn } from 'formik'
import { useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Col, Row } from 'reactstrap'
import { REAL_ESTATE_TYPES } from '../../common/constants'
import requestFields from '../../common/constants/requestFields'
import { realEstateGeneralFields } from '../../common/forms/generalRealEstate/fields'
import { fetchLocationsCity, fetchLocationsCounty, fetchLocationsIsland, fetchLocationsQuarter, fetchLocationsWiderArea } from '../../store/locations'
import { fetchProjectNoStore } from '../../store/projects'
import { fetchPropertySubtypes } from '../../store/propertySubtypes'
import {
    fetchPropertyValuesAgencyCommission,
    fetchPropertyValuesCondition,
    fetchPropertyValuesDescriptions,
    fetchPropertyValuesEquipment,
    fetchPropertyValuesFloor,
    fetchPropertyValuesHeating,
    fetchPropertyValuesLandUsage,
    fetchPropertyValuesPermit,
    fetchPropertyValuesPrimiaryUsage,
    fetchPropertyValuesSpace,
    fetchPropertyValuesUtility,
} from '../../store/propertyValues'
import AppPermissionsSwitcher from '../AppPermissionsSwitcher/AppPermissionsSwitcher'
import FormMainContainer from '../Common/FormMainContainer'
import FormSideContainer from '../Common/FormSideContainer'
import RecursiveForm from '../RecursiveForm'
import PropertyFormGeneralContext from './PropertyFormGeneralContext'

const PropertyFormGeneralTab = ({ children, ...props }) => {
    const id = getIn(props.formik?.values?.general, 'id')

    return (
        <PropertyFormGeneralContext.Provider
            value={{
                formik: props.formik,
                inputs: props.inputs,
                subform: props.subform,
                selectOptions: props.selectOptions,
                inputComponents: props.inputComponents,
                realEstateType: props.realEstateType,
                realEstateService: props.realEstateService,
                locationData: props.locationData,
                action: props.action,
                tabs: props.tabs,
                activeTab: props.activeTab,
                handleTabChange: props.handleTabChange,
                module: props.module,
                id,
            }}
        >
            <Row>{children}</Row>
        </PropertyFormGeneralContext.Provider>
    )
}

export const PropertyFormGeneralTabSide = (props) => {
    const { inputs, formik, subform, selectOptions, inputComponents, id, module } = useContext(PropertyFormGeneralContext)
    const dispatch = useDispatch()

    useEffect(() => {
        const propertyTypeId = getIn(formik?.values?.general, realEstateGeneralFields.PROPERTY_TYPE_ID)
        if (propertyTypeId) {
            dispatch(fetchPropertySubtypes(propertyTypeId))
        }
    }, [id])

    if (inputs.sideForm) {
        let propertyTypeGroupIndex = inputs.sideForm.findIndex((x) => x.some((o) => o.name === realEstateGeneralFields.PROPERTY_TYPE_ID))
        if (propertyTypeGroupIndex !== -1) {
            const propertyTypeIndex = inputs.sideForm[propertyTypeGroupIndex].findIndex(
                (obj) => obj.name === realEstateGeneralFields.PROPERTY_TYPE_ID
            )

            if (propertyTypeIndex !== -1) {
                inputs.sideForm[propertyTypeGroupIndex][propertyTypeIndex].handleChange = (id) => {
                    formik.setFieldValue(`${subform}.${realEstateGeneralFields.PROPERTY_SUB_TYPE_ID}`, '')
                    dispatch(fetchPropertySubtypes(id))
                }
            }
        }
    }

    return (
        <FormSideContainer>
            <AppPermissionsSwitcher permission="EditData" data={inputs.sideForm} module={module}>
                {({ newData }) => (
                    <RecursiveForm
                        inputs={newData}
                        subform={subform}
                        formik={formik}
                        selectOptions={selectOptions}
                        inputComponents={inputComponents}
                    />
                )}
            </AppPermissionsSwitcher>
        </FormSideContainer>
    )
}

export const PropertyFormGeneralTabMain = ({ children, ...props }) => {
    const {
        inputs,
        formik,
        subform,
        selectOptions,
        inputComponents,
        realEstateType,
        realEstateService,
        tabs,
        activeTab,
        handleTabChange,
        locationData,
        action,
        module,
        id,
    } = useContext(PropertyFormGeneralContext)

    const dispatch = useDispatch()
    const [userModifiedPriceM2, setUserModifiedPriceM2] = useState(false)

    const priceValue = getIn(formik?.values?.general, realEstateGeneralFields.PRICE)
    const areaValue = getIn(formik?.values?.general, realEstateGeneralFields.AREA)
    const priceField = document.getElementById(realEstateGeneralFields.PRICE)
    const areaField = document.getElementById(realEstateGeneralFields.AREA)

    const priceM2Value = getIn(formik?.values?.general, realEstateGeneralFields.PRICE_M2)
    const [modifiedInputs, setModifiedInputs] = useState(inputs ?? [])

    const locationGroupIndex = inputs.mainForm?.findIndex((obj) => obj.name === 'locationGroup')
    const countryIndex = inputs.mainForm?.[locationGroupIndex]?.children.findIndex((obj) => obj.name === realEstateGeneralFields.COUNTRY_ID)
    const countyIndex = inputs.mainForm?.[locationGroupIndex]?.children.findIndex((obj) => obj.name === realEstateGeneralFields.COUNTY_ID)
    const cityIndex = inputs.mainForm?.[locationGroupIndex]?.children.findIndex((obj) => obj.name === realEstateGeneralFields.CITY_ID)
    const widerAreaIndex = inputs.mainForm?.[locationGroupIndex]?.children.findIndex((obj) => obj.name === realEstateGeneralFields.WIDER_AREA_ID)
    const quarterIndex = inputs.mainForm?.[locationGroupIndex]?.children.findIndex((obj) => obj.name === realEstateGeneralFields.QUARTER_ID)
    const islandIndex = inputs.mainForm?.[locationGroupIndex]?.children.findIndex((obj) => obj.name === realEstateGeneralFields.ISLAND_ID)

    const propertyTypeId = getIn(formik?.values?.general, realEstateGeneralFields.PROPERTY_TYPE_ID)
    const propertyServiceId = getIn(formik?.values?.general, realEstateGeneralFields.PROPERTY_SERVICE_ID)
    const projectId = getIn(formik?.values?.general, realEstateGeneralFields.PROJECT_ID)
    const countryId = getIn(formik?.values?.general, realEstateGeneralFields.COUNTRY_ID)
    const countyId = getIn(formik?.values?.general, realEstateGeneralFields.COUNTY_ID)
    const cityId = getIn(formik?.values?.general, realEstateGeneralFields.CITY_ID)
    const widerAreaId = getIn(formik?.values?.general, realEstateGeneralFields.WIDER_AREA_ID)
    const quarterId = getIn(formik?.values?.general, realEstateGeneralFields.QUARTER_ID)
    const islandId = getIn(formik?.values?.general, realEstateGeneralFields.ISLAND_ID)
    const agencyCommissionId = getIn(formik?.values?.general, realEstateGeneralFields.AGENCY_COMMISSION_ID)
    const streetValue = getIn(formik?.values?.general, realEstateGeneralFields.STREET)
    const streetNumberValue = getIn(formik?.values?.general, realEstateGeneralFields.STREET_NUMBER)
    const streetSyncValue = getIn(formik?.values?.general, realEstateGeneralFields.STREET_SYNC)

    const specialFeaturesAndOtherGroupIndex = inputs.mainForm?.findIndex((obj) => obj.name === 'specialFeaturesAndOtherGroup')
    const propertySpaceIndex = inputs.mainForm?.[specialFeaturesAndOtherGroupIndex]?.children.findIndex(
        (obj) => obj.name === realEstateGeneralFields.PROPERTY_SPACE_ID
    )
    const heatingIndex = inputs.mainForm?.[specialFeaturesAndOtherGroupIndex]?.children.findIndex(
        (obj) => obj.name === realEstateGeneralFields.PROPERTY_HEATING_ID
    )
    const floorIndex = inputs.mainForm?.[specialFeaturesAndOtherGroupIndex]?.children.findIndex(
        (obj) => obj.name === realEstateGeneralFields.PROPERTY_FLOOR_ID
    )
    const descriptionsIndex = inputs.mainForm?.[specialFeaturesAndOtherGroupIndex]?.children.findIndex(
        (obj) => obj.name === realEstateGeneralFields.PROPERTY_DESCRIPTIONS_ID
    )
    const equipmentIndex = inputs.mainForm?.[specialFeaturesAndOtherGroupIndex]?.children.findIndex(
        (obj) => obj.name === realEstateGeneralFields.PROPERTY_EQUIPMENT_ID
    )
    const utilityIndex = inputs.mainForm?.[specialFeaturesAndOtherGroupIndex]?.children.findIndex(
        (obj) => obj.name === realEstateGeneralFields.PROPERTY_UTILITY_ID
    )

    const propertyFloorPositionValueTmp = getIn(formik?.values?.general, realEstateGeneralFields.PROPERTY_FLOOR_POSITION_ID)
    const propertyFloorPositionValue = propertyFloorPositionValueTmp && propertyFloorPositionValueTmp.length ? propertyFloorPositionValueTmp : null
    const floorNoValue = getIn(formik?.values?.general, realEstateGeneralFields.FLOOR_NO)

    const generalInfoIndex = inputs.mainForm?.findIndex((obj) => obj.name === 'generalInfoGroup')
    const propertyFloorPositionIndex = inputs.mainForm?.[generalInfoIndex]?.children.findIndex(
        (obj) => obj.name === realEstateGeneralFields.PROPERTY_FLOOR_POSITION_ID
    )
    const floorNoIndex = inputs.mainForm?.[generalInfoIndex]?.children.findIndex((obj) => obj.name === realEstateGeneralFields.FLOOR_NO)

    useEffect(() => {
        if (!id && inputs) {
            setModifiedInputs(inputs)
        }
    }, [inputs, id])

    // prefill country, county and city inputs with location data (if exists) in create property form
    useEffect(() => {
        if (realEstateType && realEstateService && action === 'create') {
            Object.keys(locationData).forEach((key) => {
                if (locationData[key] !== null) {
                    formik.setFieldValue(`general.${key}_id`, locationData[key]?.id)
                }
            })
        }
    }, [realEstateType, realEstateService, action])

    useEffect(() => {
        if (propertyFloorPositionValue && propertyFloorPositionValue.length) {
            if (inputs.mainForm) {
                const changed = { ...modifiedInputs }
                if (generalInfoIndex > -1 && floorNoIndex > -1) {
                    changed.mainForm[generalInfoIndex].children[floorNoIndex].disabled = true
                    changed.mainForm[generalInfoIndex].children[floorNoIndex].required = false
                    setModifiedInputs({ ...changed })
                    formik.setFieldValue(`${subform}.${realEstateGeneralFields.FLOOR_NO}`, '')
                }
            }
        } else {
            if (inputs.mainForm) {
                const changed = { ...modifiedInputs }
                if (generalInfoIndex > -1 && floorNoIndex > -1 && changed.mainForm.length > 0) {
                    changed.mainForm[generalInfoIndex].children[floorNoIndex].disabled = false
                    changed.mainForm[generalInfoIndex].children[floorNoIndex].required = true
                    setModifiedInputs({ ...changed })
                }
            }
        }

        if (floorNoValue) {
            if (inputs.mainForm) {
                const changed = { ...modifiedInputs }
                if (generalInfoIndex > -1 && floorNoIndex > -1 && changed.mainForm.length > 0) {
                    changed.mainForm[generalInfoIndex].children[propertyFloorPositionIndex].disabled = true
                    changed.mainForm[generalInfoIndex].children[propertyFloorPositionIndex].required = false
                    setModifiedInputs({ ...changed })
                    formik.setFieldValue(`${subform}.${realEstateGeneralFields.PROPERTY_FLOOR_POSITION_ID}`, [])
                }
            }
        } else {
            if (inputs.mainForm) {
                const changed = { ...modifiedInputs }
                if (generalInfoIndex > -1 && floorNoIndex > -1 && changed.mainForm.length) {
                    changed.mainForm[generalInfoIndex].children[propertyFloorPositionIndex].disabled = false
                    changed.mainForm[generalInfoIndex].children[propertyFloorPositionIndex].required = true
                    setModifiedInputs({ ...changed })
                }
            }
        }
    }, [propertyFloorPositionValue, floorNoValue])

    useEffect(() => {
        if (propertyTypeId) {
            // if(inputs.mainForm){
            //     if(specialFeaturesAndOtherGroupIndex > -1) {
            //         if(propertySpaceIndex > -1){
            //             inputs.mainForm[specialFeaturesAndOtherGroupIndex].children[propertySpaceIndex].disabled = false;
            //         }
            //         if(heatingIndex > -1){
            //             inputs.mainForm[specialFeaturesAndOtherGroupIndex].children[heatingIndex].disabled = false;
            //         }
            //         if(floorIndex > -1){
            //             inputs.mainForm[specialFeaturesAndOtherGroupIndex].children[floorIndex].disabled = false;
            //         }
            //         if(equipmentIndex > -1){
            //             inputs.mainForm[specialFeaturesAndOtherGroupIndex].children[equipmentIndex].disabled = false;
            //         }
            //         if(utilityIndex > -1){
            //             inputs.mainForm[specialFeaturesAndOtherGroupIndex].children[utilityIndex].disabled = false;
            //         }
            //     }
            // }
            dispatch(
                fetchPropertyValuesEquipment({
                    filter: {
                        propertyType: propertyTypeId,
                    },
                })
            )
            dispatch(
                fetchPropertyValuesHeating({
                    filter: { propertyType: propertyTypeId },
                })
            )
            dispatch(
                fetchPropertyValuesSpace({
                    filter: { propertyType: propertyTypeId },
                })
            )
            dispatch(
                fetchPropertyValuesFloor({
                    filter: { propertyType: propertyTypeId },
                })
            )
            dispatch(
                fetchPropertyValuesUtility({
                    filter: { propertyType: propertyTypeId },
                })
            )

            if (propertyTypeId?.toString() === REAL_ESTATE_TYPES.officeSpace.toString()) {
                dispatch(
                    fetchPropertyValuesPrimiaryUsage({
                        filter: { propertyType: propertyTypeId },
                    })
                )
            }

            if (propertyTypeId?.toString() === REAL_ESTATE_TYPES.land.toString()) {
                dispatch(fetchPropertyValuesLandUsage())
            }

            if (propertyTypeId !== REAL_ESTATE_TYPES.land) {
                dispatch(
                    fetchPropertyValuesCondition({
                        filter: { propertyType: propertyTypeId },
                    })
                )
            }
        } else {
            // if(inputs.mainForm){
            //     inputs.sideForm[propertySubTypeIndex].disabled = true;
            //     if(propertySpaceIndex > -1){
            //         inputs.mainForm[relatedStuffGroupIndex].children[propertySpaceIndex].disabled = false;
            //     }
            //     if(heatingIndex > -1){
            //         inputs.mainForm[relatedStuffGroupIndex].children[heatingIndex].disabled = true;
            //     }
            //     if(floorIndex > -1){
            //         inputs.mainForm[relatedStuffGroupIndex].children[floorIndex].disabled = true;
            //     }
            //     if(equipmentIndex > -1){
            //         inputs.mainForm[relatedStuffGroupIndex].children[equipmentIndex].disabled = true;
            //     }
            //     if(utilityIndex > -1){
            //         inputs.mainForm[relatedStuffGroupIndex].children[utilityIndex].disabled = true;
            //     }
            // }
        }
    }, [id, propertyTypeId])

    useEffect(() => {
        if (propertyTypeId && propertyServiceId) {
            const filter = {
                propertyType: propertyTypeId,
                propertyService: propertyServiceId,
            }
            dispatch(
                fetchPropertyValuesDescriptions({
                    filter,
                })
            )

            dispatch(
                fetchPropertyValuesPermit({
                    filter,
                })
            )

            // if(inputs.mainForm) {
            //     if(descriptionsIndex > -1){
            //         inputs.mainForm[relatedStuffGroupIndex].children[descriptionsIndex].disabled = false;
            //     }
            // }
        } else {
            // if(inputs.mainForm) {
            //     if(descriptionsIndex > -1){
            //         inputs.mainForm[relatedStuffGroupIndex].children[descriptionsIndex].disabled = true;
            //     }
            // }
        }
    }, [id, propertyServiceId, propertyTypeId])

    useEffect(() => {
        if (propertyServiceId) {
            dispatch(
                fetchPropertyValuesAgencyCommission({
                    filter: { propertyService: propertyServiceId },
                })
            )
        }
    }, [id, propertyServiceId])

    useEffect(() => {
        if (countryId) {
            dispatch(fetchLocationsCounty(countryId))
            dispatch(fetchLocationsIsland(countryId))
            if (inputs.mainForm) {
                if (locationGroupIndex > -1) {
                    const changed = { ...modifiedInputs }
                    if (inputs.mainForm && changed.mainForm.length > 0) {
                        changed.mainForm[locationGroupIndex].children[countyIndex].disabled = false
                        changed.mainForm[locationGroupIndex].children[islandIndex].disabled = false
                        setModifiedInputs({ ...changed })
                    }
                }
            }
        } else {
            formik.setFieldValue(`${subform}.${realEstateGeneralFields.COUNTY_ID}`, '')
            formik.setFieldValue(`${subform}.${realEstateGeneralFields.ISLAND_ID}`, '')
            if (inputs.mainForm) {
                if (locationGroupIndex > -1) {
                    const changed = { ...modifiedInputs }
                    
                    if (changed.mainForm.length > 0) {
                        changed.mainForm[locationGroupIndex].children[countyIndex].disabled = true
                        changed.mainForm[locationGroupIndex].children[islandIndex].disabled = true
                    }                    
                    setModifiedInputs({ ...changed })
                }
            }
        }
    }, [id, countryId])

    useEffect(() => {
        const changed = { ...modifiedInputs }
        if (!changed.mainForm?.length) return

        if (countyId) {
            dispatch(fetchLocationsCity(countyId))
            if (inputs.mainForm) {
                if (locationGroupIndex > -1) {
                    changed.mainForm[locationGroupIndex].children[cityIndex].disabled = false
                }
            }
        } else {
            formik.setFieldValue(`${subform}.${realEstateGeneralFields.CITY_ID}`, '')
            if (inputs.mainForm) {
                if (locationGroupIndex > -1) {
                    changed.mainForm[locationGroupIndex].children[cityIndex].disabled = true
                }
            }
        }
    }, [id, countyId])

    useEffect(() => {
        const changed = { ...modifiedInputs }
        if (!changed.mainForm?.length) return

        if (cityId) {
            dispatch(fetchLocationsWiderArea(cityId))
            if (inputs.mainForm) {
                if (locationGroupIndex > -1) {
                    changed.mainForm[locationGroupIndex].children[widerAreaIndex].disabled = false
                }
            }
        } else {
            formik.setFieldValue(`${subform}.${realEstateGeneralFields.WIDER_AREA_ID}`, '')
            if (inputs.mainForm) {
                if (locationGroupIndex > -1) {
                    changed.mainForm[locationGroupIndex].children[widerAreaIndex].disabled = true
                }
            }
        }
    }, [id, cityId])

    useEffect(() => {
        const changed = { ...modifiedInputs }
        if (!changed.mainForm?.length) return

        if (widerAreaId) {
            dispatch(fetchLocationsQuarter(widerAreaId))
            if (inputs.mainForm) {
                if (locationGroupIndex > -1) {
                    changed.mainForm[locationGroupIndex].children[quarterIndex].disabled = false
                }
            }
        } else {
            formik.setFieldValue(`${subform}.${realEstateGeneralFields.QUARTER_ID}`, '')
            if (inputs.mainForm) {
                if (locationGroupIndex > -1) {
                    changed.mainForm[locationGroupIndex].children[quarterIndex].disabled = true
                }
            }
        }
    }, [id, widerAreaId])

    useEffect(() => {
        priceField?.addEventListener('blur', () => blurCalculatedM2())
        areaField?.addEventListener('blur', () => blurCalculatedM2())

        const blurCalculatedM2 = () => {
            if (priceField.value && areaField.value) {
                const parsedPrice = parseFloat(priceField.value.toString().replace(/\./g, '').replace(/,/g, '.')).toFixed(2)
                const parsedArea = parseFloat(areaField.value.toString().replace(/\./g, '').replace(/,/g, '.')).toFixed(2)

                const calculatedM2 = parseFloat(parsedPrice / parsedArea).toFixed(2)
                formik.setFieldValue(`general.${realEstateGeneralFields.PRICE_M2}`, calculatedM2.toString().replace(/\./g, ','))
            }
        }
        return () => {
            priceField?.removeEventListener('blur', () => blurCalculatedM2())
            areaField?.removeEventListener('blur', () => blurCalculatedM2())
        }
    }, [priceField, areaField])

    useEffect(() => {
        if (projectId) {
            dispatch(
                fetchProjectNoStore(projectId, (data) => {
                    if (data) {
                        if (data.projectType?.id) {
                            formik.setFieldValue(`general.${requestFields.PROPERTY_SUPPLY_SOURCE_ID}`, data.projectType?.id)
                        }
                        if (data.country?.id) {
                            formik.setFieldValue(`general.${realEstateGeneralFields.COUNTRY_ID}`, data.country?.id)
                        }
                        if (data.county?.id) {
                            formik.setFieldValue(`general.${realEstateGeneralFields.COUNTY_ID}`, data.county?.id)
                        }
                        if (data.city?.id) {
                            formik.setFieldValue(`general.${realEstateGeneralFields.CITY_ID}`, data.city?.id)
                        }
                        if (data.widerArea?.id) {
                            formik.setFieldValue(`general.${realEstateGeneralFields.WIDER_AREA_ID}`, data.widerArea?.id)
                        }
                        if (data.quarter?.id) {
                            formik.setFieldValue(`general.${realEstateGeneralFields.QUARTER_ID}`, data.quarter?.id)
                        } /* else {
                            formik.setFieldValue(`general.${realEstateGeneralFields.QUARTER_ID}`, '')
                        } */
                        if (data.island?.id) {
                            formik.setFieldValue(`general.${realEstateGeneralFields.ISLAND_ID}`, data.island?.id)
                        } /* else {
                            formik.setFieldValue(`general.${realEstateGeneralFields.ISLAND_ID}`, '')
                        } */
                        if (data?.street) {
                            formik.setFieldValue(`general.${realEstateGeneralFields.STREET}`, data.street)
                        }
                        if (data?.streetNumber) {
                            formik.setFieldValue(`general.${realEstateGeneralFields.STREET_NUMBER}`, data.streetNumber)
                        }
                        if (data?.streetSync) {
                            formik.setFieldValue(`general.${realEstateGeneralFields.STREET_SYNC}`, data.streetSync)
                        }
                        if (data?.agencyCommission?.id) {
                            formik.setFieldValue(`general.${realEstateGeneralFields.AGENCY_COMMISSION_ID}`, data.agencyCommission?.id)
                        }
                    }
                })
            )
        }
    }, [projectId])

    if (inputs.mainForm) {
        const priceGroupIndex = inputs.mainForm?.findIndex((obj) => obj.name === 'priceGroup')

        if (priceGroupIndex !== -1) {
            const priceM2Index = inputs.mainForm[priceGroupIndex].children.findIndex((obj) => obj.name === realEstateGeneralFields.PRICE_M2)

            if (priceM2Index !== -1) {
                inputs.mainForm[priceGroupIndex].children[priceM2Index].handleChange = (value) => {
                    if (!userModifiedPriceM2) {
                        setUserModifiedPriceM2(true)
                    } else {
                        if (!value) {
                            setUserModifiedPriceM2(false)
                        }
                    }
                }
            }
        }
    }

    return (
        <FormMainContainer tabs={tabs} activeTab={activeTab} handleTabChange={handleTabChange}>
            {realEstateType && realEstateService ? (
                <AppPermissionsSwitcher permission="EditData" data={modifiedInputs?.mainForm} module={module}>
                    {({ newData }) => (
                        <RecursiveForm
                            inputs={newData}
                            subform={subform}
                            formik={formik}
                            selectOptions={selectOptions}
                            inputComponents={inputComponents}
                        />
                    )}
                </AppPermissionsSwitcher>
            ) : (
                <Row>
                    <Col>
                        <p className="text-center">Molimo odaberite tip i uslugu. </p>
                    </Col>
                </Row>
            )}
        </FormMainContainer>
    )
}

export default PropertyFormGeneralTab
