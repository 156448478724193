import { useFormik } from 'formik'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AccordionBody, AccordionHeader, AccordionItem, Button, UncontrolledAccordion } from 'reactstrap'
import * as Yup from 'yup'
import { formatDateTime } from '../../utils/general'
import DeleteModal from '../Common/DeleteModal'
import ErrorModal from '../Common/ErrorModal'
import Loader from '../Common/Loader'
import { useNoteFormSelectOptions } from '../Hooks/FormHooks'
import FormModal from '../Modals/FormModal'
import notes from '../../api/notes/hooks'
import { projectNoteInputs } from '../../common/forms/projects/inputs'
import { projectNoteValidation } from '../../common/forms/projects/validation'

const NotesViewAccordion = ({ id, currentAgentId, noteEntity = '' }) => {
    const { t } = useTranslation()
    const refreshToken = localStorage.getItem('refresh_token')

    const { isLoading: fetchingNotes, data: notesQuery } = notes.GetNotes.useQuery(id, noteEntity, refreshToken)
    const { mutate: updateMutate, status: updateStatus, reset: updateReset } = notes.CreateUpdateNote.useMutation(id, noteEntity, true)
    const { mutate: createMutate, status: createStatus, reset: createReset } = notes.CreateUpdateNote.useMutation(id, noteEntity)
    const { mutate: deleteMutate, status: deleteStatus, reset: deleteReset } = notes.DeleteNote.useMutation(id, noteEntity)

    const notesData = !fetchingNotes ? notesQuery : []

    const [note, setNote] = useState({ currentAgentId })
    const [noteModal, setNoteModal] = useState(false)
    const [noteIsEdit, setNoteIsEdit] = useState(false)
    const [noteDeleteModal, setNoteDeleteModal] = useState(false)
    const [noteErrorModal, setNoteErrorModal] = useState(false)

    const selectOptions = useNoteFormSelectOptions()

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: projectNoteValidation(note).initialValues ?? {},
        validationSchema: projectNoteValidation(note).validationSchema ?? Yup.object({}),
        onSubmit: (values) => {
            const submitValues = {
                ...values,
                [`${noteEntity}_id`]: id,
                noteEntity,
            }

            if (!noteIsEdit) {
                delete submitValues.id
                createMutate({ ...submitValues })
                return
            }

            updateMutate({ id: note.id, ...submitValues })
        },
    })

    const { setErrors } = formik

    const toggleNoteModal = () => {
        if (noteModal) {
            setNoteModal(false)
            setNote(null)
            setNoteIsEdit(false)
            setErrors(null)
        } else {
            setNoteModal(true)
        }
    }

    const onNoteClickUpdate = (id) => {
        setNote(notesData.find((x) => x.id === id))
        setNoteIsEdit(true)
        toggleNoteModal()
    }

    const onNoteClickDelete = (noteData) => {
        setNote(noteData)
        setNoteDeleteModal(true)
    }

    const handleDeleteNote = () => {
        deleteMutate(note.id)
        setNoteDeleteModal(false)
        setNote(null)
    }

    const reset = noteIsEdit
        ? (updateStatus === 'idle' ? false : updateReset) || deleteReset
        : (createStatus === 'idle' ? false : createReset) || deleteReset
    const status = noteIsEdit
        ? (updateStatus === 'idle' ? false : updateStatus) || deleteStatus
        : (createStatus === 'idle' ? false : createStatus) || deleteStatus

    if (status === 'success') {
        deleteStatus !== 'success' && toggleNoteModal()
        reset()
    }

    if (status === 'error') {
        setNoteErrorModal(true)
        reset()
    }

    return fetchingNotes && notesData?.length === 0 ? (
        <Loader />
    ) : (
        <>
            <DeleteModal show={noteDeleteModal} onDeleteClick={() => handleDeleteNote()} onCloseClick={() => setNoteDeleteModal(false)} />

            <UncontrolledAccordion defaultOpen={['1']} stayOpen>
                <AccordionItem className="mb-4 b-0">
                    <AccordionHeader targetId="1">
                        <h5 className="mb-0 me-4">{t('app.common.notes')}</h5>
                        <div
                            onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                toggleNoteModal()
                            }}
                            className="btn btn-label btn-add"
                        >
                            <i className="mdi mdi-plus label-icon align-middle fs-16 me-2"></i>
                            {t('app.common.addNote')}
                        </div>
                    </AccordionHeader>
                    <AccordionBody accordionId="1">
                        <div className="acitivity-timeline">
                            {notesData?.map((note, key) => (
                                <div className="acitivity-item py-3 d-flex" key={key}>
                                    <div className="flex-shrink-0 avatar-xs acitivity-avatar">
                                        <div className="avatar-title bg-primary text-white rounded-circle"> B </div>
                                    </div>
                                    <div className="flex-grow-1 ms-3">
                                        <div className="d-flex align-items-center justify-content-between mb-4">
                                            <div>
                                                {note.noteTitle && <h6 className="mb-1">{note.noteTitle}</h6>}
                                                {note.noteDate && <small className="mb-0 text-muted">{formatDateTime(note.noteDate)}</small>}
                                            </div>
                                            <ul className="list-inline hstack justify-content-center">
                                                <li>
                                                    <Button
                                                        title={t('button.edit')}
                                                        color="link"
                                                        className="text-edit"
                                                        onClick={() => onNoteClickUpdate(note.id)}
                                                    >
                                                        <i className="mdi mdi-pencil-outline fs-16"></i>
                                                    </Button>
                                                </li>
                                                <li className="list-inline-item">
                                                    <Button
                                                        title={t('button.delete')}
                                                        color="link"
                                                        className="text-danger"
                                                        onClick={() => {
                                                            onNoteClickDelete(note)
                                                        }}
                                                    >
                                                        <i className="mdi mdi-trash-can-outline fs-16"></i>
                                                    </Button>
                                                </li>
                                            </ul>
                                        </div>
                                        {note.note && <p className="text-secondary mb-2">{note.note}</p>}
                                    </div>
                                </div>
                            ))}

                            {!notesData?.length && !fetchingNotes && (
                                <div className="d-flex justify-content-center">{t('accordion.notes.message.info')}</div>
                            )}
                        </div>
                    </AccordionBody>
                </AccordionItem>
            </UncontrolledAccordion>
            <ErrorModal show={noteErrorModal} onCloseClick={() => setNoteErrorModal(false)} title="Uups!">
                <p>{t('modal.error.message')}</p>
            </ErrorModal>
            <FormModal
                isOpen={noteModal}
                toggle={toggleNoteModal}
                title={noteIsEdit ? t('modal.notes.edit') : t('app.common.addNote')}
                inputs={projectNoteInputs()}
                formik={formik}
                selectOptions={selectOptions}
            />
        </>
    )
}

export default NotesViewAccordion
