import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import { fetchPropertyAvailableEntitiesSuccess } from './actions';

const items = handleActions(
  {
    [fetchPropertyAvailableEntitiesSuccess]: (state, { payload }) => ({
      ...state,
      ...payload.reduce(
        (dict, item) => ({
          ...dict,
          [item]: item
        }),
        {}
      )
  })
  },
  {}
);

export default combineReducers({
  items
});
