import { generatePermissionsData } from './utils'

// handles info in detail view of Projects/Properties
export const setViewData = ({ agentPermissions, data, isAgentOwnership, isAgentOffice, module }) => {
    let newData = data
    let hasPermission = true
    const moduleName = module === 'properties' ? 'property' : 'project'

    const permissions = [
        { ruleName: `view.${moduleName}.widerArea`, dataName: 'widerArea' },
        { ruleName: `view.${moduleName}.quarter`, dataName: 'quarter' },
        { ruleName: `view.${moduleName}.street`, dataName: 'street' },
        { ruleName: `view.${moduleName}.owner`, dataName: 'ownerInfo' },
        { ruleName: 'view.property.registry', dataName: 'registry' },
        { ruleName: 'view.property.propertyLabel', dataName: 'propertyLabel' },
        { ruleName: 'view.property.priceClient', dataName: 'priceClient' },
    ]

    const viewPermissions = generatePermissionsData(permissions, agentPermissions, isAgentOwnership, isAgentOffice)
    const { registry, widerArea, quarter, street, propertyLabel, priceClient, ownerInfo } = viewPermissions
    
    const conditions = [
        { condition: registry, input: registryFormGroups },
        { condition: ownerInfo, input: ownerInfoFormGroups },
        { condition: widerArea, input: ['widerArea'] },
        { condition: quarter, input: ['quarter'] },
        { condition: street, input: ['street-streetNumber'] },
        { condition: propertyLabel, input: ['propertyLabel'] },
        { condition: priceClient, input: ['priceClient'] },
    ]

    let inputs = []
    conditions.forEach((obj) => {
        if (!obj.condition) {
            inputs.push(...obj.input)
        }
    })

    if (isAgentOwnership && !inputs.length) {
        return { newData, hasPermission }
    }

    if (inputs.length > 0) {
        const filterResult = filterViewData(newData, inputs)
        const noViewData = filterResult && !filterResult.length

        noViewData ? hasPermission = false : newData = filterResult
    }

    return { newData, hasPermission }
}

const filterViewData = (data, inputs) => {
    return data?.filter((obj) => !inputs.includes(obj.id))
}

const registryFormGroups = [
    'propertyMainRegister_propertyMainRegisterDepartment',
    'propertyRegistryMunicipality',
    'propertyRegistryInsert',
    'propertyRegistryParcelNo',
    'propertyRegistrySubinsert',
    'propertyRegistryDepartmentKpu_propertyRegistryDepartmentKpuSubinsert',
    'propertySubinsertKpu',
    'propertyPukDepartment',
    'propertyCadastralMunicipality',
    'propertyCadastralParcelNo',
    'propertyPossessorySheetNo',
]

const ownerInfoFormGroups = ['owner', 'address', 'phone', 'email']
