import { getIn } from 'formik'
import { useContext, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Row } from 'reactstrap'
import { realEstateGeneralFields } from '../../common/forms/generalRealEstate/fields'
import { fetchLocationsCity, fetchLocationsCounty, fetchLocationsIsland, fetchLocationsQuarter, fetchLocationsWiderArea } from '../../store/locations'
import AppPermissionsSwitcher from '../AppPermissionsSwitcher/AppPermissionsSwitcher'
import FormMainContainer from '../Common/FormMainContainer'
import FormSideContainer from '../Common/FormSideContainer'
import RecursiveForm from '../RecursiveForm'
import ProjectFormGeneralContext from './ProjectFormGeneralContext'

const ProjectFormGeneralTab = ({ children, ...props }) => {
    const id = getIn(props.formik?.values?.general, 'id')

    return (
        <ProjectFormGeneralContext.Provider
            value={{
                formik: props.formik,
                inputs: props.inputs,
                subform: props.subform,
                selectOptions: props.selectOptions,
                inputComponents: props.inputComponents,
                tabs: props.tabs,
                activeTab: props.activeTab,
                handleTabChange: props.handleTabChange,
                module: props.module,
                id,
            }}
        >
            <Row>{children}</Row>
        </ProjectFormGeneralContext.Provider>
    )
}

export const ProjectFormGeneralTabSide = (props) => {
    const { inputs, formik, subform, selectOptions, inputComponents, module } = useContext(ProjectFormGeneralContext)

    return (
        <FormSideContainer>
            <AppPermissionsSwitcher permission="EditData" data={inputs?.sideForm} module={module}>
                {({newData}) => (
                    <RecursiveForm
                        inputs={newData}
                        subform={subform}
                        formik={formik}
                        selectOptions={selectOptions}
                        inputComponents={inputComponents}
                    />
                )}
            </AppPermissionsSwitcher>
        </FormSideContainer>
    )
}

export const ProjectFormGeneralTabMain = ({ children, ...props }) => {
    const { inputs, formik, subform, selectOptions, inputComponents, tabs, activeTab, handleTabChange, id, module } =
        useContext(ProjectFormGeneralContext)

    const errors = formik.errors

    const dispatch = useDispatch()
    useEffect(() => {
        const countryId = getIn(formik?.values?.general, realEstateGeneralFields.COUNTRY_ID)
        if (countryId) {
            dispatch(fetchLocationsCounty(countryId))
            dispatch(fetchLocationsIsland(countryId))
        }
        const countyId = getIn(formik?.values?.general, realEstateGeneralFields.COUNTY_ID)
        if (countyId) {
            dispatch(fetchLocationsCity(countyId))
        }
        const cityId = getIn(formik?.values?.general, realEstateGeneralFields.CITY_ID)
        if (cityId) {
            dispatch(fetchLocationsWiderArea(cityId))
        }
        const widerAreaId = getIn(formik?.values?.general, realEstateGeneralFields.WIDER_AREA_ID)
        if (widerAreaId) {
            dispatch(fetchLocationsQuarter(widerAreaId))
        }
    }, [id])

    if (inputs.mainForm) {
        const locationGroupIndex = inputs.mainForm?.findIndex((obj) => obj.name === 'locationGroup')
        if (locationGroupIndex !== -1) {
            const countryIndex = inputs.mainForm[locationGroupIndex].children.findIndex((obj) => obj.name === realEstateGeneralFields.COUNTRY_ID)
            const countyIndex = inputs.mainForm[locationGroupIndex].children.findIndex((obj) => obj.name === realEstateGeneralFields.COUNTY_ID)
            const cityIndex = inputs.mainForm[locationGroupIndex].children.findIndex((obj) => obj.name === realEstateGeneralFields.CITY_ID)
            const widerAreaIndex = inputs.mainForm[locationGroupIndex].children.findIndex((obj) => obj.name === realEstateGeneralFields.WIDER_AREA_ID)
            if (countryIndex !== -1) {
                inputs.mainForm[locationGroupIndex].children[countryIndex].handleChange = (id) => dispatch(fetchLocationsCounty(id))
            }
            if (countyIndex !== -1) {
                const countyInput = inputs.mainForm[locationGroupIndex].children[countyIndex]
                countyInput.handleChange = (id) => dispatch(fetchLocationsCity(id))
            }
            if (cityIndex !== -1) {
                inputs.mainForm[locationGroupIndex].children[cityIndex].handleChange = (id) => dispatch(fetchLocationsWiderArea(id))
            }
            if (widerAreaIndex !== -1) {
                inputs.mainForm[locationGroupIndex].children[widerAreaIndex].handleChange = (id) => dispatch(fetchLocationsQuarter(id))
            }
        }
    }

    return (
        <FormMainContainer tabs={tabs} activeTab={activeTab} handleTabChange={handleTabChange} formik={formik}>
            <AppPermissionsSwitcher permission="EditData" data={inputs?.mainForm} module={module}>
                {({ newData }) => (
                    <RecursiveForm
                        inputs={newData}
                        subform={subform}
                        formik={formik}
                        selectOptions={selectOptions}
                        inputComponents={inputComponents}
                    />
                )}
            </AppPermissionsSwitcher>
        </FormMainContainer>
    )
}

export default ProjectFormGeneralTab
