import i18next from 'i18next'
import { fetchContactsAsyncInput } from '../../../../store/contacts'
import { renderCurrency } from '../../../../utils/property'
import { baseAsyncSelectInput, baseDateInput, basePriceInput, baseSelectInput, baseTextInput } from '../../fieldTypes'
import { propertyTransactionsFields } from '../fields'

export const SLOtransactionFieldTypes = (appSettings, isRequiredAccordingToStatus) => {
    const { t } = i18next

    return {
        [propertyTransactionsFields.PROPERTY_STATUS_ID]: {
            ...baseSelectInput,
            name: propertyTransactionsFields.PROPERTY_STATUS_ID,
            label: t('app.common.status'),
            required: true,
            colProps: {
                sm: 12,
            },
        },
        [propertyTransactionsFields.RESERVATION_DATE]: {
            ...baseDateInput,
            name: propertyTransactionsFields.RESERVATION_DATE,
            label: t('form.property.transaction.reservationDate.label'),
            tooltip: t('form.property.transaction.reservationDate.tooltip'),
            colProps: {
                sm: 12,
            },
        },
        [propertyTransactionsFields.CONTACT_ID]: {
            ...baseAsyncSelectInput,
            name: propertyTransactionsFields.CONTACT_ID,
            label: t('form.property.transaction.contact'),
            tooltip: t('form.property.transaction.contact.tooltip'),
            action: fetchContactsAsyncInput,
            noLimit: true,
            criteria: ['name'],
            colProps: {
                sm: 6,
            },
            required: isRequiredAccordingToStatus,
        },
        [propertyTransactionsFields.PRICE_FINAL]: {
            ...basePriceInput,
            name: propertyTransactionsFields.PRICE_FINAL,
            label: t('form.property.transaction.priceFinal', { currency: renderCurrency(appSettings?.currency) }),
            required: isRequiredAccordingToStatus,
        },
        [propertyTransactionsFields.COMMISION_SELLER]: {
            ...basePriceInput,
            name: propertyTransactionsFields.COMMISION_SELLER,
            label: t('form.property.transaction.comissionSeller'),
        },
        [propertyTransactionsFields.COMMISION_SELLER_TYPE_ID]: {
            ...baseSelectInput,
            name: propertyTransactionsFields.COMMISION_SELLER_TYPE_ID,
            label: t('form.property.transaction.comissionSellerType'),
        },
        [propertyTransactionsFields.PROPERTY_TRANSACTION_AGENT_ID]: {
            ...baseSelectInput,
            name: propertyTransactionsFields.PROPERTY_TRANSACTION_AGENT_ID,
            label: t('app.common.agent'),
        },
        [propertyTransactionsFields.COMMISION_BUYER]: {
            ...basePriceInput,
            name: propertyTransactionsFields.COMMISION_BUYER,
            label: t('form.property.transaction.comissionBuyer'),
        },
        [propertyTransactionsFields.COMMISION_BUYER_TYPE_ID]: {
            ...baseSelectInput,
            name: propertyTransactionsFields.COMMISION_BUYER_TYPE_ID,
            label: t('form.property.transaction.comissionSellerType'),
        },
        [propertyTransactionsFields.ADVANCE_AMOUNT]: {
            ...basePriceInput,
            name: propertyTransactionsFields.ADVANCE_AMOUNT,
            label: t('form.property.transaction.advanceAmount', { currency: renderCurrency(appSettings?.currency) }),
        },
        [propertyTransactionsFields.ADVANCE_DATE]: {
            ...baseDateInput,
            name: propertyTransactionsFields.ADVANCE_DATE,
            label: t('form.property.transaction.advanceDate'),
        },
        [propertyTransactionsFields.CONCLUSION_PLACE]: {
            ...baseTextInput,
            name: propertyTransactionsFields.CONCLUSION_PLACE,
            label: t('app.common.conclusionPlace'),
            required: isRequiredAccordingToStatus,
        },
        [propertyTransactionsFields.CONCLUSION_DATE]: {
            ...baseDateInput,
            name: propertyTransactionsFields.CONCLUSION_DATE,
            label: t('app.common.conclusionDate'),
            required: isRequiredAccordingToStatus,
        },
        [propertyTransactionsFields.FINAL_PAYMENT_DATE]: {
            ...baseDateInput,
            name: propertyTransactionsFields.FINAL_PAYMENT_DATE,
            label: t('form.property.transaction.finalPaymentDate'),
        },
        [propertyTransactionsFields.PROPERTY_TRANSACTION_AGENT_ID]: {
            ...baseSelectInput,
            name: propertyTransactionsFields.PROPERTY_TRANSACTION_AGENT_ID,
            label: t('app.common.agent'),
        },
        [propertyTransactionsFields.PROPERTY_CADASTRAL_MUNCIPALITY]: {
            ...baseSelectInput,
            name: propertyTransactionsFields.PROPERTY_CADASTRAL_MUNCIPALITY,
            label: t('app.common.cadastralMunicipality'),
        },
        [propertyTransactionsFields.PROPERTY_CADASTRAL_PARCEL_NO]: {
            ...baseTextInput,
            name: propertyTransactionsFields.PROPERTY_CADASTRAL_PARCEL_NO,
            label: t('app.common.property.cadastralParcel'),
        },
        [propertyTransactionsFields.PROPERTY_BUILDING_NUMBER]: {
            ...baseTextInput,
            name: propertyTransactionsFields.PROPERTY_BUILDING_NUMBER,
            label: t('app.property.transaction.buildingNumber'),
        },
        [propertyTransactionsFields.PROPERTY_BUILDING_PART_NUMBER]: {
            ...baseTextInput,
            name: propertyTransactionsFields.PROPERTY_BUILDING_PART_NUMBER,
            label: t('app.property.transaction.buildingPartNumber'),
        },
    }
}
