import { useFormik } from 'formik'
import queryString from 'query-string'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { Alert, Button, Card, CardBody, Col, Container, Form, FormFeedback, Input, Label, Row, Spinner } from 'reactstrap'
import * as Yup from 'yup'
import logoLight from '../../assets/images/dimedia-crm-logo-white.png'
import { PASSWORD_REGEX } from '../../common/constants'
import { resetPassword, resetPasswordClear } from '../../store/auth'
import { selectResetPasswordFailure, selectResetPasswordLoading, selectResetPasswordSuccess } from '../../store/auth/selectors'
import ObscurePassword from './ObscurePassword'
import ParticlesAuth from './ParticlesAuth'
import { generateRandomPassword } from './utils'

const ResetPassword = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const { search } = useLocation()
    const history = useHistory()
    const searchParams = queryString.parse(search)
    const passwordInputRef = useRef(null)

    const [showPasswordSuggestion, setShowPasswordSuggestion] = useState(false)
    const [suggestedPassword, setSuggestedPassword] = useState('')
    const [passwordFormatError, setPasswordFormatError] = useState(false)
    const [obscurePassword, setObscurePassword] = useState(true)

    const { isLoading, isSuccess, isFailure } = useSelector((state) => {
        return {
            isFailure: selectResetPasswordFailure(state),
            isSuccess: selectResetPasswordSuccess(state),
            isLoading: selectResetPasswordLoading(state),
        }
    })

    const generatePasswordFormatError = ({ password }) => {
        const errors = {}
        const isValidPassword = passwordRegex.test(password)

        if (!isValidPassword) {
            errors.format = t('form.register.error.passwordFormat')
        }

        return errors
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            token: '',
            password: '',
            confirmPassword: '',
        },
        validationSchema: Yup.object({
            password: Yup.string().required(t('app.common.password.error')),
            confirmPassword: Yup.string().oneOf([Yup.ref('password')], t('form.reset.error.confirmPassword')),
        }),

        validate: generatePasswordFormatError,
        onSubmit: (values) => {
            values.token = searchParams.token
            dispatch(resetPassword(values))
        },
    })

    const passwordRegex = useMemo(() => PASSWORD_REGEX, [])

    useEffect(() => {
        if (!suggestedPassword) {
            const password = generateRandomPassword(passwordRegex)
            setSuggestedPassword(password)
        }

        setShowPasswordSuggestion(formik.values.password.length > 0 && formik.values.password.length <= 2)
    }, [formik.values.password, passwordRegex, suggestedPassword])

    useEffect(() => {
        if (isSuccess) {
            //dispatch(resetPasswordClear())
            history.push('/login')
        }
    }, [dispatch, history, isSuccess])

    const handleGenerateNewPassword = () => {
        setSuggestedPassword(generateRandomPassword(passwordRegex))
    }

    const acceptPasswordSuggestion = () => {
        formik.setFieldValue('password', suggestedPassword)
        formik.setFieldValue('confirmPassword', suggestedPassword)
        setShowPasswordSuggestion(false)
    }

    useEffect(() => {
        const formikPassword = formik.values.password
        const isPasswordValid = passwordRegex.test(formikPassword)

        if (!isPasswordValid && formikPassword.length) {
            setPasswordFormatError(true)
        } else {
            setPasswordFormatError(false)
        }
    }, [formik.values.password, passwordRegex])

    if (!searchParams.token) return history.push('/login')

    return (
        <ParticlesAuth>
            <div className="auth-page-content">
                <Container>
                    <Row className="mt-4">
                        <Col lg={12}>
                            <div className="text-center mt-5 mb-5  text-white-50">
                                <div>
                                    <Link to="/" className="d-inline-block auth-logo">
                                        <img src={logoLight} alt="" />
                                    </Link>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="mt-5">
                                <CardBody className="p-4">
                                    <div className="text-center mt-2">
                                        <h5 className="text-primary fs-16">{t('form.resetPassword.title')}</h5>
                                    </div>
                                    <Alert className="border-0 alert-warning text-center mb-2 mx-2" role="alert">
                                        {t('form.resetPassword.message')}
                                    </Alert>
                                    <div className="p-2">
                                        {isFailure ? (
                                            <Alert color="danger" style={{ marginTop: '13px' }}>
                                                {t('modal.error.message')}
                                            </Alert>
                                        ) : null}

                                        {passwordFormatError ? (
                                            <Alert color="danger" className="text-center">
                                                {formik.errors.format}
                                            </Alert>
                                        ) : null}

                                        <Form
                                            onSubmit={(e) => {
                                                e.preventDefault()
                                                formik.handleSubmit()
                                                return false
                                            }}
                                            noValidate
                                        >
                                            <div className="mb-3">
                                                <Label htmlFor="password" className="form-label">
                                                    {t('app.common.password')}
                                                </Label>
                                                <div className="position-relative auth-pass-inputgroup mb-3">
                                                    <Input
                                                        innerRef={passwordInputRef}
                                                        name="password"
                                                        type={obscurePassword ? 'password' : 'text'}
                                                        placeholder={t('app.common.password.placeholder')}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.password || ''}
                                                        invalid={formik.touched.password && formik.errors.password ? true : false}
                                                    />

                                                    {showPasswordSuggestion ? (
                                                        <div className="form-control d-flex cursor-pointer">
                                                            <Button color="none" onClick={acceptPasswordSuggestion} className="w-50 m-0 p-0">
                                                                {suggestedPassword}
                                                            </Button>
                                                            <Button
                                                                color="none"
                                                                onClick={handleGenerateNewPassword}
                                                                className="w-50 m-0 p-0 text-muted"
                                                            >
                                                                {t('button.generateAgain')}
                                                            </Button>
                                                        </div>
                                                    ) : null}

                                                    {formik.touched.password && formik.errors.password ? (
                                                        <FormFeedback type="invalid" key={JSON.stringify(formik.errors.password)}>
                                                            <div>{formik.errors.password}</div>
                                                        </FormFeedback>
                                                    ) : null}

                                                    <ObscurePassword
                                                        errors={formik.errors}
                                                        obscurePassword={obscurePassword}
                                                        setObscurePassword={setObscurePassword}
                                                    />
                                                </div>
                                            </div>

                                            <div className="mb-3">
                                                <Label htmlFor="confirmPassword" className="form-label">
                                                    {t('form.register.field.confirmPassword')}
                                                </Label>
                                                <Input
                                                    name="confirmPassword"
                                                    type={obscurePassword ? 'password' : 'text'}
                                                    placeholder={t('form.register.placeholder.confirmPassword')}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.confirmPassword || ''}
                                                    invalid={formik.touched.confirmPassword && formik.errors.confirmPassword ? true : false}
                                                />
                                                {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                                                    <FormFeedback type="invalid">
                                                        <div>{formik.errors.confirmPassword}</div>
                                                    </FormFeedback>
                                                ) : null}
                                            </div>

                                            <div className="text-center mt-4">
                                                <button className="btn btn-success w-100" type="submit">
                                                    {isLoading ? <Spinner size="sm" /> : t('button.send')}
                                                </button>
                                            </div>
                                        </Form>
                                    </div>
                                </CardBody>
                            </Card>
                            <div className="mt-4 text-center">
                                <p className="mb-0">
                                    <Link to="/login" className="text-primary text-decoration-underline">
                                        {t('app.common.login')}
                                    </Link>
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </ParticlesAuth>
    )
}

export default ResetPassword
