import { createAction } from 'redux-actions'

const ENTITY = 'CONTACTVALUES'
export const stateKey = ENTITY.toLowerCase()

const AVAILABLEENTITIES = 'AVAILABLEENTITIES'
const COMPANYSIZE = 'COMPANYSIZE'
const COREBUSINESS = 'COREBUSINESS'
const DEFINITION = 'DEFINITION'
const COMPANYDEFINITION = 'COMPANYDEFINITION'
const FINANCING = 'FINANCING'
const DESCRIPTION = 'DESCRIPTION'
const EMAILTYPE = 'EMAILTYPE'
const GENDER = 'GENDER'
const JOBTITLE = 'JOBTITLE'
const LEGALFORM = 'LEGALFORM'
const MARTIALSTATUS = 'MARTIALSTATUS'
const PHONETYPE = 'PHONETYPE'
const PRIORITY = 'PRIORITY'
const QUERYCATEGORY = 'QUERYCATEGORY'
const STATUS = 'STATUS'
const TITLE = 'TITLE'
const SOURCE = 'SOURCE'

const PROFILE = 'PROFILE'
const PROFILEDURATION = 'PROFILEDURATION'
const PROFILESTATUS = 'PROFILESTATUS'
const PROFILEREMARK = 'PROFILEREMARK'
const PROFILESERVICE = 'PROFILESERVICE'
const COUNTRY = 'COUNTRY'

export const fetchContactValuesAvailableEntities = createAction(`${ENTITY}/${AVAILABLEENTITIES}/FETCH`)
export const fetchContactValuesAvailableEntitiesRequest = createAction(`${ENTITY}/${AVAILABLEENTITIES}/FETCH_REQUEST`)
export const fetchContactValuesAvailableEntitiesSuccess = createAction(`${ENTITY}/${AVAILABLEENTITIES}/FETCH_SUCCESS`)
export const fetchContactValuesAvailableEntitiesFailure = createAction(`${ENTITY}/${AVAILABLEENTITIES}/FETCH_FAILURE`)

export const fetchContactValuesCompanySize = createAction(`${ENTITY}/${COMPANYSIZE}/FETCH`)
export const fetchContactValuesCompanySizeRequest = createAction(`${ENTITY}/${COMPANYSIZE}/FETCH_REQUEST`)
export const fetchContactValuesCompanySizeSuccess = createAction(`${ENTITY}/${COMPANYSIZE}/FETCH_SUCCESS`)
export const fetchContactValuesCompanySizeFailure = createAction(`${ENTITY}/${COMPANYSIZE}/FETCH_FAILURE`)

export const fetchContactValuesCoreBusiness = createAction(`${ENTITY}/${COREBUSINESS}/FETCH`)
export const fetchContactValuesCoreBusinessRequest = createAction(`${ENTITY}/${COREBUSINESS}/FETCH_REQUEST`)
export const fetchContactValuesCoreBusinessSuccess = createAction(`${ENTITY}/${COREBUSINESS}/FETCH_SUCCESS`)
export const fetchContactValuesCoreBusinessFailure = createAction(`${ENTITY}/${COREBUSINESS}/FETCH_FAILURE`)

export const fetchContactValuesDefinition = createAction(`${ENTITY}/${DEFINITION}/FETCH`)
export const fetchContactValuesDefinitionRequest = createAction(`${ENTITY}/${DEFINITION}/FETCH_REQUEST`)
export const fetchContactValuesDefinitionSuccess = createAction(`${ENTITY}/${DEFINITION}/FETCH_SUCCESS`)
export const fetchContactValuesDefinitionFailure = createAction(`${ENTITY}/${DEFINITION}/FETCH_FAILURE`)

export const fetchContactValuesCompanyDefinition = createAction(`${ENTITY}/${COMPANYDEFINITION}/FETCH`)
export const fetchContactValuesCompanyDefinitionRequest = createAction(`${ENTITY}/${COMPANYDEFINITION}/FETCH_REQUEST`)
export const fetchContactValuesCompanyDefinitionSuccess = createAction(`${ENTITY}/${COMPANYDEFINITION}/FETCH_SUCCESS`)
export const fetchContactValuesCompanyDefinitionFailure = createAction(`${ENTITY}/${COMPANYDEFINITION}/FETCH_FAILURE`)

export const fetchContactValuesDescription = createAction(`${ENTITY}/${DESCRIPTION}/FETCH`)
export const fetchContactValuesDescriptionRequest = createAction(`${ENTITY}/${DESCRIPTION}/FETCH_REQUEST`)
export const fetchContactValuesDescriptionSuccess = createAction(`${ENTITY}/${DESCRIPTION}/FETCH_SUCCESS`)
export const fetchContactValuesDescriptionFailure = createAction(`${ENTITY}/${DESCRIPTION}/FETCH_FAILURE`)

export const fetchContactValuesEmailType = createAction(`${ENTITY}/${EMAILTYPE}/FETCH`)
export const fetchContactValuesEmailTypeRequest = createAction(`${ENTITY}/${EMAILTYPE}/FETCH_REQUEST`)
export const fetchContactValuesEmailTypeSuccess = createAction(`${ENTITY}/${EMAILTYPE}/FETCH_SUCCESS`)
export const fetchContactValuesEmailTypeFailure = createAction(`${ENTITY}/${EMAILTYPE}/FETCH_FAILURE`)

export const fetchContactValuesGender = createAction(`${ENTITY}/${GENDER}/FETCH`)
export const fetchContactValuesGenderRequest = createAction(`${ENTITY}/${GENDER}/FETCH_REQUEST`)
export const fetchContactValuesGenderSuccess = createAction(`${ENTITY}/${GENDER}/FETCH_SUCCESS`)
export const fetchContactValuesGenderFailure = createAction(`${ENTITY}/${GENDER}/FETCH_FAILURE`)

export const fetchContactValuesJobTitle = createAction(`${ENTITY}/${JOBTITLE}/FETCH`)
export const fetchContactValuesJobTitleRequest = createAction(`${ENTITY}/${JOBTITLE}/FETCH_REQUEST`)
export const fetchContactValuesJobTitleSuccess = createAction(`${ENTITY}/${JOBTITLE}/FETCH_SUCCESS`)
export const fetchContactValuesJobTitleFailure = createAction(`${ENTITY}/${JOBTITLE}/FETCH_FAILURE`)

export const fetchContactValuesLegalForm = createAction(`${ENTITY}/${LEGALFORM}/FETCH`)
export const fetchContactValuesLegalFormRequest = createAction(`${ENTITY}/${LEGALFORM}/FETCH_REQUEST`)
export const fetchContactValuesLegalFormSuccess = createAction(`${ENTITY}/${LEGALFORM}/FETCH_SUCCESS`)
export const fetchContactValuesLegalFormFailure = createAction(`${ENTITY}/${LEGALFORM}/FETCH_FAILURE`)

export const fetchContactValuesMartialStatus = createAction(`${ENTITY}/${MARTIALSTATUS}/FETCH`)
export const fetchContactValuesMartialStatusRequest = createAction(`${ENTITY}/${MARTIALSTATUS}/FETCH_REQUEST`)
export const fetchContactValuesMartialStatusSuccess = createAction(`${ENTITY}/${MARTIALSTATUS}/FETCH_SUCCESS`)
export const fetchContactValuesMartialStatusFailure = createAction(`${ENTITY}/${MARTIALSTATUS}/FETCH_FAILURE`)

export const fetchContactValuesPhoneType = createAction(`${ENTITY}/${PHONETYPE}/FETCH`)
export const fetchContactValuesPhoneTypeRequest = createAction(`${ENTITY}/${PHONETYPE}/FETCH_REQUEST`)
export const fetchContactValuesPhoneTypeSuccess = createAction(`${ENTITY}/${PHONETYPE}/FETCH_SUCCESS`)
export const fetchContactValuesPhoneTypeFailure = createAction(`${ENTITY}/${PHONETYPE}/FETCH_FAILURE`)

export const fetchContactValuesPriority = createAction(`${ENTITY}/${PRIORITY}/FETCH`)
export const fetchContactValuesPriorityRequest = createAction(`${ENTITY}/${PRIORITY}/FETCH_REQUEST`)
export const fetchContactValuesPrioritySuccess = createAction(`${ENTITY}/${PRIORITY}/FETCH_SUCCESS`)
export const fetchContactValuesPriorityFailure = createAction(`${ENTITY}/${PRIORITY}/FETCH_FAILURE`)

export const fetchContactValuesSource = createAction(`${ENTITY}/${SOURCE}/FETCH`)
export const fetchContactValuesSourceRequest = createAction(`${ENTITY}/${SOURCE}/FETCH_REQUEST`)
export const fetchContactValuesSourceSuccess = createAction(`${ENTITY}/${SOURCE}/FETCH_SUCCESS`)
export const fetchContactValuesSourceFailure = createAction(`${ENTITY}/${SOURCE}/FETCH_FAILURE`)

export const fetchContactValuesQueryCategory = createAction(`${ENTITY}/${QUERYCATEGORY}/FETCH`)
export const fetchContactValuesQueryCategoryRequest = createAction(`${ENTITY}/${QUERYCATEGORY}/FETCH_REQUEST`)
export const fetchContactValuesQueryCategorySuccess = createAction(`${ENTITY}/${QUERYCATEGORY}/FETCH_SUCCESS`)
export const fetchContactValuesQueryCategoryFailure = createAction(`${ENTITY}/${QUERYCATEGORY}/FETCH_FAILURE`)

export const fetchContactValuesStatus = createAction(`${ENTITY}/${STATUS}/FETCH`)
export const fetchContactValuesStatusRequest = createAction(`${ENTITY}/${STATUS}/FETCH_REQUEST`)
export const fetchContactValuesStatusSuccess = createAction(`${ENTITY}/${STATUS}/FETCH_SUCCESS`)
export const fetchContactValuesStatusFailure = createAction(`${ENTITY}/${STATUS}/FETCH_FAILURE`)

export const fetchContactValuesTitle = createAction(`${ENTITY}/${TITLE}/FETCH`)
export const fetchContactValuesTitleRequest = createAction(`${ENTITY}/${TITLE}/FETCH_REQUEST`)
export const fetchContactValuesTitleSuccess = createAction(`${ENTITY}/${TITLE}/FETCH_SUCCESS`)
export const fetchContactValuesTitleFailure = createAction(`${ENTITY}/${TITLE}/FETCH_FAILURE`)

export const fetchContactValuesFinancing = createAction(`${ENTITY}/${FINANCING}/FETCH`)
export const fetchContactValuesFinancingRequest = createAction(`${ENTITY}/${FINANCING}/FETCH_REQUEST`)
export const fetchContactValuesFinancingSuccess = createAction(`${ENTITY}/${FINANCING}/FETCH_SUCCESS`)
export const fetchContactValuesFinancingFailure = createAction(`${ENTITY}/${FINANCING}/FETCH_FAILURE`)

export const fetchContactValuesProfile = createAction(`${ENTITY}/${PROFILE}/FETCH`)
export const fetchContactValuesProfileRequest = createAction(`${ENTITY}/${PROFILE}/FETCH_REQUEST`)
export const fetchContactValuesProfileSuccess = createAction(`${ENTITY}/${PROFILE}/FETCH_SUCCESS`)
export const fetchContactValuesProfileFailure = createAction(`${ENTITY}/${PROFILE}/FETCH_FAILURE`)

export const fetchContactValuesProfileDuration = createAction(`${ENTITY}/${PROFILEDURATION}/FETCH`)
export const fetchContactValuesProfileDurationRequest = createAction(`${ENTITY}/${PROFILEDURATION}/FETCH_REQUEST`)
export const fetchContactValuesProfileDurationSuccess = createAction(`${ENTITY}/${PROFILEDURATION}/FETCH_SUCCESS`)
export const fetchContactValuesProfileDurationFailure = createAction(`${ENTITY}/${PROFILEDURATION}/FETCH_FAILURE`)

export const fetchContactValuesProfileStatus = createAction(`${ENTITY}/${PROFILESTATUS}/FETCH`)
export const fetchContactValuesProfileStatusRequest = createAction(`${ENTITY}/${PROFILESTATUS}/FETCH_REQUEST`)
export const fetchContactValuesProfileStatusSuccess = createAction(`${ENTITY}/${PROFILESTATUS}/FETCH_SUCCESS`)
export const fetchContactValuesProfileStatusFailure = createAction(`${ENTITY}/${PROFILESTATUS}/FETCH_FAILURE`)

export const fetchContactValuesProfileRemark = createAction(`${ENTITY}/${PROFILEREMARK}/FETCH`)
export const fetchContactValuesProfileRemarkRequest = createAction(`${ENTITY}/${PROFILEREMARK}/FETCH_REQUEST`)
export const fetchContactValuesProfileRemarkSuccess = createAction(`${ENTITY}/${PROFILEREMARK}/FETCH_SUCCESS`)
export const fetchContactValuesProfileRemarkFailure = createAction(`${ENTITY}/${PROFILEREMARK}/FETCH_FAILURE`)

export const fetchContactValuesProfileService = createAction(`${ENTITY}/${PROFILESERVICE}/FETCH`)
export const fetchContactValuesProfileServiceRequest = createAction(`${ENTITY}/${PROFILESERVICE}/FETCH_REQUEST`)
export const fetchContactValuesProfileServiceSuccess = createAction(`${ENTITY}/${PROFILESERVICE}/FETCH_SUCCESS`)
export const fetchContactValuesProfileServiceFailure = createAction(`${ENTITY}/${PROFILESERVICE}/FETCH_FAILURE`)

export const fetchContactValuesCountry = createAction(`${ENTITY}/${COUNTRY}/FETCH`)
export const fetchContactValuesCountryRequest = createAction(`${ENTITY}/${COUNTRY}/FETCH_REQUEST`)
export const fetchContactValuesCountrySuccess = createAction(`${ENTITY}/${COUNTRY}/FETCH_SUCCESS`)
export const fetchContactValuesCountryFailure = createAction(`${ENTITY}/${COUNTRY}/FETCH_FAILURE`)
