import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { selectAgentIdFromItem, selectAgentOfficeIdFromItem } from '../../store/agents/selectors'
import { selectCurrentUser } from '../../store/auth/selectors'
import { selectProperty } from '../../store/properties'
import {
    setAllowedAgents,
    setAllowedPermissionTabs,
    setAllowedPhotos,
    setChangeAgent,
    setEditData,
    setGoogleMaps,
    setModulePermissions,
    setNavigation,
    setPropertyStatus,
    setViewData,
} from './utils/utils'

// use this component to turn on/off application permissions
const AppPermissionsSwitcher = ({ children, ...props }) => {
    const { id } = useParams()

    let { permission, module, itemAgentId, itemAgentOfficeId, activityAgentId, documentAgentId } = props

    module = module?.pathname?.includes('/') ? module.pathname.split('/')[1] || module : module

    const { agent, agentOwnershipId, agentOfficeId, propertyStatus } = useSelector((state) => ({
        agent: selectCurrentUser(state),
        agentOwnershipId: selectAgentIdFromItem(state, module, id),
        agentOfficeId: selectAgentOfficeIdFromItem(state, module, id),
        propertyStatus: selectProperty(state)?.propertyStatus,
    }))

    const { agentPermissions, id: currentAgentId, agencyOffice: currentOffice } = agent ?? []

    // checks for agent ownership over item (is item created by current agent)
    const projectsPropertiesAgentViewCheck = Array.isArray(agentOwnershipId) && agentOwnershipId?.some((obj) => obj.id === currentAgentId)
    const contactsCompaniesAgentViewCheck = agentOwnershipId === currentAgentId
    const listAgentCheck = itemAgentId === currentAgentId
    const activityAgentCheck = activityAgentId === currentAgentId
    const documentAgentCheck = documentAgentId === currentAgentId
    const isAgentOwnership =
        listAgentCheck || projectsPropertiesAgentViewCheck || contactsCompaniesAgentViewCheck || activityAgentCheck || documentAgentCheck

    // checks for agent office (is item created by agent of same office)
    const projectsPropertiesOfficeViewCheck = Array.isArray(agentOfficeId) && agentOfficeId?.some((obj) => obj.id === currentOffice?.id)
    const contactsCompaniesOfficeViewCheck = agentOfficeId === currentOffice?.id
    const listOfficeCheck = itemAgentOfficeId === currentOffice?.id
    const documentOfficeCheck = itemAgentOfficeId === currentOffice?.id
    const isAgentOffice = listOfficeCheck || projectsPropertiesOfficeViewCheck || contactsCompaniesOfficeViewCheck || documentOfficeCheck

    const permissionProps = {
        ...props,
        agentPermissions,
        isAgentOwnership,
        isAgentOffice,
        currentAgentId,
        currentOffice,
        module,
        propertyStatus,
    }
    let newProps = {}

    switch (permission) {
        case 'ModulePermissions':
            newProps = setModulePermissions(permissionProps)
            break
        case 'Navigation':
            newProps = setNavigation(permissionProps)
            break
        case 'ViewData':
            newProps = setViewData(permissionProps)
            break
        case 'EditData':
            newProps = setEditData(permissionProps)
            break
        case 'AllowedTabs':
            newProps = setAllowedPermissionTabs(permissionProps)
            break
        case 'AllowedPhotos':
            newProps = setAllowedPhotos(permissionProps)
            break
        case 'AllowedAgents':
            newProps = setAllowedAgents(permissionProps)
            break
        case 'GoogleMaps':
            newProps = setGoogleMaps(permissionProps)
            break
        case 'PropertyStatus':
            newProps = setPropertyStatus(permissionProps)
            break
        case 'ChangeAgent':
            newProps = setChangeAgent(permissionProps)
            break
        default:
            console.error(`No application permission defined for: ${permission}`)
    }

    return children(newProps)
}

export default AppPermissionsSwitcher
