import { useSelector } from 'react-redux'
import { selectCurrentUser } from '../../store/auth/selectors'
import { action } from '../AppPermissionsSwitcher/constants/actions'
import { setModulePermissions } from '../AppPermissionsSwitcher/utils/module'

// React Table header checkbox is not skipping disabled checkboxes by default
// this function modifies default behaviour so disabled checkboxes are skipped on select all
export const getCheckboxProps = (toggleRowSelected, isAllPageRowsSelected, page) => {
    const modifiedOnChange = (event) => {
        page.forEach((row) => {
            !row.original.disabled && toggleRowSelected(row.id, event.currentTarget.checked)
        })
    }

    // Count number of selectable and selected rows in the current page
    // to determine the state of select all checkbox
    let selectableRowsInCurrentPage = 0
    let selectedRowsInCurrentPage = 0
    page.forEach((row) => {
        row.isSelected && selectedRowsInCurrentPage++
        !row.original.disabled && selectableRowsInCurrentPage++
    })

    // If there are no selectable rows in the current page
    // select all checkbox will be disabled
    const disabled = selectableRowsInCurrentPage === 0
    const checked = (isAllPageRowsSelected || selectableRowsInCurrentPage === selectedRowsInCurrentPage) && !disabled

    return { onChange: modifiedOnChange, checked, disabled }
}

// formats table data according to certain permission level
// adds "disabled" property for table checkboxes
export const useFormatTableData = (data, module) => {
    const { currentAgentId, agentPermissions, currentOfficeId } = useSelector((state) => ({
        agentPermissions: selectCurrentUser(state).agentPermissions,
        currentAgentId: selectCurrentUser(state).id,
        currentOfficeId: selectCurrentUser(state).agencyOffice.id,
    }))

    return data?.map((obj) => {
        // Projects/Properties can have multiple agents added (array), Contact/Companies can be added by only one agent
        const isAgentOwnership = Array.isArray(obj.agent) ? obj.agent?.[0].id === currentAgentId : obj.agent?.id === currentAgentId
        const isAgentOffice = Array.isArray(obj.agent)
            ? obj.agent?.[0].agencyOffice.id === currentOfficeId
            : obj.agent?.agencyOffice.id === currentOfficeId

        const { hasModulePermission } = setModulePermissions({ agentPermissions, isAgentOwnership, isAgentOffice, module, action: action.delete })

        return {
            ...obj,
            disabled: !hasModulePermission,
        }
    })
}
