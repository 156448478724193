import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { projectGeneralViewData } from "../common/views/projectViewData";
import { selectApplicationAreaUnit, selectApplicationCurrency, selectSettingsDefaultLanguage } from "../store/settings/selectors";

export const useProjectGeneralViewData = (data) => {
    const [viewData, setViewData ] = useState(null);

    const appSettings = useSelector((state) => ({
        currency: selectApplicationCurrency(state),
        lang: selectSettingsDefaultLanguage(state),
        areaUnit: selectApplicationAreaUnit(state)
    }));

    useEffect(() => {
       setViewData(projectGeneralViewData(data, appSettings))
    }, [data])

    return viewData;
}