import React, { useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import { Button, Card, CardBody, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from 'reactstrap'
import AppPermissionsSwitcher from '../../../Components/AppPermissionsSwitcher/AppPermissionsSwitcher'
import DeleteModal from '../../../Components/Common/DeleteModal'
import PageTitle from '../../../Components/Common/PageTitle'
import TableContainer from '../../../Components/Table/TableContainer'
import { useFormatTableData } from '../../../Components/Table/utils'
import { MODAL_TYPES } from '../../../common/constants'
import { useLocationChange } from '../../../hooks'
import { useCurrentUser } from '../../../hooks/useCurrentUser'
import { fetchAgents } from '../../../store/agents/actions'
import { selectCurrentUserSettings } from '../../../store/auth/selectors'
import { showModal } from '../../../store/modal'
import { deleteProject, deleteProjectMultiple, fetchProjects, selectProjectsPagination } from '../../../store/projects'
import { selectProjectCount, selectProjects, selectProjectsFetching } from '../../../store/projects/selectors'
import { clearDetailSearchProjectsCriteria, selectSearchCriteriaProjects } from '../../../store/search'
import { resetTableCheckbox, selectCheckedTableRows } from '../../../store/table'
import OfferPrint from '../ProjectView/OfferPrint/OfferPrint'
import TableColumns from './TableColumns/TableColumns'

const ProjectList = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const { user } = useCurrentUser()
    const { t } = useTranslation()
    const { CHANGE_AGENT_MODAL } = MODAL_TYPES

    const { pagination, projects, projectsFetching, projectCount, projectSearchCriteria, selectedTableRows } = useSelector((state) => ({
        projects: selectProjects(state),
        projectsFetching: selectProjectsFetching(state),
        projectCount: selectProjectCount(state),
        projectSearchCriteria: selectSearchCriteriaProjects(state),
        userSettings: selectCurrentUserSettings(state),
        selectedTableRows: selectCheckedTableRows(state),
        pagination: selectProjectsPagination(state),
    }))

    const [rowId, setRowId] = useState(null)
    const [deleteModal, setDeleteModal] = useState(false)
    const [deleteModalMulti, setDeleteModalMulti] = useState(false)
    const [pageCount, setPageCount] = useState(0)
    const projectPrintArray = useRef([])
    const [modalBaseOpen, setModalBaseOpen] = useState(false)

    const appModule = 'project'

    /* useLocationChange((location, prevLocation) => {
        // console.log('Location changed', location,prevLocation)
        if (location.pathname === '/projects' && prevLocation && prevLocation.pathname === '/projects' && location.state && location.state.clearDetailSearch) {
            if (projectSearchCriteria) {
                dispatch(clearDetailSearchProjectsCriteria())
            }
        }
    }) */

    /* useEffect(() => {
        return () => {
            dispatch(clearDetailSearchProjectsCriteria())
        }
    }, []) */

    const onClickDelete = useCallback(
        (id) => {
            setRowId(id)
            setDeleteModal(true)
        },
        [setDeleteModal]
    )

    const handleDeleteProject = () => {
        dispatch(deleteProject(rowId))
        dispatch(resetTableCheckbox(true))
        setDeleteModal(false)
    }

    const deleteMultipleProjects = () => {
        const selectedRowsIds = selectedTableRows.map((row) => row.id)

        dispatch(deleteProjectMultiple(selectedRowsIds))
        dispatch(resetTableCheckbox(true))
        setDeleteModalMulti(false)
    }

    const handleChangeAgentModal = () => {
        const payload = {
            modalType: CHANGE_AGENT_MODAL,
        }

        dispatch(showModal(payload))
        dispatch(fetchAgents())
    }

    const handleDataFetch = (page, limit, order, criteria) => {
        dispatch(fetchProjects({ page, limit, order, criteria }, (count) => setPageCount(Math.ceil(count / limit))))
    }

    const handleRowClick = (id) => {
        history.push(`/projects/${id}`)
    }

    const tableData = useFormatTableData(projects, 'projects')
    const columns = TableColumns(onClickDelete, 'projects')

    const handleShowPrintModal = () => {
        const projectArray = projects.filter((projectFirst) => selectedTableRows.some((itemSelected) => itemSelected.id === projectFirst.id))
        projectPrintArray.current = projectArray
        setModalBaseOpen(true)
    }

    const tableActions = () => (
        <div className="d-flex flex-grow-1">
            <Button tag={Link} to="/projects/new" color="add" className="btn-label">
                <i className="mdi mdi-layers-outline align-bottom label-icon align-middle fs-16 me-2"></i>
                {t('button.add')}
            </Button>
            <UncontrolledDropdown className="ms-2">
                <DropdownToggle title={t('table.showMore')} href="#" className="btn btn-primary dropdown" tag="button">
                    <i className="ri-more-fill align-middle"></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem href="#" onClick={() => setDeleteModalMulti(true)}>
                        <i className="mdi mdi-trash-can-outline align-bottom me-2 text-muted"></i> {t('table.action.deleteSelected')}
                    </DropdownItem>
                    <AppPermissionsSwitcher permission="ChangeAgent">
                        {({ hasPermission }) =>
                            hasPermission && (
                                <DropdownItem onClick={handleChangeAgentModal} disabled={!selectedTableRows.length}>
                                    <i className="mdi mdi-account-switch-outline align-bottom me-2 text-muted"></i> {t('app.common.switchAgent')}
                                </DropdownItem>
                            )
                        }
                    </AppPermissionsSwitcher>
                    <DropdownItem onClick={handleShowPrintModal} disabled={!selectedTableRows.length}>
                        <i className="mdi mdi-file-document-multiple-outline align-bottom me-2 text-muted"></i> {t('app.common.offerOrPrint')}
                    </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
        </div>
    )

    return (
        <React.Fragment>
            <div className="page-content">
                <DeleteModal show={deleteModal} onDeleteClick={() => handleDeleteProject()} onCloseClick={() => setDeleteModal(false)} />
                <DeleteModal show={deleteModalMulti} onDeleteClick={() => deleteMultipleProjects()} onCloseClick={() => setDeleteModalMulti(false)} />

                <Container fluid>
                    <PageTitle title={t('app.common.projects')} />
                    <Row>
                        <Col lg={12}>
                            <Card id="ProjectList">
                                <CardBody>
                                    <TableContainer
                                        tableActions={tableActions}
                                        hasColumnSelection={true}
                                        columns={columns}
                                        data={tableData || []}
                                        isGlobalFilter={false}
                                        isAddUserList={false}
                                        customPageSize={20}
                                        className="custom-header-css"
                                        theadClass={'dmn-table-header'}
                                        isRealEstatesListFilter={true}
                                        hasPageSizeSelect={true}
                                        isLoading={projectsFetching}
                                        handleDataFetch={handleDataFetch}
                                        handleRowClick={handleRowClick}
                                        totalCount={projectCount}
                                        pageCount={pageCount}
                                        searchCriteria={projectSearchCriteria}
                                        tableHash={appModule}
                                        currentPage={pagination.currentPage}
                                        totalPages={pagination.totalPages}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <OfferPrint setModalBaseOpen={setModalBaseOpen} modalBaseOpen={modalBaseOpen} data={projectPrintArray.current} />
            </div>
        </React.Fragment>
    )
}

export default ProjectList
