import QueryString from 'qs'
import { toast } from 'react-toastify'
import { put, takeEvery, takeLatest } from 'redux-saga/effects'
import { apiRequest } from '../api'
import i18n from '../../i18n'
import {
    fetchUsers,
    fetchUsersRequest,
    fetchUsersSuccess,
    fetchUsersFailure,
    fetchUser,
    fetchUserRequest,
    fetchUserSuccess,
    fetchUserFailure,
    updateUser,
    updateUserRequest,
    updateUserSuccess,
    updateUserFailure,
    fetchAgencyOffices,
    fetchAgencyOfficesRequest,
    fetchAgencyOfficesSuccess,
    fetchAgencyOfficesFailure,
    fetchAgencyPermissions,
    fetchAgencyPermissionsRequest,
    fetchAgencyPermissionsSuccess,
    fetchAgencyPermissionsFailure,
    fetchAgency,
    fetchAgencyRequest,
    fetchAgencySuccess,
    fetchAgencyFailure,
    fetchAgencyOffice,
    fetchAgencyOfficeRequest,
    fetchAgencyOfficeSuccess,
    fetchAgencyOfficeFailure,
    updateAgency,
    updateAgencyRequest,
    updateAgencySuccess,
    updateAgencyFailure,
    updateAgencyOffice,
    updateAgencyOfficeRequest,
    updateAgencyOfficeSuccess,
    updateAgencyOfficeFailure,
    createAgencyOffice,
    createAgencyOfficeRequest,
    createAgencyOfficeSuccess,
    createAgencyOfficeFailure,
} from './actions'
import { SUBMIT_RESPONSE_STATUS } from '../../common/constants'

export function* fetchUsersSaga({ payload }) {
    yield put(fetchUsersRequest())

    try {
        const response = yield apiRequest(`v1/core/users`, {
            method: 'GET',
            params: payload,
            paramsSerializer: (params) => {
                return QueryString.stringify(params)
            },
        })

        yield put(fetchUsersSuccess(response.data))
    } catch (error) {
        yield put(fetchUsersFailure(error))
    }
}

export function* fetchUserSaga({ payload }) {
    yield put(fetchUserRequest())

    try {
        const response = yield apiRequest(`v1/core/users/${payload}`, {
            method: 'GET',
            params: payload,
            paramsSerializer: (params) => {
                return QueryString.stringify(params)
            },
        })

        yield put(fetchUserSuccess(response.data))
    } catch (error) {
        yield put(fetchUserFailure(error))
    }
}

export function* updateUserSaga({ payload, meta }) {
    yield put(updateUserRequest())
    const userToast = toast.loading(i18n.t('toast.loading'))
    const { id, ...data } = payload

    try {
        const response = yield apiRequest(`v1/core/users/${id}`, {
            method: 'PUT',
            data: data,
        })
        meta && meta.callback && meta.callback(response)
        yield put(updateUserSuccess(response))

        toast.update(userToast, { render: i18n.t('toast.update.success'), type: 'success', isLoading: false, autoClose: 3000 })
    } catch (error) {
        yield put(updateUserFailure(error))
        toast.update(userToast, { render: i18n.t('toast.update.error'), type: 'error', isLoading: false, autoClose: 3000 })
    }
}

export function* fetchAgencyOfficesSaga() {
    yield put(fetchAgencyOfficesRequest())

    try {
        const response = yield apiRequest(`v1/agency-office?noLimit=1`, {
            method: 'GET',
        })

        yield put(fetchAgencyOfficesSuccess(response.data))
    } catch (error) {
        yield put(fetchAgencyOfficesFailure(error))
    }
}

export function* fetchAgencyOfficeSaga({ payload }) {
    yield put(fetchAgencyOfficeRequest())

    try {
        const response = yield apiRequest(`v1/agency-office/${payload}`, {
            method: 'GET',
            params: payload,
            paramsSerializer: (params) => {
                return QueryString.stringify(params)
            },
        })

        yield put(fetchAgencyOfficeSuccess(response.data))
    } catch (error) {
        yield put(fetchAgencyOfficeFailure(error))
    }
}

export function* createAgencyOfficeSaga({ payload, meta }) {
    yield put(createAgencyOfficeRequest())
    const progressToast = toast.loading(i18n.t('toast.loading'))
    try {
        const response = yield apiRequest('v1/agency-office', {
            method: 'POST',
            data: payload,
        })
        yield put(createAgencyOfficeSuccess(response))
        if (meta && meta.callback) {
            meta.callback({
                submitStatus: SUBMIT_RESPONSE_STATUS.SUCCESS,
                data: response?.data,
            })
        }
        toast.update(progressToast, { render: i18n.t('toast.update.success'), type: 'success', isLoading: false, autoClose: 3000 })
    } catch (error) {
        yield put(createAgencyOfficeFailure(error))
        toast.update(progressToast, { render: i18n.t('toast.update.error'), type: 'error', isLoading: false, autoClose: 3000 })
    }
}

export function* updateAgencyOfficeSaga({ payload }) {
    yield put(updateAgencyOfficeRequest())
    const userToast = toast.loading(i18n.t('toast.loading'))
    const { id, ...data } = payload

    try {
        const response = yield apiRequest(`v1/agency-office/${id}`, {
            method: 'PUT',
            data: data,
        })
        yield put(updateAgencyOfficeSuccess(response))

        toast.update(userToast, { render: i18n.t('toast.update.success'), type: 'success', isLoading: false, autoClose: 3000 })
    } catch (error) {
        yield put(updateAgencyOfficeFailure(error))
        toast.update(userToast, { render: i18n.t('toast.update.error'), type: 'error', isLoading: false, autoClose: 3000 })
    }
}

export function* fetchAgencyPermissionsSaga() {
    yield put(fetchAgencyPermissionsRequest())

    try {
        const response = yield apiRequest(`v1/core/agent-permissions`, {
            method: 'GET',
        })

        yield put(fetchAgencyPermissionsSuccess(response.data))
    } catch (error) {
        yield put(fetchAgencyPermissionsFailure(error))
    }
}

export function* fetchAgencySaga() {
    yield put(fetchAgencyRequest())

    try {
        const response = yield apiRequest(`v1/agency`, {
            method: 'GET',
        })

        yield put(fetchAgencySuccess(response.data))
    } catch (error) {
        yield put(fetchAgencyFailure(error))
    }
}

export function* updateAgencySaga({ payload }) {
    yield put(updateAgencyRequest())
    const userToast = toast.loading(i18n.t('toast.loading'))
    const { id, ...data } = payload

    try {
        const response = yield apiRequest(`v1/agency`, {
            method: 'PUT',
            data: data,
        })
        yield put(updateAgencySuccess(response))

        toast.update(userToast, { render: i18n.t('toast.update.success'), type: 'success', isLoading: false, autoClose: 3000 })
    } catch (error) {
        yield put(updateAgencyFailure(error))
        toast.update(userToast, { render: i18n.t('toast.update.error'), type: 'error', isLoading: false, autoClose: 3000 })
    }
}

export default function* actionWatcher() {
    yield takeLatest(`${fetchUsers}`, fetchUsersSaga)
    yield takeLatest(`${fetchUser}`, fetchUserSaga)
    yield takeEvery(`${updateUser}`, updateUserSaga)
    yield takeLatest(`${fetchAgencyOffices}`, fetchAgencyOfficesSaga)
    yield takeEvery(`${createAgencyOffice}`, createAgencyOfficeSaga)
    yield takeEvery(`${updateAgencyOffice}`, updateAgencyOfficeSaga)
    yield takeLatest(`${fetchAgencyPermissions}`, fetchAgencyPermissionsSaga)
    yield takeLatest(`${fetchAgency}`, fetchAgencySaga)
    yield takeEvery(`${updateAgency}`, updateAgencySaga)
    yield takeLatest(`${fetchAgencyOffice}`, fetchAgencyOfficeSaga)
}
