import { put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { apiRequest } from '../api';
import { fetchPropertyAvailableEntitiesSuccess, selectPropertyAvailableEntities } from '../propertyAvailableEntities';
import { fetchPropertyServices, fetchPropertyServicesFailure, fetchPropertyServicesRequest, fetchPropertyServicesSuccess } from './actions';

export function* fetchPropertyServicesSaga() {
  yield put(fetchPropertyServicesRequest());
  const availableEntities = yield select(selectPropertyAvailableEntities);
  try {
    // if(availableEntities['propertyService']){
        const response = yield apiRequest('v1/property/values/propertyService', {
        method: 'GET'
        });
        yield put(fetchPropertyServicesSuccess(response.data));
    // }
  } catch (error) {
    yield put(fetchPropertyServicesFailure(error));
  }
}

export default function* actionWatcher() {
  // yield takeLatest([`${fetchPropertyAvailableEntitiesSuccess}`, `${fetchPropertyServices}`], fetchPropertyServicesSaga);
  yield takeLatest(`${fetchPropertyServices}`, fetchPropertyServicesSaga);
}
