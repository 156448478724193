import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'reactstrap'
import { selectGroundPlansProxyPhoto } from '../../store/groundplans'
import { selectCount, setCount } from '../../store/imageEditor'
import { selectPhotosProxyPhoto } from '../../store/photos'

const FormActionButtons = ({ disabled = false, hasCancel = false, cancelAction }) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const { proxyPhoto, proxyGroundPlan, count } = useSelector((state) => ({
        proxyPhoto: selectPhotosProxyPhoto(state),
        proxyGroundPlan: selectGroundPlansProxyPhoto(state),
        count: selectCount(state),
    }))

    const isProxyPhoto = proxyPhoto || proxyGroundPlan

    const handleImageSave = () => {
        dispatch(setCount(count + 1))
    }

    return (
        <div className="hstack gap-2 justify-content-end d-print-none">
            <Button
                color="success"
                className="btn-label"
                type={isProxyPhoto ? 'button' : 'submit'}
                disabled={disabled}
                onClick={isProxyPhoto ? handleImageSave : () => {}}
            >
                <i className="mdi mdi-check label-icon align-middle fs-16 me-2"></i> {t('button.save')}
            </Button>
            {hasCancel && (
                <Button
                    color="primary"
                    className="btn-label"
                    onClick={(e) => {
                        e.preventDefault()

                        if (cancelAction) cancelAction()
                    }}
                >
                    <i className="mdi mdi-close label-icon align-middle fs-16 me-2"></i>
                    {t('button.cancel')}
                </Button>
            )}
        </div>
    )
}

export default FormActionButtons
