import { useFormik } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Alert, Card, CardBody, Col, Container, Form, FormFeedback, Input, Label, Row, Spinner } from 'reactstrap'
import * as Yup from 'yup'
import logoLight from '../../assets/images/dimedia-crm-logo-white.png'
import { forgotPasswordSendEmail } from '../../store/auth'
import { selectForgotPasswordFailure, selectForgotPasswordLoading, selectForgotPasswordSuccess } from '../../store/auth/selectors'
import ParticlesAuth from './ParticlesAuth'

const ForgotPassword = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().required(t('app.common.email.error')).email(t('form.forgotPassword.error.invalidEmail')),
        }),
        onSubmit: (values) => {
            dispatch(forgotPasswordSendEmail(values))
        },
    })

    const { isLoading, isSuccess, isFailure } = useSelector((state) => {
        return {
            isFailure: selectForgotPasswordFailure(state),
            isSuccess: selectForgotPasswordSuccess(state),
            isLoading: selectForgotPasswordLoading(state),
        }
    })

    return (
        <ParticlesAuth>
            <div className="auth-page-content">
                <Container>
                    <Row className="mt-4">
                        <Col lg={12}>
                            <div className="text-center mt-5 mb-5  text-white-50">
                                <div>
                                    <Link to="/" className="d-inline-block auth-logo">
                                        <img src={logoLight} alt="" />
                                    </Link>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="mt-5">
                                <CardBody className="p-4">
                                    <div className="text-center mt-2">
                                        <h5 className="text-primary fs-16">{t('app.common.password.forgotPassword')}</h5>
                                        <i className="ri-mail-send-line display-5 text-success d-block mt-3 mb-3"></i>
                                    </div>
                                    <Alert className="border-0 alert-warning text-center mb-2 mx-2" role="alert">
                                        {t('form.forgotPassword.message')}
                                    </Alert>
                                    <div className="p-2">
                                        {isFailure ? (
                                            <Alert color="danger" style={{ marginTop: '13px' }}>
                                                {t('modal.error.message')}
                                            </Alert>
                                        ) : null}
                                        {isSuccess ? (
                                            <Alert color="success" style={{ marginTop: '13px' }}>
                                                {t('form.sendEmail.success.message')}
                                            </Alert>
                                        ) : null}
                                        {!isSuccess && (
                                            <Form
                                                onSubmit={(e) => {
                                                    e.preventDefault()
                                                    formik.handleSubmit()
                                                    return false
                                                }}
                                                noValidate
                                            >
                                                <div className="mb-4">
                                                    <Label className="form-label">{t('app.common.email.label')}</Label>
                                                    <Input
                                                        name="email"
                                                        className="form-control"
                                                        placeholder={t('app.common.email.placeholder')}
                                                        type="email"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.email || ''}
                                                        invalid={formik.touched.email && formik.errors.email ? true : false}
                                                    />
                                                    {formik.touched.email && formik.errors.email ? (
                                                        <FormFeedback type="invalid">
                                                            <div>{formik.errors.email}</div>
                                                        </FormFeedback>
                                                    ) : null}
                                                </div>

                                                <div className="text-center mt-4">
                                                    <button className="btn btn-success w-100" type="submit">
                                                        {isLoading ? <Spinner size="sm" /> : t('button.send')}
                                                    </button>
                                                </div>
                                            </Form>
                                        )}
                                    </div>
                                </CardBody>
                            </Card>
                            <div className="mt-4 text-center">
                                <p className="mb-0">
                                    <Link to="/login" className="text-primary text-decoration-underline">
                                        {t('app.common.login')}
                                    </Link>
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </ParticlesAuth>
    )
}

export default ForgotPassword
