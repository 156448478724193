export const generateRandomPassword = (regex) => {
    const length = 8
    const allowedChars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_-+=[]{}|;:,.<>?'
    let isValid = false

    while (!isValid) {
        let password = ''

        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * allowedChars.length)
            password += allowedChars[randomIndex]
        }

        if (regex.test(password)) {
            isValid = true
            return password
        }
    }
}

