import Loader from '../../Components/Common/Loader'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import { selectCurrentUser, selectCurrentUserChecked } from '../../store/auth/selectors'
import { withRouter, Redirect } from 'react-router-dom'
import Header from '../Header'
import SideMenu from './SideMenu/SideMenu'
import style from './AdminLayout.module.css'

const AdminLayout = ({ children }) => {
    const { user, currentUserChecked } = useSelector((state) => ({
        user: selectCurrentUser(state),
        currentUserChecked: selectCurrentUserChecked(state),
    }))

    const [showMenu, setShowMenu] = useState(false)

    const toggleMenu = () => {
        setShowMenu(!showMenu)
    }

    if (!user?.roles?.includes('ROLE_ADMIN')) return <Redirect to="/" />

    return (
        <>
            <div id="layout-wrapper">
                {!currentUserChecked ? (
                    <div className="full-page-loader">
                        <Loader />
                    </div>
                ) : (
                    <>
                        <Header btnFunction={toggleMenu} />
                        <div className={style.mainContent}>
                            <div className={style.pageContentAdmin}>
                                <SideMenu className={style.sideMenu} showMenu={showMenu} setShowMenu={setShowMenu} />
                                <div className={style.pageContentChildren}>{children}</div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    )
}

export default withRouter(AdminLayout)
