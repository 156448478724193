import ReactDom from 'react-dom'
import styles from './ModalWrapper.module.css'
import { useEffect, useRef } from 'react'

const ModalWrapper = ({ open, children, onClose, className }) => {
    const body = useRef(document.getElementsByTagName('body'))

    useEffect(() => {
        open && (body.current[0].style.overflow = 'hidden')
    }, [open])

    if (!open) return null

    return ReactDom.createPortal(
        <>
            <div className={styles.overlay} />
            <div className={styles.modalWrapper}>
                <div className={`${styles.modal} ${className}`}>
                    <button
                        className={styles.closeButton}
                        onClick={() => {
                            onClose()
                            body.current[0].style.overflow = 'auto'
                        }}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="#000">
                            <path d="M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z" />
                        </svg>
                    </button>
                    {children}
                </div>
            </div>
        </>,
        document.getElementById('modal-portal')
    )
}

export default ModalWrapper
