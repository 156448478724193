import { useState, useEffect } from 'react'
import { Input } from 'reactstrap'
import i18n from 'i18next'

const SearchBar = ({ data, fetching, setFilteredData, searchFieldFirst, searchFieldSecond }) => {
    const [searchInput, setSearchInput] = useState('')

    useEffect(() => {
        const filtered = data?.filter(
            (item) =>
                item?.[searchFieldFirst]?.toLowerCase().includes(searchInput.toLowerCase()) ||
                item?.[searchFieldSecond]?.toLowerCase().includes(searchInput.toLowerCase())
        )
        setFilteredData(filtered)
    }, [searchInput, fetching])

    return (
        <div className="search-box">
            <Input
                type="text"
                className="form-control"
                placeholder={`${i18n.t('admin.searchInput')}`}
                onChange={(e) => setSearchInput(e.target.value)}
            />
            <i className="ri-search-line search-icon"></i>
        </div>
    )
}

export default SearchBar
