import { handleActions } from 'redux-actions'

import {
    authenticateDestroy,
    authenticateExpired,
    authenticateFailure,
    authenticateInitial,
    authenticateRefresh,
    authenticateRequest,
    authenticateSuccess,
    errorMessageClear,
    fetchUserFailure,
    fetchUserSuccess,
    registerUserClear,
    registerUserFailure,
    registerUserRequest,
    registerUserSuccess,
    updateCurrentSettingsSuccess,
    forgotPasswordSendEmailRequest,
    forgotPasswordSendEmailSuccess,
    forgotPasswordSendEmailFailure,
    resetPasswordRequest,
    resetPasswordSuccess,
    resetPasswordFailure,
    resetPasswordClear,
} from './actions'

export const initialState = {
    accessToken: null,
    refreshToken: null,
    isRefreshing: false,
    isFetching: false,
    isAuthenticated: false,
    isRegistered: false,
    isCurrentChecked: false,
    errorMessage: null,
    isSendingEmail: false,
    isSendingEmailSuccess: false,
    isSendingEmailFailure: false,
    isSendingReset: false,
    isSendingFailureReset: false,
    isSendingSuccessReset: false,
    user: {},
}

export default handleActions(
    {
        // login
        [authenticateInitial]: (state, { payload }) => {
            return {
                ...state,
                accessToken: payload.accessToken,
                refreshToken: payload.refreshToken,
                isAuthenticated: !!(payload.accessToken && payload.refreshToken),
            }
        },
        [authenticateRequest]: (state) => ({
            ...state,
            isFetching: true,
        }),
        [authenticateSuccess]: (state, { payload }) => ({
            ...state,
            accessToken: payload.data.token,
            refreshToken: payload.data.refresh_token,
            isRefreshing: false,
            isFetching: false,
            isAuthenticated: true,
            errorMessage: null,
        }),
        [authenticateFailure]: (state, { payload }) => ({
            ...initialState,
            isFetching: false,
            isAuthenticated: false,
            errorMessage: payload,
        }),
        [authenticateExpired]: (state) => ({
            ...state,
            isFetching: false,
        }),
        [authenticateDestroy]: () => initialState,
        [authenticateRefresh]: (state) => ({
            ...state,
            isRefreshing: true,
            errorMessage: null,
        }),

        // register
        [registerUserRequest]: (state) => ({
            ...state,
            isFetching: true,
        }),
        [registerUserSuccess]: (state, { payload }) => ({
            ...state,
            isFetching: false,
            isRegistered: payload === 200,
        }),
        [registerUserFailure]: (state, { payload }) => ({
            ...state,
            isFetching: false,
            errorMessage: payload,
        }),
        [registerUserClear]: (state) => ({
            ...state,
            isFetching: false,
            isRegistered: false,
            errorMessage: null,
        }),

        // fetch user
        [fetchUserSuccess]: (state, { payload }) => ({
            ...state,
            isCurrentChecked: true,
            user: {
                ...payload,
                userSettings: payload.userSettings ? JSON.parse(payload.userSettings) : {},
            },
        }),
        [fetchUserFailure]: (state, { payload }) => ({
            ...state,
            isCurrentChecked: true,
        }),
        [updateCurrentSettingsSuccess]: (state, { payload }) => ({
            ...state,
            user: {
                ...state.user,
                userSettings: payload,
            },
        }),

        [errorMessageClear]: (state) => ({
            ...state,
            isFetching: false,
            errorMessage: null,
        }),

        [forgotPasswordSendEmailRequest]: (state) => ({
            ...state,
            isSendingEmail: true,
            isSendingEmailSucces: false,
            isSendingEmailFailure: false,
        }),
        [forgotPasswordSendEmailSuccess]: (state) => ({
            ...state,
            isSendingEmail: false,
            isSendingEmailSucces: true,
            isSendingEmailFailure: false,
        }),
        [forgotPasswordSendEmailFailure]: (state) => ({
            ...state,
            isSendingEmail: false,
            isSendingEmailFailure: true,
            isSendingEmailSucces: false,
        }),

        [resetPasswordRequest]: (state) => ({
            ...state,
            isSendingReset: true,
            isSendingSuccessReset: false,
            isSendingFailureReset: false,
        }),
        [resetPasswordSuccess]: (state) => ({
            ...state,
            isSendingReset: false,
            isSendingSuccessReset: true,
            isSendingFailureReset: false,
        }),
        [resetPasswordFailure]: (state) => ({
            ...state,
            isSendingReset: false,
            isSendingFailureReset: true,
            isSendingSuccessReset: false,
        }),

        [resetPasswordClear]: (state) => ({
            ...state,
            isSendingReset: false,
            isSendingFailureReset: false,
            isSendingSuccessReset: false,
            isSendingEmail: false,
            isSendingEmailFailure: false,
            isSendingEmailSucces: false,
        }),
    },
    initialState
)
