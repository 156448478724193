import axios from 'axios'
import i18n from 'i18next'
import { toast } from 'react-toastify'
import { API_URL } from '../../common/constants'
import { authenticateFailure, selectAccessToken } from '../auth'

export const RSAA = '@@API_MIDDLEWARE/RSAA'

const middleware = (store) => (next) => (action) => {
    const apiAction = action[RSAA]

    if (typeof apiAction === 'undefined') {
        return next(action)
    }

    const { endpoint, types, method = 'GET', body, params } = apiAction
    const [requestType, successType, failureType] = types
    const accessToken = selectAccessToken(store.getState())
    const headers = {}

    // omit adding accessToken to token/refresh endpoint, since it is public
    if (endpoint !== 'token/refresh' && accessToken) {
        headers.Authorization = `Bearer ${accessToken}`
    }

    next(requestType())

    const handleError = (error) => {
        if (error.response && error.response.status === 401) {
            next(authenticateFailure({ status: error.response.status, message: error.response.data.message }))
        } else {
            next(failureType(error.response ? error.response.data : 'Unknown error'))
        }
    }

    if (method === 'POST') {
        return axios
            .post(API_URL + endpoint, body, { headers })
            .then((response) => {
                // show toast if response includes permissionsChanged flag
                if (endpoint === 'login_check' && response.data.permissionsChanged) {
                    toast.info(<div className="text-center">{i18n.t('toast.permissionsChanged')}</div>, { autoClose: 8000 })
                }

                next(successType(response))
            })
            .catch((error) => handleError(error))
    }
    if (method === 'PUT') {
        return axios
            .put(API_URL + endpoint, body, { headers })
            .then((response) => next(successType(response.data)))
            .catch((error) => handleError(error))
    }
    if (method === 'DELETE') {
        return axios
            .delete(API_URL + endpoint, { params, headers })
            .then(() => next(successType(params)))
            .catch((error) => handleError(error))
    }

    return axios
        .get(API_URL + endpoint, {
            headers,
            params,
        })
        .then((response) => next(successType(response.data)))
        .catch((error) => handleError(error))
}

export default middleware
