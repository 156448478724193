import { useMutation } from '@tanstack/react-query'
import { useHistory } from 'react-router-dom'
import { fetchNewPropertyId } from '../../../api'

export const useFetchNewPropertyId = (handleClose) => {
    const history = useHistory()

    const { mutate, isPending } = useMutation({
        mutationFn: ({ id, data }) => fetchNewPropertyId(id, data),
        onSuccess: ({ id: newId }) => {
            history.push(`/properties/${newId}/edit`)
            handleClose()
        },
    })

    return { mutate, isPending }
}
