import { getIn } from 'formik'
import { useEffect } from 'react'
import { DETAIL_SEARCH_TYPE } from '../common/constants'

export const useDetailSearchProjectEffect = (type, validation) => {
    const projectStatus = getIn(validation?.values, 'projectStatus')

    useEffect(() => {
        if (type === DETAIL_SEARCH_TYPE.PROJECT) {
            validation.setValues({})
            validation.setFieldValue(`projectStatus`, projectStatus)
        }
    }, [type, projectStatus])

}
