import { combineActions, handleActions } from 'redux-actions'
import {
    createProjectFailure,
    createProjectRequest,
    createProjectSuccess,
    deleteProjectFailure,
    deleteProjectMultipleFailure,
    deleteProjectMultipleRequest,
    deleteProjectMultipleSuccess,
    deleteProjectRequest,
    deleteProjectSuccess,
    fetchProjectFailure,
    fetchProjectRequest,
    fetchProjectSuccess,
    fetchProjectsFailure,
    fetchProjectsRequest,
    fetchProjectsSuccess,
    updateProjectFailure,
    updateProjectRequest,
    updateProjectSuccess,
    resetPageProjects,
    updateProjectsAgent,
} from './actions'

export const initialState = {
    items: {},
    item: {},
    count: 0,
    fetching: true,
    fetchingDetails: true,
    deleting: false,
    error: null,
    errorDetails: null,
    submitting: false,
    notes: {},
    note: {},
    notecount: 0,
    fetchingNotes: false,
    fetchingNote: false,
    errornote: null,
    notesubmitting: false,
    detailSearchCriteria: {},
    pagination: {},
    links: {},
}

export default handleActions(
    {
        [fetchProjectsRequest]: (state) => ({
            ...state,
            fetching: true,
            error: null,
        }),
        [fetchProjectsSuccess]: (state, { payload }) => ({
            ...state,
            count: payload?.count ?? 0,
            items: payload?.items,
            pagination: payload?.pagination,
            // items: payload?.items?.reduce((acc, item) => {
            //     return { ...acc, [item.id]: item }
            // }, {}),
            fetching: false,
        }),
        [fetchProjectsFailure]: (state, { payload }) => ({
            ...state,
            error: payload,
            fetching: false,
        }),
        [fetchProjectRequest]: (state) => ({
            ...state,
            fetchingDetails: true,
            errorDetails: null,
        }),
        [fetchProjectSuccess]: (state, { payload }) => {
            return {
                ...state,
                item: payload?.item,
                fetchingDetails: false,
                links: payload.links,
            }
        },
        [fetchProjectFailure]: (state, { payload }) => ({
            ...state,
            errorDetails: payload,
            fetchingDetails: false,
        }),
        [combineActions(updateProjectRequest, createProjectRequest)]: (state, { payload }) => ({
            ...state,
            submitting: true,
        }),
        [combineActions(updateProjectSuccess, createProjectSuccess)]: (state, { payload }) => ({
            ...state,
            submitting: false,
        }),
        [combineActions(updateProjectFailure, createProjectFailure)]: (state, { payload }) => ({
            ...state,
            submitting: false,
            error: payload,
        }),
        [deleteProjectRequest]: (state) => {
            return {
                ...state,
                error: null,
                deleting: true,
            }
        },
        [deleteProjectSuccess]: (state, { payload }) => {
            let items = [...state.items]
            items = items.filter((x) => x.id !== parseInt(payload))

            return {
                ...state,
                deleting: false,
                items,
                count: state.count - 1,
            }
        },
        [deleteProjectFailure]: (state, { payload }) => {
            return {
                ...state,
                deleting: false,
                error: payload.response,
            }
        },
        [deleteProjectMultipleRequest]: (state) => {
            return {
                ...state,
                error: null,
                deleting: true,
            }
        },
        [deleteProjectMultipleSuccess]: (state, { payload }) => {
            let items = [...state.items]
            items = items.filter((x) => !payload.includes(x.id))

            return {
                ...state,
                deleting: false,
                items,
                count: state.count - payload?.length,
            }
        },
        [deleteProjectMultipleFailure]: (state, { payload }) => {
            return {
                ...state,
                deleting: false,
                error: payload.response,
            }
        },
        [resetPageProjects]: (state) => ({
            ...state,
            pagination: { ...state.pagination, currentPage: 1 },
        }),
        [updateProjectsAgent]: (state, { payload }) => {
            const { agent, selectedIds } = payload
            const updatedProjects = state.items.map((item) => (selectedIds.includes(item.id) ? { ...item, agent: [agent] } : item))

            return {
                ...state,
                items: updatedProjects,
            }
        },
    },
    initialState
)
