import { createAction } from 'redux-actions'

const ENTITY = 'OFFERS'
export const stateKey = ENTITY.toLowerCase()

export const fetchOffers = createAction(`${ENTITY}/FETCH`)
export const fetchOffersRequest = createAction(`${ENTITY}/FETCH_REQUEST`)
export const fetchOffersSuccess = createAction(`${ENTITY}/FETCH_SUCCESS`)
export const fetchOffersFailure = createAction(`${ENTITY}/FETCH_FAILURE`)

export const fetchContact = createAction(`${ENTITY}/DETAILS/FETCH`)
export const fetchOfferRequest = createAction(`${ENTITY}/DETAILS/FETCH_REQUEST`)
export const fetchOfferSuccess = createAction(`${ENTITY}/DETAILS/FETCH_SUCCESS`)
export const fetchOfferFailure = createAction(`${ENTITY}/DETAILS/FETCH_FAILURE`)

export const createOffer = createAction(
    `${ENTITY}/CREATE`,
    (values) => values,
    (values, callback) => ({ callback })
)
// export const createContact = createAction(`${ENTITY}/CREATE`);
export const createOfferRequest = createAction(`${ENTITY}/CREATE_REQUEST`)
export const createOfferSuccess = createAction(`${ENTITY}/CREATE_SUCCESS`)
export const createOfferFailure = createAction(`${ENTITY}/CREATE_FAILURE`)

export const updateOffer = createAction(
    `${ENTITY}/UPDATE`,
    (values) => values,
    (values, callback) => ({ callback })
)
// export const createContact = createAction(`${ENTITY}/CREATE`);
export const updateOfferRequest = createAction(`${ENTITY}/UPDATE_REQUEST`)
export const updateOfferSuccess = createAction(`${ENTITY}/UPDATE_SUCCESS`)
export const updateOfferFailure = createAction(`${ENTITY}/UPDATE_FAILURE`)

export const deleteOffer = createAction(
    `${ENTITY}/DELETE`,
    (values) => values,
    (values, callback) => ({ callback })
)
// export const deleteOffer = createAction(`${ENTITY}/DELETE`);
export const deleteOfferRequest = createAction(`${ENTITY}/DELTE_REQUEST`)
export const deleteOfferSuccess = createAction(`${ENTITY}/DELTE_SUCCESS`)
export const deleteOfferFailure = createAction(`${ENTITY}/DELETE_FAILURE`)

export const previewOffer = createAction(
    `${ENTITY}/PREVIEW`,
    (values) => values,
    (values, callback) => ({ callback })
)
// export const createContact = createAction(`${ENTITY}/CREATE`);
export const previoewOfferRequest = createAction(`${ENTITY}/PREVIEW/REQUEST`)
export const previoewOfferSuccess = createAction(`${ENTITY}/PREVIEW/SUCCESS`)
export const previoewOfferFailure = createAction(`${ENTITY}/PREVIEW/FAILURE`)
