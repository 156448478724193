import { put, takeLatest } from 'redux-saga/effects'
import QueryString from 'qs'
import { apiRequest } from '../api'
import { fetchQuickSearch, fetchQuickSearchFailure, fetchQuickSearchRequest, fetchQuickSearchSuccess } from './actions'
import { formatDateTime } from '../../utils/general'
import { DETAIL_SEARCH_TYPE } from '../../common/constants'

function quickSearchResultFormatter(type, item) {
    switch (type) {
        case 'contact':
            return {
                id: item.id,
                name: `${item.contactPerson?.firstName} ${item.contactPerson?.lastName}`,
                date: formatDateTime(item.updated),
                link: `/contacts/${item.id}`,
            }
        case 'company':
            return {
                id: item.id,
                name: item.contactCompany?.name,
                date: formatDateTime(item.updated),
                link: `/companies/${item.id}`,
            }
        case 'property':
            return {
                id: item.id,
                date: formatDateTime(item.updated),
                link: `/properties/${item.id}`,
                propertyType: item.propertyType?.name ?? '',
                price: item.price,
                area: item.area,
            }
        case 'project':
            return {
                id: item.id,
                date: formatDateTime(item.updated),
                link: `/projects/${item.id}`,
                name: item.projectTitle,
            }
        default:
            return {}
    }
}

function* quickSearchResponse(payload, type, requestUrl, criteria) {
    let results = []
    let error = null

    try {
        let response = yield apiRequest(`${requestUrl}`, {
            method: 'GET',
            params: {
                limit: 5,
                criteria: criteria,
            },
            paramsSerializer: (params) => {
                return QueryString.stringify(params)
            },
        })

        if (response.data?.items) {
            results = response.data.items.map((item) => {
                return quickSearchResultFormatter(type, item)
            })
        }
    } catch (err) {
        error = err
    }

    return {
        results,
        criteria,
        error,
    }
}

export function* fetchQuickSearchSaga({ payload, meta }) {
    yield put(fetchQuickSearchRequest())
    let results

    switch (payload.pageType) {
        case DETAIL_SEARCH_TYPE.COMPANY:
            results = yield quickSearchResponse(payload, 'company', 'v1/contact', {
                ...payload.searchProps,
                contactType: 'company',
            })
            break
        case DETAIL_SEARCH_TYPE.PROPERTY:
            results = yield quickSearchResponse(payload, 'property', 'v1/property', {
                ...payload.searchProps,
            })
            break
        case DETAIL_SEARCH_TYPE.CONTACT:
            results = yield quickSearchResponse(payload, 'contact', 'v1/contact', {
                ...payload.searchProps,
                contactType: 'person',
            })
            break
        case DETAIL_SEARCH_TYPE.PROJECT:
            results = yield quickSearchResponse(payload, 'project', 'v1/project', {
                ...payload.searchProps,
            })
            break
    }

    yield put(
        fetchQuickSearchSuccess({
            criteria: {
                contactCriteria: results.criteria,
                companyCriteria: results.criteria,
                propertyCriteria: results.criteria,
                projectCriteria: results.criteria,
            },
            results: {
                contacts: results.results,
                companies: results.results,
                projects: results.results,
                properties: results.results,
            },
        })
    )

    if (results.error) {
        yield put(
            fetchQuickSearchFailure({
                contactError: results.error,
                companyError: results.error,
                propertyError: results.error,
                projectError: results.error,
            })
        )
    }
}

export default function* actionWatcher() {
    yield takeLatest(`${fetchQuickSearch}`, fetchQuickSearchSaga)
}
