import _ from "lodash";

export function removeEmpyObjectValues(arr) {
    return arr?.map(item => _.omitBy(item, v => v === _.isNil(v) || v === '')).filter(item => {
        if (Object.keys(item).length) {
            return true;
        }

        return false
    })
}

export function findByPartial(arr, obj) {
    return _(arr).flatMapDeep().find(obj);
}