import { mapFieldTypes } from './fieldTypes'
import { mapFields } from './fields'
import * as Yup from 'yup'

export const mapValidation = (data) => {
    let mapLocationSyncType

    if (data?.mapLocationSyncType) {
        mapLocationSyncType = `${data.mapLocationSyncType}`
    } else {
        const inputs = mapFieldTypes()
        const mapLocationSyncTypeInput = inputs[mapFields.MAP_LOCATION_SYNC_TYPE]
        // Set default value
        mapLocationSyncType = mapLocationSyncTypeInput.options.find((opt) => opt.default)?.value
    }

    return {
        initialValues: {
            [mapFields.MAP_LATITUDE]: data?.mapLatitude ? +data.mapLatitude : '',
            [mapFields.MAP_LONGITUDE]: data?.mapLongitude ? +data.mapLongitude : '',
            [mapFields.MAP_LOCATION_SYNC_TYPE]: mapLocationSyncType ?? '',
            [mapFields.MAP_LOCATION_SYNC]: data?.mapLocationSync ?? 0,
        },
        validationSchema: Yup.object({
            [mapFields.MAP_LATITUDE]: Yup.string(),
            [mapFields.MAP_LONGITUDE]: Yup.string(),
            [mapFields.MAP_LOCATION_SYNC_TYPE]: Yup.string(),
            [mapFields.MAP_LOCATION_SYNC]: Yup.number(),
        }),
    }
}
