import { combineActions, handleActions } from "redux-actions";
import { clearContactProfile, createContactProfileSuccess, deleteContactProfileFailure, deleteContactProfileRequest, deleteContactProfileSuccess, fetchContactProfileFailure, fetchContactProfileRequest, fetchContactProfilesFailure, fetchContactProfilesRequest, fetchContactProfilesSuccess, fetchContactProfileSuccess } from "./actions";

export const initialState = {
    items: [],
    item: {},
    fetching: false,
    fetchingDetails: false,
    error: null,
    errorDetails: null,
    deleting: false,
}

export default handleActions({
    [fetchContactProfilesRequest]: state => ({
        ...state,
        fetching: true,
        error: null,
    }),
    [fetchContactProfilesSuccess]: (state, { payload }) => ({
        ...state,
        items: payload ? payload : [],
        fetching: false
    }),
    [fetchContactProfilesFailure]: (state, { payload }) => ({
        ...state,
        error: payload,
        fetching: false
    }),
    [fetchContactProfileRequest]: state => ({
        ...state,
        fetchingDetails: true,
        errorDetails: null,
    }),
    [fetchContactProfileSuccess]: (state, { payload }) => ({
        ...state,
        item: payload,
        fetchingDetails: false
    }),
    [fetchContactProfileFailure]: (state, { payload }) => ({
        ...state,
        errorDetails: payload,
        fetchingDetails: false
    }),
    [createContactProfileSuccess]: (state, { payload}) => {
        let profile = [ ...state.items];
        profile.push(payload)
        return {
            ...state,
            items: [...profile],
            item: payload
        }
    },
    [deleteContactProfileRequest]: state => {
        return {
            ...state,
            error: null,
            deleting: true
        };
    },
    [deleteContactProfileSuccess]: (state, { payload }) => {
        let profile = [...state.items ];
        
        profile = profile.filter(x => x.id !== payload.id);
        
        return {
            ...state,
            deleting: false,
            items: [...profile]
        };
    },
    [deleteContactProfileFailure]: (state, { payload }) => {
        return {
            ...state,
            deleting: false,
            error: payload.response
        }
    },
    [clearContactProfile]: (state, { payload }) => ({
        ...state,
        item: {}
    })
},initialState);