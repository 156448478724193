import { Form, FormikProvider, getIn, useFormik } from 'formik'
import { debounce } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button, Col, Label, Modal, ModalBody, ModalHeader, Row, TabContent, TabPane } from 'reactstrap'
import * as Yup from 'yup'
import { DETAIL_SEARCH_TYPE, REAL_ESTATE_TYPES, SUBMIT_RESPONSE_STATUS } from '../../common/constants'
import {
    useDetailSearch,
    useDetailSearchContactEffect,
    useDetailSearchProjectEffect,
    useDetailSearchPropertyEffect,
    useLocationChange,
} from '../../hooks'
import { useDidUpdateEffect } from '../../hooks/useDidUpdateEffect'
import { fetchContactsAsyncInputContactFailure } from '../../store/contacts'
import { clearDetailSearchPropertiesCriteria } from '../../store/search'
import {
    fetchDetailSearchLocationsCity,
    fetchDetailSearchLocationsCityRequest,
    fetchDetailSearchLocationsCounty,
    fetchDetailSearchLocationsIsland,
    fetchDetailSearchLocationsQuarter,
    fetchDetailSearchLocationsWiderArea,
    fetchDetailSearchPropertySubtypes,
    fetchDetailSearchPropertyValuesFloor,
} from '../../store/searchValues'
import { selectSettingsPropertyFlatSubTypes } from '../../store/settings/selectors'
import { SaveButton, SearchButton } from '../Common/Buttons'
import Loader from '../Common/Loader'
import RecursiveForm from '../RecursiveForm'

const DetailSearchModal = ({ id, location, htmlId, handleFetchData, isOpen, toggle, size = 'xl', type, ...props }) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const { t } = useTranslation()

    const [count, setCount] = useState(0)
    const [loadingCount, setLoadingCount] = useState(false)

    const {
        title,
        subtitle,
        inputs,
        clearDetailSearchActionRef,
        validationConfig,
        validationConfigRef,
        additionalValidationConfig,
        additionalInputs,
        selectOptions,
        additionalSelectOptions,
        fetchCountAction,
        detailSearchAction,
        redirectPage,
        resetPagePaginationRef,
    } = useDetailSearch(type)

    const { ...validation } = useFormik({
        enableReinitialize: true,
        initialValues: additionalValidationConfig
            ? {
                  [validationConfig.formGroup]: validationConfig.initialValues ?? {},
                  [additionalValidationConfig.formGroup]: additionalValidationConfig.initialValues ?? {},
              }
            : validationConfig.initialValues ?? {},
        validationSchema: additionalValidationConfig
            ? Yup.object({
                  [validationConfig.formGroup]: validationConfig.validationSchema ?? Yup.object({}),
                  [additionalValidationConfig.formGroup]: additionalValidationConfig.validationSchema ?? Yup.object({}),
              })
            : validationConfig.validationSchema ?? Yup.object({}),
        onSubmit: (values) => {
            const submitValues = additionalValidationConfig
                ? {
                      [validationConfig.formGroup]: validationConfigRef.current.prepareModelForSubmit(values[validationConfig.formGroup]),
                      [additionalValidationConfig.formGroup]: additionalValidationConfig.prepareModelForSubmit(
                          values[additionalValidationConfig.formGroup]
                      ),
                  }
                : validationConfigRef.current.prepareModelForSubmit(values)
            // console.log('submit values', submitValues)
            // console.log('redirectUrl', detailSearchAction)
            dispatch(detailSearchAction(submitValues))
            toggle()
            history.push({
                pathname: `/${redirectPage}`,
                // state: {
                //     detailSearch: true
                // }
            })
        },
    })

    const activeButon = document.querySelector('.nav-link.active')

    const handleClearDetailSearchStateAndForm = () => {
        const clearDetailSearchAction = clearDetailSearchActionRef.current
        const resetPagePagination = resetPagePaginationRef.current
        clearDetailSearchAction && dispatch(clearDetailSearchAction())
        resetPagePagination && dispatch(resetPagePagination())
        validation.resetForm()
        handleInputChange(validation.initialValues)
    }

    useEffect(() => {
        activeButon && activeButon.addEventListener('click', handleClearDetailSearchStateAndForm)
        return () => activeButon && activeButon.removeEventListener('click', handleClearDetailSearchStateAndForm)
    }, [activeButon])

    /* useLocationChange((location, prevLocation) => {
        //console.log('Location changed', location, prevLocation)
        if (location.pathname === '/projects' && prevLocation && prevLocation.pathname === '/projects' && location.state && location.state.clearDetailSearch) {
            validation.resetForm()
            console.log('Form projects has reset!!!')
        }

        if (location.pathname === '/properties' && prevLocation && prevLocation.pathname === '/properties' && location.state && location.state.clearDetailSearch) {
            validation.resetForm()
            console.log('Form properties has reset!!!')
        }

        if (location.pathname === '/contacts' && prevLocation && prevLocation.pathname === '/contacts' && location.state && location.state.clearDetailSearch) {
            validation.resetForm()
            console.log('Form contacts has reset!!!')
        }

        if (location.pathname === '/companies' && prevLocation && prevLocation.pathname === '/companies' && location.state && location.state.clearDetailSearch) {
            validation.resetForm()
            console.log('Form companies has reset!!!')
        }
    }) */

    // useEffect(() => {
    //     if(type === DETAIL_SEARCH_TYPE.CONTACT) {
    //         if(countryMainValue){
    //             dispatch(fetchDetailSearchLocationsCity({ country: countryMainValue }))
    //             if(inputs.columns){
    //                 if(cityMainIndex > -1) {
    //                     inputs.columns[1][cityMainIndex].disabled = false;
    //                 }
    //             }
    //         } else {
    //             validation.setFieldValue(`${validationConfig.formGroup}.county`, []);
    //             if(inputs.mainForm) {
    //                 if(cityMainIndex > -1) {
    //                     inputs.columns[1][cityMainIndex].disabled = true;
    //                 }
    //             }
    //         }
    //     }
    // }, [type, countryMainValue])

    useDetailSearchProjectEffect(type, validation)
    useDetailSearchPropertyEffect(type, validation, inputs)
    useDetailSearchContactEffect(type, validation, inputs, additionalInputs, validationConfig, additionalValidationConfig)

    const debouncedCountCall = useCallback(
        debounce((val) => {
            const submitValues = additionalValidationConfig
                ? {
                      [validationConfig.formGroup]: validationConfigRef.current.prepareModelForSubmit(val[validationConfig.formGroup]),
                      [additionalValidationConfig.formGroup]: additionalValidationConfig.prepareModelForSubmit(
                          val[additionalValidationConfig.formGroup]
                      ),
                  }
                : validationConfigRef.current.prepareModelForSubmit(val)

            if (!fetchCountAction) return
            
            setLoadingCount(true)

            dispatch(
                fetchCountAction(
                    {
                        criteria: submitValues,
                    },
                    (res) => {
                        const { data, submitStatus } = res

                        setLoadingCount(false)
                        if (submitStatus === SUBMIT_RESPONSE_STATUS.SUCCESS) {
                            if (data && 'count' in data) {
                                setCount(data.count)
                            }
                        }
                    }
                )
            )
        }, 500),
        [fetchCountAction]
    )

    const handleInputChange = useCallback(
        (initialValues) => {
            let newValues

            if (initialValues) {
                newValues = initialValues
            } else {
                newValues = { ...validation.values }
            }

            debouncedCountCall(newValues) // Call API after debounce
        },
        [debouncedCountCall, validation.values]
    )

    useDidUpdateEffect(() => {
        if (validation.dirty) {
            handleInputChange()
        }
    }, [validation.values])

    return (
        <>
            <Modal size={size} isOpen={isOpen} id={htmlId} toggle={toggle}>
                <ModalHeader toggle={toggle}>{title}</ModalHeader>
                <ModalBody>
                    <FormikProvider value={validation}>
                        <Form
                            className="needs-validation"
                            name="event-form"
                            id="form-event"
                            onSubmit={(e) => {
                                e.preventDefault()
                                validation.handleSubmit()
                                return false
                            }}
                        >
                            <RecursiveForm
                                config={inputs?.config}
                                inputs={inputs.config ? inputs.columns : inputs?.mainForm}
                                formik={validation}
                                selectOptions={selectOptions}
                                inputMargin="mb-2"
                                subform={additionalValidationConfig ? validationConfig.formGroup : ''}
                            />

                            {additionalInputs && (
                                <>
                                    <Row className="justify-content-start">
                                        <Col>
                                            <h5 className="mt-3 mb-4 fs-14 fw-normal">{subtitle}</h5>
                                        </Col>
                                    </Row>

                                    <RecursiveForm
                                        config={additionalInputs?.config}
                                        inputs={additionalInputs.config ? additionalInputs.columns : additionalInputs?.mainForm}
                                        formik={validation}
                                        selectOptions={additionalSelectOptions}
                                        inputMargin="mb-2"
                                        subform={additionalValidationConfig.formGroup}
                                    />
                                </>
                            )}

                            <Row className="justify-content-end mb-3 mt-2">
                                <Col className="d-flex justify-content-end align-items-center">
                                    <div className="me-4 d-flex align-items-center">
                                        {loadingCount && <Loader />}
                                        {t('form.detailSearch.results')}
                                        <span className="fw-medium mx-1">{count}</span>
                                    </div>
                                    <SearchButton type="submit" />
                                </Col>
                            </Row>
                            <Row className="justify-content-end">
                                <Col className="d-flex justify-content-end align-items-center">
                                    <span className="text-danger" onClick={handleClearDetailSearchStateAndForm} style={{ cursor: 'pointer' }}>
                                        <i className="mdi mdi-trash-can-outline fs-17"></i> {t('button.cancelSearch')}
                                    </span>
                                </Col>
                            </Row>
                        </Form>
                    </FormikProvider>
                </ModalBody>
            </Modal>
        </>
    )
}

export default DetailSearchModal
