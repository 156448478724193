import { diaryDetailSearchFields } from './fields'
import { baseTextInput, baseDateInput } from '../fieldTypes'
import i18n from '../../../i18n'

export const diaryDetailSearchFieldTypes = () => {
    return {
        [diaryDetailSearchFields.ID]: {
            ...baseTextInput,
            name: diaryDetailSearchFields.ID,
            label: i18n.t('app.common.propertyId'),
            colProps: {
                sm: 6,
            },
        },
        [diaryDetailSearchFields.FULL_NAME_SELLER]: {
            ...baseTextInput,
            name: diaryDetailSearchFields.FULL_NAME_SELLER,
            label: i18n.t('form.detailSearch.diary.fullNameSeller'),
            colProps: {
                sm: 6,
            },
        },
        [diaryDetailSearchFields.FULL_NAME_BUYER]: {
            ...baseTextInput,
            name: diaryDetailSearchFields.FULL_NAME_BUYER,
            label: i18n.t('form.detailSearch.diary.fullNameBuyer'),
            colProps: {
                sm: 6,
            },
        },
        [diaryDetailSearchFields.LOCATION]: {
            ...baseTextInput,
            name: diaryDetailSearchFields.LOCATION,
            label: i18n.t('app.common.location'),
            colProps: {
                sm: 6,
            },
        },
        [diaryDetailSearchFields.DIARY_DATE_FROM]: {
            ...baseDateInput,
            colProps: {
                sm: 6,
                className: 'pe-0',
            },
            name: diaryDetailSearchFields.DIARY_DATE_FROM,
            placeholder: i18n.t('app.common.from'),
        },
        [diaryDetailSearchFields.DIARY_DATE_TO]: {
            ...baseDateInput,
            colProps: {
                sm: 6,
            },
            name: diaryDetailSearchFields.DIARY_DATE_TO,
            placeholder: i18n.t('app.common.to'),
        },
    }
}
