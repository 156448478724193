import { combineReducers } from 'redux'
import { handleActions } from 'redux-actions'
import { fetchPropertySetSuccess } from './actions'

const items = handleActions(
    {
        [fetchPropertySetSuccess]: (state, { payload }) => [...payload],
    },
    []
)

export default combineReducers({
    items,
})
