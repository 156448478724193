import { put, takeLatest } from 'redux-saga/effects'
import { IS_ERSTE } from '../../common/constants'
import { apiRequest } from '../api'
import { fetchPropertySet, fetchPropertySetFailure, fetchPropertySetRequest, fetchPropertySetSuccess } from './actions'

export function* fetchPropertySetSaga() {
    if (!IS_ERSTE) return 

    yield put(fetchPropertySetRequest())

    try {
        const response = yield apiRequest('v1/property/values/propertySet', {
            method: 'GET',
        })

        yield put(fetchPropertySetSuccess(response.data))
    } catch (error) {
        yield put(fetchPropertySetFailure(error))
    }
}

export default function* actionWatcher() {
    yield takeLatest(`${fetchPropertySet}`, fetchPropertySetSaga)
}
