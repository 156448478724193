import { handleActions } from 'redux-actions'
import {
    fetchContactValuesAvailableEntitiesSuccess,
    fetchContactValuesCompanyDefinitionSuccess,
    fetchContactValuesCompanySizeSuccess,
    fetchContactValuesCoreBusinessSuccess,
    fetchContactValuesCountrySuccess,
    fetchContactValuesDefinitionSuccess,
    fetchContactValuesDescriptionSuccess,
    fetchContactValuesEmailTypeSuccess,
    fetchContactValuesFinancingSuccess,
    fetchContactValuesGenderSuccess,
    fetchContactValuesJobTitleSuccess,
    fetchContactValuesLegalFormSuccess,
    fetchContactValuesMartialStatusSuccess,
    fetchContactValuesPhoneTypeSuccess,
    fetchContactValuesPrioritySuccess,
    fetchContactValuesProfileDurationSuccess,
    fetchContactValuesProfileRemarkSuccess,
    fetchContactValuesProfileServiceSuccess,
    fetchContactValuesProfileStatusSuccess,
    fetchContactValuesProfileSuccess,
    fetchContactValuesQueryCategorySuccess,
    fetchContactValuesSourceSuccess,
    fetchContactValuesStatusSuccess,
    fetchContactValuesTitleSuccess,
} from './actions'

export const initialState = {
    availableentities: {},
    companysize: {},
    corebusiness: {},
    definition: {},
    companydefinition: {},
    description: {},
    emailtype: {},
    gender: {},
    jobtitle: {},
    legalform: {},
    martialstatus: {},
    phonetype: {},
    priority: {},
    queryCategory: {},
    status: {},
    title: {},
    financing: {},
    contactsource: {},
    profile: {},
    profileduration: {},
    profilestatus: {},
    profileremark: {},
    profileservice: {},
    country: {},
}

export default handleActions(
    {
        [fetchContactValuesAvailableEntitiesSuccess]: (state, { payload }) => ({
            ...state,
            availableentities: payload.reduce(
                (dict, item) => ({
                    ...dict,
                    [item]: item,
                }),
                {}
            ),
        }),
        [fetchContactValuesCompanySizeSuccess]: (state, { payload }) => ({
            ...state,
            companysize: payload.reduce(
                (dict, item) => ({
                    ...dict,
                    [item.id]: item,
                }),
                {}
            ),
        }),
        [fetchContactValuesCoreBusinessSuccess]: (state, { payload }) => ({
            ...state,
            corebusiness: payload.reduce(
                (dict, item) => ({
                    ...dict,
                    [item.id]: item,
                }),
                {}
            ),
        }),
        [fetchContactValuesDefinitionSuccess]: (state, { payload }) => ({
            ...state,
            definition: payload.reduce(
                (dict, item) => ({
                    ...dict,
                    [item.id]: item,
                }),
                {}
            ),
        }),
        [fetchContactValuesCompanyDefinitionSuccess]: (state, { payload }) => ({
            ...state,
            companydefinition: payload.reduce(
                (dict, item) => ({
                    ...dict,
                    [item.id]: item,
                }),
                {}
            ),
        }),
        [fetchContactValuesDescriptionSuccess]: (state, { payload }) => ({
            ...state,
            description: payload.reduce(
                (dict, item) => ({
                    ...dict,
                    [item.id]: item,
                }),
                {}
            ),
        }),
        [fetchContactValuesEmailTypeSuccess]: (state, { payload }) => ({
            ...state,
            emailtype: payload.reduce(
                (dict, item) => ({
                    ...dict,
                    [item.id]: item,
                }),
                {}
            ),
        }),
        [fetchContactValuesGenderSuccess]: (state, { payload }) => ({
            ...state,
            gender: payload.reduce(
                (dict, item) => ({
                    ...dict,
                    [item.id]: item,
                }),
                {}
            ),
        }),
        [fetchContactValuesJobTitleSuccess]: (state, { payload }) => ({
            ...state,
            jobtitle: payload.reduce(
                (dict, item) => ({
                    ...dict,
                    [item.id]: item,
                }),
                {}
            ),
        }),
        [fetchContactValuesLegalFormSuccess]: (state, { payload }) => ({
            ...state,
            legalform: payload.reduce(
                (dict, item) => ({
                    ...dict,
                    [item.id]: item,
                }),
                {}
            ),
        }),
        [fetchContactValuesMartialStatusSuccess]: (state, { payload }) => ({
            ...state,
            martialstatus: payload.reduce(
                (dict, item) => ({
                    ...dict,
                    [item.id]: item,
                }),
                {}
            ),
        }),
        [fetchContactValuesPhoneTypeSuccess]: (state, { payload }) => ({
            ...state,
            phonetype: payload.reduce(
                (dict, item) => ({
                    ...dict,
                    [item.id]: item,
                }),
                {}
            ),
        }),
        [fetchContactValuesPrioritySuccess]: (state, { payload }) => ({
            ...state,
            priority: payload.reduce(
                (dict, item) => ({
                    ...dict,
                    [item.id]: item,
                }),
                {}
            ),
        }),
        [fetchContactValuesQueryCategorySuccess]: (state, { payload }) => ({
            ...state,
            querycategory: payload.reduce(
                (dict, item) => ({
                    ...dict,
                    [item.id]: item,
                }),
                {}
            ),
        }),
        [fetchContactValuesStatusSuccess]: (state, { payload }) => ({
            ...state,
            status: payload.reduce(
                (dict, item) => ({
                    ...dict,
                    [item.id]: item,
                }),
                {}
            ),
        }),
        [fetchContactValuesTitleSuccess]: (state, { payload }) => ({
            ...state,
            title: payload.reduce(
                (dict, item) => ({
                    ...dict,
                    [item.id]: item,
                }),
                {}
            ),
        }),
        [fetchContactValuesSourceSuccess]: (state, { payload }) => ({
            ...state,
            contactsource: payload.reduce(
                (dict, item) => ({
                    ...dict,
                    [item.id]: item,
                }),
                {}
            ),
        }),
        [fetchContactValuesFinancingSuccess]: (state, { payload }) => ({
            ...state,
            financing: payload.reduce(
                (dict, item) => ({
                    ...dict,
                    [item.id]: item,
                }),
                {}
            ),
        }),
        [fetchContactValuesProfileSuccess]: (state, { payload }) => ({
            ...state,
            profile: payload.reduce(
                (dict, item) => ({
                    ...dict,
                    [item.id]: item,
                }),
                {}
            ),
        }),
        [fetchContactValuesProfileDurationSuccess]: (state, { payload }) => ({
            ...state,
            profileduration: payload.reduce(
                (dict, item) => ({
                    ...dict,
                    [item.id]: item,
                }),
                {}
            ),
        }),
        [fetchContactValuesProfileStatusSuccess]: (state, { payload }) => ({
            ...state,
            profilestatus: payload.reduce(
                (dict, item) => ({
                    ...dict,
                    [item.id]: item,
                }),
                {}
            ),
        }),
        [fetchContactValuesProfileRemarkSuccess]: (state, { payload }) => ({
            ...state,
            profileremark: payload.reduce(
                (dict, item) => ({
                    ...dict,
                    [item.id]: item,
                }),
                {}
            ),
        }),
        [fetchContactValuesProfileServiceSuccess]: (state, { payload }) => ({
            ...state,
            profileservice: payload.reduce(
                (dict, item) => ({
                    ...dict,
                    [item.id]: item,
                }),
                {}
            ),
        }),
        [fetchContactValuesCountrySuccess]: (state, { payload }) => ({
            ...state,
            country: payload.reduce(
                (dict, item) => ({
                    ...dict,
                    [item.id]: item,
                }),
                {}
            ),
        }),
    },
    initialState
)
