import { handleActions } from 'redux-actions'
import { v4 as uuid } from 'uuid'
import {
    clearPhotos,
    clearQuePhoto,
    deletePhotoSuccess,
    fetchPhotosFailure,
    fetchPhotosRequest,
    fetchPhotosSuccess,
    proxyPhotoEditClear,
    proxyPhotoEditFailure,
    proxyPhotoEditRequest,
    proxyPhotoEditSuccess,
    proxyPhotoUpdateList,
    quePhotoSuccess,
    reorderPhotos,
    syncPhotoSuccess,
    updatePhotoFailure,
    updatePhotoRequest,
    updatePhotoSuccess,
    updatePhotosCount,
    uploadPhotoFailure,
    uploadPhotoRequest,
    uploadPhotoSuccess,
} from './actions'

export const initialState = {
    items: [],
    count: 0,
    fetching: false,
    uploading: false,
    updating: false,
    error: null,
    que: {},
    proxyPhoto: null,
    proxyPhotoFetching: false,
    proxyPhotoError: null,
    translations: {},
}

export default handleActions(
    {
        [fetchPhotosRequest]: (state) => ({
            ...state,
            fetching: true,
            error: null,
        }),
        [fetchPhotosSuccess]: (state, { payload }) => {
            return {
                ...state,
                count: payload?.count ?? 0,
                items: payload?.items ?? [],
                fetching: false,
            }
        },
        [fetchPhotosFailure]: (state, { payload }) => ({
            ...state,
            error: payload,
            fetching: false,
        }),
        [uploadPhotoRequest]: (state) => {
            return {
                ...state,
                uploading: true,
            }
        },
        [uploadPhotoSuccess]: (state, { payload }) => {
            // TODO: backend expects file_sync but returns fileSync
            const updatedPayload = {
                ...payload,
                fileSync: payload.fileSync || payload.file_sync,
            }

            return {
                ...state,
                items: [...state.items, updatedPayload],
                uploading: false,
                translations: payload.translations,
            }
        },
        [uploadPhotoFailure]: (state) => {
            return {
                ...state,
                uploading: false,
            }
        },
        [deletePhotoSuccess]: (state, { payload }) => {
            const updatedPhotos = state.items.filter((photo) => photo.id !== +payload).map((photo, index) => ({ ...photo, priority: index + 1 }))

            return {
                ...state,
                deleting: false,
                items: updatedPhotos,
                count: state.count - 1,
            }
        },

        [syncPhotoSuccess]: (state, { payload }) => {
            const updatedPhotos = state.items.map((photo) => (photo.id === +payload ? { ...photo, fileSync: !photo.fileSync } : photo))

            return {
                ...state,
                items: updatedPhotos,
            }
        },
        [reorderPhotos]: (state, { payload }) => {
            const { fromIndex, toIndex } = payload

            const photos = [...state.items]
            const [removedItem] = photos.splice(fromIndex, 1)
            photos.splice(toIndex, 0, removedItem)

            const updatedPhotos = photos.map((item, index) => ({
                ...item,
                priority: index + 1,
            }))

            return {
                ...state,
                items: updatedPhotos,
            }
        },

        [proxyPhotoEditRequest]: (state) => ({
            ...state,
            proxyPhotoFetching: true,
            proxyPhotoError: null,
            proxyPhoto: null,
        }),
        [proxyPhotoEditSuccess]: (state, { payload }) => {
            return {
                ...state,
                proxyPhoto: payload,
                proxyPhotoFetching: false,
            }
        },
        [proxyPhotoEditFailure]: (state, { payload }) => ({
            ...state,
            proxyPhotoError: payload,
            proxyPhotoFetching: false,
        }),
        [proxyPhotoEditClear]: (state) => ({
            ...state,
            proxyPhoto: null,
        }),
        [proxyPhotoUpdateList]: (state) => {
            const items = [...state.items]

            return {
                ...state,
                items: items,
            }
        },
        [updatePhotoRequest]: (state) => {
            return {
                ...state,
                updating: true,
            }
        },
        [updatePhotoSuccess]: (state, { payload }) => {
            const { id, file } = payload

            const updatedPhotos = state.items.map((photo) => (photo.id === id ? { ...photo, base64: file } : photo))

            return {
                ...state,
                items: updatedPhotos,
                updating: false,
            }
        },
        [updatePhotoFailure]: (state) => {
            return {
                ...state,
                updating: false,
            }
        },
        [updatePhotosCount]: (state, { payload }) => {
            return {
                ...state,
                count: payload,
            }
        },
        [clearPhotos]: (state) => {
            return {
                ...state,
                items: [],
            }
        },

        // TODO: quePhoto probably not needed anymore
        [quePhotoSuccess]: (state, { payload }) => {
            console.log('que payload', payload)
            return {
                ...state,
                que: {
                    ...state.que,
                    ...payload?.reduce((acc, item) => {
                        // let parsedItem = JSON.parse(item);
                        let id = uuid()
                        return {
                            ...acc,
                            [id]: Object.assign(item, {
                                id: id,
                                tmpId: id,
                                preview: URL.createObjectURL(item),
                                // formattedSize: formatBytes(file.size),
                                queue: true,
                                uploading: false,
                            }),
                        }
                    }, {}),
                },
            }
        },
        [clearQuePhoto]: (state, { payload, file }) => {
            return {
                ...state,
                que: {},
            }
        },
    },
    initialState
)
