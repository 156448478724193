import { handleActions } from "redux-actions";
import { fetchProjectValuesAvailableEntitiesSuccess, fetchProjectValuesDevelopmentStageSuccess, fetchProjectValuesObjectTypeSuccess, fetchProjectValuesSizeSuccess, fetchProjectValuesStatusSuccess, fetchProjectValuesTypeSuccess } from "./actions";

export const initialState = {
    availableentities: {},
    developmentstage: {},
    objecttype: {},
    size: {},
    status: {},
    type: {}
}

export default handleActions(
    {
        [fetchProjectValuesAvailableEntitiesSuccess]: (state, { payload }) => ({
            ...state,
            availableentities: payload.reduce(
                (dict, item) => ({
                    ...dict,
                    [item]: item
                }),
                {}
            )
        }),
        [fetchProjectValuesDevelopmentStageSuccess]: (state, { payload }) => ({
            ...state,
            developmentstage: payload.reduce(
                (dict, item) => ({
                    ...dict,
                    [item.id]: item
                }),
                {}
            )
        }),
        [fetchProjectValuesObjectTypeSuccess]: (state, { payload }) => ({
            ...state,
            objecttype: payload.reduce(
                (dict, item) => ({
                    ...dict,
                    [item.id]: item
                }),
                {}
            )
        }),
        [fetchProjectValuesSizeSuccess]: (state, { payload }) => ({
            ...state,
            size: payload.reduce(
                (dict, item) => ({
                    ...dict,
                    [item.id]: item
                }),
                {}
            )
        }),
        [fetchProjectValuesStatusSuccess]: (state, { payload }) => ({
            ...state,
            status: payload.reduce(
                (dict, item) => ({
                    ...dict,
                    [item.id]: item
                }),
                {}
            )
        }),
        [fetchProjectValuesTypeSuccess]: (state, { payload }) => ({
            ...state,
            type: payload.reduce(
                (dict, item) => ({
                    ...dict,
                    [item.id]: item
                }),
                {}
            )
        }),
    },
    initialState
);