import { createAction } from "redux-actions";

const ENTITY = 'PROJECTVALUES';
export const stateKey = ENTITY.toLowerCase();

const AVAILABLEENTITIES = 'AVAILABLEENTITIES';
const DEVELOPMENTSTAGE = "DEVELOPMENTSTAGE";
const OBJECTTYPE = "OBJECTTYPE";
const SIZE = "SIZE";
const STATUS = "STATUS";
const TYPE = "TYPE";


export const fetchProjectValuesAvailableEntities = createAction(`${ENTITY}/${AVAILABLEENTITIES}/FETCH`);
export const fetchProjectValuesAvailableEntitiesRequest = createAction(`${ENTITY}/${AVAILABLEENTITIES}/FETCH_REQUEST`);
export const fetchProjectValuesAvailableEntitiesSuccess = createAction(`${ENTITY}/${AVAILABLEENTITIES}/FETCH_SUCCESS`);
export const fetchProjectValuesAvailableEntitiesFailure = createAction(`${ENTITY}/${AVAILABLEENTITIES}/FETCH_FAILURE`);

export const fetchProjectValuesSize = createAction(`${ENTITY}/${SIZE}/FETCH`);
export const fetchProjectValuesSizeRequest = createAction(`${ENTITY}/${SIZE}/FETCH_REQUEST`);
export const fetchProjectValuesSizeSuccess = createAction(`${ENTITY}/${SIZE}/FETCH_SUCCESS`);
export const fetchProjectValuesSizeFailure = createAction(`${ENTITY}/${SIZE}/FETCH_FAILURE`);

export const fetchProjectValuesDevelopmentStage = createAction(`${ENTITY}/${DEVELOPMENTSTAGE}/FETCH`);
export const fetchProjectValuesDevelopmentStageRequest = createAction(`${ENTITY}/${DEVELOPMENTSTAGE}/FETCH_REQUEST`);
export const fetchProjectValuesDevelopmentStageSuccess = createAction(`${ENTITY}/${DEVELOPMENTSTAGE}/FETCH_SUCCESS`);
export const fetchProjectValuesDevelopmentStageFailure = createAction(`${ENTITY}/${DEVELOPMENTSTAGE}/FETCH_FAILURE`);

export const fetchProjectValuesObjectType = createAction(`${ENTITY}/${OBJECTTYPE}/FETCH`);
export const fetchProjectValuesObjectTypeRequest = createAction(`${ENTITY}/${OBJECTTYPE}/FETCH_REQUEST`);
export const fetchProjectValuesObjectTypeSuccess = createAction(`${ENTITY}/${OBJECTTYPE}/FETCH_SUCCESS`);
export const fetchProjectValuesObjectTypeFailure = createAction(`${ENTITY}/${OBJECTTYPE}/FETCH_FAILURE`);

export const fetchProjectValuesStatus = createAction(`${ENTITY}/${STATUS}/FETCH`);
export const fetchProjectValuesStatusRequest = createAction(`${ENTITY}/${STATUS}/FETCH_REQUEST`);
export const fetchProjectValuesStatusSuccess = createAction(`${ENTITY}/${STATUS}/FETCH_SUCCESS`);
export const fetchProjectValuesStatusFailure = createAction(`${ENTITY}/${STATUS}/FETCH_FAILURE`);

export const fetchProjectValuesType = createAction(`${ENTITY}/${TYPE}/FETCH`);
export const fetchProjectValuesTypeRequest = createAction(`${ENTITY}/${TYPE}/FETCH_REQUEST`);
export const fetchProjectValuesTypeSuccess = createAction(`${ENTITY}/${TYPE}/FETCH_SUCCESS`);
export const fetchProjectValuesTypeFailure = createAction(`${ENTITY}/${TYPE}/FETCH_FAILURE`);