import { getIn, useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Button, FormFeedback, Row } from 'reactstrap'
import * as Yup from 'yup'
import { documentFields } from '../common/forms/documents/fields'
import { updateDocument, uploadDocument } from '../store/documents'
import Dropzone from './Dropzone/Dropzone'
import { useDocumentFormConfig, useDocumentFormSelectOptions } from './Hooks/FormHooks'
import FormValidationErrorModal from './Modals/FormValidationErrorModal'
import RecursiveForm from './RecursiveForm/RecursiveForm'

const DocumentForm = ({ hasUpload, isEdit, documentModule, documentData, contactType, parentId, activeTab }) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const [uploadErrorMessage, setUploadErrorMessage] = useState(null)
    const [isFormError, setIsFormError] = useState(false)
    const [isFileAdded, setIsFileAdded] = useState(false)
    const [isErrorModalActive, setIsErrorModalActive] = useState(false)

    const { documentInputs, documentValidationConfig } = useDocumentFormConfig(documentData)
    const selectOptions = useDocumentFormSelectOptions(contactType, !contactType ? documentData.parentElement : null)

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: documentValidationConfig.initialValues ?? {},
        validationSchema: Yup.object({
            document_type_id: Yup.string().required(t('form.errors.documents.document_type_id')),
        }),
        onSubmit: (values) => {
            if (values.id > 0) {
                dispatch(updateDocument(values))
            }
        },
    })

    const { setFieldValue, values } = formik

    const projectId = getIn(formik?.values, documentFields.DOCUMENT_PROJECT_ID)
    const propertyId = getIn(formik?.values, documentFields.DOCUMENT_PROPRETY_ID)
    const contactId = getIn(formik?.values, documentFields.DOCUMENT_CONTACT_ID)
    const documentType = getIn(formik?.values, documentFields.DOCUMENT_TYPE)
    const contactInput = documentInputs?.sideForm?.findIndex((x) => x.name === documentFields.DOCUMENT_CONTACT_ID)
    const projectInput = documentInputs?.sideForm?.findIndex((x) => x.name === documentFields.DOCUMENT_PROJECT_ID)
    const propertyInput = documentInputs?.sideForm?.findIndex((x) => x.name === documentFields.DOCUMENT_PROPRETY_ID)

    const toggleErrorModal = () => {
        setIsErrorModalActive(!isErrorModalActive)
    }

    // TODO: this useEffect is refactored (below is old commented out useEffect just for the reference)
    useEffect(() => {
        const { document_project_id, document_property_id, document_contact_id } = values

        const disableInput = (inputIndex, field, value, condition) => {
            if (inputIndex > -1) {
                documentInputs.sideForm[inputIndex].disabled = condition
                setFieldValue(field, value)
            }
        }

        switch (true) {
            case Boolean(document_project_id):
                // documentModule signifies if form is inside certain module (project, property, contact) or not
                // if it's not (eg. Documents list), than field should be enabled
                disableInput(projectInput, documentFields.DOCUMENT_PROJECT_ID, document_project_id, documentModule ? true : false)
                disableInput(propertyInput, documentFields.DOCUMENT_PROPRETY_ID, '', true)
                disableInput(contactInput, documentFields.DOCUMENT_CONTACT_ID, '', true)
                break
            case Boolean(document_property_id):
                disableInput(projectInput, documentFields.DOCUMENT_PROJECT_ID, '', true)
                disableInput(propertyInput, documentFields.DOCUMENT_PROPRETY_ID, document_property_id, documentModule ? true : false)
                disableInput(contactInput, documentFields.DOCUMENT_CONTACT_ID, '', true)
                break
            case Boolean(document_contact_id):
                disableInput(projectInput, documentFields.DOCUMENT_PROJECT_ID, '', true)
                disableInput(propertyInput, documentFields.DOCUMENT_PROPRETY_ID, '', true)
                disableInput(contactInput, documentFields.DOCUMENT_CONTACT_ID, document_contact_id, documentModule ? true : false)
                break
            default:
                break
        }
    }, [values, documentInputs.sideForm, projectInput, propertyInput, contactInput, documentModule, setFieldValue])

    // useEffect(() => {
    //     if (!documentModule) {
    //         if (projectId) {
    //             console.log('projectId', projectId)
    //             console.log('propertyInput', propertyInput)

    //             if (propertyInput > -1) {
    //                 console.log('here')
    //                 documentInputs.sideForm[propertyInput].disabled = true
    //                 formik.setFieldValue(documentFields.DOCUMENT_PROPRETY_ID, '')
    //             }
    //             if (contactInput > -1) {
    //                 documentInputs.sideForm[contactInput].disabled = true
    //                 formik.setFieldValue(documentFields.DOCUMENT_CONTACT_ID, '')
    //             }
    //         } else {
    //             if (propertyInput > -1) {
    //                 documentInputs.sideForm[propertyInput].disabled = false
    //             }
    //             if (contactInput > -1) {
    //                 documentInputs.sideForm[contactInput].disabled = false
    //             }
    //         }
    //         if (contactId) {
    //             // console.log('contactId', contactId)
    //             if (projectInput > -1) {
    //                 documentInputs.sideForm[projectInput].disabled = true
    //                 formik.setFieldValue(documentFields.DOCUMENT_PROJECT_ID, '')
    //             }

    //             if (propertyInput > -1) {
    //                 documentInputs.sideForm[propertyInput].disabled = true
    //                 formik.setFieldValue(documentFields.DOCUMENT_PROPRETY_ID, '')
    //             }
    //         } else {
    //             if (projectInput > -1) {
    //                 documentInputs.sideForm[projectInput].disabled = false
    //             }
    //             if (propertyInput > -1) {
    //                 documentInputs.sideForm[propertyInput].disabled = false
    //             }
    //         }

    //         if (propertyId) {
    //             // console.log('contactInput', projectId);
    //             if (projectInput > -1) {
    //                 documentInputs.sideForm[projectInput].disabled = true
    //                 formik.setFieldValue(documentFields.DOCUMENT_PROJECT_ID, '')
    //             }
    //             if (contactInput > -1) {
    //                 documentInputs.sideForm[contactInput].disabled = true
    //                 formik.setFieldValue(documentFields.DOCUMENT_CONTACT_ID, '')
    //             }
    //         } else {
    //             if (projectInput > -1) {
    //                 documentInputs.sideForm[projectInput].disabled = false
    //             }
    //             if (contactInput > -1) {
    //                 documentInputs.sideForm[contactInput].disabled = false
    //             }
    //         }
    //     } else {
    //         switch (documentModule) {
    //             case 'property':
    //                 if (projectInput > -1) {
    //                     documentInputs.sideForm[projectInput].disabled = true
    //                     formik.setFieldValue(documentFields.DOCUMENT_PROJECT_ID, '')
    //                 }
    //                 if (contactInput > -1) {
    //                     documentInputs.sideForm[contactInput].disabled = true
    //                     formik.setFieldValue(documentFields.DOCUMENT_CONTACT_ID, '')
    //                 }

    //                 break
    //             case 'project':
    //                 if (propertyInput > -1) {
    //                     documentInputs.sideForm[propertyInput].disabled = true
    //                     formik.setFieldValue(documentFields.DOCUMENT_PROPRETY_ID, '')
    //                 }
    //                 if (contactInput > -1) {
    //                     documentInputs.sideForm[contactInput].disabled = true
    //                     formik.setFieldValue(documentFields.DOCUMENT_CONTACT_ID, '')
    //                 }
    //                 break
    //             case 'contact':
    //                 if (propertyInput > -1) {
    //                     documentInputs.sideForm[propertyInput].disabled = true
    //                     formik.setFieldValue(documentFields.DOCUMENT_PROPRETY_ID, '')
    //                 }
    //                 if (projectInput > -1) {
    //                     documentInputs.sideForm[projectInput].disabled = true
    //                     formik.setFieldValue(documentFields.DOCUMENT_PROJECT_ID, '')
    //                 }
    //                 break
    //             default:
    //                 break
    //         }
    //     }
    // }, [documentModule, contactId, propertyId, projectId])

    useEffect(() => {
        if (!documentType && uploadErrorMessage) {
            setIsErrorModalActive(true)
        }
    }, [documentType, uploadErrorMessage])

    useEffect(() => {
        if (isFileAdded && isFormError && documentType) {
            setUploadErrorMessage(t('form.errors.documents.upload'))
            setIsFormError(false)
        }
    }, [isFileAdded, isFormError, documentType, t])

    // TODO: this function is huge, needs to be refactored
    const handleDocumentUpload = (file) => {
        if (!contactId && !propertyId && !projectId) {
            let errorMsg = `${t('form.project.documents.errorMsg')} `
            if (documentModule) {
                switch (documentModule) {
                    case 'property':
                        errorMsg = errorMsg + t('app.common.propertyId')
                        break
                    case 'project':
                        errorMsg = errorMsg + t('app.common.projectId')
                        break
                    case 'contact':
                        errorMsg = errorMsg + t('form.project.documents.contact')
                        break
                    default:
                        break
                }
            }
            setUploadErrorMessage(errorMsg)
            return
        }

        if (!documentType) {
            setUploadErrorMessage(t('form.errors.documents.document_type_id'))
            setIsFormError(true)
        }

        if (file) {
            setIsFileAdded(true)

            const fileData = { ...file }[0]
            const [fileName, fileExtension] = file[0].name.split('.')

            // to actually see fromData content you need to convert it to js object
            let formData = new FormData()
            formData.append('file', fileData)

            const docTypeValue = getIn(formik?.values, documentFields.DOCUMENT_TYPE)
            const docTypeLabel = selectOptions?.[documentFields.DOCUMENT_TYPE]?.find((x) => x.value === docTypeValue?.toString())?.label

            const currentLang = localStorage.getItem('i18nextLng')

            Object.assign(fileData, {
                translations: {
                    [currentLang]: {
                        fileDescription: fileName,
                    },
                },
                parentId: parentId,
                parent: parentId,
                order: 0,
                module: documentModule,
                collection: 'document',
                documentCreationDate: getIn(formik?.values, documentFields.DOCUMENT_CREATION_DATE),
                documentValidityDate: getIn(formik?.values, documentFields.DOCUMENT_VALIDITY_DATE),
                documentType: {
                    id: docTypeValue,
                    name: docTypeLabel,
                },
                fileSync: getIn(formik?.values, documentFields.FILE_SYNC),
                fileExtension: fileExtension,
            })

            if (contactId) {
                formData.append('module', 'contact')
                formData.append('parent_id', contactId)
            }
            if (propertyId) {
                formData.append('module', 'property')
                formData.append('parent_id', propertyId)
            }

            if (projectId) {
                formData.append('module', 'project')
                formData.append('parent_id', projectId)
            }

            formData.append('collection', 'document')
            // console.log('appending creation date', getIn(formik?.values, documentFields.DOCUMENT_CONTACT_ID))
            formData.append(documentFields.DOCUMENT_CREATION_DATE, getIn(formik?.values, documentFields.DOCUMENT_CREATION_DATE))
            formData.append(documentFields.DOCUMENT_VALIDITY_DATE, getIn(formik?.values, documentFields.DOCUMENT_VALIDITY_DATE))
            formData.append(documentFields.DOCUMENT_PROJECT_ID, getIn(formik?.values, documentFields.DOCUMENT_PROJECT_ID))
            formData.append(documentFields.DOCUMENT_PROPRETY_ID, getIn(formik?.values, documentFields.DOCUMENT_PROPRETY_ID))
            formData.append(documentFields.DOCUMENT_CONTACT_ID, getIn(formik?.values, documentFields.DOCUMENT_CONTACT_ID))
            formData.append(documentFields.DOCUMENT_TYPE, getIn(formik?.values, documentFields.DOCUMENT_TYPE))
            const fileSyncValue = getIn(formik?.values, documentFields.FILE_SYNC)
            formData.append(documentFields.FILE_SYNC, fileSyncValue ? 1 : 0)

            let uplUrl = ''

            if (contactId) {
                uplUrl = 'contact/document'
            }
            if (propertyId) {
                uplUrl = 'property/document'
            }

            if (projectId) {
                uplUrl = 'project/document'
            }

            if (documentType) {
                dispatch(
                    uploadDocument(
                        {
                            fileData,
                            formData,
                            uploadUrl: uplUrl,
                        },
                        () => {
                            setUploadErrorMessage(null)
                            setIsFileAdded(false)
                            formik.resetForm()
                            formik.setFieldValue(documentFields.DOCUMENT_TYPE, '')
                        }
                    )
                )
            }
        }
    }

    return (
        <>
            {hasUpload && (
                <Row>
                    {uploadErrorMessage ? (
                        <FormFeedback type="invalid" className={`${uploadErrorMessage ? 'd-block text-center' : ''} my-2`}>
                            {uploadErrorMessage}
                        </FormFeedback>
                    ) : null}
                </Row>
            )}

            <RecursiveForm inputs={documentInputs?.sideForm} formik={formik} selectOptions={selectOptions} />

            {activeTab === 'documents' && <Dropzone callback={handleDocumentUpload} tab={activeTab} />}

            {isEdit && (
                <div className="border-top border-top-dashed py-3">
                    <div className="hstack gap-2">
                        <Button tag="a" color="success" onClick={() => formik.handleSubmit()} className="btn-label" disabled={false}>
                            <i className="mdi mdi-check align-bottom label-icon align-middle fs-16 me-2"></i>
                            {t('button.accept')}
                        </Button>
                    </div>
                </div>
            )}

            {isErrorModalActive && <FormValidationErrorModal validation={formik} inputs={[documentInputs]} onClose={() => toggleErrorModal(false)} />}
        </>
    )
}

export default DocumentForm
