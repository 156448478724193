import i18next from 'i18next'
import { formatDateTime } from '../../utils/general'
import { renderAreaUnit, renderCurrency, renderCurrencyWithUnit } from '../../utils/property'
import { IS_ERSTE } from '../constants'
import { projectResponseFields } from '../response/project'
import { getAgentsViewData, getOwnersViewData } from './utils'

export const projectGeneralViewData = (data, config) => {
    const { t } = i18next
    const currency = renderCurrency(config?.currency)
    const currencyWithUnit = renderCurrencyWithUnit(config?.currency, config?.areaUnit)
    const unit = renderAreaUnit(config?.areaUnit)

    return {
        general: [
            {
                id: projectResponseFields.ID,
                label: t('app.common.id'),
                value: data?.[projectResponseFields.ID],
            },
            {
                id: `${projectResponseFields.PROJECT_STATUS}_name`,
                label: t('app.common.status'),
                value: data?.[projectResponseFields.PROJECT_STATUS]?.name,
            },
            {
                id: `${projectResponseFields.PROJECT_END_DATE}`,
                label: t('app.common.project.endDate'),
                value: formatDateTime(data?.[projectResponseFields.PROJECT_END_DATE]),
            },
            {
                id: projectResponseFields.PROJECT_RECEIPT_DATE,
                label: t('app.common.project.receiptDate'),
                value: formatDateTime(data?.[projectResponseFields.PROJECT_RECEIPT_DATE]),
            },
            {
                id: projectResponseFields.CONTRACT_NO,
                label: t('app.common.contractNumber'),
                value: data?.[projectResponseFields.CONTRACT_NO],
            },
            {
                id: projectResponseFields.CONTRACT_DATE,
                label: t('app.common.contractDate'),
                value: formatDateTime(data?.contractDate),
            },
            {
                id: projectResponseFields.CREATED,
                label: t('view.common.other.created'),
                value: formatDateTime(data?.[projectResponseFields.CREATED]),
            },
            {
                id: projectResponseFields.UPDATED,
                label: t('app.common.updated'),
                value: formatDateTime(data?.[projectResponseFields.UPDATED]),
            },
            //     {
            //         id: projectResponseFields.DATA_SOURCE,
            //         label: 'Izvor podataka',
            //         value: data?.[projectResponseFields.DATA_SOURCE]?.name
            //     },
            //     {
            //         id: `${projectResponseFields.PROJECT}_name`,
            //         label: 'Projekt',
            //         value: data?.[projectResponseFields.PROJECT]?.name
            //     }
        ],
        location: [
            {
                id: projectResponseFields.COUNTRY,
                label: t('app.common.country'),
                value: data?.[projectResponseFields.COUNTRY]?.name,
            },
            {
                id: projectResponseFields.COUNTY,
                label: t('app.common.county'),
                value: data?.[projectResponseFields.COUNTY]?.name,
            },
            {
                id: projectResponseFields.CITY,
                label: t('app.common.cityMunicipality'),
                value: data?.[projectResponseFields.CITY]?.name,
            },
            {
                id: projectResponseFields.WIDER_AREA,
                label: t('app.common.widerArea'),
                value: data?.[projectResponseFields.WIDER_AREA]?.name,
            },
            {
                id: projectResponseFields.QUARTER,
                label: t('app.common.quarter'),
                value: data?.[projectResponseFields.QUARTER]?.name,
            },
            {
                id: projectResponseFields.ISLAND,
                label: t('app.common.island'),
                value: data?.[projectResponseFields.ISLAND]?.name,
            },
            {
                id: `${projectResponseFields.STREET}-${projectResponseFields.STREET_NUMBER}`,
                label: t('view.common.location.street'),
                value: data?.[projectResponseFields.STREET]
                    ? `${data?.[projectResponseFields.STREET] ?? ''} ${data?.[projectResponseFields.STREET_NUMBER] ?? ''}`
                    : null,
                icon: data?.[projectResponseFields.STREET_SYNC] ? <i className={`ms-1 text-success mdi mdi-check-circle fs-16`}></i> : null,
            },
        ],
        owners: getOwnersViewData(data?.[projectResponseFields.OWNER]),
        details: [
            {
                id: projectResponseFields.PROJECT_SIZE,
                label: t('view.project.details.projectSize'),
                value: data?.[projectResponseFields.PROJECT_SIZE]?.name,
            },
            {
                id: projectResponseFields.PROJECT_TYPE,
                label: t('app.common.project.type'),
                value: data?.[projectResponseFields.PROJECT_TYPE]?.name,
            },
            {
                id: projectResponseFields.PROJECT_OBJECT_TYPE,
                label: t('view.project.details.propertyType'),
                value: data?.[projectResponseFields.PROJECT_OBJECT_TYPE]?.name,
            },
            IS_ERSTE && {
                id: projectResponseFields.PROJECT_DEVELOPMENT_STAGE,
                label: t('app.common.project.developmentStage'),
                value: data?.[projectResponseFields.PROJECT_DEVELOPMENT_STAGE]?.name,
            },
            {
                id: projectResponseFields.PROJECT_ENERGY_EFFICIENCY,
                label: t('app.common.energyEfficiency'),
                value: data?.[projectResponseFields.PROJECT_ENERGY_EFFICIENCY]?.name,
            },
            {
                id: projectResponseFields.PROJECT_WEB_SYNC,
                label: t('app.common.project.webSync'),
                value: data?.[projectResponseFields.PROJECT_WEB_SYNC] ? t('view.common.conclusion.yes') : t('view.common.conclusion.no'),
            },
            IS_ERSTE && {
                id: projectResponseFields.PROJECT_WEB_NAVIGATION_SYNC,
                label: t('view.project.details.webNavigationSync'),
                value: data?.[projectResponseFields.PROJECT_WEB_NAVIGATION_SYNC] ? t('view.common.conclusion.yes') : t('view.common.conclusion.no'),
            },
            //     {
            //         id: projectResponseFields.AREA_NETT,
            //         label: <>Neto površina {unit}</>,
            //         value: data?.[projectResponseFields.AREA_NETT]
            //     },
            //     {
            //         id: projectResponseFields.PROPERTY_ROOMS_NO,
            //         label: 'Broj Soba',
            //         value: data?.[projectResponseFields.PROPERTY_ROOMS_NO]
            //     },
            //     {
            //         id: projectResponseFields.BEDROOMS_NO,
            //         label: 'Broj spavaćih soba',
            //         value: data?.[projectResponseFields.BEDROOMS_NO]
            //     },
            //     {
            //         id: projectResponseFields.BATHROOM_TOILET_NO,
            //         label: 'Broj kupaonica sa toaletom',
            //         value: data?.[projectResponseFields.AREA.BATHROOM_TOILET_NO]
            //     },
            //     {
            //         id: projectResponseFields.BATHROOM_NO,
            //         label: 'Broj kupaonica bez toaleta',
            //         value: data?.[projectResponseFields.BATHROOM_NO]
            //     },
            //     {
            //         id: projectResponseFields.TOILET_NO,
            //         label: 'Broj toaleta',
            //         value: data?.[projectResponseFields.TOILET_NO]
            //     },
            //     {
            //         id: projectResponseFields.CEILING_HEIGHT,
            //         label: 'Visina stropa (m)',
            //         value: data?.[projectResponseFields.CEILING_HEIGHT]
            //     },
            //     {
            //         id: projectResponseFields.CONSTRUCTION_YEAR,
            //         label: 'Godina izgradnje',
            //         value: data?.[projectResponseFields.CONSTRUCTION_YEAR]
            //     },
            //     {
            //         id: projectResponseFields.ADAPTATION_YEAR,
            //         label: 'Godina adaptacije',
            //         value: data?.[projectResponseFields.ADAPTATION_YEAR]
            //     },
            //     {
            //         id: projectResponseFields.PROPERTY_FLOOR_POSITION,
            //         label: 'Etaže',
            //         value: data?.[projectResponseFields.PROPERTY_FLOOR_POSITION]
            //     },
            //     {
            //         id: projectResponseFields.FLOOR_NO,
            //         label: 'Kat',
            //         value: data?.FLOOR_NO
            //     },
            //     {
            //         id: projectResponseFields.FLOORS_TOTAL_NO,
            //         label: 'Od katova',
            //         value: data?.[projectResponseFields.FLOORS_TOTAL_NO]
            //     },
            //     {
            //         id: projectResponseFields.PROPERTY_ORIENTATION,
            //         label: 'Orijentacija',
            //         value: data?.[projectResponseFields.PROPERTY_ORIENTATION]?.map((item, index) => (
            //             <span key={item.id}>
            //                 {item.name}{data?.[projectResponseFields.PROPERTY_ORIENTATION]?.length-1 !== index ? ', ' : ''}
            //             </span>
            //         ))
            //     },
            //     {
            //         id: projectResponseFields.PROPERTY_HEATING,
            //         label: 'Grijanje',
            //         value: data?.[projectResponseFields.PROPERTY_HEATING]?.map((item, index) => (
            //             <span key={item.id}>
            //                 {item.name}{data?.[projectResponseFields.PROPERTY_HEATING]?.length-1 !== index ? ', ' : ''}
            //             </span>
            //         ))
            //     },
            //     {
            //         id: projectResponseFields.PROPERTY_ENERGY_EFFICIENCY,
            //         label: 'Energetski crtifikat',
            //         value: data?.projectEnergyEfficiency?.name
            //     }
        ],
        other: [
            ...getAgentsViewData(data?.[projectResponseFields.AGENT]),
            {
                id: projectResponseFields.UPDATED,
                label: t('view.project.other.updated'),
                value: formatDateTime(data?.[projectResponseFields.UPDATED], true),
            },
            {
                id: `${projectResponseFields.ERSTE_REPRESENTATIVE}`,
                label: t('view.project.other.ersteRepresentative'),
                value: data?.[projectResponseFields.ERSTE_REPRESENTATIVE],
            },
        ],
        // relatedData: [
        //     {
        //         id: projectResponseFields.PROPERTY_SPECIAL_FEATURE,
        //         label: 'Posebnosti',
        //         value: data?.[projectResponseFields.PROPERTY_SPECIAL_FEATURE]?.map((item, index) => (
        //             <span key={item.id}>
        //                 {item.name}{data?.[projectResponseFields.PROPERTY_SPECIAL_FEATURE]?.length-1 !== index ? ', ' : ''}
        //             </span>
        //         ))
        //     },
        //     {
        //         id: projectResponseFields.PROPERTY_DESCRIPTIONS,
        //         label: 'Opis nekretnine',
        //         value: data?.[projectResponseFields.PROPERTY_DESCRIPTIONS]?.map((item, index) => (
        //             <span key={item.id}>
        //                 {item.name}{data?.[projectResponseFields.PROPERTY_DESCRIPTIONS]?.length-1 !== index ? ', ' : ''}
        //             </span>
        //         ))
        //     },
        //     {
        //         id: projectResponseFields.PROPERTY_EQUIPMENT,
        //         label: 'Opremljenost nekretnine',
        //         value: data?.[projectResponseFields.PROPERTY_EQUIPMENT]?.map((item, index) => (
        //             <span key={item.id}>
        //                 {item.name}{data?.[projectResponseFields.PROPERTY_EQUIPMENT]?.length-1 !== index ? ', ' : ''}
        //             </span>
        //         ))
        //     },
        //     {
        //         id: projectResponseFields.PROPERTY_UTILITY,
        //         label: 'Osnovna infrastruktura',
        //         value: data?.[projectResponseFields.PROPERTY_UTILITY]?.map((item, index) => (
        //             <span key={item.id}>
        //                 {item.name}{data?.[projectResponseFields.PROPERTY_UTILITY]?.length-1 !== index ? ', ' : ''}
        //             </span>
        //         ))
        //     },
        //     {
        //         id: projectResponseFields.PROPERTY_SPACE,
        //         label: 'Prostori vezani uz nekretninu',
        //         value: data?.[projectResponseFields.PROPERTY_SPACE]?.map((item, index) => (
        //             <div key={item.id}>
        //                 {item.name}{data?.[projectResponseFields.PROPERTY_SPACE]?.length-1 !== index ? ', ' : ''}
        //             </div>
        //         ))
        //     },
        //     {
        //         id: projectResponseFields.DISTANCE_SEA,
        //         label: 'Udaljenost od mora (m)',
        //         value: data?.[projectResponseFields.DISTANCE_SEA]
        //     },
        //     {
        //         id: projectResponseFields.DISTANCE_RIVER,
        //         label: 'Udaljenost od rijeke (m)',
        //         value: data?.[projectResponseFields.DISTANCE_RIVER]
        //     },
        //     {
        //         id: projectResponseFields.DISTANCE_BEACH,
        //         label: 'Udaljenost od plaže (m)',
        //         value: data?.[projectResponseFields.DISTANCE_BEACH]
        //     },
        //     {
        //         id: projectResponseFields.DISTANCE_CITY_CENTRE,
        //         label: 'Udaljenost od centra (m)',
        //         value: data?.[projectResponseFields.DISTANCE_CITY_CENTRE]
        //     },
        //     {
        //         id: projectResponseFields.DISTANCE_SCHOOL,
        //         label: 'Udaljenost od škole (m)',
        //         value: data?.[projectResponseFields.DISTANCE_SCHOOL]
        //     },
        //     {
        //         id: projectResponseFields.DISTANCE_PUBLIC_TRANSPORTATION,
        //         label: 'Udaljenost od javnog prijevoza (m)',
        //         value: data?.[projectResponseFields.DISTANCE_SCHOOL]
        //     },
        // ]
    }
}
