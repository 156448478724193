import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import { fetchPropertyTypesSuccess } from './actions';

const items = handleActions(
  {
    [fetchPropertyTypesSuccess]: (state, { payload }) => ([
      ...payload,
    ])
  },
  []
);

export default combineReducers({
  items
});
