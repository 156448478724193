/**
 * @param {array} rulesArray - Array of objects containing all the featues or permissions.
 * @param {array} tabsToHide - Array of objects containing tabs that should be hidden.
 * @param {function} setAction - Helper function that returns boolean if feature/permission is allowed or not
 * @param {string} propertyName - Name of the property describing if tabs are related to features or permissions
 * @param {boolean} isAgentOwnership - Boolean related to permissions (not needed for features), signifies whether agent created item or not
 * @param {boolean} isAgentOffice - Boolean related to permissions (not needed for features), signifies whether agent belongs to agency office
 * @returns {object} - Returns object with defined tab names that should be hidden/not allowed, or null if tab should be visible
 */

export const setAllowedTabs = (rulesArray, tabsToHide, setAction, propertyName, isAgentOwnership = false, isAgentOffice = false) => {
    const tabs = tabsToHide?.reduce(
        (acc, curr) => {
            const { ruleName, tabName } = curr
            const isFeature = propertyName === 'featureTabs' 

            // type of function (setAction) differs if tab is features or permissions related
            const hasPermission = isFeature ? setAction(rulesArray, ruleName) : setAction(rulesArray, ruleName, isAgentOwnership, isAgentOffice)
            let result = setHiddenTab(hasPermission, tabName)

            const tabInfo = { [tabName]: result }

            acc[propertyName] = { ...acc[propertyName], ...tabInfo }

            return acc
        },
        { [propertyName]: {} }
    )

    return tabs
}

// returns string or null (string: tab to hide, null: no tab to hide)
const setHiddenTab = (hasPermission, tabToHide) => {
    return !hasPermission ? tabToHide : null
}
