import { Form, FormikProvider } from 'formik'
import { Button, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import { SaveButton } from '../Common/Buttons'
import RecursiveForm from '../RecursiveForm'

const FormModal = ({ id, htmlId, handleFetchData, isOpen, title, formik, inputs, selectOptions, toggle, isSubmitting, ...props }) => {
    return (
        <Modal isOpen={isOpen} id={htmlId}>
            <ModalHeader toggle={toggle} tag="h5" className="p-3  modal-title">
                {title}
            </ModalHeader>
            <ModalBody>
                {formik && (
                    <FormikProvider value={formik}>
                        <Form
                            className="needs-validation"
                            name="event-form"
                            id="form-event"
                            onSubmit={(e) => {
                                e.preventDefault()
                                formik.handleSubmit()
                                return false
                            }}
                        >
                            <RecursiveForm inputs={inputs?.mainForm} formik={formik} selectOptions={selectOptions} />

                            <div className="hstack gap-2 justify-content-end">
                                <SaveButton loading={formik.isSubmitting} />
                            </div>
                        </Form>
                    </FormikProvider>
                )}
            </ModalBody>
        </Modal>
    )
}

export default FormModal
