export const propertyResponseFields = {
    ID: 'id',
    INTERNAL_ID: 'internalId',
    PROPERTY_TYPE: 'propertyType',
    PROPERTY_SERVICE: 'propertyService',
    PROPERTY_SUB_TYPE: 'propertySubType',
    PROPERTY_SET: 'propertySet', // ERSTE ONLY FIELD
    CONTRACT_NO: 'contractNo',
    CONTRACT_DATE: 'contractDate',
    DATA_SOURCE: 'dataSource',
    PROJECT: 'project',
    OWNER: 'owner',
    OWNER_COMPANY: 'ownerCompany',
    PROPERTY_LAND_USAGE: 'propertyLandUsage',
    PROPERTY_PRIMARY_USAGE: 'propertyPrimaryUsage',
    AVAILABLE_FROM_DATE: 'rentedToDate',
    COUNTRY: 'country',
    COUNTY: 'county',
    CITY: 'city',
    WIDER_AREA: 'widerArea',
    QUARTER: 'quarter',
    ISLAND: 'island',
    STREET: 'street',
    STREET_NUMBER: 'streetNumber',
    STREET_SYNC: 'streetSync',
    PROPERTY_LABEL: 'propertyLabel',
    PROPERTY_POSITION: 'propertyPosition',
    PRICE: 'price',
    PRICE_CLIENT: 'priceClient',
    PRICE_M2: 'priceM2',
    PRICE_OLD: 'priceOld',
    PRICE_HISTORY: 'priceHistory',
    VAT_INCLUDED: 'vatIncluded',
    VAT_NOT_INCLUDED: 'vatNotIncluded',
    UTILITIES_INCLUDED: 'utilitiesIncluded',
    PRICE_ON_REQUEST: 'priceOnRequest',
    EXCHANGE_POSSIBLE: 'exchangePossible',
    MAINTENCE_COSTS: 'maintenanceCosts',
    MAINTENCE_COSTS_TYPE: 'maintenanceCostsType',
    COMMON_MAINTENCE_COSTS: 'commonMaintenanceCosts',
    COMMON_MAINTENCE_COSTS_TYPE: 'commonMaintenanceCostsType',
    COMMON_UTILITY_COSTS: 'commonUtilityCosts',
    COMMON_UTILITY_COSTS_TYPE: 'commonUtilityCostsType',
    PARKING_SPACE_PRICE: 'parkingSpacePrice',
    PARKING_SPACE_NUMBER: 'parkingSpaceNumber',
    MORTGAGE_INFO: 'mortgageInfo',
    PROPERTY_HOUSE_TYPE: 'propertyHouseType',
    AREA: 'area',
    AREA_NETT: 'areaNett',
    AREA_GARDEN: 'areaGarden',
    AREA_STORAGE: 'areaStorage',
    PLOT_AREA: 'plotArea',
    PLOT_WIDTH: 'plotWidth',
    PLOT_LENGTH: 'plotLength',
    CONSTRUCTION_COEFFICIENT: 'constructionCoefficient',
    CONSTRUCTION_FLOORS_NO: 'constructionFloorsNo',
    MAX_CONTRUCTION_AREA: 'maxConstructionArea',
    PROPERTY_ROOMS_NO: 'propertyRoomsNo',
    ROOMS_NO: 'roomsNo',
    PROPERTY_ROOMS_TYPE: 'propertyRoomsType', // ERSTE ONLY FIELD
    HOUSING_UNITS_NUMBER: 'housingUnitsNumber',
    BEDROOMS_NO: 'bedroomsNo',
    BED_NO: 'bedNo',
    BATHROOM_TOILET_NO: 'bathroomToiletNo',
    BATHROOM_NO: 'bathroomNo',
    COMMON_BATHROOM_NO: 'commBathroomNo',
    TOILET_NO: 'toiletNo',
    CEILING_HEIGHT: 'ceilingHeight',
    CONSTRUCTION_YEAR: 'constructionYear',
    ADAPTATION_YEAR: 'adaptationYear',
    PROPERTY_FLOOR: 'propertyFloor',
    PROPERTY_FLOOR_POSITION: 'propertyFloorPosition',
    FLOOR_NO: 'floorNo',
    FLOORS_TOTAL_NO: 'floorsTotalNo',
    PROPERTY_ORIENTATION: 'propertyOrientation',
    PROPERTY_HEATING: 'propertyHeating',
    PROPERTY_CONDITION: 'propertyCondition',
    PROPERTY_WINDOWS_DOORS: 'propertyWindowsDoors',
    PROPERTY_PUBLIC_TRANSPORTATION: 'propertyPublicTransportation',
    DISTANCE_SEA: 'distanceSea',
    DISTANCE_RIVER: 'distanceRiver',
    DISTANCE_BEACH: 'distanceBeach',
    DISTANCE_CITY_CENTRE: 'distanceCityCentre',
    DISTANCE_SCHOOL: 'distanceSchool',
    DISTANCE_PUBLIC_TRANSPORTATION: 'distancePublicTransportation',
    DISTANCE_PATHWAY: 'distancePathway',
    PROPERTY_SPECIAL_FEATURE: 'propertySpecialFeature',
    PROPERTY_DESCRIPTION: 'propertyDescription',
    PROPERTY_DESCRIPTIONS: 'propertyDescriptions',
    PROPERTY_TITLE: 'propertyTitle',
    PROPERTY_UTILITY: 'propertyUtility',
    PROPERTY_EQUIPMENT: 'propertyEquipment',
    PROPERTY_SPACE: 'propertySpace',
    PROPERTY_ENERGY_EFFICIENCY: 'propertyEnergyEfficiency',
    PROPERTY_ACCESS_ID: 'propertyAccess',
    PROPERTY_ACCESS: 'propertyAccess',
    PROPERTY_PERMIT_ID: 'propertyPermit',
    PROPERTY_PERMIT: 'propertyPermit',
    AGENCY_COMMISSION_ID: 'agencyCommission',
    AGENCY_COMMISSION: 'agencyCommission',
    AGENT: 'agent',
    MAP_LATITUDE: 'mapLatitude',
    MAP_LONGITUDE: 'mapLongitude',
    CUSTOMER: 'customer',
    PROPERTY_RECEIPT_DATE: 'propertyReceiptDate',
    PROPERTY_STATUS: 'propertyStatus',
    PRICE_FINALL: 'priceFinal',
    COMMISSION_SELLER: 'commissionSeller',
    COMMISSION_SELLER_TYPE: 'commissionSellerType',
    COMMISSION_BUYER: 'commissionBuyer',
    COMMISION_BUYER_TYPE: 'commissionBuyerType',
    CONCLUSION_PLACE: 'conclusionPlace',
    CONCLUSION_DATE: 'conclusionDate',
    ADVANCE_AMOUNT: 'advanceAmount',
    FINAL_PAYMENT_DATE: 'finalPaymentDate',
    PROPERTY_TRANSACTION: 'propertyTransaction',
    PROPERTY_TRANSACTION_RISK_DEGREE: 'propertyTransactionRiskDegree',
    PROPERTY_MAIN_REGISTER: 'propertyMainRegister',
    PROPERTY_MAIN_REGISTER_DEPARTMENT: 'propertyMainRegisterDepartment',
    PROPERTY_REGISTRY_MUNICIPALITY: 'propertyRegistryMunicipality',
    PROPERTY_REGISTRY_INSERT: 'propertyRegistryInsert',
    PROPERTY_REGISTRY_PARCEL_NO: 'propertyRegistryParcelNo',
    PROPERTY_REGISTRY_SUBINSERT: 'propertyRegistrySubinsert',
    PROPERTY_REGISTRY_DEPARTMENT_KPU: 'propertyRegistryDepartmentKpu',
    PROPERTY_REGISTRY_DEPARTMENT_KPU_SUBINSERT: 'propertyRegistryDepartmentKpuSubinsert',
    PROPERTY_SUBINSERT_KPU: 'propertySubinsertKpu',
    PROPERTY_PUK_DEPARTMENT: 'propertyPukDepartment',
    PROPERTY_CADASTRAL_MUNCIPALITY: 'propertyCadastralMunicipality',
    PROPERTY_CADASTRAL_PARCEL_NO: 'propertyCadastralParcelNo',
    PROPERTY_POSSESSORY_SHEET_NO: 'propertyPossessorySheetNo',
    PROPERTY_PUK: 'propertyPuk',
    UPDATED: 'updated',
    CREATED: 'created',
    VIRTUAL_WALK_URL: 'virtualWalkUrl',
    PROPERTY_VIDEO_URL: 'propertyVideoUrl',
    PROPERTY_TITLE_TRANSLATION: 'propertyTitleTranslations',
    PROPERTY_DESCRIPTION_TRANSLATION: 'propertyDescriptionTranslations',
    PRICE_INITIAL: 'priceInitial',
    PROPERTY_BUILDING_NUMBER: 'propertyBuildingNumber',  // SLO MARKET ONLY FIELD
    PROPERTY_BUILDING_PART_NUMBER: 'propertyBuildingPartNumber', // SLO MARKET ONLY FIELD
}
