import { useEffect } from "react"

// hook for closing/reseting component while clicking outside of that component
const useClickOutside = (ref, setFn) => {
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current?.contains(event.target)) {
                setFn(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [ref, setFn])
}

export default useClickOutside
