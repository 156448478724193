import { createAction } from 'redux-actions'

const ENTITY = 'PROJECTS'
export const stateKey = ENTITY.toLowerCase()

export const fetchProjects = createAction(
    `${ENTITY}/FETCH`,
    (payload) => payload,
    (_, callback) => callback
)

export const fetchProjectsRequest = createAction(`${ENTITY}/FETCH_REQUEST`)
export const fetchProjectsSuccess = createAction(`${ENTITY}/FETCH_SUCCESS`)
export const fetchProjectsFailure = createAction(`${ENTITY}/FETCH_FAILURE`)

export const fetchProject = createAction(`${ENTITY}/DETAILS/FETCH`)
export const fetchProjectRequest = createAction(`${ENTITY}/DETAILS/FETCH_REQUEST`)
export const fetchProjectSuccess = createAction(`${ENTITY}/DETAILS/FETCH_SUCCESS`)
export const fetchProjectFailure = createAction(`${ENTITY}/DETAILS/FETCH_FAILURE`)

export const createProject = createAction(
    `${ENTITY}/CREATE`,
    (values) => values,
    (values, callback) => ({ callback })
)
// export const createProject = createAction(`${ENTITY}/CREATE`);
export const createProjectRequest = createAction(`${ENTITY}/CREATE_REQUEST`)
export const createProjectSuccess = createAction(`${ENTITY}/CREATE_SUCCESS`)
export const createProjectFailure = createAction(`${ENTITY}/CREATE_FAILURE`)

export const updateProject = createAction(`${ENTITY}/UPDATE`, (values) => values)
// export const createProject = createAction(`${ENTITY}/CREATE`);
export const updateProjectRequest = createAction(`${ENTITY}/UPDATE_REQUEST`)
export const updateProjectSuccess = createAction(`${ENTITY}/UPDATE_SUCCESS`)
export const updateProjectFailure = createAction(`${ENTITY}/UPDATE_FAILURE`)

export const deleteProject = createAction(
    `${ENTITY}/DELETE`,
    (payload) => payload,
    (_, redirect) => ({ redirect })
)
export const deleteProjectRequest = createAction(`${ENTITY}/DELETE_REQUEST`)
export const deleteProjectSuccess = createAction(`${ENTITY}/DELETE_SUCCESS`)
export const deleteProjectFailure = createAction(`${ENTITY}/DELETE_FAILURE`)

export const deleteProjectMultiple = createAction(
    `${ENTITY}/DELETE_MULTIPLE`,
    (payload) => payload,
    (payload, callback) => callback
)
export const deleteProjectMultipleRequest = createAction(`${ENTITY}/DELETE_MULTPLE_REQUEST`)
export const deleteProjectMultipleSuccess = createAction(`${ENTITY}/DELETE_MULTPLE_SUCCESS`)
export const deleteProjectMultipleFailure = createAction(`${ENTITY}/DELETE_MULTPLE_FAILURE`)

export const fetchProjectsAsyncInput = createAction(
    `${ENTITY}/FETCH_ASYNC_INPUT`,
    (payload) => payload,
    (payload, callback) => callback
)
export const fetchProjectsAsyncInputContactRequest = createAction(`${ENTITY}/FETCH_ASYNC_INPUT_REQUEST`)
export const fetchProjectsAsyncInputContactSuccess = createAction(`${ENTITY}/FETCH_ASYNC_INPUT_SUCCESS`)
export const fetchProjectsAsyncInputContactFailure = createAction(`${ENTITY}/FETCH_ASYNC_INPUT_FAILURE`)

export const fetchProjectNoStore = createAction(
    `${ENTITY}/FETCH_NO_STORE`,
    (payload) => payload,
    (payload, callback) => callback
)
export const fetchProjectNoStoreRequest = createAction(`${ENTITY}/FETCH_NO_STORE_REQUEST`)
export const fetchProjectNoStoreSuccess = createAction(`${ENTITY}/FETCH_NO_STORE_SUCCESS`)
export const fetchProjectNoStoreFailure = createAction(`${ENTITY}/FETCH_NO_STORE_FAILURE`)

export const fetchProjectsNoStore = createAction(
    `${ENTITY}/NO_STORE/FETCH`,
    (payload) => payload,
    (payload, callback) => ({ callback })
)
export const fetchProjectsNoStoreRequest = createAction(`${ENTITY}/NO_STORE/FETCH_REQUEST`)
export const fetchProjectsNoStoreSuccess = createAction(`${ENTITY}/NO_STORE/FETCH_SUCCESS`)
export const fetchProjectsNoStoreFailure = createAction(`${ENTITY}/NO_STORE/FETCH_FAILURE`)
export const fetchProjectsNoStoreClear = createAction(`${ENTITY}/NO_STORE/CLEAR`)

export const resetPageProjects = createAction(`${ENTITY}/RESET_PAGE_PROJECTS`)

export const fetchTemplatesNoStore = createAction(
    `${ENTITY}/FETCH_TEMPLATES_NO_STORE`,
    (payload) => payload,
    (payload, callback) => callback
)
export const fetchTemplatesNoStoreRequest = createAction(`${ENTITY}/FETCH_TEMPLATES_NO_STORE_REQUEST`)
export const fetchTemplatesNoStoreSuccess = createAction(`${ENTITY}/FETCH_TEMPLATES_NO_STORE_SUCCESS`)
export const fetchTemplatesNoStoreFailure = createAction(`${ENTITY}/FETCH_TEMPLATES_NO_STORE_FAILURE`)

export const updateProjectsAgent = createAction(`${ENTITY}/UPDATE_PROJECTS_AGENT`)
