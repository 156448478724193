import { combineActions, handleActions } from 'redux-actions'
import {
    createCompanyFailure,
    createCompanyRequest,
    createCompanySuccess,
    deleteCompanyFailure,
    deleteCompanyMultipleFailure,
    deleteCompanyMultipleRequest,
    deleteCompanyMultipleSuccess,
    deleteCompanyRequest,
    deleteCompanySuccess,
    fetchCompaniesFailure,
    fetchCompaniesRequest,
    fetchCompaniesSuccess,
    fetchCompanyFailure,
    fetchCompanyRequest,
    fetchCompanySuccess,
    updateCompanyFailure,
    updateCompanyRequest,
    updateCompanySuccess,
    resetPageCompanies,
} from './actions'

export const initialState = {
    items: {},
    item: {},
    count: 0,
    fetching: false,
    fetchingDetails: false,
    submitting: false,
    pagination: {},
    links: {},
}

export default handleActions(
    {
        [fetchCompaniesRequest]: (state) => ({
            ...state,
            fetching: true,
            error: null,
        }),
        [fetchCompaniesSuccess]: (state, { payload }) => ({
            ...state,
            count: payload?.count ?? 0,
            items: payload?.items,
            pagination: payload?.pagination,
            fetching: false,
        }),
        [fetchCompaniesFailure]: (state, { payload }) => ({
            ...state,
            error: payload,
            fetching: false,
        }),
        [fetchCompanyRequest]: (state) => ({
            ...state,
            fetchingDetails: true,
            errorDetails: null,
        }),
        [fetchCompanySuccess]: (state, { payload }) => ({
            ...state,
            item: {
                ...payload.item,
                profiles: payload?.contactProfile?.reduce((acc, item) => {
                    // let parsedItem = JSON.parse(item);
                    return { ...acc, [item.id]: item }
                }, {}),
            },
            fetchingDetails: false,
            links: payload.links,
        }),
        [fetchCompanyFailure]: (state, { payload }) => ({
            ...state,
            errorDetails: payload,
            fetchingDetails: false,
        }),
        [combineActions(updateCompanyRequest, createCompanyRequest)]: (state, { payload }) => ({
            ...state,
            submitting: true,
        }),
        [combineActions(updateCompanySuccess, createCompanySuccess)]: (state, { payload }) => ({
            ...state,
            submitting: false,
        }),
        [combineActions(updateCompanyFailure, createCompanyFailure)]: (state, { payload }) => ({
            ...state,
            submitting: false,
            error: payload,
        }),
        [deleteCompanyRequest]: (state) => {
            return {
                ...state,
                error: null,
                deleting: true,
            }
        },
        [deleteCompanySuccess]: (state, { payload }) => {
            let items = [...state.items]
            items = items.filter((x) => x.id !== parseInt(payload))

            return {
                ...state,
                deleting: false,
                items,
                count: state.count - 1,
            }
        },
        [deleteCompanyFailure]: (state, { payload }) => {
            return {
                ...state,
                deleting: false,
                error: payload.response,
            }
        },
        [deleteCompanyMultipleRequest]: (state) => {
            return {
                ...state,
                error: null,
                deleting: true,
            }
        },
        [deleteCompanyMultipleSuccess]: (state, { payload }) => {
            let items = [...state.items]
            items = items.filter((x) => !payload.includes(x.id))

            return {
                ...state,
                deleting: false,
                items,
                count: state.count - payload?.length,
            }
        },
        [deleteCompanyMultipleFailure]: (state, { payload }) => {
            return {
                ...state,
                deleting: false,
                error: payload.response,
            }
        },
        [resetPageCompanies]: (state) => ({
            ...state,
            pagination: { ...state.pagination, currentPage: 1 },
        }),
    },
    initialState
)
