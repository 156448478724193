import { getIn } from "formik";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Card, CardBody, Row } from "reactstrap";
import { contactProfileFields } from "../../common/forms/contacts/fields";
import { fetchLocationsCity, fetchLocationsCounty, fetchLocationsIsland, fetchLocationsQuarter, fetchLocationsWiderArea } from "../../store/locations";
import { fetchPropertySubtypes } from "../../store/propertySubtypes";
import { fetchPropertyValuesDescriptions, fetchPropertyValuesEquipment, fetchPropertyValuesFloor, fetchPropertyValuesHeating, fetchPropertyValuesSpace, fetchPropertyValuesUtility } from "../../store/propertyValues/actions";
import FormMainContainer from "../Common/FormMainContainer";
import FormSideContainer from "../Common/FormSideContainer";
import RecursiveForm from "../RecursiveForm";

const ContactProfileFormTab = ({formik, inputs, subform, selectOptions, inputComponents, tabs, activeTab, handleTabChange, contactType, ...props}) => {
    const dispatch = useDispatch();
    const id = getIn(props.formik?.values?.profile, 'id');
    const countryId = getIn(formik?.values?.profile, contactProfileFields.COUNTRY_ID);
    const countyId = getIn(formik?.values?.profile, contactProfileFields.COUNTY_ID);
    const cityId = getIn(formik?.values?.profile, contactProfileFields.CITY_ID);
    const widerAreaId = getIn(formik?.values?.profile, contactProfileFields.WIDER_AREA_ID);

    const propertyTypeId = getIn(formik?.values?.profile, contactProfileFields.PROPERTY_TYPE_ID);
    const propertyServiceId = getIn(formik?.values?.profile, contactProfileFields.CONTACT_PROFILE_SERVICE_ID)
    const propertySubTypeId = getIn(formik?.values?.profile, contactProfileFields.PROPERTY_SUB_TYPE_ID);
    const propertyTypeIndex = inputs.sideForm?.findIndex(o => o.name === contactProfileFields.PROPERTY_TYPE_ID);
    const propertySubTypeIndex = inputs.sideForm?.findIndex(o => o.name === contactProfileFields.PROPERTY_SUB_TYPE_ID);
    const locationGroupIndex = inputs.mainForm?.findIndex(obj => (obj.name === 'locationGroup'));
    const countryIndex = inputs.mainForm?.[locationGroupIndex]?.children.findIndex(obj => obj.name === contactProfileFields.COUNTRY_ID);
    const countyIndex = inputs.mainForm?.[locationGroupIndex]?.children.findIndex(obj => obj.name === contactProfileFields.COUNTY_ID);
    const cityIndex = inputs.mainForm?.[locationGroupIndex]?.children.findIndex(obj => obj.name === contactProfileFields.CITY_ID);
    const widerAreaIndex = inputs.mainForm?.[locationGroupIndex]?.children.findIndex(obj => obj.name === contactProfileFields.WIDER_AREA_ID);
    const quarterIndex = inputs.mainForm?.[locationGroupIndex]?.children.findIndex(obj => obj.name === contactProfileFields.QUARTER_ID);
    const islandIndex = inputs.mainForm?.[locationGroupIndex]?.children.findIndex(obj => obj.name === contactProfileFields.ISLAND_ID);

    const relatedStuffGroupIndex = inputs.mainForm?.findIndex(obj => (obj.name === 'relatedStuffGroup'));
    const propertySpaceIndex = inputs.mainForm?.[relatedStuffGroupIndex]?.children.findIndex(obj => obj.name === contactProfileFields.PROPERTY_SPACE_ID);
    const heatingIndex = inputs.mainForm?.[relatedStuffGroupIndex]?.children.findIndex(obj => obj.name === contactProfileFields.PROPERTY_HEATING_ID);
    const floorIndex = inputs.mainForm?.[relatedStuffGroupIndex]?.children.findIndex(obj => obj.name === contactProfileFields.PROPERTY_FLOOR_ID);
    const descriptionsIndex = inputs.mainForm?.[relatedStuffGroupIndex]?.children.findIndex(obj => obj.name === contactProfileFields.PROPERTY_DESCRIPTIONS_ID);
    const equipmentIndex = inputs.mainForm?.[relatedStuffGroupIndex]?.children.findIndex(obj => obj.name === contactProfileFields.PROPERTY_EQUIPMENT_ID);
    const utilityIndex = inputs.mainForm?.[relatedStuffGroupIndex]?.children.findIndex(obj => obj.name === contactProfileFields.PROPERTY_UTILITY_ID);
    
    useEffect(() => {     
        if(propertyTypeId){
            dispatch(fetchPropertySubtypes(propertyTypeId))
            if(inputs.mainForm){ 
                inputs.sideForm[propertySubTypeIndex].disabled = false;
                if(propertySpaceIndex > -1){
                    inputs.mainForm[relatedStuffGroupIndex].children[propertySpaceIndex].disabled = false;
                }
                if(heatingIndex > -1){
                    inputs.mainForm[relatedStuffGroupIndex].children[heatingIndex].disabled = false;
                }
                if(floorIndex > -1){
                    inputs.mainForm[relatedStuffGroupIndex].children[floorIndex].disabled = false;
                }
                if(equipmentIndex > -1){
                    inputs.mainForm[relatedStuffGroupIndex].children[equipmentIndex].disabled = false;
                }
                if(utilityIndex > -1){
                    inputs.mainForm[relatedStuffGroupIndex].children[utilityIndex].disabled = false;
                }
            }
            dispatch(fetchPropertyValuesEquipment({filter: {
                propertyType: propertyTypeId
            }}))
            dispatch(fetchPropertyValuesHeating({
                filter: { propertyType: propertyTypeId}
            }))
            dispatch(fetchPropertyValuesSpace({
                filter: { propertyType: propertyTypeId}
            }))
            dispatch(fetchPropertyValuesFloor({
                filter: { propertyType: propertyTypeId}
            }))
            dispatch(fetchPropertyValuesEquipment({
                filter: { propertyType: propertyTypeId}
            }))
            dispatch(fetchPropertyValuesUtility({
                filter: { propertyType: propertyTypeId}
            }))
        } else {
            if(propertySubTypeId && propertySubTypeId.length) {
                formik.setFieldValue(`${subform}.${contactProfileFields.PROPERTY_SUB_TYPE_ID}`, [])
            }
            if(inputs.mainForm){ 
                inputs.sideForm[propertySubTypeIndex].disabled = true;
                if(propertySpaceIndex > -1){
                    inputs.mainForm[relatedStuffGroupIndex].children[propertySpaceIndex].disabled = false;
                }
                if(heatingIndex > -1){
                    inputs.mainForm[relatedStuffGroupIndex].children[heatingIndex].disabled = true;
                }
                if(floorIndex > -1){
                    inputs.mainForm[relatedStuffGroupIndex].children[floorIndex].disabled = true;
                }
                if(equipmentIndex > -1){
                    inputs.mainForm[relatedStuffGroupIndex].children[equipmentIndex].disabled = true;
                }
                if(utilityIndex > -1){
                    inputs.mainForm[relatedStuffGroupIndex].children[utilityIndex].disabled = true;
                }
            }
        }
    }, [id, propertyTypeId])

    useEffect(() => {
        if(propertyTypeId && propertyServiceId) {
            const filter = {
                propertyType: propertyTypeId,
                propertyService: propertyServiceId
            }
            dispatch(fetchPropertyValuesDescriptions({
                filter
            }))
            if(inputs.mainForm) { 
                if(descriptionsIndex > -1){
                    inputs.mainForm[relatedStuffGroupIndex].children[descriptionsIndex].disabled = false;
                }
            }
        } else {
            if(inputs.mainForm) { 
                if(descriptionsIndex > -1){
                    inputs.mainForm[relatedStuffGroupIndex].children[descriptionsIndex].disabled = true;
                }
            }
        }
    }, [id, propertyServiceId, propertyTypeId])

    useEffect(() => {
        if(countryId){
            dispatch(fetchLocationsCounty(countryId))
            dispatch(fetchLocationsIsland(countryId))
            if(inputs.mainForm){
                inputs.mainForm[locationGroupIndex].children[countyIndex].disabled = false;
                inputs.mainForm[locationGroupIndex].children[islandIndex].disabled = false;
            }
        } else {
            formik.setFieldValue(`${subform}.${contactProfileFields.COUNTY_ID}`, '');
            formik.setFieldValue(`${subform}.${contactProfileFields.ISLAND_ID}`, '')
            if(inputs.mainForm) {
                inputs.mainForm[locationGroupIndex].children[countyIndex].disabled = true;
                inputs.mainForm[locationGroupIndex].children[islandIndex].disabled = true;
            }
        }
    }, [id, countryId])

    useEffect(() => {
        if(countyId) {
            dispatch(fetchLocationsCity(countyId))
            if(inputs.mainForm) {
                inputs.mainForm[locationGroupIndex].children[cityIndex].disabled = false;
            }
        } else {
            formik.setFieldValue(`${subform}.${contactProfileFields.CITY_ID}`, []);
            if(inputs.mainForm){
                inputs.mainForm[locationGroupIndex].children[cityIndex].disabled = true;
            }
        }
    }, [id, countyId])

    useEffect(() => {
        if(cityId && cityId.length) {
            dispatch(fetchLocationsWiderArea(cityId))
            if(inputs.mainForm) {
                inputs.mainForm[locationGroupIndex].children[widerAreaIndex].disabled = false;
            }
        } else {
            formik.setFieldValue(`${subform}.${contactProfileFields.WIDER_AREA_ID}`, []);
            if(inputs.mainForm) {
                inputs.mainForm[locationGroupIndex].children[widerAreaIndex].disabled = true;    
            }
        }
    }, [id, cityId])

    useEffect(() => {
        if(widerAreaId && widerAreaId.length){
            dispatch(fetchLocationsQuarter(widerAreaId))
            if(inputs.mainForm) {
                inputs.mainForm[locationGroupIndex].children[quarterIndex].disabled = false;
            }
        } else {
            formik.setFieldValue(`${subform}.${contactProfileFields.QUARTER_ID}`, []);
            if(inputs.mainForm) {
                inputs.mainForm[locationGroupIndex].children[quarterIndex].disabled = true;
            }
        }
    }, [id, widerAreaId])

    if (inputs.sideForm) {
        let propertyTypeIndex = inputs.sideForm.findIndex(o => o.name === contactProfileFields.PROPERTY_TYPE_ID);

            if (propertyTypeIndex !== -1) {
                // if(id) {
                //     const propertyTypeId = getIn(props.formik?.values?.general, contactProfileFields.PROPERTY_TYPE_ID);
                //     dispatch(fetchPropertySubtypes(propertyTypeId))
                // }
                inputs.sideForm[propertyTypeIndex].handleChange = (id) => {
                    formik.setFieldValue(`${subform}.${contactProfileFields.PROPERTY_SUB_TYPE_ID}`, '');
                    dispatch(fetchPropertySubtypes(id))
                }
            }
        
    }

    // if(inputs.mainForm) {
    //     if (countryIndex !== -1) {
    //         inputs.mainForm[locationGroupIndex].children[countryIndex].handleChange = (id) => dispatch(fetchLocationsCounty(id));
    //     }
    //     if (countyIndex !== -1) {
    //         const countyInput = inputs.mainForm[locationGroupIndex].children[countyIndex];
    //         countyInput.handleChange = (id) => dispatch(fetchLocationsCity(id));
    //     }
    //     if(cityIndex !== -1) {
    //         inputs.mainForm[locationGroupIndex].children[cityIndex].handleChange = (id) => dispatch(fetchLocationsWiderArea(id));
    //     }
    //     if(widerAreaIndex !== -1) {
    //         inputs.mainForm[locationGroupIndex].children[widerAreaIndex].handleChange = (id) => dispatch(fetchLocationsQuarter(id));
    //     }
    // }
    
    return (
        <>
            <Row>
                <FormSideContainer>
                    <Card>
                        <CardBody>
                            <RecursiveForm inputs={inputs?.sideForm} subform={subform} formik={formik} selectOptions={selectOptions} inputComponents={inputComponents} />
                        </CardBody>
                    </Card>
                </FormSideContainer>
                <FormMainContainer tabs={tabs} activeTab={activeTab} handleTabChange={handleTabChange}>
                    <RecursiveForm inputs={inputs?.mainForm} subform={subform} formik={formik} selectOptions={selectOptions} inputComponents={inputComponents} />
                </FormMainContainer>
            </Row>
        </>
    )
}

export default ContactProfileFormTab;