import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'reactstrap'
import { getSVGIconFromExtension } from '../../../utils/files'
import { formatDateTime } from '../../../utils/general'
import AppPermissionsSwitcher from '../../AppPermissionsSwitcher/AppPermissionsSwitcher'
import { action } from '../../AppPermissionsSwitcher/constants/actions'
import TableCheckbox from '../../Table/TableCheckbox'
import { getCheckboxProps } from '../../Table/utils'

const useDocumentsHeaders = (documentModule, dispatch, syncAction, setSidebarData, setSidebarEditMode, onDelete, module) => {
    const { t } = useTranslation()

    function sidebarOpen(value) {
        const element = document.getElementsByTagName('body')[0]
        element.classList.add(value)
    }

    const fileSidebar = () => {
        document.getElementById('file-overview').style.display = 'block'
    }

    const columns = useMemo(
        () => [
            {
                Header: ({ toggleRowSelected, isAllPageRowsSelected, page }) => {
                    const checkboxProps = getCheckboxProps(toggleRowSelected, isAllPageRowsSelected, page)

                    return <TableCheckbox {...checkboxProps} />
                },
                Cell: ({ row }) => {
                    return <TableCheckbox {...row.getToggleRowSelectedProps()} disabled={row.original.disabled} />
                },
                id: '#',
                tdClassName: 'text-center align-middle',
            },
            {
                Header: t('app.common.id'),
                id: 'id',
                accessor: 'id',
                filterable: false,
                width: 41,
            },
            {
                Header: t('table.header.documents.fileName'),
                id: 'fileName',
                filterable: false,
                Cell: (file) => {
                    const { translations, fileExtension } = file.row.original ?? {}
                    const currentLang = localStorage.getItem('i18nextLng')

                    return (
                        <>
                            {getSVGIconFromExtension(file.row.original.fileExtension)}
                            <span className="d-inline-block me-2" />
                            {translations[currentLang]?.fileDescription ?? ''}
                            {`.${fileExtension}` ?? ''}
                        </>
                    )
                },
            },
            {
                Header: t('app.common.documentType'),
                id: 'documentType',
                accessor: 'documentType.name',
                filterable: false,
            },
            ...(!documentModule
                ? [
                      {
                          Header: t('app.common.contactCompany'),
                          id: 'documentContactId',
                          Cell: (file) => (
                              <>
                                  {file.row.original.module === 'contact' || file.row.original.module === 'company'
                                      ? file.row.original.parentElement?.title
                                      : ''}
                              </>
                          ),
                          filterable: false,
                          width: 77,
                      },
                      {
                          Header: t('app.common.propertyId'),
                          id: 'documentPropertyId',
                          Cell: (file) => <>{file.row.original.module === 'property' ? file.row.original.parent : ''}</>,
                          filterable: false,
                          width: 77,
                      },
                      {
                          Header: t('app.common.projectId'),
                          id: 'documentProjectId',
                          Cell: (file) => <>{file.row.original.module === 'project' ? file.row.original.parent : ''}</>,
                          filterable: false,
                          width: 77,
                      },
                  ]
                : []),
            {
                Header: t('app.common.creationDate'),
                id: 'documentCreationDate',
                filterable: false,
                Cell: (file) => formatDateTime(file.row.original.documentCreationDate),
            },
            {
                Header: t('app.common.expirationDate'),
                id: 'documentValidityDate',
                filterable: false,
                Cell: (file) => formatDateTime(file.row.original.documentValidityDate),
            },
            {
                Header: '',
                id: 'actions',
                thDivClass: 'text-center',
                Cell: (cellProps) => {
                    const agent = cellProps.row.original.agent
                    const itemAgentId = agent?.id
                    const itemAgentOfficeId = agent?.agencyOffice?.id

                    return (
                        <ul className="list-inline hstack gap-2 mb-0 justify-content-end">
                            <li className="list-inline-item">
                                <Button
                                    title={t('app.common.synchronize')}
                                    color="link"
                                    className={`${cellProps.row.original.fileSync ? 'text-success' : 'text-secondary'} d-inline-block p-0`}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        dispatch(syncAction(cellProps.row.original.id))
                                    }}
                                >
                                    <i
                                        className={`mdi ${cellProps.row.original.fileSync ? 'mdi-check-circle' : 'mdi-check-circle-outline'} fs-16`}
                                    ></i>
                                </Button>
                            </li>
                            <li className="list-inline-item">
                                <Button
                                    title={t('button.view')}
                                    color="link"
                                    className="text-warning d-inline-block p-0"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        setSidebarData(cellProps.row.original)
                                        fileSidebar()
                                        sidebarOpen('file-detail-show')
                                        setSidebarEditMode(false)
                                    }}
                                >
                                    <i className="mdi mdi-eye-outline fs-16"></i>
                                </Button>
                            </li>

                            <AppPermissionsSwitcher
                                permission="ModulePermissions"
                                itemAgentId={itemAgentId}
                                itemAgentOfficeId={itemAgentOfficeId}
                                module={module}
                                action={action.edit}
                            >
                                {({ hasModulePermission }) =>
                                    hasModulePermission && (
                                        <>
                                            <li className="list-inline-item">
                                                <Button
                                                    title={t('button.edit')}
                                                    color="link"
                                                    className="text-info d-inline-block p-0"
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        setSidebarData(cellProps.row.original)
                                                        fileSidebar()
                                                        sidebarOpen('file-detail-show')
                                                        setSidebarEditMode(true)
                                                    }}
                                                >
                                                    <i className="mdi mdi-pencil-outline fs-16"></i>
                                                </Button>
                                            </li>
                                            <li className="list-inline-item">
                                                <Button
                                                    title={t('button.delete')}
                                                    color="link"
                                                    className="text-danger d-inline-block p-0"
                                                    onClick={() => onDelete(cellProps.row.original.id)}
                                                >
                                                    <i className="mdi mdi-trash-can-outline fs-16"></i>
                                                </Button>
                                            </li>
                                        </>
                                    )
                                }
                            </AppPermissionsSwitcher>
                        </ul>
                    )
                },
            },
        ],
        [documentModule, dispatch, syncAction, setSidebarData, setSidebarEditMode, onDelete, module, t]
    )

    return columns
}

export default useDocumentsHeaders
