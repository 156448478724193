import { handleActions } from 'redux-actions'
import {
    fetchUsersRequest,
    fetchUsersSuccess,
    fetchUsersFailure,
    fetchUserRequest,
    fetchUserSuccess,
    fetchUserFailure,
    updateUserRequest,
    updateUserSuccess,
    updateUserFailure,
    fetchAgencyOfficesRequest,
    fetchAgencyOfficesSuccess,
    fetchAgencyOfficesFailure,
    fetchAgencyPermissionsRequest,
    fetchAgencyPermissionsSuccess,
    fetchAgencyPermissionsFailure,
    fetchAgencyRequest,
    fetchAgencySuccess,
    fetchAgencyFailure,
    fetchAgencyOfficeRequest,
    fetchAgencyOfficeSuccess,
    fetchAgencyOfficeFailure,
    updateAgencyOfficeRequest,
    updateAgencyOfficeSuccess,
    updateAgencyOfficeFailure,
    updateAgencyRequest,
    updateAgencySuccess,
    updateAgencyFailure,
} from './actions'

export const initialState = {
    users: [],
    error: null,
    fetchingUsers: false,
    user: {},
    fetchingUser: false,
    errorUser: null,
    submitting: false,
    applicationSettings: {},
    fetchingSettings: false,
    agencyOffices: [],
    fetchingOffices: false,
    agencyOffice: {},
    fetchingOffice: false,
    agencyPermissions: [],
    fetchingAgencyPermissions: false,
    agency: {},
    fetchingAgency: false,
}

export default handleActions(
    {
        [fetchUsersRequest]: (state) => ({
            ...state,
            fetchingUsers: true,
            error: null,
        }),
        [fetchUsersSuccess]: (state, { payload }) => {
            return {
                ...state,
                users: payload,
                fetchingUsers: false,
            }
        },
        [fetchUsersFailure]: (state, { payload }) => ({
            ...state,
            error: payload,
            fetchingUsers: false,
        }),
        [fetchUserRequest]: (state) => {
            return {
                ...state,
                fetchingUser: true,
                errorUser: null,
            }
        },
        [fetchUserSuccess]: (state, { payload }) => {
            return {
                ...state,
                user: payload,
                fetchingUser: false,
            }
        },
        [fetchUserFailure]: (state, { payload }) => ({
            ...state,
            errorUser: payload,
            fetchingUser: false,
        }),
        [updateUserRequest]: (state) => {
            return {
                ...state,
                submitting: true,
            }
        },
        [updateUserSuccess]: (state, { payload }) => {
            return {
                ...state,
                user: payload.data.item,
                submitting: false,
            }
        },
        [updateUserFailure]: (state, { payload }) => {
            return {
                ...state,
                submitting: false,
                error: payload,
            }
        },
        [fetchAgencyOfficesRequest]: (state) => ({
            ...state,
            fetchingOffices: true,
            error: null,
        }),
        [fetchAgencyOfficesSuccess]: (state, { payload }) => ({
            ...state,
            agencyOffices: payload,
            fetchingOffices: false,
        }),
        [fetchAgencyOfficesFailure]: (state, { payload }) => ({
            ...state,
            error: payload,
            fetchingOffices: false,
        }),
        [fetchAgencyOfficeRequest]: (state) => ({
            ...state,
            fetchingOffice: true,
            error: null,
        }),
        [fetchAgencyOfficeSuccess]: (state, { payload }) => ({
            ...state,
            agencyOffice: payload.item,
            fetchingOffice: false,
        }),
        [fetchAgencyOfficeFailure]: (state, { payload }) => ({
            ...state,
            error: payload,
            fetchingOffice: false,
        }),
        [updateAgencyOfficeRequest]: (state) => {
            return {
                ...state,
                submitting: true,
            }
        },
        [updateAgencyOfficeSuccess]: (state, { payload }) => {
            return {
                ...state,
                agencyOffice: payload.data.item,
                submitting: false,
            }
        },
        [updateAgencyOfficeFailure]: (state, { payload }) => {
            return {
                ...state,
                submitting: false,
                error: payload,
            }
        },

        [fetchAgencyPermissionsRequest]: (state) => ({
            ...state,
            fetchingAgencyPermissions: true,
            error: null,
        }),
        [fetchAgencyPermissionsSuccess]: (state, { payload }) => ({
            ...state,
            agencyPermissions: payload,
            fetchingAgencyPermissions: false,
        }),
        [fetchAgencyPermissionsFailure]: (state, { payload }) => ({
            ...state,
            error: payload,
            fetchingAgencyPermissions: false,
        }),
        [fetchAgencyRequest]: (state) => ({
            ...state,
            fetchingAgency: true,
            error: null,
        }),
        [fetchAgencySuccess]: (state, { payload }) => ({
            ...state,
            agency: payload,
            fetchingAgency: false,
        }),
        [fetchAgencyFailure]: (state, { payload }) => ({
            ...state,
            error: payload,
            fetchingAgency: false,
        }),
        [updateAgencyRequest]: (state) => {
            return {
                ...state,
                submitting: true,
            }
        },
        [updateAgencySuccess]: (state, { payload }) => {
            return {
                ...state,
                agency: payload.data.item,
                submitting: false,
            }
        },
        [updateAgencyFailure]: (state, { payload }) => {
            return {
                ...state,
                submitting: false,
                error: payload,
            }
        },
    },
    initialState
)
