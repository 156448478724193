import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { fetchOpportunityActivity } from '../../../store/properties'
import { selectOpportunityActivity, selectOpportunityActivityFetching, selectOpportunityActivityCount } from '../../../store/properties/selectors'
import TableContainer from '../../Table/TableContainer'
import TableColumns from './TableColumns/TableColumns'
import OpportunityDropDown from '../OpportunityDropDown/OpportunityDropDown'

const ActivityTable = () => {
    const dispatch = useDispatch()
    const { id } = useParams()

    const { propertyActivity, fetching, propertyActivityCount } = useSelector((state) => ({
        propertyActivity: selectOpportunityActivity(state),
        fetching: selectOpportunityActivityFetching(state),
        propertyActivityCount: selectOpportunityActivityCount(state),
    }))

    const handleDataFetch = (page, limit, order, criteria) => {
        dispatch(fetchOpportunityActivity({ page, limit, order, criteria: { ...criteria, property: [id] } }))
    }

    const columnsActivity = TableColumns()

    const tableActions = () => <OpportunityDropDown />

    return (
        <TableContainer
            tableActions={tableActions}
            columns={columnsActivity}
            data={propertyActivity || []}
            isGlobalFilter={false}
            isAddUserList={false}
            isLoading={fetching}
            customPageSize={20}
            tableClass="dmn-table--property-documents"
            theadClass={'dmn-table-header'}
            totalCount={propertyActivityCount}
            pageCount={Math.ceil(propertyActivityCount / 20)}
            handleDataFetch={handleDataFetch}
            tableHash={'opportunityActivityTable'}
        />
    )
}

export default ActivityTable
