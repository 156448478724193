import classNames from 'classnames'
import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Card, CardBody, CardTitle, Collapse } from 'reactstrap'
import AppPermissionsSwitcher from '../AppPermissionsSwitcher/AppPermissionsSwitcher'

const CollapseCard = ({ header, data, children }) => {
    const location = useLocation()
    const [isOpen, setIsOpen] = useState(false)

    const handleToggleCollapse = () => {
        setIsOpen((v) => !v)
    }

    return (
        <AppPermissionsSwitcher permission="ViewData" data={data} module={location}>
            {({ hasPermission }) =>
                hasPermission && (
                    <Card className="dmn-collapse-card">
                        <CardBody>
                            <CardTitle className="mb-0">
                                <button
                                    type="button"
                                    className={classNames('accordion-button fw-medium', { collapsed: !isOpen })}
                                    onClick={handleToggleCollapse}
                                >
                                    {header}
                                    <i
                                        className={classNames('mdi fs-5 ms-auto', {
                                            'mdi-chevron-up': isOpen,
                                            'mdi-chevron-down': !isOpen,
                                        })}
                                    />
                                </button>
                            </CardTitle>
                            <Collapse isOpen={isOpen} className="border-top mt-3">
                                <div className="mt-4">{children}</div>
                            </Collapse>
                        </CardBody>
                    </Card>
                )
            }
        </AppPermissionsSwitcher>
    )
}

export default CollapseCard
