import { stateKey } from "./actions";

export const selectSettingsData = (state) => state[stateKey].data;
export const selectSettingsLoading = (state) => state[stateKey].loading;
export const selectApplicationCurrency = (state) => state[stateKey].data?.currency;
export const selectSettingsDefaultLanguage = (state) => state[stateKey].data?.defaultLanguage;
export const selectApplicationAreaUnit = (state) => state[stateKey].data?.areaUnit;
export const selectSettingsAvailableLanguages = (state) => state[stateKey].data?.availableLanguages;
export const selectSettingsPropertyFlatSubTypes = (state) => state[stateKey].propertyFlatSubTypes;
export const selectSettingsPropertyHouseSubTypes = (state) => state[stateKey].propertyHouseSubTypes;
export const selectSettingsPropertyOfficeSpaceSubTypes = (state) => state[stateKey].propertyOfficeSpaceSubTypes;
export const selectSettingsPropertyLandSubTypes = (state) => state[stateKey].propertyLandSubTypes;
export const selectSettingsPropertyGarageSubTypes = (state) => state[stateKey].propertyGarageSubTypes;
export const selectCostTypes = () => {
    return [
        {
            value: '€/m2',
            label: '€/m2',
        },{
        value: '€',
        label: '€',
    }]
    
}