import i18n from 'i18next'
import { isEqual } from 'lodash'
import {
    ACTIVITY_FILTERS,
    ACTIVITY_VIEW_SELECT_ID,
    ALL_OPTION_ID,
    COMPANY_FILTERS,
    COMPANY_VIEW_SELECT_ID,
    CONTACT_FILTERS,
    CONTACT_VIEW_SELECT_ID,
    DOCUMENT_FILTERS,
    DOCUMENT_VIEW_SELECT_ID,
    OFFICE_OPTION_ID,
    OWN_OPTION_ID,
    PROJECT_FILTERS,
    PROJECT_VIEW_SELECT_ID,
    PROPERTY_FILTERS,
    PROPERTY_VIEW_SELECT_ID,
    VIEW_SELECT_IDS,
} from './Components/AgentPermissions/constants'

export const createSelectOptionData = (array, label, id) => {
    return array?.map((item) => ({
        label: item?.[`${label}`],
        value: item?.[`${id}`],
        ...item,
    }))
}

export const handleImageChange = (e, property, setState, formik, setImageInputError) => {
    const file = e.target.files[0]

    if (file.size > 1048576) {
        setImageInputError(i18n.t('admin.uploadImage'))
        return null
    }

    const fileUrl = URL.createObjectURL(file)

    const reader = new FileReader()
    reader.onloadend = function () {
        formik.setFieldValue(`${property}`, reader.result)
    }
    reader.readAsDataURL(file)
    setState(fileUrl)
}

export const removePrefix = (property) => {
    let number = property
    if (number === '+385') number = null
    return number
}

export const handleFilterValue = (permissionId, setterFn) => {
    const moduleMapping = {
        [PROJECT_VIEW_SELECT_ID]: 'project',
        [PROPERTY_VIEW_SELECT_ID]: 'property',
        [CONTACT_VIEW_SELECT_ID]: 'contact',
        [COMPANY_VIEW_SELECT_ID]: 'company',
        [ACTIVITY_VIEW_SELECT_ID]: 'activity',
        [DOCUMENT_VIEW_SELECT_ID]: 'document',
    }

    const filterValue = moduleMapping[permissionId]
    setterFn(filterValue)
}

export const handleSectionFilters = (filterValue) => {
    const filters = {
        PROJECT: PROJECT_FILTERS,
        PROPERTY: PROPERTY_FILTERS,
        CONTACT: CONTACT_FILTERS,
        COMPANY: COMPANY_FILTERS,
        ACTIVITY: ACTIVITY_FILTERS,
        DOCUMENT: DOCUMENT_FILTERS,
    }

    return filters[filterValue.toUpperCase()]
}

export const handleFilterConfig = (filterValue, optionId) => {
    const filterValues = ['project', 'property', 'contact', 'company', 'activity', 'document']

    if (optionId === OFFICE_OPTION_ID) {
        if (['project', 'property'].includes(filterValue)) {
            return { defaultValue: OFFICE_OPTION_ID, targetValue: ALL_OPTION_ID }
        } else if (['contact', 'company', 'activity', 'document'].includes(filterValue)) {
            return { defaultValue: OFFICE_OPTION_ID }
        }
    }

    if (optionId === ALL_OPTION_ID && filterValues.includes(filterValue)) {
        return { defaultValue: ALL_OPTION_ID }
    }
}

const handleShouldFilter = (array, optionId) => {
    return array.map((level) => {
        const { shouldFilter, shouldDisable, ...initialLevelObj } = level

        // reset to default values
        if (!optionId) return initialLevelObj

        // adds shouldFilter flag
        return level.id === optionId ? { ...initialLevelObj, shouldFilter: true } : initialLevelObj
    })
}

export const handleShouldDisable = (array) => {
    const permissionGroups = []

    array?.forEach((item) => {
        const isOwnOptionSelected = item.permissionLevel?.id === OWN_OPTION_ID

        if (isOwnOptionSelected && VIEW_SELECT_IDS.includes(item.permission.id)) {
            permissionGroups.push(item.permission.permissionGroup)
        }
    })

    return array?.map((item) => {
        if (permissionGroups.includes(item.permission.permissionGroup) && !VIEW_SELECT_IDS.includes(item.permission.id)) {
            return {
                ...item,
                permission: {
                    ...item.permission,
                    agentPermissionsLevels: item.permission.agentPermissionsLevels.map((level) => ({
                        ...level,
                        shouldDisable: true,
                    })),
                },
            }
        }
        return item
    })
}

export const handleMultipleFiltersChange = (array, filters, defaultValue, targetValue) => {
    return array?.map((item) => {
        const { permission, permissionLevel } = item ?? {}
        const { agentPermissionsLevels = [], id } = permission ?? {}

        if (filters.includes(id)) {
            const defaultPermissionValue = agentPermissionsLevels.find((level) => level.id === defaultValue) ?? permissionLevel

            return {
                permission: {
                    ...permission,
                    agentPermissionsLevels: handleShouldFilter(agentPermissionsLevels, targetValue),
                },
                permissionLevel: defaultPermissionValue,
            }
        }
        return item
    })
}

export const handleMultipleFiltersDisable = (permission) => {
    return permission.agentPermissionsLevels.every((level) => level?.shouldDisable)
}

export const handleIsEqual = (formikAgentPermissions, updatedAgentPermissions, formik) => {
    if (updatedAgentPermissions?.length && !isEqual(formikAgentPermissions, updatedAgentPermissions)) {
        formik.setFieldValue('agentPermissions', updatedAgentPermissions)
    }
}
