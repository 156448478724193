import dayjs from 'dayjs'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import AppPermissionsSwitcher from '../../../../Components/AppPermissionsSwitcher/AppPermissionsSwitcher'
import { action } from '../../../../Components/AppPermissionsSwitcher/constants/actions'
import TableCheckbox from '../../../../Components/Table/TableCheckbox'
import { getCheckboxProps } from '../../../../Components/Table/utils'
import TableShowHideText from '../../../../Components/TableShowHideText/TableShowHideText'
import { companyResponseFields } from '../../../../common/response/company'
import { contactResponseFields } from '../../../../common/response/contact'
import { formatDateTime, formatNumberWithTwoDecimals } from '../../../../utils/general'

const TableColumns = (onDelete, module) => {
    const { t } = useTranslation()

    const columns = useMemo(
        () => [
            {
                Header: ({ toggleRowSelected, isAllPageRowsSelected, page }) => {
                    const checkboxProps = getCheckboxProps(toggleRowSelected, isAllPageRowsSelected, page)

                    return <TableCheckbox {...checkboxProps} />
                },
                Cell: ({ row }) => {
                    return <TableCheckbox {...row.getToggleRowSelectedProps()} disabled={row.original.disabled} />
                },
                id: '#',
                tdClassName: 'text-center align-middle',
            },
            {
                Header: t('app.common.id'),
                accessor: 'id',
                filterable: true,
            },
            {
                Header: t('app.common.name'),
                id: `${companyResponseFields.CONTACT_COMPANY}Name`,
                accessor: `${companyResponseFields.CONTACT_COMPANY}.${companyResponseFields.NAME}`,
                filterable: true,
                minWidth: 200,
            },
            {
                Header: t('app.common.pin'),
                accessor: companyResponseFields.TAX_NUMBER,
                filterable: false,
            },
            {
                Header: t('app.common.country'),
                id: companyResponseFields.CONTACT_COUNTRY,
                accessor: `${companyResponseFields.CONTACT_COUNTRY}.name`,
                filterable: true,
            },
            {
                Header: t('app.common.city'),
                accessor: companyResponseFields.CITY,
                filterable: true,
            },
            {
                Header: t('app.common.street'),
                accessor: companyResponseFields.STREET,
                filterable: true,
            },
            {
                Header: t('app.common.streetNumber'),
                accessor: companyResponseFields.STREET_NUMBER,
                filterable: false,
            },
            {
                Header: t('app.common.status'),
                id: companyResponseFields.CONTACT_STATUS,
                Cell: (item) => <>{item.row.original?.[companyResponseFields.CONTACT_STATUS]?.name}</>,
                filterable: false,
                minWidth: 150,
            },
            {
                Header: t('app.common.profileType'),
                accessor: `${contactResponseFields.LAST_CONTACT_PROFILE}.${contactResponseFields.CONTACT_PROFILE_SERVICE}.name`,
                filterable: false,
            },
            {
                Header: t('app.common.contact.profileStatus'),
                accessor: contactResponseFields.CONTACT_PROFILE_STATUS,
                filterable: false,
                minWidth: 150,
            },
            {
                Header: t('app.common.contactPhoneNumber1'),
                id: `${companyResponseFields.CONTACT_PHONE}1`,
                accessor: (company) => company?.[companyResponseFields.CONTACT_PHONE]?.[0]?.phoneNumber,
                filterable: false,
                minWidth: 150,
            },
            {
                Header: t('table.header.contacts.contactPhoneNumber2'),
                id: `${companyResponseFields.CONTACT_PHONE}2`,
                accessor: (company) => company?.[companyResponseFields.CONTACT_PHONE]?.[1]?.phoneNumber,
                filterable: false,
                minWidth: 150,
            },
            {
                Header: t('app.common.email.label'),
                id: companyResponseFields.CONTACT_EMAIL,
                accessor: (company) => company?.[companyResponseFields.CONTACT_EMAIL]?.[0]?.email,
                filterable: false,
            },
            {
                Header: t('table.header.contacts.previousActivityDate'),
                id: 'previousActivityDate',
                accessor: (company) => <>{formatDateTime(company?.previousActivityDate)}</>,
                filterable: true,
            },
            {
                Header: t('table.header.contacts.nextActivityDate'),
                id: 'nextActivityDate',
                Cell: (item) => {
                    const nextActivityDate = item.row.original?.nextActivityDate
                    const dayDiff = dayjs().diff(nextActivityDate, 'day')
                    return <>{nextActivityDate && <span className={`${dayDiff > 0 && 'text-danger'}`}>{formatDateTime(nextActivityDate)}</span>}</>
                },
                filterable: true,
            },
            {
                Header: t('app.common.agent'),
                id: companyResponseFields.AGENT,
                Cell: (item) => (
                    <>
                        {item.row.original?.[companyResponseFields.AGENT]?.firstName} {item.row.original?.[companyResponseFields.AGENT]?.lastName}
                    </>
                ),
                filterable: true,
                minWidth: 150,
            },
            {
                Header: t('app.common.contact.establishmentDate'),
                id: companyResponseFields.INITIAL_CONTACT_DATE,
                Cell: (item) => formatDateTime(item.row.original?.[companyResponseFields.INITIAL_CONTACT_DATE]),
                filterable: true,
            },
            {
                Header: t('table.header.contacts.priceTo'),
                id: companyResponseFields.PRICE_TO,
                Cell: (item) => (
                    <>
                        {item.row.original?.[`${companyResponseFields.LAST_CONTACT_PROFILE}`]?.[`${companyResponseFields.PRICE_TO}`]
                            ? formatNumberWithTwoDecimals(
                                  item.row.original?.[`${companyResponseFields.LAST_CONTACT_PROFILE}`]?.[`${companyResponseFields.PRICE_TO}`]
                              )
                            : ''}
                    </>
                ),
                filterable: true,
            },
            {
                Header: t('app.common.contact.dataSource'),
                id: companyResponseFields.DATA_SOURCE,
                accessor: `${companyResponseFields.DATA_SOURCE}.name`,
                filterable: true,
            },
            {
                Header: t('table.header.contacts.notes'),
                id: companyResponseFields.NOTES,
                accessor: `${companyResponseFields.PREVIOUS_ACTIVITY}.${companyResponseFields.NOTES}`,
                filterable: false,
                Cell: (item, i) => <TableShowHideText key={i} text={item.cell.value} rowId={item.cell.row.id} numberOfRows={item.rows.length} />,
                minWidth: 300,
            },
            {
                Header: t('app.common.activityType'),
                id: companyResponseFields.NEXT_ACTIVITY,
                accessor: `${companyResponseFields.NEXT_ACTIVITY}.activityType.name`,
                filterable: false,
            },
            {
                id: 'actions',
                thDivClass: 'text-center',
                Cell: (cellProps) => {
                    const itemAgentId = cellProps.row.original.agent?.id
                    const itemAgentOfficeId = cellProps.row.original.agent?.agencyOffice?.id

                    return (
                        <ul className="list-inline hstack gap-2 mb-0 justify-content-center">
                            <li className="list-inline-item">
                                <AppPermissionsSwitcher
                                    permission="ModulePermissions"
                                    itemAgentId={itemAgentId}
                                    itemAgentOfficeId={itemAgentOfficeId}
                                    module={module}
                                    action={action.view}
                                >
                                    {({ hasModulePermission }) =>
                                        hasModulePermission && (
                                            <Link
                                                title={t('button.view')}
                                                to={`/companies/${cellProps.row.original.id}`}
                                                className="text-info d-inline-block"
                                            >
                                                <i className="mdi mdi-eye-outline fs-16"></i>
                                            </Link>
                                        )
                                    }
                                </AppPermissionsSwitcher>
                            </li>

                            <AppPermissionsSwitcher
                                permission="ModulePermissions"
                                itemAgentId={itemAgentId}
                                itemAgentOfficeId={itemAgentOfficeId}
                                module={module}
                                action={action.edit}
                            >
                                {({ hasModulePermission }) =>
                                    hasModulePermission && (
                                        <>
                                            <li className="list-inline-item edit">
                                                <Link
                                                    title={t('button.edit')}
                                                    to={`/companies/${cellProps.row.original.id}/edit`}
                                                    className="text-info d-inline-block edit-item-btn"
                                                >
                                                    <i className="mdi mdi-pencil-outline fs-16"></i>
                                                </Link>
                                            </li>
                                            <li className="list-inline-item edit">
                                                <Link
                                                    title={t('button.delete')}
                                                    to="#"
                                                    className="text-danger d-inline-block remove-item-btn"
                                                    onClick={() => onDelete(cellProps.row.original.id)}
                                                >
                                                    <i className="mdi mdi-trash-can-outline fs-16"></i>
                                                </Link>
                                            </li>
                                        </>
                                    )
                                }
                            </AppPermissionsSwitcher>
                        </ul>
                    )
                },
            },
        ],
        [onDelete, module, t]
    )

    return columns
}

export default TableColumns
