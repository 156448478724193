import React from 'react'
import { useTranslation } from 'react-i18next'
import { Input } from 'reactstrap'
import Loader from '../../Components/Common/Loader'
import { hexToRGB } from '../../utils/general'

const ExternalEvents = ({ activityTypes, selectedActivityTypes, setSelectedActivityTypes }) => {
    const { t } = useTranslation()

    const onDrag = (event) => {
        event.preventDefault()
    }

    return (
        <div id="external-events">
            <br />
            <p className="text-muted">{t('activityCalendar.info')}</p>
            {activityTypes && activityTypes.length ? (
                activityTypes.map((category) => (
                    <div
                        className={` external-event fc-event text-primary d-flex justify-content-between align-items-center`}
                        style={{
                            // color: category.color,
                            backgroundColor: `${hexToRGB(category.color, 0.18)}`,
                        }}
                        key={'cat-' + category.id}
                        draggable
                        onDragEnter={(event) => {
                            onDrag(event, category)
                        }}
                        data-activity-type-id={category.id}
                    >
                        <div>
                            <i className="mdi mdi-checkbox-blank-circle font-size-11 me-2" style={{ color: category.color }} />
                            {category.name}
                        </div>
                        <div className="ms-2 form-check">
                            <Input
                                type="checkbox"
                                className="form-check-input"
                                id={category.name}
                                name={category.name}
                                value={category.id}
                                checked={selectedActivityTypes.includes(category.id.toString())}
                                onChange={(e) => {
                                    let chcked = e.target.checked
                                    if (chcked) {
                                        setSelectedActivityTypes([...selectedActivityTypes, e.target.value])
                                    } else {
                                        setSelectedActivityTypes(selectedActivityTypes.filter((x) => x !== e.target.value))
                                    }
                                }}
                            />
                        </div>
                    </div>
                ))
            ) : (
                <Loader />
            )}
        </div>
    )
}

export default ExternalEvents
