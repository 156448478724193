import React, { useState } from 'react'
import { Button, Card, CardBody, Col, Row } from 'reactstrap'
import { Link } from 'react-router-dom'
import SearchBar from '../SearchBar/SearchBar'
import i18n from 'i18next'
import style from './CardTableView.module.css'
import ActiveButton from './ActiveButton/ActiveButton'
import ImageContainer from '../ImageContainer/ImageContainer'

const CardTableView = (props) => {
    const { data, fetching, filteredData, editButtonLink, setFilteredData, searchFields, isUserView } = props
    const [showCardView, setShowCardView] = useState(true)

    return (
        <>
            <Card>
                <CardBody>
                    <Row className="g-2">
                        <Col sm={4}>
                            <SearchBar
                                data={data}
                                fetching={fetching}
                                setFilteredData={setFilteredData}
                                searchFieldFirst={searchFields?.firstName}
                                searchFieldSecond={searchFields?.lastName}
                            />
                        </Col>
                        {isUserView ? (
                            <Col className="col-sm-auto ms-auto">
                                <div className="list-grid-nav hstack gap-1">
                                    <Button
                                        color="info"
                                        id="grid-view-button"
                                        className={`btn btn-soft-info nav-link btn-icon fs-14 filter-button ${showCardView ? 'active' : ''}`}
                                        onClick={() => setShowCardView(true)}
                                    >
                                        <i className="ri-grid-fill"></i>
                                    </Button>
                                    <Button
                                        color="info"
                                        id="list-view-button"
                                        className={`btn btn-soft-info nav-link btn-icon fs-14 filter-button ${showCardView ? '' : 'active'}`}
                                        onClick={() => setShowCardView(false)}
                                    >
                                        <i className="ri-list-unordered"></i>
                                    </Button>
                                </div>
                            </Col>
                        ) : (
                            <Col className="col-sm-auto ms-auto">
                                <div className="list-grid-nav hstack gap-1">
                                    <Link className="btn btn-success" to={() => '/admin/office/create'}>
                                        <i className="ri-add-fill me-1 align-bottom"></i> {i18n.t('admin.createNewOffice')}
                                    </Link>
                                </div>
                            </Col>
                        )}
                    </Row>
                </CardBody>
            </Card>

            <Col lg={12}>
                <Row className={`team-list ${showCardView ? 'grid-view-filter' : 'list-view-filter'}`}>
                    {filteredData?.map((item, key) => {
                        return (
                            <Col key={key}>
                                <Card className={`${showCardView ? style.userCard : null} team-box`}>
                                    <div className={`team-cover ${style.cardCover}`}>
                                        {item?.agency?.logo ? <img src={item?.agency?.logo} alt="" className="img-fluid" /> : null}
                                    </div>
                                    <CardBody className="p-4">
                                        <Row className="align-items-center team-row">
                                            {isUserView ? (
                                                <>
                                                    <Col className="team-settings">
                                                        <Row>
                                                            <Col>
                                                                <div className="flex-shrink-0 me-2">
                                                                    <ActiveButton user={item} filteredUsers={filteredData} />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col lg={4} className="col">
                                                        <div className="team-profile-img">
                                                            <ImageContainer
                                                                imageUrl={item.photo}
                                                                firstName={item.firstName}
                                                                lastName={item.lastName}
                                                                roundContainer={true}
                                                            />
                                                            <div className="team-content">
                                                                <h5 className="fs-16 mb-1">
                                                                    {item.firstName} {item.lastName}
                                                                </h5>
                                                                <p className="text-muted mb-0">{item?.agencyOffice?.name}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </>
                                            ) : (
                                                <Col lg={4} className="col mt-4">
                                                    <div className="team-profile-img">
                                                        <ImageContainer imageUrl={item?.logo} firstName={item.name} roundContainer={true} />
                                                        <div className="team-content">
                                                            <h5 className="fs-16 mb-1">{item.name}</h5>
                                                            <p className="text-muted mb-0">
                                                                {item?.street} {item?.streetNumber}, {item?.city}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Col>
                                            )}

                                            <Col lg={4} className="col">
                                                <div className={`d-flex ${showCardView ? 'flex-column' : 'flex-row'}`}>
                                                    <Col
                                                        className={`d-flex align-items-center mb-1 ${
                                                            showCardView ? 'justify-content-center' : 'justify-content-start'
                                                        }`}
                                                    >
                                                        <i className="mdi mdi-email"></i>
                                                        <p className="text-muted mb-0 ms-1">{isUserView ? item.primaryEmail : item.email}</p>
                                                    </Col>
                                                    {item?.phoneNumber && showCardView && (
                                                        <Col className="d-flex justify-content-center align-items-center mb-1">
                                                            <i className="mdi mdi-phone"></i>
                                                            <p className="text-muted mb-0 ms-1">{item.phoneNumber}</p>
                                                        </Col>
                                                    )}
                                                    {item?.mobileNumber && showCardView && (
                                                        <Col className="d-flex justify-content-center align-items-center mb-1">
                                                            <i className="mdi mdi-cellphone"></i>
                                                            <p className="text-muted mb-0 ms-1">{item.mobileNumber}</p>
                                                        </Col>
                                                    )}
                                                </div>
                                            </Col>

                                            <Col lg={2} className={`${showCardView ? style.buttonContainer : null} col`}>
                                                <div className="d-flex justify-content-center">
                                                    <Link to={`${editButtonLink}/${item.id}/edit`} className="btn btn-success view-btn w-100 me-1">
                                                        {i18n.t('button.edit')}
                                                    </Link>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        )
                    })}
                </Row>
            </Col>
        </>
    )
}

export default CardTableView
