import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { propertyDetailSearchInputs } from "../common/forms/generalRealEstate/inputs";
import { propertyDetailSearchValidation } from "../common/forms/generalRealEstate/validations";
import { selectCurrentUser, selectCurrentUserSettings } from "../store/auth/selectors";

export const useCurrentUser = (data) => {
    const { user, userSettings } = useSelector(state => ({
        user: selectCurrentUser(state),
        userSettings: selectCurrentUserSettings(state)
    }))

    return {
        user,
        userSettings
    }
}