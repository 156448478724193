import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { DETAIL_SEARCH_TYPE } from '../../common/constants'
import DetailSearchModal from '../Modals/DetailSearchModal'

const DetailSearchOption = () => {
    const [detailSearchModal, setDetailSearchModal] = useState(false)
    const [detailSearchType, setDetailSearchType] = useState(null)
    const [size, setSize] = useState('xl')
    let { pathname } = useLocation()
    const { t } = useTranslation()

    useEffect(() => {
        if (pathname.includes('properties')) {
            setDetailSearchType(DETAIL_SEARCH_TYPE.PROPERTY)
            
            setSize('xl')
        } else if (pathname.includes('contacts')) {
            setDetailSearchType(DETAIL_SEARCH_TYPE.CONTACT)
            setSize('xl')
        } else if (pathname.includes('companies')) {
            setDetailSearchType(DETAIL_SEARCH_TYPE.COMPANY)
            setSize('xl')
        } else if (pathname.includes('projects') || pathname === '/') {
            setDetailSearchType(DETAIL_SEARCH_TYPE.PROJECT)
            setSize('l')
        } else if (pathname.includes('diary')) {
            setDetailSearchType(DETAIL_SEARCH_TYPE.DIARY)
            setSize('xl')
        } else {
            setDetailSearchType(null)
        }
    }, [pathname])

    const toggleDetailSearchModal = () => {
        if (detailSearchModal) {
            setDetailSearchModal(false)
        } else {
            setDetailSearchModal(true)
        }
    }
    return (
        <>
            <div className="d-none d-md-flex header-item ms-4">
                <span className="btn btn-ghost-secondary text-white detail-search-button" onClick={toggleDetailSearchModal}>
                    {t('form.detailSearch.label')} <i className="mdi mdi-plus-circle ms-1"></i>
                </span>
            </div>
            <DetailSearchModal size={size} isOpen={detailSearchModal} toggle={toggleDetailSearchModal} type={detailSearchType} />
        </>
    )
}

export default DetailSearchOption
