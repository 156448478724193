import { handleActions } from 'redux-actions'
import { v4 as uuid } from 'uuid'
import {
    clearGroundPlans,
    clearQueGroundPlan,
    deleteGroundPlanSuccess,
    fetchGroundPlansFailure,
    fetchGroundPlansRequest,
    fetchGroundPlansSuccess,
    queGroundPlanSuccess,
    reorderGroundPlans,
    syncGroundPlanSuccess,
    updateGroundPlanSuccess,
    updateGroundPlansCount,
    uploadGroundPlanFailure,
    uploadGroundPlanRequest,
    uploadGroundPlanSuccess,
    proxyGroundPlanEditSuccess,
    proxyGroundPlanEditRequest,
    proxyGroundPlanEditFailure,
    proxyGroundPlanEditClear,
    proxyGroundPlanUpdateList
} from './actions'

export const initialState = {
    items: [],
    count: 0,
    fetching: false,
    error: null,
    que: {},
    proxyPhoto: null,
    proxyPhotoFetching: false,
    proxyPhotoError: null,
    translations: {},
}

export default handleActions(
    {
        [fetchGroundPlansRequest]: (state) => ({
            ...state,
            fetching: true,
            error: null,
        }),
        [fetchGroundPlansSuccess]: (state, { payload }) => {
            return {
                ...state,
                count: payload?.count ?? 0,
                items: payload?.items ?? [],
                fetching: false,
            }
        },
        [fetchGroundPlansFailure]: (state, { payload }) => ({
            ...state,
            error: payload,
            fetching: false,
        }),
        [uploadGroundPlanRequest]: (state) => {
            return {
                ...state,
                uploading: true,
            }
        },
        [uploadGroundPlanSuccess]: (state, { payload }) => {
            return {
                ...state,
                items: [...state.items, payload],
                uploading: false,
                translations: payload.translations,
            }
        },
        [uploadGroundPlanFailure]: (state) => {
            return {
                ...state,
                uploading: false,
            }
        },
        [deleteGroundPlanSuccess]: (state, { payload }) => {
            const updatedGroundPlans = state.items.filter((photo) => photo.id !== +payload).map((photo, index) => ({ ...photo, priority: index + 1 }))

            return {
                ...state,
                deleting: false,
                items: updatedGroundPlans,
                count: state.count - 1,
            }
        },
        [syncGroundPlanSuccess]: (state, { payload }) => {
            const updatedGroundPlans = state.items.map((photo) => (photo.id === +payload ? { ...photo, fileSync: !photo.fileSync } : photo))

            return {
                ...state,
                items: updatedGroundPlans,
            }
        },
        [reorderGroundPlans]: (state, { payload }) => {
            const { fromIndex, toIndex } = payload

            const groundPlans = [...state.items]
            const [removedItem] = groundPlans.splice(fromIndex, 1)
            groundPlans.splice(toIndex, 0, removedItem)

            const updatedGroundPlans = groundPlans.map((item, index) => ({
                ...item,
                priority: index + 1,
            }))

            return {
                ...state,
                items: updatedGroundPlans,
            }
        },
        [proxyGroundPlanEditRequest]: (state) => ({
            ...state,
            proxyPhotoFetching: true,
            proxyPhotoError: null,
            proxyPhoto: null,
        }),
        [proxyGroundPlanEditSuccess]: (state, { payload }) => {
            return {
                ...state,
                proxyPhoto: payload,
                proxyPhotoFetching: false,
            }
        },
        [proxyGroundPlanEditFailure]: (state, { payload }) => ({
            ...state,
            proxyPhotoError: payload,
            proxyPhotoFetching: false,
        }),
        [proxyGroundPlanEditClear]: (state) => ({
            ...state,
            proxyPhoto: null,
        }),
        [proxyGroundPlanUpdateList]: (state) => {
            const items = [...state.items]

            return {
                ...state,
                items: items,
            }
        },
        [updateGroundPlanSuccess]: (state, { payload }) => {
            const { id, file } = payload

            const updatedPhotos = state.items.map((photo) => (photo.id === id ? { ...photo, base64: file } : photo))

            return {
                ...state,
                items: updatedPhotos
            }
        },
        [updateGroundPlansCount]: (state, { payload }) => {
            return {
                ...state,
                count: payload,
            }
        },
        [clearGroundPlans]: (state) => {
            return {
                ...state,
                items: [],
            }
        },

        // TODO: quePhoto probably not needed anymore
        [queGroundPlanSuccess]: (state, { payload }) => {
            console.log('que payload', payload)
            return {
                ...state,
                que: {
                    ...state.que,
                    ...payload?.reduce((acc, item) => {
                        // let parsedItem = JSON.parse(item);
                        let id = uuid()
                        return {
                            ...acc,
                            [id]: Object.assign(item, {
                                id: id,
                                tmpId: id,
                                preview: URL.createObjectURL(item),
                                // formattedSize: formatBytes(file.size),
                                queue: true,
                                uploading: false,
                            }),
                        }
                    }, {}),
                },
            }
        },
        [clearQueGroundPlan]: (state, { payload, file }) => {
            return {
                ...state,
                que: {},
            }
        },
    },
    initialState
)
