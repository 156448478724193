import { put, takeLatest } from "redux-saga/effects";
import { apiRequest } from "../api";
import { fetchAgentActivities, fetchReportsFailure, fetchReportsRequest, fetchReportsSuccess, fetchAgentRevenue, fetchAgentInquiries } from "./actions";
import QueryString from "qs";

const REPORTS_BASE_URI = 'v1/report/agents'

function* fetchReport(path, { payload, meta }) {
    yield put(fetchReportsRequest());
    try {
        const response = yield apiRequest(`${REPORTS_BASE_URI}/${path}`, {
            method: 'GET',
            params: payload,
            paramsSerializer: ({ criteria }) => {
                // Pass only criteria query param
                return QueryString.stringify(criteria)
            }
        });
        yield put(fetchReportsSuccess(response.data));
        meta(response.data.count);
    } catch (error) {
        yield put(fetchReportsFailure(error));
    }
}

export function* fetchActivityReportsSaga(requestOptions) {
    yield fetchReport('activities', requestOptions)
}

export function* fetchAgentRevenueSaga(requestOptions) {
    yield fetchReport('revenue', requestOptions)
}

export function* fetchAgentInquiriesSaga(requestOptions) {
    yield fetchReport('inquires', requestOptions)
}

export default function* actionWatcher() {
    yield takeLatest(`${fetchAgentActivities}`, fetchActivityReportsSaga);
    yield takeLatest(`${fetchAgentRevenue}`, fetchAgentRevenueSaga);
    yield takeLatest(`${fetchAgentInquiries}`, fetchAgentInquiriesSaga);
}