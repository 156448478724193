import { getIn } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Dropdown, DropdownItem, DropdownMenu, Input, InputGroup, Label, UncontrolledTooltip } from 'reactstrap'
import useClickOutside from '../Hooks/useClickOutside'

export const SelectInputWithInputs = ({
    inputField,
    isFieldArray,
    formik,
    fieldName,
    index,
    fieldArrayDeleteComponent,
    selectOptions,
    inputsLength,
    inputMargin,
}) => {
    const dropdownRef = useRef(null)
    const [selectedOptions, setSelectedOptions] = useState([])
    const [isOpen, setIsOpen] = useState(false)
    const formikValue = getIn(formik?.values, fieldName)

    const { t } = useTranslation()
    useClickOutside(dropdownRef, setIsOpen)

    useEffect(() => {
        if (formikValue && formikValue.length) {
            if (selectOptions && selectOptions[inputField.selectOptionsName] && selectOptions[inputField.selectOptionsName].length) {
                const propertySpaceIds = formikValue?.map((x) => x?.['property_space_id'])
                const selectedOpts = selectOptions[inputField.selectOptionsName]?.filter(
                    (option) => propertySpaceIds?.indexOf(option.value?.toString()) >= 0
                )
                setSelectedOptions(selectedOpts)
            }
        } else {
            setSelectedOptions([])
        }
    }, [formikValue, selectOptions, inputField.selectOptionsName])

    const handleFormikTextInputChange = (formikValueItem, value, name) => {
        const formikValueItemIndex = formikValue?.findIndex((x) => x['property_space_id'] === formikValueItem['property_space_id'])
        const destValues = [...formikValue]
        if (formikValueItemIndex > -1) {
            destValues[formikValueItemIndex][name] = value
        }

        formik.setFieldValue(fieldName, destValues)
    }

    return (
        <>
            <Col {...inputField.colProps} className={`${inputField.colProps?.className ?? ''} ${!isFieldArray ? inputMargin : 'mb-2'}`}>
                {inputField.label && (
                    <div className="d-flex justify-content-between">
                        <Label for={fieldName} id={`${inputField.name}Lbl`}>
                            {inputField.label}
                            {inputField.required && <span className="required-mark">*</span>}
                        </Label>
                        {inputField.tooltip && (
                            <span id={`${inputField.name}Tooltip`}>
                                <i className="mdi mdi-help-circle-outline"></i>{' '}
                            </span>
                        )}
                    </div>
                )}

                <InputGroup className="mb-3">
                    <Input
                        className="rounded"
                        type="text"
                        value={selectedOptions?.map((x) => x.label).join(', ') || []}
                        readOnly={true}
                        onClick={() => setIsOpen(true)}
                    />
                    <div ref={dropdownRef}>
                        <Dropdown isOpen={isOpen} toggle={() => {}}>
                            <DropdownMenu
                                style={{
                                    maxHeight: '400px',
                                    overflowY: 'scroll',
                                }}
                            >
                                <DropdownItem className="d-flex pe-none">
                                    <span className="flex-grow-1 fw-medium fs-12">{t('app.common.room')}</span>
                                    <span
                                        style={{
                                            width: '40px',
                                        }}
                                        className="fw-medium ms-2 fs-12 text-center"
                                    >
                                        {t('app.common.spaceCount')}
                                    </span>
                                    <span style={{ width: '80px' }} className="fw-medium ms-2 fs-12 text-center">
                                        {t('app.common.spaceArea')}
                                    </span>
                                </DropdownItem>
                                {selectOptions[inputField.selectOptionsName]?.map((option) => {
                                    const formikValueItem = formikValue?.find((x) => {
                                        return x[inputField.inputs[0].name] === option.value
                                    })

                                    return (
                                        <DropdownItem
                                            key={option.value}
                                            className="d-flex align-items-center"
                                            onClick={(e) => {
                                                e.preventDefault()
                                                e.stopPropagation()
                                            }}
                                        >
                                            <div className="form-check flex-grow-1">
                                                <Input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    name={`${fieldName}.${inputField.inputs[0].name}`}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            if (!formikValueItem) {
                                                                formik?.setFieldValue(fieldName, [
                                                                    ...formikValue,
                                                                    {
                                                                        [inputField.inputs[0].name]: e.target.value,
                                                                        [inputField.inputs[1].name]: '',
                                                                        [inputField.inputs[2].name]: '',
                                                                    },
                                                                ])
                                                            }
                                                        } else {
                                                            formik?.setFieldValue(fieldName, [
                                                                ...formikValue.filter((x) => x[inputField.inputs[0].name] !== e.target.value),
                                                            ])
                                                        }
                                                    }}
                                                    value={option.value || ''}
                                                    checked={formikValueItem ? true : false}
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                    }}
                                                />
                                                <Label
                                                    className="form-check-label"
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                    }}
                                                >
                                                    {option.label}
                                                </Label>
                                            </div>
                                            <div className="fs-12 ms-2" style={{ width: '40px' }}>
                                                <Input
                                                    className="fs-12"
                                                    type="text"
                                                    name={`${fieldName}.${inputField.inputs[1].name}`}
                                                    value={formikValueItem?.[inputField.inputs[1].name] || ''}
                                                    disabled={!formikValueItem ? true : false}
                                                    onChange={(e) => {
                                                        if (formikValueItem) {
                                                            handleFormikTextInputChange(formikValueItem, e.target.value, inputField.inputs[1].name)
                                                        }
                                                    }}
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        e.stopPropagation()
                                                    }}
                                                />
                                            </div>
                                            <div className="fs-12 ms-2" style={{ width: '80px' }}>
                                                <Input
                                                    className="fs-12"
                                                    type="text"
                                                    name={`${fieldName}.${inputField.inputs[2].name}`}
                                                    disabled={!formikValueItem ? true : false}
                                                    value={formikValueItem?.[inputField.inputs[2].name] || ''}
                                                    onChange={(e) => {
                                                        if (formikValueItem) {
                                                            handleFormikTextInputChange(formikValueItem, e.target.value, inputField.inputs[2].name)
                                                        }
                                                    }}
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        e.stopPropagation()
                                                    }}
                                                />
                                            </div>
                                        </DropdownItem>
                                    )
                                })}
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </InputGroup>

                {inputField.tooltip && (
                    <UncontrolledTooltip pement="right" target={`${inputField.name}Tooltip`}>
                        {inputField.tooltip}
                    </UncontrolledTooltip>
                )}
            </Col>
            {isFieldArray && index === inputsLength && fieldArrayDeleteComponent}
        </>
    )
}
