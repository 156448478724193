import { createAction } from 'redux-actions';

const ENTITY = 'SEARCHVALUES';
export const stateKey = ENTITY.toLowerCase();

const COUNTRY = 'COUNTRY'; 
const COUNTY = 'COUNTY'; 
const CITY = 'CITY';
const WIDERAREA = 'WIDERAREA';
const QUARTER = 'QUARTER';
const ISLAND = 'ISLAND';

export const fetchDetailSearchLocationsCountry = createAction(`${ENTITY}/${COUNTRY}/FETCH`);
export const fetchDetailSearchLocationsCountryRequest = createAction(`${ENTITY}/${COUNTRY}/FETCH_REQUEST`);
export const fetchDetailSearchLocationsCountrySuccess = createAction(`${ENTITY}/${COUNTRY}/FETCH_SUCCESS`);
export const fetchDetailSearchLocationsCountryFailure = createAction(`${ENTITY}/${COUNTRY}/FETCH_FAILURE`);

export const fetchDetailSearchLocationsCounty = createAction(`${ENTITY}/${COUNTY}/FETCH`);
export const fetchDetailSearchLocationsCountyRequest = createAction(`${ENTITY}/${COUNTY}/FETCH_REQUEST`);
export const fetchDetailSearchLocationsCountySuccess = createAction(`${ENTITY}/${COUNTY}/FETCH_SUCCESS`);
export const fetchDetailSearchLocationsCountyFailure = createAction(`${ENTITY}/${COUNTY}/FETCH_FAILURE`);

export const fetchDetailSearchLocationsCity = createAction(`${ENTITY}/${CITY}/FETCH`);
export const fetchDetailSearchLocationsCityRequest = createAction(`${ENTITY}/${CITY}/FETCH_REQUEST`);
export const fetchDetailSearchLocationsCitySuccess = createAction(`${ENTITY}/${CITY}/FETCH_SUCCESS`);
export const fetchDetailSearchLocationsCityFailure = createAction(`${ENTITY}/${CITY}/FETCH_FAILURE`);

export const fetchDetailSearchLocationsWiderArea = createAction(`${ENTITY}/${WIDERAREA}/FETCH`);
export const fetchDetailSearchLocationsWiderAreaRequest = createAction(`${ENTITY}/${WIDERAREA}/FETCH_REQUEST`);
export const fetchDetailSearchLocationsWiderAreaSuccess = createAction(`${ENTITY}/${WIDERAREA}/FETCH_SUCCESS`);
export const fetchDetailSearchLocationsWiderAreaFailure = createAction(`${ENTITY}/${WIDERAREA}/FETCH_FAILURE`);

export const fetchDetailSearchLocationsQuarter = createAction(`${ENTITY}/${QUARTER}/FETCH`);
export const fetchDetailSearchLocationsQuarterRequest = createAction(`${ENTITY}/${QUARTER}/FETCH_REQUEST`);
export const fetchDetailSearchLocationsQuarterSuccess = createAction(`${ENTITY}/${QUARTER}/FETCH_SUCCESS`);
export const fetchDetailSearchLocationsQuarterFailure = createAction(`${ENTITY}/${QUARTER}/FETCH_FAILURE`);

export const fetchDetailSearchLocationsIsland = createAction(`${ENTITY}/${ISLAND}/FETCH`);
export const fetchDetailSearchLocationsIslandRequest = createAction(`${ENTITY}/${ISLAND}/FETCH_REQUEST`);
export const fetchDetailSearchLocationsIslandSuccess = createAction(`${ENTITY}/${ISLAND}/FETCH_SUCCESS`);
export const fetchDetailSearchLocationsIslandFailure = createAction(`${ENTITY}/${ISLAND}/FETCH_FAILURE`);

export const fetchDetailSearchPropertySubtypes = createAction(`${ENTITY}/PROPERTY_SUBTYPE/FETCH`);
export const fetchDetailSearchPropertySubtypesRequest = createAction(`${ENTITY}/PROPERTY_SUBTYPE/FETCH_REQUEST`);
export const fetchDetailSearchPropertySubtypesSuccess = createAction(`${ENTITY}/PROPERTY_SUBTYPE/FETCH_SUCCESS`);
export const fetchDetailSearchPropertySubtypesFailure = createAction(`${ENTITY}/PROPERTY_SUBTYPE/FETCH_FAILURE`);

export const fetchDetailSearchPropertyValuesDescriptions = createAction(`${ENTITY}/PROPERTY_DESCRIPTIONS/FETCH`);
export const fetchDetailSearchPropertyValuesDescriptionsRequest = createAction(`${ENTITY}/PROPERTY_DESCRIPTIONS/FETCH_REQUEST`);
export const fetchDetailSearchPropertyValuesDescriptionsSuccess = createAction(`${ENTITY}/PROPERTY_DESCRIPTIONS/FETCH_SUCCESS`);
export const fetchDetailSearchPropertyValuesDescriptionsFailure = createAction(`${ENTITY}/PROPERTY_DESCRIPTIONS/FETCH_FAILURE`);

export const fetchDetailSearchPropertyValuesUtility = createAction(`${ENTITY}/PROPERTY_UTILITY/FETCH`);
export const fetchDetailSearchPropertyValuesUtilityRequest = createAction(`${ENTITY}/PROPERTY_UTILITY/FETCH_REQUEST`);
export const fetchDetailSearchPropertyValuesUtilitySuccess = createAction(`${ENTITY}/PROPERTY_UTILITY/FETCH_SUCCESS`);
export const fetchDetailSearchPropertyValuesUtilityFailure = createAction(`${ENTITY}/PROPERTY_UTILITY/FETCH_FAILURE`);

export const fetchDetailSearchPropertyValuesSpace = createAction(`${ENTITY}/PROPETY_SPACE/FETCH`);
export const fetchDetailSearchPropertyValuesSpaceRequest = createAction(`${ENTITY}/PROPERTY_SPACE/FETCH_REQUEST`);
export const fetchDetailSearchPropertyValuesSpaceSuccess = createAction(`${ENTITY}/PROPERTY_SPACE/FETCH_SUCCESS`);
export const fetchDetailSearchPropertyValuesSpaceFailure = createAction(`${ENTITY}/PROPERTY_SPACE/FETCH_FAILURE`);

export const fetchDetailSearchPropertyValuesEquipment = createAction(`${ENTITY}/PROPERTY_EQUIPMENT/FETCH`);
export const fetchDetailSearchPropertyValuesEquipmentRequest = createAction(`${ENTITY}/PROPERTY_EQUIPMENT/FETCH_REQUEST`);
export const fetchDetailSearchPropertyValuesEquipmentSuccess = createAction(`${ENTITY}/PROPERTY_EQUIPMENT/FETCH_SUCCESS`);
export const fetchDetailSearchPropertyValuesEquipmentFailure = createAction(`${ENTITY}/PROPERTY_EQUIPMENT/FETCH_FAILURE`);

export const fetchDetailSearchPropertyTypes = createAction(`${ENTITY}/PROPERTY_TYPE/FETCH`);
export const fetchDetailSearchPropertyTypesRequest = createAction(`${ENTITY}/PROPERTY_TYPE/FETCH_REQUEST`);
export const fetchDetailSearchPropertyTypesSuccess = createAction(`${ENTITY}/PROPERTY_TYPE/FETCH_SUCCESS`);
export const fetchDetailSearchPropertyTypesFailure = createAction(`${ENTITY}/PROPERTY_TYPE/FETCH_FAILURE`);

export const fetchDetailSearchPropertyValuesFloor = createAction(`${ENTITY}/PROPERTY_FLOOR/FETCH`);
export const fetchDetailSearchPropertyValuesFloorRequest = createAction(`${ENTITY}/PROPERTY_FLOOR/FETCH_REQUEST`);
export const fetchDetailSearchPropertyValuesFloorSuccess = createAction(`${ENTITY}/PROPERTY_FLOOR/FETCH_SUCCESS`);
export const fetchDetailSearchPropertyValuesFloorFailure = createAction(`${ENTITY}/PROPERTY_FLOOR/FETCH_FAILURE`);