import { useSelector } from 'react-redux'
import { Card, CardBody } from 'reactstrap'
import ActivityTable from './ActivityTable/ActivityTable'
import ContactTable from './ContactTable/ContactTable'
import { selectOpportunityShowActivity } from '../../store/properties/selectors'

const OpportunityList = () => {
    const { showActivity } = useSelector((state) => ({
        showActivity: selectOpportunityShowActivity(state),
    }))

    return (
        <Card>
            <CardBody>{showActivity ? <ActivityTable /> : <ContactTable />}</CardBody>
        </Card>
    )
}

export default OpportunityList
