import { createAction } from 'redux-actions';

const ENTITY = 'CONTACTPROFILES';
export const stateKey = ENTITY.toLowerCase();

export const fetchContactProfiles = createAction(`${ENTITY}/FETCH`);
export const fetchContactProfilesRequest = createAction(`${ENTITY}/FETCH_REQUEST`);
export const fetchContactProfilesSuccess = createAction(`${ENTITY}/FETCH_SUCCESS`);
export const fetchContactProfilesFailure = createAction(`${ENTITY}/FETCH_FAILURE`);

export const fetchContactProfile = createAction(`${ENTITY}/DETAIL/FETCH`);
export const fetchContactProfileRequest = createAction(`${ENTITY}/DETAIL/FETCH_REQUEST`);
export const fetchContactProfileSuccess = createAction(`${ENTITY}/DETAIL/FETCH_SUCCESS`);
export const fetchContactProfileFailure = createAction(`${ENTITY}/DETAIL/FETCH_FAILURE`);

export const clearContactProfile = createAction(`${ENTITY}/DETAIL/CLEAR`);

export const createContactProfile = createAction(
    `${ENTITY}/CREATE`,
    values => values,
    (values, callback) => ({ callback })
);
// export const createContact = createAction(`${ENTITY}/CREATE`);
export const createContactProfileRequest = createAction(`${ENTITY}/CREATE_REQUEST`);
export const createContactProfileSuccess = createAction(`${ENTITY}/CREATE_SUCCESS`);
export const createContactProfileFailure = createAction(`${ENTITY}/CREATE_FAILURE`);

export const updateContactProfile = createAction(
    `${ENTITY}/UPDATE`,
    values => values,
    (values, callback) => ({ callback })
);
// export const createContact = createAction(`${ENTITY}/CREATE`);
export const updateContactProfileRequest = createAction(`${ENTITY}/UPDATE_REQUEST`);
export const updateContactProfileSuccess = createAction(`${ENTITY}/UPDATE_SUCCESS`);
export const updateContactProfileFailure = createAction(`${ENTITY}/UPDATE_FAILURE`);

export const deleteContactProfile = createAction(`${ENTITY}/DELETE`);
export const deleteContactProfileRequest = createAction(`${ENTITY}/DELTE_REQUEST`);
export const deleteContactProfileSuccess = createAction(`${ENTITY}/DELTE_SUCCESS`);
export const deleteContactProfileFailure = createAction(`${ENTITY}/DELETE_FAILURE`);

