import i18n from 'i18next'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import Select from 'react-select'
import { Card, CardBody, Col, Form, Input, Label, Row, UncontrolledTooltip } from 'reactstrap'
import FormActionButtons from '../../../../Components/Common/FormActionButtons'
import InputNumber from '../../../../Components/FormUI/InputNumber'
import InputText from '../../../../Components/FormUI/InputText'
import PhoneWithDropDown from '../../../../Components/FormUI/PhoneWithDropDown'
import { handleImageChange } from '../../helpers'
import ErrorModal from '../ErrorModal/ErrorModal'
import styles from './AgencyOfficeForm.module.css'
import { officeAgencyFieldDescription } from './officeAgencyFieldDescription'

const AgencyOfficeForm = ({ formik, isOffice, cancelButtonUrl }) => {
    const history = useHistory()

    const [watermarkImageUpload, setWatermarkImageUpload] = useState('')
    const description = officeAgencyFieldDescription()

    const watermarkPositionOptions = [
        { label: `${i18n.t('admin.watermarkPositionTopLeft')}`, value: 'top-left' },
        { label: `${i18n.t('admin.watermarkPositionTop')}`, value: 'top' },
        { label: `${i18n.t('admin.watermarkPositionTopRight')}`, value: 'top-right' },
        { label: `${i18n.t('admin.watermarkPositionCenter')}`, value: 'center' },
        { label: `${i18n.t('admin.watermarkPositionBottomLeft')}`, value: 'bottom-left' },
        { label: `${i18n.t('admin.watermarkPositionBottom')}`, value: 'bottom' },
        { label: `${i18n.t('admin.watermarkPositionBottomRight')}`, value: 'bottom-right' },
    ]

    return (
        <Col xxl={9}>
            <Card>
                <CardBody className="p-4">
                    <Form onSubmit={formik.handleSubmit}>
                        <Row>
                            <Col lg={isOffice ? 5 : 6}>
                                <div className="mb-3">
                                    <InputText
                                        label={isOffice ? i18n.t('admin.office.name') : i18n.t('app.common.company.name')}
                                        id={'name'}
                                        onChange={formik.handleChange}
                                        defaultValue={formik?.values?.name ?? ''}
                                        formikError={formik.errors}
                                        formikTouched={formik.touched}
                                        isRequired={true}
                                        permission={isOffice ? description.officeName : description.agencyName}
                                    />
                                </div>
                            </Col>
                            <Col lg={isOffice ? 5 : 6}>
                                <div className="mb-3">
                                    <InputText
                                        label={i18n.t('app.common.pin')}
                                        id={'taxNumber'}
                                        onChange={formik.handleChange}
                                        defaultValue={formik?.values?.taxNumber ?? ''}
                                        formikError={formik.errors}
                                        formikTouched={formik.touched}
                                        permission={isOffice ? description.taxNumberOffice : description.taxNumberAgency}
                                    />
                                </div>
                            </Col>
                            {isOffice && (
                                <Col lg={2}>
                                    <div className="mb-3">
                                        <InputNumber
                                            label={i18n.t('app.common.contact.priority')}
                                            id={'priority'}
                                            onChange={formik.handleChange}
                                            defaultValue={formik?.values?.priority ?? ''}
                                            formikError={formik.errors}
                                            formikTouched={formik.touched}
                                            permission={description.priority}
                                        />
                                    </div>
                                </Col>
                            )}
                            <Col lg={6}>
                                <div className="mb-3">
                                    <PhoneWithDropDown
                                        label={i18n.t('admin.phone')}
                                        id={'phoneNumber'}
                                        formik={formik}
                                        defaultValue={formik.values?.phoneNumber ?? ''}
                                        permission={description.phone}
                                    />
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="mb-3">
                                    <PhoneWithDropDown
                                        label={i18n.t('admin.mobilePhone')}
                                        id={'mobileNumber'}
                                        formik={formik}
                                        defaultValue={formik.values?.mobileNumber ?? ''}
                                        permission={description.mobileNumber}
                                    />
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="mb-3">
                                    <InputText
                                        label={i18n.t('app.common.email.label')}
                                        id={'email'}
                                        onChange={formik.handleChange}
                                        defaultValue={formik?.values?.email ?? ''}
                                        formikError={formik.errors}
                                        formikTouched={formik.touched}
                                        permission={isOffice ? description.emailOffice : description.emailAgency}
                                    />
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="mb-3">
                                    <InputText
                                        label={i18n.t('app.common.web')}
                                        id={'websiteUrl'}
                                        onChange={formik.handleChange}
                                        defaultValue={formik?.values?.websiteUrl ?? ''}
                                        formikError={formik.errors}
                                        formikTouched={formik.touched}
                                        permission={isOffice ? description.webOffice : description.webAgency}
                                    />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <InputText
                                        label={i18n.t('app.common.street')}
                                        id={'street'}
                                        onChange={formik.handleChange}
                                        defaultValue={formik?.values?.street ?? ''}
                                        formikError={formik.errors}
                                        formikTouched={formik.touched}
                                        permission={description.street}
                                    />
                                </div>
                            </Col>
                            <Col lg={2}>
                                <div className="mb-3">
                                    <InputText
                                        label={i18n.t('app.common.streetNumber')}
                                        id={'streetNumber'}
                                        onChange={formik.handleChange}
                                        defaultValue={formik?.values?.streetNumber ?? ''}
                                        formikError={formik.errors}
                                        formikTouched={formik.touched}
                                        permission={description.streetNumber}
                                    />
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="mb-3">
                                    <InputText
                                        label={i18n.t('app.common.city')}
                                        id={'city'}
                                        onChange={formik.handleChange}
                                        defaultValue={formik?.values?.city ?? ''}
                                        formikError={formik.errors}
                                        formikTouched={formik.touched}
                                        permission={description.city}
                                    />
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="mb-3">
                                    <InputText
                                        label={i18n.t('form.contact.general.zipCode')}
                                        id={'zipCode'}
                                        onChange={formik.handleChange}
                                        defaultValue={formik?.values?.zipCode ?? ''}
                                        formikError={formik.errors}
                                        formikTouched={formik.touched}
                                        permission={description.zipCode}
                                    />
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="mb-3">
                                    <InputText
                                        label={i18n.t('app.common.country')}
                                        id={'country'}
                                        onChange={formik.handleChange}
                                        defaultValue={formik?.values?.country ?? ''}
                                        formikError={formik.errors}
                                        formikTouched={formik.touched}
                                        permission={description.country}
                                    />
                                </div>
                            </Col>

                            <Col lg={12}>
                                <div className="mb-3 d-flex align-items-center">
                                    <Input
                                        type="checkbox"
                                        checked={isOffice ? formik?.values?.agencyOfficeSync : formik?.values?.agencySync || false}
                                        id={isOffice ? 'agencyOfficeSync' : 'agencySync'}
                                        onChange={formik.handleChange}
                                        className="form-control me-2 p-2"
                                    />
                                    <Label className="form-label m-0">
                                        {isOffice ? i18n.t('admin.agencyOfficeSync') : i18n.t('admin.agencySync')}
                                    </Label>
                                    <span className="ps-1">
                                        <span id={`agentPermissionsTooltipsyncName`}>
                                            <i className="mdi mdi-help-circle-outline"></i>{' '}
                                        </span>
                                        <UncontrolledTooltip pement="right" target={`agentPermissionsTooltipsyncName`}>
                                            {isOffice ? description.agencyOfficeSync.description : description.agencySync.description}
                                        </UncontrolledTooltip>
                                    </span>
                                </div>
                            </Col>
                            <Col lg={12}>
                                <div className="mb-3">
                                    <InputText
                                        type="textarea"
                                        rows={4}
                                        label={isOffice ? i18n.t('admin.officeInfo') : i18n.t('admin.agencyInfo')}
                                        id={'description'}
                                        onChange={formik.handleChange}
                                        defaultValue={formik?.values?.description ?? ''}
                                        formikError={formik.errors}
                                        formikTouched={formik.touched}
                                        permission={isOffice ? description.officeInfo : description.agencyInfo}
                                    />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label>{i18n.t('admin.WatermarkImage')}</Label>
                                    {(description.agencyWatermark || description.officeWatermark) && (
                                        <>
                                            {' '}
                                            <span id={`agentPermissionsTooltipWatermark`}>
                                                <i className="mdi mdi-help-circle-outline"></i>{' '}
                                            </span>
                                            <UncontrolledTooltip pement="right" target={`agentPermissionsTooltipWatermark`}>
                                                {isOffice ? description.officeWatermark.description : description.agencyWatermark.description}
                                            </UncontrolledTooltip>
                                        </>
                                    )}
                                    <div className={styles.watermarkContainer}>
                                        <div className={`${styles.imageWrapper} profile-photo-edit d-flex align-items-center justify-content-center`}>
                                            {(watermarkImageUpload || formik?.values?.watermark) === '' ||
                                            (watermarkImageUpload || formik?.values?.watermark) === '' ? (
                                                <p>{i18n.t('admin.WatermarkNoImage')}</p>
                                            ) : (
                                                <img
                                                    className={styles.imageFitCover}
                                                    alt="watermark"
                                                    src={watermarkImageUpload || formik?.values?.watermark}
                                                />
                                            )}
                                        </div>
                                        <div className={styles.uploadButton}>
                                            <Input
                                                onChange={(e) => handleImageChange(e, 'watermark', setWatermarkImageUpload, formik)}
                                                id="watermark-img-file-input"
                                                type="file"
                                                className={styles.inputImage}
                                            />
                                            <Label htmlFor="watermark-img-file-input" className={styles.iconUpload}>
                                                <span className="avatar-title rounded-circle bg-light text-body">
                                                    <i className="ri-camera-fill"></i>
                                                </span>
                                            </Label>
                                        </div>
                                        <div
                                            className={styles.closeButton}
                                            onClick={() => {
                                                formik.setFieldValue(`watermark`, '')
                                                setWatermarkImageUpload('')
                                            }}
                                        >
                                            <div className={styles.iconX}>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="#000">
                                                    <path d="M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label>{i18n.t('admin.watermarkPosition')}</Label>
                                    {description.watermarkPosition && (
                                        <>
                                            {' '}
                                            <span id={`agentPermissionsTooltipWatermarkPosition`}>
                                                <i className="mdi mdi-help-circle-outline"></i>{' '}
                                            </span>
                                            <UncontrolledTooltip pement="right" target={`agentPermissionsTooltipWatermarkPosition`}>
                                                {description.watermarkPosition.description}
                                            </UncontrolledTooltip>
                                        </>
                                    )}
                                    <Select
                                        id="watermarkPosition"
                                        onChange={(e) => {
                                            formik.setFieldValue('watermarkPosition', e.value)
                                        }}
                                        options={watermarkPositionOptions}
                                        placeholder={i18n.t('admin.watermarkPositionPlaceholder')}
                                        value={watermarkPositionOptions.filter((item) => item.value === formik?.values?.watermarkPosition)}
                                    />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3">
                                    <Label>
                                        {i18n.t('admin.watermarkTransparency')} ({formik?.values?.watermarkTransparency || 0})
                                    </Label>
                                    {description.watermarkTransperncy && (
                                        <>
                                            {' '}
                                            <span id={`agentPermissionsTooltipWatermarkTransperncy`}>
                                                <i className="mdi mdi-help-circle-outline"></i>{' '}
                                            </span>
                                            <UncontrolledTooltip pement="right" target={`agentPermissionsTooltipWatermarkTransperncy`}>
                                                {description.watermarkTransperncy.description}
                                            </UncontrolledTooltip>
                                        </>
                                    )}
                                    <Input
                                        onChange={(e) => formik.setFieldValue('watermarkTransparency', e.target.value)}
                                        id="watermarkTransparencyRange"
                                        name="watermarkTransparency"
                                        type="range"
                                        value={formik?.values?.watermarkTransparency || 0}
                                        className="my-3"
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Col lg={12}>
                            <div className="hstack gap-2 justify-content-end">
                                <FormActionButtons disabled={false} hasCancel={true} cancelAction={() => history.push(cancelButtonUrl)} />
                            </div>
                        </Col>
                    </Form>
                    <ErrorModal formik={formik} />
                </CardBody>
            </Card>
        </Col>
    )
}

export default AgencyOfficeForm
