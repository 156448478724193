import { combineActions, handleActions } from 'redux-actions'
import {
    clearNotes,
    createNoteFailure,
    createNoteRequest,
    createNoteSuccess,
    deleteNoteFailure,
    deleteNoteRequest,
    deleteNoteSuccess,
    fetchNotesFailure,
    fetchNotesRequest,
    fetchNotesSuccess,
    updateNoteFailure,
    updateNoteRequest,
    updateNoteSuccess,
} from './actions'

export const initialState = {
    items: [],
    item: {},
    fetching: false,
    fetchingDetails: false,
    submitting: false,
    error: null,
    errorDetails: null,
}

export default handleActions(
    {
        [fetchNotesRequest]: (state) => ({
            ...state,
            fetching: true,
            error: null,
        }),
        [fetchNotesSuccess]: (state, { payload }) => {
            return {
                ...state,
                items: payload.items,
                fetching: false,
            }
        },
        [fetchNotesFailure]: (state, { payload }) => ({
            ...state,
            error: payload,
            fetching: false,
        }),
        [createNoteSuccess]: (state, { payload }) => {
            const currentNotes = [...state.items]   
            const updatedNotes = [payload, ...currentNotes]

            return {
                ...state,
                items: updatedNotes,
                fetching: false,
            }
        },
        [updateNoteSuccess]: (state, { payload }) => {
            const currentNotes = [...state.items]   
            const updatedNotes = currentNotes.map(note => note.id === payload.id ? payload : note)

            return {
                ...state,
                items: updatedNotes,
                fetching: false,
            }
        },
        [combineActions(updateNoteRequest, createNoteRequest)]: (state) => ({
            ...state,
            submitting: true,
        }),
        [combineActions(updateNoteFailure, createNoteFailure)]: (state, { payload }) => ({
            ...state,
            submitting: false,
            error: payload?.response,
        }),
        [deleteNoteRequest]: (state) => {
            return {
                ...state,
                error: null,
                deleting: true,
            }
        },
        [deleteNoteSuccess]: (state, { payload }) => {
            const items = [...state.items]
            const updatedNotes = items.filter((item) => item.id !== payload)

            return {
                ...state,
                deleting: false,
                items: updatedNotes,
            }
        },
        [deleteNoteFailure]: (state, { payload }) => {
            return {
                ...state,
                deleting: false,
                error: payload.response,
            }
        },
        [clearNotes]: (state) => {
            return {
                ...state,
                items: [],
            }
        },
    },
    initialState
)
