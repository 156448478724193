import i18n from 'i18next'

export const userFieldDescription = () => {
    return {
        firstName: {
            id: 'firstName',
            description: i18n.t('admin.user.description.firstName'),
        },
        lastName: {
            id: 'lastName',
            description: i18n.t('admin.user.description.lastName'),
        },
        companyRole: {
            id: 'companyRole',
            description: i18n.t('admin.user.description.companyRole'),
        },
        photo: {
            id: 'photo',
            description: i18n.t('admin.user.description.photo'),
        },
        phone: {
            id: 'phone',
            description: i18n.t('admin.user.description.phone'),
        },
        mobileNumber: {
            id: 'mobileNumber',
            description: i18n.t('admin.user.description.mobileNumber'),
        },
        primaryEmail: {
            id: 'primaryEmail',
            description: i18n.t('admin.user.description.primaryEmail'),
        },
        ccEmail: {
            id: 'ccEmail',
            description: i18n.t('admin.user.description.ccEmail'),
        },
        displayEmail: {
            id: 'displayEmail',
            description: i18n.t('admin.user.description.displayEmail'),
        },
        defaultCountry: {
            id: 'defaultCountry',
            description: i18n.t('admin.user.description.defaultCountry'),
        },
        defaultCounty: {
            id: 'defaultCounty',
            description: i18n.t('admin.user.description.defaultCounty'),
        },
        defaultCity: {
            id: 'defaultCity',
            description: i18n.t('admin.user.description.defaultCity'),
        },
        applicationLanguage: {
            id: 'applicationLanguage',
            description: i18n.t('admin.user.description.applicationLanguage'),
        },
        agentHomepage: {
            id: 'agentHomepage',
            description: i18n.t('admin.user.description.agentHomepage'),
        },
        nameSync: {
            id: 'nameSync',
            description: i18n.t('admin.user.description.nameSync'),
        },
        phoneNumberSync: {
            id: 'phoneNumberSync',
            description: i18n.t('admin.user.description.phoneNumberSync'),
        },
        mobileNumberSync: {
            id: 'mobileNumberSync',
            description: i18n.t('admin.user.description.mobileNumberSync'),
        },
        primaryEmailSync: {
            id: 'primaryEmailSync',
            description: i18n.t('admin.user.description.primaryEmailSync'),
        },
        photoSync: {
            id: 'photoSync',
            description: i18n.t('admin.user.description.photoSync'),
        },
        agencySync: {
            id: 'agencySync',
            description: i18n.t('admin.user.description.agencySync'),
        },
        agencyOfficeSync: {
            id: 'agencyOfficeSync',
            description: i18n.t('admin.user.description.agencyOfficeSync'),
        },
        activeUser: {
            id: 'activeUser',
            description: i18n.t('admin.user.description.activeUser'),
        },
        agencyOffice: {
            id: 'agencyOffice',
            description: i18n.t('admin.user.description.agencyOffice'),
        },
    }
}
