import classNames from 'classnames'

const ObscurePassword = ({ errors = {}, obscurePassword, setObscurePassword }) => {
    const toggleObscurePassword = () => {
        setObscurePassword((value) => !value)
    }

    return (
        !errors.password && (
            <button
                className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                type="button"
                id="password-addon"
                onClick={toggleObscurePassword}
            >
                <i
                    className={classNames('mdi align-middle', {
                        'mdi-eye': obscurePassword,
                        'mdi-eye-off': !obscurePassword,
                    })}
                />
            </button>
        )
    )
}

export default ObscurePassword
