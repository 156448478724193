import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

const Navdata = () => {
    const history = useHistory()
    const { t } = useTranslation()

    //state data
    const [isDashboard, setIsDashboard] = useState(false)
    const [isApps, setIsApps] = useState(false)
    const [isAuth, setIsAuth] = useState(false)
    const [isPages, setIsPages] = useState(false)
    const [isBaseUi, setIsBaseUi] = useState(false)
    const [isAdvanceUi, setIsAdvanceUi] = useState(false)
    const [isForms, setIsForms] = useState(false)
    const [isTables, setIsTables] = useState(false)
    const [isCharts, setIsCharts] = useState(false)
    const [isIcons, setIsIcons] = useState(false)
    const [isMaps, setIsMaps] = useState(false)
    const [isMultiLevel, setIsMultiLevel] = useState(false)

    // State data
    const [isHomepage, setIsHomepage] = useState(false)
    const [isProjects, setIsProjects] = useState(false)
    const [isProperties, setIsProperties] = useState(false)
    const [isContacts, setIsContacts] = useState(false)
    const [isCompanies, setIsCompanies] = useState(false)
    const [isActivities, setIsActivities] = useState(false)
    const [isDocuments, setIsDocuments] = useState(false)
    const [isReports, setIsReports] = useState(false)
    const [isDiary, setIsDiary] = useState(false)
    const [isClientRecords, setIsClientRecords] = useState(false)
    const [isIntermediaryJournal, setIsIntermediaryJournal] = useState(false)


    const [iscurrentState, setIscurrentState] = useState('Dashboard')

    function updateIconSidebar(e) {
        if (e && e.target && e.target.getAttribute('subitems')) {
            const ul = document.getElementById('two-column-menu')
            const iconItems = ul.querySelectorAll('.nav-icon.active')
            let activeIconItems = [...iconItems]
            activeIconItems.forEach((item) => {
                item.classList.remove('active')
                var id = item.getAttribute('subitems')
                if (document.getElementById(id)) document.getElementById(id).classList.remove('show')
            })
        }
    }

    useEffect(() => {
        document.body.classList.remove('twocolumn-panel')
        if (iscurrentState !== 'Dashboard') {
            setIsDashboard(false)
        }
        if (iscurrentState !== 'Apps') {
            setIsApps(false)
        }
        if (iscurrentState !== 'Auth') {
            setIsAuth(false)
        }
        if (iscurrentState !== 'Pages') {
            setIsPages(false)
        }
        if (iscurrentState !== 'BaseUi') {
            setIsBaseUi(false)
        }
        if (iscurrentState !== 'AdvanceUi') {
            setIsAdvanceUi(false)
        }
        if (iscurrentState !== 'Forms') {
            setIsForms(false)
        }
        if (iscurrentState !== 'Tables') {
            setIsTables(false)
        }
        if (iscurrentState !== 'Charts') {
            setIsCharts(false)
        }
        if (iscurrentState !== 'Icons') {
            setIsIcons(false)
        }
        if (iscurrentState !== 'Maps') {
            setIsMaps(false)
        }
        if (iscurrentState !== 'MuliLevel') {
            setIsMultiLevel(false)
        }
        if (iscurrentState === 'Widgets') {
            history.push('/widgets')
            document.body.classList.add('twocolumn-panel')
        }
        if (iscurrentState !== 'Projects') {
            setIsProjects(false)
        }
        if (iscurrentState !== 'Contacts') {
            setIsContacts(false)
        }
        if (iscurrentState !== 'Companies') {
            setIsCompanies(false)
        }
        if (iscurrentState !== 'properties') {
            setIsProperties(false)
        }
        if (iscurrentState !== 'Homepage') {
            setIsHomepage(false)
        }
        if (iscurrentState !== 'reports') {
            setIsReports(false)
        }
        if (iscurrentState !== 'diray') {
            setIsDiary(false)
        }
    }, [
        history,
        iscurrentState,
        isDashboard,
        isHomepage,
        isProjects,
        isActivities,
        isProperties,
        isContacts,
        isCompanies,
        isIntermediaryJournal,
        isClientRecords,
        isDocuments,
        isReports,
        isApps,
        isAuth,
        isPages,
        isBaseUi,
        isAdvanceUi,
        isForms,
        isTables,
        isCharts,
        isIcons,
        isMaps,
        isMultiLevel,
    ])

    const menuItems = [
        {
            label: 'Menu',
            isHeader: true,
        },
        {
            id: 'projects',
            label: t('app.common.projects'),
            icon: 'mdi mdi-layers-outline',
            link: '/projects',
            historyState: { clearDetailSearch: true },
            stateVariables: isProjects,
            click: function (e) {
                e.preventDefault()
                setIsProjects(!isProjects)
                setIscurrentState('Projects')
                updateIconSidebar(e)
            },
        },
        {
            id: 'real-estates',
            label: t('app.common.properties'),
            icon: 'mdi mdi-domain',
            link: '/properties',
            historyState: { clearDetailSearch: true },
            stateVariables: isProperties,
            click: function (e) {
                e.preventDefault()
                setIsProperties(!isProperties)
                setIscurrentState('properties')
                updateIconSidebar(e)
            },
        },
        {
            id: 'contacts',
            label: t('app.common.contacts'),
            icon: 'mdi mdi-account-outline',
            link: '/contacts',
            historyState: { clearDetailSearch: true },
            stateVariables: isContacts,
            click: function (e) {
                e.preventDefault()
                setIsContacts(!isContacts)
                setIscurrentState('Contacts')
                updateIconSidebar(e)
            },
        },
        {
            id: 'companies',
            label: t('app.common.companies'),
            icon: 'mdi mdi-storefront-outline',
            link: '/companies',
            historyState: { clearDetailSearch: true },
            stateVariables: isCompanies,
            click: function (e) {
                e.preventDefault()
                setIsCompanies(!isCompanies)
                setIscurrentState('Companies')
                updateIconSidebar(e)
            },
        },
        {
            id: 'activities',
            label: t('app.common.activities'),
            icon: 'mdi mdi-calendar-month-outline',
            link: '/activities',
            stateVariables: isActivities,
            click: function (e) {
                e.preventDefault()
                setIsActivities(!isActivities)
                setIscurrentState('Activities')
                updateIconSidebar(e)
            },
        },
        {
            id: 'documents',
            label: t('app.common.documents'),
            icon: 'mdi mdi-file-document-outline',
            link: '/documents',
            stateVariables: isDocuments,
            click: function (e) {
                e.preventDefault()
                setIsDocuments(!isDocuments)
                setIscurrentState('Documents')
                updateIconSidebar(e)
            },
        },
        {
            id: 'reports',
            label: t('menu.reports'),
            icon: 'mdi mdi-chart-box-outline',
            link: '/reports',
            stateVariables: isReports,
            click: function (e) {
                e.preventDefault()
                setIsReports(!isReports)
                setIscurrentState('reports')
                updateIconSidebar(e)
            },
        },
        {
            id: 'diary',
            label: t('app.common.intermediaryDiary'),
            icon: 'mdi mdi-chart-box-outline',
            link: '/diary',
        },
    ]
    return <React.Fragment>{menuItems}</React.Fragment>
}
export default Navdata
