import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Button, Form, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { MODAL_TYPES } from '../../../common/constants'
import { baseCheckboxInput } from '../../../common/forms/fieldTypes'
import { hideModal } from '../../../store/modal'
import RecursiveForm from '../../RecursiveForm'
import { formFields } from './formFields'
import { useFetchNewPropertyId } from './query'

const ReturnToOffer = ({ isOpen, ...modalProps }) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const { RETURN_TO_OFFER_MODAL } = MODAL_TYPES
    const { id } = modalProps[RETURN_TO_OFFER_MODAL]

    const setModalInputs = () => {
        return formFields.map((field) => ({
            ...baseCheckboxInput,
            name: field.name,
            label: t(field.label),
            colProps: {
                xxl: 12,
            },
        }))
    }

    const setInitialValues = () => {
        return formFields.reduce((acc, curr) => {
            acc[curr.name] = curr.checked
            return acc
        }, {})
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: setInitialValues(),
        onSubmit: (values) => {
            const data = []

            Object.keys(values).forEach((key) => {
                if (values[key] === 1) {
                    data.push(key)
                }
            })

            mutate({ id, data })
        },
    })

    const handleClose = () => {
        const payload = {
            modalType: RETURN_TO_OFFER_MODAL,
        }

        dispatch(hideModal(payload))
    }

    const { mutate, isPending } = useFetchNewPropertyId(handleClose)

    return (
        <Modal isOpen={isOpen} centered>
            <ModalHeader toggle={handleClose}>
                <p className="m-0">{t('app.common.returnToOffer')}</p>
            </ModalHeader>
            <hr className="mx-3 mb-1" />
            <ModalBody>
                <p className="text-muted">{t('modal.returnToOffer.message')}</p>
                <Form onSubmit={formik.handleSubmit} className="py-2 d-flex flex-column">
                    <RecursiveForm inputs={setModalInputs()} formik={formik} />
                    <Button className="btn-label align-self-end" type="submit" color="success" disabled={isPending}>
                        {isPending ? (
                            <i className="mdi mdi-spin mdi-loading label-icon align-middle fs-16 me-2"></i>
                        ) : (
                            <i className="mdi mdi-check label-icon align-middle fs-16 me-2"></i>
                        )}

                        {t('button.save')}
                    </Button>
                </Form>
            </ModalBody>
        </Modal>
    )
}

export default ReturnToOffer
