import { MODAL_TYPES } from '../../common/constants'
import ChangeAgentModal from './ChangeAgentModal/ChangeAgentModal'
import OfferPrintModal from './OfferPrintModal'
import ReturnToOfferModal from './ReturnToOfferModal/ReturnToOfferModal'
import ShopWindowPrintModal from './ShopWindowPrint/ShopWindowPrintModal/ShopWindowPrintModal'
import TemplateModal from './ShopWindowPrint/TemplatesModal/TemplatesModal'
import ExportSyncModal from './ExportSyncModal'

const MODAL_COMPONENTS = {
    [MODAL_TYPES.OFFER_PRINT_MODAL]: OfferPrintModal,
    [MODAL_TYPES.TEMPLATES_MODAL]: TemplateModal,
    [MODAL_TYPES.SHOPWINDOW_PRINT_MODAL]: ShopWindowPrintModal,
    [MODAL_TYPES.CHANGE_AGENT_MODAL]: ChangeAgentModal,
    [MODAL_TYPES.RETURN_TO_OFFER_MODAL]: ReturnToOfferModal,
    [MODAL_TYPES.EXPORT_SYNC_MODAL]: ExportSyncModal,
}

const ModalRoot = ({ modalType, modalProps }) => {
    if (!modalType) {
        return null
    }
    const SpecificModal = MODAL_COMPONENTS[modalType]
    return <SpecificModal {...modalProps} />
}

export default ModalRoot
