import { createAction } from 'redux-actions';

const ENTITY = 'GROUNDPLANS';
export const stateKey = ENTITY.toLowerCase();

export const uploadGroundPlan = createAction(`${ENTITY}/UPLOAD`);
export const uploadGroundPlans = createAction(`${ENTITY}/UPLOAD/BATCH`, (payload) => payload);
export const uploadGroundPlanRequest = createAction(`${ENTITY}/UPLOAD_REQUEST`);
export const uploadGroundPlanSuccess = createAction(`${ENTITY}/UPLOAD_SUCCESS`);
export const uploadGroundPlanFailure = createAction(`${ENTITY}/UPLOAD_FAILURE`);

export const deleteGroundPlan = createAction(`${ENTITY}/DELETE`);
export const deleteGroundPlanRequest = createAction(`${ENTITY}/DELETE_REQUEST`);
export const deleteGroundPlanSuccess = createAction(`${ENTITY}/DELETE_SUCCESS`);
export const deleteGroundPlanFailure = createAction(`${ENTITY}/DELETE_FAILURE`);

export const queGroundPlan = createAction(`${ENTITY}/QUE`);
export const queGroundPlanRequest = createAction(`${ENTITY}/QUE_REQUEST`);
export const queGroundPlanSuccess = createAction(`${ENTITY}/QUE_SUCCESS`);
export const queGroundPlanFailure = createAction(`${ENTITY}/QUE_FAILURE`);

export const clearQueGroundPlan = createAction(`${ENTITY}/CLEAR_QUE`);
export const reorderGroundPlans = createAction(
    `${ENTITY}/REODER`,
    payload => payload,
    (_, callback) => callback
);


export const fetchGroundPlans = createAction(
    `${ENTITY}/FETCH`,
    payload => payload,
    (_, callback) => callback
);
export const fetchGroundPlansRequest = createAction(`${ENTITY}/FETCH_REQUEST`);
export const fetchGroundPlansSuccess = createAction(`${ENTITY}/FETCH_SUCCESS`);
export const fetchGroundPlansFailure = createAction(`${ENTITY}/FETCH_FAILURE`);


export const downloadGroundPlan = createAction(
    `${ENTITY}/DOWNLOAD`,
    payload => payload,
    (_, callback) => callback
);

export const downloadGroundPlanRequest = createAction(`${ENTITY}/DOWNLOAD_REQUEST`);
export const downloadGroundPlanSuccess = createAction(`${ENTITY}/DOWNLOAD_SUCCESS`);
export const downloadGroundPlanFailure = createAction(`${ENTITY}/DOWNLOAD_FAILURE`);

export const syncGroundPlan = createAction(
    `${ENTITY}/SYNC`,
    payload => payload,
    (_, callback) => callback
);

export const syncGroundPlanRequest = createAction(`${ENTITY}/SYNC_REQUEST`);
export const syncGroundPlanSuccess = createAction(`${ENTITY}/SYNC_SUCCESS`);
export const syncGroundPlanFailure = createAction(`${ENTITY}/SYNC_FAILURE`);

export const updateGroundPlan = createAction(
    `${ENTITY}/UPDATE_GROUNDPLAN`,
    payload => payload,
    (_, callback) => callback
);
export const updateGroundPlanRequest = createAction(`${ENTITY}/UPDATE_REQUEST`);
export const updateGroundPlanSuccess = createAction(`${ENTITY}/UPDATE_SUCCESS`);
export const updateGroundPlanFailure = createAction(`${ENTITY}/UPDATE_FAILURupdate`);

export const proxyGroundPlanEdit = createAction(
    `${ENTITY}/PROXY_GROUNDPLAN_EDIT`,
    (payload) => payload,
    (_, callback) => callback
)

export const proxyGroundPlanEditRequest = createAction(`${ENTITY}/PROXY_GROUNDPLAN_EDIT_REQUEST`);
export const proxyGroundPlanEditSuccess = createAction(`${ENTITY}/PROXY_GROUNDPLAN_EDIT_SUCCESS`);
export const proxyGroundPlanEditFailure = createAction(`${ENTITY}/PROXY_GROUNDPLAN_EDIT_FAILURE`);
export const proxyGroundPlanEditClear = createAction(`${ENTITY}/PROXY_GROUNDPLAN_EDIT_CLEAR`);
export const proxyGroundPlanUpdateList = createAction(`${ENTITY}/PROXY_GROUNDPLAN_UPDATE_LIST`);


export const updateGroundPlansCount = createAction(`${ENTITY}/UPDATE_GROUNDPLANS_COUNT`)
export const updateGroundPlansPriority = createAction(`${ENTITY}/UPDATE_PRIORITY`)
export const clearGroundPlans = createAction(`${ENTITY}/CLEAR_GROUNDPLANS`)
