import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

export const useOfferPrintModalTabs = () => {
    const [tabs, setTabs] = useState([])
    const [activeTab, setActiveTab] = useState(tabs?.[0] ?? 'offer')
    const { t } = useTranslation()

    const handleTabChange = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab)
        }
    }

    useEffect(() => {
        setTabs([
            { id: 1, name: 'offer', label: t('app.common.offer.title') },
            { id: 2, name: 'print', label: t('app.common.print.title') },
        ])
    }, [t])
    return {
        tabs,
        activeTab,
        handleTabChange,
    }
}
