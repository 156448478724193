import { useTranslation } from 'react-i18next'

// toolbar
const buttonTranslations = {
    '.tie-btn-crop': 'Izreži',
    '.tie-btn-resize': 'Veličina',
    '.tie-btn-rotate': 'Rotiraj',
    '.tie-btn-draw': 'Crtaj',
    '.tie-btn-shape': 'Oblik',
    '.tie-btn-icon': 'Ikona',
    '.tie-btn-text': 'Tekst',
}

// draw translation
const drawTranslations = {
    '.free': 'Slobodno',
    '.line': 'Linija',
    '.tie-draw-color': 'Boja',
}

// shape translations
const shapeTranslations = {
    '.tui-image-editor-button.rect': 'Kvadrat',
    '.tui-image-editor-button.circle': 'Krug',
    '.tui-image-editor-button.triangle': 'Trokut',
    '.tie-color-fill': 'Boja ispune',
    '.tie-color-stroke': 'Boja ruba',
}
const rangeLabelShape = 'Debljina ruba'

// icons translations
const iconTranslations = {
    'icon-arrow': 'Strelica-1',
    'icon-arrow-2': 'Strelica-2',
    'icon-arrow-3': 'Strelica-3',
    'icon-star': 'Zvijezda-1',
    'icon-star-2': 'Zvijezda-2',
    'icon-polygon': 'Mnogokut',
    'icon-location': 'Lokacija',
    'icon-heart': 'Srce',
    'icon-bubble': 'Oblak',
}
const customIconLabel = 'Dodaj ikonu'

// filter translations
const filterTranslations = {
    '.tie-grayscale': 'Sivo',
    '.tie-invert': 'Obrnuto',
    '.tie-sepia': 'Blijedo',
    '.tie-vintage': 'Staro',
    '.tie-blur': 'Mutno',
    '.tie-sharpen': 'Oštro',
    '.tie-emboss': 'Reljef',
    '.tie-remove-white': 'Ukloni bijelo',
    '.tie-brightness': 'Svjetlina',
    '.tie-noise': 'Šum',
    '.tie-pixelate': 'Pikseliziraj',
    '.tie-color-filter': 'Filter boje',
}

const filterTranslations2 = {
    '.tie-filter-tint-color': 'Nijansa',
    '.tie-filter-multiply-color': 'Tamnije',
    '.tie-filter-blend-color': 'Miješano',
}
const rangeLabelDistance = 'Udaljenost'
const rangeLabelTreshold = 'Nivo'

// action buttons
const actionTranslations = {
    '.apply': 'Prihvati',
    '.cancel': 'Odbaci',
}

// resize mode translations
const resizeLabelsTranslations = { 0: 'Širina', 2: 'Visina' }
const ratioLabel = 'Zaključaj omjer'

// text translations
const textAlignTranslations = { 0: 'Lijevo', 1: 'Centar', 2: 'Desno' }
const rangeLabelText = 'Veličina teksta'

// rotate and draw range translation
const rangleLabelRD = 'Raspon'

// color picker
const colorPickerLabel = 'Boja'

// custom crop button
const customCropLabel = 'Slobodno'

// custom tooltip
const customTooltip = 'Dvostruki klik za promjenu vrijednosti'

// undo button tooltip
const undoTooltip = 'Poništi'

export {
    actionTranslations,
    buttonTranslations,
    colorPickerLabel,
    customCropLabel,
    customIconLabel,
    customTooltip,
    drawTranslations,
    filterTranslations,
    filterTranslations2,
    iconTranslations,
    rangeLabelDistance,
    rangeLabelShape,
    rangeLabelText,
    rangeLabelTreshold,
    rangleLabelRD,
    ratioLabel,
    resizeLabelsTranslations,
    shapeTranslations,
    textAlignTranslations,
    undoTooltip,
}
