import i18next from 'i18next'
import { Button, Spinner } from 'reactstrap'

export const SaveButton = ({ loading, ...props }) => {
    const { t } = i18next

    return (
        <Button type="submit" color="success" className={`${loading ? 'btn-load' : 'btn-label'}`} {...props}>
            {loading ? (
                <span className="d-flex align-items-center">
                    <Spinner size="sm" className="flex-shrink-0">
                        {t('spinner.loading')}
                    </Spinner>
                    <span className="flex-grow-1 ms-2">{t('spinner.saving')}</span>
                </span>
            ) : (
                <>
                    <i className="mdi mdi-check label-icon align-middle fs-16 me-2"></i>
                    {t('button.save')}
                </>
            )}
        </Button>
    )
}

export const DeleteButton = (props) => {
    const { t } = i18next

    return (
        <Button type="button" color="danger" className="btn-label" {...props}>
            <i className="mdi mdi-trash-can-outline label-icon align-middle fs-16"></i>
            {t('button.delete')}
        </Button>
    )
}

export const EditButton = (props) => {
    const { t } = i18next

    return (
        <Button type="submit" color="edit" className="btn-label" {...props}>
            <i className="mdi mdi-pencil-outline label-icon align-middle fs-16 me-2"></i> {t('button.edit')}
        </Button>
    )
}

export const SearchButton = (props) => {
    const { t } = i18next

    return (
        <Button type="submit" color="send" className="btn-label" {...props}>
            <i className="mdi mdi-magnify label-icon align-middle fs-16 me-2"></i> {t('button.search')}
        </Button>
    )
}
