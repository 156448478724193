import flagenglish from '../../../assets/images/flags/gb.svg'
import flaggermany from '../../../assets/images/flags/germany.svg'
import flagcroatian from '../../../assets/images/flags/hr.svg'
import flagslovenian from '../../../assets/images/flags/sl.svg'

// add more flags if needed
const flags = {
    hr: {
        flag: flagcroatian,
    },
    en: {
        flag: flagenglish,
    },
    de: {
        flag: flaggermany,
    },
    sl: {
        flag: flagslovenian,
    },
}

export default flags
