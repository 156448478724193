import _ from 'lodash'
import { useState } from 'react'
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from 'reactstrap'
import RecursiveForm from '../RecursiveForm'

export const AccordionInput = ({ subform, formik, inputs, selectOptions }) => {
    const [isAccordionOpen, setAccordionOpen] = useState('')

    const toggleAccordion = (id) => {
        if (isAccordionOpen === id) {
            setAccordionOpen('')
        } else {
            setAccordionOpen(id)
        }
    }

    return inputs.children?.map((item) => {
        const itemNoLabel = { ...item }

        delete itemNoLabel.label
        delete itemNoLabel.labelProps

        return (
            <Accordion id="default-accordion-example" key={item.name} open={isAccordionOpen} toggle={toggleAccordion}>
                <AccordionItem key={item.name} className="border-0">
                    <AccordionHeader targetId={item.name} className="accordion-input-header">
                        {item.label}
                        {item.required && <span className="required-mark">*</span>}
                    </AccordionHeader>
                    <AccordionBody accordionId={item.name} className="border-0">
                        <RecursiveForm subform={subform} inputs={itemNoLabel} formik={formik} selectOptions={selectOptions} />
                    </AccordionBody>
                </AccordionItem>
            </Accordion>
        )
    })
}
