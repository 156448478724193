import { handleActions } from 'redux-actions'
import { setCount } from './actions'

export const initialState = {
    count: 0
}

export default handleActions(
    {
        [setCount]: (state, { payload }) => {
            return {
                ...state,
                count: payload,
            }
        },
    },
    initialState
)
