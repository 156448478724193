import QueryString from 'qs'
import { toast } from 'react-toastify'
import { put, select, takeLatest } from 'redux-saga/effects'
import { apiRequest } from '../api'
import { updateProjectsAgent } from '../projects'
import { updatePropertiesAgent } from '../properties'
import { updateContactsAgent } from '../contacts'
import { selectCheckedTableRows } from '../table'
import i18n from '../../i18n'
import {
    fetchAgents,
    fetchAgentsFailure,
    fetchAgentsRequest,
    fetchAgentsSuccess,
    updateAgent,
    updatePageData,
    fetchUser,
    fetchUserRequest,
    fetchUserSuccess,
    fetchUserFailure,
} from './actions'
import { selectAgentById } from './selectors'

export function* fetchAgentsSaga({ payload, meta }) {
    yield put(fetchAgentsRequest())

    try {
        const response = yield apiRequest(`v1/core/users`, {
            method: 'GET',
            params: payload,
            paramsSerializer: (params) => {
                return QueryString.stringify(params)
            },
        })

        yield put(fetchAgentsSuccess(response.data))
        meta(response.data.count)
    } catch (error) {
        yield put(fetchAgentsFailure(error))
    }
}

export function* updateAgentSaga({ payload, meta }) {
    const { agentId, data, urlBase, setIsLoading } = payload

    setIsLoading(true)

    const progressToast = toast.loading(i18n.t('toast.loading.agent'))

    try {
        const response = yield apiRequest(`v1/${urlBase}/agent/${agentId}`, {
            method: 'PUT',
            data: data,
        })

        if (response.status === 200) {
            const agentId = response.data.id
            const agent = yield select((state) => selectAgentById(state, agentId))
            const selectedIds = yield select((state) => selectCheckedTableRows(state).map((row) => row.id))

            yield put(updatePageData({ url: urlBase, agent, selectedIds }))

            toast.update(progressToast, { render: i18n.t('toast.loading.agent.success'), type: 'success', isLoading: false, autoClose: 3000 })
        }

        meta.reset()
    } catch (error) {
        toast.update(progressToast, { render: i18n.t('toast.loading.agent.error'), type: 'error', isLoading: false, autoClose: 3000 })

        meta.reset()
    }
}

export function* updatePageDataSaga({ payload }) {
    const { url, agent, selectedIds } = payload

    switch (url) {
        case 'project':
            yield put(updateProjectsAgent({ agent, selectedIds }))
            break
        case 'property':
            yield put(updatePropertiesAgent({ agent, selectedIds }))
            break
        case 'contact':
            yield put(updateContactsAgent({ agent, selectedIds }))
            break
        default:
            throw new Error(`There is no page with this ${url}`)
    }
}

export function* fetchUserSaga({ payload }) {
    yield put(fetchUserRequest())

    try {
        const response = yield apiRequest(`v1/core/users/${payload}`, {
            method: 'GET',
            params: payload,
            paramsSerializer: (params) => {
                return QueryString.stringify(params)
            },
        })

        yield put(fetchUserSuccess(response.data))
    } catch (error) {
        yield put(fetchUserFailure(error))
    }
}

export default function* actionWatcher() {
    yield takeLatest(`${fetchAgents}`, fetchAgentsSaga)
    yield takeLatest(`${updateAgent}`, updateAgentSaga)
    yield takeLatest(`${updatePageData}`, updatePageDataSaga)
    yield takeLatest(`${fetchUser}`, fetchUserSaga)
}
