import values from 'lodash/values'

import { stateKey } from './actions'

export const selectPropertyValuesStatusFetching = (state) => state[stateKey].fetching

export const selectPropertyValuesPositionFormOptions = (state) => {
    const data = values(state[stateKey].position)
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => ({
        value: service.id.toString(),
        label: service.name,
    }))
}

export const selectPropertyValuesRoomsTypeFormOptions = (state) => {
    const data = values(state[stateKey].roomstype)
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => ({
        value: service.id.toString(),
        label: service.name,
    }))
}

export const selectPropertyValuesHouseTypeFormOptions = (state) => {
    const data = values(state[stateKey].housetype)
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => ({
        value: service.id.toString(),
        label: service.name,
    }))
}

export const selectPropertyValuesRoomsNoFormOptions = (state) => {
    const data = values(state[stateKey].roomsno)
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => ({
        value: service.id.toString(),
        label: service.name,
    }))
}

export const selectPropertyValuesFloorFormOptions = (state) => {
    const data = values(state[stateKey].floor)
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => ({
        value: service.id.toString(),
        label: service.name,
    }))
}

export const selectPropertyValuesFloorPositionFormOptions = (state) => {
    const data = values(state[stateKey].floorposition)
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => ({
        value: service.id.toString(),
        label: service.name,
    }))
}

export const selectPropertyValuesOrientationFormOptions = (state) => {
    const data = values(state[stateKey].orientation)
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => ({
        value: service.id.toString(),
        label: service.name,
    }))
}

export const selectPropertyValuesHeatingFormOptions = (state) => {
    const data = values(state[stateKey].heating)
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => ({
        value: service.id.toString(),
        label: service.name,
    }))
}

export const selectPropertyValuesConditionFormOptions = (state) => {
    const data = values(state[stateKey].condition)
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => ({
        value: service.id.toString(),
        label: service.name,
    }))
}

export const selectPropertyValuesWindowsDoorsFormOptions = (state) => {
    const data = values(state[stateKey].windowsdoors)
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => ({
        value: service.id.toString(),
        label: service.name,
    }))
}

export const selectPropertyValuesPublicTransportationFormOptions = (state) => {
    const data = values(state[stateKey].publictransportation)
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => ({
        value: service.id.toString(),
        label: service.name,
    }))
}

export const selectPropertyValuesSpecialFeatureFormOptions = (state) => {
    const data = values(state[stateKey].specialfeature)
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => ({
        value: service.id.toString(),
        label: service.name,
    }))
}

export const selectPropertyValuesUtilityFormOptions = (state) => {
    const data = values(state[stateKey].utility)
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => ({
        value: service.id.toString(),
        label: service.name,
    }))
}

export const selectPropertyValuesEquipmentFormOptions = (state) => {
    const data = values(state[stateKey].equipment)
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => ({
        value: service.id.toString(),
        label: service.name,
    }))
}

export const selectPropertyValuesSpaceFormOptions = (state) => {
    const data = values(state[stateKey].space)
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => ({
        value: service.id.toString(),
        label: service.name,
    }))
}

export const selectPropertyValuesEnergyEfficiencyFormOptions = (state) => {
    const data = values(state[stateKey].energyefficiency)
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => ({
        value: service.id.toString(),
        label: service.name,
    }))
}

export const selectPropertyValuesAccessFormOptions = (state) => {
    const data = values(state[stateKey].access)
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => ({
        value: service.id.toString(),
        label: service.name,
    }))
}

export const selectPropertyValuesPermitFormOptions = (state) => {
    const data = values(state[stateKey].permit)
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => ({
        value: service.id.toString(),
        label: service.name,
    }))
}

export const selectPropertyValuesAgencyCommissionFormOptions = (state) => {
    const data = values(state[stateKey].agencycommission)
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => ({
        value: service.id.toString(),
        label: service.name,
    }))
}

export const selectPropertyValuesStatusFormOptions = (state) => {
    const data = values(state[stateKey].status)
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => ({
        value: service?.id?.toString(),
        label: service.name,
    }))
}

export const selectPropertyValuesPropertyTransactionRiskDegreeFormOptions = (state) => {
    const data = values(state[stateKey].propertytransactionriskdegree)
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => ({
        value: service.id.toString(),
        label: service.name,
    }))
}

export const selectPropertyValuesMainRegisterFormOptions = (state) => {
    const data = values(state[stateKey].mainregister)
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => ({
        value: service.id.toString(),
        label: service.name,
    }))
}

export const selectPropertyValuesDescriptionsrFormOptions = (state) => {
    const data = values(state[stateKey].descriptions)
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => ({
        value: service.id.toString(),
        label: service.name,
    }))
}

export const selectPropertyValuesMainRegisterDepartmentFormOptions = (state) => {
    const data = values(state[stateKey].mainregisterdepartment)
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => ({
        value: service.id.toString(),
        label: service.name,
    }))
}

export const selectPropertyValuesPukFormOptions = (state) => {
    const data = values(state[stateKey].puk)
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => ({
        value: service.id.toString(),
        label: service.name,
    }))
}

export const selectPropertyValuesPukDepartmentFormOptions = (state) => {
    const data = values(state[stateKey].pukdepartment)
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => ({
        value: service.id.toString(),
        label: service.name,
    }))
}

export const selectPropertyValuesRegistryDepartmentKpuFormOptions = (state) => {
    const data = values(state[stateKey].registrydepartmentkpu)
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => ({
        value: service.id.toString(),
        label: service.name,
    }))
}

export const selectPropertyValuesRegistryDepartmentKpuSubinsertFormOptions = (state) => {
    const data = values(state[stateKey].registrydepartmentkpusubinsert)
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => ({
        value: service.id.toString(),
        label: service.name,
    }))
}

export const selectPropertyValuesDataSource = (state) => values(state[stateKey].datasource)

export const selectPropertyValuesDataSourceFormOptions = (state) => {
    const data = values(state[stateKey].datasource)
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => ({
        value: service.id.toString(),
        label: service.name,
    }))
}

export const selectPropertyValuesLandRegistryStatusFormOptions = (state) => {
    const data = values(state[stateKey].landregistrystatus)
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => ({
        value: service.id.toString(),
        label: service.name,
    }))
}

export const selectPropertyValuesOwnershipAcquisitionFormOptions = (state) => {
    const data = values(state[stateKey].ownershipacquistion)
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => ({
        value: service.id.toString(),
        label: service.name,
    }))
}

export const selectPropertyValuesOwnershipAcquisitionProofFormOptions = (state) => {
    const data = values(state[stateKey].ownershipacquistionproof)
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => ({
        value: service.id.toString(),
        label: service.name,
    }))
}

export const selectPropertyValuesPrimaryUsageFormOptions = (state) => {
    const data = values(state[stateKey].primaryusage)
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => ({
        value: service.id.toString(),
        label: service.name,
    }))
}

export const selectPropertyValuesLandUsageFormOptions = (state) => {
    const data = values(state[stateKey].landusage)
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => ({
        value: service.id.toString(),
        label: service.name,
    }))
}

export const selectPropertyValuesMapLocationSyncTypeFormOptions = (state) => {
    return [
        {
            value: '1',
            label: 'Prikaži točnu lokaciju',
        },
        {
            value: '2',
            label: 'Prikaži kao približnu lokaciju',
        },
    ]
}

export const selectPropertyValuesTransactionCommissionTypeFormOptions = (state) => {
    return [
        {
            value: '€',
            label: '€',
        },
        {
            value: '%',
            label: '%',
        },
    ]
}

export const selectPropertyValuesSupplySourceFormOptions = (state) => {
    const data = values(state[stateKey].supplysource)
    // console.log('data', data)
    // console.log('state', state)
    return data.map((service) => ({
        value: service.id.toString(),
        label: service.name,
    }))
}

export const selectPropertyValuesCadastralMunicipalityFormOptions = (state) => {
    const data = values(state[stateKey].cadastralMunicipality)

    return data.map((item) => ({
        value: `${item.id} - ${item.name}`,
        label: `${item.id} - ${item.name}`,
    }))
}
