import React, { useMemo } from 'react'
import TableContainer from '../../Components/Table/TableContainer'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAgentInquiries, selectOmittedActivitiesReports, selectReportsFetching } from '../../store/reports'
import { totalReducer } from '../../utils/general'
import { dataFallback } from './constants'
import { getAgentInquirySourceTotals } from '../../utils/report'

export const AgentInquiriesReport = ({ searchCriteria, selectSources, sourceKey }) => {
    const dispatch = useDispatch()

    const { reports, reportsFetching, sources } = useSelector((state) => ({
        reports: selectOmittedActivitiesReports(state),
        reportsFetching: selectReportsFetching(state),
        sources: selectSources(state),
    }))

    const columns = useMemo(() => {
        if (sources?.length > 0) {
            const sourceColumns = sources.map(({ id, name }) => ({
                Header: name,
                id: `${id}`,
                accessor: ({ [sourceKey]: source }) => source[id] ?? dataFallback,
                filterable: false,
            }))

            return [
                {
                    Header: 'Agent',
                    id: 'agent',
                    accessor: ({ agent }) => `${agent.firstName} ${agent.lastName}`,
                    filterable: false,
                },
                ...sourceColumns,
                {
                    Header: 'Ukupno',
                    id: 'total',
                    accessor: ({ [sourceKey]: source }) => {
                        const sourceData = Object.values(source)
                        const total = sourceData.reduce(totalReducer, 0)

                        return <strong>{total}</strong>
                    },
                    filterable: false,
                },
            ]
        }

        return []
    }, [sources, sourceKey])

    const handleDataFetch = (page, limit, order, criteria) => {
        dispatch(fetchAgentInquiries({ page, limit, order, criteria }))
    }

    const sourceTotals = useMemo(() => getAgentInquirySourceTotals(sources, reports, sourceKey), [sources, reports, sourceKey])

    const tableFooter = useMemo(() => {
        if (!sourceTotals) return null

        const total = sourceTotals.reduce(totalReducer, 0)

        return (
            <tr>
                <td>Ukupno</td>
                {sourceTotals.map((value, i) => (
                    <td key={i}>{value}</td>
                ))}
                <td>{total}</td>
            </tr>
        )
    }, [sourceTotals])

    return (
        <TableContainer
            columns={columns}
            data={reports || []}
            className="custom-header-css"
            theadClass={'dmn-table-header'}
            isLoading={reportsFetching}
            handleDataFetch={handleDataFetch}
            searchCriteria={searchCriteria}
            tableFooter={tableFooter}
            hidePagination
        />
    )
}
