import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import { useCurrentUser } from '../../hooks/useCurrentUser'
import { authenticateDestroy } from '../../store/auth'

const ProfileDropdown = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const { user } = useCurrentUser()

    const [userName, setUserName] = useState('Agent')

    useEffect(() => {
        if (user && user.firstName && user.lastName) {
            setUserName(`${user.firstName} ${user.lastName}` || 'Agent')
        }
    }, [userName, user])

    //Dropdown Toggle
    const [isProfileDropdown, setIsProfileDropdown] = useState(false)
    const toggleProfileDropdown = () => {
        setIsProfileDropdown(!isProfileDropdown)
    }

    const handleLogOut = (e) => {
        e.preventDefault()
        dispatch(authenticateDestroy())
    }

    const userThumbnail = <img className="rounded-circle header-profile-user" src={user.photo} alt="User thumbnail" />

    const userThumbnailPlaceholder = (
        <div className="flex-shrink-0 avatar-xs acitivity-avatar">
            <div className="avatar-title bg-light text-primary rounded-circle">
                {' '}
                {user && user.firstName && user.lastName
                    ? `${user.firstName?.charAt(0)?.toUpperCase()}${user.lastName?.charAt(0)?.toUpperCase()}`
                    : 'A'}{' '}
            </div>
        </div>
    )

    return (
        <React.Fragment>
            <Dropdown isOpen={isProfileDropdown} toggle={toggleProfileDropdown} className="ms-sm-3 header-item topbar-user">
                <DropdownToggle tag="button" type="button" className="btn">
                    <span className="d-flex align-items-center">
                        {user.photo ? userThumbnail : userThumbnailPlaceholder}
                        <span className="text-start ms-xl-2">
                            <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">{userName}</span>
                        </span>
                    </span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                    {user?.roles?.includes('ROLE_ADMIN') &&
                        (history?.location?.pathname?.split('/')[1] === 'admin' ? (
                            <DropdownItem to={'/'} tag={Link}>
                                <i className="mdi mdi-keyboard-backspace text-muted fs-16 align-middle me-1"></i>{' '}
                                <span className="align-middle">{t('app.user.backToSoftwere')}</span>
                            </DropdownItem>
                        ) : (
                            <DropdownItem to={'/admin/agency'} tag={Link}>
                                <i className="mdi mdi-head-cog text-muted fs-16 align-middle me-1"></i>{' '}
                                <span className="align-middle">{t('app.user.administration')}</span>
                            </DropdownItem>
                        ))}
                    <DropdownItem to={''} tag={Link} onClick={(e) => handleLogOut(e)}>
                        <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>{' '}
                        <span className="align-middle" data-key="t-logout">
                            {t('app.user.logout')}
                        </span>
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    )
}

export default ProfileDropdown
