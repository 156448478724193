import { useFormik } from 'formik'
import i18n from 'i18next'
import parsePhoneNumber from 'libphonenumber-js'
import { isEqual } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import Select from 'react-select'
import { Card, CardBody, CardHeader, Col, Form, Input, Label, Row, TabContent, TabPane, UncontrolledTooltip } from 'reactstrap'
import * as Yup from 'yup'
import BreadCrumb from '../../../../Components/Common/BreadCrumb'
import FormActionButtons from '../../../../Components/Common/FormActionButtons'
import FormTabNavigation from '../../../../Components/Common/FormTabNavigation'
import InputText from '../../../../Components/FormUI/InputText'
import PhoneWithDropDown from '../../../../Components/FormUI/PhoneWithDropDown'
import useApplicationSettings from '../../../../Components/Hooks/useApplicationSettings'
import {
    fetchAgencyOffices,
    fetchAgencyPermissions,
    fetchUser,
    selectAgencyOffices,
    selectAgencyPermissions,
    selectUser,
    selectUserFetching,
    updateUser,
} from '../../../../store/administration'
import { selectApplicationPackage, selectAvailableLanguages } from '../../../../store/applicationSettings'
import {
    fetchLocationsCity,
    fetchLocationsCounty,
    selectLocationsCityFormOptions,
    selectLocationsCountryFormOptions,
    selectLocationsCountyFormOptions,
} from '../../../../store/locations'
import AdminLoader from '../../Components/AdminLoader/AdminLoader'
import AgentPermissions from '../../Components/AgentPermissions/AgentPermissions'
import ErrorModal from '../../Components/ErrorModal/ErrorModal'
import UploadPhoto from '../../Components/UploadPhoto/UploadPhoto'
import { createSelectOptionData } from '../../helpers'
import { userFieldDescription } from '../userFieldDescription'
import { userValidation } from '../validateRules'

const UserEdit = () => {
    const { id } = useParams()
    const dispatch = useDispatch()
    const history = useHistory()
    const tabs = [
        { id: 1, name: 'agentInfo', label: i18n.t('admin.agentInfo') },
        { id: 2, name: 'permissions', label: i18n.t('admin.permissions') },
    ]

    // TODO: uncomment after sagamiddleware refactor, remove useSelector
    // const { applicationPackage, availableLanguages } = useApplicationSettings()

    const description = userFieldDescription()

    const [activeTab, setActiveTab] = useState(tabs[0].name)

    const tabChange = (tab) => {
        if (activeTab !== tab) setActiveTab(tab)
    }

    const { user, fetchingUser, country, county, city, agencyOffices, agencyPermissions, applicationPackage, availableLanguages } = useSelector(
        (state) => ({
            user: selectUser(state),
            fetchingUser: selectUserFetching(state),
            country: selectLocationsCountryFormOptions(state),
            county: selectLocationsCountyFormOptions(state),
            city: selectLocationsCityFormOptions(state),
            agencyOffices: selectAgencyOffices(state),
            agencyPermissions: selectAgencyPermissions(state),
            applicationPackage: selectApplicationPackage(state),
            availableLanguages: selectAvailableLanguages(state),
        })
    )

    useEffect(() => {
        dispatch(fetchUser(id))
    }, [dispatch, id])

    useEffect(() => {
        dispatch(fetchAgencyOffices())
    }, [dispatch])

    useEffect(() => {
        dispatch(fetchAgencyPermissions())
    }, [dispatch])

    useEffect(() => {
        user?.defaultCountry && dispatch(fetchLocationsCounty(user?.defaultCountry?.id))
        user?.defaultCounty && dispatch(fetchLocationsCity(user?.defaultCounty?.id))
    }, [dispatch, user?.defaultCountry, user?.defaultCounty])

    // some permissions form fields might be added or removed from agencyPermissions on the backend
    // if so, update user.agentPermissions with new/missing permissions from /agent-permissions endpoint
    const updatedAgentPermissions = useMemo(() => {
        const isNewPermission = (id) => {
            return !user.agentPermissions?.some((item) => item.permission?.id === id)
        }

        return agencyPermissions?.map((permission) => {
            let newPermission = {
                permission: { ...permission },
                permissionLevel: {},
            }

            user.agentPermissions?.forEach((item) => {
                if (item.permission.id === permission.id) {
                    newPermission.permissionLevel = { ...item.permissionLevel }
                } else if (isNewPermission(permission.id)) {
                    return newPermission
                }
            })

            return newPermission
        })
    }, [user.agentPermissions, agencyPermissions])

    const formattedUser = useMemo(() => {
        return {
            ...user,
            agentPermissions: updatedAgentPermissions,
        }
    }, [user, updatedAgentPermissions])

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: !fetchingUser && formattedUser,
        validationSchema: Yup.object(userValidation()),
        onSubmit: (values) => {
            const agentPermissionsArray = values.agentPermissions.map((item) => {
                return {
                    agentPermission_id: item.permission.id,
                    agentPermissionLevel_id: item?.permissionLevel?.id,
                }
            })

            const phoneNumber = parsePhoneNumber(`+${values?.phoneNumber}`)?.formatInternational()
            const mobileNumber = parsePhoneNumber(`+${values?.mobileNumber}`)?.formatInternational()

            const user = {
                ...values,
                agency_office_id: values?.agencyOffice?.id,
                defaultCounty_id: values?.defaultCounty?.id,
                defaultCountry_id: values?.defaultCountry?.id,
                defaultCity_id: values?.defaultCity?.id,
                applicationLanguage_id: values?.applicationLanguage?.id,
                agentPermissions: [...agentPermissionsArray],
                mobileNumber: mobileNumber,
                phoneNumber: phoneNumber,
                permissionsChanged: handlePermissionsChange(agentPermissionsArray),
            }

            delete user.agency
            delete user.agencyOffice
            delete user.defaultCounty
            delete user.defaultCountry
            delete user.defaultCity
            delete user.applicationLanguage

            dispatch(updateUser(user))
        },
    })

    const handleDropDownChange = (e, propertyName) => {
        const property = {
            id: parseInt(e.value),
            name: e.label,
        }
        formik?.setFieldValue(propertyName, property)
    }

    const handlePermissionsChange = (newPermissions) => {
        const currentPermissions = user.agentPermissions.map((item) => ({
            agentPermission_id: item?.permission.id,
            agentPermissionLevel_id: item?.permissionLevel.id,
        }))

        return !isEqual(newPermissions, currentPermissions)
    }

    return fetchingUser ? (
        <AdminLoader />
    ) : (
        <Row>
            <BreadCrumb title={`${i18n.t('button.edit')} - ${formik?.values?.firstName} ${formik?.values?.lastName}`} />
            <UploadPhoto
                photoField={'photo'}
                formik={formik}
                imageUrl={formik?.values?.photo}
                firstName={formik?.values?.firstName}
                lastName={formik?.values?.lastName}
                headline={`${formik?.values?.firstName} ${formik?.values?.lastName}`}
                subheadline={`${formik?.values?.companyRole || ''}`}
                permission={description.photo}
            />
            <Col xxl={9}>
                <Card>
                    <CardHeader>
                        <FormTabNavigation
                            tabs={tabs}
                            activeTab={activeTab}
                            handleTabChange={tabChange}
                            customClass="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                        />
                    </CardHeader>
                    <CardBody className="p-4">
                        <Form onSubmit={formik.handleSubmit}>
                            <TabContent activeTab={activeTab}>
                                <TabPane tabId="agentInfo">
                                    <Row>
                                        <Col lg={6}>
                                            <div className="mb-3">
                                                <InputText
                                                    label={i18n.t('app.common.firstName')}
                                                    id={'firstName'}
                                                    onChange={formik.handleChange}
                                                    defaultValue={formik?.values?.firstName ?? ''}
                                                    formikError={formik.errors}
                                                    formikTouched={formik.touched}
                                                    isRequired={true}
                                                    permission={description.firstName}
                                                />
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="mb-3">
                                                <InputText
                                                    label={i18n.t('app.common.lastName')}
                                                    id={'lastName'}
                                                    onChange={formik.handleChange}
                                                    defaultValue={formik?.values?.lastName ?? ''}
                                                    formikError={formik.errors}
                                                    formikTouched={formik.touched}
                                                    isRequired={true}
                                                    permission={description.lastName}
                                                />
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="mb-3">
                                                <Label htmlFor="agencyOffice" className="form-label">
                                                    {i18n.t('admin.officeLabel')}
                                                </Label>
                                                {description.agencyOffice && (
                                                    <span className="ps-1">
                                                        <span id={`agentPermissionsTooltipagencyOffice`}>
                                                            <i className="mdi mdi-help-circle-outline"></i>{' '}
                                                        </span>
                                                        <UncontrolledTooltip pement="right" target={`agentPermissionsTooltipagencyOffice`}>
                                                            {description.agencyOffice.description}
                                                        </UncontrolledTooltip>
                                                    </span>
                                                )}
                                                <Select
                                                    id={formik.values?.agencyOffice?.id}
                                                    onChange={(e) => {
                                                        handleDropDownChange(e, 'agencyOffice')
                                                    }}
                                                    options={createSelectOptionData(agencyOffices?.items, 'name', 'id')}
                                                    placeholder={i18n.t('admin.selectAgencyOffice')}
                                                    value={
                                                        createSelectOptionData(agencyOffices?.items, 'name', 'id')?.filter(
                                                            (office) => office?.value === formik.values?.agencyOffice?.id
                                                        )[0]
                                                    }
                                                />
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="mb-3">
                                                <InputText
                                                    label={i18n.t('app.common.jobTitle')}
                                                    id={'companyRole'}
                                                    onChange={formik.handleChange}
                                                    defaultValue={formik.values?.companyRole ?? ''}
                                                    formikError={formik.errors}
                                                    formikTouched={formik.touched}
                                                    permission={description.companyRole}
                                                />
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="mb-3">
                                                <PhoneWithDropDown
                                                    label={i18n.t('admin.phone')}
                                                    id={'phoneNumber'}
                                                    formik={formik}
                                                    defaultValue={formik.values?.phoneNumber}
                                                    permission={description.phone}
                                                />
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="mb-3">
                                                <PhoneWithDropDown
                                                    label={i18n.t('admin.mobilePhone')}
                                                    id={'mobileNumber'}
                                                    formik={formik}
                                                    defaultValue={formik.values?.mobileNumber}
                                                    permission={description.mobileNumber}
                                                />
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="mb-3">
                                                <InputText
                                                    label={i18n.t('app.common.email.label')}
                                                    id={'primaryEmail'}
                                                    onChange={formik.handleChange}
                                                    defaultValue={formik.values?.primaryEmail ?? ''}
                                                    formikError={formik.errors}
                                                    formikTouched={formik.touched}
                                                    isDisabled={true}
                                                    permission={description.primaryEmail}
                                                />
                                            </div>
                                        </Col>
                                        <Col lg={6} className="d-flex align-items-center mt-3">
                                            <div className="d-flex align-items-center">
                                                <Input
                                                    type="checkbox"
                                                    checked={formik.values?.ccEmail || false}
                                                    id={'ccEmail'}
                                                    onChange={formik.handleChange}
                                                    className="form-control me-2 p-2"
                                                />
                                                <Label htmlFor="ccEmail" className="form-label m-0">
                                                    {i18n.t('admin.ccEmail')}
                                                </Label>
                                                {description.ccEmail && (
                                                    <span className="ps-1">
                                                        <span id={`agentPermissionsTooltipccEmail`}>
                                                            <i className="mdi mdi-help-circle-outline"></i>{' '}
                                                        </span>
                                                        <UncontrolledTooltip pement="right" target={`agentPermissionsTooltipccEmail`}>
                                                            {description.ccEmail.description}
                                                        </UncontrolledTooltip>
                                                    </span>
                                                )}
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="mb-3">
                                                <InputText
                                                    label={i18n.t('admin.displayEmail')}
                                                    id={'displayEmail'}
                                                    onChange={formik.handleChange}
                                                    defaultValue={formik.values?.displayEmail ?? ''}
                                                    formikError={formik.errors}
                                                    formikTouched={formik.touched}
                                                    permission={description.displayEmail}
                                                />
                                            </div>
                                        </Col>
                                        <Col lg={6} className="d-flex align-items-center mt-4">
                                            <div className="mb-3 d-flex align-items-center">
                                                <Input
                                                    id={'active'}
                                                    type="checkbox"
                                                    checked={formik.values?.active || false}
                                                    onChange={formik.handleChange}
                                                    className="form-control me-2 p-2"
                                                />
                                                <Label htmlFor="active" className="form-label m-0">
                                                    {i18n.t('admin.activeUser')}
                                                </Label>
                                                {description.activeUser && (
                                                    <span className="ps-1">
                                                        <span id={`agentPermissionsTooltipactiveUser`}>
                                                            <i className="mdi mdi-help-circle-outline"></i>{' '}
                                                        </span>
                                                        <UncontrolledTooltip pement="right" target={`agentPermissionsTooltipactiveUser`}>
                                                            {description.activeUser.description}
                                                        </UncontrolledTooltip>
                                                    </span>
                                                )}
                                            </div>
                                        </Col>
                                        <Col lg={12}></Col>
                                        <Col lg={4}>
                                            <div className="mb-3">
                                                <Label htmlFor="defaultCountry" className="form-label">
                                                    {i18n.t('app.common.country')}
                                                </Label>
                                                {description.defaultCountry && (
                                                    <span className="ps-1">
                                                        <span id={`agentPermissionsTooltipdefaultCountry`}>
                                                            <i className="mdi mdi-help-circle-outline"></i>{' '}
                                                        </span>
                                                        <UncontrolledTooltip pement="right" target={`agentPermissionsTooltipdefaultCountry`}>
                                                            {description.defaultCountry.description}
                                                        </UncontrolledTooltip>
                                                    </span>
                                                )}
                                                <Select
                                                    id={formik.values?.defaultCountry}
                                                    onChange={(e) => {
                                                        handleDropDownChange(e, 'defaultCountry')
                                                        dispatch(fetchLocationsCounty(e.value))
                                                    }}
                                                    options={country}
                                                    isDisabled={country.length === 0}
                                                    placeholder={i18n.t('admin.selectCountry')}
                                                    value={
                                                        country?.filter(
                                                            (country) => country?.value === formik.values?.defaultCountry?.id?.toString()
                                                        )[0]
                                                    }
                                                />
                                            </div>
                                        </Col>
                                        <Col lg={4}>
                                            <div className="mb-3">
                                                <Label htmlFor="defaultCounty" className="form-label">
                                                    {i18n.t('admin.label.county')}
                                                </Label>
                                                {description.defaultCounty && (
                                                    <span className="ps-1">
                                                        <span id={`agentPermissionsTooltipdefaultCounty`}>
                                                            <i className="mdi mdi-help-circle-outline"></i>{' '}
                                                        </span>
                                                        <UncontrolledTooltip pement="right" target={`agentPermissionsTooltipdefaultCounty`}>
                                                            {description.defaultCounty.description}
                                                        </UncontrolledTooltip>
                                                    </span>
                                                )}
                                                <Select
                                                    id={formik.values?.defaultCounty}
                                                    onChange={(e) => {
                                                        handleDropDownChange(e, 'defaultCounty')
                                                        dispatch(fetchLocationsCity(e.value))
                                                    }}
                                                    options={county}
                                                    isDisabled={county.length === 0}
                                                    placeholder={i18n.t('admin.selectCounty')}
                                                    value={
                                                        county?.filter((county) => county?.value === formik.values?.defaultCounty?.id?.toString())[0]
                                                    }
                                                />
                                            </div>
                                        </Col>
                                        <Col lg={4}>
                                            <div className="mb-3">
                                                <Label htmlFor="defaultCity" className="form-label">
                                                    {i18n.t('app.common.city')}
                                                </Label>
                                                {description.defaultCity && (
                                                    <span className="ps-1">
                                                        <span id={`agentPermissionsTooltipdefaultCity`}>
                                                            <i className="mdi mdi-help-circle-outline"></i>{' '}
                                                        </span>
                                                        <UncontrolledTooltip pement="right" target={`agentPermissionsTooltipdefaultCity`}>
                                                            {description.defaultCity.description}
                                                        </UncontrolledTooltip>
                                                    </span>
                                                )}
                                                <Select
                                                    id={formik.values?.defaultCity}
                                                    onChange={(e) => handleDropDownChange(e, 'defaultCity')}
                                                    options={city}
                                                    isDisabled={city.length === 0}
                                                    placeholder={i18n.t('admin.selectCity')}
                                                    value={city?.filter((city) => city?.value === formik.values?.defaultCity?.id?.toString())[0]}
                                                />
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="mb-3">
                                                <Label htmlFor="applicationLanguage" className="form-label">
                                                    {i18n.t('admin.selectLanguageLabel')}
                                                </Label>
                                                {description.applicationLanguage && (
                                                    <span className="ps-1">
                                                        <span id={`agentPermissionsTooltipapplicationLanguage`}>
                                                            <i className="mdi mdi-help-circle-outline"></i>{' '}
                                                        </span>
                                                        <UncontrolledTooltip pement="right" target={`agentPermissionsTooltipapplicationLanguage`}>
                                                            {description.applicationLanguage.description}
                                                        </UncontrolledTooltip>
                                                    </span>
                                                )}
                                                <Select
                                                    id={formik.values?.applicationLanguage}
                                                    onChange={(e) => handleDropDownChange(e, 'applicationLanguage')}
                                                    options={createSelectOptionData(availableLanguages, 'name', 'id')}
                                                    placeholder={i18n.t('admin.selectLanguage')}
                                                    value={
                                                        createSelectOptionData(availableLanguages, 'name', 'id')?.filter(
                                                            (lang) => lang?.value === formik?.values?.applicationLanguage?.id
                                                        )[0]
                                                    }
                                                />
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="mb-3">
                                                <Label htmlFor="agentHomepage" className="form-label">
                                                    {i18n.t('admin.homepageLabel')}
                                                </Label>
                                                {description.agentHomepage && (
                                                    <span className="ps-1">
                                                        <span id={`agentPermissionsTooltipagentHomepage`}>
                                                            <i className="mdi mdi-help-circle-outline"></i>{' '}
                                                        </span>
                                                        <UncontrolledTooltip pement="right" target={`agentPermissionsTooltipagentHomepage`}>
                                                            {description.agentHomepage.description}
                                                        </UncontrolledTooltip>
                                                    </span>
                                                )}
                                                <Select
                                                    id={formik.values?.agentHomepage}
                                                    onChange={(e) => {
                                                        formik?.setFieldValue('agentHomepage', `/${e.value}`)
                                                    }}
                                                    options={createSelectOptionData(applicationPackage?.applicationModules, 'name', 'token')}
                                                    placeholder={i18n.t('admin.selectHomePage')}
                                                    value={createSelectOptionData(applicationPackage?.applicationModules, 'name', 'token')?.find(
                                                        (item) => item.value === formik.values?.agentHomepage?.substring(1)
                                                    )}
                                                />
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="mb-3 d-flex align-items-center">
                                                <Input
                                                    type="checkbox"
                                                    checked={formik.values?.nameSync || false}
                                                    id={'nameSync'}
                                                    onChange={formik.handleChange}
                                                    className="form-control me-2 p-2"
                                                />
                                                <Label className="form-label m-0">{i18n.t('admin.syncName')}</Label>
                                                {description.nameSync && (
                                                    <span className="ps-1">
                                                        <span id={`agentPermissionsTooltipsyncName`}>
                                                            <i className="mdi mdi-help-circle-outline"></i>{' '}
                                                        </span>
                                                        <UncontrolledTooltip pement="right" target={`agentPermissionsTooltipsyncName`}>
                                                            {description.nameSync.description}
                                                        </UncontrolledTooltip>
                                                    </span>
                                                )}
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="mb-3 d-flex align-items-center">
                                                <Input
                                                    id={'phoneNumberSync'}
                                                    type="checkbox"
                                                    checked={formik.values?.phoneNumberSync || false}
                                                    onChange={formik.handleChange}
                                                    className="form-control me-2 p-2"
                                                />
                                                <Label htmlFor="phoneNumberSync" className="form-label m-0">
                                                    {i18n.t('admin.snycPhone')}
                                                </Label>
                                                {description.phoneNumberSync && (
                                                    <span className="ps-1">
                                                        <span id={`agentPermissionsTooltipphoneNumberSync`}>
                                                            <i className="mdi mdi-help-circle-outline"></i>{' '}
                                                        </span>
                                                        <UncontrolledTooltip pement="right" target={`agentPermissionsTooltipphoneNumberSync`}>
                                                            {description.phoneNumberSync.description}
                                                        </UncontrolledTooltip>
                                                    </span>
                                                )}
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="mb-3 d-flex align-items-center">
                                                <Input
                                                    type="checkbox"
                                                    checked={formik.values?.mobileNumberSync || false}
                                                    id={'mobileNumberSync'}
                                                    onChange={formik.handleChange}
                                                    className="form-control me-2 p-2"
                                                />
                                                <Label htmlFor="mobileNumberSync" className="form-label m-0">
                                                    {i18n.t('admin.syncMobilePhone')}
                                                </Label>
                                                {description.mobileNumberSync && (
                                                    <span className="ps-1">
                                                        <span id={`agentPermissionsTooltipmobileNumberSync`}>
                                                            <i className="mdi mdi-help-circle-outline"></i>{' '}
                                                        </span>
                                                        <UncontrolledTooltip pement="right" target={`agentPermissionsTooltipmobileNumberSync`}>
                                                            {description.mobileNumberSync.description}
                                                        </UncontrolledTooltip>
                                                    </span>
                                                )}
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="mb-3 d-flex align-items-center">
                                                <Input
                                                    type="checkbox"
                                                    checked={formik.values?.primaryEmailSync || false}
                                                    id={'primaryEmailSync'}
                                                    onChange={formik.handleChange}
                                                    className="form-control me-2 p-2"
                                                />
                                                <Label htmlFor="primaryEmailSync" className="form-label m-0">
                                                    {i18n.t('admin.syncEmail')}
                                                </Label>
                                                {description.primaryEmailSync && (
                                                    <span className="ps-1">
                                                        <span id={`agentPermissionsTooltipprimaryEmailSync`}>
                                                            <i className="mdi mdi-help-circle-outline"></i>{' '}
                                                        </span>
                                                        <UncontrolledTooltip pement="right" target={`agentPermissionsTooltipprimaryEmailSync`}>
                                                            {description.primaryEmailSync.description}
                                                        </UncontrolledTooltip>
                                                    </span>
                                                )}
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="mb-3 d-flex align-items-center">
                                                <Input
                                                    type="checkbox"
                                                    checked={formik.values?.photoSync || false}
                                                    id={'photoSync'}
                                                    onChange={formik.handleChange}
                                                    className="form-control me-2 p-2"
                                                />
                                                <Label htmlFor="photoSync" className="form-label m-0">
                                                    {i18n.t('admin.syncPhoto')}
                                                </Label>
                                                {description.photoSync && (
                                                    <span className="ps-1">
                                                        <span id={`agentPermissionsTooltipphotoSync`}>
                                                            <i className="mdi mdi-help-circle-outline"></i>{' '}
                                                        </span>
                                                        <UncontrolledTooltip pement="right" target={`agentPermissionsTooltipphotoSync`}>
                                                            {description.photoSync.description}
                                                        </UncontrolledTooltip>
                                                    </span>
                                                )}
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="mb-3 d-flex align-items-center">
                                                <Input
                                                    type="checkbox"
                                                    checked={formik.values?.agencySync || false}
                                                    id={'agencySync'}
                                                    onChange={formik.handleChange}
                                                    className="form-control me-2 p-2"
                                                />
                                                <Label htmlFor="agencySync" className="form-label m-0">
                                                    {i18n.t('admin.syncAgency')}
                                                </Label>
                                                {description.agencySync && (
                                                    <span className="ps-1">
                                                        <span id={`agentPermissionsTooltipagencySync`}>
                                                            <i className="mdi mdi-help-circle-outline"></i>{' '}
                                                        </span>
                                                        <UncontrolledTooltip pement="right" target={`agentPermissionsTooltipagencySync`}>
                                                            {description.agencySync.description}
                                                        </UncontrolledTooltip>
                                                    </span>
                                                )}
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="mb-3 d-flex align-items-center">
                                                <Input
                                                    type="checkbox"
                                                    checked={formik.values?.agencyOfficeSync || false}
                                                    id={'agencyOfficeSync'}
                                                    onChange={formik.handleChange}
                                                    className="form-control me-2 p-2"
                                                />
                                                <Label htmlFor="agencyOfficeSync" className="form-label m-0">
                                                    {i18n.t('admin.syncOffice')}
                                                </Label>
                                                {description.agencyOfficeSync && (
                                                    <span className="ps-1">
                                                        <span id={`agentPermissionsTooltipagencyOfficeSync`}>
                                                            <i className="mdi mdi-help-circle-outline"></i>{' '}
                                                        </span>
                                                        <UncontrolledTooltip pement="right" target={`agentPermissionsTooltipagencyOfficeSync`}>
                                                            {description.agencyOfficeSync.description}
                                                        </UncontrolledTooltip>
                                                    </span>
                                                )}
                                            </div>
                                        </Col>
                                    </Row>
                                </TabPane>

                                <TabPane tabId="permissions">
                                    <AgentPermissions formik={formik} />
                                </TabPane>
                            </TabContent>
                            <Col lg={12}>
                                <div className="hstack gap-2 justify-content-end">
                                    <FormActionButtons disabled={false} hasCancel={true} cancelAction={() => history.push('/admin/user')} />
                                </div>
                            </Col>
                        </Form>
                        <ErrorModal formik={formik} />
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

export default UserEdit
