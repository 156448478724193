import { put, takeLatest } from 'redux-saga/effects'
import { apiRequest } from '../api'
import {
    fetchApplicationSettings,
    fetchApplicationSettingsFailure,
    fetchApplicationSettingsRequest,
    fetchApplicationSettingsSuccess,
} from './actions'

export function* fetchApplicationSettingsSaga() {
    yield put(fetchApplicationSettingsRequest())

    try {
        const response = yield apiRequest('v1/core/application-settings', {
            method: 'GET',
        })

        yield put(fetchApplicationSettingsSuccess(response.data))
    } catch (error) {
        yield put(fetchApplicationSettingsFailure(error))
    }
}

export default function* actionWatcher() {
    yield takeLatest(`${fetchApplicationSettings}`, fetchApplicationSettingsSaga)
}
