import { generatePermissionsData } from './utils'

// handles info (inputs) in edit view of Projects/Properties
export const setEditData = ({ agentPermissions, data, isAgentOwnership, isAgentOffice, module }) => { 
    let newData = data
    const moduleName = module === 'properties' ? 'property' : 'project'

    const permissions = [
        { ruleName: `view.${moduleName}.widerArea`, dataName: 'widerArea' },
        { ruleName: `view.${moduleName}.quarter`, dataName: 'quarter' },
        { ruleName: `view.${moduleName}.street`, dataName: 'street' },
        { ruleName: `view.${moduleName}.owner`, dataName: 'ownerInfo' },
        { ruleName: 'view.property.registry', dataName: 'registry' },
        { ruleName: 'view.property.propertyLabel', dataName: 'propertyLabel' },
        { ruleName: 'view.property.priceClient', dataName: 'priceClient' },
        { ruleName: 'module.property.authorization', dataName: 'webSync' },
    ]

    const editPermissions = generatePermissionsData(permissions, agentPermissions, isAgentOwnership, isAgentOffice)
    const { registry, widerArea, quarter, street, propertyLabel, priceClient, webSync, ownerInfo } = editPermissions

    let inputs = []
    let groupNames = []
    const conditions = [
        { condition: widerArea, input: ['wider_area_id'], groupName: 'locationGroup' },
        { condition: quarter, input: ['quarter_id'], groupName: 'locationGroup' },
        { condition: street, input: ['street', 'street_number', 'street_sync'], groupName: 'locationGroup' },
        { condition: propertyLabel, input: ['property_label'], groupName: 'locationGroup' },
        { condition: priceClient, input: ['price_client'], groupName: 'priceGroup' },
        { condition: webSync, input: ['project_web_sync'], groupName: 'websiteGroup' },
    ]

    const isGeneralTabData = data?.some((obj) => obj?.name?.includes('locationGroup'))

    if (!registry) {
        newData = filterRegistryData(newData)
    }

    if (!ownerInfo) {
        newData = filterOwnerData(newData)
    }

    if (isGeneralTabData) {
        conditions.forEach((obj) => {
            if (!obj.condition) {
                newData = handleGeneralData(newData, inputs, groupNames, obj.input, obj.groupName)
            }
        })
    }

    return { newData }
}

const filterRegistryData = (data) => {
    return data?.filter((obj) => !registryFormGroups.includes(obj?.name))
}
const filterOwnerData = (data) => {
    const isTargetData = data?.every((item) => Array.isArray(item))

    return !isTargetData ? data : data?.filter((arr) => arr.some((obj) => obj.name !== 'owner_id'))
}

const handleGeneralData = (data, inputs, groupNames, newInputs, newGroupName) => {
    inputs.push(...newInputs)
    groupNames.push(newGroupName)
    groupNames = [...new Set(groupNames)]

    return filterGeneralData(data, inputs, groupNames)
}

const filterGeneralData = (data, inputs, groupNames) => {
    return data?.map((group) => {
        if (groupNames.includes(group.name)) {
            return {
                ...group,
                children: group.children.filter((obj) => !inputs.includes(obj.name)),
            }
        }

        return group
    })
}

const registryFormGroups = ['ownershipGroup', 'mainRegistryGroup', 'registryGroup', 'registryDepartmentGroup', 'pukDepartmentGroup', 'cadastralGroup']
