import i18n from 'i18next'

export const officeAgencyFieldDescription = () => {
    return {
        officeName: {
            id: 'officeName',
            description: i18n.t('admin.office.description.name'),
        },
        officeLogo: {
            id: 'officeLogo',
            description: i18n.t('admin.office.description.logo'),
        },
        agencyName: {
            id: 'agencyName',
            description: i18n.t('app.common.company.name'),
        },
        agencyLogo: {
            id: 'agencyLogo',
            description: i18n.t('admin.agency.description.logo'),
        },
        agencyWatermark: {
            id: 'agencyWatermark',
            description: i18n.t('admin.agency.description.watermark'),
        },
        officeWatermark: {
            id: 'officeWatermark',
            description: i18n.t('admin.office.description.watermark'),
        },
        watermarkPosition: {
            id: 'watermarkPosition',
            description: i18n.t('admin.agency.description.watermarkPosition'),
        },
        watermarkTransperncy: {
            id: 'watermarkTransperncy',
            description: i18n.t('admin.agency.description.watermarkTransperncy'),
        },
        country: {
            id: 'country',
            description: i18n.t('app.common.country'),
        },
        city: {
            id: 'city',
            description: i18n.t('app.common.city'),
        },
        zipCode: {
            id: 'zipCode',
            description: i18n.t('form.contact.general.zipCode'),
        },
        street: {
            id: 'street',
            description: i18n.t('app.common.street'),
        },
        streetNumber: {
            id: 'streetNumber',
            description: i18n.t('app.common.streetNumber'),
        },
        taxNumberAgency: {
            id: 'taxNumber',
            description: i18n.t('admin.agency.description.taxNumber'),
        },
        taxNumberOffice: {
            id: 'taxNumber',
            description: i18n.t('admin.office.description.taxNumber'),
        },
        phone: {
            id: 'phone',
            description: i18n.t('admin.user.description.phone'),
        },
        mobileNumber: {
            id: 'mobileNumber',
            description: i18n.t('admin.user.description.mobileNumber'),
        },
        emailAgency: {
            id: 'email',
            description: i18n.t('admin.agency.description.emailAgency'),
        },
        emailOffice: {
            id: 'email',
            description: i18n.t('admin.office.description.email'),
        },
        webAgency: {
            id: 'web',
            description: i18n.t('app.common.web'),
        },
        webOffice: {
            id: 'web',
            description: i18n.t('admin.office.description.web'),
        },
        officeInfo: {
            id: 'officeInfo',
            description: i18n.t('admin.office.description.officeInfo'),
        },
        agencyInfo: {
            id: 'agencyInfo',
            description: i18n.t('admin.agency.description.agencyInfo'),
        },
        priority: {
            id: 'priority',
            description: i18n.t('admin.office.description.priority'),
        },
        agencyOfficeSync: {
            id: 'agencyOfficeSync',
            description: i18n.t('admin.office.description.agencyOfficeSync'),
        },
        agencySync: {
            id: 'agencySync',
            description: i18n.t('admin.agency.description.agencySync'),
        },
    }
}
