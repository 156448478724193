import values from 'lodash/values';

import {stateKey} from './actions';

export const selectPropertyTypes = state => values(state[stateKey].results);
export const selectPropertyTypesFormOptions = state => {
    const data = values(state[stateKey].items);
    return data.map((realEstateType) => (
        {
            value: realEstateType.id.toString(),
            label: realEstateType.name
        }
    ))
}