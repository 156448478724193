import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectCurrentUserSettings } from '../store/auth/selectors'

export const useColumnOrder = (url) => {
    const { userSettings } = useSelector((state) => ({
        userSettings: selectCurrentUserSettings(state),
    }))

    const moduleColumnSort = userSettings?.[`${url}_columnSort`]
    const { id: columnName, desc } = moduleColumnSort?.[0] ?? {}

    const order = useMemo(
        () => ({
            [columnName]: desc ? 'desc' : 'asc',
        }),
        [columnName, desc]
    )

    return order
}
