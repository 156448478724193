import { forwardRef, useEffect, useRef } from 'react'

const TableCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
    const defaultRef = useRef()
    const resolvedRef = ref || defaultRef

    useEffect(() => {
        if (resolvedRef.current) {
            resolvedRef.current.indeterminate = indeterminate
        }
    }, [resolvedRef, indeterminate])

    return <input className="form-check-input" type="checkbox" ref={resolvedRef} {...rest} />
})

TableCheckbox.displayName = 'TableCheckbox'

export default TableCheckbox
