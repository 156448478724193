import values from 'lodash/values';
import { priorityCompareFn } from '../../utils/general';

import { stateKey } from './actions';

export const selectPhotos = state => values(state[stateKey].items);
export const selectSortedPhotos = state => values(state[stateKey].items).sort(priorityCompareFn);
export const selectPhotosFetching = state => state[stateKey].fetching;
export const selectPhotosCount = state => state[stateKey].count;
export const selectPhotosQue = state => values(state[stateKey].que);
export const selectPhotosProxyPhoto = state => state[stateKey].proxyPhoto;
export const selectPhotosProxyPhotoFetching = state => state[stateKey].proxyPhotoFetching;
export const selectPhotosProxyPhotoError = state => state[stateKey].proxyPhotoError;