import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Button, Input, Modal, ModalBody, ModalHeader, Tooltip } from 'reactstrap'
import { MODAL_TYPES } from '../../../../common/constants'
import { hideModal, showModal } from '../../../../store/modal'
import { resetTableCheckbox } from '../../../../store/table'
import { useFetchAgencyData } from '../query'
import styles from './TemplatesModal.module.css'

const TemplatesModal = ({ isOpen, ...modalProps }) => {
    const [lang, setLang] = useState('hr')
    const [tooltipOpen, setTooltipOpen] = useState({})

    const dispatch = useDispatch()
    const { t } = useTranslation()

    const { TEMPLATES_MODAL, SHOPWINDOW_PRINT_MODAL } = MODAL_TYPES
    const { selectedProperties } = modalProps[TEMPLATES_MODAL]

    const templateOptions = [
        { id: 'template-1', src: '../images/templates/template-1.png' },
        { id: 'template-2', src: '../images/templates/template-2.png' },
        { id: 'template-3', src: '../images/templates/template-3.png' },
        { id: 'template-4', src: '../images/templates/template-4.png' },
        { id: 'template-5', src: '../images/templates/template-5.png' },
        { id: 'template-6', src: '../images/templates/template-6.png' },
        { id: 'template-7', src: '../images/templates/template-7.png' },
        { id: 'template-8', src: '../images/templates/template-8.png' },
    ]

    // fetch agency data (logo, phone, website)
    const { data } = useFetchAgencyData()

    const getPropertyContent = (property) => {
        const { translations } = property ?? {}

        const content = {
            title: translations[lang]?.propertyTitle ?? '',
            description: translations[lang]?.propertyDescription ?? '',
        }

        return content
    }

    const propertyPrintData = () => {
        const propertyData = selectedProperties.map((property) => {
            const propertyContent = getPropertyContent(property)

            return {
                ...property,
                title: propertyContent.title,
                description: propertyContent.description,
                logo: data?.logo,
                phoneNumber: data?.phoneNumber,
                website: data?.websiteUrl,
                qrCode: data?.qrCode,
            }
        })

        return propertyData
    }

    const handleShopWindowPrint = (e) => {
        const selectedTemplate = e.target.id

        const isTemplateEnabled = handleEnabledTemplates(selectedTemplate)

        if (isTemplateEnabled) {
            const propertyData = propertyPrintData()
            const payload = {
                modalType: SHOPWINDOW_PRINT_MODAL,
                modalProps: {
                    [SHOPWINDOW_PRINT_MODAL]: {
                        properties: propertyData,
                        selectedTemplate: selectedTemplate,
                    },
                },
            }

            if (propertyData.length) {
                dispatch(showModal(payload))
            }
        }
    }

    const handleClose = () => {
        const payload = {
            modalType: TEMPLATES_MODAL,
        }

        dispatch(hideModal(payload))
        dispatch(resetTableCheckbox(true))
    }

    const handleEnabledTemplates = (template) => {
        switch (template) {
            case 'template-1':
            case 'template-2':
            case 'template-3':
            case 'template-5':
            case 'template-6':
                return selectedProperties?.length === 1
            case 'template-4':
                return selectedProperties?.length === 2
            case 'template-7':
                return selectedProperties?.length === 4
            case 'template-8':
                return selectedProperties?.length === 6
            default:
                return false
        }
    }

    const handleLangChange = (e) => {
        setLang(e.target.value)
    }

    const toggleTooltip = (tooltipId) => {
        setTooltipOpen((prev) => ({
            ...prev,
            [tooltipId]: !prev[tooltipId],
        }))
    }

    const tooltipContent = (tooltipId) => {
        switch (tooltipId) {
            case 'template-1':
            case 'template-2':
            case 'template-3':
            case 'template-5':
            case 'template-6':
                return t('modal.templates.tooltip.selectOne')
            case 'template-4':
                return t('modal.templates.tooltip.selectTwo')
            case 'template-7':
                return t('modal.templates.tooltip.selectFour')
            case 'template-8':
                return t('modal.templates.tooltip.selectSix')
            default:
                break
        }
    }

    return (
        <Modal isOpen={isOpen} className={styles.templatesModal} centered>
            <div className={styles.modalHeaderContainer}>
                <ModalHeader>{t('modal.templates.title')}</ModalHeader>
                <Input onChange={handleLangChange} value={lang} name="select" type="select">
                    <option value="hr">hr</option>
                    <option value="en">en</option>
                </Input>
                <Button type="button" className={styles.btnClose} onClick={handleClose}>
                    <i className="mdi mdi-close fs-16"></i>
                </Button>
            </div>
            <ModalBody>
                <div className={styles.gridContainer}>
                    {templateOptions.map((option, index) => {
                        const showTooltip = !handleEnabledTemplates(option.id)

                        return (
                            <React.Fragment key={option.id}>
                                {showTooltip && (
                                    <Tooltip isOpen={tooltipOpen[option.id]} toggle={() => toggleTooltip(option.id)} target={option.id}>
                                        {tooltipContent(option.id)}
                                    </Tooltip>
                                )}
                                <div
                                    id={option.id}
                                    onClick={handleShopWindowPrint}
                                    className={`${styles.gridItem} 
                                        ${index < 4 ? styles.gridItemVertical : styles.gridItemHorizontal} 
                                        ${!handleEnabledTemplates(option.id) && styles.gridItemDisabled}
                                        `}
                                >
                                    <img src={option.src} id={option.id} alt={option.id} />
                                </div>
                            </React.Fragment>
                        )
                    })}
                </div>
            </ModalBody>
        </Modal>
    )
}

export default TemplatesModal
