import { put, takeLatest } from "redux-saga/effects";
import { apiRequest } from "../api";
import { fetchActivityValuesAvailableEntities, fetchActivityValuesAvailableEntitiesFailure, fetchActivityValuesAvailableEntitiesRequest, fetchActivityValuesAvailableEntitiesSuccess, fetchActivityValuesType, fetchActivityValuesTypeFailure, fetchActivityValuesTypeRequest, fetchActivityValuesTypeSuccess } from "./actions";

export function* fetchActivityValuesAvailableEntitiesSaga() {
    yield put(fetchActivityValuesAvailableEntitiesRequest());
    try {
        const response = yield apiRequest('v1/activity/availableEntities', {
            method: 'GET'
        });
        yield put(fetchActivityValuesAvailableEntitiesSuccess(response.data));
    } catch (error) {
        yield put(fetchActivityValuesAvailableEntitiesFailure(error));
    }
}

export function* fetchActivityValuesTypeSaga() {
    yield put(fetchActivityValuesTypeRequest());
    try {
        const response = yield apiRequest('v1/activity/values/activityType', {
            method: 'GET'
        });

        yield put(fetchActivityValuesTypeSuccess(response.data));
    } catch (error) {
        yield put(fetchActivityValuesTypeFailure(error));
    }
}

export default function* actionWatcher() {
    yield takeLatest(`${fetchActivityValuesAvailableEntities}`, fetchActivityValuesAvailableEntitiesSaga);
    yield takeLatest(`${fetchActivityValuesType}`, fetchActivityValuesTypeSaga);
}