import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { selectApplicationCurrency } from '../../../store/settings/selectors'
import { formatDateTime, formatNumberWithTwoDecimals } from '../../../utils/general'
import { renderCurrency } from '../../../utils/property'

const TableColumns = (toggleQuickViewModal) => {
    const { currency } = useSelector((state) => ({
        currency: selectApplicationCurrency(state),
    }))

    const { t } = useTranslation()

    const columns = useMemo(
        () => [
            {
                Header: t('app.common.status'),
                Cell: (item) => {
                    const propertyStatusId = item.row.original?.propertyStatus.id
                    const status = item.row.original?.activityType
                        ? propertyStatusId === 2 || propertyStatusId === 3 || propertyStatusId === 4
                            ? item.row.original?.propertyStatus?.name
                            : item.row.original?.activityType?.name
                        : item.row.original?.propertyStatus?.name
                    return <>{status}</>
                },
                filterable: false,
            },
            {
                Header: t('app.common.date'),
                accessor: 'created',
                Cell: (item) => {
                    let formatedDate
                    const propertyStatus = item.row.original?.propertyStatus
                    const activityType = item.row.original?.activityType
                    const propertyTransaction = item.row.original?.propertyTransaction

                    if (!activityType && propertyStatus.id === 1) {
                        formatedDate = formatDateTime(item.row.original?.created)
                    } else if (propertyStatus.id === 3 || propertyStatus.id === 4 || propertyStatus.id === 8) {
                        formatedDate = propertyTransaction && formatDateTime(propertyTransaction?.conclusionDate)
                    } else if (!activityType && propertyStatus.id === 2) {
                        formatedDate = propertyTransaction && formatDateTime(propertyTransaction?.reservationDate)
                    } else if (activityType && (activityType.id === 4 || activityType.id === 7)) {
                        formatedDate = propertyTransaction && formatDateTime(propertyTransaction?.reservationDate)
                    } else if (propertyStatus.id === 7) {
                        formatedDate = formatDateTime(item.row.original?.updated)
                    } else {
                        formatedDate = formatDateTime(item.row.original?.created)
                    }

                    return <>{formatedDate}</>
                },
                filterable: false,
            },
            {
                Header: t('app.common.id'),
                accessor: 'id',
                filterable: false,
            },
            {
                Header: t('app.common.type'),
                accessor: 'propertyType.name',
                filterable: false,
            },
            {
                Header: t('app.common.city'),
                accessor: 'city.name',
                filterable: false,
            },
            {
                Header: t('app.common.street'),
                accessor: 'street',
                filterable: false,
                width: 200,
            },
            {
                Header: t('app.common.price', { currency: renderCurrency(currency) }),
                Cell: (item) => {
                    const formatedPrice = item.row.original?.price && formatNumberWithTwoDecimals(Number(item.row.original?.price))
                    return <>{formatedPrice}</>
                },
                filterable: false,
                width: 120,
            },
            {
                id: 'actions',
                thDivClass: 'text-center',
                Cell: (cellProps) => {
                    return (
                        <ul className="list-inline hstack gap-2 mb-0 justify-content-center">
                            <li className="list-inline-item">
                                <Link
                                    title={t('button.view')}
                                    to="#"
                                    className="btn d-inline-block p-0 text-warning"
                                    onClick={() => toggleQuickViewModal(cellProps?.row?.original)}
                                >
                                    <i className="mdi mdi-eye-outline fs-16"></i>
                                </Link>
                            </li>
                        </ul>
                    )
                },
            },
        ],
        [currency]
    )

    return columns
}

export default TableColumns
