export const PROJECT_VIEW_SELECT_ID = 1
export const PROPERTY_VIEW_SELECT_ID = 5
export const CONTACT_VIEW_SELECT_ID = 9
export const COMPANY_VIEW_SELECT_ID = 13
export const ACTIVITY_VIEW_SELECT_ID = 17
export const DOCUMENT_VIEW_SELECT_ID = 21

export const PROJECT_FILTERS = [2, 44, 45, 46, 47, 48, 49]
export const PROPERTY_FILTERS = [6, 25, 26, 27, 28, 29, 31, 32, 33, 35]
export const CONTACT_FILTERS = [10]
export const COMPANY_FILTERS = [14]
export const ACTIVITY_FILTERS = [18]
export const DOCUMENT_FILTERS = [22]

export const OWN_OPTION_ID = 2
export const OFFICE_OPTION_ID = 3
export const ALL_OPTION_ID = 4

export const VIEW_SELECT_IDS = [
    PROJECT_VIEW_SELECT_ID,
    PROPERTY_VIEW_SELECT_ID,
    CONTACT_VIEW_SELECT_ID,
    COMPANY_VIEW_SELECT_ID,
    ACTIVITY_VIEW_SELECT_ID,
    DOCUMENT_VIEW_SELECT_ID,
]

export const formTitles = [
    { title: 'app.common.projects', permissionGroup: 'project' },
    { title: 'app.common.properties', permissionGroup: 'property' },
    { title: 'app.common.contacts', permissionGroup: 'contact' },
    { title: 'app.common.companies', permissionGroup: 'company' },
    { title: 'app.common.activities', permissionGroup: 'activity' },
    { title: 'app.common.documents', permissionGroup: 'document' },
    { title: 'menu.other', permissionGroup: 'other' },
]
