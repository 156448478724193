import { setAllowedTabs } from '../../AppTopSwitcher/utils'
import { setPermissions } from './utils'

export const setAllowedPermissionTabs = ({ agentPermissions, isAgentOwnership, isAgentOffice, module }) => {
    const moduleName = module === 'properties' ? 'property' : 'project'

    // add more tabs if needed, then destructure them in FormTabNavigation component
    const permissions = [
        { ruleName: 'module.document.view', tabName: 'documents' },
        { ruleName: `view.${moduleName}.map`, tabName: 'map' },
        { ruleName: 'view.property.owner', tabName: 'relatedProperties' },
        { ruleName: 'module.property.synchronization', tabName: 'export' },
    ]

    const tabs = setAllowedTabs(agentPermissions, permissions, setPermissions, 'permissionTabs', isAgentOwnership, isAgentOffice)

    return tabs
}
