import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { Button, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from 'reactstrap'
import SimpleBar from 'simplebar-react'
import responseFields from '../../common/constants/responseFields'
import { projectResponseFields } from '../../common/response/project'
import { propertyResponseFields } from '../../common/response/property'
import { selectApplicationAreaUnit, selectApplicationCurrency } from '../../store/settings'
import { getSVGIconFromExtension } from '../../utils/files'
import { findEntityTitle, formatDateTime, formatNumberWithTwoDecimals } from '../../utils/general'
import { getOwnerFullName, renderAreaUnit, renderCurrency, renderCurrencyWithUnit } from '../../utils/property'
import DeleteModal from '../Common/DeleteModal'
import OfferPrintModal from '../Modals/OfferPrintModal'
import TableContainer from '../Table/TableContainer'

const RelatedPropertiesViewTab = ({
    properties,
    extraCriteria,
    deleteAction,
    addButtonAction,
    fetchAction,
    fetching,
    downloadAction,
    documentModule,
    ...props
}) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const { currency, areaUnit } = useSelector((state) => {
        return {
            currency: selectApplicationCurrency(state),
            areaUnit: selectApplicationAreaUnit(state),
        }
    })

    const [totalCount, setTotalCount] = useState(0)
    const [pageCount, setPageCount] = useState(0)
    const [deleteModal, setDeleteModal] = useState(false)
    const [offerPrintModal, setOfferPrintModal] = useState(false)
    const [selectedOfferProperties, setSelectedOfferProperties] = useState([])
    const [deleteModalMulti, setDeleteModalMulti] = useState(false)
    const [invoice, setInvoice] = useState(null)

    const appModule = 'relatedProperties'

    // Delete File
    const onClickFileDelete = (file) => {
        setInvoice(file)
        setDeleteModal(true)
    }
    const checkedAll = () => {
        const checkall = document.getElementById('checkBoxAll')
        const ele = document.querySelectorAll('.invoiceCheckBox')

        if (checkall.checked) {
            ele.forEach((ele) => {
                ele.checked = true
            })
        } else {
            ele.forEach((ele) => {
                ele.checked = false
            })
        }
    }
    const onClickDelete = (invoice) => {
        setInvoice(invoice)
        setDeleteModal(true)
    }
    const handleDataFetch = (page, limit, order, criteria) => {
        dispatch(
            fetchAction(
                {
                    page,
                    limit,
                    order,
                    criteria: {
                        ...criteria,
                        ...extraCriteria,
                    },
                },
                (count) => {
                    setPageCount(Math.ceil(count / limit))
                    setTotalCount(count)
                }
            )
        )
        // dispatch(fetchContacts({
        //     page,
        //     limit,
        //     order,
        //     criteria: {
        //       ...criteria,
        //       contactType: 'person'
        //     }
        // }, (count) => setPageCount(Math.floor(count / limit))))
    }
    const toggleOfferPrintModal = () => {
        if (offerPrintModal) {
            setOfferPrintModal(false)
            setSelectedOfferProperties([])
            // noteValidation.resetForm();
            //   setEvent(null);
            //   setIsEdit(false);
        } else {
            setOfferPrintModal(true)
        }
    }
    const columns = useMemo(
        () => [
            // {
            //     Header: (<><input type="checkbox" id="checkBoxAll" className="form-check-input" onClick={() => checkedAll()} /></>),
            //     Cell: (cellProps) => {
            //         return (
            //             <div className="form-check d-flex justify-content-center">
            //                 <input type="checkbox" className="form-check-input invoiceCheckBox" value={cellProps.row.original.id} />
            //             </div>
            //         );
            //     },
            //     id: '#',
            //     thDivClass: 'form-check d-flex justify-content-center align-items-center'
            // },
            {
                Header: 'ID',
                id: 'id',
                accessor: 'id',
                filterable: true,
                // Cell: (cell) => {
                //   return <Link to={`/properties/${cell.row.original.id}`} className="link-primary">{cell.value}</Link>;
                // },
            },
            {
                Header: t('table.header.common.mainPhoto'),
                filterable: false,
                id: 'propertyPhoto',
            },
            {
                Header: t('app.common.agencyId'),
                accessor: propertyResponseFields.INTERNAL_ID,
                filterable: true,
            },
            {
                Header: t('app.common.service'),
                accessor: `${propertyResponseFields.PROPERTY_SERVICE}.name`,
                id: `${propertyResponseFields.PROPERTY_SERVICE}`,
                filterable: true,
            },
            {
                Header: t('app.common.status'),
                accessor: `${propertyResponseFields.PROPERTY_STATUS}.name`,
                id: `${propertyResponseFields.PROPERTY_STATUS}`,
                filterable: true,
            },
            {
                Header: t('app.common.type'),
                accessor: `${propertyResponseFields.PROPERTY_TYPE}.name`,
                id: `${propertyResponseFields.PROPERTY_TYPE}`,
                filterable: true,
            },
            {
                Header: t('table.header.properties.subtype'),
                accessor: `${propertyResponseFields.PROPERTY_SUB_TYPE}.name`,
                id: `${propertyResponseFields.PROPERTY_SUB_TYPE}`,
                filterable: false,
            },
            {
                Header: t('app.common.country'),
                accessor: `${propertyResponseFields.COUNTRY}.name`,
                id: `${propertyResponseFields.COUNTRY}`,
                filterable: true,
            },
            {
                Header: t('app.common.county'),
                accessor: `${propertyResponseFields.COUNTY}.name`,
                id: `${propertyResponseFields.COUNTY}`,
                filterable: true,
            },
            {
                Header: t('app.common.city'),
                accessor: `${propertyResponseFields.CITY}.name`,
                filterable: true,
            },
            {
                Header: t('app.common.widerArea'),
                accessor: `${propertyResponseFields.WIDER_AREA}.name`,
                id: `${propertyResponseFields.WIDER_AREA}`,
                filterable: true,
            },
            {
                Header: t('app.common.quarter'),
                accessor: `${propertyResponseFields.QUARTER}.name`,
                id: `${propertyResponseFields.QUARTER}`,
                filterable: true,
            },
            {
                Header: t('app.common.street'),
                accessor: `${propertyResponseFields.STREET}`,
                filterable: true,
            },
            {
                Header: t('app.common.streetNumber'),
                accessor: `${propertyResponseFields.STREET_NUMBER}`,
                filterable: true,
            },
            {
                Header: t('app.common.owner'),
                accessor: (property) => getOwnerFullName(property[propertyResponseFields.OWNER]),
                id: `${propertyResponseFields.OWNER}`,
                filterable: true,
            },
            {
                Header: t('app.common.price', { currency: renderCurrency(currency) }),
                id: `${propertyResponseFields.PRICE}`,
                Cell: (item) => (
                    <>
                        {item.row.original?.[propertyResponseFields.PRICE]
                            ? formatNumberWithTwoDecimals(item.row.original?.[propertyResponseFields.PRICE])
                            : null}
                    </>
                ),
                filterable: true,
            },
            {
                Header: t('table.header.properties.pricePerUnit', { currencyPerUnit: renderCurrencyWithUnit(currency, areaUnit) }),
                id: `${propertyResponseFields.PRICE_M2}`,
                Cell: (item) => (
                    <>
                        {item.row.original?.[propertyResponseFields.PRICE_M2]
                            ? formatNumberWithTwoDecimals(item.row.original?.[propertyResponseFields.PRICE_M2])
                            : null}
                    </>
                ),
                filterable: true,
            },
            {
                Header: t('app.common.area', { areaUnit: renderAreaUnit(areaUnit) }),
                id: propertyResponseFields.AREA,
                Cell: (item) =>
                    item.row.original?.[propertyResponseFields.AREA]
                        ? formatNumberWithTwoDecimals(item.row.original?.[propertyResponseFields.AREA])
                        : null,
                filterable: true,
            },
            {
                Header: t('app.common.floorNumber'),
                id: propertyResponseFields.FLOOR_NO,
                Cell: (property) => {
                    const { original: propertyOriginal } = property.row

                    if (!propertyOriginal) {
                        return null
                    }

                    return (
                        <React.Fragment>
                            {propertyOriginal[propertyResponseFields.PROPERTY_FLOOR_POSITION]?.length > 0
                                ? propertyOriginal[propertyResponseFields.PROPERTY_FLOOR_POSITION][0].name
                                : propertyOriginal[propertyResponseFields.FLOOR_NO]
                                ? propertyOriginal[propertyResponseFields.FLOOR_NO]
                                : '-'}
                            {propertyOriginal[propertyResponseFields.FLOORS_TOTAL_NO]
                                ? `/${propertyOriginal[propertyResponseFields.FLOORS_TOTAL_NO]}`
                                : '/-'}
                        </React.Fragment>
                    )
                },
            },
            {
                Header: t('app.common.property.numberOfRooms'),
                accessor: `${propertyResponseFields.PROPERTY_ROOMS_NO}.name`,
                filterable: true,
            },
            {
                Header: t('app.common.bedrooms'),
                accessor: `${propertyResponseFields.BEDROOMS_NO}`,
                filterable: true,
            },
            {
                Header: t('app.common.agent'),
                Cell: (item) => (
                    <>
                        {item.row.original?.[propertyResponseFields.AGENT]?.[0]?.firstName}{' '}
                        {item.row.original?.[propertyResponseFields.AGENT]?.[0]?.lastName}
                    </>
                ),
                filterable: true,
            },
            {
                Header: t('table.header.properties.maxConstructionArea', { areaUnit: renderAreaUnit(areaUnit) }),
                id: `${propertyResponseFields.MAX_CONTRUCTION_AREA}`,
                Cell: (item) =>
                    item.row.original?.[propertyResponseFields.MAX_CONTRUCTION_AREA]
                        ? formatNumberWithTwoDecimals(item.row.original?.[propertyResponseFields.MAX_CONTRUCTION_AREA])
                        : null,
                filterable: true,
            },
            // {
            //     Header: 'Prilike',
            //     id: 'prilike',
            //     filterable: true,
            // },
            {
                Header: t('app.common.property.seaDistance'),
                id: propertyResponseFields.DISTANCE_SEA,
                Cell: (item) =>
                    item.row.original?.[propertyResponseFields.DISTANCE_SEA]
                        ? formatNumberWithTwoDecimals(item.row.original?.[propertyResponseFields.DISTANCE_SEA])
                        : null,
                filterable: true,
            },
            {
                Header: t('app.common.property.yard'),
                id: propertyResponseFields.AREA_GARDEN,
                Cell: (item) =>
                    item.row.original?.[propertyResponseFields.AREA_GARDEN]
                        ? formatNumberWithTwoDecimals(item.row.original?.[propertyResponseFields.AREA_GARDEN])
                        : null,
                filterable: true,
            },
            {
                Header: t('app.common.property.areaPlot'),
                id: propertyResponseFields.PLOT_AREA,
                Cell: (item) =>
                    item.row.original?.[propertyResponseFields.PLOT_AREA]
                        ? formatNumberWithTwoDecimals(item.row.original?.[propertyResponseFields.PLOT_AREA])
                        : null,
                filterable: true,
            },
            {
                Header: t('table.header.properties.agentMobile'),
                id: 'agentMobileNumber',
                accessor: (property) => property[propertyResponseFields.AGENT]?.[0]?.mobile,
                filterable: true,
            },
            {
                Header: t('app.common.heating'),
                id: `${propertyResponseFields.PROPERTY_HEATING}`,
                Cell: (property) => (
                    <>
                        {property.row.original?.[propertyResponseFields.PROPERTY_HEATING]?.map((item) => (
                            <div key={item.id}>{item.name}</div>
                        ))}
                    </>
                ),
                filterable: true,
            },
            {
                Header: t('app.common.web'),
                id: 'websiteTitle',
                accessor: (property) => {
                    if (property.propertyWebsite?.[0]?.visibility === 1) {
                        return <i className="mdi mdi-check fs-4" />
                    }

                    return null
                },
                filterable: false,
            },
            {
                Header: t('table.header.properties.elevator'),
                id: propertyResponseFields.PROPERTY_EQUIPMENT,
                accessor: (property) => {
                    const elevator = property[propertyResponseFields.PROPERTY_EQUIPMENT]?.find(({ name }) => name.toLowerCase() === 'lift')

                    if (elevator) {
                        return <i className="mdi mdi-check fs-4" />
                    }

                    return null
                },
                filterable: false,
            },
            {
                Header: t('app.common.property.constructionYear'),
                accessor: `${propertyResponseFields.CONSTRUCTION_YEAR}`,
                filterable: true,
            },
            {
                Header: t('app.common.property.availableFrom'),
                accessor: `${propertyResponseFields.AVAILABLE_FROM_DATE}`,
                Cell: (item) => formatDateTime(item.row.original?.[propertyResponseFields.AVAILABLE_FROM_DATE]),
                filterable: true,
            },
            {
                Header: t('app.common.offerSource'),
                accessor: `${responseFields.PROPERTY_SUPPLY_SOURCE}.name`,
                id: `${responseFields.PROPERTY_SUPPLY_SOURCE}_name`,
                filterable: true,
            },
            {
                Header: t('table.header.properties.receiptDate'),
                id: `${propertyResponseFields.PROPERTY_RECEIPT_DATE}`,
                filterable: true,
                Cell: (item) => formatDateTime(item.row.original?.[propertyResponseFields.PROPERTY_RECEIPT_DATE]),
            },
            {
                Header: t('app.common.contractNumber'),
                accessor: `${propertyResponseFields.CONTRACT_NO}`,
                filterable: true,
            },
            {
                Header: t('table.header.properties.ownerPhoneNumber1'),
                id: `${propertyResponseFields.OWNER}_phone1`,
                accessor: (property) => property[propertyResponseFields.AGENT]?.[0]?.phone,
                filterable: true,
            },
            {
                Header: t('table.header.properties.ownerPhoneNumber2'),
                id: `${propertyResponseFields.OWNER}_phone2`,
                accessor: (property) => property[propertyResponseFields.AGENT]?.[1]?.phone,
                filterable: true,
            },
            // {
            //     Header: 'Ime kontakt osobe',
            //     // accessor: `${propertyResponseFields.CO}`,
            //     id: 'contact_name',
            //     filterable: true,
            // },
            // {
            //     Header: 'Broj tel. kontakt osobe',
            //     // accessor: `${propertyResponseFields.CONTRACT_NO}`,
            //     id: 'contact_number',
            //     filterable: true,
            // },
            {
                Header: t('app.common.project.label'),
                accessor: (property) => findEntityTitle(property.project, projectResponseFields.PROJECT_TITLE),
                filterable: true,
            },
            {
                Header: t('app.common.updated'),
                accessor: `${propertyResponseFields.UPDATED}`,
                filterable: true,
            },
            {
                Header: t('app.common.property.designation'),
                accessor: propertyResponseFields.PROPERTY_LABEL,
                filterable: false,
            },
            // {
            //   Header: "Address",
            //   filterable: true,
            //   Cell: (property) => (
            //     <>
            //       {property.row.original.street}{" "}{property.row.original.streetNumber}
            //     </>
            //   )
            // },
            // {
            //   Header: "PAYMENT STATUS",
            //   accessor: "status",
            //   Cell: (cell) => {
            //     switch (cell.value) {
            //       case "Paid":
            //         return <span className="badge text-uppercase badge-soft-success"> {cell.value} </span>;
            //       case "Unpaid":
            //         return <span className="badge text-uppercase badge-soft-success"> {cell.value} </span>;
            //       case "Cancel":
            //         return <span className="badge text-uppercase badge-soft-danger"> {cell.value} </span>;
            //       default:
            //         return <span className="badge text-uppercase badge-soft-info"> {cell.value} </span>;
            //     }
            //   }
            // },
            {
                id: 'actions',
                thDivClass: 'text-center',
                Cell: (cellProps) => {
                    return (
                        <ul className="list-inline hstack gap-2 mb-0 justify-content-center">
                            <li className="list-inline-item edit">
                                <Link
                                    title={t('button.edit')}
                                    to={`/properties/${cellProps.row.original.id}/edit`}
                                    className="text-info d-inline-block edit-item-btn"
                                >
                                    <i className="mdi mdi-pencil-outline fs-16"></i>
                                </Link>
                            </li>
                            <li className="list-inline-item">
                                <Link title={t('button.view')} to={`/properties/${cellProps.row.original.id}`} className="text-info d-inline-block">
                                    <i className="mdi mdi-eye-outline fs-16"></i>
                                </Link>
                            </li>
                            <li className="list-inline-item">
                                <Link
                                    title={t('button.delete')}
                                    to="#"
                                    className="text-danger d-inline-block remove-item-btn"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                        const orderData = cellProps.row.original
                                        onClickDelete(orderData)
                                    }}
                                >
                                    <i className="mdi mdi-trash-can-outline fs-16"></i>
                                </Link>
                            </li>
                        </ul>
                    )
                },
            },
        ],
        []
    )

    const handleRowClick = (id) => {
        window.open(`/properties/${id}`, '_blank')
    }
    const handleDeleteInvoice = () => {
        if (invoice) {
            // console.log('invoice', invoice)
            dispatch(deleteAction(invoice.id))
            setDeleteModal(false)
        }
    }
    const offerFunctionAction = () => {
        const ele = document.querySelectorAll('.invoiceCheckBox:checked')
        const checkall = document.getElementById('checkBoxAll')
        // console.log('ele', ele)
        if (ele.length) {
            const eleValues = []
            ele.forEach((element) => {
                // console.log(element.value)
                eleValues.push(element.value)
            })
            // console.log('flter', properties.filter(x => {
            //   console.log('eleValues', eleValues)
            //   console.log('item', x)
            //   return eleValues.includes(x.id.toString())
            // }))
            setSelectedOfferProperties(properties.filter((x) => eleValues.includes(x.id.toString())))
            setOfferPrintModal(true)
        }

        checkall.checked = false
    }
    const tableActions = () => (
        <div className="d-flex flex-grow-1">
            {/* <Button tag={Link} to="/properties/new" color="add" className="btn-label">
            <i className="mdi mdi-domain align-bottom label-icon align-middle fs-16 me-2"></i>
            Dodaj
          </Button> */}
            {/* <UncontrolledDropdown className="ms-2"> */}
            {/* <DropdownToggle
              href="#"
              className="btn btn-primary dropdown"
              tag="button"
            >
              <i className="ri-more-fill align-middle"></i>
            </DropdownToggle> */}
            {/* <DropdownMenu className="dropdown-menu-end"> */}
            {/* <DropdownItem
                href="#"
              // onClick={() => { const invoiceData = cellProps.row.original; onClickDelete(invoiceData); }}
              >
                <i className="mdi mdi-trash-can-outline align-bottom me-2 text-muted"></i>{" "}
                Izbriši oznacene
              </DropdownItem>
    
              <DropdownItem href="/apps-invoices-create">
                <i className="mdi mdi-pencil-outline align-bottom me-2 text-muted"></i>{" "}
                Uredi
              </DropdownItem>
    
              <DropdownItem href="/#">
                <i className="mdi mdi-home-import-outline align-bottom me-2 text-muted"></i>{" "}
                Autoriziraj nekretninu
              </DropdownItem>
    
              <DropdownItem href="/#">
                <i className="mdi mdi-dock-window align-bottom me-2 text-muted"></i>{" "}
                Otvori u drugom prozoru
              </DropdownItem> */}
            {/* <DropdownItem onClick={() => offerFunctionAction()}>
                <i className="mdi mdi-file-document-multiple-outline align-bottom me-2 text-muted"></i>{" "}
                Ponuda / Print
              </DropdownItem> */}
            {/* <DropdownItem href="/#">
                <i className="mdi mdi-printer-outline align-bottom me-2 text-muted"></i>{" "}
                Print za izlog
              </DropdownItem>
              <DropdownItem href="/#">
                <i className="mdi mdi-account-switch-outline align-bottom me-2 text-muted"></i>{" "}
                Promjena agenta
              </DropdownItem>
              <DropdownItem href="/#">
                <i className="mdi mdi-export align-bottom me-2 text-muted"></i>{" "}
                Export u CSV
              </DropdownItem>
              <DropdownItem divider />*/}
            {/* </DropdownMenu> */}
            {/* </UncontrolledDropdown>  */}
            <div style={{ width: '60px' }} />
            {/* <div className="hstack gap-2 justify-content-end d-print-none">
            <Button tag={Link} to="real-estates/0/general" color="warning" className="btn-label">
              <i className="mdi mdi-plus align-bottom label-icon align-middle fs-16 me-2"></i>
              Vaše pretrage
            </Button>
            <Button tag={Link} to="real-estates/0/general" color="success" className="btn-label">
              <i className="mdi mdi-content-save-outline align-bottom label-icon align-middle fs-16 me-2"></i>
              Spremi pretragu
            </Button>
            <Button tag={Link} to="real-estates/0/general" color="favorite" className="btn-label">
              <i className="mdi mdi-heart-outline align-bottom label-icon align-middle fs-16 me-2"></i>
              Favoriti
            </Button>
          </div> */}
        </div>
    )

    return (
        <>
            <DeleteModal show={deleteModal} onDeleteClick={() => handleDeleteInvoice()} onCloseClick={() => setDeleteModal(false)} />

            <TableContainer
                hasColumnSelection={true}
                columns={columns}
                tableActions={tableActions}
                data={properties || []}
                isGlobalFilter={false}
                isAddUserList={false}
                isLoading={fetching}
                customPageSize={20}
                hasPageSizeSelect={true}
                tableClass="dmn-table--property-documents"
                handleDataFetch={handleDataFetch}
                theadClass={'dmn-table-header'}
                isRealEstatesListFilter={true}
                totalCount={totalCount}
                pageCount={pageCount}
                handleRowClick={handleRowClick}
                tableHash={appModule}
            />
        </>
    )
}

export default RelatedPropertiesViewTab
