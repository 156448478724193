import { createAction } from 'redux-actions';

const ENTITY = 'MODAL';
export const stateKey = ENTITY.toLowerCase();

export const showModal = createAction(
    `${ENTITY}/SHOW`,
    values => values,
    (values, callback) => ({ callback })
);

export const showModalRequest = createAction(`${ENTITY}/FETCH_REQUEST`);
export const showModalSuccess = createAction(`${ENTITY}/FETCH_SUCCESS`);
export const showModalFailure = createAction(`${ENTITY}/FETCH_FAILURE`);

export const hideModal = createAction(
    `${ENTITY}/HIDE`,
    values => values,
    (values, callback) => ({ callback })
);

