import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

export const useProjectFormTabs = (isEdit) => {
    const [tabs, setTabs] = useState([])
    const { t } = useTranslation()

    useEffect(() => {
        setTabs([
            { id: 1, name: 'general', label: t('app.common.generalInfo') },
            { id: 2, name: 'description', label: t('app.common.description') },
            { id: 3, name: 'map', label: t('form.common.tab.map'), disabled: !isEdit },
            {
                id: 4,
                name: 'materials',
                label: t('form.common.tab.materials'),
                disabled: !isEdit,
                children: [
                    { id: '4-1', name: 'photos', label: t('app.common.photos') },
                    { id: '4-2', name: 'groundPlans', label: t('app.common.groundPlans') },
                    { id: '4-3', name: '3dVideo', label: t('form.common.tab.3dVideo') },
                ],
            },
            { id: 6, name: 'documents', label: t('app.common.documents'), disabled: !isEdit },
        ])
    }, [isEdit, t])
    return tabs
}
