import classNames from 'classnames'
import { useFormik } from 'formik'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Alert, Button, Card, CardBody, Col, Container, Form, FormFeedback, Input, Label, Row, Spinner } from 'reactstrap'
import { useDebouncedCallback } from 'use-debounce'
import * as Yup from 'yup'
import logoLight from '../../assets/images/dimedia-crm-logo-white.png'
import { PASSWORD_REGEX } from '../../common/constants'
import { selectErrorMessage, selectIsFetching, selectIsRegistered } from '../../store/auth'
import { errorMessageClear, registerUser, registerUserClear } from '../../store/auth/actions'
import ObscurePassword from './ObscurePassword'
import ParticlesAuth from './ParticlesAuth'
import { generateRandomPassword } from './utils'

const Register = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const [showPasswordSuggestion, setShowPasswordSuggestion] = useState(false)
    const [suggestedPassword, setSuggestedPassword] = useState('')
    const [passwordFormatError, setPasswordFormatError] = useState(false)
    const [obscurePassword, setObscurePassword] = useState(true)

    const passwordRegex = useMemo(() => PASSWORD_REGEX, [])

    const generatePasswordFormatError = ({ password }) => {
        const errors = {}
        const isValidPassword = passwordRegex.test(password)

        if (!isValidPassword) {
            errors.format = t('form.register.error.passwordFormat')
        }

        return errors
    }

    const formik = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            firstName: '',
            lastName: '',
            primaryEmail: '',
            agencyId: '',
            password: '',
        },
        validationSchema: Yup.object({
            firstName: Yup.string().required(t('form.register.error.firstName')),
            lastName: Yup.string().required(t('form.register.error.lastName')),
            primaryEmail: Yup.string().required(t('app.common.email.error')).email(t('form.register.error.invalidEmail')),
            agencyId: Yup.string().required(t('form.register.error.agencyId')),
            password: Yup.string().required(t('app.common.password.error')),
        }),
        validate: generatePasswordFormatError,
        onSubmit: (values) => {
            dispatch(registerUser(values))
        },
    })

    const { isRegistered, errorMessage, isLoading } = useSelector((state) => {
        return {
            isRegistered: selectIsRegistered(state),
            errorMessage: selectErrorMessage(state),
            isLoading: selectIsFetching(state),
        }
    })

    const handleShowPasswordSuggestion = (e) => {
        const value = e.target.value

        formik.handleChange(e)

        if (!suggestedPassword) {
            const password = generateRandomPassword(passwordRegex)
            setSuggestedPassword(password)
        }

        setShowPasswordSuggestion(value.length > 0 && value.length <= 2)
    }

    const handleGenerateNewPassword = () => {
        const password = generateRandomPassword(passwordRegex)
        setSuggestedPassword(password)
    }

    const acceptPasswordSuggestion = () => {
        formik.setFieldValue('password', suggestedPassword)
        setShowPasswordSuggestion(false)
    }

    // avoid pinging store on every keystroke
    const debouncedDispatch = useDebouncedCallback(() => dispatch(registerUserClear()), 1000)

    // clear registered user on every new registration attempt
    const handleClearRegisteredUser = (e) => {
        formik.handleChange(e)
        debouncedDispatch()
    }

    useEffect(() => {
        dispatch(registerUserClear())
    }, [dispatch])

    useEffect(() => {
        const formikPassword = formik.values.password
        const isPasswordValid = passwordRegex.test(formikPassword)

        if (!isPasswordValid && formikPassword.length) {
            setPasswordFormatError(true)
        } else {
            setPasswordFormatError(false)
        }
    }, [formik.values.password, passwordRegex])

    const { resetForm } = formik

    useEffect(() => {
        if (isRegistered) {
            resetForm()
            dispatch(errorMessageClear())
        }
    }, [isRegistered, dispatch, resetForm])

    const formSubtitleClasses = classNames({ 'd-none': isRegistered || errorMessage, 'd-block text-muted': !isRegistered })
    const formFieldsClasses = classNames({ 'd-none': isRegistered || errorMessage, 'd-block': !isRegistered })
    const formLinkClasses = classNames('text-decoration-underline', { 'text-white': isRegistered || errorMessage, 'text-primary': !isRegistered })

    return (
        <React.Fragment>
            <ParticlesAuth>
                <div className="auth-page-content">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center mt-sm-5 mb-4 text-white-50">
                                    <div>
                                        <Link to="/" className="d-inline-block auth-logo">
                                            <img src={logoLight} alt="" />
                                        </Link>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="mt-4 mt-sm-2">
                                    <CardBody className="p-4">
                                        <div className="text-center mt-2">
                                            <h5 className="text-primary">{t('app.common.register')}</h5>
                                            <p className={formSubtitleClasses}>{t('form.register.message')}</p>
                                        </div>
                                        <div className="p-2 mt-4">
                                            <Form
                                                onSubmit={(e) => {
                                                    e.preventDefault()
                                                    formik.handleSubmit()
                                                    return false
                                                }}
                                                className="needs-validation"
                                                noValidate
                                            >
                                                {isRegistered ? (
                                                    <Alert color="success" className="text-center">
                                                        {t('form.register.success.message')}
                                                    </Alert>
                                                ) : null}

                                                {errorMessage ? (
                                                    <Alert color="success" className="text-center">
                                                        {t('form.register.success.message')}
                                                    </Alert>
                                                ) : null}

                                                {passwordFormatError ? (
                                                    <Alert color="danger" className="text-center">
                                                        {formik.errors.format}
                                                    </Alert>
                                                ) : null}

                                                <div className={formFieldsClasses}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="firstName" className="form-label">
                                                            {t('app.common.firstName')}
                                                        </Label>
                                                        <Input
                                                            name="firstName"
                                                            type="text"
                                                            placeholder={t('form.register.placeholder.firstName')}
                                                            onChange={handleClearRegisteredUser}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.firstName || ''}
                                                            invalid={formik.touched.firstName && formik.errors.firstName ? true : false}
                                                        />
                                                        {formik.touched.firstName && formik.errors.firstName ? (
                                                            <FormFeedback type="invalid">
                                                                <div>{formik.errors.firstName}</div>
                                                            </FormFeedback>
                                                        ) : null}
                                                    </div>

                                                    <div className="mb-3">
                                                        <Label htmlFor="lastName" className="form-label">
                                                            {t('app.common.lastName')}
                                                        </Label>
                                                        <Input
                                                            name="lastName"
                                                            type="text"
                                                            placeholder={t('form.register.placeholder.lastName')}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.lastName || ''}
                                                            invalid={formik.touched.lastName && formik.errors.lastName ? true : false}
                                                        />
                                                        {formik.touched.lastName && formik.errors.lastName ? (
                                                            <FormFeedback type="invalid">
                                                                <div>{formik.errors.lastName}</div>
                                                            </FormFeedback>
                                                        ) : null}
                                                    </div>

                                                    <div className="mb-3">
                                                        <Label htmlFor="email" className="form-label">
                                                            {t('app.common.email.label')}
                                                        </Label>
                                                        <Input
                                                            id="email"
                                                            name="primaryEmail"
                                                            className="form-control"
                                                            placeholder={t('app.common.email.placeholder')}
                                                            type="email"
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.primaryEmail || ''}
                                                            invalid={formik.touched.primaryEmail && formik.errors.primaryEmail ? true : false}
                                                        />
                                                        {formik.touched.primaryEmail && formik.errors.primaryEmail ? (
                                                            <FormFeedback type="invalid">
                                                                <div>{formik.errors.primaryEmail}</div>
                                                            </FormFeedback>
                                                        ) : null}
                                                    </div>

                                                    <div className="mb-3">
                                                        <Label htmlFor="agencyId" className="form-label">
                                                            {t('app.common.agencyId')}
                                                        </Label>
                                                        <Input
                                                            name="agencyId"
                                                            type="text"
                                                            placeholder={t('form.register.placeholder.agencyId')}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.agencyId || ''}
                                                            invalid={formik.touched.agencyId && formik.errors.agencyId ? true : false}
                                                        />
                                                        {formik.touched.agencyId && formik.errors.agencyId ? (
                                                            <FormFeedback type="invalid">
                                                                <div>{formik.errors.agencyId}</div>
                                                            </FormFeedback>
                                                        ) : null}
                                                    </div>

                                                    <div className="mb-3">
                                                        <Label htmlFor="password" className="form-label">
                                                            {t('app.common.password')}
                                                        </Label>
                                                        <div className="position-relative auth-pass-inputgroup mb-3">
                                                            <Input
                                                                name="password"
                                                                type={obscurePassword ? 'password' : 'text'}
                                                                placeholder={t('app.common.password.placeholder')}
                                                                onChange={handleShowPasswordSuggestion}
                                                                onBlur={formik.handleBlur}
                                                                value={formik.values.password || ''}
                                                                invalid={formik.touched.password && formik.errors.password ? true : false}
                                                            />
                                                            {showPasswordSuggestion ? (
                                                                <div className="form-control d-flex cursor-pointer">
                                                                    <Button color="none" onClick={acceptPasswordSuggestion} className="w-50 m-0 p-0">
                                                                        {suggestedPassword}
                                                                    </Button>
                                                                    <Button
                                                                        color="none"
                                                                        onClick={handleGenerateNewPassword}
                                                                        className="w-50 m-0 p-0 text-muted"
                                                                    >
                                                                        {t('button.generateAgain')}
                                                                    </Button>
                                                                </div>
                                                            ) : null}

                                                            {formik.touched.password && formik.errors.password ? (
                                                                <FormFeedback type="invalid">
                                                                    <div>{formik.errors.password}</div>
                                                                </FormFeedback>
                                                            ) : null}

                                                            <ObscurePassword
                                                                errors={formik.errors}
                                                                obscurePassword={obscurePassword}
                                                                setObscurePassword={setObscurePassword}
                                                            />
                                                        </div>
                                                    </div>

                                                    {/* TODO: terms & conditions */}
                                                    {/* <div className="mb-4">
                                                    <p className="mb-0 fs-12 text-muted fst-italic">By registering you agree to the Velzon
                                                        <Link to="#" className="text-primary text-decoration-underline fst-normal fw-medium">Terms of Use</Link></p>
                                                     </div> */}

                                                    <div className="mt-4">
                                                        <button className="btn btn-success w-100" type="submit">
                                                            {isLoading ? <Spinner size="sm" /> : t('button.register')}
                                                        </button>
                                                    </div>
                                                </div>
                                            </Form>
                                        </div>
                                    </CardBody>
                                </Card>
                                <div className="mt-4 text-center">
                                    <p className="mb-0">
                                        <Link to="/login" className={formLinkClasses}>
                                            {t('app.common.login')}
                                        </Link>
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </ParticlesAuth>
        </React.Fragment>
    )
}

export default Register
