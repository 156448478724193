import i18n from '../../../i18n'
import { renderCurrency, renderCurrencyWithUnit } from '../../../utils/property'
import { PROPERTY_STATUS, REAL_ESTATE_SERVICE } from '../../constants'
import requestFields from '../../constants/requestFields'
import {
    propertyDetailSearchFields,
    propertyOfferPrintFields,
    propertyTransactionsFields,
    realEstateDescriptionFields,
    realEstateGeneralFields,
    realEstateMaterials3DVideoFields,
} from './fields'
import {
    propertyDetailSearhFieldTypes,
    propertyOfferPrintFieldTypes,
    propertyTransactionsFieldTypes,
    realEstateDescriptionFieldTypes,
    realEstateGeneralFieldTypes,
    realEstaterealEstateMaterials3DVideoFieldTypes,
} from './fieldTypes'
import { IS_ERSTE } from '../../constants'

const generateSharedInputs = (generalFieldTypes, isSale = true, onlySideForm = false) => {
    const vatField = isSale ? 'VAT_INCLUDED' : 'VAT_NOT_INCLUDED'

    const sideForm = [
        [
            generalFieldTypes[realEstateGeneralFields.ID],
            generalFieldTypes[realEstateGeneralFields.INTERNAL_ID],
            generalFieldTypes[realEstateGeneralFields.PROPERTY_SERVICE_ID],
            generalFieldTypes[realEstateGeneralFields.PROPERTY_TYPE_ID],
            generalFieldTypes[realEstateGeneralFields.PROPERTY_SUB_TYPE_ID],
            IS_ERSTE && generalFieldTypes[realEstateGeneralFields.PROPERTY_SET_ID], 
            generalFieldTypes[realEstateGeneralFields.PROPERTY_RECEIPT_DATE],
            generalFieldTypes[realEstateGeneralFields.CONTRACT_NO],
            generalFieldTypes[realEstateGeneralFields.CONTRACT_DATE],
        ],
        [
            {
                type: 'fieldArray',
                name: realEstateGeneralFields.OWNER_ID,
                children: [generalFieldTypes[realEstateGeneralFields.OWNER_ID]],
            },
        ],
        [generalFieldTypes[requestFields.PROPERTY_SUPPLY_SOURCE_ID], generalFieldTypes[realEstateGeneralFields.PROJECT_ID]],
    ]

    if (onlySideForm) {
        return {
            sideForm,
        }
    }

    return {
        sideForm,
        mainForm: [
            {
                type: 'array',
                name: 'locationGroup',
                children: [
                    generalFieldTypes[realEstateGeneralFields.COUNTRY_ID],
                    generalFieldTypes[realEstateGeneralFields.COUNTY_ID],
                    generalFieldTypes[realEstateGeneralFields.CITY_ID],
                    generalFieldTypes[realEstateGeneralFields.WIDER_AREA_ID],
                    generalFieldTypes[realEstateGeneralFields.QUARTER_ID],
                    generalFieldTypes[realEstateGeneralFields.ISLAND_ID],
                    generalFieldTypes[realEstateGeneralFields.STREET],
                    generalFieldTypes[realEstateGeneralFields.STREET_NUMBER],
                    generalFieldTypes[realEstateGeneralFields.STREET_SYNC],
                ],
            },
            {
                type: 'array',
                name: 'priceGroup',
                children: [
                    generalFieldTypes[realEstateGeneralFields.PRICE],
                    generalFieldTypes[realEstateGeneralFields.PRICE_CLIENT],
                    generalFieldTypes[realEstateGeneralFields.PRICE_M2],
                    generalFieldTypes[realEstateGeneralFields.PRICE_OLD],
                    generalFieldTypes[realEstateGeneralFields[vatField]],
                    generalFieldTypes[realEstateGeneralFields.PRICE_ON_REQUEST],
                ],
            },
            {
                type: 'array',
                name: 'generalInfoGroup',
                children: [],
            },
            {
                type: 'array',
                name: 'distanceGroup',
                children: [
                    generalFieldTypes[realEstateGeneralFields.DISTANCE_SEA],
                    generalFieldTypes[realEstateGeneralFields.DISTANCE_RIVER],
                    generalFieldTypes[realEstateGeneralFields.DISTANCE_BEACH],
                    generalFieldTypes[realEstateGeneralFields.DISTANCE_CITY_CENTRE],
                    generalFieldTypes[realEstateGeneralFields.DISTANCE_SCHOOL],
                    generalFieldTypes[realEstateGeneralFields.DISTANCE_PUBLIC_TRANSPORTATION],
                ],
            },
            {
                type: 'array',
                name: 'specialFeaturesAndOtherGroup',
                children: [
                    generalFieldTypes[realEstateGeneralFields.PROPERTY_SPECIAL_FEATURE_ID],
                    generalFieldTypes[realEstateGeneralFields.PROPERTY_DESCRIPTION],
                    generalFieldTypes[realEstateGeneralFields.PROPERTY_UTILITY_ID],
                    generalFieldTypes[realEstateGeneralFields.PROPERTY_EQUIPMENT_ID],
                ],
            },
            {
                type: 'array',
                name: 'commissionGroup',
                children: [generalFieldTypes[realEstateGeneralFields.AGENCY_COMMISSION_ID]],
            },
            {
                type: 'array',
                name: 'agentGroup',
                children: [
                    generalFieldTypes[realEstateGeneralFields.AGENT_ID],
                    {
                        ...generalFieldTypes[realEstateGeneralFields.AGENT_ID],
                        name: `${realEstateGeneralFields.AGENT_ID}_secondary`,
                        required: false,
                    },
                ],
            },
        ],
    }
}

const propertySpaceInput = (inputs) => ({
    type: 'select-with-inputs',
    name: 'propertyPropertySpace_group',
    label: inputs[realEstateGeneralFields.PROPERTY_SPACE_ID].label,
    tooltip: inputs[realEstateGeneralFields.PROPERTY_SPACE_ID].tooltip,
    selectOptionsName: realEstateGeneralFields.PROPERTY_SPACE_ID,
    inputs: [
        inputs[realEstateGeneralFields.PROPERTY_SPACE_ID],
        inputs[realEstateGeneralFields.SPACE_COUNT],
        inputs[realEstateGeneralFields.SPACE_AREA],
    ],
})

const maintenceCostsInput = (inputs, appSettings, isGarageOrOfficeSpace) => ({
    type: 'grouped',
    name: 'maintenceCostsGrouped',
    label: (
        <>
            {isGarageOrOfficeSpace ? `${i18n.t('view.property.price.maintenanceCost')}/` : ''}
            {i18n.t('view.property.price.provision')} {renderCurrency(appSettings.currency)} {i18n.t('app.common.or')} {renderCurrencyWithUnit(appSettings?.currency, appSettings?.areaUnit)}
        </>
    ),
    tooltip: i18n.t('form.property.maintenceCosts.tooltip'),
    colProps: {
        sm: 12,
        xxl: 4,
    },
    items: [inputs[realEstateGeneralFields.MAINTENCE_COSTS], inputs[realEstateGeneralFields.MAINTENCE_COSTS_TYPE]],
})

const commonMaintenceCostsInput = (inputs, appSettings) => ({
    type: 'grouped',
    name: 'commonMaintenceCostsGrouped',
    label: (
        <>
            {i18n.t('view.property.price.maintenanceCost')} {renderCurrency(appSettings.currency)} {i18n.t('app.common.or')}{' '}
            {renderCurrencyWithUnit(appSettings?.currency, appSettings?.areaUnit)}
        </>
    ),
    colProps: {
        sm: 12,
        xxl: 4,
    },
    items: [inputs[realEstateGeneralFields.COMMON_MAINTENCE_COSTS], inputs[realEstateGeneralFields.COMMON_MAINTENCE_COSTS_TYPE]],
})

const commonUtilityCostsInput = (inputs, appSettings) => ({
    type: 'grouped',
    name: 'commonUtilityCostsGrouped',
    label: (
        <>
            {i18n.t('form.property.commonUtilityCosts')} {renderCurrency(appSettings.currency)} {i18n.t('app.common.or')}{' '}
            {renderCurrencyWithUnit(appSettings?.currency, appSettings?.areaUnit)}
        </>
    ),
    tooltip: i18n.t('form.property.commonUtilityCosts.tooltip'),
    colProps: {
        sm: 12,
        xxl: 4,
    },
    items: [inputs[realEstateGeneralFields.COMMON_UTILITY_COSTS], inputs[realEstateGeneralFields.COMMON_UTILITY_COSTS_TYPE]],
})

const realEstateGeneralInputs = (isEdit, appSettings) => {
    const generalFieldTypes = realEstateGeneralFieldTypes(isEdit, appSettings)
    const sharedInputs = generateSharedInputs(generalFieldTypes, undefined, true)

    return {
        sideForm: [...sharedInputs.sideForm],
        mainForm: [],
    }
}

const realEstateGeneralFlatSaleInputs = (isEdit, appSettings) => {
    const generalFieldTypes = realEstateGeneralFieldTypes(isEdit, appSettings)
    const sharedInputs = generateSharedInputs(generalFieldTypes)

    const locationGroupIndex = sharedInputs.mainForm?.findIndex((x) => x.name === 'locationGroup')

    if (locationGroupIndex !== -1) {
        sharedInputs.mainForm[locationGroupIndex].children.push(generalFieldTypes[realEstateGeneralFields.PROPERTY_LABEL])
    }

    const maintenceCostsGroupInputs = {
        type: 'array',
        name: 'maintenceCostsGroup',
        children: [maintenceCostsInput(generalFieldTypes, appSettings)],
    }
    const mortageGroupInputs = {
        type: 'array',
        name: 'otherPriceGroup',
        children: [generalFieldTypes[realEstateGeneralFields.MORTGAGE_INFO]],
    }
    const priceGroupIndex = sharedInputs.mainForm?.findIndex((x) => x.name === 'priceGroup')
    if (priceGroupIndex !== -1) {
        sharedInputs.mainForm[priceGroupIndex].children.splice(1, 0, generalFieldTypes[realEstateGeneralFields.PRICE_INITIAL]).join()
        sharedInputs.mainForm[priceGroupIndex].children.push(generalFieldTypes[realEstateGeneralFields.EXCHANGE_POSSIBLE])
        sharedInputs.mainForm?.splice(priceGroupIndex + 1, 0, maintenceCostsGroupInputs).join()
        sharedInputs.mainForm?.splice(priceGroupIndex + 2, 0, mortageGroupInputs).join()
    }

    const generalInfoIndex = sharedInputs.mainForm?.findIndex((obj) => obj.name === 'generalInfoGroup')
    if (generalInfoIndex > -1) {
        
        const propertyFloorIdInput = generalFieldTypes[realEstateGeneralFields.PROPERTY_FLOOR_ID]
        propertyFloorIdInput.required = true
        const propertyFloorPosition = generalFieldTypes[realEstateGeneralFields.PROPERTY_FLOOR_POSITION_ID]
        propertyFloorPosition.showRequired = true
        const floorNo = generalFieldTypes[realEstateGeneralFields.FLOOR_NO]
        floorNo.showRequired = true

        const generalInfoInputs = [
            generalFieldTypes[realEstateGeneralFields.AREA],
            generalFieldTypes[realEstateGeneralFields.AREA_NETT],
            generalFieldTypes[realEstateGeneralFields.AREA_GARDEN],
            generalFieldTypes[realEstateGeneralFields.PROPERTY_ROOMS_NO_ID],
            IS_ERSTE && generalFieldTypes[realEstateGeneralFields.PROPERTY_ROOMS_TYPE_ID],
            generalFieldTypes[realEstateGeneralFields.BEDROOMS_NO],
            generalFieldTypes[realEstateGeneralFields.BATHROOM_TOILET_NO],
            generalFieldTypes[realEstateGeneralFields.BATHROOM_NO],
            generalFieldTypes[realEstateGeneralFields.TOILET_NO],
            generalFieldTypes[realEstateGeneralFields.CEILING_HEIGHT],
            generalFieldTypes[realEstateGeneralFields.CONSTRUCTION_YEAR],
            generalFieldTypes[realEstateGeneralFields.ADAPTATION_YEAR],
            propertyFloorIdInput,
            generalFieldTypes[realEstateGeneralFields.EMPTY],
            generalFieldTypes[realEstateGeneralFields.PROPERTY_FLOOR_POSITION_ID],
            floorNo,
            generalFieldTypes[realEstateGeneralFields.FLOORS_TOTAL_NO],
            generalFieldTypes[realEstateGeneralFields.PROPERTY_ORIENTATION_ID],
            generalFieldTypes[realEstateGeneralFields.PROPERTY_HEATING_ID],
            generalFieldTypes[realEstateGeneralFields.PROPERTY_CONDITION_ID],
            generalFieldTypes[realEstateGeneralFields.PROPERTY_WINDOWS_DOORS_ID],
            generalFieldTypes[realEstateGeneralFields.PROPERTY_PUBLIC_TRANSPORTATION_ID],
        ]
        sharedInputs.mainForm[generalInfoIndex].children = generalInfoInputs
    }

    const specialFeaturesAndOtherGrouppIndex = sharedInputs.mainForm?.findIndex((obj) => obj.name === 'specialFeaturesAndOtherGroup')
    if (specialFeaturesAndOtherGrouppIndex !== -1) {
        const specialFieldsFlatInputs = [
            propertySpaceInput(generalFieldTypes),
            generalFieldTypes[realEstateGeneralFields.PROPERTY_ENERGY_EFFICIENCY_ID],
            generalFieldTypes[realEstateGeneralFields.PROPERTY_PERMIT_ID],
        ]
        sharedInputs.mainForm[specialFeaturesAndOtherGrouppIndex].children = [
            ...sharedInputs.mainForm[specialFeaturesAndOtherGrouppIndex].children,
            ...specialFieldsFlatInputs,
        ]
    }

    return {
        sideForm: [...sharedInputs.sideForm],
        mainForm: [...sharedInputs.mainForm],
    }
}

const realEstateGeneralFlatLeaseInputs = (isEdit, appSettings) => {
    const generalFieldTypes = realEstateGeneralFieldTypes(isEdit, appSettings)
    const sharedInputs = generateSharedInputs(generalFieldTypes, false)

    let supplySourceGroupIndex = sharedInputs.sideForm.findIndex((x) => x.some((o) => o.name === requestFields.PROPERTY_SUPPLY_SOURCE_ID))

    if (supplySourceGroupIndex !== -1) {
        sharedInputs.sideForm?.[supplySourceGroupIndex].unshift(generalFieldTypes[realEstateGeneralFields.AVAILABLE_FROM_DATE])
    }
    
    const locationGroupIndex = sharedInputs.mainForm?.findIndex((x) => x.name === 'locationGroup')

    if (locationGroupIndex !== -1) {
        sharedInputs.mainForm[locationGroupIndex].children.push(generalFieldTypes[realEstateGeneralFields.PROPERTY_LABEL])
    }

    const maintenceCostsGroupInputs = {
        type: 'array',
        name: 'reserveCommonCostsGroup',
        children: [
            generalFieldTypes[realEstateGeneralFields.UTILITIES_INCLUDED],
            maintenceCostsInput(generalFieldTypes, appSettings),
            commonUtilityCostsInput(generalFieldTypes, appSettings),
            generalFieldTypes[realEstateGeneralFields.PARKING_SPACE_PRICE],
        ],
    }
    const priceGroupIndex = sharedInputs.mainForm?.findIndex((x) => x.name === 'priceGroup')

    if (priceGroupIndex !== -1) {
        sharedInputs.mainForm?.splice(priceGroupIndex + 1, 0, maintenceCostsGroupInputs).join()
    }

    const generalInfoIndex = sharedInputs.mainForm?.findIndex((obj) => obj.name === 'generalInfoGroup')
    if (generalInfoIndex > -1) {
        const generalInfoInputs = [
            generalFieldTypes[realEstateGeneralFields.AREA],
            generalFieldTypes[realEstateGeneralFields.AREA_NETT],
            generalFieldTypes[realEstateGeneralFields.AREA_GARDEN],
            generalFieldTypes[realEstateGeneralFields.PROPERTY_ROOMS_NO_ID],
            IS_ERSTE && generalFieldTypes[realEstateGeneralFields.PROPERTY_ROOMS_TYPE_ID],
            generalFieldTypes[realEstateGeneralFields.BEDROOMS_NO],
            generalFieldTypes[realEstateGeneralFields.BATHROOM_TOILET_NO],
            generalFieldTypes[realEstateGeneralFields.BATHROOM_NO],
            generalFieldTypes[realEstateGeneralFields.TOILET_NO],
            generalFieldTypes[realEstateGeneralFields.CEILING_HEIGHT],
            generalFieldTypes[realEstateGeneralFields.CONSTRUCTION_YEAR],
            generalFieldTypes[realEstateGeneralFields.ADAPTATION_YEAR],
            generalFieldTypes[realEstateGeneralFields.PROPERTY_FLOOR_ID],
            generalFieldTypes[realEstateGeneralFields.EMPTY],
            generalFieldTypes[realEstateGeneralFields.PROPERTY_FLOOR_POSITION_ID],
            generalFieldTypes[realEstateGeneralFields.FLOOR_NO],
            generalFieldTypes[realEstateGeneralFields.FLOORS_TOTAL_NO],
            generalFieldTypes[realEstateGeneralFields.PROPERTY_ORIENTATION_ID],
            generalFieldTypes[realEstateGeneralFields.PROPERTY_HEATING_ID],
            generalFieldTypes[realEstateGeneralFields.PROPERTY_CONDITION_ID],
            generalFieldTypes[realEstateGeneralFields.PROPERTY_WINDOWS_DOORS_ID],
            generalFieldTypes[realEstateGeneralFields.PROPERTY_PUBLIC_TRANSPORTATION_ID],
        ]
        sharedInputs.mainForm[generalInfoIndex].children = generalInfoInputs
    }

    const specialFeaturesAndOtherGrouppIndex = sharedInputs.mainForm?.findIndex((obj) => obj.name === 'specialFeaturesAndOtherGroup')
    if (specialFeaturesAndOtherGrouppIndex !== -1) {
        const specialFieldsFlatInputs = [
            propertySpaceInput(generalFieldTypes),
            generalFieldTypes[realEstateGeneralFields.PROPERTY_ENERGY_EFFICIENCY_ID],
            generalFieldTypes[realEstateGeneralFields.PROPERTY_PERMIT_ID],
        ]
        sharedInputs.mainForm[specialFeaturesAndOtherGrouppIndex].children = [
            ...sharedInputs.mainForm[specialFeaturesAndOtherGrouppIndex].children,
            ...specialFieldsFlatInputs,
        ]
    }

    return {
        sideForm: [...sharedInputs.sideForm],
        mainForm: [...sharedInputs.mainForm],
    }
}

const realEstateGeneralHouseSaleInputs = (isEdit, appSettings) => {
    const generalFieldTypes = realEstateGeneralFieldTypes(isEdit, appSettings)
    const sharedInputs = generateSharedInputs(generalFieldTypes)

    const mortageGroupInputs = {
        type: 'array',
        name: 'otherPriceGroup',
        children: [generalFieldTypes[realEstateGeneralFields.MORTGAGE_INFO]],
    }
    const priceGroupIndex = sharedInputs.mainForm?.findIndex((x) => x.name === 'priceGroup')
    if (priceGroupIndex !== -1) {
        sharedInputs.mainForm[priceGroupIndex].children.splice(1, 0, generalFieldTypes[realEstateGeneralFields.PRICE_INITIAL]).join()
        sharedInputs.mainForm[priceGroupIndex].children.push(generalFieldTypes[realEstateGeneralFields.EXCHANGE_POSSIBLE])
        sharedInputs.mainForm?.splice(priceGroupIndex + 1, 0, mortageGroupInputs).join()
    }

    const generalInfoIndex = sharedInputs.mainForm?.findIndex((obj) => obj.name === 'generalInfoGroup')
    if (generalInfoIndex > -1) {
        const propertyFloorIdInput = generalFieldTypes[realEstateGeneralFields.PROPERTY_FLOOR_ID]
        propertyFloorIdInput.required = true
        const generalInfoInputs = [
            generalFieldTypes[realEstateGeneralFields.PROPERTY_HOUSE_TYPE_ID],
            generalFieldTypes[realEstateGeneralFields.EMPTY],
            generalFieldTypes[realEstateGeneralFields.AREA],
            generalFieldTypes[realEstateGeneralFields.AREA_NETT],
            generalFieldTypes[realEstateGeneralFields.EMPTY],
            generalFieldTypes[realEstateGeneralFields.AREA_GARDEN],
            generalFieldTypes[realEstateGeneralFields.PLOT_AREA],
            generalFieldTypes[realEstateGeneralFields.EMPTY],
            generalFieldTypes[realEstateGeneralFields.ROOMS_NO],
            generalFieldTypes[realEstateGeneralFields.BEDROOMS_NO],
            generalFieldTypes[realEstateGeneralFields.HOUSING_UNITS_NUMBER],
            generalFieldTypes[realEstateGeneralFields.BED_NO],
            generalFieldTypes[realEstateGeneralFields.EMPTY],
            generalFieldTypes[realEstateGeneralFields.BATHROOM_TOILET_NO],
            generalFieldTypes[realEstateGeneralFields.BATHROOM_NO],
            generalFieldTypes[realEstateGeneralFields.TOILET_NO],
            generalFieldTypes[realEstateGeneralFields.CEILING_HEIGHT],
            generalFieldTypes[realEstateGeneralFields.CONSTRUCTION_YEAR],
            generalFieldTypes[realEstateGeneralFields.ADAPTATION_YEAR],
            propertyFloorIdInput,
            generalFieldTypes[realEstateGeneralFields.EMPTY],
            generalFieldTypes[realEstateGeneralFields.PROPERTY_ORIENTATION_ID],
            generalFieldTypes[realEstateGeneralFields.PROPERTY_HEATING_ID],
            generalFieldTypes[realEstateGeneralFields.PROPERTY_CONDITION_ID],
            generalFieldTypes[realEstateGeneralFields.PROPERTY_WINDOWS_DOORS_ID],
            generalFieldTypes[realEstateGeneralFields.PROPERTY_PUBLIC_TRANSPORTATION_ID],
        ]
        sharedInputs.mainForm[generalInfoIndex].children = generalInfoInputs
    }

    const specialFeaturesAndOtherGrouppIndex = sharedInputs.mainForm?.findIndex((obj) => obj.name === 'specialFeaturesAndOtherGroup')
    if (specialFeaturesAndOtherGrouppIndex !== -1) {
        const specialFieldsFlatInputs = [
            propertySpaceInput(generalFieldTypes),
            generalFieldTypes[realEstateGeneralFields.PROPERTY_ENERGY_EFFICIENCY_ID],
            generalFieldTypes[realEstateGeneralFields.PROPERTY_PERMIT_ID],
        ]
        sharedInputs.mainForm[specialFeaturesAndOtherGrouppIndex].children = [
            ...sharedInputs.mainForm[specialFeaturesAndOtherGrouppIndex].children,
            ...specialFieldsFlatInputs,
        ]
    }

    return {
        sideForm: [...sharedInputs.sideForm],
        mainForm: [...sharedInputs.mainForm],
    }
}

const realEstateGeneralHouseLeaseInputs = (isEdit, appSettings) => {
    const generalFieldTypes = realEstateGeneralFieldTypes(isEdit, appSettings)
    const sharedInputs = generateSharedInputs(generalFieldTypes, false)

    let supplySourceGroupIndex = sharedInputs.sideForm.findIndex((x) => x.some((o) => o.name === requestFields.PROPERTY_SUPPLY_SOURCE_ID))

    if (supplySourceGroupIndex !== -1) {
        sharedInputs.sideForm?.[supplySourceGroupIndex].unshift(generalFieldTypes[realEstateGeneralFields.AVAILABLE_FROM_DATE])
    }

    const priceGroupIndex = sharedInputs.mainForm?.findIndex((x) => x.name === 'priceGroup')
    if (priceGroupIndex !== -1) {
        sharedInputs.mainForm[priceGroupIndex].children.push(generalFieldTypes[realEstateGeneralFields.UTILITIES_INCLUDED])
    }

    const generalInfoIndex = sharedInputs.mainForm?.findIndex((obj) => obj.name === 'generalInfoGroup')
    if (generalInfoIndex > -1) {
        const generalInfoInputs = [
            generalFieldTypes[realEstateGeneralFields.PROPERTY_HOUSE_TYPE_ID],
            generalFieldTypes[realEstateGeneralFields.EMPTY],
            generalFieldTypes[realEstateGeneralFields.AREA],
            generalFieldTypes[realEstateGeneralFields.AREA_NETT],
            generalFieldTypes[realEstateGeneralFields.EMPTY],
            generalFieldTypes[realEstateGeneralFields.AREA_GARDEN],
            generalFieldTypes[realEstateGeneralFields.PLOT_AREA],
            generalFieldTypes[realEstateGeneralFields.EMPTY],
            generalFieldTypes[realEstateGeneralFields.ROOMS_NO],
            generalFieldTypes[realEstateGeneralFields.BEDROOMS_NO],
            generalFieldTypes[realEstateGeneralFields.HOUSING_UNITS_NUMBER],
            generalFieldTypes[realEstateGeneralFields.BED_NO],
            generalFieldTypes[realEstateGeneralFields.EMPTY],
            generalFieldTypes[realEstateGeneralFields.BATHROOM_TOILET_NO],
            generalFieldTypes[realEstateGeneralFields.BATHROOM_NO],
            generalFieldTypes[realEstateGeneralFields.TOILET_NO],
            generalFieldTypes[realEstateGeneralFields.CEILING_HEIGHT],
            generalFieldTypes[realEstateGeneralFields.CONSTRUCTION_YEAR],
            generalFieldTypes[realEstateGeneralFields.ADAPTATION_YEAR],
            generalFieldTypes[realEstateGeneralFields.PROPERTY_FLOOR_ID],
            generalFieldTypes[realEstateGeneralFields.EMPTY],
            generalFieldTypes[realEstateGeneralFields.PROPERTY_ORIENTATION_ID],
            generalFieldTypes[realEstateGeneralFields.PROPERTY_HEATING_ID],
            generalFieldTypes[realEstateGeneralFields.PROPERTY_CONDITION_ID],
            generalFieldTypes[realEstateGeneralFields.PROPERTY_WINDOWS_DOORS_ID],
            generalFieldTypes[realEstateGeneralFields.PROPERTY_PUBLIC_TRANSPORTATION_ID],
        ]
        sharedInputs.mainForm[generalInfoIndex].children = generalInfoInputs
    }

    const specialFeaturesAndOtherGrouppIndex = sharedInputs.mainForm?.findIndex((obj) => obj.name === 'specialFeaturesAndOtherGroup')
    if (specialFeaturesAndOtherGrouppIndex !== -1) {
        const specialFieldsFlatInputs = [
            propertySpaceInput(generalFieldTypes),
            generalFieldTypes[realEstateGeneralFields.PROPERTY_ENERGY_EFFICIENCY_ID],
            generalFieldTypes[realEstateGeneralFields.PROPERTY_PERMIT_ID],
        ]
        sharedInputs.mainForm[specialFeaturesAndOtherGrouppIndex].children = [
            ...sharedInputs.mainForm[specialFeaturesAndOtherGrouppIndex].children,
            ...specialFieldsFlatInputs,
        ]
    }

    return {
        sideForm: [...sharedInputs.sideForm],
        mainForm: [...sharedInputs.mainForm],
    }
}

const realEstateGeneralOfficeSpaceSaleInputs = (isEdit, appSettings) => {
    const generalFieldTypes = realEstateGeneralFieldTypes(isEdit, appSettings, true)
    const sharedInputs = generateSharedInputs(generalFieldTypes)

    sharedInputs.sideForm[0]
        .splice(
            sharedInputs.sideForm[0].findIndex((obj) => obj.name === realEstateGeneralFields.PROPERTY_SUB_TYPE_ID) + 1,
            0,
            generalFieldTypes[realEstateGeneralFields.PROPERTY_PRIMARY_USAGE_ID]
        )
        .join()

    const locationGroupIndex = sharedInputs.mainForm?.findIndex((x) => x.name === 'locationGroup')

    if (locationGroupIndex !== -1) {
        sharedInputs.mainForm[locationGroupIndex].children.push(generalFieldTypes[realEstateGeneralFields.PROPERTY_LABEL])
        sharedInputs.mainForm?.[locationGroupIndex].children.push(generalFieldTypes[realEstateGeneralFields.PROPERTY_POSITION_ID])
    }

    const maintenceCostsGroupInputs = {
        type: 'array',
        name: 'maintenceCostsGroup',
        children: [maintenceCostsInput(generalFieldTypes, appSettings, true)],
    }
    const mortageGroupInputs = {
        type: 'array',
        name: 'otherPriceGroup',
        children: [generalFieldTypes[realEstateGeneralFields.MORTGAGE_INFO]],
    }

    const priceGroupIndex = sharedInputs.mainForm?.findIndex((x) => x.name === 'priceGroup')

    if (priceGroupIndex !== -1) {
        const oldPriceIndex = sharedInputs.mainForm[priceGroupIndex].children.findIndex((x) => x.name === realEstateGeneralFields.PRICE_OLD)
        if (oldPriceIndex > -1) {
            sharedInputs.mainForm[priceGroupIndex].children[oldPriceIndex].required = false
        }
        sharedInputs.mainForm[priceGroupIndex].children.splice(1, 0, generalFieldTypes[realEstateGeneralFields.PRICE_INITIAL]).join()
        sharedInputs.mainForm[priceGroupIndex].children.push(generalFieldTypes[realEstateGeneralFields.EXCHANGE_POSSIBLE])
        sharedInputs.mainForm?.splice(priceGroupIndex + 1, 0, maintenceCostsGroupInputs).join()
        sharedInputs.mainForm?.splice(priceGroupIndex + 2, 0, mortageGroupInputs).join()
    }

    const generalInfoIndex = sharedInputs.mainForm?.findIndex((obj) => obj.name === 'generalInfoGroup')
    if (generalInfoIndex > -1) {
        const generalInfoInputs = [
            generalFieldTypes[realEstateGeneralFields.AREA],
            generalFieldTypes[realEstateGeneralFields.AREA_STORAGE],
            generalFieldTypes[realEstateGeneralFields.EMPTY],
            generalFieldTypes[realEstateGeneralFields.AREA_GARDEN],
            generalFieldTypes[realEstateGeneralFields.PLOT_AREA],
            generalFieldTypes[realEstateGeneralFields.EMPTY],
            generalFieldTypes[realEstateGeneralFields.ROOMS_NO],
            generalFieldTypes[realEstateGeneralFields.COMMON_BATHROOM_NO],
            generalFieldTypes[realEstateGeneralFields.TOILET_NO],
            generalFieldTypes[realEstateGeneralFields.CEILING_HEIGHT],
            generalFieldTypes[realEstateGeneralFields.CONSTRUCTION_YEAR],
            generalFieldTypes[realEstateGeneralFields.ADAPTATION_YEAR],
            generalFieldTypes[realEstateGeneralFields.PROPERTY_FLOOR_POSITION_ID],
            generalFieldTypes[realEstateGeneralFields.FLOOR_NO],
            generalFieldTypes[realEstateGeneralFields.FLOORS_TOTAL_NO],
            generalFieldTypes[realEstateGeneralFields.PARKING_SPACE_NUMBER],
            generalFieldTypes[realEstateGeneralFields.PROPERTY_ORIENTATION_ID],
            generalFieldTypes[realEstateGeneralFields.PROPERTY_HEATING_ID],
            generalFieldTypes[realEstateGeneralFields.PROPERTY_CONDITION_ID],
            generalFieldTypes[realEstateGeneralFields.PROPERTY_WINDOWS_DOORS_ID],
            generalFieldTypes[realEstateGeneralFields.PROPERTY_PUBLIC_TRANSPORTATION_ID],
        ]
        sharedInputs.mainForm[generalInfoIndex].children = generalInfoInputs
    }

    const specialFeaturesAndOtherGrouppIndex = sharedInputs.mainForm?.findIndex((obj) => obj.name === 'specialFeaturesAndOtherGroup')
    if (specialFeaturesAndOtherGrouppIndex !== -1) {
        const specialFieldsFlatInputs = [
            propertySpaceInput(generalFieldTypes),
            generalFieldTypes[realEstateGeneralFields.PROPERTY_ENERGY_EFFICIENCY_ID],
            generalFieldTypes[realEstateGeneralFields.PROPERTY_PERMIT_ID],
            generalFieldTypes[realEstateGeneralFields.PROPERTY_ACCESS_ID],
        ]
        sharedInputs.mainForm[specialFeaturesAndOtherGrouppIndex].children = [
            ...sharedInputs.mainForm[specialFeaturesAndOtherGrouppIndex].children,
            ...specialFieldsFlatInputs,
        ]
    }

    return {
        sideForm: [...sharedInputs.sideForm],
        mainForm: sharedInputs.mainForm,
    }
}

const realEstateGeneralOfficeSpaceLeaseInputs = (isEdit, appSettings) => {
    const generalFieldTypes = realEstateGeneralFieldTypes(isEdit, appSettings, true)
    const sharedInputs = generateSharedInputs(generalFieldTypes, false)

    sharedInputs.sideForm[0]
        .splice(
            sharedInputs.sideForm[0].findIndex((obj) => obj.name === realEstateGeneralFields.PROPERTY_SUB_TYPE_ID) + 1,
            0,
            generalFieldTypes[realEstateGeneralFields.PROPERTY_PRIMARY_USAGE_ID]
        )
        .join()

    let supplySourceGroupIndex = sharedInputs.sideForm.findIndex((x) => x.some((o) => o.name === requestFields.PROPERTY_SUPPLY_SOURCE_ID))

    if (supplySourceGroupIndex !== -1) {
        sharedInputs.sideForm?.[supplySourceGroupIndex].unshift(generalFieldTypes[realEstateGeneralFields.AVAILABLE_FROM_DATE])
    }

    const locationGroupIndex = sharedInputs.mainForm?.findIndex((x) => x.name === 'locationGroup')

    if (locationGroupIndex !== -1) {
        sharedInputs.mainForm[locationGroupIndex].children.push(generalFieldTypes[realEstateGeneralFields.PROPERTY_LABEL])
        sharedInputs.mainForm?.[locationGroupIndex].children.push(generalFieldTypes[realEstateGeneralFields.PROPERTY_POSITION_ID])
    }

    const maintenceCostsGroupInputs = {
        type: 'array',
        name: 'reserveCommonCostsGroup',
        children: [
            maintenceCostsInput(generalFieldTypes, appSettings, true),
            commonMaintenceCostsInput(generalFieldTypes, appSettings),
            commonUtilityCostsInput(generalFieldTypes, appSettings),
            generalFieldTypes[realEstateGeneralFields.PARKING_SPACE_PRICE],
            generalFieldTypes[realEstateGeneralFields.UTILITIES_INCLUDED],
        ],
    }
    const priceGroupIndex = sharedInputs.mainForm?.findIndex((x) => x.name === 'priceGroup')

    if (priceGroupIndex !== -1) {
        const oldPriceIndex = sharedInputs.mainForm[priceGroupIndex].children.findIndex((x) => x.name === realEstateGeneralFields.PRICE_OLD)
        if (oldPriceIndex > -1) {
            sharedInputs.mainForm[priceGroupIndex].children[oldPriceIndex].required = false
        }
        sharedInputs.mainForm?.splice(priceGroupIndex + 1, 0, maintenceCostsGroupInputs).join()
    }

    const generalInfoIndex = sharedInputs.mainForm?.findIndex((obj) => obj.name === 'generalInfoGroup')
    if (generalInfoIndex > -1) {
        const generalInfoInputs = [
            generalFieldTypes[realEstateGeneralFields.AREA],
            generalFieldTypes[realEstateGeneralFields.AREA_STORAGE],
            generalFieldTypes[realEstateGeneralFields.EMPTY],
            generalFieldTypes[realEstateGeneralFields.AREA_GARDEN],
            generalFieldTypes[realEstateGeneralFields.PLOT_AREA],
            generalFieldTypes[realEstateGeneralFields.EMPTY],
            generalFieldTypes[realEstateGeneralFields.ROOMS_NO],
            generalFieldTypes[realEstateGeneralFields.COMMON_BATHROOM_NO],
            generalFieldTypes[realEstateGeneralFields.TOILET_NO],
            generalFieldTypes[realEstateGeneralFields.CEILING_HEIGHT],
            generalFieldTypes[realEstateGeneralFields.CONSTRUCTION_YEAR],
            generalFieldTypes[realEstateGeneralFields.ADAPTATION_YEAR],
            generalFieldTypes[realEstateGeneralFields.PROPERTY_FLOOR_POSITION_ID],
            generalFieldTypes[realEstateGeneralFields.FLOOR_NO],
            generalFieldTypes[realEstateGeneralFields.FLOORS_TOTAL_NO],
            generalFieldTypes[realEstateGeneralFields.PARKING_SPACE_NUMBER],
            generalFieldTypes[realEstateGeneralFields.PROPERTY_ORIENTATION_ID],
            generalFieldTypes[realEstateGeneralFields.PROPERTY_HEATING_ID],
            generalFieldTypes[realEstateGeneralFields.PROPERTY_CONDITION_ID],
            generalFieldTypes[realEstateGeneralFields.PROPERTY_WINDOWS_DOORS_ID],
            generalFieldTypes[realEstateGeneralFields.PROPERTY_PUBLIC_TRANSPORTATION_ID],
        ]
        sharedInputs.mainForm[generalInfoIndex].children = generalInfoInputs
    }

    const specialFeaturesAndOtherGrouppIndex = sharedInputs.mainForm?.findIndex((obj) => obj.name === 'specialFeaturesAndOtherGroup')
    if (specialFeaturesAndOtherGrouppIndex !== -1) {
        const specialFieldsFlatInputs = [
            propertySpaceInput(generalFieldTypes),
            generalFieldTypes[realEstateGeneralFields.PROPERTY_ENERGY_EFFICIENCY_ID],
            generalFieldTypes[realEstateGeneralFields.PROPERTY_PERMIT_ID],
            generalFieldTypes[realEstateGeneralFields.PROPERTY_ACCESS_ID],
        ]
        sharedInputs.mainForm[specialFeaturesAndOtherGrouppIndex].children = [
            ...sharedInputs.mainForm[specialFeaturesAndOtherGrouppIndex].children,
            ...specialFieldsFlatInputs,
        ]
    }

    return {
        sideForm: [...sharedInputs.sideForm],
        mainForm: sharedInputs.mainForm,
    }
}

const realEstateGeneralLandSaleInputs = (isEdit, appSettings) => {
    const generalFieldTypes = realEstateGeneralFieldTypes(isEdit, appSettings)
    const sharedInputs = generateSharedInputs(generalFieldTypes)

    const propertyUsageInput = generalFieldTypes[realEstateGeneralFields.PROPERTY_LAND_USAGE_ID]
    if (propertyUsageInput) {
        sharedInputs.sideForm?.[0]
            .splice(sharedInputs.sideForm[0].findIndex((obj) => obj.name === realEstateGeneralFields.PROPERTY_SUB_TYPE_ID) + 1, 0, propertyUsageInput)
            .join()
    }

    const mortageGroupInputs = {
        type: 'array',
        name: 'otherPriceGroup',
        children: [generalFieldTypes[realEstateGeneralFields.MORTGAGE_INFO]],
    }
    const priceGroupIndex = sharedInputs.mainForm?.findIndex((x) => x.name === 'priceGroup')
    if (priceGroupIndex !== -1) {
        sharedInputs.mainForm[priceGroupIndex].children.splice(1, 0, generalFieldTypes[realEstateGeneralFields.PRICE_INITIAL]).join()
        sharedInputs.mainForm[priceGroupIndex].children.push(generalFieldTypes[realEstateGeneralFields.EXCHANGE_POSSIBLE])
        sharedInputs.mainForm?.splice(priceGroupIndex + 1, 0, mortageGroupInputs).join()
    }

    const generalInfoIndex = sharedInputs.mainForm?.findIndex((obj) => obj.name === 'generalInfoGroup')
    if (generalInfoIndex > -1) {
        const generalInfoInputs = [
            generalFieldTypes[realEstateGeneralFields.AREA],
            generalFieldTypes[realEstateGeneralFields.PLOT_WIDTH],
            generalFieldTypes[realEstateGeneralFields.PLOT_LENGTH],
            generalFieldTypes[realEstateGeneralFields.CONSTRUCTION_COEFFICIENT],
            generalFieldTypes[realEstateGeneralFields.CONSTRUCTION_FLOORS_NO],
            generalFieldTypes[realEstateGeneralFields.MAX_CONTRUCTION_AREA],
            generalFieldTypes[realEstateGeneralFields.PROPERTY_ORIENTATION_ID],
            generalFieldTypes[realEstateGeneralFields.PROPERTY_PUBLIC_TRANSPORTATION_ID],
        ]
        sharedInputs.mainForm[generalInfoIndex].children = generalInfoInputs
    }

    const distanceGroupIndex = sharedInputs.mainForm?.findIndex((obj) => obj.name === 'distanceGroup')
    if (distanceGroupIndex !== -1) {
        sharedInputs.mainForm[distanceGroupIndex].children.push(generalFieldTypes[realEstateGeneralFields.DISTANCE_PATHWAY])
    }

    const specialFeaturesAndOtherGrouppIndex = sharedInputs.mainForm?.findIndex((obj) => obj.name === 'specialFeaturesAndOtherGroup')
    if (specialFeaturesAndOtherGrouppIndex !== -1) {
        sharedInputs.mainForm[specialFeaturesAndOtherGrouppIndex].children.splice(
            sharedInputs.mainForm[specialFeaturesAndOtherGrouppIndex].children.findIndex(
                (obj) => obj.name === realEstateGeneralFields.PROPERTY_EQUIPMENT_ID
            ),
            1
        )

        sharedInputs.mainForm[specialFeaturesAndOtherGrouppIndex].children.push(generalFieldTypes[realEstateGeneralFields.PROPERTY_PERMIT_ID])
        sharedInputs.mainForm[specialFeaturesAndOtherGrouppIndex].children.push(generalFieldTypes[realEstateGeneralFields.PROPERTY_ACCESS_ID])
    }

    return {
        sideForm: [...sharedInputs.sideForm],
        mainForm: sharedInputs.mainForm,
    }
}

const realEstateGeneralLandLeaseInputs = (isEdit, appSettings) => {
    const generalFieldTypes = realEstateGeneralFieldTypes(isEdit, appSettings)
    const sharedInputs = generateSharedInputs(generalFieldTypes, false)

    const propertyUsageInput = generalFieldTypes[realEstateGeneralFields.PROPERTY_LAND_USAGE_ID]
    if (propertyUsageInput) {
        sharedInputs.sideForm?.[0]
            .splice(sharedInputs.sideForm[0].findIndex((obj) => obj.name === realEstateGeneralFields.PROPERTY_SUB_TYPE_ID) + 1, 0, propertyUsageInput)
            .join()
    }

    let supplySourceGroupIndex = sharedInputs.sideForm.findIndex((x) => x.some((o) => o.name === requestFields.PROPERTY_SUPPLY_SOURCE_ID))

    if (supplySourceGroupIndex !== -1) {
        sharedInputs.sideForm?.[supplySourceGroupIndex].unshift(generalFieldTypes[realEstateGeneralFields.AVAILABLE_FROM_DATE])
    }

    const generalInfoIndex = sharedInputs.mainForm?.findIndex((obj) => obj.name === 'generalInfoGroup')
    if (generalInfoIndex > -1) {
        const generalInfoInputs = [
            generalFieldTypes[realEstateGeneralFields.AREA],
            generalFieldTypes[realEstateGeneralFields.PLOT_WIDTH],
            generalFieldTypes[realEstateGeneralFields.PLOT_LENGTH],
            generalFieldTypes[realEstateGeneralFields.CONSTRUCTION_COEFFICIENT],
            generalFieldTypes[realEstateGeneralFields.CONSTRUCTION_FLOORS_NO],
            generalFieldTypes[realEstateGeneralFields.MAX_CONTRUCTION_AREA],
            generalFieldTypes[realEstateGeneralFields.PROPERTY_ORIENTATION_ID],
            generalFieldTypes[realEstateGeneralFields.PROPERTY_PUBLIC_TRANSPORTATION_ID],
        ]
        sharedInputs.mainForm[generalInfoIndex].children = generalInfoInputs
    }

    const distanceGroupIndex = sharedInputs.mainForm?.findIndex((obj) => obj.name === 'distanceGroup')
    if (distanceGroupIndex !== -1) {
        sharedInputs.mainForm[distanceGroupIndex].children.push(generalFieldTypes[realEstateGeneralFields.DISTANCE_PATHWAY])
    }

    const specialFeaturesAndOtherGrouppIndex = sharedInputs.mainForm?.findIndex((obj) => obj.name === 'specialFeaturesAndOtherGroup')
    if (specialFeaturesAndOtherGrouppIndex !== -1) {
        sharedInputs.mainForm[specialFeaturesAndOtherGrouppIndex].children.splice(
            sharedInputs.mainForm[specialFeaturesAndOtherGrouppIndex].children.findIndex(
                (obj) => obj.name === realEstateGeneralFields.PROPERTY_EQUIPMENT_ID
            ),
            1
        )
        sharedInputs.mainForm[specialFeaturesAndOtherGrouppIndex].children.push(generalFieldTypes[realEstateGeneralFields.PROPERTY_PERMIT_ID])
        sharedInputs.mainForm[specialFeaturesAndOtherGrouppIndex].children.push(generalFieldTypes[realEstateGeneralFields.PROPERTY_ACCESS_ID])
    }

    return {
        sideForm: [...sharedInputs.sideForm],
        mainForm: sharedInputs.mainForm,
    }
}

const realEstateGeneralGarageSaleInputs = (isEdit, appSettings) => {
    const generalFieldTypes = realEstateGeneralFieldTypes(isEdit, appSettings)
    const sharedInputs = generateSharedInputs(generalFieldTypes)

    const mortageGroupInputs = {
        type: 'array',
        name: 'otherPriceGroup',
        children: [
            commonMaintenceCostsInput(generalFieldTypes, appSettings),
            generalFieldTypes[realEstateGeneralFields.EMPTY],
            generalFieldTypes[realEstateGeneralFields.MORTGAGE_INFO],
        ],
    }
    const priceGroupIndex = sharedInputs.mainForm?.findIndex((x) => x.name === 'priceGroup')
    if (priceGroupIndex !== -1) {
        sharedInputs.mainForm[priceGroupIndex].children.splice(1, 0, generalFieldTypes[realEstateGeneralFields.PRICE_INITIAL]).join()
        sharedInputs.mainForm[priceGroupIndex].children.push(generalFieldTypes[realEstateGeneralFields.EXCHANGE_POSSIBLE])
        sharedInputs.mainForm?.splice(priceGroupIndex + 1, 0, mortageGroupInputs).join()
    }

    const generalInfoIndex = sharedInputs.mainForm?.findIndex((obj) => obj.name === 'generalInfoGroup')
    if (generalInfoIndex > -1) {
        const generalInfoInputs = [
            generalFieldTypes[realEstateGeneralFields.AREA],
            generalFieldTypes[realEstateGeneralFields.CEILING_HEIGHT],
            generalFieldTypes[realEstateGeneralFields.CONSTRUCTION_YEAR],
            generalFieldTypes[realEstateGeneralFields.PROPERTY_FLOOR_POSITION_ID],
            generalFieldTypes[realEstateGeneralFields.FLOOR_NO],
            generalFieldTypes[realEstateGeneralFields.FLOORS_TOTAL_NO],
        ]
        sharedInputs.mainForm[generalInfoIndex].children = generalInfoInputs
    }
    const distanceGroupIndex = sharedInputs.mainForm?.findIndex((obj) => obj.name === 'distanceGroup')
    if (distanceGroupIndex !== -1) {
        sharedInputs.mainForm.splice(distanceGroupIndex, 1)
    }

    const specialFeaturesAndOtherGrouppIndex = sharedInputs.mainForm?.findIndex((obj) => obj.name === 'specialFeaturesAndOtherGroup')
    if (specialFeaturesAndOtherGrouppIndex !== -1) {
        sharedInputs.mainForm[specialFeaturesAndOtherGrouppIndex].children.push(generalFieldTypes[realEstateGeneralFields.PROPERTY_PERMIT_ID])
    }

    return {
        sideForm: [...sharedInputs.sideForm],
        mainForm: sharedInputs.mainForm,
    }
}

const realEstateGeneralGarageLeaseInputs = (isEdit, appSettings) => {
    const generalFieldTypes = realEstateGeneralFieldTypes(isEdit, appSettings)
    const sharedInputs = generateSharedInputs(generalFieldTypes, false)

    let supplySourceGroupIndex = sharedInputs.sideForm.findIndex((x) => x.some((o) => o.name === requestFields.PROPERTY_SUPPLY_SOURCE_ID))

    if (supplySourceGroupIndex !== -1) {
        sharedInputs.sideForm?.[supplySourceGroupIndex].unshift(generalFieldTypes[realEstateGeneralFields.AVAILABLE_FROM_DATE])
    }

    const maintenceCostsGroupInputs = {
        type: 'array',
        name: 'reserveCommonCostsGroup',
        children: [commonMaintenceCostsInput(generalFieldTypes, appSettings), commonUtilityCostsInput(generalFieldTypes, appSettings)],
    }
    const priceGroupIndex = sharedInputs.mainForm?.findIndex((x) => x.name === 'priceGroup')

    if (priceGroupIndex !== -1) {
        sharedInputs.mainForm?.splice(priceGroupIndex + 1, 0, maintenceCostsGroupInputs).join()
    }

    const generalInfoIndex = sharedInputs.mainForm?.findIndex((obj) => obj.name === 'generalInfoGroup')
    if (generalInfoIndex > -1) {
        const generalInfoInputs = [
            generalFieldTypes[realEstateGeneralFields.AREA],
            generalFieldTypes[realEstateGeneralFields.CEILING_HEIGHT],
            generalFieldTypes[realEstateGeneralFields.CONSTRUCTION_YEAR],
            generalFieldTypes[realEstateGeneralFields.PROPERTY_FLOOR_POSITION_ID],
            generalFieldTypes[realEstateGeneralFields.FLOOR_NO],
            generalFieldTypes[realEstateGeneralFields.FLOORS_TOTAL_NO],
        ]
        sharedInputs.mainForm[generalInfoIndex].children = generalInfoInputs
    }

    const distanceGroupIndex = sharedInputs.mainForm?.findIndex((obj) => obj.name === 'distanceGroup')
    if (distanceGroupIndex !== -1) {
        sharedInputs.mainForm.splice(distanceGroupIndex, 1)
    }

    const specialFeaturesAndOtherGrouppIndex = sharedInputs.mainForm?.findIndex((obj) => obj.name === 'specialFeaturesAndOtherGroup')
    if (specialFeaturesAndOtherGrouppIndex !== -1) {
        sharedInputs.mainForm[specialFeaturesAndOtherGrouppIndex].children.push(generalFieldTypes[realEstateGeneralFields.PROPERTY_PERMIT_ID])
    }

    return {
        sideForm: [...sharedInputs.sideForm],
        mainForm: sharedInputs.mainForm,
    }
}

const propertyTransactionsInputs = (appSettings, propertyServiceValue, propertyStatusValue, isRequiredAccordingToStatus) => {
    const inputs = propertyTransactionsFieldTypes(appSettings, isRequiredAccordingToStatus)

    return {
        sideForm: [
            inputs[propertyTransactionsFields.PROPERTY_STATUS_ID],
            propertyStatusValue?.toString() === PROPERTY_STATUS.RESERVED.toString() ? inputs[propertyTransactionsFields.RESERVATION_DATE] : null,
        ],
        mainForm: [
            {
                type: 'fieldArray',
                name: 'contact_collection',
                children: [inputs[propertyTransactionsFields.CONTACT_ID]],
            },
            {
                type: 'array',
                name: 'contractedPriceGroup',
                children: [inputs[propertyTransactionsFields.PRICE_FINAL]],
            },
            {
                type: 'array',
                name: 'sellerCommissionGroup',
                children: [inputs[propertyTransactionsFields.COMMISION_SELLER], inputs[propertyTransactionsFields.COMMISION_SELLER_TYPE_ID]],
            },
            {
                type: 'array',
                name: 'buyerCommissionGroup',
                children: [inputs[propertyTransactionsFields.COMMISION_BUYER], inputs[propertyTransactionsFields.COMMISION_BUYER_TYPE_ID]],
            },
            {
                type: 'array',
                name: 'advanceGroup',
                children: [inputs[propertyTransactionsFields.ADVANCE_AMOUNT], inputs[propertyTransactionsFields.ADVANCE_DATE]],
            },
            {
                type: 'array',
                name: 'finalPaymentGroup',
                children: [
                    inputs[propertyTransactionsFields.CONCLUSION_PLACE],
                    inputs[propertyTransactionsFields.CONCLUSION_DATE],
                    inputs[propertyTransactionsFields.FINAL_PAYMENT_DATE],
                ],
            },
            {
                type: 'array',
                name: 'riskGroup',
                children: [inputs[propertyTransactionsFields.PROPERTY_TRANSACTION_RISK_DEGREE_ID]],
            },
            {
                type: 'array',
                name: 'agentsGroup',
                children: [inputs[propertyTransactionsFields.PROPERTY_TRANSACTION_AGENT_ID]],
            },
            propertyServiceValue === REAL_ESTATE_SERVICE.sale
                ? {
                      type: 'array',
                      name: 'ownershipGroup',
                      children: [
                          inputs[propertyTransactionsFields.PROPERTY_LAND_REGISTRY_STATUS_ID],
                          inputs[propertyTransactionsFields.PROPERTY_OWNERSHIP_ACQUISITION_ID],
                          inputs[propertyTransactionsFields.PROPERTY_OWNERSHIP_ACQUISITION_PROOF_ID],
                      ],
                  }
                : {},
            {
                type: 'array',
                name: 'mainRegistryGroup',
                children: [
                    inputs[propertyTransactionsFields.PROPERTY_MAIN_REGISTER_ID],
                    inputs[propertyTransactionsFields.PROPERTY_MAIN_REGISTER_DEPARTMENT_ID],
                    inputs[propertyTransactionsFields.PROPERTY_REGISTRY_MUNICIPALITY],
                ],
            },
            {
                type: 'array',
                name: 'registryGroup',
                children: [
                    inputs[propertyTransactionsFields.PROPERTY_REGISTRY_INSERT],
                    inputs[propertyTransactionsFields.PROPERTY_REGISTRY_PARCEL_NO],
                    inputs[propertyTransactionsFields.PROPERTY_REGISTRY_SUBINSERT],
                ],
            },
            {
                type: 'array',
                name: 'registryDepartmentGroup',
                children: [
                    inputs[propertyTransactionsFields.PROPERTY_REGISTRY_DEPARTMENT_KPU_ID],
                    inputs[propertyTransactionsFields.PROPERTY_REGISTRY_DEPARTMENT_KPU_SUBINSERT_ID],
                    inputs[propertyTransactionsFields.PROPERTY_SUBINSERT_KPU],
                ],
            },
            {
                type: 'array',
                name: 'pukDepartmentGroup',
                children: [inputs[propertyTransactionsFields.PROPERTY_PUK_ID], inputs[propertyTransactionsFields.PROPERTY_PUK_DEPARTMENT_ID]],
            },
            {
                type: 'array',
                name: 'cadastralGroup',
                children: [
                    inputs[propertyTransactionsFields.PROPERTY_CADASTRAL_MUNCIPALITY],
                    inputs[propertyTransactionsFields.PROPERTY_CADASTRAL_PARCEL_NO],
                    inputs[propertyTransactionsFields.PROPERTY_POSSESSORY_SHEET_NO],
                ],
            },
            {
                type: 'array',
                name: 'buildingNumberGroup',
                children: [
                    inputs[propertyTransactionsFields.PROPERTY_BUILDING_NUMBER],
                    inputs[propertyTransactionsFields.PROPERTY_BUILDING_PART_NUMBER],
                ],
            },
        ],
    }
}

const realEstateMaterials3DVideoInputs = (appSettings) => {
    const inputs = realEstaterealEstateMaterials3DVideoFieldTypes(appSettings)

    return {
        sideForm: [],
        mainForm: [
            {
                type: 'array',
                name: 'videoGroup',
                children: [inputs[realEstateMaterials3DVideoFields.VIRTUAL_WALK_URL], inputs[realEstateMaterials3DVideoFields.PROPERTY_VIDEO_URL]],
            },
        ],
    }
}

const realEstateDescriptionInputs = (appSettings) => {
    const inputs = realEstateDescriptionFieldTypes(appSettings)
    return {
        sideForm: [inputs[realEstateDescriptionFields.PROPERTY_TITLE]],
        mainForm: [inputs[realEstateDescriptionFields.PROPERTY_DESCRIPTION]],
    }
}

const propertyOfferInputs = () => {
    const inputs = propertyOfferPrintFieldTypes()
    return {
        mainForm: [
            inputs[propertyOfferPrintFields.LANGUAGE],
            inputs[propertyOfferPrintFields.AGENT_ID],
            {
                type: 'fieldArray',
                name: 'client_group',
                children: [inputs[propertyOfferPrintFields.CLIENT_ID]],
            },
            inputs[propertyOfferPrintFields.EMAIL],
        ],
    }
}

const propertyPrintInputs = () => {
    const inputs = propertyOfferPrintFieldTypes()
    return {
        mainForm: [inputs[propertyOfferPrintFields.OFFER_LOCALE], inputs[propertyOfferPrintFields.AGENT_ID]],
    }
}

const propertyDetailSearchInputs = () => {
    const inputs = propertyDetailSearhFieldTypes()
    return {
        mainForm: [
            {
                type: 'array',
                name: 'idGroup',
                children: [inputs[propertyDetailSearchFields.ID]],
            },
            {
                type: 'array',
                name: 'otherFieldsGroup',
                children: [
                    inputs[propertyDetailSearchFields.PROPERTY_SERVICE],
                    inputs[propertyDetailSearchFields.COUNTRY],
                    inputs[propertyDetailSearchFields.OWNER],
                    inputs[propertyDetailSearchFields.PROPERTY_STATUS],
                    inputs[propertyDetailSearchFields.COUNTY],
                    inputs[propertyDetailSearchFields.PHONE_NUMBER],
                    inputs[propertyDetailSearchFields.PROPERTY_TYPE],
                    inputs[propertyDetailSearchFields.CITY],
                    inputs[propertyDetailSearchFields.AGENT],
                    inputs[propertyDetailSearchFields.PROPERTY_SUB_TYPE],
                    inputs[propertyDetailSearchFields.WIDER_AREA],
                    {
                        type: 'grouped',
                        name: 'receiptDateGrouped',
                        label: i18n.t('app.common.properties.receiptDate'),
                        colProps: {
                            sm: 12,
                            xl: 4,
                        },
                        items: [
                            inputs[propertyDetailSearchFields.PROPERTY_RECEIPT_DATE_FROM],
                            inputs[propertyDetailSearchFields.PROPERTY_RECEIPT_DATE_TO],
                        ],
                    },
                    {
                        type: 'grouped',
                        name: 'priceGrouped',
                        label: i18n.t('table.header.profile.price'),
                        colProps: {
                            sm: 12,
                            xl: 4,
                        },
                        items: [inputs[propertyDetailSearchFields.PRICE_FROM], inputs[propertyDetailSearchFields.PRICE_TO]],
                    },
                    inputs[propertyDetailSearchFields.QUARTER],

                    {
                        type: 'grouped',
                        name: 'availableFromDateGrouped',
                        label: i18n.t('form.detailSearch.availableFromDate.properties'),
                        colProps: {
                            sm: 12,
                            xl: 4,
                        },
                        items: [
                            inputs[propertyDetailSearchFields.AVAILABLE_FROM_DATE_FROM],
                            inputs[propertyDetailSearchFields.AVAILABLE_FROM_DATE_TO],
                        ],
                    },
                    {
                        type: 'grouped',
                        name: 'areaGrouped',
                        label: i18n.t('app.common.spaceArea'),
                        colProps: {
                            sm: 12,
                            xl: 4,
                        },
                        items: [inputs[propertyDetailSearchFields.AREA_FROM], inputs[propertyDetailSearchFields.AREA_TO]],
                    },
                    inputs[propertyDetailSearchFields.STREET],
                    inputs[propertyDetailSearchFields.WEB],
                    {
                        type: 'grouped',
                        name: 'roomsNoGrouped',
                        label: i18n.t('app.common.property.numberOfRooms'),
                        colProps: {
                            sm: 12,
                            xl: 4,
                        },
                        items: [inputs[propertyDetailSearchFields.ROOMS_NO_FROM], inputs[propertyDetailSearchFields.ROOMS_NO_TO]],
                    },
                    inputs[propertyDetailSearchFields.ISLAND],
                    inputs[propertyDetailSearchFields.ATTRIBUTE],
                    {
                        type: 'grouped',
                        name: 'bedroomsNoGrouped',
                        label: i18n.t('app.common.bedrooms'),
                        colProps: {
                            sm: 12,
                            xl: 4,
                        },
                        items: [inputs[propertyDetailSearchFields.BEDROOMS_NO_FROM], inputs[propertyDetailSearchFields.BEDROOMS_NO_TO]],
                    },
                    inputs[propertyDetailSearchFields.DISTANCE_SEA],

                    inputs[propertyDetailSearchFields.PORTAL],
                    {
                        type: 'grouped',
                        name: 'floorNoGrouped',
                        label: i18n.t('app.common.floorNumber'),
                        colProps: {
                            sm: 12,
                            xl: 4,
                        },
                        items: [inputs[propertyDetailSearchFields.FLOOR_NO_FROM], inputs[propertyDetailSearchFields.FLOOR_NO_TO]],
                    },
                    inputs[propertyDetailSearchFields.PROPERTY_FLOOR],
                    {
                        type: 'empty',
                        name: 'empty-2',
                        colProps: {
                            sm: 0,
                            xl: 4,
                        },
                    },
                    inputs[propertyDetailSearchFields.PROPERTY_DESCRIPTIONS],
                    inputs[propertyDetailSearchFields.PROPERTY_UTILITY],
                    inputs[propertyDetailSearchFields.PROPERTY_CONDITION],
                    inputs[propertyDetailSearchFields.PROPERTY_SPACE],
                    inputs[propertyDetailSearchFields.PROPERTY_EQUIPMENT],
                    inputs[propertyDetailSearchFields.PROPERTY_ENERGY_EFFICIENCY],
                ],
            },
        ],
    }
}

export {
    propertyDetailSearchInputs,
    propertyOfferInputs,
    propertyPrintInputs,
    propertyTransactionsInputs,
    realEstateDescriptionInputs,
    realEstateGeneralFlatLeaseInputs,
    realEstateGeneralFlatSaleInputs,
    realEstateGeneralGarageLeaseInputs,
    realEstateGeneralGarageSaleInputs,
    realEstateGeneralHouseLeaseInputs,
    realEstateGeneralHouseSaleInputs,
    realEstateGeneralInputs,
    realEstateGeneralLandLeaseInputs,
    realEstateGeneralLandSaleInputs,
    realEstateGeneralOfficeSpaceLeaseInputs,
    realEstateGeneralOfficeSpaceSaleInputs,
    realEstateMaterials3DVideoInputs,
}
