import { FormikProvider, getIn, useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { Container, Form, TabContent, TabPane } from 'reactstrap'
import * as Yup from 'yup'
import { CONTACT_TYPE, SUBMIT_RESPONSE_STATUS } from '../../common/constants'
import { companyProfileFields } from '../../common/forms/company/fields'
import { companyResponseFields } from '../../common/response/company'
import FormActionButtons from '../../Components/Common/FormActionButtons'
import Loader from '../../Components/Common/Loader'
import PageTitle from '../../Components/Common/PageTitle'
import CompanyFormGeneralTab, {
    CompanyFormGeneralTabMain,
    CompanyFormGeneralTabSide,
} from '../../Components/CompanyFormGeneralTab/CompanyFormGeneralTab'
import CompanyFormOtherInfoTab, {
    CompanyFormOtherInfoTabMain,
    CompanyFormOtherInfoTabSide,
} from '../../Components/CompanyFormOtherInfoTab/CompanyFormOtherInfoTab'
import ContactFormProfileTab, {
    ContactFormProfileTabMain,
    ContactFormProfileTabSide,
} from '../../Components/ContactFormProfileTab/ContactFormProfileTab'
import ContactProfileFormTab from '../../Components/ContactProfileFormTab'
import DocumentFormTab from '../../Components/DocumentFormTab'
import {
    useCompanyGeneralFormConfig,
    useCompanyGeneralFormSelectOptions,
    useCompanyOtherInfoFormConfig,
    useCompanyOtherInfoFormSelectOptions,
    useCompanyProfileFormConfig,
    useCompanyProfileFormSelectOptions,
    useContactProfileFormConfig,
    useContactProfileFormSelectOptions,
    useRealEstateGeneralFormConfig,
    useRealEstateGeneralFormSelectOptions,
    useRealEstateTransactionFormConfig,
} from '../../Components/Hooks/FormHooks'
import useCompanyFormTabs from '../../Components/Hooks/useCompanyTabs'
import FormValidationErrorModal from '../../Components/Modals/FormValidationErrorModal'
import { fetchCompany, selectCompany, selectCompanyFetchingDetails, selectCompanyProfile, updateCompany } from '../../store/companies'
import {
    clearContactProfile,
    createContactProfile,
    fetchContactProfile,
    selectContactProfileById,
    selectContactProfileDetails,
    updateContactProfile,
} from '../../store/contactProfiles'

const CompanyEdit = () => {
    const location = useLocation()
    const history = useHistory()
    const tabs = useCompanyFormTabs(true)
    const dispatch = useDispatch()
    const [activeTab, setActiveTab] = useState(null)
    const [contactProfileId, setContactProfileId] = useState(null)
    let { id } = useParams()
    const [propertyTypeValue, setPropertyTypeValue] = useState('')
    const [propertyServiceValue, setPropertyServiceValue] = useState('')
    let { company, fetchingDetails, contactProfile } = useSelector((state) => ({
        company: selectCompany(state),
        contactProfile: selectContactProfileDetails(state),
        fetchingDetails: selectCompanyFetchingDetails(state),
    }))
    const { generalInputs, generalValidationConfig } = useCompanyGeneralFormConfig(company)
    const { otherInfoInputs, otherInfoValidationConfig } = useCompanyOtherInfoFormConfig(company)
    const { profileInputs, profileValidationConfig } = useCompanyProfileFormConfig(contactProfile, propertyTypeValue, propertyServiceValue)
    const formSelectOptions = useCompanyGeneralFormSelectOptions()
    const otherInfoSelectOptions = useCompanyOtherInfoFormSelectOptions()
    const contactProfileSelectOptions = useCompanyProfileFormSelectOptions()

    useEffect(() => {
        dispatch(fetchCompany({ id }))

        return () => {
            // console.log('cleanup')
            dispatch(clearContactProfile())
        }
    }, [dispatch, id])

    useEffect(() => {
        if (tabs.length > 0) {
            if (location?.state?.activeTab) {
                setActiveTab(location?.state?.activeTab)
            } else {
                setActiveTab(tabs[0].name)
            }
        }

        // console.log('location', location)
        if (location?.state && location?.state?.profileId) {
            dispatch(
                fetchContactProfile({
                    contactId: id,
                    id: location.state.profileId,
                })
            )
            setContactProfileId(location.state.profileId)
        }
    }, [tabs, location])
    const handleTabChange = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab)
        }
    }
    const { isValid, isSubmitting, isValidating, ...validation } = useFormik({
        enableReinitialize: true,
        initialValues: {
            general: generalValidationConfig?.initialValues ?? {},
            otherInfo: otherInfoValidationConfig?.initialValues ?? {},
        },
        validationSchema: Yup.object({
            general: generalValidationConfig?.validationSchema ?? Yup.object({}),
            otherInfo: otherInfoValidationConfig?.validationSchema ?? Yup.object({}),
        }),
        onSubmit: (values) => {
            const submitValues = {
                ...generalValidationConfig.prepareModelForSubmit(values.general),
                ...values.otherInfo,
                id,
            }
            // console.log('submit values', submitValues)

            dispatch(
                updateCompany(submitValues, (response) => {
                    if (response?.submitStatus === SUBMIT_RESPONSE_STATUS.SUCCESS) {
                        // console.log('response', response)
                        // commented because of ticket [393kMYtG]
                        // history.goBack();
                    }
                })
            )
        },
    })

    const profileValidation = useFormik({
        enableReinitialize: true,
        initialValues: {
            profile: profileValidationConfig?.initialValues ?? {},
        },
        validationSchema: Yup.object({
            profile: profileValidationConfig?.validationSchema ?? Yup.object({}),
        }),
        onSubmit: (values) => {
            const profileValues = {
                ...profileValidationConfig.prepareModelForSubmit(values.profile),
                contactId: id,
                contact_id: id,
            }
            // console.log('profile values', profileValues)
            if (location?.state?.profileId || profileValues.id) {
                /// todo udpate
                dispatch(updateContactProfile(profileValues))
            } else {
                if (profileValues?.property_type_id && profileValues.contact_profile_service_id) {
                    delete profileValues.id
                    dispatch(
                        createContactProfile(profileValues, (response) => {
                            if (response?.submitStatus === SUBMIT_RESPONSE_STATUS.SUCCESS) {
                                // profileValidation.resetForm();
                                // console.log('response', response.data)
                                profileValidation.setFieldValue('profile.id', response.data.id)
                            }
                        })
                    )
                }
            }
        },
    })

    // const profileId = getIn(profileValidation?.values?.profile, 'id');
    const propertyTypeId = getIn(profileValidation?.values?.profile, companyProfileFields.PROPERTY_TYPE_ID)
    const propertyServiceId = getIn(profileValidation?.values?.profile, companyProfileFields.CONTACT_PROFILE_SERVICE_ID)

    useEffect(() => {
        if (propertyTypeId !== propertyTypeValue) {
            setPropertyTypeValue(propertyTypeId)
        }
    }, [propertyTypeId, propertyTypeValue])

    useEffect(() => {
        if (propertyServiceId !== propertyServiceValue) {
            setPropertyServiceValue(propertyServiceId)
        }
    }, [propertyServiceId, propertyServiceValue])

    useEffect(() => {
        if (!isValid && !isValidating && isSubmitting) {
            setIsErrorModalActive(true)
        }
    }, [isValid, isValidating, isSubmitting])

    const renderFormButtons = () => <FormActionButtons disabled={false} hasCancel={true} cancelAction={() => history.push(`/companies/${id}`)} />

    const [isErrorModalActive, setIsErrorModalActive] = useState(false)

    const toggleErrorModal = () => {
        setIsErrorModalActive(!isErrorModalActive)
    }

    if (fetchingDetails) {
        return (
            <div className="page-content">
                <Container fluid>
                    <Loader />
                </Container>
            </div>
        )
    }

    if (fetchingDetails) {
        return (
            <div className="page-content">
                <Container fluid>
                    <Loader />
                </Container>
            </div>
        )
    }
    return (
        <div className="page-content">
            <Container fluid>
                <FormikProvider value={validation}>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault()
                            validation.handleSubmit()
                            profileValidation.handleSubmit()
                            return false
                        }}
                        className="needs-validation"
                        id="real_estate_form"
                    >
                        <PageTitle title={`Uredi > ${company?.[companyResponseFields.CONTACT_COMPANY]?.[companyResponseFields.NAME]}`}>
                            {renderFormButtons()}
                        </PageTitle>

                        <TabContent activeTab={activeTab} className="text-muted">
                            <TabPane tabId="general">
                                <CompanyFormGeneralTab
                                    tabs={tabs}
                                    activeTab={activeTab}
                                    handleTabChange={handleTabChange}
                                    inputs={generalInputs}
                                    subform="general"
                                    formik={validation}
                                    selectOptions={formSelectOptions}
                                >
                                    <CompanyFormGeneralTabSide />
                                    <CompanyFormGeneralTabMain />
                                </CompanyFormGeneralTab>
                            </TabPane>
                            <TabPane tabId="otherInfo">
                                <CompanyFormOtherInfoTab
                                    tabs={tabs}
                                    activeTab={activeTab}
                                    handleTabChange={handleTabChange}
                                    inputs={otherInfoInputs}
                                    subform="otherInfo"
                                    formik={validation}
                                    selectOptions={otherInfoSelectOptions}
                                >
                                    <CompanyFormOtherInfoTabSide />
                                    <CompanyFormOtherInfoTabMain />
                                </CompanyFormOtherInfoTab>
                            </TabPane>
                            <TabPane tabId="contactProfile">
                                <ContactProfileFormTab
                                    tabs={tabs}
                                    activeTab={activeTab}
                                    handleTabChange={handleTabChange}
                                    inputs={profileInputs}
                                    subform="profile"
                                    formik={profileValidation}
                                    selectOptions={contactProfileSelectOptions}
                                    contactType={CONTACT_TYPE.COMPANY}
                                />
                            </TabPane>
                            {activeTab === 'documents' && (
                                <TabPane tabId="documents">
                                    <DocumentFormTab
                                        tabs={tabs}
                                        activeTab={activeTab}
                                        handleTabChange={handleTabChange}
                                        documentModule="contact"
                                        contactType={CONTACT_TYPE.COMPANY}
                                        parentId={id}
                                    />
                                </TabPane>
                            )}
                        </TabContent>
                    </Form>
                </FormikProvider>
            </Container>
            {isErrorModalActive && (
                <FormValidationErrorModal
                    validation={validation}
                    inputs={[generalInputs, otherInfoInputs, profileInputs]}
                    onClose={() => toggleErrorModal(false)}
                />
            )}
        </div>
    )
}

export default CompanyEdit
