import classNames from 'classnames'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import AppPermissionsSwitcher from '../AppPermissionsSwitcher/AppPermissionsSwitcher'

const ViewDataListItem = ({ label, value, className, ownerId, icon }) => {
    if (!label || !value) return null

    return (
        <Row className={className}>
            <Col sm={6}>
                <span className="flex-grow-1 fw-medium">{label}:</span>
            </Col>
            <Col sm={6}>
                {ownerId ? (
                    <Link className="dmn-link" to={`/contacts/${ownerId}`} target="_blank" rel="noopener noreferrer">
                        <span className="flex-grow-1 fw-light">{value}</span>
                    </Link>
                ) : (
                    <>
                        <span className="flex-grow-1 fw-light">{value}</span>
                        <span className="flex-grow-1 fw-light">{icon}</span>
                    </>
                )}
            </Col>
        </Row>
    )
}

const ViewDataList = ({ dataList }) => {
    const location = useLocation()
    if (!dataList || dataList.every(({ value }) => !value)) return null

    return (
        <AppPermissionsSwitcher permission="ViewData" data={dataList} module={location}>
            {({ newData }) => {
                return (
                    newData?.length &&
                    newData.map(({ id, label, value, ownerId, icon }, i) => {
                        const isLast = dataList.length - 1 === i

                        return (
                            <ViewDataListItem
                                key={i}
                                className={classNames({
                                    'mb-4': id === 'utilitiesIncluded' ? true : !isLast,
                                })}
                                ownerId={ownerId}
                                label={label}
                                value={value}
                                icon={icon}
                            />
                        )
                    })
                )
            }}
        </AppPermissionsSwitcher>
    )
}

export default ViewDataList
