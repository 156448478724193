import { apiReq } from '../index'
import QueryString from 'qs'
import { getSearchCriteriaFromStore } from '../helpers'

export const getPropertyById = async (id: number) => {
    const response = await apiReq(`v1/property/${id}`, {
        params: getSearchCriteriaFromStore('property'),
        paramsSerializer: (params: any) => {
            return QueryString.stringify(params)
        },
    })

    return response?.data
}
