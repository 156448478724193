import { handleActions } from 'redux-actions'
import { hideModal, showModalFailure, showModalRequest, showModalSuccess } from './actions'

export const initialState = {
    modalType: null,
    modalProps: {},
    fetching: false,
    isOpen: false,
}

export default handleActions(
    {
        [showModalRequest]: (state, { payload }) => ({
            ...state,
            fetching: true,
            modalType: payload.modalType,
            isOpen: true,
        }),
        [showModalSuccess]: (state, { payload }) => {
            return {
                ...state,
                fetching: false,
                modalType: payload.modalType,
                modalProps: {
                    ...state.modalProps,
                    ...payload.modalProps,
                },
                isOpen: true,
            }
        },
        [showModalFailure]: (state, { payload }) => ({
            ...state,
            fetching: false,
        }),
        [hideModal]: (state, { payload }) => {
            const modalProps = state.modalProps
            delete modalProps[payload.modalType]

            return {
                ...state,
                modalType: null,
                modalProps: {
                    ...state.modalProps,
                },
                isOpen: false,
            }
        },
    },
    initialState
)
