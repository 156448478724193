import { put, takeLatest } from "redux-saga/effects";
import { apiRequest } from "../api";
import { fetchDocumentValuesAvailableEntities, fetchDocumentValuesAvailableEntitiesFailure, fetchDocumentValuesAvailableEntitiesRequest, fetchDocumentValuesAvailableEntitiesSuccess, fetchDocumentValuesType, fetchDocumentValuesTypeFailure, fetchDocumentValuesTypeRequest, fetchDocumentValuesTypeSuccess } from "./actions";

export function* fetchDocumentValuesAvailableEntitiesSaga() {
    yield put(fetchDocumentValuesAvailableEntitiesRequest());
    try {
        const response = yield apiRequest('v1/file/availableEntities', {
            method: 'GET'
        });
        yield put(fetchDocumentValuesAvailableEntitiesSuccess(response.data));
    } catch (error) {
        yield put(fetchDocumentValuesAvailableEntitiesFailure(error));
    }
}

export function* fetchDocumentValuesTypeSaga() {
    yield put(fetchDocumentValuesTypeRequest());
    try {
        const response = yield apiRequest('v1/file/values/documentType', {
            method: 'GET'
        });
        yield put(fetchDocumentValuesTypeSuccess(response.data));
    } catch (error) {
        yield put(fetchDocumentValuesTypeFailure(error));
    }
}


export default function* actionWatcher() {
    yield takeLatest(`${fetchDocumentValuesAvailableEntities}`, fetchDocumentValuesAvailableEntitiesSaga);
    yield takeLatest(`${fetchDocumentValuesType}`, fetchDocumentValuesTypeSaga);
}