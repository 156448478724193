import { put, takeLatest } from "redux-saga/effects";
import { apiRequest } from "../api";
import { fetchProjectValuesAvailableEntities, fetchProjectValuesAvailableEntitiesFailure, fetchProjectValuesAvailableEntitiesRequest, fetchProjectValuesAvailableEntitiesSuccess, fetchProjectValuesDevelopmentStage, fetchProjectValuesDevelopmentStageFailure, fetchProjectValuesDevelopmentStageRequest, fetchProjectValuesDevelopmentStageSuccess, fetchProjectValuesObjectType, fetchProjectValuesObjectTypeFailure, fetchProjectValuesObjectTypeRequest, fetchProjectValuesObjectTypeSuccess, fetchProjectValuesSize, fetchProjectValuesSizeFailure, fetchProjectValuesSizeRequest, fetchProjectValuesSizeSuccess, fetchProjectValuesStatus, fetchProjectValuesStatusFailure, fetchProjectValuesStatusRequest, fetchProjectValuesStatusSuccess, fetchProjectValuesType, fetchProjectValuesTypeFailure, fetchProjectValuesTypeRequest, fetchProjectValuesTypeSuccess } from "./actions";

export function* fetchProjectValuesAvailableEntitiesSaga() {
    yield put(fetchProjectValuesAvailableEntitiesRequest());
    try {
        const response = yield apiRequest('v1/project/availableEntities', {
            method: 'GET'
        });
        yield put(fetchProjectValuesAvailableEntitiesSuccess(response.data));
    } catch (error) {
        yield put(fetchProjectValuesAvailableEntitiesFailure(error));
    }
}

export function* fetchProjectValuesDevelopmentStageSaga() {
    yield put(fetchProjectValuesDevelopmentStageRequest());
    try {
        const response = yield apiRequest('v1/project/values/projectDevelopmentStage', {
            method: 'GET'
        });
        yield put(fetchProjectValuesDevelopmentStageSuccess(response.data));
    } catch (error) {
        yield put(fetchProjectValuesDevelopmentStageFailure(error));
    }
}

export function* fetchProjectValuesObjectTypeSaga() {
    yield put(fetchProjectValuesObjectTypeRequest());
    try {
        const response = yield apiRequest('v1/project/values/projectObjectType', {
            method: 'GET'
        });
        yield put(fetchProjectValuesObjectTypeSuccess(response.data));
    } catch (error) {
        yield put(fetchProjectValuesObjectTypeFailure(error));
    }
}

export function* fetchProjectValuesSizeSaga() {
    yield put(fetchProjectValuesSizeRequest());
    try {
        const response = yield apiRequest('v1/project/values/projectSize', {
            method: 'GET'
        });
        yield put(fetchProjectValuesSizeSuccess(response.data));
    } catch (error) {
        yield put(fetchProjectValuesSizeFailure(error));
    }
}

export function* fetchProjectValuesStatusSaga() {
    yield put(fetchProjectValuesStatusRequest());
    try {
        const response = yield apiRequest('v1/project/values/projectStatus', {
            method: 'GET'
        });
        yield put(fetchProjectValuesStatusSuccess(response.data));
    } catch (error) {
        yield put(fetchProjectValuesStatusFailure(error));
    }
}

export function* fetchProjectValuesTypeSaga() {
    yield put(fetchProjectValuesTypeRequest());
    try {
        const response = yield apiRequest('v1/project/values/projectType', {
            method: 'GET'
        });
        yield put(fetchProjectValuesTypeSuccess(response.data));
    } catch (error) {
        yield put(fetchProjectValuesTypeFailure(error));
    }
}

export default function* actionWatcher() {
    yield takeLatest(`${fetchProjectValuesAvailableEntities}`, fetchProjectValuesAvailableEntitiesSaga);
    yield takeLatest(`${fetchProjectValuesDevelopmentStage}`, fetchProjectValuesDevelopmentStageSaga);
    yield takeLatest(`${fetchProjectValuesObjectType}`, fetchProjectValuesObjectTypeSaga);
    yield takeLatest(`${fetchProjectValuesSize}`, fetchProjectValuesSizeSaga);
    yield takeLatest(`${fetchProjectValuesStatus}`, fetchProjectValuesStatusSaga);
    yield takeLatest(`${fetchProjectValuesType}`, fetchProjectValuesTypeSaga);
}