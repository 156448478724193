import QueryString from 'qs'
import { put, takeEvery, takeLatest } from 'redux-saga/effects'
import { SUBMIT_RESPONSE_STATUS } from '../../common/constants'
import { apiRequest } from '../api'
import {
    createNote,
    createNoteFailure,
    createNoteRequest,
    createNoteSuccess,
    deleteNote,
    deleteNoteFailure,
    deleteNoteRequest,
    deleteNoteSuccess,
    fetchNotes,
    fetchNotesFailure,
    fetchNotesRequest,
    fetchNotesSuccess,
    updateNote,
    updateNoteFailure,
    updateNoteRequest,
    updateNoteSuccess,
} from './actions'

export function* fetchNotesSaga({ payload, meta }) {
    const { noteEntity } = payload
    const params = { noLimit: 1 }

    let id

    switch (noteEntity) {
        case 'project':
            id = payload.project_id
            break;
        case 'property':
            id = payload.property_id
            break;
        case 'contact':
            id = payload.contact_id
            break;
        default:
            break;
    }

    yield put(fetchNotesRequest())

    try {
        const response = yield apiRequest(`v1/${noteEntity}-note/parent/${id}`, {
            method: 'GET',
            params: params,
            paramsSerializer: (params) => {
                return QueryString.stringify(params)
            },
        })

        yield put(fetchNotesSuccess(response.data))
    } catch (error) {
        yield put(fetchNotesFailure(error))
    }
}

export function* createNoteSaga({ payload, meta }) {
    const { noteEntity, ...data } = payload

    yield put(createNoteRequest())

    try {
        const response = yield apiRequest(`v1/${noteEntity}-note`, {
            method: 'POST',
            data: data,
        })

        if (response.status === 200) {
            yield put(createNoteSuccess(response.data))
        }

        if (meta && meta.callback) {
            meta.callback(SUBMIT_RESPONSE_STATUS.SUCCESS)
        }
    } catch (error) {
        yield put(createNoteFailure(error))

        if (meta && meta.callback) {
            meta.callback(SUBMIT_RESPONSE_STATUS.FAILURE)
        }
    }
}

export function* updateNoteSaga({ payload, meta }) {
    const { id, noteEntity, ...data } = payload

    yield put(updateNoteRequest())

    try {
        const response = yield apiRequest(`v1/${noteEntity}-note/${id}`, {
            method: 'PUT',
            data: data,
        })

        if (response.status === 200) {
            yield put(updateNoteSuccess(response.data))
        }

        if (meta && meta.callback) {
            meta.callback(SUBMIT_RESPONSE_STATUS.SUCCESS)
        }
    } catch (error) {
        console.log('error', error)
        yield put(updateNoteFailure(error))

        if (meta && meta.callback) {
            meta.callback(SUBMIT_RESPONSE_STATUS.FAILURE)
        }
    }
}

export function* deleteNoteSaga({ payload, meta }) {
    const { id, noteEntity } = payload

    yield put(deleteNoteRequest())

    try {
        const response = yield apiRequest(`v1/${noteEntity}-note/${id}`, {
            method: 'DELETE',
        })
        yield put(deleteNoteSuccess(id))

        if (meta && meta.callback) {
            meta.callback(SUBMIT_RESPONSE_STATUS.SUCCESS)
        }
    } catch (error) {
        yield put(deleteNoteFailure(error))
    }
}

export default function* actionWatcher() {
    yield takeLatest(`${fetchNotes}`, fetchNotesSaga)
    yield takeEvery(`${createNote}`, createNoteSaga)
    yield takeEvery(`${updateNote}`, updateNoteSaga)
    yield takeEvery(`${deleteNote}`, deleteNoteSaga)
}
