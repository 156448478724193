import { Button, ModalBody, ModalHeader } from 'reactstrap'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'

const UserDeleteModal = ({ handleModalClose }) => {
    const body = useRef(document.getElementsByTagName('body'))
    const { t } = useTranslation()

    return (
        <>
            <ModalHeader className="mt-3">{t('admin.deleteModal.title')}</ModalHeader>
            <ModalBody style={{ marginTop: '2.5rem' }} className="d-flex gap-2 justify-content-end mt-4 mb-2 pt-3">
                <Button
                    onClick={() => {
                        handleModalClose()
                        body.current[0].style.overflow = 'auto'
                    }}
                    className="btn-label btn-primary"
                >
                    <i className="mdi mdi-close label-icon align-middle fs-16"></i>
                    {t('button.cancel')}
                </Button>
                <Button className="btn-label btn-danger">
                    <i className="mdi mdi-trash-can-outline label-icon align-middle fs-16"></i>
                    {t('button.delete')}
                </Button>
            </ModalBody>
        </>
    )
}

export default UserDeleteModal
