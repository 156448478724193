import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { propertyOfferInputs } from '../common/forms/generalRealEstate/inputs'
import { propertyOfferValidation } from '../common/forms/generalRealEstate/validations'
import { selectCurrentUserId } from '../store/auth/selectors'

// this is used by Projects and Properties offer modals
export const useOfferFormConfig = (type) => {
    const [inputs, setInputs] = useState({})
    const [validationConfig, setValidationConfig] = useState({})

    const { userId } = useSelector((state) => ({
        userId: selectCurrentUserId(state),
    }))

    useEffect(() => {
        setInputs(propertyOfferInputs())
    }, [])

    useEffect(() => {
        const validationConfig = propertyOfferValidation({
            agent: {
                id: userId,
            },
            // if modal is in Property default template is 1, if modal is in Project default is 4
            offerTemplate: type === 'property' ? 1 : 4,
            offerLocale: 'hr',
            offerType: type,
        })

        setValidationConfig(validationConfig)
    }, [userId, type])

    return {
        inputs,
        validationConfig,
    }
}
