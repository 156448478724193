import { createAction } from "redux-actions";

const ENTITY = 'activityVALUES';
export const stateKey = ENTITY.toLowerCase();

const AVAILABLEENTITIES = 'AVAILABLEENTITIES';
const TYPE = "TYPE";

export const fetchActivityValuesAvailableEntities = createAction(`${ENTITY}/${AVAILABLEENTITIES}/FETCH`);
export const fetchActivityValuesAvailableEntitiesRequest = createAction(`${ENTITY}/${AVAILABLEENTITIES}/FETCH_REQUEST`);
export const fetchActivityValuesAvailableEntitiesSuccess = createAction(`${ENTITY}/${AVAILABLEENTITIES}/FETCH_SUCCESS`);
export const fetchActivityValuesAvailableEntitiesFailure = createAction(`${ENTITY}/${AVAILABLEENTITIES}/FETCH_FAILURE`);

export const fetchActivityValuesType = createAction(`${ENTITY}/${TYPE}/FETCH`);
export const fetchActivityValuesTypeRequest = createAction(`${ENTITY}/${TYPE}/FETCH_REQUEST`);
export const fetchActivityValuesTypeSuccess = createAction(`${ENTITY}/${TYPE}/FETCH_SUCCESS`);
export const fetchActivityValuesTypeFailure = createAction(`${ENTITY}/${TYPE}/FETCH_FAILURE`);