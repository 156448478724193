import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import { selectApplicationAreaUnit, selectApplicationCurrency } from '../../../../../store/settings/selectors'
import { toFullURL } from '../../../../../utils/files'
import { formatNumberWithTwoDecimals } from '../../../../../utils/general'
import { renderAreaUnit } from '../../../../../utils/property'
import { useTranslation } from 'react-i18next'

const InfoWindowContent = (props) => {
    const { id, propertyType, mainPhoto, propertyService, area, price, city, widerArea, quarter } = props

    const { t } = useTranslation()
    const { currency, areaUnit } = useSelector((state) => ({
        currency: selectApplicationCurrency(state),
        areaUnit: selectApplicationAreaUnit(state),
    }))

    return (
        <Link to={`properties/${id}`} className="d-flex mt-2">
            <img width="150" height="auto" src={toFullURL(mainPhoto?.id)} alt={mainPhoto?.fileDescription} />
            <div>
                <p className="fw-bold mb-0 ms-4">
                    {propertyType?.name}, {propertyService?.name}
                </p>
                <p className="mb-3 ms-4">
                    {city?.name}, {quarter?.name ? quarter?.name : widerArea?.name}
                </p>
                <p className="fw-bold mb-1 ms-4">
                    <span className="fw-medium">{t('table.header.profile.price')}:</span> {formatNumberWithTwoDecimals(price)} {currency}
                </p>
                <p className="fw-bold mb-0 ms-4">
                    <span className="fw-medium">{t('app.common.spaceArea')}:</span> {area} {renderAreaUnit(areaUnit)}
                </p>
            </div>
        </Link>
    )
}

export default InfoWindowContent
