import { useState } from 'react'
import { Card, CardBody, Col, Input, Label, UncontrolledTooltip } from 'reactstrap'
import UserImage from '../ImageContainer/ImageContainer'
import { handleImageChange } from '../../helpers'

const UploadPhoto = ({ imageUrl, photoField, firstName, lastName, headline, subheadline, formik, permission }) => {
    const [profileImageUpload, setProfileImageUpload] = useState()
    const [imageInputerror, setImageInputError] = useState()

    return (
        <Col xxl={3}>
            <Card>
                <CardBody>
                    <div className="text-center">
                        <div className="profile-user position-relative d-inline-block mx-auto mb-4">
                            <UserImage imageUrl={profileImageUpload || imageUrl} firstName={firstName} lastName={lastName} roundContainer={true} />
                            <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                                <Input
                                    onChange={(e) => handleImageChange(e, photoField, setProfileImageUpload, formik, setImageInputError)}
                                    id="profile-img-file-input"
                                    type="file"
                                    className="profile-img-file-input"
                                />
                                <Label htmlFor="profile-img-file-input" className="profile-photo-edit avatar-xs">
                                    <span className="avatar-title rounded-circle bg-light text-body">
                                        <i className="ri-camera-fill"></i>
                                    </span>
                                </Label>
                            </div>
                        </div>
                        {imageInputerror && <p className="d-block invalid-feedback">{imageInputerror}</p>}
                        {permission && (
                            <span style={{ top: '10px', position: 'absolute' }}>
                                {' '}
                                <span id={`agentPermissionsTooltip${permission.id}`}>
                                    <i className="mdi mdi-help-circle-outline"></i>{' '}
                                </span>
                                <UncontrolledTooltip pement="right" target={`agentPermissionsTooltip${permission.id}`}>
                                    {permission.description}
                                </UncontrolledTooltip>
                            </span>
                        )}
                        <h5 className="fs-16 mb-1">{headline}</h5>
                        <p className="text-muted mb-0">{subheadline}</p>
                    </div>
                </CardBody>
            </Card>
        </Col>
    )
}

export default UploadPhoto
