import { debounce } from 'lodash'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import Select from 'react-select'
import { Col, FormFeedback, Label, UncontrolledTooltip } from 'reactstrap'

export const AsyncSelectInput = ({
    inputField,
    isFieldArray,
    formik,
    fieldName,
    error,
    touched,
    inputComponents,
    inputValue,
    index,
    fieldArrayDeleteComponent,
    configLength,
    inputsLength,
    selectOptions,
    inputMargin,
}) => {
    const dispatch = useDispatch()
    const [defaultValue, setDefaultValue] = useState([])
    const [isFirstRun, setIsFirstRun] = useState(true)
    const [asyncOptions, setAsyncOptions] = useState([])
    const [selectedValue, setSelectedValue] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const { t } = useTranslation()

    // TODO: this is probably useless since we already have value from setSelectedValue
    const getValue = () => {
        if (selectOptions) {
            if (inputField.isMulti) {
                return selectOptions[!isFieldArray ? inputField.name : inputField.oldName]?.filter(
                    (option) => inputValue?.indexOf(option.value.toString()) >= 0
                )
            }

            return selectOptions[!isFieldArray ? inputField.name : inputField.oldName]?.filter((option) => {
                return option.value.toString() === inputValue?.toString()
            })
        }

        return []
    }

    useEffect(() => {
        if (inputValue && selectOptions && isFirstRun) {
            if (selectOptions) {
                const selectedOptions = getValue()

                if (selectedOptions?.length) {
                    setAsyncOptions(selectedOptions)
                    setDefaultValue(selectedOptions)
                    setSelectedValue([...selectedOptions])
                }
            }
            setIsFirstRun(false)
        } else {
            if (!inputValue) {
                setSelectedValue('')
            } else {
                if (selectOptions) {
                    const selectedOptions = getValue()

                    if (selectedOptions?.length) {
                        setAsyncOptions(selectedOptions)
                        setDefaultValue(selectedOptions)
                        setSelectedValue([...selectedValue])
                    }
                }
            }
        }
    }, [inputValue])

    const debounceHandleChange = debounce(
        (val, callback) => {
            setIsLoading(true)
            return getOptions(val)
        },
        500,
        {
            leading: false,
            trailing: true,
        }
    )

    const getOptions = (inputValue) => {
        let params = {
            noLimit: 1,
        }
        if (inputField.criteria) {
            params.criteria = inputField.criteria.reduce((acc, item) => {
                return { ...acc, [item]: inputValue }
            }, {})
        }

        if (inputField.noLimit) {
            params.noLimit = 1
        } else {
            if (inputField.limit) {
                params.limit = inputField.limit
            } else {
                params.limit = 10
            }
        }
        if (inputField.staticCriteria) {
            params = {
                ...params,
                criteria: {
                    ...params.criteria,
                    ...inputField.staticCriteria,
                },
            }
        }

        if (inputField.action) {
            dispatch(
                inputField.action(
                    {
                        params,
                    },
                    (asyncValues) => {
                        setIsLoading(false)
                        let newValues
                        if (defaultValue?.length) {
                            newValues = [...defaultValue, ...asyncValues]
                        } else {
                            newValues = [...asyncValues]
                        }
                        return setAsyncOptions(newValues)
                    }
                )
            )
        }
    }

    // propertyId, projectId, location
    const setFormikValues = (e) => {
        const values = e ? e.map((item) => item.value) : []
        const locations = e ? e.map((obj) => obj.location).join(' / ') : ''

        formik?.setFieldValue(fieldName, values)

        // since onChange adds all input items, first clear, then prefill location input with location data
        formik?.setFieldValue('location', '')
        formik?.setFieldValue('location', locations)
    }

    return (
        <>
            <Col
                key={inputField.name}
                {...inputField.colProps}
                className={`${inputField.colProps?.className ?? ''} ${!isFieldArray ? inputMargin : 'mb-2'}`}
            >
                {inputField.label && (
                    <div className="d-flex justify-content-between">
                        <Label for={fieldName} id={`${inputField.name}Lbl`}>
                            {inputField.label}
                            {inputField.required && <span className="required-mark">*</span>}
                        </Label>
                        {inputField.tooltip && (
                            <span id={`${inputField.name.toString().replace(/\./g, '')}Tooltip`}>
                                <i className="mdi mdi-help-circle-outline"></i>{' '}
                            </span>
                        )}
                    </div>
                )}

                <Select
                    onInputChange={(value) => {
                        if (value.length) {
                            debounceHandleChange(value)
                        }
                    }}
                    onChange={(e) => {
                        if (inputField.isMulti) {
                            setFormikValues(e)
                            setDefaultValue(e ? e : [])
                            setAsyncOptions(e ? e : [])
                            setSelectedValue(e ? e.map((item) => item) : [])
                        } else {
                            formik?.setFieldValue(fieldName, e?.value || '')
                            setDefaultValue(e ? [e] : [])
                            setAsyncOptions(e ? [e] : [])
                            setSelectedValue(e || '')
                        }
                        if (inputField.handleChange) {
                            if (inputField.isMulti) {
                                inputField.handleChange(e ? e.map((item) => item.value) : [])
                            } else {
                                inputField.handleChange(e.value)
                            }
                        }
                    }}
                    onBlur={() => {
                        formik?.setFieldTouched(fieldName)
                    }}
                    options={asyncOptions}
                    id={inputField.name}
                    name={fieldName}
                    value={selectedValue ?? ''}
                    isDisabled={inputField.disabled}
                    isMulti={inputField.isMulti ?? false}
                    placeholder={inputField.placeholder ?? ''}
                    noOptionsMessage={() => t('form.common.select.noData')}
                    isLoading={isLoading}
                    loadingMessage={() => t('app.common.loading')}
                    className={error && touched ? 'is-invalid' : ''}
                    isClearable
                    {...inputField?.inputProps}
                />

                {error && touched ? <FormFeedback type="invalid">{error}</FormFeedback> : null}
                {inputComponents && inputComponents[inputField.name] && inputComponents[inputField.name]()}
                {inputField.tooltip && (
                    <UncontrolledTooltip pement="right" target={`${inputField.name.toString().replace(/\./g, '')}Tooltip`}>
                        {inputField.tooltip}
                    </UncontrolledTooltip>
                )}
            </Col>
            {isFieldArray && index === inputsLength && fieldArrayDeleteComponent}
        </>
    )
}

export default AsyncSelectInput
