import i18n from 'i18next'
import * as Yup from 'yup'
import { diaryResponseFields } from '../../../common/response/diary'

export const getDiaryValidationSchema = () => {
    const errorMsg = i18n.t('validation.message')
    const errorDate = i18n.t('validation.date')

    return {
        [diaryResponseFields.ID]: Yup.number(),
        [diaryResponseFields.AREA]: Yup.string()
            .nullable()
            .required(`${errorMsg}; ${i18n.t('app.common.spaceArea')}`),
        buyer: Yup.array().of(
            Yup.object().shape({
                [diaryResponseFields.BUYER_SELLER.NAME]: Yup.string()
                    .nullable()
                    .required(`${errorMsg}; ${i18n.t('form.diary.fullName')}`),
            })
        ),
        seller: Yup.array().of(
            Yup.object().shape({
                [diaryResponseFields.BUYER_SELLER.NAME]: Yup.string()
                    .nullable()
                    .required(`${errorMsg}; ${i18n.t('form.diary.fullName')}`),
            })
        ),
        [diaryResponseFields.PROPERTY_TYPE]: Yup.string()
            .nullable()
            .required(`${errorMsg}; ${i18n.t('app.common.property.type')}`),
        propertyCadastralParcelNo: Yup.string()
            .nullable()
            .when('propertyRegistryParcelNo', {
                is: null,
                then: Yup.string()
                    .nullable()
                    .required(`${errorMsg}; ${i18n.t('app.common.property.cadastralParcel')}`),
            }),
        [diaryResponseFields.PROPERTY_REGISTRY_INSERT]: Yup.string()
            .nullable()
            .required(`${errorMsg}; ${i18n.t('app.common.registry.insert')}`),
        [diaryResponseFields.PROPERTY_CASASTRAL_MUNICIPALITY]: Yup.string()
            .nullable()
            .required(`${errorMsg}; ${i18n.t('app.common.cadastralMunicipality')}`),

        [diaryResponseFields.PRICE]: Yup.string()
            .nullable()
            .required(`${errorMsg}; ${i18n.t('form.diary.finalPrice')}`),
        [diaryResponseFields.CONCLUSION_PLACE]: Yup.string()
            .nullable()
            .required(`${errorMsg}; ${i18n.t('app.common.conclusionPlace')}`),
        [diaryResponseFields.CONCLUSION_DATE]: Yup.string().matches(
            /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/,
            `${errorDate}; ${i18n.t('app.common.conclusionDate')}`
        ),
        [diaryResponseFields.FINAL_PAYMENT_DATE]: Yup.string().matches(
            /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/,
            `${errorDate}; ${i18n.t('form.diary.finalPaymentDate')}`
        ),
        [diaryResponseFields.INTERMEDIARY_DIARY_DATE]: Yup.string().matches(
            /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/,
            `${errorDate}; ${i18n.t('app.common.creationDate')}`
        ),
    }
}
